
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { MarketHistoryEntry } from '@/types/market';
import { logger } from '@/utils/logger';

interface MarketHistoryParams {
  market?: { id: string } | null;
  userId?: string;
  page?: number;
  limit?: number;
  filterByAction?: string;
  dateRange?: { start?: Date; end?: Date };
}

/**
 * Hook to fetch market history entries with pagination and filtering
 */
export const useMarketHistory = (params: MarketHistoryParams = {}) => {
  const { market, userId, page = 1, limit = 10, filterByAction, dateRange } = params;
  const marketId = market?.id;
  
  const [history, setHistory] = useState<MarketHistoryEntry[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchMarketHistory = async () => {
    try {
      setIsLoading(true);
      setError(null);

      logger.info('Fetching market history', { marketId, userId, page, limit });

      // Build a query that doesn't rely on direct relationship between tables
      // First, get the market history entries
      let query = supabase
        .from('market_history')
        .select('id, market_id, action, details, performed_by, timestamp', { count: 'exact' });

      // Apply filters
      if (marketId) {
        query = query.eq('market_id', marketId);
      }

      if (userId) {
        query = query.eq('performed_by', userId);
      }

      if (filterByAction) {
        query = query.eq('action', filterByAction);
      }

      if (dateRange?.start) {
        query = query.gte('timestamp', dateRange.start.toISOString());
      }

      if (dateRange?.end) {
        query = query.lte('timestamp', dateRange.end.toISOString());
      }

      // Apply pagination
      const from = (page - 1) * limit;
      const to = from + limit - 1;
      
      query = query.order('timestamp', { ascending: false })
        .range(from, to);

      const { data: historyData, error: fetchError, count } = await query;

      if (fetchError) {
        throw fetchError;
      }

      // Make separate queries to get the related data
      if (historyData && historyData.length > 0) {
        // Get unique user IDs and market IDs
        const userIds = Array.from(new Set(historyData.map(entry => entry.performed_by).filter(Boolean)));
        const marketIds = Array.from(new Set(historyData.map(entry => entry.market_id).filter(Boolean)));
        
        // Fetch user profiles
        const { data: profiles, error: profilesError } = await supabase
          .from('profiles')
          .select('id, email')
          .in('id', userIds);
          
        if (profilesError) {
          logger.error('Error fetching profile data for history', { error: profilesError.message });
        }
        
        // Fetch markets
        const { data: markets, error: marketsError } = await supabase
          .from('markets')
          .select('id, name, status')
          .in('id', marketIds);
          
        if (marketsError) {
          logger.error('Error fetching market data for history', { error: marketsError.message });
        }
        
        // Create lookup maps
        const profileMap = new Map(profiles?.map(profile => [profile.id, profile]) || []);
        const marketMap = new Map(markets?.map(market => [market.id, market]) || []);
        
        // Transform the data to match the expected format
        const formattedHistory: MarketHistoryEntry[] = historyData.map(entry => {
          const profile = profileMap.get(entry.performed_by);
          const market = marketMap.get(entry.market_id);
          
          return {
            id: entry.id,
            market_id: entry.market_id,
            action_type: entry.action,
            details: typeof entry.details === 'string' ? JSON.parse(entry.details) : (entry.details || {}),
            performed_by: entry.performed_by,
            performed_at: entry.timestamp,
            admin: { email: profile?.email || 'System' },
            admin_email: profile?.email || 'System',
            market_name: market?.name || 'Unknown Market',
            market_status: market?.status || 'unknown'
          };
        });
        
        setHistory(formattedHistory);
      } else {
        // If no data, set empty array
        setHistory([]);
      }
      
      setTotalCount(count || 0);
      
      logger.info('Market history fetched successfully', {
        marketId,
        count: historyData?.length || 0,
        totalCount: count
      });
    } catch (err) {
      const error = err as Error;
      logger.error('Error fetching market history', {
        marketId,
        userId,
        error: error.message
      });
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMarketHistory();
  }, [marketId, userId, page, limit, filterByAction, dateRange?.start, dateRange?.end]);

  return {
    history,
    isLoading,
    error,
    totalCount,
    refetch: fetchMarketHistory
  };
};
