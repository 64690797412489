
import { useState } from "react";
import { Market } from "@/types/market";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ConfigurationForm } from "./configuration/ConfigurationForm";
import { HistoryTab } from "./HistoryTab";

interface ConfigurationTabProps {
  market: Market;
  onUpdate: (updatedMarket?: Market) => void;
}

export const ConfigurationTab = ({ market, onUpdate }: ConfigurationTabProps) => {
  const [activeTab, setActiveTab] = useState("settings");

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
      <TabsList className="mb-4">
        <TabsTrigger value="settings">Settings</TabsTrigger>
        <TabsTrigger value="history">History</TabsTrigger>
      </TabsList>
      
      <TabsContent value="settings">
        <ConfigurationForm market={market} onUpdate={onUpdate} />
      </TabsContent>
      
      <TabsContent value="history">
        <HistoryTab market={market} />
      </TabsContent>
    </Tabs>
  );
};
