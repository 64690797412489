
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";

export type MarketHistoryAction = 
  | 'created'
  | 'updated'
  | 'deleted'
  | 'status_change'
  | 'config_updated'
  | 'market_duplicated'
  | 'member_added'
  | 'member_removed'
  | 'member_approved'
  | 'member_suspended';

interface LogMarketHistoryParams {
  marketId: string;
  action: MarketHistoryAction;
  details: Record<string, any>;
  performedBy?: string; // Will default to current user if not provided
}

/**
 * Logs an action to the market history table
 */
export const logMarketHistory = async ({
  marketId,
  action,
  details,
  performedBy
}: LogMarketHistoryParams): Promise<boolean> => {
  try {
    logger.info(`Logging market history: ${action}`, { marketId, details });
    
    // Get current user if not provided
    if (!performedBy) {
      const { data: { user } } = await supabase.auth.getUser();
      performedBy = user?.id;
    }
    
    // Add directly to the market_history table
    const { error } = await supabase
      .from('market_history')
      .insert({
        market_id: marketId,
        action: action,
        details: details,
        performed_by: performedBy
      });
    
    if (error) {
      logger.error('Failed to log market history:', error);
      return false;
    }
    
    return true;
  } catch (err) {
    logger.error('Error in logMarketHistory:', err);
    return false;
  }
};
