
import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { Search, Filter, User, Calendar, RefreshCw } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar as CalendarComponent } from "@/components/ui/calendar";
import { format } from "date-fns";
import { debounce } from "@/utils/performance/debounce";
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination";

interface ActivityLog {
  id: string;
  market_id?: string;
  action: string;
  details: any;
  performed_by: string;
  timestamp: string;
  user_email?: string;
  market_name?: string;
}

const LOGS_PER_PAGE = 10;

export const AdminActivityLog = () => {
  const [logs, setLogs] = useState<ActivityLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [actionFilter, setActionFilter] = useState("all");
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLogs, setTotalLogs] = useState(0);
  const { toast } = useToast();

  // Debounce search term updates
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Fetch logs when filters change
  useEffect(() => {
    fetchActivityLogs();
  }, [debouncedSearchTerm, actionFilter, date, currentPage]);

  const fetchActivityLogs = useCallback(async () => {
    try {
      setLoading(true);
      
      // Build query with pagination
      let query = supabase
        .from('market_history')
        .select(`
          id,
          market_id,
          action,
          details,
          performed_by,
          timestamp
        `, { count: 'exact' });
      
      // Apply filters
      if (actionFilter && actionFilter !== 'all') {
        query = query.eq('action', actionFilter);
      }
      
      if (date) {
        const dateStr = format(date, 'yyyy-MM-dd');
        query = query.gte('timestamp', `${dateStr}T00:00:00`)
                     .lt('timestamp', `${dateStr}T23:59:59`);
      }
      
      // Add pagination
      const from = (currentPage - 1) * LOGS_PER_PAGE;
      const to = from + LOGS_PER_PAGE - 1;
      
      // Execute query with range for pagination
      const { data, error, count } = await query
        .order('timestamp', { ascending: false })
        .range(from, to);

      if (error) throw error;
      
      if (count !== null) {
        setTotalLogs(count);
      }
      
      // Get additional user and market information
      const enhancedLogs = await Promise.all((data || []).map(async (log) => {
        let userEmail = 'System';
        let marketName = 'Unknown';
        
        // Get user email if available
        if (log.performed_by && log.performed_by !== '00000000-0000-0000-0000-000000000000') {
          const { data: userData } = await supabase
            .from('profiles')
            .select('email')
            .eq('id', log.performed_by)
            .single();
          
          if (userData) {
            userEmail = userData.email || 'Unknown User';
          }
        }
        
        // Get market name if available
        if (log.market_id) {
          const { data: marketData } = await supabase
            .from('markets')
            .select('name')
            .eq('id', log.market_id)
            .single();
          
          if (marketData) {
            marketName = marketData.name;
          }
        }
        
        return {
          ...log,
          user_email: userEmail,
          market_name: marketName
        };
      }));
      
      setLogs(enhancedLogs);
    } catch (error) {
      console.error('Error fetching activity logs:', error);
      toast({
        title: "Error",
        description: "Failed to load activity logs. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [actionFilter, date, currentPage, toast]);

  // Filter logs based on search term
  const filteredLogs = logs.filter(log => {
    if (!searchTerm) return true;
    
    return (
      (log.market_name && log.market_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (log.user_email && log.user_email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      log.action.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const getActionColor = (action: string) => {
    switch (action) {
      case 'status_change': return 'bg-blue-100 text-blue-800';
      case 'market_created': return 'bg-green-100 text-green-800';
      case 'market_updated': return 'bg-amber-100 text-amber-800';
      case 'market_closed': return 'bg-red-100 text-red-800';
      case 'member_joined': return 'bg-purple-100 text-purple-800';
      case 'member_left': return 'bg-pink-100 text-pink-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'PPpp');
  };

  const formatDetailsAsString = (details: any): string => {
    if (typeof details === 'string') {
      return details;
    }
    
    try {
      if (typeof details === 'object') {
        // Handle common detail formats
        if (details.old_status && details.new_status) {
          return `Status changed from ${details.old_status} to ${details.new_status}`;
        }
        
        // Default object representation
        return JSON.stringify(details, null, 2);
      }
      return String(details);
    } catch (e) {
      return 'Error formatting details';
    }
  };

  const totalPages = Math.ceil(totalLogs / LOGS_PER_PAGE);

  const handleRefresh = debounce(() => {
    fetchActivityLogs();
  }, 300);

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>System Activity Log</CardTitle>
        <CardDescription>Track all system activities and changes</CardDescription>
        <div className="flex flex-col sm:flex-row gap-4 mt-4">
          <div className="relative flex-1">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search logs..."
              className="pl-8"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex gap-2">
            <Select value={actionFilter} onValueChange={setActionFilter}>
              <SelectTrigger className="w-[160px]">
                <div className="flex items-center gap-2">
                  <Filter className="h-4 w-4" />
                  <span>Action Type</span>
                </div>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Actions</SelectItem>
                <SelectItem value="status_change">Status Change</SelectItem>
                <SelectItem value="market_created">Market Created</SelectItem>
                <SelectItem value="market_updated">Market Updated</SelectItem>
                <SelectItem value="market_closed">Market Closed</SelectItem>
                <SelectItem value="member_joined">Member Joined</SelectItem>
                <SelectItem value="member_left">Member Left</SelectItem>
                <SelectItem value="status_update_controlled">Status Update</SelectItem>
              </SelectContent>
            </Select>
            
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" className="w-[160px] justify-start">
                  <Calendar className="mr-2 h-4 w-4" />
                  {date ? format(date, 'PPP') : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <CalendarComponent
                  mode="single"
                  selected={date}
                  onSelect={setDate}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            
            <Button variant="outline" size="icon" onClick={handleRefresh}>
              <RefreshCw className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="space-y-2">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
          </div>
        ) : (
          <div className="rounded-md border overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Time</TableHead>
                  <TableHead>User</TableHead>
                  <TableHead>Market</TableHead>
                  <TableHead>Action</TableHead>
                  <TableHead>Details</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredLogs.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center py-8 text-muted-foreground">
                      No activity logs found
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredLogs.map((log) => (
                    <TableRow key={log.id} className="hover:bg-muted/50">
                      <TableCell className="whitespace-nowrap">
                        {formatDate(log.timestamp)}
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center gap-1">
                          <User className="h-4 w-4 text-muted-foreground" />
                          <span>{log.user_email}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        {log.market_name}
                      </TableCell>
                      <TableCell>
                        <Badge variant="outline" className={getActionColor(log.action)}>
                          {log.action.replace(/_/g, ' ')}
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <div className="max-w-[300px] truncate">
                          {formatDetailsAsString(log.details)}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        )}
        
        {totalPages > 1 && (
          <Pagination className="mt-4">
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious 
                  onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                  className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
                />
              </PaginationItem>
              
              {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                // Show pages around current page
                let pageNum = i + 1;
                if (totalPages > 5) {
                  if (currentPage > 3) {
                    pageNum = currentPage - 3 + i;
                  }
                  if (currentPage > totalPages - 2) {
                    pageNum = totalPages - 4 + i;
                  }
                }
                
                if (pageNum <= totalPages) {
                  return (
                    <PaginationItem key={pageNum}>
                      <PaginationLink
                        isActive={currentPage === pageNum}
                        onClick={() => setCurrentPage(pageNum)}
                      >
                        {pageNum}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }
                return null;
              })}
              
              <PaginationItem>
                <PaginationNext 
                  onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                  className={currentPage === totalPages ? "pointer-events-none opacity-50" : ""}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        )}
        
        {totalLogs > 0 && (
          <div className="text-sm text-muted-foreground mt-2">
            Showing {((currentPage - 1) * LOGS_PER_PAGE) + 1} to {Math.min(currentPage * LOGS_PER_PAGE, totalLogs)} of {totalLogs} entries
          </div>
        )}
      </CardContent>
    </Card>
  );
};
