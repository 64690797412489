
import React from 'react';
import { MarketCard } from './MarketCard'; // Correct import using named import
import { Market } from '@/types/market';

interface MarketsListProps {
  markets: Market[] | null;
  onParticipate: (marketId: string) => Promise<void>;
  onContributionSubmitted: () => Promise<void>; // Parameters removed since we're not using it
  onCloseTimeUpdate: (marketId: string, closeTime: string) => Promise<void>;
  isLoading: boolean;
  onRefresh: () => Promise<void>;
}

const MarketsList = ({
  markets,
  onParticipate,
  onContributionSubmitted,
  onCloseTimeUpdate,
  isLoading,
  onRefresh
}: MarketsListProps) => {
  if (isLoading) {
    return <div className="text-center py-4">Loading markets...</div>;
  }

  if (!markets || markets.length === 0) {
    return (
      <div className="text-center py-4">
        No markets available.
        <button onClick={onRefresh} className="text-blue-500 underline ml-2">
          Refresh
        </button>
      </div>
    );
  }

  const handleParticipate = async (marketId: string): Promise<void> => {
    if (onParticipate) {
      return onParticipate(marketId);
    }
    return Promise.resolve();
  };

  // This function simply calls the parent handler with no parameters
  const handleContributionSubmitted = async (): Promise<void> => {
    if (onContributionSubmitted) {
      return onContributionSubmitted();
    }
    return Promise.resolve();
  };

  const handleCloseTimeUpdate = async (marketId: string, closeTime: string): Promise<void> => {
    if (onCloseTimeUpdate) {
      return onCloseTimeUpdate(marketId, closeTime);
    }
    return Promise.resolve();
  };

  const renderMarketCard = (market: Market) => (
    <MarketCard
      key={market.id}
      market={market}
      markets={markets}
      onParticipate={handleParticipate}
      onParticipateSuccess={handleContributionSubmitted}
      onCloseTimeUpdate={handleCloseTimeUpdate}
    />
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {markets.map(renderMarketCard)}
    </div>
  );
};

export default MarketsList;
