
import { useState, useCallback, useEffect } from "react";
import { Market } from "@/types/market";
import { useMarketUpdate } from "./config/use-market-update";
import { useMarketDelete } from "./config/use-market-delete";
import { useStatusUpdate } from "./config/use-status-update";
import { logger } from "@/utils/logger";
import { 
  getMarketDescription, 
  getSequencePosition,
  parseMarketMetadata 
} from "@/utils/market/metadata-parser";

export const useMarketConfiguration = (market: Market, onUpdate: (updatedMarket?: Market) => void) => {
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  // Market configuration state
  const [dailyTarget, setDailyTarget] = useState(market.daily_contribution_target?.toString() || "0");
  const [adjustmentFactor, setAdjustmentFactor] = useState(market.adjustment_factor?.toString() || "1.07");
  const [maxParticipationsPerUser, setMaxParticipationsPerUser] = useState(market.max_participations_per_user || 1);
  const [requiresApproval, setRequiresApproval] = useState(market.requires_approval || false);
  const [marketType, setMarketType] = useState<'standard' | 'premium' | 'exclusive' | 'duplicate'>(
    (market.market_type as 'standard' | 'premium' | 'exclusive' | 'duplicate') || 'standard'
  );
  const [marketRiskLevel, setMarketRiskLevel] = useState<'low' | 'medium' | 'high'>(
    (market.market_risk_level as 'low' | 'medium' | 'high') || 'medium'
  );
  const [expectedROI, setExpectedROI] = useState(market.expected_roi || 0);
  const [maxDuplications, setMaxDuplications] = useState(market.max_duplications || 3);
  
  // Market description from metadata
  const [description, setDescription] = useState('');
  const [sequencePosition, setSequencePosition] = useState<number | null>(null);

  // Update state when market props change
  useEffect(() => {
    logger.info('Market configuration updated from props', {
      marketId: market.id,
      dailyTarget: market.daily_contribution_target,
      adjustmentFactor: market.adjustment_factor,
      maxParticipationsPerUser: market.max_participations_per_user,
      requiresApproval: market.requires_approval,
      marketType: market.market_type,
      marketRiskLevel: market.market_risk_level,
      expectedROI: market.expected_roi,
      maxDuplications: market.max_duplications
    });
    
    // Only update state if values have changed to avoid infinite render loops
    if (market.daily_contribution_target?.toString() !== dailyTarget) {
      setDailyTarget(market.daily_contribution_target?.toString() || "0");
    }
    
    if (market.adjustment_factor?.toString() !== adjustmentFactor) {
      setAdjustmentFactor(market.adjustment_factor?.toString() || "1.07");
    }
    
    if (market.max_participations_per_user !== maxParticipationsPerUser) {
      setMaxParticipationsPerUser(market.max_participations_per_user || 1);
    }
    
    if (market.requires_approval !== requiresApproval) {
      setRequiresApproval(market.requires_approval || false);
    }
    
    const newMarketType = (market.market_type as 'standard' | 'premium' | 'exclusive' | 'duplicate') || 'standard';
    if (newMarketType !== marketType) {
      setMarketType(newMarketType);
    }
    
    const newMarketRiskLevel = (market.market_risk_level as 'low' | 'medium' | 'high') || 'medium';
    if (newMarketRiskLevel !== marketRiskLevel) {
      setMarketRiskLevel(newMarketRiskLevel);
    }
    
    if (market.expected_roi !== expectedROI) {
      setExpectedROI(market.expected_roi || 0);
    }
    
    if (market.max_duplications !== maxDuplications) {
      setMaxDuplications(market.max_duplications || 3);
    }
    
    // Get description from metadata
    const extractedDescription = getMarketDescription(market.metadata);
    if (extractedDescription !== description) {
      setDescription(extractedDescription);
    }
    
    // Get sequence position from metadata
    const extractedSequencePosition = getSequencePosition(market.metadata);
    if (extractedSequencePosition !== sequencePosition) {
      setSequencePosition(extractedSequencePosition);
    }
  }, [market]);

  // Hooks for market operations
  const { handleUpdateConfiguration, saveError, isSaving } = useMarketUpdate(market, onUpdate);
  const { handleDelete } = useMarketDelete(market, onUpdate);
  const { handleStatusUpdate } = useStatusUpdate(market.id, onUpdate);

  // Wrapper for the delete function to manage loading state locally
  const handleDeleteWrapper = useCallback(async () => {
    if (loading || isDeleting) return;
    setIsDeleting(true);
    try {
      await handleDelete(loading, setLoading);
    } finally {
      setIsDeleting(false);
    }
  }, [handleDelete, loading, isDeleting]);

  // Wrapper for the status update function to manage loading state
  const handleStatusUpdateWrapper = useCallback(async (status: Market['status']) => {
    if (loading) return;
    try {
      await handleStatusUpdate(status, loading, setLoading);
    } catch (error) {
      console.error("Error in status update wrapper:", error);
    }
  }, [handleStatusUpdate, loading]);

  // Save configuration changes
  const saveConfigurationChanges = useCallback(async () => {
    logger.info(`Saving market configuration changes for market ${market.id}`, {
      dailyTarget,
      adjustmentFactor,
      maxParticipationsPerUser,
      requiresApproval,
      marketType,
      marketRiskLevel,
      expectedROI,
      maxDuplications,
      description,
      sequencePosition
    });
    
    await handleUpdateConfiguration(loading, setLoading, {
      dailyTarget,
      adjustmentFactor,
      requiresApproval,
      maxParticipationsPerUser,
      marketType,
      marketRiskLevel,
      expectedROI,
      maxDuplications,
      description,
      sequencePosition
    });
  }, [
    loading, 
    handleUpdateConfiguration, 
    dailyTarget, 
    adjustmentFactor, 
    requiresApproval, 
    maxParticipationsPerUser,
    marketType,
    marketRiskLevel,
    expectedROI,
    maxDuplications,
    description,
    sequencePosition,
    market.id
  ]);

  return {
    // State
    loading,
    dailyTarget,
    adjustmentFactor,
    maxParticipationsPerUser,
    requiresApproval,
    marketType,
    marketRiskLevel,
    expectedROI,
    maxDuplications,
    description,
    sequencePosition,
    isDeleting,
    
    // Setters
    setDailyTarget,
    setAdjustmentFactor,
    setMaxParticipationsPerUser,
    setRequiresApproval,
    setMarketType,
    setMarketRiskLevel,
    setExpectedROI,
    setMaxDuplications,
    setDescription,
    setSequencePosition,
    
    // Error state
    saveError,
    isSaving,
    
    // Actions
    handleUpdateConfiguration: saveConfigurationChanges,
    handleStatusUpdate: handleStatusUpdateWrapper,
    handleDelete: handleDeleteWrapper
  };
};
