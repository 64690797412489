
import { MarketParticipant } from "@/types/market";
import { logger } from "@/utils/logger";

/**
 * Transforms raw Supabase data to typed MarketParticipant objects
 * with enhanced error handling for JSON parsing
 */
export const transformMemberData = (members: any[]): MarketParticipant[] => {
  return members.map(member => {
    try {
      // Handle performance_metrics safely
      let parsedPerformanceMetrics: Record<string, any> | null = null;
      
      if (member.performance_metrics) {
        if (typeof member.performance_metrics === 'string') {
          try {
            parsedPerformanceMetrics = JSON.parse(member.performance_metrics);
            logger.info('Successfully parsed performance_metrics JSON');
          } catch (e) {
            logger.warn('Failed to parse performance_metrics JSON:', e);
            parsedPerformanceMetrics = {};
          }
        } else if (typeof member.performance_metrics === 'object') {
          parsedPerformanceMetrics = member.performance_metrics as Record<string, any>;
        }
      }

      // Handle trading_history safely
      let parsedTradingHistory: any[] | null = null;
      
      if (member.trading_history) {
        if (typeof member.trading_history === 'string') {
          try {
            parsedTradingHistory = JSON.parse(member.trading_history);
            if (!Array.isArray(parsedTradingHistory)) {
              logger.warn('Trading history is not an array after parsing:', { value: parsedTradingHistory });
              parsedTradingHistory = [];
            }
          } catch (e) {
            logger.warn('Failed to parse trading_history JSON:', e);
            parsedTradingHistory = [];
          }
        } else if (Array.isArray(member.trading_history)) {
          parsedTradingHistory = member.trading_history;
        } else if (typeof member.trading_history === 'object') {
          logger.warn('Trading history is an object but not an array:', { value: member.trading_history });
          parsedTradingHistory = [];
        }
      }

      // Create a typed MarketParticipant object that adheres to the interface
      const typedMember: MarketParticipant = {
        ...member,
        performance_metrics: parsedPerformanceMetrics,
        trading_history: parsedTradingHistory
      };

      return typedMember;
    } catch (error) {
      // Catch any unexpected errors during the transformation process
      logger.error('Error transforming member data:', {
        error,
        memberId: member.id,
        userId: member.user_id
      });
      
      // Return member with safe defaults for JSON fields
      return {
        ...member,
        performance_metrics: {},
        trading_history: []
      };
    }
  });
};
