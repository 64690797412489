
import React from 'react';
import { Market } from '@/types/market';
import { Tabs, TabsContent } from '@/components/ui/tabs';
import { useTabContentEffects } from '@/hooks/markets/tabs/use-tab-content-effects';
import { TabContentDisplay } from './display/TabContentDisplay';
import { RegionMarketsDisplay } from '../region/RegionMarketsDisplay';
import { PaginatedClosedMarkets } from '../closed/PaginatedClosedMarkets';

interface TabContentProps {
  markets: Market[] | null;
  filteredMarkets?: Market[] | null;
  isLoading: boolean;
  activeTab: string;
  onRefresh: () => Promise<void>;
  onParticipate: (marketId: string) => Promise<void>;
  onContributionSubmitted: () => Promise<void>;
  onCloseTimeUpdate: (marketId: string, closeTime: string) => Promise<void>;
  setActiveTab: (tab: string) => void;
  viewMode: 'grid' | 'list';
  value: string;
}

export const TabContent: React.FC<TabContentProps> = ({
  markets,
  filteredMarkets,
  isLoading,
  activeTab,
  onRefresh,
  onParticipate,
  onContributionSubmitted,
  onCloseTimeUpdate,
  setActiveTab,
  viewMode,
  value
}) => {
  // Get filtered markets for the current tab
  // If filteredMarkets is provided, use it; otherwise, use useTabContentEffects
  const { displayMarkets, hasMarkets } = filteredMarkets !== undefined
    ? { displayMarkets: filteredMarkets, hasMarkets: !!filteredMarkets && filteredMarkets.length > 0 }
    : useTabContentEffects(value, markets, isLoading, onRefresh);

  return (
    <Tabs value={activeTab} defaultValue={activeTab}>
      <TabsContent value={value} className="animate-fade-in">
        {value === 'closed' ? (
          <PaginatedClosedMarkets
            markets={displayMarkets}
            onParticipate={onParticipate}
            onContributionSubmitted={onContributionSubmitted}
            onCloseTimeUpdate={onCloseTimeUpdate}
            viewMode={viewMode}
            groupByRegion={true}
          />
        ) : value === 'available' || value === 'upcoming' ? (
          <RegionMarketsDisplay
            markets={displayMarkets}
            viewMode={viewMode}
            onParticipate={onParticipate}
            onContributionSubmitted={onContributionSubmitted}
            onCloseTimeUpdate={onCloseTimeUpdate}
          />
        ) : (
          <TabContentDisplay
            markets={displayMarkets}
            hasMarkets={hasMarkets}
            isLoading={isLoading}
            value={value}
            onParticipate={onParticipate}
            onContributionSubmitted={onContributionSubmitted}
            onCloseTimeUpdate={onCloseTimeUpdate}
            onRefresh={onRefresh}
          />
        )}
      </TabsContent>
    </Tabs>
  );
};
