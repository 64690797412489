
import { MarketErrorType } from './error-types';

/**
 * Market specific error types with detailed descriptions
 */
export type { MarketErrorType } from './error-types';

export const MARKET_ERROR_DESCRIPTIONS: Record<MarketErrorType, string> = {
  'forbidden': 'You do not have permission to access this market or perform this operation.',
  'network': 'A network error occurred. Please check your internet connection and try again.',
  'cors': 'Cross-origin resource sharing error. This is a technical issue that has been logged.',
  'recursion': 'The system detected a recursive operation that could not be completed.',
  'deadlock': 'A database deadlock was detected. The system will automatically retry your request.',
  'timeout': 'The operation timed out. This could be due to high server load or connectivity issues.',
  'validation': 'The data provided did not meet validation requirements.',
  'not-found': 'The requested market or resource could not be found.',
  'authentication': 'Your session may have expired. Please sign in again to continue.',
  'auth': 'Authentication error. Please sign in again to continue.',
  'server': 'A server error occurred. Our team has been notified and is working to resolve the issue.',
  'circuit-open': 'The system is temporarily unavailable due to high error rates. Please try again later.',
  'unknown': 'An unexpected error occurred. Please try again or contact support if the issue persists.'
};

/**
 * Determine if an error of a specific type is retryable
 */
export function isMarketErrorRetryable(errorType: MarketErrorType): boolean {
  // These error types are typically retryable
  const retryableTypes: MarketErrorType[] = [
    'network',
    'timeout',
    'deadlock',
    'server'
  ];
  
  return retryableTypes.includes(errorType);
}

/**
 * Get user-friendly error recovery suggestions based on error type
 */
export function getMarketErrorRecoverySteps(errorType: MarketErrorType): string[] {
  switch (errorType) {
    case 'network':
      return [
        'Check your internet connection',
        'Ensure you\'re not behind a restrictive firewall',
        'Try refreshing the page'
      ];
    case 'timeout':
      return [
        'The server might be experiencing high traffic',
        'Try again in a few moments',
        'If the issue persists, contact support'
      ];
    case 'auth':
    case 'authentication':
      return [
        'Your session may have expired',
        'Sign out and sign back in',
        'Clear your browser cache and cookies'
      ];
    case 'forbidden':
      return [
        'You may not have the necessary permissions',
        'Contact your administrator for access',
        'Verify that your account is properly configured'
      ];
    case 'server':
      return [
        'Our team has been automatically notified',
        'Please try again in a few minutes',
        'If the issue persists, contact support'
      ];
    case 'circuit-open':
      return [
        'The system is temporarily limiting requests due to high error rates',
        'Try again after a few minutes',
        'If this persists for a long time, contact support'
      ];
    default:
      return [
        'Try refreshing the page',
        'Sign out and sign back in',
        'If the issue persists, contact support'
      ];
  }
}
