
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Wallet, Search, BadgeHelp } from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Input } from "@/components/ui/input";
import UserMenu from "../navigation/UserMenu";
import NotificationsMenu from "../navigation/NotificationsMenu";
import { formatCurrency } from "@/utils/formatters";
import ThemeToggle from "../navigation/ThemeToggle";

export function DashboardTopBar() {
  const {
    session,
    isAdmin
  } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [userBalance, setUserBalance] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const firstName = session?.user?.user_metadata?.first_name;
  const lastName = session?.user?.user_metadata?.last_name;
  const fullName = firstName && lastName ? `${firstName} ${lastName}` : firstName || "User";
  
  useEffect(() => {
    if (session?.user?.id && !isAdmin) {
      fetchUserBalance();
    } else {
      setLoading(false);
    }
  }, [session?.user?.id, isAdmin]);
  
  const fetchUserBalance = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('profiles')
        .select('balance')
        .eq('id', session?.user?.id)
        .maybeSingle(); // Use maybeSingle instead of single
      
      if (error) {
        console.error('Error fetching user balance:', error);
        setUserBalance(0); // Default to 0 on error
      } else {
        setUserBalance(data?.balance || 0);
      }
    } catch (error) {
      console.error('Error fetching user balance:', error);
      setUserBalance(0); // Default to 0 on exception
    } finally {
      setLoading(false);
    }
  };
  
  const handleBalanceClick = () => {
    navigate("/dashboard/finances");
  };
  
  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      toast({
        title: "Search",
        description: `Searching for: ${searchQuery}`,
      });
    }
  };
  
  return (
    <div className="fixed top-0 left-0 right-0 bg-muted text-zinc-100 z-50 border-b border-border/40 shadow-sm">
      <div className="container flex h-20 max-w-screen-2xl items-center py-4">
        <div className="flex flex-1 items-center justify-between">
          <div className="flex items-center mr-2 cursor-pointer" onClick={() => navigate("/dashboard")}>
            <img src="/lovable-uploads/6e56694c-5695-4312-b4e0-39855e75c061.png" alt="The Colosseum" className="h-16" />
            <div className="flex items-center ml-2 text-xs px-2 py-1 bg-primary/10 text-primary rounded-md border border-primary/20">
              <BadgeHelp className="h-3 w-3 mr-1 text-primary" />
              <span>BETA</span>
            </div>
          </div>

          <div className="hidden md:flex flex-1 mx-4 max-w-md">
            <form onSubmit={handleSearchSubmit} className="relative w-full">
              <Input
                type="text"
                placeholder="Search markets, finances, reports..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-background/10 border-input focus-visible:border-primary focus-visible:ring-1 focus-visible:ring-primary focus-visible:outline-none text-zinc-100"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-zinc-300" />
            </form>
          </div>

          <div className="flex items-center gap-2">
            <ThemeToggle />
            
            {/* Only show balance for non-admin users */}
            {!isAdmin && (
              <Button variant="outline" className="gap-2 bg-primary/5 border-primary/20 hover:bg-primary/10 hover:border-primary/30 hidden md:flex text-zinc-100" onClick={handleBalanceClick}>
                <Wallet className="h-4 w-4 text-primary" />
                {loading ? <Skeleton className="h-4 w-20" /> : <span>{formatCurrency(userBalance)}</span>}
              </Button>
            )}
            
            <NotificationsMenu />
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  );
}
