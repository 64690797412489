
// Define standardized status groupings for consistent handling across the app
export const CLOSED_STATUSES = [
  'closed', 
  'closed_successful', 
  'closed_failed', 
  'completed', 
  'success', 
  'failed', 
  'failed_final'
];

export const ACTIVE_STATUSES = [
  'active', 
  'open'
];

export const PENDING_STATUSES = [
  'pending', 
  'pending_contribution', 
  'pending_approval'
];

export const SCHEDULED_STATUSES = [
  'scheduled'
];

/**
 * Filter strategy implementations for each tab
 */
export const tabStrategies = {
  available: (market: any) => {
    return (
      ACTIVE_STATUSES.includes(market.status) &&
      !market.isParticipating &&
      (!market.requires_approval || market.requires_approval === false)
    );
  },
  
  joined: (market: any) => {
    return (
      market.isParticipating === true &&
      !CLOSED_STATUSES.includes(market.status)
    );
  },
  
  pending: (market: any) => {
    return (
      (PENDING_STATUSES.includes(market.status) || 
       (market.requires_approval === true && !market.isParticipating)) &&
      !CLOSED_STATUSES.includes(market.status)
    );
  },
  
  upcoming: (market: any) => {
    return SCHEDULED_STATUSES.includes(market.status);
  },
  
  closed: (market: any) => {
    return (
      CLOSED_STATUSES.includes(market.status) &&
      market.isParticipating === true
    );
  },
  
  // Admin-specific tabs
  all: () => true,
  
  admin_active: (market: any) => {
    return ACTIVE_STATUSES.includes(market.status);
  },
  
  admin_pending: (market: any) => {
    return PENDING_STATUSES.includes(market.status);
  },
  
  admin_scheduled: (market: any) => {
    return SCHEDULED_STATUSES.includes(market.status);
  },
  
  admin_closed: (market: any) => {
    return CLOSED_STATUSES.includes(market.status);
  }
};

/**
 * Helper function to get the appropriate tab strategy function
 */
export const getTabStrategy = (activeTab: string) => {
  return tabStrategies[activeTab as keyof typeof tabStrategies] || tabStrategies.all;
};

// Export individual strategy functions for direct use
export const availableTabStrategy = tabStrategies.available;
export const joinedTabStrategy = tabStrategies.joined;
export const pendingTabStrategy = tabStrategies.pending;
export const upcomingTabStrategy = tabStrategies.upcoming;
export const closedTabStrategy = tabStrategies.closed;
