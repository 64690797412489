
/**
 * Determines if a market is considered "new" (created within the last 24 hours)
 * @param createdAt The timestamp when the market was created
 * @returns Boolean indicating if the market should be considered new
 */
export const isNewMarket = (createdAt?: string | null): boolean => {
  if (!createdAt) return false;
  
  const createdDate = new Date(createdAt);
  const now = new Date();
  
  // Calculate the difference in milliseconds
  const differenceInMs = now.getTime() - createdDate.getTime();
  
  // 24 hours in milliseconds = 24 * 60 * 60 * 1000 = 86400000
  return differenceInMs <= 86400000;
};
