
import React from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { format } from "date-fns";

interface MonthSelectorProps {
  selectedMonth: number;
  selectedYear: number;
  onMonthYearChange: (month: number, year: number) => void;
}

export const MonthSelector: React.FC<MonthSelectorProps> = ({
  selectedMonth,
  selectedYear,
  onMonthYearChange
}) => {
  // Get current month and year
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1; // JavaScript months are 0-indexed
  
  // Generate year options (from current year - 2 to current year)
  const yearOptions = [];
  for (let year = currentYear; year >= currentYear - 2; year--) {
    yearOptions.push(year);
  }
  
  // Generate month options
  const monthOptions = [];
  for (let month = 1; month <= 12; month++) {
    const date = new Date(2000, month - 1, 1);
    monthOptions.push({
      value: month,
      label: format(date, 'MMMM')
    });
  }
  
  return (
    <div className="flex space-x-4 mb-6">
      <div className="w-1/2">
        <label className="block text-sm font-medium mb-1">Month</label>
        <Select
          value={String(selectedMonth)}
          onValueChange={(value) => onMonthYearChange(parseInt(value), selectedYear)}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select month" />
          </SelectTrigger>
          <SelectContent>
            {monthOptions.map((month) => (
              <SelectItem key={month.value} value={String(month.value)}>
                {month.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      
      <div className="w-1/2">
        <label className="block text-sm font-medium mb-1">Year</label>
        <Select
          value={String(selectedYear)}
          onValueChange={(value) => onMonthYearChange(selectedMonth, parseInt(value))}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select year" />
          </SelectTrigger>
          <SelectContent>
            {yearOptions.map((year) => (
              <SelectItem key={year} value={String(year)}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
