
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@/hooks/use-toast';
import { registerUser } from '@/services/registration';
import type { FormData } from '@/hooks/useRegistrationForm';
import { validatePassword } from '@/utils/validation';
import { logger } from '@/utils/logger';

export const useRegistrationHandler = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleRegistration = async (formData: FormData) => {
    setIsLoading(true);
    setError(null);

    try {
      // Pre-validate password to provide better feedback
      if (!validatePassword(formData.password)) {
        setError('Password must be at least 8 characters and include uppercase, lowercase, numbers, and special characters.');
        toast({
          variant: "destructive",
          title: "Invalid Password",
          description: "Password must meet security requirements.",
        });
        setIsLoading(false);
        return false;
      }

      toast({
        title: "Creating Account",
        description: "Please wait while we set up your account...",
      });

      const nameParts = formData.fullName.trim().split(/\s+/);
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(' ') || '';

      logger.info('Starting registration process', { 
        email: formData.email,
        name: `${firstName} ${lastName}`
      });

      const result = await registerUser({
        email: formData.email.trim().toLowerCase(),
        password: formData.password,
        firstName,
        lastName,
        phone: formData.phone || '',
        country: formData.country || '',
      });

      if (result && result.user) {
        logger.info('Registration successful, redirecting to waiting list', {
          userId: result.user.id
        });
        
        toast({
          title: "Registration Submitted",
          description: "Your account has been created successfully. An administrator will review your registration shortly.",
          duration: 6000,
        });
        
        // Always redirect to waiting list
        navigate('/waiting-list', { replace: true });
        return true;
      } else {
        throw new Error("Registration failed - no user data returned");
      }
    } catch (err: any) {
      const errorMessage = err?.message || "An unexpected error occurred";
      logger.error("Registration error:", err);
      
      if (errorMessage.includes('User already registered')) {
        toast({
          variant: "warning",
          title: "Account Already Exists",
          description: "An account with this email already exists. Please sign in instead.",
        });
        
        // Automatically navigate to sign in page after a short delay
        setTimeout(() => {
          navigate('/signin', { replace: true });
        }, 2000);
      } else if (errorMessage.includes('password')) {
        setError('Password must be at least 8 characters and include uppercase, lowercase, numbers, and special characters.');
        toast({
          variant: "destructive",
          title: "Registration Failed",
          description: "Password does not meet security requirements.",
        });
      } else {
        setError(errorMessage || "An unexpected error occurred during registration");
        toast({
          variant: "destructive",
          title: "Registration Failed",
          description: errorMessage || "An unexpected error occurred. Please try again.",
        });
      }
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleRegistration,
    isLoading,
    error,
  };
};
