import { createClient } from '@supabase/supabase-js';
import type { Database } from '@/integrations/supabase/types';
import { logger } from '@/utils/logger';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const siteUrl = import.meta.env.VITE_SITE_URL || 'https://thecolosseum.co.za';

if (!supabaseUrl) throw new Error('Missing environment variable: VITE_SUPABASE_URL');
if (!supabaseKey) throw new Error('Missing environment variable: VITE_SUPABASE_ANON_KEY');

export const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storage: localStorage,
    storageKey: 'app-session',
    flowType: 'pkce', // Use PKCE flow for better security
    debug: import.meta.env.DEV
  },
  global: {
    headers: {
      'X-Client-Info': 'supabase-js-web',
    },
  },
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  },
  // Add better timeout and retry configuration
  db: {
    schema: 'public'
  }
});

// Add a type assertion helper for database responses
export const assertType = <T>(data: T): T => data;

// Enhanced auth state change listener with better error handling
supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'TOKEN_REFRESHED') {
    logger.info('Token refreshed successfully', {
      userId: session?.user?.id,
      event,
      expiresAt: session?.expires_at
    });
  }
  if (event === 'SIGNED_OUT') {
    logger.info('Session cleared on sign out');
    
    // Make sure we clean up all storage on sign out
    localStorage.removeItem('app-session');
    localStorage.removeItem('userRole');
    
    // Clear any cached data that might contain user-specific information
    const localStorageKeys = Object.keys(localStorage);
    localStorageKeys.forEach(key => {
      if (key.includes('supabase') || key.includes('sb-')) {
        localStorage.removeItem(key);
      }
    });
  }
  if (event === 'SIGNED_IN') {
    logger.info('User signed in', {
      userId: session?.user?.id,
      email: session?.user?.email,
      expiresAt: session?.expires_at
    });
  }
  if (event === 'USER_UPDATED') {
    logger.info('User profile updated', {
      userId: session?.user?.id
    });
  }
  if (event === 'PASSWORD_RECOVERY') {
    logger.info('Password recovery initiated', {
      userId: session?.user?.id
    });
  }
});

// Schedule token refresh 5 minutes before expiration
supabase.auth.onAuthStateChange((event, session) => {
  if (session && (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED')) {
    const expiresAt = session.expires_at || 0;
    const refreshTime = (expiresAt - 300) * 1000; // 5 minutes before expiry in ms
    const currentTime = Date.now();
    
    if (refreshTime > currentTime) {
      const timeUntilRefresh = refreshTime - currentTime;
      
      // Schedule refresh
      setTimeout(async () => {
        try {
          logger.info('Proactively refreshing auth token');
          await supabase.auth.refreshSession();
        } catch (error) {
          logger.error('Failed to refresh token:', error);
        }
      }, timeUntilRefresh);
    }
  }
});

// Export the configuration for use in other parts of the app
export const SUPABASE_CONFIG = {
  url: supabaseUrl,
  anonKey: supabaseKey
};
