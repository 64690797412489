
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { 
  Tooltip, 
  TooltipContent, 
  TooltipProvider, 
  TooltipTrigger 
} from "@/components/ui/tooltip";
import { NavItem } from "./navigation-items";

interface NavMenuItemProps {
  item: NavItem;
  isCollapsed: boolean;
  isMobile: boolean;
  onNavigate?: () => void;
}

export function NavMenuItem({ item, isCollapsed, isMobile, onNavigate }: NavMenuItemProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname === item.path;
  const Icon = item.icon;
  
  const handleClick = () => {
    navigate(item.path);
    if (onNavigate) onNavigate();
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            onClick={handleClick}
            className={cn(
              "w-full gap-3 transition-all duration-200",
              "relative rounded-lg",
              isCollapsed && !isMobile ? "justify-center px-2" : "justify-start px-3.5 py-2.5",
              isActive 
                ? "bg-primary/10 text-primary font-medium" 
                : "hover:bg-primary/5 text-zinc-700 dark:text-muted-foreground hover:text-primary"
            )}
          >
            <Icon className={cn(
              "h-5 w-5 transition-colors duration-200",
              isActive ? "text-primary" : "text-zinc-600 dark:text-muted-foreground group-hover:text-primary"
            )} />
            
            <span className={cn(
              "font-medium whitespace-nowrap transition-all duration-300",
              !isMobile && isCollapsed ? "hidden" : "block"
            )}>
              {item.label}
            </span>
            
            {isActive && (
              <div className="absolute left-0 top-1/2 -translate-y-1/2 w-1 h-8 bg-primary rounded-r-full" />
            )}
          </Button>
        </TooltipTrigger>
        
        {!isMobile && isCollapsed && (
          <TooltipContent 
            side="right" 
            align="center"
            className="bg-card/95 backdrop-blur-sm text-card-foreground border border-border/30"
          >
            {item.label}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
}
