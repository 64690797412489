
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { format } from "date-fns";
import { useAuth } from "@/contexts/AuthContext";
import { Transaction, TransactionSummaryStats } from "@/types/transaction";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

interface TransactionHistoryProps {
  limit?: number;
  showTitle?: boolean;
}

export const TransactionHistory = ({ 
  limit,
  showTitle = true
}: TransactionHistoryProps) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [summaryStats, setSummaryStats] = useState<TransactionSummaryStats>({
    totalDeposits: 0,
    totalEarnings: 0,
    totalSpent: 0,
    netBalance: 0,
    pendingDeposits: 0
  });
  const { toast } = useToast();
  const { session } = useAuth();

  useEffect(() => {
    if (session?.user?.id) {
      fetchTransactions();
    }
  }, [session?.user?.id]);

  const fetchTransactions = async () => {
    try {
      setIsLoading(true);
      
      let query = supabase
        .from('transactions')
        .select('*')
        .eq('user_id', session?.user?.id)
        .order('created_at', { ascending: false });

      if (limit) {
        query = query.limit(limit);
      }

      const { data, error } = await query;

      if (error) {
        throw error;
      }

      setTransactions(data as Transaction[]);
      calculateSummaryStats(data as Transaction[]);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      toast({
        title: "Error",
        description: "Failed to fetch transaction history",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const calculateSummaryStats = (transactions: Transaction[]) => {
    const stats: TransactionSummaryStats = {
      totalDeposits: 0,
      totalEarnings: 0,
      totalSpent: 0,
      netBalance: 0,
      pendingDeposits: 0
    };

    transactions.forEach(tx => {
      if (tx.status === 'approved') {
        if (tx.type === 'deposit') {
          stats.totalDeposits += Number(tx.amount);
        } else if (tx.type === 'earnings') {
          stats.totalEarnings += Number(tx.amount);
        } else if (tx.type === 'market_entry' || tx.type === 'loss') {
          stats.totalSpent += Number(tx.amount);
        }
      } else if (tx.status === 'pending' && tx.type === 'deposit') {
        stats.pendingDeposits += Number(tx.amount);
      }
    });

    stats.netBalance = stats.totalDeposits + stats.totalEarnings - stats.totalSpent;
    setSummaryStats(stats);
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'approved':
        return <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">Approved</Badge>;
      case 'pending':
        return <Badge variant="outline" className="bg-yellow-50 text-yellow-700 border-yellow-200">Pending</Badge>;
      case 'rejected':
        return <Badge variant="outline" className="bg-red-50 text-red-700 border-red-200">Rejected</Badge>;
      default:
        return <Badge variant="outline">{status}</Badge>;
    }
  };

  const getTypeBadge = (type: string) => {
    switch (type) {
      case 'deposit':
        return <Badge className="bg-blue-100 text-blue-800">Deposit</Badge>;
      case 'withdrawal':
        return <Badge className="bg-purple-100 text-purple-800">Withdrawal</Badge>;
      case 'market_entry':
        return <Badge className="bg-indigo-100 text-indigo-800">Market Entry</Badge>;
      case 'earnings':
        return <Badge className="bg-green-100 text-green-800">Earnings</Badge>;
      case 'loss':
        return <Badge className="bg-red-100 text-red-800">Loss</Badge>;
      default:
        return <Badge>{type}</Badge>;
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2
    }).format(amount);
  };

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          {showTitle && (
            <>
              <CardTitle>Transaction History</CardTitle>
              <CardDescription>Your recent financial activities</CardDescription>
            </>
          )}
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex justify-between items-center">
                <div className="space-y-2">
                  <Skeleton className="h-4 w-[250px]" />
                  <Skeleton className="h-4 w-[200px]" />
                </div>
                <Skeleton className="h-8 w-[80px]" />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        {showTitle && (
          <>
            <CardTitle>Transaction History</CardTitle>
            <CardDescription>Your recent financial activities</CardDescription>
          </>
        )}
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
          <div className="bg-green-50 p-4 rounded-lg">
            <p className="text-sm text-muted-foreground">Total Deposits</p>
            <p className="text-2xl font-bold text-green-700">{formatCurrency(summaryStats.totalDeposits)}</p>
          </div>
          <div className="bg-blue-50 p-4 rounded-lg">
            <p className="text-sm text-muted-foreground">Total Earnings</p>
            <p className="text-2xl font-bold text-blue-700">{formatCurrency(summaryStats.totalEarnings)}</p>
          </div>
          <div className="bg-yellow-50 p-4 rounded-lg">
            <p className="text-sm text-muted-foreground">Pending Deposits</p>
            <p className="text-2xl font-bold text-yellow-700">{formatCurrency(summaryStats.pendingDeposits)}</p>
          </div>
          <div className="bg-purple-50 p-4 rounded-lg">
            <p className="text-sm text-muted-foreground">Net Balance</p>
            <p className="text-2xl font-bold text-purple-700">{formatCurrency(summaryStats.netBalance)}</p>
          </div>
        </div>

        {transactions.length === 0 ? (
          <div className="text-center py-8">
            <p className="text-muted-foreground">No transactions found</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Amount</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Reference</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {transactions.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell>
                      {format(new Date(transaction.created_at), "MMM d, yyyy")}
                      <div className="text-xs text-muted-foreground">
                        {format(new Date(transaction.created_at), "h:mm a")}
                      </div>
                    </TableCell>
                    <TableCell>{getTypeBadge(transaction.type)}</TableCell>
                    <TableCell className={
                      transaction.type === 'market_entry' || transaction.type === 'loss' || transaction.type === 'withdrawal'
                        ? 'text-red-600'
                        : 'text-green-600'
                    }>
                      {transaction.type === 'market_entry' || transaction.type === 'loss' || transaction.type === 'withdrawal'
                        ? `-${formatCurrency(Number(transaction.amount))}`
                        : formatCurrency(Number(transaction.amount))
                      }
                    </TableCell>
                    <TableCell>{getStatusBadge(transaction.status)}</TableCell>
                    <TableCell>
                      <span className="text-sm">
                        {transaction.reference_number || '-'}
                      </span>
                      {transaction.admin_message && (
                        <div className="text-xs text-muted-foreground mt-1">
                          Note: {transaction.admin_message}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
