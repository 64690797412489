
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { formatCurrency } from "@/utils/formatters";
import { format } from "date-fns";
import { CheckCircle, XCircle } from "lucide-react";

interface Contribution {
  id: string;
  input_value: number;
  input_odds?: number;
  calculated_contribution: number;
  contribution_date: string;
  status: string;
  contribution_round: number;
  metadata?: {
    required_contribution?: number;
    previous_failed_total?: number;
    progressive_calculation?: boolean;
    odds?: number;
  };
  created_at: string;
}

interface ContributionHistoryTableProps {
  contributions: Contribution[];
  isLoading: boolean;
  maxHeight?: string;
  showProgressiveDetails?: boolean;
}

export const ContributionHistoryTable = ({ 
  contributions, 
  isLoading, 
  maxHeight,
  showProgressiveDetails = false
}: ContributionHistoryTableProps) => {
  if (isLoading) {
    return (
      <div className="space-y-2">
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-full" />
      </div>
    );
  }

  if (!contributions || contributions.length === 0) {
    return <p className="text-muted-foreground text-center py-4">No contribution history found.</p>;
  }

  const sortedContributions = [...contributions].sort((a, b) => {
    // First sort by round
    const roundDiff = a.contribution_round - b.contribution_round;
    if (roundDiff !== 0) return roundDiff;
    
    // Then sort by date (newest first if rounds are the same)
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  return (
    <div className={maxHeight ? `overflow-auto max-h-[${maxHeight}]` : ""}>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Round</TableHead>
            <TableHead>Date</TableHead>
            <TableHead>Odds Input</TableHead>
            <TableHead>Required</TableHead>
            <TableHead>Contribution</TableHead>
            {showProgressiveDetails && (
              <>
                <TableHead>Previous Failed</TableHead>
              </>
            )}
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedContributions.map((contribution) => (
            <TableRow key={contribution.id}>
              <TableCell>{contribution.contribution_round}</TableCell>
              <TableCell>
                {format(new Date(contribution.contribution_date), "MMM d, yyyy")}
              </TableCell>
              <TableCell>
                {(contribution.input_odds || contribution.metadata?.odds)
                  ? Number(contribution.input_odds || contribution.metadata?.odds).toFixed(2)
                  : "N/A"}
              </TableCell>
              <TableCell>
                {contribution.metadata?.required_contribution
                  ? formatCurrency(contribution.metadata.required_contribution)
                  : "N/A"}
              </TableCell>
              <TableCell>{formatCurrency(contribution.calculated_contribution)}</TableCell>
              {showProgressiveDetails && (
                <>
                  <TableCell>
                    {contribution.metadata?.previous_failed_total !== undefined
                      ? formatCurrency(contribution.metadata.previous_failed_total)
                      : "N/A"}
                  </TableCell>
                </>
              )}
              <TableCell>
                {contribution.status === "success" || contribution.status === "closed_successful" ? (
                  <Badge variant="success" className="flex items-center gap-1">
                    <CheckCircle className="h-3 w-3" />
                    Success
                  </Badge>
                ) : contribution.status === "failed" || contribution.status === "closed_failed" ? (
                  <Badge variant="destructive" className="flex items-center gap-1">
                    <XCircle className="h-3 w-3" />
                    Failed
                  </Badge>
                ) : (
                  <Badge variant="outline">{contribution.status}</Badge>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
