
import { useState } from "react";
import { Session } from "@supabase/supabase-js";
import { useToast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";
import { useMarketsQuery } from "./markets/use-markets-query";
import { useMarketFilters } from "./markets/use-market-filters";
import { useMarketActions } from "./markets/use-market-actions";

export const useMarketsManagement = (session: Session | null) => {
  const { toast } = useToast();
  const [attempts, setAttempts] = useState(0);
  const [lastQueryTime, setLastQueryTime] = useState<number | null>(null);

  const { data: markets, isLoading, error, refetch } = useMarketsQuery(
    session,
    { attempts, lastQueryTime },
    { setAttempts, setLastQueryTime }
  );

  const {
    searchQuery,
    selectedRegion,
    riskLevel,
    setSearchQuery,
    setSelectedRegion,
    setRiskLevel,
    filterMarkets
  } = useMarketFilters();

  const { handleParticipate } = useMarketActions(session);

  const handleRefresh = () => {
    logger.info('Manual refresh requested:', {
      attempts,
      lastQueryTime,
      userEmail: session?.user?.email,
      timestamp: new Date().toISOString()
    });
    
    refetch();
    
    toast({
      title: "Refreshing markets",
      description: "Fetching latest market data...",
    });
  };

  return {
    markets: filterMarkets(markets),
    isLoading,
    error,
    searchQuery,
    selectedRegion,
    riskLevel,
    setSearchQuery,
    setSelectedRegion,
    setRiskLevel,
    handleParticipate: async (marketId: string) => {
      const success = await handleParticipate(marketId);
      if (success) await refetch();
    },
    handleRefresh,
    refetch,
    debugInfo: {
      queryAttempts: attempts,
      lastQueryTime,
      hasSession: !!session,
      userId: session?.user?.id
    }
  };
};
