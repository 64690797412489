
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';
import { MarketErrorType } from '@/utils/error-handling/error-types';

/**
 * Centralized hook for managing market error state
 */
export const useMarketsErrorState = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [error, setError] = useState<Error | null>(null);
  const [errorType, setErrorType] = useState<MarketErrorType | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [lastErrorTime, setLastErrorTime] = useState<number | null>(null);
  const [isRecovering, setIsRecovering] = useState(false);
  
  /**
   * Clear the current error state
   */
  const clearError = useCallback(() => {
    setError(null);
    setErrorType(null);
  }, []);
  
  /**
   * Set an error with the appropriate type
   */
  const setErrorWithType = useCallback((
    newError: Error | null, 
    type: MarketErrorType | null = null
  ) => {
    setError(newError);
    
    if (newError && type) {
      setErrorType(type);
      setLastErrorTime(Date.now());
      
      logger.error('Market error registered:', {
        message: newError.message,
        type,
        timestamp: new Date().toISOString(),
        retryCount
      });
    } else if (!newError) {
      setErrorType(null);
    }
  }, [retryCount]);
  
  /**
   * Handle refresh with proper error tracking
   */
  const handleRefresh = useCallback(async (refreshFn?: () => Promise<void>) => {
    // If already recovering, prevent multiple attempts
    if (isRecovering) return;
    
    logger.info('Manually refreshing markets', { 
      retryCount: retryCount + 1,
      lastErrorTime,
      isRecovering 
    });
    
    setRetryCount(prev => prev + 1);
    setIsRecovering(true);
    
    toast({
      title: "Refreshing",
      description: "Updating market data...",
    });
    
    try {
      // Verify auth session is still valid before refetching
      const { data: { session: currentSession } } = await supabase.auth.getSession();
      if (!currentSession) {
        toast({
          title: "Session Expired",
          description: "Your session has expired. Please sign in again.",
          variant: "destructive",
        });
        navigate('/signin');
        return;
      }
      
      // Execute the refresh function if provided
      if (refreshFn) {
        await refreshFn();
        // On success, clear the error
        clearError();
      }
    } catch (refreshError) {
      // If refresh fails, update the error state
      const err = refreshError instanceof Error 
        ? refreshError 
        : new Error(String(refreshError));
        
      setErrorWithType(err, 'unknown');
      
      toast({
        title: "Refresh Failed",
        description: "Could not update market data. Please try again later.",
        variant: "destructive",
      });
    } finally {
      setIsRecovering(false);
    }
    
    return retryCount + 1;
  }, [
    retryCount, 
    navigate, 
    toast, 
    lastErrorTime, 
    isRecovering, 
    clearError, 
    setErrorWithType
  ]);

  return {
    error,
    errorType,
    retryCount,
    lastErrorTime,
    isRecovering,
    setError: setErrorWithType,
    clearError,
    handleRefresh,
    setRetryCount,
    setIsRecovering
  };
};
