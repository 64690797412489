
import { Market } from "@/types/market";

// Helper to normalize region names to ensure consistency
export const normalizeRegionName = (region: string): string => {
  // Handle common region naming inconsistencies
  if (!region) return 'Other';
  
  const regionLower = region.toLowerCase();
  
  // Map of common variations to normalized names
  const regionMappings: Record<string, string> = {
    'au': 'Australia',
    'australia': 'Australia',
    'uk': 'United Kingdom',
    'united kingdom': 'United Kingdom',
    'us': 'United States',
    'usa': 'United States',
    'united states': 'United States',
    'za': 'South Africa',
    'south africa': 'South Africa',
    'nz': 'New Zealand',
    'new zealand': 'New Zealand',
  };
  
  return regionMappings[regionLower] || region;
};

export const groupMarketsByRegion = (markets: Market[] | null): Record<string, Market[]> => {
  if (!markets) return {};
  
  const groups: Record<string, Market[]> = {};
  markets.forEach(market => {
    const normalizedRegion = normalizeRegionName(market.region || 'Other');
    if (!groups[normalizedRegion]) {
      groups[normalizedRegion] = [];
    }
    groups[normalizedRegion].push(market);
  });
  
  // Sort regions alphabetically
  return Object.keys(groups)
    .sort()
    .reduce((acc: Record<string, Market[]>, key) => {
      acc[key] = groups[key];
      return acc;
    }, {});
};
