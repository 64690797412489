
import { useEffect, useState } from "react";
import { Market } from "@/types/market";
import { logger } from "@/utils/logger";
import { getTabStrategy } from "@/utils/market/filters/tab-strategies";

export const useTabContentEffects = (
  tabValue: string,
  markets: Market[] | null,
  isLoading: boolean,
  onRefresh?: () => Promise<void>
) => {
  const [displayMarkets, setDisplayMarkets] = useState<Market[] | null>(null);
  const [hasMarkets, setHasMarkets] = useState<boolean>(false);

  // Filter markets appropriately based on the active tab
  useEffect(() => {
    if (isLoading) {
      return;
    }
    
    if (!markets) {
      setDisplayMarkets(null);
      setHasMarkets(false);
      return;
    }

    // Get the appropriate tab strategy
    const tabStrategy = getTabStrategy(tabValue);
    
    // Count markets with participation
    const participatingCount = markets.filter(m => m.isParticipating === true).length;
    
    // Apply the tab strategy
    const filteredMarkets = tabStrategy(markets);
    
    logger.info(`Filtering markets for tab: ${tabValue}`, {
      totalMarkets: markets.length, 
      filtered: filteredMarkets.length,
      participatingCount,
      statuses: markets.map(m => m.status).slice(0, 5) // Log first 5 statuses for debugging
    });
    
    // Check for inconsistencies on joined tab
    if (tabValue === 'joined' && participatingCount > 0 && filteredMarkets.length === 0) {
      logger.warn(`Potential data inconsistency in joined tab: ${participatingCount} participating markets but none displayed`);
      
      // Consider triggering a refresh here
      if (onRefresh) {
        logger.info('Refreshing data due to joined tab inconsistency');
        onRefresh().catch(e => logger.error('Error refreshing data', e));
      }
    }
    
    setDisplayMarkets(filteredMarkets);
    setHasMarkets(filteredMarkets.length > 0);
    
  }, [markets, tabValue, isLoading, onRefresh]);

  return {
    displayMarkets,
    hasMarkets
  };
};
