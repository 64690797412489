
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@/components/ui/button";
import { Menu, BadgeHelp } from "lucide-react";
import LanguageSelector from "./navigation/LanguageSelector";
import NotificationsMenu from "./navigation/NotificationsMenu";
import UserMenu from "./navigation/UserMenu";
import MobileMenu from "./navigation/MobileMenu";
import ThemeToggle from "./navigation/ThemeToggle";

const Navigation = () => {
  const navigate = useNavigate();
  const { session } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-muted/95 backdrop-blur-md border-b border-primary/10 transition-colors">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <div 
            className="flex items-center transform hover:scale-105 transition-all duration-300 cursor-pointer group" 
            onClick={() => {
              navigate("/");
              setIsMobileMenuOpen(false);
            }}
          >
            <img
              src="/lovable-uploads/091b8c7b-f5d9-4803-8e29-f8a0e5e19c20.png"
              alt="The Colosseum"
              className="h-16 w-auto object-contain filter brightness-200 group-hover:brightness-150 transition-all duration-300"
            />
            <div className="flex items-center ml-2 text-xs px-2 py-1 bg-primary/10 text-primary rounded-md border border-primary/20">
              <BadgeHelp className="h-3 w-3 mr-1 text-primary" />
              <span>BETA</span>
            </div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            <div className="flex items-center space-x-3 bg-card/30 backdrop-blur-sm px-4 py-2 rounded-full border border-primary/10">
              <ThemeToggle />
              <LanguageSelector />
              {session && <NotificationsMenu />}
              <UserMenu />
            </div>
          </div>

          {/* Mobile Menu Button */}
          <Button
            variant="ghost"
            size="icon"
            className="md:hidden hover:bg-primary/10 transition-colors duration-200 rounded-full"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <Menu className="h-6 w-6 text-primary" />
          </Button>
        </div>

        {/* Mobile Menu */}
        <MobileMenu 
          isOpen={isMobileMenuOpen}
          onClose={() => setIsMobileMenuOpen(false)}
        />
      </div>
    </nav>
  );
};

export default Navigation;
