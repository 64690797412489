
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import { FinancialSettings } from '@/types/financial';

export const useFinancialSettings = () => {
  const [settings, setSettings] = useState<FinancialSettings | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { toast } = useToast();

  const fetchSettings = async () => {
    try {
      setLoading(true);
      
      const { data, error: fetchError } = await supabase.rpc('get_financial_settings');
      
      if (fetchError) {
        throw new Error(fetchError.message);
      }
      
      if (data && data.length > 0) {
        setSettings(data[0]);
      }
    } catch (err) {
      console.error('Error fetching financial settings:', err);
      setError(err instanceof Error ? err : new Error('Failed to fetch financial settings'));
      
      toast({
        title: 'Error',
        description: 'Failed to load financial settings. Please try again later.',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateSettings = async (baseTarget: number, adjustmentFactor: number) => {
    try {
      setLoading(true);
      
      const { error: updateError } = await supabase.rpc('set_financial_settings', {
        p_base_target: baseTarget,
        p_adjustment_factor: adjustmentFactor
      });

      if (updateError) {
        throw new Error(updateError.message);
      }
      
      // Update local state with the new values
      setSettings({
        base_target: baseTarget,
        adjustment_factor: adjustmentFactor
      });
      
      toast({
        title: 'Settings Updated',
        description: 'Financial settings have been updated successfully.',
      });
      
      return true;
    } catch (err) {
      console.error('Error updating financial settings:', err);
      
      toast({
        title: 'Update Failed',
        description: err instanceof Error ? err.message : 'Failed to update financial settings',
        variant: 'destructive',
      });
      
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch of settings
  useEffect(() => {
    fetchSettings();
  }, []);

  return {
    settings,
    loading,
    error,
    fetchSettings,
    updateSettings
  };
};
