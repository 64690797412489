
import { Button } from "@/components/ui/button";
import { RefreshCw, Search, Package2, Clock, Users } from "lucide-react";
import { Card } from "@/components/ui/card";

interface EmptyMarketsListProps {
  tabContext: string;
  onRefresh?: () => Promise<void>;
  filterActive?: boolean;
}

export const EmptyMarketsList = ({ tabContext, onRefresh, filterActive }: EmptyMarketsListProps) => {
  const getIllustration = () => {
    switch (tabContext) {
      case 'available':
        return <Package2 className="h-16 w-16 text-primary opacity-30" />;
      case 'joined':
        return <Users className="h-16 w-16 text-primary opacity-30" />;
      case 'closed':
        return <Clock className="h-16 w-16 text-primary opacity-30" />;
      default:
        return <Search className="h-16 w-16 text-primary opacity-30" />;
    }
  };

  const getMessage = () => {
    // If filters are active, show a more specific message
    if (filterActive) {
      return {
        title: "No Markets Found",
        message: "No markets match your current filters. Try adjusting your search criteria or filters."
      };
    }

    switch (tabContext) {
      case 'available':
        return {
          title: "No Available Markets",
          message: "There are currently no available markets that match your criteria."
        };
      case 'joined':
        return {
          title: "No Joined Markets",
          message: "You haven't joined any markets yet. Browse available markets to get started."
        };
      case 'closed':
        return {
          title: "No Closed Markets",
          message: "There are no closed markets in your portfolio yet."
        };
      default:
        return {
          title: "No Markets Found",
          message: "No markets were found matching your current filters."
        };
    }
  };

  const content = getMessage();

  return (
    <Card className="py-16 flex flex-col items-center justify-center text-center border-dashed border-primary/20 bg-card/50">
      <div className="mb-6">
        {getIllustration()}
      </div>
      <h3 className="text-xl font-semibold mb-2 text-zinc-800 dark:text-zinc-100">{content.title}</h3>
      <p className="text-zinc-600 dark:text-zinc-400 max-w-md mb-6">
        {content.message}
      </p>
      
      {onRefresh && (
        <Button variant="outline" size="sm" onClick={onRefresh} className="gap-2 text-zinc-700 dark:text-zinc-300">
          <RefreshCw className="h-4 w-4" />
          Refresh
        </Button>
      )}
    </Card>
  );
};
