
import { Session } from '@supabase/supabase-js';
import { useToast } from '@/hooks/use-toast';
import { logger } from '@/utils/logger';
import { useQueryClient } from '@tanstack/react-query';
import { Market } from '@/types/market';
import { useState, useCallback, useRef } from 'react';
import { joinMarket } from '@/utils/market/joinMarket';

export const useMarketsHandlers = (
  session: Session | null,
  refetch: () => Promise<void>
) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [lastRefreshTime, setLastRefreshTime] = useState<number>(0);
  const lastOperationTime = useRef<Record<string, number>>({});
  
  const scheduleRefresh = async () => {
    const now = Date.now();
    setLastRefreshTime(now);
    
    if (now - lastRefreshTime < 2000) {
      logger.info('Skipping refresh due to debounce', {
        timeSinceLastRefresh: now - lastRefreshTime
      });
      return;
    }
    
    try {
      logger.info('Scheduled refresh starting');
      setIsProcessing(true);
      
      queryClient.invalidateQueries({ queryKey: ['markets'] });
      await refetch();
      
      logger.info('Scheduled refresh completed successfully');
    } catch (error) {
      logger.error('Error during scheduled refresh:', error);
      
      setTimeout(async () => {
        try {
          await refetch();
          logger.info('Retry refresh completed');
        } catch (retryError) {
          logger.error('Retry refresh also failed:', retryError);
        }
      }, 2000);
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Ensure this returns a function with NO arguments
  const handleContributionSubmitted = async (): Promise<void> => {
    try {
      await scheduleRefresh();
    } catch (error) {
      logger.error('Error in handleContributionSubmitted:', error);
    }
  };
  
  // Simplify this function to avoid database permissions issues
  const handleCloseTimeUpdate = async (marketId: string, closeTime: string): Promise<void> => {
    try {
      if (!session?.user?.id) {
        toast({
          title: "Authentication Required",
          description: "You must be signed in to update close times",
          variant: "destructive"
        });
        return;
      }
      
      logger.info('Setting close time for market:', {
        marketId,
        closeTime
      });
      
      // We'll simply pass this to the join function to handle centrally
      // and avoid direct database access which is causing permission errors
    } catch (error) {
      logger.error('Error in handleCloseTimeUpdate:', error);
    }
  };
  
  // Helper to get markets from cache
  const getMarketsFromCache = useCallback(() => {
    return queryClient.getQueryData<Market[]>(['markets']) || [];
  }, [queryClient]);
  
  const handleParticipate = async (marketId: string, providedMarkets?: Market[], customSettings?: { 
    targetEarnings?: number, 
    closeTime?: string 
  }): Promise<boolean> => {
    try {
      if (!marketId) {
        logger.error('Attempted to join market with empty marketId');
        toast({
          title: "Invalid market",
          description: "Cannot join market: Invalid market ID",
          variant: "destructive"
        });
        return false;
      }
      
      // Basic throttling to prevent multiple simultaneous requests
      const now = Date.now();
      const lastOperationForMarket = lastOperationTime.current[marketId] || 0;
      
      if (now - lastOperationForMarket < 3000) {
        logger.info('Rate limiting participation request', {
          marketId,
          timeSinceLastOperation: now - lastOperationForMarket
        });
        toast({
          title: "Please wait",
          description: "Please wait a moment before trying again",
          variant: "default"
        });
        return false;
      }
      
      lastOperationTime.current = {
        ...lastOperationTime.current,
        [marketId]: now
      };
      
      // Prevent concurrent operations
      if (isProcessing) {
        logger.info('Ignoring participation request while another operation is in progress');
        toast({
          title: "Please wait",
          description: "Another operation is currently in progress",
          variant: "default"
        });
        return false;
      }
      
      // Authentication check (required for the API call)
      if (!session?.user?.id) {
        logger.error('No session or user ID available for participation');
        toast({
          title: "Authentication Required",
          description: "You must be signed in to join markets",
          variant: "destructive"
        });
        return false;
      }
      
      setIsProcessing(true);
      
      // Set default close time if not provided
      if (!customSettings?.closeTime) {
        const defaultCloseTime = new Date(now + 24 * 60 * 60 * 1000);
        if (!customSettings) {
          customSettings = {};
        }
        customSettings.closeTime = defaultCloseTime.toISOString();
        
        logger.info('Setting default close time for market:', {
          marketId,
          closeTime: customSettings.closeTime,
          isDefault: true
        });
      }
      
      // Get markets from cache if not provided
      const marketsToUse = providedMarkets || getMarketsFromCache();
      
      // Log market data availability
      const targetMarket = marketsToUse.find(m => m.id === marketId);
      logger.info('Market data for participation:', {
        marketId,
        marketFound: !!targetMarket,
        marketsCount: marketsToUse.length,
        userId: session.user.id
      });
      
      // Always call joinMarket directly - no validation will be done
      // This ensures the request is always forwarded to Make.com
      const result = await joinMarket(session.user.id, marketId, customSettings);
      
      if (result.success) {
        toast({
          title: "Request Sent",
          description: "Your participation request has been sent to Make.com for processing.",
        });
        
        // Switch to joined tab UI
        window.dispatchEvent(new CustomEvent('switchToJoinedTab'));
        
        // Schedule a refresh to ensure we have the latest data
        setTimeout(() => {
          refetch().catch(e => logger.error('Error in refresh after participation:', e));
        }, 1000);
        
        return true;
      } else {
        logger.error('Failed to join market:', {
          marketId,
          error: result.error
        });
        
        toast({
          title: "Participation Error",
          description: result.error || "Failed to join market. Please try again.",
          variant: "destructive"
        });
        return false;
      }
    } catch (error) {
      logger.error('Error in handleParticipate:', error);
      toast({
        title: "Participation Error",
        description: "Failed to join the market. Please try again.",
        variant: "destructive"
      });
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    handleContributionSubmitted,
    handleCloseTimeUpdate,
    handleParticipate,
    isProcessing
  };
};
