
import { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Download, FileSpreadsheet, FileText } from "lucide-react";
import { useAdminFinancials } from "@/hooks/admin/use-admin-financials";

interface ExportMenuProps {
  selectedYear: number;
  selectedMonth: number;
}

export const ExportMenu: React.FC<ExportMenuProps> = ({
  selectedYear,
  selectedMonth
}) => {
  const { toast } = useToast();
  const { exportFinancialData } = useAdminFinancials();
  
  const handleExport = (format: 'csv' | 'pdf') => {
    if (format === 'csv') {
      exportFinancialData();
    } else {
      toast({
        title: "Export Failed",
        description: "PDF export is not yet implemented",
        variant: "destructive",
      });
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline"
          className="gap-2 mb-2 sm:mb-0"
        >
          <Download className="h-4 w-4" />
          Export Data
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => handleExport('csv')}>
          <FileSpreadsheet className="h-4 w-4 mr-2" />
          Export to CSV
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleExport('pdf')}>
          <FileText className="h-4 w-4 mr-2" />
          Export to PDF
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
