
import { Shield, User } from "lucide-react";

interface SignInHeaderProps {
  isAdmin: boolean;
  isLoading: boolean;
  isCheckingAdmin: boolean;
}

export const SignInHeader = ({ isAdmin, isLoading, isCheckingAdmin }: SignInHeaderProps) => {
  return (
    <div className="text-center">
      {isAdmin ? (
        <Shield className={`w-12 h-12 mx-auto text-primary mb-4 ${isLoading || isCheckingAdmin ? 'animate-pulse' : ''}`} />
      ) : (
        <User className={`w-12 h-12 mx-auto text-primary mb-4 ${isLoading || isCheckingAdmin ? 'animate-pulse' : ''}`} />
      )}
      <h2 className="text-3xl font-bold text-primary">
        {isAdmin ? "Admin Portal" : "Welcome Back"}
      </h2>
      <p className="mt-2 text-muted-foreground">
        {isAdmin ? "Sign in to access admin dashboard" : "Sign in to your account"}
      </p>
    </div>
  );
};
