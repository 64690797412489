
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { MemberFinancials } from "@/components/admin/users/MemberFinancials";
import { FinancialTargetConfig } from "@/components/admin/financials/FinancialTargetConfig";
import { RegionalTargetSettings } from "@/components/admin/financials/RegionalTargetSettings";
import { FinancialSettings, RegionalSettings } from '@/types/financial';

interface FinancialsTabProps {
  settings: FinancialSettings | null;
  regionalSettings: RegionalSettings[];
  loading: boolean;
  isLoading: boolean;
  updateSettings: (baseTarget: number, adjustmentFactor: number) => Promise<any>;
  handleUpdateRegionalSettings: (regionData: any) => Promise<any>;
}

export const FinancialsTab: React.FC<FinancialsTabProps> = ({
  settings,
  regionalSettings,
  loading,
  isLoading,
  updateSettings,
  handleUpdateRegionalSettings
}) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
        <div className="xl:col-span-2">
          <MemberFinancials />
        </div>
        <div className="space-y-6">
          <Card className="border-primary/20 shadow-lg hover:shadow-xl transition-all">
            <CardHeader className="bg-gradient-to-br from-card to-muted/30 rounded-t-lg pb-4">
              <CardTitle className="text-xl text-primary">Financial Targets Configuration</CardTitle>
              <CardDescription>
                Configure base targets and adjustment factors
              </CardDescription>
            </CardHeader>
            <CardContent className="pt-6">
              {settings && (
                <FinancialTargetConfig 
                  currentSettings={settings}
                  onSettingsUpdated={updateSettings}
                />
              )}
            </CardContent>
          </Card>
          
          <Card className="border-primary/20 shadow-lg hover:shadow-xl transition-all">
            <CardHeader className="bg-gradient-to-br from-card to-muted/30 rounded-t-lg pb-4">
              <CardTitle className="text-xl text-primary">Regional Target Settings</CardTitle>
              <CardDescription>
                Configure region-specific target parameters
              </CardDescription>
            </CardHeader>
            <CardContent className="pt-6">
              <RegionalTargetSettings 
                regions={regionalSettings.map(rs => ({
                  region: rs.region,
                  baseTarget: rs.baseTarget,
                  adjustment: rs.adjustment,
                  adjustmentFactor: rs.adjustmentFactor
                }))}
                isLoading={isLoading}
                onSaveRegion={handleUpdateRegionalSettings}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
