
import React from 'react';
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { ShieldAlert } from "lucide-react";

const MarketsSessionError: React.FC = () => {
  return (
    <div className="p-6">
      <Alert variant="destructive" className="mb-6">
        <ShieldAlert className="h-4 w-4" />
        <AlertTitle>Authentication Required</AlertTitle>
        <AlertDescription>
          You need to be signed in to view markets. Redirecting to sign in page...
        </AlertDescription>
      </Alert>
    </div>
  );
};

export default MarketsSessionError;
