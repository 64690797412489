
import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { FinancialSettings } from "@/types/financial";

interface FinancialTargetConfigProps {
  currentSettings: FinancialSettings;
  onSettingsUpdated: (baseTarget: number, adjustmentFactor: number) => Promise<boolean>;
}

export const FinancialTargetConfig: React.FC<FinancialTargetConfigProps> = ({
  currentSettings,
  onSettingsUpdated
}) => {
  const [baseTarget, setBaseTarget] = useState<number>(currentSettings?.base_target || 5000);
  const [adjustmentFactor, setAdjustmentFactor] = useState<number>(currentSettings?.adjustment_factor || 1.07);
  const [loading, setLoading] = useState(false);
  
  // Update local state when settings change
  useEffect(() => {
    if (currentSettings) {
      setBaseTarget(currentSettings.base_target || 5000);
      setAdjustmentFactor(currentSettings.adjustment_factor || 1.07);
    }
  }, [currentSettings]);
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    setLoading(true);
    try {
      const success = await onSettingsUpdated(baseTarget, adjustmentFactor);
      
      if (!success) {
        // Reset to current settings on failure
        setBaseTarget(currentSettings.base_target);
        setAdjustmentFactor(currentSettings.adjustment_factor);
      }
    } catch (error) {
      console.error('Error updating financial targets:', error);
    } finally {
      setLoading(false);
    }
  };
  
  if (!currentSettings) {
    return <Skeleton className="h-48 w-full" />;
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="baseTarget">Base Target Amount</Label>
          <Input 
            id="baseTarget" 
            type="number" 
            min={1000} 
            step={100}
            value={baseTarget} 
            onChange={(e) => setBaseTarget(Number(e.target.value))} 
          />
          <p className="text-xs text-muted-foreground">
            The standard target amount for markets before regional adjustments
          </p>
        </div>
        
        <div className="space-y-2">
          <Label htmlFor="adjustmentFactor">Adjustment Factor</Label>
          <Input 
            id="adjustmentFactor" 
            type="number" 
            min={1} 
            max={2} 
            step={0.01} 
            value={adjustmentFactor} 
            onChange={(e) => setAdjustmentFactor(Number(e.target.value))} 
          />
          <p className="text-xs text-muted-foreground">
            The multiplier applied to adjusted targets
          </p>
        </div>
      </div>
      
      <div className="mt-4">
        <Button type="submit" disabled={loading}>
          {loading ? 'Updating...' : 'Update Financial Targets'}
        </Button>
      </div>
    </form>
  );
};
