
import React from 'react';
import { DashboardSidebar } from "@/components/DashboardSidebar";
import MarketsLoading from "@/components/markets/loading/MarketsLoading";
import MarketsSessionError from "@/components/markets/error/MarketsSessionError";
import { Session } from '@supabase/supabase-js';

interface MarketsAuthCheckProps {
  session: Session | null;
  authLoading: boolean;
  children: React.ReactNode;
}

const MarketsAuthCheck: React.FC<MarketsAuthCheckProps> = ({ 
  session, 
  authLoading, 
  children 
}) => {
  if (authLoading) {
    return (
      <DashboardSidebar>
        <MarketsLoading />
      </DashboardSidebar>
    );
  }

  if (!session) {
    return (
      <DashboardSidebar>
        <MarketsSessionError />
      </DashboardSidebar>
    );
  }

  return <>{children}</>;
};

export default MarketsAuthCheck;
