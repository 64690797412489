
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

interface MarketApprovalToggleProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

export const MarketApprovalToggle = ({ checked, onCheckedChange }: MarketApprovalToggleProps) => {
  return (
    <div className="flex items-center justify-between">
      <Label htmlFor="requiresApproval">Require Member Approval</Label>
      <Switch
        id="requiresApproval"
        checked={checked}
        onCheckedChange={onCheckedChange}
      />
    </div>
  );
};
