
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { toast } from "sonner";
import { normalizeRegionCode, getSupportedRegions } from "./formatters";

/**
 * Synchronizes data from regional_targets to all related tables
 * to ensure consistent values across the system
 */
export const syncRegionalTablesFromTargets = async (
  region: string,
  baseTarget: number,
  adjustment: number,
  factor: number,
  userId?: string // Add optional userId parameter to target specific members
): Promise<boolean> => {
  try {
    // Normalize region code to upstream format
    const normalizedRegion = normalizeRegionCode(region);
    
    logger.info(`Syncing from targets to regional tables for ${normalizedRegion} (original: ${region})`, {
      baseTarget,
      adjustment,
      factor,
      normalizedRegion,
      forSpecificUserId: userId || 'system-wide'
    });
    
    // 1. First update the regional_settings table (only if not targeting specific user)
    if (!userId) {
      const { error: settingsError } = await supabase
        .from('regional_settings')
        .upsert({
          region_code: normalizedRegion,
          base_target: baseTarget,
          adjustment: adjustment,
          adjustment_factor: factor,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'region_code'
        });
        
      if (settingsError) {
        logger.error(`Error updating regional_settings for ${normalizedRegion}:`, settingsError);
        throw settingsError;
      }
    }
    
    // 2. Update markets with this region (only if not targeting specific user)
    if (!userId) {
      const { error: marketsError } = await supabase
        .from('markets')
        .update({
          base_value: adjustment,
          adjustment_factor: factor,
          target_value: baseTarget,
          current_value: baseTarget + (adjustment / factor),
          updated_at: new Date().toISOString()
        })
        .or(`region.eq.${normalizedRegion},region.ilike.%${normalizedRegion}%,region.ilike.%${region}%`);
      
      if (marketsError) {
        logger.warn(`Warning: Could not update markets for region ${normalizedRegion}:`, marketsError);
        // Continue despite error - this is not fatal
      }
    }
    
    // 3. Update system default settings in regional_targets (only if not targeting specific user)
    if (!userId) {
      const { error: systemTargetError } = await supabase
        .from('regional_targets')
        .upsert({
          user_id: '00000000-0000-0000-0000-000000000000', // System user ID
          region_code: normalizedRegion,
          base_target: baseTarget,
          adjustment: adjustment,
          factor: factor,
          target_value: baseTarget + (adjustment / factor),
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,region_code'
        });
      
      if (systemTargetError) {
        logger.warn(`Warning: Could not update system defaults for ${normalizedRegion}:`, systemTargetError);
        // Continue despite error - this is not fatal
      }
    }
    
    // 4. Update user specific target or all member targets
    if (userId) {
      // Update only the specified user's target for this region
      const { error: userTargetError } = await supabase
        .from('regional_targets')
        .upsert({
          user_id: userId,
          region_code: normalizedRegion,
          base_target: baseTarget,
          adjustment: adjustment,
          factor: factor,
          target_value: baseTarget + (adjustment / factor),
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,region_code'
        });
        
      if (userTargetError) {
        logger.error(`Error updating target for user ${userId}:`, userTargetError);
        throw userTargetError;
      }
      
      // Emit a custom event for single member target update
      window.dispatchEvent(new CustomEvent('member-target-updated', {
        detail: {
          userId: userId,
          region: normalizedRegion,
          originalRegion: region,
          timestamp: new Date().toISOString()
        }
      }));
      
      logger.info(`Updated target for member ${userId} in region ${normalizedRegion}`);
    } else {
      // Update all member targets for this region (only when not targeting specific user)
      try {
        const { data: userData, error: userError } = await supabase
          .from('profiles')
          .select('id')
          .eq('is_admin', false);
          
        if (!userError && userData) {
          // Batch update all user targets for this region
          await Promise.all(userData.map(async (user) => {
            const { error: userTargetError } = await supabase
              .from('regional_targets')
              .upsert({
                user_id: user.id,
                region_code: normalizedRegion,
                base_target: baseTarget,
                adjustment: adjustment,
                factor: factor,
                target_value: baseTarget + (adjustment / factor),
                updated_at: new Date().toISOString()
              }, {
                onConflict: 'user_id,region_code'
              });
              
            if (userTargetError) {
              logger.warn(`Warning: Could not update target for user ${user.id}:`, userTargetError);
            }
          }));
          
          // Emit a custom event for member target updates
          window.dispatchEvent(new CustomEvent('member-targets-synced', {
            detail: {
              region: normalizedRegion,
              originalRegion: region,
              members: userData.map(u => u.id),
              timestamp: new Date().toISOString()
            }
          }));
          
          logger.info(`Updated targets for ${userData.length} members in region ${normalizedRegion}`);
        }
      } catch (memberErr) {
        logger.warn(`Warning: Could not update member targets:`, memberErr);
        // Continue despite error - this is not fatal
      }
    }
    
    // 5. Emit custom events for different components that need to update
    if (userId) {
      // For single user target update
      window.dispatchEvent(new CustomEvent('user-target-updated', {
        detail: { 
          userId: userId,
          region: normalizedRegion, 
          originalRegion: region,
          timestamp: new Date().toISOString(),
          settings: {
            baseTarget,
            adjustment,
            factor,
            targetValue: baseTarget + (adjustment / factor)
          }
        }
      }));
    } else {
      // For regional settings components (system-wide update)
      window.dispatchEvent(new CustomEvent('regional-settings-updated', {
        detail: { 
          region: normalizedRegion, 
          originalRegion: region,
          timestamp: new Date().toISOString(),
          settings: {
            baseTarget,
            adjustment,
            factor,
            targetValue: baseTarget + (adjustment / factor)
          }
        }
      }));
      
      // For market components (system-wide update)
      window.dispatchEvent(new CustomEvent('market-targets-updated', {
        detail: { 
          region: normalizedRegion, 
          originalRegion: region,
          timestamp: new Date().toISOString(),
          settings: {
            baseTarget,
            adjustment,
            factor
          }
        }
      }));
      
      // For regional targets components (system-wide update)
      window.dispatchEvent(new CustomEvent('regional-targets-updated', {
        detail: { 
          region: normalizedRegion, 
          originalRegion: region,
          timestamp: new Date().toISOString()
        }
      }));
      
      // For calculator components (system-wide update)
      window.dispatchEvent(new CustomEvent('calculator-targets-synced', {
        detail: {
          complete: true,
          regions: [normalizedRegion],
          originalRegions: [region],
          timestamp: new Date().toISOString()
        }
      }));
      
      // Force a comprehensive system refresh (system-wide update)
      window.dispatchEvent(new CustomEvent('system-targets-updated', {
        detail: {
          timestamp: new Date().toISOString(),
          forceRefresh: true
        }
      }));
    }
    
    if (userId) {
      toast.success(`Target settings for ${normalizedRegion} updated successfully for this member`);
    } else {
      toast.success(`Regional settings for ${normalizedRegion} updated successfully for all members`);
    }
    
    logger.info(`Successfully synced regional tables for ${normalizedRegion}${userId ? ` (user: ${userId})` : ''}`);
    
    return true;
  } catch (error) {
    const errorMsg = error instanceof Error ? error.message : 'Unknown error';
    logger.error('Error in syncRegionalTablesFromTargets:', error);
    toast.error(`Failed to sync regional tables: ${errorMsg}`);
    return false;
  }
};

/**
 * Synchronizes data from regional_settings to regional_targets table
 * (Opposite direction of syncRegionalTablesFromTargets)
 */
export const syncRegionalTablesFromSettings = async (
  region: string,
  baseTarget: number,
  adjustment: number,
  factor: number
): Promise<boolean> => {
  try {
    // Normalize region code to uppercase
    const normalizedRegion = normalizeRegionCode(region);
    
    logger.info(`Syncing from settings to regional targets for ${normalizedRegion}`, {
      baseTarget,
      adjustment,
      factor
    });
    
    // Update the system default target
    const { error } = await supabase
      .from('regional_targets')
      .upsert({
        user_id: '00000000-0000-0000-0000-000000000000', // System user ID
        region_code: normalizedRegion,
        base_target: baseTarget,
        adjustment: adjustment,
        factor: factor,
        target_value: baseTarget + (adjustment / factor),
        updated_at: new Date().toISOString()
      }, {
        onConflict: 'user_id,region_code'
      });
      
    if (error) {
      logger.error(`Error updating regional_targets for ${normalizedRegion}:`, error);
      throw error;
    }
    
    // Emit events to update components
    window.dispatchEvent(new CustomEvent('regional-targets-updated', {
      detail: { 
        region: normalizedRegion, 
        timestamp: new Date().toISOString(),
        settings: {
          baseTarget,
          adjustment,
          factor
        }
      }
    }));
    
    return true;
  } catch (error) {
    logger.error('Error in syncRegionalTablesFromSettings:', error);
    toast.error(`Failed to sync from settings: ${error instanceof Error ? error.message : 'Unknown error'}`);
    return false;
  }
};
