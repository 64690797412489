
import React from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { useAdminAuth } from '@/hooks/auth/useAdminAuth';
import { Loader2 } from 'lucide-react';

interface AdminProtectedRouteProps {
  children: React.ReactNode;
  redirectTo?: string;
}

export const AdminProtectedRoute: React.FC<AdminProtectedRouteProps> = ({ 
  children,
  redirectTo = '/dashboard'
}) => {
  const { session, isLoading: authLoading } = useAuth();
  const { isVerifyingAdmin, isAdmin, adminVerificationError } = useAdminAuth(session, authLoading, redirectTo);
  
  // Show loading screen during authentication or admin verification
  if (authLoading || isVerifyingAdmin) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[60vh]">
        <Loader2 className="h-8 w-8 animate-spin text-primary mb-4" />
        <p className="text-lg font-medium">Verifying administrator access...</p>
        <p className="text-sm text-muted-foreground mt-2">
          This may take a moment. Please wait.
        </p>
      </div>
    );
  }
  
  // Show error message if verification failed
  if (adminVerificationError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[60vh] max-w-md mx-auto">
        <div className="bg-destructive/10 p-6 rounded-lg text-center">
          <h2 className="text-xl font-bold text-destructive mb-2">Access Verification Failed</h2>
          <p className="text-muted-foreground">
            {adminVerificationError}
          </p>
          <p className="mt-4 text-sm">
            If you believe this is an error, please contact support.
          </p>
        </div>
      </div>
    );
  }
  
  // If admin is verified, render children
  if (isAdmin) {
    return <>{children}</>;
  }
  
  // This should not be reached as useAdminAuth should redirect,
  // but it's here as an extra safeguard
  return null;
};
