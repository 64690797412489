
import { useCallback } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { AuthDispatch } from "@/types/auth";

export const useAuthActions = (dispatch: AuthDispatch) => {
  const { toast } = useToast();

  // Sign out with proper cleanup
  const signOut = useCallback(async () => {
    try {
      dispatch({ type: 'SET_SIGNING_OUT', payload: true });
      logger.info('User initiated sign out');
      
      const { error } = await supabase.auth.signOut();
      
      if (error) throw error;
      
      // Clear any local storage items used by the app
      localStorage.removeItem('app-session');
      localStorage.removeItem('userRole');
      
      // Update auth state
      dispatch({ type: 'SIGN_OUT' });
      
      // Use window.location for a hard redirect to ensure complete state reset
      window.location.href = '/signin';
      
      logger.info('Sign out successful');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred during sign out';
      logger.error('Sign out failed:', { error: errorMessage });
      
      toast({
        title: "Sign Out Failed",
        description: "There was an issue signing you out. Please try again.",
        variant: "destructive",
      });
      
      // Even if there's an error, we should try to clean up the local state
      dispatch({ type: 'SIGN_OUT' });
      
      // Redirect to sign in page
      window.location.href = '/signin';
    } finally {
      dispatch({ type: 'SET_SIGNING_OUT', payload: false });
    }
  }, [dispatch, toast]);

  // Hard sign out that bypasses API and just clears state
  const hardSignOut = useCallback(async () => {
    try {
      logger.info('Hard sign out initiated');
      
      // Directly clear the session without waiting for API
      localStorage.removeItem('app-session');
      localStorage.removeItem('userRole');
      sessionStorage.clear();
      
      // Force reload supabase internal auth state
      await supabase.auth.signOut({ scope: 'local' });
      
      // Update state
      dispatch({ type: 'SIGN_OUT' });
      
      // Use window.location for a hard redirect to ensure complete state reset
      window.location.href = '/signin';
      
      logger.info('Hard sign out completed');
    } catch (error) {
      logger.error('Hard sign out error:', error);
      
      // Still attempt to clean up local state and redirect
      dispatch({ type: 'SIGN_OUT' });
      window.location.href = '/signin';
    } finally {
      dispatch({ type: 'SET_SIGNING_OUT', payload: false });
    }
  }, [dispatch]);

  // Check and update admin status
  const checkAndUpdateAdminStatus = useCallback(async () => {
    try {
      logger.info('Checking admin status');
      
      // Use the secure function to check admin status
      const { data: isAdmin, error } = await supabase.rpc('is_admin_email_direct');
      
      if (error) {
        logger.error('Admin status check failed:', error);
        throw error;
      }
      
      dispatch({ type: 'SET_ADMIN', payload: !!isAdmin });
      logger.info('Admin status updated:', { isAdmin });
      
      return !!isAdmin;
    } catch (error) {
      logger.error('Failed to check admin status:', error);
      return false;
    }
  }, [dispatch]);

  return { signOut, hardSignOut, checkAndUpdateAdminStatus };
};
