
import { useState } from "react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Globe } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

const LanguageSelector = () => {
  const { toast } = useToast();
  const [currentLanguage, setCurrentLanguage] = useState("English");
  
  const handleLanguageChange = (language: string) => {
    setCurrentLanguage(language);
    toast({
      title: "Language Changed",
      description: `Language has been changed to ${language}`
    });
  };
  
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="hover:bg-primary/10 transition-colors duration-200 rounded-full">
          <Globe className="h-5 w-5 text-zinc-600 dark:text-zinc-400" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-card/95 backdrop-blur-sm border border-primary/20">
        <DropdownMenuItem onClick={() => handleLanguageChange("English")} className="cursor-pointer hover:bg-primary/10">
          English
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleLanguageChange("Spanish")} className="cursor-pointer hover:bg-primary/10">
          Spanish
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleLanguageChange("French")} className="cursor-pointer hover:bg-primary/10">
          French
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageSelector;
