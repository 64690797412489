
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import * as z from "zod";
import { marketFormSchema } from "../config/formConfig";

interface NumericFieldsProps {
  form: UseFormReturn<z.infer<typeof marketFormSchema>>;
  fields: string[];
}

export function NumericFields({ form, fields }: NumericFieldsProps) {
  return (
    <>
      {fields.includes("target_value") && (
        <FormField
          control={form.control}
          name="target_value"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Target Value</FormLabel>
              <FormControl>
                <Input 
                  {...field} 
                  type="number" 
                  onChange={e => field.onChange(Number(e.target.value))}
                  min={0}
                  placeholder="Enter target value"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      
      {fields.includes("entry_amount") && (
        <FormField
          control={form.control}
          name="entry_amount"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Entry Amount</FormLabel>
              <FormControl>
                <Input 
                  {...field} 
                  type="number" 
                  onChange={e => field.onChange(Number(e.target.value))}
                  min={0}
                  placeholder="Enter entry amount"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      
      {fields.includes("expected_roi") && (
        <FormField
          control={form.control}
          name="expected_roi"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Expected ROI (%)</FormLabel>
              <FormControl>
                <Input 
                  {...field} 
                  type="number" 
                  onChange={e => field.onChange(Number(e.target.value))}
                  min={0}
                  step="0.1"
                  placeholder="Enter expected ROI percentage"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      
      {fields.includes("max_participants") && (
        <FormField
          control={form.control}
          name="max_participants"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Max Participants</FormLabel>
              <FormControl>
                <Input 
                  {...field} 
                  type="number" 
                  onChange={e => field.onChange(Number(e.target.value))}
                  min={1}
                  placeholder="Enter maximum participants"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      
      {fields.includes("max_participations_per_user") && (
        <FormField
          control={form.control}
          name="max_participations_per_user"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Maximum Participations per User</FormLabel>
              <FormControl>
                <Input 
                  {...field} 
                  type="number" 
                  onChange={e => field.onChange(Number(e.target.value))}
                  min={1}
                  placeholder="Enter maximum participations per user"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      
      {fields.includes("max_duplications") && (
        <FormField
          control={form.control}
          name="max_duplications"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Max Duplications</FormLabel>
              <FormControl>
                <Input 
                  {...field} 
                  type="number" 
                  onChange={e => field.onChange(Number(e.target.value))}
                  min={0}
                  placeholder="Enter maximum duplications"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </>
  );
}
