
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { 
  Globe, 
  TrendingUp, 
  ListChecks, 
  Clock, 
  CheckCircle2, 
  XCircle, 
  Sparkles 
} from "lucide-react";
import { Market } from "@/types/market";

interface TabTriggersProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  participationCounts?: Record<string, number>;
  onTabChange?: (tab: string) => void;
  markets?: Market[] | null;
  market?: any;
  isParticipating?: boolean;
  isMember?: boolean;
  isApproved?: boolean;
  isSuspended?: boolean;
  refetchMarket?: () => Promise<void>;
}

export function TabTriggers({ 
  activeTab, 
  setActiveTab, 
  participationCounts = {}, 
  onTabChange
}: TabTriggersProps) {
  const renderCount = (tab: string) => {
    const count = participationCounts[tab] || 0;
    if (count <= 0) return null;
    
    return (
      <span className={`ml-1.5 px-1.5 py-0.5 text-xs rounded-full inline-flex items-center justify-center 
        ${activeTab === tab 
          ? 'bg-primary/15 text-primary/90 dark:bg-primary/30 dark:text-primary-foreground' 
          : 'bg-zinc-100 text-zinc-700 dark:bg-zinc-700 dark:text-zinc-300'}`}>
        {count}
      </span>
    );
  };

  const getTabIcon = (tab: string) => {
    switch (tab) {
      case 'all':
        return <Globe className="h-4 w-4 mr-1.5" />;
      case 'trending':
        return <TrendingUp className="h-4 w-4 mr-1.5" />;  
      case 'joined':
        return <CheckCircle2 className="h-4 w-4 mr-1.5" />;
      case 'available':
        return <ListChecks className="h-4 w-4 mr-1.5" />;
      case 'upcoming':
        return <Clock className="h-4 w-4 mr-1.5" />;
      case 'closed':
        return <XCircle className="h-4 w-4 mr-1.5" />;
      case 'new':
        return <Sparkles className="h-4 w-4 mr-1.5" />;
      default:
        return null;
    }
  };

  // Use onTabChange if provided, otherwise fall back to setActiveTab
  const handleTabChange = (tab: string) => {
    if (onTabChange) {
      onTabChange(tab);
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <Tabs
      value={activeTab}
      onValueChange={handleTabChange}
      className="w-full"
    >
      <TabsList className="w-full overflow-x-auto flex justify-start p-1 bg-zinc-50 dark:bg-muted/20 rounded-lg h-auto border border-zinc-200 dark:border-zinc-800 shadow-sm">
        <TabsTrigger 
          value="all" 
          className="h-9 px-3 text-sm rounded-md data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm data-[state=active]:border-zinc-100 dark:data-[state=active]:bg-zinc-800 dark:data-[state=active]:border-zinc-700 flex items-center whitespace-nowrap text-zinc-700 dark:text-zinc-300 hover:text-primary/80 dark:hover:text-primary-foreground/80 transition-colors"
        >
          {getTabIcon('all')}
          All Markets
          {renderCount('all')}
        </TabsTrigger>
        
        <TabsTrigger 
          value="trending" 
          className="h-9 px-3 text-sm rounded-md data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm data-[state=active]:border-zinc-100 dark:data-[state=active]:bg-zinc-800 dark:data-[state=active]:border-zinc-700 flex items-center whitespace-nowrap text-zinc-700 dark:text-zinc-300 hover:text-primary/80 dark:hover:text-primary-foreground/80 transition-colors"
        >
          {getTabIcon('trending')}
          Trending
          {renderCount('trending')}
        </TabsTrigger>
        
        <TabsTrigger 
          value="joined" 
          className="h-9 px-3 text-sm rounded-md data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm data-[state=active]:border-zinc-100 dark:data-[state=active]:bg-zinc-800 dark:data-[state=active]:border-zinc-700 flex items-center whitespace-nowrap text-zinc-700 dark:text-zinc-300 hover:text-primary/80 dark:hover:text-primary-foreground/80 transition-colors"
        >
          {getTabIcon('joined')}
          Participating
          {renderCount('joined')}
        </TabsTrigger>
        
        <TabsTrigger 
          value="available" 
          className="h-9 px-3 text-sm rounded-md data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm data-[state=active]:border-zinc-100 dark:data-[state=active]:bg-zinc-800 dark:data-[state=active]:border-zinc-700 flex items-center whitespace-nowrap text-zinc-700 dark:text-zinc-300 hover:text-primary/80 dark:hover:text-primary-foreground/80 transition-colors"
        >
          {getTabIcon('available')}
          Available
          {renderCount('available')}
        </TabsTrigger>
        
        <TabsTrigger 
          value="upcoming" 
          className="h-9 px-3 text-sm rounded-md data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm data-[state=active]:border-zinc-100 dark:data-[state=active]:bg-zinc-800 dark:data-[state=active]:border-zinc-700 flex items-center whitespace-nowrap text-zinc-700 dark:text-zinc-300 hover:text-primary/80 dark:hover:text-primary-foreground/80 transition-colors"
        >
          {getTabIcon('upcoming')}
          Upcoming
          {renderCount('upcoming')}
        </TabsTrigger>
        
        <TabsTrigger 
          value="closed" 
          className="h-9 px-3 text-sm rounded-md data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm data-[state=active]:border-zinc-100 dark:data-[state=active]:bg-zinc-800 dark:data-[state=active]:border-zinc-700 flex items-center whitespace-nowrap text-zinc-700 dark:text-zinc-300 hover:text-primary/80 dark:hover:text-primary-foreground/80 transition-colors"
        >
          {getTabIcon('closed')}
          Closed
          {renderCount('closed')}
        </TabsTrigger>
        
        <TabsTrigger 
          value="new" 
          className="h-9 px-3 text-sm rounded-md data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-sm data-[state=active]:border-zinc-100 dark:data-[state=active]:bg-zinc-800 dark:data-[state=active]:border-zinc-700 flex items-center whitespace-nowrap text-zinc-700 dark:text-zinc-300 hover:text-primary/80 dark:hover:text-primary-foreground/80 transition-colors"
        >
          {getTabIcon('new')}
          New
          {renderCount('new')}
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
