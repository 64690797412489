
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface MaxParticipationsInputProps {
  value: number;
  onChange: (value: number) => void;
}

export const MaxParticipationsInput = ({ value, onChange }: MaxParticipationsInputProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="maxParticipations">Maximum Participations per User</Label>
      <Input
        id="maxParticipations"
        type="number"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
        placeholder="Enter max participations per user"
        min="1"
        step="1"
      />
    </div>
  );
};
