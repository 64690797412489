
import { logger } from '@/utils/logger';
import { dispatchMarketSyncEvent, MARKET_SYNC_EVENTS } from './market-sync-events';

class MarketDataSynchronizer {
  private _isSyncInProgress: boolean = false;
  private _lastSync: Date | null = null;
  private _autoSyncInterval: number | null = null;
  
  constructor() {
    this._loadSyncState();
  }
  
  // Getters
  get isSyncInProgress(): boolean {
    return this._isSyncInProgress;
  }
  
  get lastSync(): Date | null {
    return this._lastSync;
  }
  
  // Sync state management methods
  syncStarted(): void {
    this._isSyncInProgress = true;
    this._saveSyncState();
    
    // Dispatch sync start event
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYNC_START, {
      timestamp: new Date().toISOString()
    });
  }
  
  syncSucceeded(data?: any): void {
    this._isSyncInProgress = false;
    this._lastSync = new Date();
    this._saveSyncState();
    
    // Dispatch sync complete event with success flag
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYNC_COMPLETE, {
      success: true,
      timestamp: this._lastSync.toISOString(),
      data
    });
  }
  
  syncFailed(error: Error): void {
    this._isSyncInProgress = false;
    this._saveSyncState();
    
    // Dispatch sync complete event with error info
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYNC_COMPLETE, {
      success: false,
      timestamp: new Date().toISOString(),
      error: error.message
    });
    
    logger.error('Sync failed:', error);
  }
  
  // Auto-sync management
  startAutoSync(intervalMs: number = 5 * 60 * 1000): void {
    this.stopAutoSync(); // Clear any existing interval first
    
    // Start a new interval
    this._autoSyncInterval = window.setInterval(() => {
      this.triggerSync(false); // Don't show toast on auto-sync
    }, intervalMs);
    
    logger.info(`Auto-sync started with interval: ${intervalMs}ms`);
  }
  
  stopAutoSync(): void {
    if (this._autoSyncInterval !== null) {
      clearInterval(this._autoSyncInterval);
      this._autoSyncInterval = null;
      logger.info('Auto-sync stopped');
    }
  }
  
  // Trigger sync manually
  triggerSync(showToast: boolean = true): void {
    // This is just a public method that other components can call
    // The actual sync logic is in the MarketDataProvider
    
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.FORCE_SYNC, {
      timestamp: new Date().toISOString(),
      showToast
    });
  }
  
  // Save/load sync state from localStorage for persistence
  private _saveSyncState(): void {
    try {
      localStorage.setItem('market_sync_state', JSON.stringify({
        isSyncInProgress: this._isSyncInProgress,
        lastSync: this._lastSync ? this._lastSync.toISOString() : null
      }));
    } catch (error) {
      logger.error('Error saving sync state to localStorage:', error);
    }
  }
  
  private _loadSyncState(): void {
    try {
      const savedState = localStorage.getItem('market_sync_state');
      if (savedState) {
        const state = JSON.parse(savedState);
        this._isSyncInProgress = state.isSyncInProgress || false;
        this._lastSync = state.lastSync ? new Date(state.lastSync) : null;
      }
    } catch (error) {
      logger.error('Error loading sync state from localStorage:', error);
    }
  }
}

// Create and export singleton instance
export const marketDataSynchronizer = new MarketDataSynchronizer();
