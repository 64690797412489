
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { Market } from "@/types/market";
import { Button } from "@/components/ui/button";
import { Settings, X, PlayCircle, PauseCircle, Copy } from "lucide-react";
import { logger } from "@/utils/logger";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { MarketConfigurationPanel } from "@/components/admin/markets/MarketConfigurationPanel";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useMarketStatus } from "@/hooks/markets/use-market-status";
import { toast } from "sonner";
import { CloneMarketDialog } from "./CloneMarketDialog";

interface AdminActionsProps {
  market: Market;
  onConfigurationUpdated?: () => void;
  onScrollToBottom?: () => void;
}

export const AdminActions = ({ 
  market, 
  onConfigurationUpdated,
  onScrollToBottom
}: AdminActionsProps) => {
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { toast: uiToast } = useToast();
  const { updateMarketStatus } = useMarketStatus();
  
  logger.info(`Rendering AdminActions for ${market.name}`, {
    marketId: market.id,
    status: market.status
  });
  
  const handleOpenConfig = () => {
    setIsConfigOpen(true);
    if (onScrollToBottom) {
      onScrollToBottom();
    }
  };
  
  const handleCloseConfig = () => {
    setIsConfigOpen(false);
  };
  
  const handleConfigUpdated = () => {
    setIsConfigOpen(false);
    uiToast({
      title: "Configuration Updated",
      description: "Market configuration has been updated successfully.",
    });
    
    if (onConfigurationUpdated) {
      onConfigurationUpdated();
    }
  };

  const handleOpenCloneDialog = () => {
    setIsCloneDialogOpen(true);
  };

  const isActive = market.status === 'active';

  const handleToggleStatus = async () => {
    try {
      setIsLoading(true);
      const newStatus = isActive ? 'closed' : 'active';
      
      logger.info('Toggling market status:', { marketId: market.id, currentStatus: market.status, newStatus });
      
      const result = await updateMarketStatus(market.id, newStatus);
      
      if (result.success) {
        toast.success(isActive ? "Market paused" : "Market activated", {
          description: `The market has been ${isActive ? 'paused' : 'activated'} successfully.`
        });
        
        if (onConfigurationUpdated) {
          onConfigurationUpdated();
        }
      } else {
        throw new Error(result.message || `Failed to ${isActive ? 'pause' : 'activate'} market`);
      }
    } catch (error) {
      const err = error as Error;
      logger.error('Error toggling market status:', err);
      toast.error(`Failed to ${isActive ? 'pause' : 'activate'} market`, {
        description: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                onClick={handleOpenConfig}
                className="flex-1"
                disabled={isLoading}
              >
                <Settings className="mr-2 h-4 w-4" />
                Configure
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Advanced market configuration</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      <div className="flex flex-wrap gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                onClick={handleToggleStatus}
                className="flex-1"
                disabled={isLoading}
              >
                {isActive ? (
                  <>
                    <PauseCircle className="mr-2 h-4 w-4 text-amber-500" />
                    Pause
                  </>
                ) : (
                  <>
                    <PlayCircle className="mr-2 h-4 w-4 text-green-500" />
                    Activate
                  </>
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{isActive ? "Pause this market" : "Activate this market"}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                onClick={handleOpenCloneDialog}
                className="flex-1"
                disabled={isLoading}
              >
                <Copy className="mr-2 h-4 w-4 text-blue-500" />
                Clone
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Create a customized copy of this market</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      
      <Dialog open={isConfigOpen} onOpenChange={setIsConfigOpen}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <span>Market Configuration - {market.name}</span>
              <Button
                variant="ghost"
                size="icon"
                onClick={handleCloseConfig}
                className="h-6 w-6"
              >
                <X className="h-4 w-4" />
              </Button>
            </DialogTitle>
          </DialogHeader>
          <MarketConfigurationPanel 
            market={market}
            onUpdate={handleConfigUpdated}
          />
        </DialogContent>
      </Dialog>

      <CloneMarketDialog
        market={market}
        open={isCloneDialogOpen}
        onOpenChange={setIsCloneDialogOpen}
        onCloneSuccess={() => {
          if (onConfigurationUpdated) {
            onConfigurationUpdated();
          }
        }}
      />
    </div>
  );
};
