import { motion } from "framer-motion";
import { Shield, Target, Users, Gem } from "lucide-react";
import ScrollSection from "@/components/ScrollSection";

const Benefits = () => {
  const benefits = [
    {
      icon: <Shield className="w-12 h-12 text-primary" />,
      title: "Elite Protection",
      description: "Advanced risk management and portfolio protection strategies.",
    },
    {
      icon: <Target className="w-12 h-12 text-primary" />,
      title: "Strategic Insights",
      description: "Access to proprietary market analysis and trading signals.",
    },
    {
      icon: <Users className="w-12 h-12 text-primary" />,
      title: "Exclusive Network",
      description: "Connect with high-net-worth individuals and industry leaders.",
    },
    {
      icon: <Gem className="w-12 h-12 text-primary" />,
      title: "Premium Support",
      description: "24/7 dedicated concierge service and priority assistance.",
    },
  ];

  return (
    <div className="min-h-screen bg-background">
      <div className="container mx-auto px-4 py-16">
        <ScrollSection>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl sm:text-5xl font-bold text-primary mb-6">
              Membership Benefits
            </h1>
            <p className="text-lg sm:text-xl text-primary-foreground/80 max-w-3xl mx-auto">
              Join an exclusive community of financial leaders and gain access to
              unparalleled opportunities.
            </p>
          </motion.div>
        </ScrollSection>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <ScrollSection key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="roman-card hover:scale-105 transition-all duration-300"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="mb-6 transform hover:scale-110 transition-all duration-300">
                    {benefit.icon}
                  </div>
                  <h3 className="text-xl font-bold text-primary-foreground mb-4">
                    {benefit.title}
                  </h3>
                  <p className="text-primary-foreground/80">
                    {benefit.description}
                  </p>
                </div>
              </motion.div>
            </ScrollSection>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;