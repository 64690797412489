
/**
 * Implements a circuit breaker pattern to prevent repeated calls to failing services
 */
export class CircuitBreaker {
  private failures: number = 0;
  private lastFailureTime: number = 0;
  private state: 'closed' | 'open' | 'half-open' = 'closed';
  
  constructor(
    private readonly failureThreshold: number = 5,
    private readonly resetTimeout: number = 30000, // 30 seconds
    private readonly halfOpenMaxCalls: number = 1
  ) {}
  
  /**
   * Checks if the circuit is allowing requests
   */
  public canRequest(): boolean {
    this.checkStateTransition();
    return this.state !== 'open';
  }
  
  /**
   * Records a successful call and resets the circuit if in half-open state
   */
  public recordSuccess(): void {
    if (this.state === 'half-open') {
      this.reset();
    }
  }
  
  /**
   * Records a failure and potentially opens the circuit
   */
  public recordFailure(): void {
    this.failures++;
    this.lastFailureTime = Date.now();
    
    if (this.failures >= this.failureThreshold && this.state === 'closed') {
      this.state = 'open';
    }
  }
  
  /**
   * Resets the circuit to closed state
   */
  public reset(): void {
    this.failures = 0;
    this.state = 'closed';
  }
  
  /**
   * Force the circuit to open
   */
  public forceOpen(): void {
    this.state = 'open';
    this.failures = this.failureThreshold;
    this.lastFailureTime = Date.now();
  }
  
  /**
   * Get the current state of the circuit
   */
  public getState(): string {
    return this.state;
  }
  
  /**
   * Get the number of consecutive failures
   */
  public getFailureCount(): number {
    return this.failures;
  }
  
  /**
   * Check if state transition is needed
   */
  private checkStateTransition(): void {
    if (this.state === 'open') {
      const timeElapsed = Date.now() - this.lastFailureTime;
      if (timeElapsed >= this.resetTimeout) {
        this.state = 'half-open';
      }
    }
  }
}

/**
 * A registry to store and manage circuit breakers for different services
 */
export class CircuitBreakerRegistry {
  private static breakers: Map<string, CircuitBreaker> = new Map();
  
  /**
   * Get a circuit breaker for a specific service
   */
  public static getBreaker(serviceKey: string): CircuitBreaker {
    if (!this.breakers.has(serviceKey)) {
      this.breakers.set(serviceKey, new CircuitBreaker());
    }
    return this.breakers.get(serviceKey)!;
  }
  
  /**
   * Reset all circuit breakers
   */
  public static resetAll(): void {
    this.breakers.forEach(breaker => breaker.reset());
  }
  
  /**
   * Get the status of all circuit breakers
   */
  public static getStatus(): Record<string, { state: string; failures: number }> {
    const status: Record<string, { state: string; failures: number }> = {};
    
    this.breakers.forEach((breaker, key) => {
      status[key] = {
        state: breaker.getState(),
        failures: breaker.getFailureCount()
      };
    });
    
    return status;
  }
}
