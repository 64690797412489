
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';

export interface MarketContribution {
  id: string;
  market_id: string;
  user_id: string;
  input_value: number;
  calculated_contribution: number;
  contribution_date: string;
  contribution_round: number;
  status: 'pending' | 'approved' | 'rejected' | 'submitted' | 'success' | 'failed' | 'closed_successful';
  created_at: string;
}

// Type for the raw database response
interface DatabaseMarketContribution {
  id: string;
  market_id: string;
  user_id: string;
  input_value: number;
  calculated_contribution: number;
  contribution_date: string;
  contribution_round: number;
  status: string;
  created_at: string;
  updated_at: string;
  deposit_amount?: number;
}

/**
 * Hook to fetch market contributions for a specific market and user
 */
export const useMarketContributions = (marketId: string, userId: string) => {
  const [contributions, setContributions] = useState<MarketContribution[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchContributions = async () => {
      if (!marketId || !userId) {
        setContributions([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        logger.info('Fetching market contributions', { marketId, userId });

        const { data, error } = await supabase
          .from('market_contributions')
          .select('*')
          .eq('market_id', marketId)
          .eq('user_id', userId)
          .order('contribution_date', { ascending: false });

        if (error) {
          throw error;
        }

        logger.info('Market contributions fetched successfully', { 
          count: data?.length || 0,
          marketId, 
          userId 
        });

        // Map the database results to our expected MarketContribution type
        const typedContributions: MarketContribution[] = (data || []).map((item: DatabaseMarketContribution) => ({
          id: item.id,
          market_id: item.market_id,
          user_id: item.user_id,
          input_value: item.input_value,
          calculated_contribution: item.calculated_contribution,
          contribution_date: item.contribution_date,
          contribution_round: item.contribution_round,
          // Cast the status to the expected union type
          status: (item.status as 'pending' | 'approved' | 'rejected' | 'submitted' | 'success' | 'failed' | 'closed_successful'),
          created_at: item.created_at
        }));

        setContributions(typedContributions);
      } catch (err) {
        const error = err instanceof Error ? err : new Error(String(err));
        logger.error('Error fetching market contributions', {
          error: error.message,
          marketId,
          userId,
          stack: error.stack
        });
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContributions();
  }, [marketId, userId]);

  return {
    contributions,
    isLoading,
    error,
    refetch: async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from('market_contributions')
          .select('*')
          .eq('market_id', marketId)
          .eq('user_id', userId)
          .order('contribution_date', { ascending: false });

        if (error) throw error;

        // Map the database results to our expected MarketContribution type
        const typedContributions: MarketContribution[] = (data || []).map((item: DatabaseMarketContribution) => ({
          id: item.id,
          market_id: item.market_id,
          user_id: item.user_id,
          input_value: item.input_value,
          calculated_contribution: item.calculated_contribution,
          contribution_date: item.contribution_date,
          contribution_round: item.contribution_round,
          // Cast the status to the expected union type
          status: (item.status as 'pending' | 'approved' | 'rejected' | 'submitted' | 'success' | 'failed' | 'closed_successful'),
          created_at: item.created_at
        }));

        setContributions(typedContributions);
        return { success: true, data: typedContributions };
      } catch (err) {
        const error = err instanceof Error ? err : new Error(String(err));
        logger.error('Error refetching contributions', { 
          error: error.message, 
          marketId, 
          userId 
        });
        setError(error);
        return { success: false, error };
      } finally {
        setIsLoading(false);
      }
    }
  };
};
