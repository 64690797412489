
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { MemberMarketSelector } from './selection/MemberMarketSelector';
import { useUserMarketManagement, MarketForManagement } from '@/hooks/admin/use-user-market-management';
import { MemberMarketManagement } from './MemberMarketManagement';
import { AlertCircle, Loader2 } from 'lucide-react';
import { supabase } from '@/lib/supabase';

interface User {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  balance: number;
  status: string | null;
  member_share_percentage: number;
}

export const UserMarketsView = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedMarketId, setSelectedMarketId] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  
  // Only fetch data when both IDs are selected
  const { 
    userMarkets, 
    loading: isLoadingMarkets, 
    updateMarketStatus, 
    refreshData 
  } = useUserMarketManagement(
    selectedUserId || '', 
    selectedMarketId || ''
  );
  
  useEffect(() => {
    // Fetch user data when user is selected
    const fetchUser = async () => {
      if (!selectedUserId) {
        setUser(null);
        return;
      }
      
      setIsLoadingUser(true);
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', selectedUserId)
          .single();
        
        if (error) throw error;
        
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
        setUser(null);
      } finally {
        setIsLoadingUser(false);
      }
    };
    
    fetchUser();
  }, [selectedUserId]);
  
  const handleSelection = (userId: string, marketId: string) => {
    setSelectedUserId(userId);
    setSelectedMarketId(marketId);
  };
  
  // Create a wrapper function to adapt the return type
  const handleUpdateMarketStatus = async (marketId: string, status: string) => {
    await updateMarketStatus(marketId, status);
  };

  return (
    <div className="space-y-6">
      <MemberMarketSelector onSelect={handleSelection} />
      
      {(selectedUserId && selectedMarketId && user) ? (
        <div className="mt-6">
          <MemberMarketManagement
            user={user}
            markets={userMarkets}
            loading={isLoadingMarkets}
            onUpdateMarketStatus={handleUpdateMarketStatus}
            onRefresh={refreshData}
          />
        </div>
      ) : (isLoadingUser || isLoadingMarkets) ? (
        <Card className="p-12">
          <div className="flex flex-col items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary mb-4" />
            <p className="text-muted-foreground">Loading data...</p>
          </div>
        </Card>
      ) : (
        <Card className="p-8">
          <div className="text-center">
            <AlertCircle className="h-12 w-12 mx-auto mb-3 text-primary/50" />
            <h3 className="text-xl font-medium mb-2">Select a Member and Market</h3>
            <p className="text-muted-foreground mb-2">
              Please select both a member and market from above to view market management details.
            </p>
          </div>
        </Card>
      )}
    </div>
  );
};
