import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Crown, Shield, Target } from "lucide-react";
import ScrollSection from "@/components/ScrollSection";
import { useNavigate } from "react-router-dom";

const ApplyNow = () => {
  const navigate = useNavigate();
  
  const tiers = [
    {
      name: "Elite",
      price: "25,000",
      description: "For seasoned investors seeking premium opportunities",
      features: [
        "Priority Market Access",
        "1-on-1 Strategic Consulting",
        "Exclusive Event Invitations",
        "24/7 Concierge Support",
      ],
      icon: <Crown className="w-8 h-8 text-primary" />,
    },
    {
      name: "Sovereign",
      price: "50,000",
      description: "Ultimate access for institutional-grade investors",
      features: [
        "All Elite Features",
        "Private Deal Flow",
        "Board Meeting Access",
        "Custom Portfolio Strategy",
      ],
      icon: <Shield className="w-8 h-8 text-primary" />,
    },
  ];

  return (
    <div className="min-h-screen bg-background">
      <div className="container mx-auto px-4 py-16">
        <ScrollSection>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl sm:text-5xl font-bold text-primary mb-6">
              Choose Your Path to Excellence
            </h1>
            <p className="text-lg sm:text-xl text-primary-foreground/80 max-w-3xl mx-auto">
              Select the membership tier that aligns with your investment goals and
              aspirations.
            </p>
          </motion.div>
        </ScrollSection>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {tiers.map((tier, index) => (
            <ScrollSection key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Card className="roman-card hover:scale-105 transition-all duration-300">
                  <CardHeader className="text-center">
                    <div className="mx-auto mb-4">{tier.icon}</div>
                    <CardTitle className="text-2xl font-bold text-primary">
                      {tier.name}
                    </CardTitle>
                    <div className="mt-4">
                      <span className="text-4xl font-bold text-primary-foreground">
                        ${tier.price}
                      </span>
                      <span className="text-primary-foreground/60">/year</span>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <p className="text-center text-primary-foreground/80 mb-6">
                      {tier.description}
                    </p>
                    <ul className="space-y-4">
                      {tier.features.map((feature, featureIndex) => (
                        <li
                          key={featureIndex}
                          className="flex items-center text-primary-foreground"
                        >
                          <Target className="w-5 h-5 text-primary mr-2" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <Button
                      className="w-full mt-8 bg-primary hover:bg-primary/90"
                      onClick={() => navigate("/register")}
                    >
                      Apply Now
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </ScrollSection>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplyNow;