
import React, { useMemo } from 'react';
import { Market } from '@/types/market';
import { MarketCard } from '@/components/markets/MarketCard';
import { GroupSeparator } from '@/components/markets/display/GroupSeparator';
import { cn } from '@/lib/utils';
import { Loader2, Map } from 'lucide-react';

interface RegionMarketsDisplayProps {
  markets: Market[] | null;
  viewMode: 'grid' | 'list';
  onParticipate: (marketId: string) => Promise<void>;
  onContributionSubmitted: () => Promise<void>;
  onCloseTimeUpdate: (marketId: string, closeTime: string) => Promise<void>;
  showParticipationSummary?: boolean; // Added this missing prop
}

export const RegionMarketsDisplay: React.FC<RegionMarketsDisplayProps> = ({
  markets,
  viewMode,
  onParticipate,
  onContributionSubmitted,
  onCloseTimeUpdate,
  showParticipationSummary = false // Default value for the new prop
}) => {
  // Group markets by region
  const marketsByRegion = useMemo(() => {
    if (!markets) return [];
    
    const grouped = markets.reduce((acc, market) => {
      const region = market.region || 'Global';
      if (!acc[region]) {
        acc[region] = [];
      }
      acc[region].push(market);
      return acc;
    }, {} as Record<string, Market[]>);
    
    // Convert to array of [region, markets] pairs and sort by region name
    return Object.entries(grouped)
      .sort(([regionA], [regionB]) => {
        if (regionA === 'Global') return -1;
        if (regionB === 'Global') return 1;
        return regionA.localeCompare(regionB);
      });
  }, [markets]);
  
  if (!markets) {
    return (
      <div className="flex justify-center items-center p-12">
        <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
      </div>
    );
  }
  
  if (markets.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center p-12 text-center gap-3 bg-muted/30 rounded-lg border">
        <Map className="h-10 w-10 text-muted-foreground/70" />
        <h3 className="text-xl font-medium">No markets in this category</h3>
        <p className="text-muted-foreground">Check back later for new opportunities.</p>
      </div>
    );
  }
  
  return (
    <div className="space-y-8">
      {marketsByRegion.map(([region, regionMarkets]) => (
        <div key={region} className="space-y-4">
          <GroupSeparator title={region} count={regionMarkets.length} />
          
          <div className={cn(
            viewMode === 'grid' 
              ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" 
              : "space-y-4"
          )}>
            {regionMarkets.map(market => (
              <MarketCard
                key={market.id}
                market={market}
                markets={markets}
                onParticipate={onParticipate}
                onParticipateSuccess={onContributionSubmitted}
                onCloseTimeUpdate={onCloseTimeUpdate}
                variant={viewMode === 'list' ? 'horizontal' : 'default'}
                showParticipationSummary={showParticipationSummary} // Pass down the prop to MarketCard
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
