
import { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import { logger } from '@/utils/logger';
import { normalizeRegionCode } from '@/utils/market/formatters';

export interface RegionalSetting {
  id: number;
  region_code: string;
  base_target: number;
  adjustment: number;
  adjustment_factor: number;
  created_at: string;
  updated_at: string;
}

export const useRegionalSettings = () => {
  const [settings, setSettings] = useState<RegionalSetting[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();
  
  const fetchSettings = useCallback(async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('regional_settings')
        .select('*')
        .order('region_code');
        
      if (error) {
        throw error;
      }
      
      setSettings(data);
      logger.info(`Fetched ${data.length} regional settings`);
    } catch (error) {
      logger.error('Error fetching regional settings:', error);
      toast({
        title: "Error",
        description: "Failed to load regional settings",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);
  
  const refresh = useCallback(() => {
    logger.info('Manual refresh of regional settings requested');
    return fetchSettings();
  }, [fetchSettings]);

  // Initialize settings
  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);
  
  // Set up realtime subscription with improved handling for different region formats
  useEffect(() => {
    const subscription = supabase
      .channel('regional_settings_changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'regional_settings'
      }, (payload) => {
        logger.info('Realtime update for regional settings:', payload);
        fetchSettings();
      })
      .subscribe();
      
    // Listen for custom events with improved handling
    const handleSettingsUpdate = (event: Event) => {
      const customEvent = event as CustomEvent;
      const detail = customEvent.detail || {};
      
      // Check if we have region info in the event
      if (detail.region) {
        const eventRegion = normalizeRegionCode(detail.region);
        const originalRegion = detail.originalRegion ? normalizeRegionCode(detail.originalRegion) : null;
        
        logger.info('Regional settings update event received:', {
          eventType: customEvent.type,
          region: eventRegion,
          originalRegion: originalRegion
        });
        
        // Check if we have this region in our settings
        const hasRegion = settings.some(setting => {
          const settingRegion = normalizeRegionCode(setting.region_code);
          return settingRegion === eventRegion || 
                 (originalRegion && settingRegion === originalRegion);
        });
        
        if (hasRegion) {
          fetchSettings();
        } else {
          // If region not found, refresh anyway to get new regions
          logger.info(`Region ${eventRegion} not found in current settings, refreshing all`);
          fetchSettings();
        }
      } else {
        // For global events with no specific region, always refresh
        logger.info('Global settings update event received:', customEvent.type);
        fetchSettings();
      }
    };
    
    window.addEventListener('regional-settings-updated', handleSettingsUpdate);
    window.addEventListener('calculator-targets-synced', handleSettingsUpdate);
    window.addEventListener('system-targets-updated', handleSettingsUpdate);
    
    return () => {
      supabase.removeChannel(subscription);
      window.removeEventListener('regional-settings-updated', handleSettingsUpdate);
      window.removeEventListener('calculator-targets-synced', handleSettingsUpdate);
      window.removeEventListener('system-targets-updated', handleSettingsUpdate);
    };
  }, [fetchSettings, settings]);

  const updateSettings = useCallback(async (
    region: string,
    baseTarget: number,
    adjustment: number,
    factor: number
  ): Promise<boolean> => {
    try {
      // Normalize the region code
      const normalizedRegion = normalizeRegionCode(region);
      
      logger.info(`Updating settings for region ${normalizedRegion} (original: ${region})`, {
        baseTarget,
        adjustment,
        factor
      });
      
      const { error } = await supabase
        .from('regional_settings')
        .upsert({
          region_code: normalizedRegion,
          base_target: baseTarget,
          adjustment: adjustment,
          adjustment_factor: factor,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'region_code'
        });
        
      if (error) {
        throw error;
      }
      
      // Update local state
      setSettings(prevSettings => {
        const updatedSettings = [...prevSettings];
        const index = updatedSettings.findIndex(s => normalizeRegionCode(s.region_code) === normalizedRegion);
        
        if (index >= 0) {
          updatedSettings[index] = {
            ...updatedSettings[index],
            base_target: baseTarget,
            adjustment: adjustment,
            adjustment_factor: factor,
            updated_at: new Date().toISOString()
          };
        } else {
          updatedSettings.push({
            id: Date.now(),
            region_code: normalizedRegion,
            base_target: baseTarget,
            adjustment: adjustment,
            adjustment_factor: factor,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          });
        }
        
        return updatedSettings;
      });
      
      // Emit event with both original and normalized region
      window.dispatchEvent(new CustomEvent('regional-settings-updated', {
        detail: {
          region: normalizedRegion,
          originalRegion: region,
          settings: {
            baseTarget,
            adjustment,
            factor
          },
          timestamp: new Date().toISOString()
        }
      }));
      
      toast({
        title: "Success",
        description: `Regional settings for ${region} updated successfully`,
      });
      
      return true;
    } catch (error) {
      logger.error('Error updating regional settings:', error);
      toast({
        title: "Error",
        description: "Failed to update regional settings",
        variant: "destructive",
      });
      return false;
    }
  }, [toast]);

  return {
    settings,
    loading,
    refresh,
    updateSettings
  };
};
