
import React, { useState } from 'react';
import { AlertCircle, AlertTriangle, RefreshCw, Server, Wifi, ShieldOff, Clock, Lock, Info } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { 
  Accordion, 
  AccordionContent, 
  AccordionItem, 
  AccordionTrigger 
} from "@/components/ui/accordion";
import { MarketErrorType } from '@/utils/error-handling/error-types';
import { 
  MARKET_ERROR_DESCRIPTIONS, 
  getMarketErrorRecoverySteps 
} from '@/utils/error-handling/market-error-types';

interface EnhancedErrorDisplayProps {
  error: Error;
  errorType: MarketErrorType;
  retryCount: number;
  onRetry?: () => void;
  systemInfo?: Record<string, any>;
}

export const EnhancedErrorDisplay: React.FC<EnhancedErrorDisplayProps> = ({
  error,
  errorType,
  retryCount = 0,
  onRetry,
  systemInfo = {}
}) => {
  const [showDetails, setShowDetails] = useState(false);
  
  // Choose the appropriate icon based on error type
  const ErrorIcon = () => {
    switch (errorType) {
      case 'network':
        return <Wifi className="h-10 w-10 text-destructive" />;
      case 'auth':
      case 'authentication':
        return <ShieldOff className="h-10 w-10 text-destructive" />;
      case 'timeout':
        return <Clock className="h-10 w-10 text-destructive" />;
      case 'server':
        return <Server className="h-10 w-10 text-destructive" />;
      case 'forbidden':
        return <Lock className="h-10 w-10 text-destructive" />;
      default:
        return <AlertTriangle className="h-10 w-10 text-destructive" />;
    }
  };

  // Get recovery steps
  const recoverySteps = getMarketErrorRecoverySteps(errorType);
  
  // Format system info for display
  const formatSystemInfo = () => {
    return Object.entries(systemInfo).map(([key, value]) => {
      // Format the key with spaces and capitalization
      const formattedKey = key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
        .replace(/([A-Z])/g, (str) => str.toUpperCase());
      
      // Format the value based on type
      let formattedValue = value;
      if (typeof value === 'object') {
        formattedValue = JSON.stringify(value, null, 2);
      } else if (typeof value === 'boolean') {
        formattedValue = value ? 'Yes' : 'No';
      }
      
      return (
        <div key={key} className="grid grid-cols-3 gap-2 mb-1 text-sm">
          <span className="font-medium text-muted-foreground">{formattedKey}:</span>
          <span className="col-span-2 font-mono">{formattedValue}</span>
        </div>
      );
    });
  };

  return (
    <Card className="shadow-lg border-destructive/20">
      <CardHeader className="pb-2 border-b">
        <CardTitle className="flex items-center gap-2 text-destructive">
          <AlertCircle className="h-5 w-5" /> 
          Error Encountered
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-6 space-y-4">
        <div className="flex flex-col items-center justify-center p-4 text-center">
          <ErrorIcon />
          <h3 className="mt-4 text-lg font-semibold">{error.message}</h3>
          <p className="mt-2 text-muted-foreground">{MARKET_ERROR_DESCRIPTIONS[errorType]}</p>
          
          {retryCount > 0 && (
            <div className="mt-2 text-sm text-muted-foreground">
              Retry attempts: <span className="font-medium">{retryCount}</span>
            </div>
          )}
        </div>
        
        {recoverySteps.length > 0 && (
          <Alert variant="default" className="bg-muted/50 border-primary/20">
            <Info className="h-4 w-4" />
            <AlertTitle>Suggested Steps</AlertTitle>
            <AlertDescription className="mt-2">
              <ul className="list-disc list-inside text-sm space-y-1">
                {recoverySteps.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ul>
            </AlertDescription>
          </Alert>
        )}
        
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="debug-info">
            <AccordionTrigger className="text-sm text-muted-foreground">
              Technical Details
            </AccordionTrigger>
            <AccordionContent>
              <div className="text-xs bg-muted/50 p-3 rounded-md">
                <div className="mb-2">
                  <span className="font-semibold">Error Type:</span> {errorType}
                </div>
                
                {error.stack && (
                  <div className="mb-2">
                    <span className="font-semibold">Stack Trace:</span>
                    <pre className="mt-1 overflow-auto max-h-[150px] text-[11px] p-2 bg-muted rounded">
                      {error.stack}
                    </pre>
                  </div>
                )}
                
                {Object.keys(systemInfo).length > 0 && (
                  <div className="mt-4">
                    <span className="font-semibold">System Information:</span>
                    <div className="mt-1 p-2 bg-background/80 rounded">
                      {formatSystemInfo()}
                    </div>
                  </div>
                )}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </CardContent>
      {onRetry && (
        <CardFooter className="border-t pt-4 flex justify-center gap-2">
          <Button 
            onClick={onRetry} 
            className="gap-2"
            disabled={retryCount > 10}
          >
            <RefreshCw className="h-4 w-4" />
            Try Again
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};
