
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { Session } from "@supabase/supabase-js";
import { dispatchMarketSyncEvent, MARKET_SYNC_EVENTS } from "@/utils/market/sync/market-sync-events";

// Function to fetch all markets data with user participation information
export const fetchMarketsData = async (
  session: Session, 
  attempts: number = 0,
  lastQueryTime: number | null = null
) => {
  const start = performance.now();
  
  try {
    // Log the request attempt
    logger.info('Fetching markets data', { 
      attempts, 
      userId: session.user.id,
      lastQueryTime: lastQueryTime ? new Date(lastQueryTime).toISOString() : null
    });
    
    // First check if the user has admin access
    const { data: profile, error: profileError } = await supabase
      .from('profiles')
      .select('is_admin')
      .eq('id', session.user.id)
      .single();
      
    if (profileError) {
      throw new Error(`Profile check failed: ${profileError.message}`);
    }
    
    if (!profile.is_admin) {
      throw new Error('Admin access required');
    }
    
    // Dispatch event to notify of markets query start
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYNC_START, {
      userId: session.user.id,
      attempts: attempts,
      timestamp: new Date().toISOString()
    });
    
    // Query for all markets with admin visibility
    const { data: markets, error } = await supabase
      .rpc('get_all_markets_with_participation', {
        user_id: session.user.id
      });
      
    if (error) {
      logger.error('Error fetching markets:', error);
      
      // Dispatch event for query completion with error
      dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYNC_COMPLETE, {
        success: false,
        error: error.message,
        timestamp: new Date().toISOString()
      });
      
      throw new Error(`Failed to fetch markets: ${error.message}`);
    }
    
    if (!markets || markets.length === 0) {
      logger.info('No markets found');
      
      // Dispatch event for empty result
      dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYNC_COMPLETE, {
        success: true,
        empty: true,
        attempts: attempts,
        timestamp: new Date().toISOString()
      });
      
      return [];
    }
    
    const end = performance.now();
    
    // Log success and timing information
    logger.info('Markets fetched successfully', {
      count: markets.length,
      duration: `${Math.round(end - start)}ms`,
      attempts: attempts
    });
    
    // Dispatch event for successful query
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYNC_COMPLETE, {
      success: true,
      marketsCount: markets.length,
      attempts: attempts,
      timestamp: new Date().toISOString()
    });
    
    return markets;
  } catch (error) {
    const err = error as Error;
    const end = performance.now();
    
    // Log the error with timing information
    logger.error('Error in fetchMarketsData:', {
      message: err.message,
      duration: `${Math.round(end - start)}ms`,
      attempts: attempts,
      stack: err.stack
    });
    
    // Dispatch event for query error
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYNC_COMPLETE, {
      success: false,
      error: err.message,
      timestamp: new Date().toISOString()
    });
    
    throw err;
  }
};
