import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { handleError } from "@/utils/error-handler";
import { toast } from "@/hooks/use-toast";

/**
 * Enhanced utility for handling database transactions with proper error management
 * and financial tracking support
 */
export const executeTransaction = async <T>(
  operations: () => Promise<T>,
  context: string,
  errorOptions: {
    fallbackMessage: string;
    logDetails?: Record<string, any>;
    showToast?: boolean;
  }
): Promise<T> => {
  try {
    // Begin the transaction
    const { error: beginError } = await supabase.rpc('begin_transaction');
    if (beginError) {
      throw beginError;
    }
    logger.info('Transaction started', { context });
    
    // Execute operations
    const result = await operations();
    
    // Commit the transaction
    const { error: commitError } = await supabase.rpc('commit_transaction');
    if (commitError) {
      throw commitError;
    }
    logger.info('Transaction committed successfully', { context });
    
    return result;
  } catch (error) {
    // Attempt to rollback the transaction on error
    try {
      const { error: rollbackError } = await supabase.rpc('rollback_transaction');
      if (rollbackError) {
        throw rollbackError;
      }
      logger.info('Transaction rolled back successfully', { context });
    } catch (rollbackError) {
      logger.error('Failed to rollback transaction', { 
        context, 
        rollbackError,
        originalError: error 
      });
    }
    
    // Handle the original error
    handleError(error, {
      context,
      ...errorOptions
    });
    
    throw error;
  }
};

/**
 * Creates a financial transaction record in the database
 */
export async function createFinancialTransaction(
  userId: string,
  amount: number,
  type: 'deposit' | 'withdrawal' | 'market_entry' | 'earnings' | 'loss',
  description: string,
  referenceNumber?: string,
  proofOfPaymentUrl?: string,
  marketId?: string
): Promise<{ success: boolean; data?: any; error?: any }> {
  try {
    // Validate inputs
    if (!userId || amount <= 0) {
      throw new Error('Invalid transaction parameters');
    }

    // Create transaction record
    const { data, error } = await supabase
      .from('transactions')
      .insert({
        user_id: userId,
        amount,
        type,
        status: type === 'earnings' ? 'approved' : 'pending', // Auto-approve earnings
        description,
        reference_number: referenceNumber,
        proof_of_payment_url: proofOfPaymentUrl,
        market_id: marketId,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .select('*')
      .single();

    if (error) {
      throw error;
    }

    logger.info(`${type} transaction created:`, { 
      transactionId: data.id,
      userId,
      amount,
      status: data.status
    });

    return { success: true, data };
  } catch (error) {
    handleError(error, {
      context: 'Financial Transaction',
      fallbackMessage: `Failed to create ${type} transaction`,
      logDetails: { userId, amount, type },
      showToast: true
    });
    return { success: false, error };
  }
};

/**
 * Approves a financial transaction and updates user balance
 * Only for admin use
 */
export async function approveTransaction(
  transactionId: string,
  adminMessage?: string
): Promise<{ success: boolean; data?: any; error?: any }> {
  return executeTransaction(
    async () => {
      // Update transaction status
      const { data, error } = await supabase
        .from('transactions')
        .update({
          status: 'approved',
          admin_message: adminMessage,
          updated_at: new Date().toISOString()
        })
        .eq('id', transactionId)
        .select('*')
        .single();

      if (error) {
        throw error;
      }

      logger.info('Transaction approved:', {
        transactionId,
        userId: data.user_id,
        amount: data.amount,
        type: data.type
      });

      return { success: true, data };
    },
    'Transaction Approval',
    {
      fallbackMessage: 'Failed to approve transaction',
      logDetails: { transactionId },
      showToast: true
    }
  ).catch(error => ({ success: false, error }));
};

/**
 * Rejects a financial transaction
 * Only for admin use
 */
export async function rejectTransaction(
  transactionId: string,
  adminMessage: string
): Promise<{ success: boolean; data?: any; error?: any }> {
  try {
    const { data, error } = await supabase
      .from('transactions')
      .update({
        status: 'rejected',
        admin_message: adminMessage,
        updated_at: new Date().toISOString()
      })
      .eq('id', transactionId)
      .select('*')
      .single();

    if (error) {
      throw error;
    }

    logger.info('Transaction rejected:', {
      transactionId,
      userId: data.user_id,
      reason: adminMessage
    });

    return { success: true, data };
  } catch (error) {
    handleError(error, {
      context: 'Transaction Rejection',
      fallbackMessage: 'Failed to reject transaction',
      logDetails: { transactionId },
      showToast: true
    });
    return { success: false, error };
  }
};

/**
 * Creates a market entry transaction and updates user balance
 */
export async function createMarketEntryTransaction(
  userId: string,
  marketId: string,
  amount: number
): Promise<{ success: boolean; data?: any; error?: any }> {
  return executeTransaction(
    async () => {
      // Create market entry transaction
      const { data, error } = await supabase
        .from('transactions')
        .insert({
          user_id: userId,
          market_id: marketId,
          amount,
          type: 'market_entry',
          status: 'approved', // Auto-approve market entries
          description: 'Market participation fee',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        })
        .select('*')
        .single();

      if (error) {
        throw error;
      }

      logger.info('Market entry transaction created:', { 
        transactionId: data.id,
        userId,
        marketId,
        amount
      });

      return { success: true, data };
    },
    'Market Entry',
    {
      fallbackMessage: 'Failed to process market entry',
      logDetails: { userId, marketId, amount },
      showToast: true
    }
  ).catch(error => ({ success: false, error }));
};

/**
 * Calculates market participation cost for a user
 */
export async function calculateParticipationCost(
  userId: string,
  marketId: string
): Promise<number> {
  try {
    const { data, error } = await supabase
      .rpc('calculate_market_participation_cost', {
        user_id: userId,
        market_id: marketId
      });

    if (error) {
      throw error;
    }

    return data || 0;
  } catch (error) {
    handleError(error, {
      context: 'Participation Cost Calculation',
      fallbackMessage: 'Failed to calculate participation cost',
      logDetails: { userId, marketId },
      showToast: false
    });
    return 0;
  }
};

/**
 * Distribute earnings to market participants
 * Only for admin use or automated processes
 */
export async function distributeMarketEarnings(
  marketId: string
): Promise<{ success: boolean; error?: any }> {
  try {
    const { data, error } = await supabase
      .rpc('distribute_market_earnings', {
        market_id: marketId
      });

    if (error) {
      throw error;
    }

    logger.info('Market earnings distributed:', { marketId });
    
    // Show success toast
    toast({
      title: "Earnings Distributed",
      description: "Market earnings have been distributed to participants",
    });

    return { success: true };
  } catch (error) {
    handleError(error, {
      context: 'Earnings Distribution',
      fallbackMessage: 'Failed to distribute market earnings',
      logDetails: { marketId },
      showToast: true
    });
    return { success: false, error };
  }
};

/**
 * Updates market configuration
 * Only for admin use
 */
export const updateMarketConfiguration = async ({
  marketId,
  dailyTarget,
  adjustmentFactor,
  requiresApproval,
  maxParticipations
}: {
  marketId: string;
  dailyTarget: number;
  adjustmentFactor: number;
  requiresApproval: boolean;
  maxParticipations: number;
}): Promise<void> => {
  try {
    // Get the current user ID for admin verification
    const currentUser = (await supabase.auth.getUser()).data.user;
    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    // Call the Supabase RPC function with correctly named parameters
    const { error } = await supabase.rpc('update_market_configuration', {
      market_id_param: marketId,
      daily_contribution_target: dailyTarget,  // Match parameter name with SQL function
      adjustment_factor: adjustmentFactor,
      requires_approval: requiresApproval,
      max_participations: maxParticipations,
      admin_id: currentUser.id
    });

    if (error) {
      logger.error('Failed to update market configuration:', error);
      throw error;
    }

    logger.info('Market configuration updated successfully', {
      marketId,
      dailyTarget,
      adjustmentFactor,
      requiresApproval,
      maxParticipations
    });
  } catch (error) {
    handleError(error, {
      context: 'Market Configuration Update',
      fallbackMessage: 'Failed to update market configuration',
      logDetails: { marketId },
      showToast: true
    });
    throw error;
  }
};

/**
 * Updates market status
 * Only for admin use
 */
export const updateMarketStatus = async (
  marketId: string, 
  newStatus: string
): Promise<boolean> => {
  try {
    // Get the current user ID for admin verification
    const currentUser = (await supabase.auth.getUser()).data.user;
    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    // Make sure we use the correct parameter names that match the RPC function
    const { error } = await supabase.rpc('update_market_status', {
      market_id: marketId,  // Changed from market_id_param to match the RPC function
      new_status: newStatus,
      admin_id: currentUser.id
    });

    if (error) {
      logger.error('Failed to update market status:', error);
      throw error;
    }

    logger.info('Market status updated successfully', {
      marketId,
      newStatus
    });

    toast({
      title: "Status Updated",
      description: `Market status changed to ${newStatus}`,
    });

    return true;
  } catch (error) {
    handleError(error, {
      context: 'Market Status Update',
      fallbackMessage: 'Failed to update market status',
      logDetails: { marketId, newStatus },
      showToast: true
    });
    return false;
  }
};

export function get_admin_financial_overview() {
  // Properly typed Supabase RPC call using the extended types
  return supabase.rpc('get_admin_financial_overview')
    .then((response) => {
      const { data, error } = response;
      if (error) {
        // We should handle the error here since the DB function is failing
        logger.error('Failed to get financial overview:', error);
        throw error;
      }
      return data;
    });
}

/**
 * Fetches market history entries
 * Uses a type casting approach to handle the market_history table that isn't in the TypeScript types
 */
export const fetchMarketHistory = async (
  marketId: string,
  dateRange?: { from: Date; to?: Date },
  sortDirection: 'asc' | 'desc' = 'desc'
) => {
  try {
    // Use a type cast approach since market_history isn't in the TypeScript types yet
    let query = supabase
      .from('market_history' as any)
      .select('*, admin:performed_by(email)')
      .eq('market_id', marketId);
    
    // Apply date filtering if provided
    if (dateRange?.from) {
      query = query.gte('performed_at', dateRange.from.toISOString());
    }
    
    if (dateRange?.to) {
      // Add one day to include the end date
      const endDate = new Date(dateRange.to);
      endDate.setDate(endDate.getDate() + 1);
      query = query.lt('performed_at', endDate.toISOString());
    }
    
    // Apply sorting
    query = query.order('performed_at', { ascending: sortDirection === 'asc' });
    
    const { data, error } = await query;
    
    if (error) throw error;
    
    return data;
  } catch (error) {
    handleError(error, {
      context: 'Market History',
      fallbackMessage: 'Failed to fetch market history',
      logDetails: { marketId },
      showToast: false
    });
    throw error;
  }
};
