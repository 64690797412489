
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { RefreshCw } from "lucide-react";
import { WaitingListTable } from "./WaitingListTable";
import { WaitingUser } from "@/hooks/admin/use-waiting-list";

interface WaitingListCardProps {
  users: WaitingUser[];
  isLoading: boolean;
  onApprove: (userId: string) => Promise<void>;
  onReject: (userId: string) => Promise<void>;
  onRefresh: () => void;
}

export function WaitingListCard({
  users,
  isLoading,
  onApprove,
  onReject,
  onRefresh
}: WaitingListCardProps) {
  return (
    <Card className="hover:shadow-lg transition-shadow">
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <CardTitle>Waiting List</CardTitle>
            <CardDescription>Approve or reject pending registration requests</CardDescription>
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={onRefresh}
            className="flex items-center gap-1"
          >
            <RefreshCw className="h-4 w-4" />
            Refresh
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <WaitingListTable
          users={users}
          isLoading={isLoading}
          onApprove={onApprove}
          onReject={onReject}
        />
      </CardContent>
    </Card>
  );
}
