
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";

export interface ParticipationVerificationResult {
  exists: boolean;
  participationId?: string;
  approvalStatus?: string;
  status?: string;
  metadata?: any;
  error?: any;
}

/**
 * Utility for consistent market participation verification
 */
export class ParticipationVerifier {
  private static instance: ParticipationVerifier;
  
  // Cache verified participations to reduce DB queries
  private verifiedParticipations: Map<string, ParticipationVerificationResult> = new Map();
  
  private constructor() {}
  
  static getInstance(): ParticipationVerifier {
    if (!ParticipationVerifier.instance) {
      ParticipationVerifier.instance = new ParticipationVerifier();
    }
    return ParticipationVerifier.instance;
  }
  
  /**
   * Verify if a user is participating in a market
   * @param useCache - Whether to use cached results (default: true)
   */
  async verifyParticipation(
    userId: string,
    marketId: string,
    options?: { useCache?: boolean; force?: boolean }
  ): Promise<ParticipationVerificationResult> {
    // Cache key
    const cacheKey = `${userId}:${marketId}`;
    
    // Check cache first unless disabled
    const useCache = options?.useCache !== false;
    
    if (useCache && !options?.force && this.verifiedParticipations.has(cacheKey)) {
      logger.debug('Using cached participation verification:', { 
        userId, 
        marketId 
      });
      
      return this.verifiedParticipations.get(cacheKey)!;
    }
    
    try {
      logger.info('Verifying market participation:', { userId, marketId });
      
      // Query for participation and approval status
      const { data, error } = await supabase
        .from("market_participants")
        .select("id, status, approval_status, metadata")
        .eq("user_id", userId)
        .eq("market_id", marketId)
        .maybeSingle();
        
      if (error) {
        logger.error('Error verifying market participation:', {
          error,
          userId,
          marketId
        });
        
        return { exists: false, error };
      }
      
      if (!data) {
        const result = { exists: false };
        
        // Cache the negative result
        if (useCache) {
          this.verifiedParticipations.set(cacheKey, result);
        }
        
        return result;
      }
      
      // Create verification result
      const result: ParticipationVerificationResult = {
        exists: true,
        participationId: data.id,
        status: data.status,
        approvalStatus: data.approval_status,
        metadata: data.metadata
      };
      
      // Cache the result
      if (useCache) {
        this.verifiedParticipations.set(cacheKey, result);
      }
      
      logger.info('Participation verification result:', {
        exists: true,
        participationId: data.id,
        status: data.status,
        approvalStatus: data.approval_status
      });
      
      return result;
    } catch (error) {
      logger.error('Exception during market participation verification:', {
        error,
        userId,
        marketId
      });
      
      return { exists: false, error };
    }
  }
  
  /**
   * Manually cache a participation result
   * Useful when we already have the result from another source
   */
  cacheParticipationResult(
    userId: string,
    marketId: string,
    result: ParticipationVerificationResult
  ): void {
    const cacheKey = `${userId}:${marketId}`;
    this.verifiedParticipations.set(cacheKey, result);
    logger.debug('Manually cached participation result:', {
      userId,
      marketId,
      result
    });
  }
  
  /**
   * Clear verification cache for a specific user/market or all if not specified
   */
  clearCache(userId?: string, marketId?: string): void {
    if (userId && marketId) {
      this.verifiedParticipations.delete(`${userId}:${marketId}`);
    } else {
      this.verifiedParticipations.clear();
    }
  }
  
  /**
   * Get the size of the cache for monitoring
   */
  getCacheSize(): number {
    return this.verifiedParticipations.size;
  }
  
  /**
   * Reconcile the cache with the database to ensure consistency
   * This is used to fix data inconsistency issues
   */
  async reconcileCache(userId: string): Promise<number> {
    try {
      logger.info('Reconciling participation cache for user:', userId);
      
      // Get all participations for this user from the database
      const { data, error } = await supabase
        .from("market_participants")
        .select("market_id, id, status, approval_status")
        .eq("user_id", userId);
        
      if (error) {
        logger.error('Error fetching participations for reconciliation:', error);
        return 0;
      }
      
      if (!data || !Array.isArray(data)) {
        logger.warn('No participation data returned for reconciliation');
        return 0;
      }
      
      // Update cache with fresh data
      let updatedCount = 0;
      data.forEach(p => {
        const cacheKey = `${userId}:${p.market_id}`;
        const result: ParticipationVerificationResult = {
          exists: true,
          participationId: p.id,
          status: p.status,
          approvalStatus: p.approval_status
        };
        
        this.verifiedParticipations.set(cacheKey, result);
        updatedCount++;
      });
      
      logger.info('Participation cache reconciled:', {
        userId,
        updatedCount,
        totalParticipations: data.length
      });
      
      return updatedCount;
    } catch (error) {
      logger.error('Exception during participation cache reconciliation:', error);
      return 0;
    }
  }
}

// Export singleton instance
export const participationVerifier = ParticipationVerifier.getInstance();
