
/**
 * Market-specific error types
 */
export type MarketErrorType = 
  | 'forbidden'    // Access permission errors
  | 'network'      // Network-related errors
  | 'cors'         // Cross-origin request issues
  | 'recursion'    // Recursive operation errors
  | 'deadlock'     // Database deadlock issues
  | 'timeout'      // Request timeout errors
  | 'validation'   // Data validation errors
  | 'not-found'    // Resource not found errors
  | 'authentication' // Authentication-related errors
  | 'auth'         // General auth errors
  | 'server'       // Server-side errors
  | 'circuit-open' // Circuit breaker open state
  | 'unknown';     // Unclassified errors

/**
 * Extended Error class with additional properties for application errors
 */
export class AppError extends Error {
  public readonly type: MarketErrorType;
  public readonly code?: string;
  public readonly retryable: boolean;
  public readonly details?: Record<string, any>;

  constructor(options: {
    message: string;
    type: MarketErrorType;
    code?: string;
    retryable?: boolean;
    details?: Record<string, any>;
  }) {
    super(options.message);
    this.name = 'AppError';
    this.type = options.type;
    this.code = options.code;
    this.retryable = options.retryable ?? false;
    this.details = options.details;
    
    // Capture stack trace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AppError);
    }
  }

  /**
   * Create a non-retryable error
   */
  static nonRetryable(message: string, type: MarketErrorType, details?: Record<string, any>): AppError {
    return new AppError({
      message,
      type,
      retryable: false,
      details
    });
  }
  
  /**
   * Create a retryable error
   */
  static retryable(message: string, type: MarketErrorType, details?: Record<string, any>): AppError {
    return new AppError({
      message,
      type,
      retryable: true,
      details
    });
  }
  
  /**
   * Create a server error
   */
  static server(message: string, code?: string): AppError {
    return new AppError({
      message,
      type: 'server',
      code,
      retryable: true
    });
  }
  
  /**
   * Create a network error
   */
  static network(message: string): AppError {
    return new AppError({
      message,
      type: 'network',
      retryable: true
    });
  }
  
  /**
   * Create an authentication error
   */
  static auth(message: string): AppError {
    return new AppError({
      message,
      type: 'auth',
      retryable: false
    });
  }
  
  /**
   * Check if an error is an AppError
   */
  static isAppError(error: any): error is AppError {
    return error instanceof AppError;
  }
}

/**
 * Interface for error details
 */
export interface ErrorDetails {
  message: string;
  code?: string;
  details?: string;
  hint?: string;
}
