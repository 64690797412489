
import React from 'react';

const MarketsLoading: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-[80vh]">
      <div className="text-center">
        <div className="animate-spin w-12 h-12 border-t-2 border-primary rounded-full mx-auto mb-4"></div>
        <p className="text-muted-foreground">Verifying authentication...</p>
      </div>
    </div>
  );
};

export default MarketsLoading;
