
import React, { useState, useEffect, useCallback } from 'react';
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { logger } from "@/utils/logger";
import { categorizeError } from '@/utils/error-handling/error-handler';
import { MarketErrorType } from '@/utils/error-handling/error-types';
import { EnhancedErrorDisplay } from './EnhancedErrorDisplay';
import { CircuitBreakerRegistry } from '@/utils/error-handling/circuit-breaker';

interface ImprovedMarketsErrorHandlerProps {
  marketError: Error | null;
  retryCount: number;
  refreshMarkets: () => Promise<void>;
  children: React.ReactNode;
  errorType?: MarketErrorType | null;
  marketMetadata?: Record<string, any>;
}

const ImprovedMarketsErrorHandler: React.FC<ImprovedMarketsErrorHandlerProps> = ({
  marketError,
  retryCount,
  refreshMarkets,
  children,
  errorType: providedErrorType,
  marketMetadata
}) => {
  const [errorType, setErrorType] = useState<MarketErrorType | null>(null);
  const [isRetrying, setIsRetrying] = useState(false);
  const [displayedErrorTime, setDisplayedErrorTime] = useState<string | null>(null);
  const circuitBreakerKey = 'markets-refresh';

  // Determine error type when error changes
  useEffect(() => {
    if (!marketError) {
      setErrorType(null);
      return;
    }

    // Record when we first saw this error
    if (!displayedErrorTime) {
      setDisplayedErrorTime(new Date().toISOString());
    }

    // Use provided errorType if available, otherwise categorize the error
    const detectedType = providedErrorType || categorizeError(marketError);
    setErrorType(detectedType);
    
    // Record the failure in the circuit breaker
    const breaker = CircuitBreakerRegistry.getBreaker(circuitBreakerKey);
    breaker.recordFailure();
    
    logger.error('Market error detected:', { 
      message: marketError.message,
      type: detectedType,
      retryCount,
      errorTime: displayedErrorTime || new Date().toISOString(),
      circuitState: breaker.getState(),
      failureCount: breaker.getFailureCount()
    });
  }, [marketError, retryCount, providedErrorType, displayedErrorTime, circuitBreakerKey]);

  // Handle refresh with retry state and circuit breaker
  const handleRefresh = useCallback(async () => {
    if (isRetrying) return;
    
    const breaker = CircuitBreakerRegistry.getBreaker(circuitBreakerKey);
    
    // Check if circuit breaker allows the request
    if (!breaker.canRequest()) {
      logger.warn('Circuit breaker preventing request:', {
        circuitKey: circuitBreakerKey,
        state: breaker.getState(),
        failureCount: breaker.getFailureCount()
      });
      return;
    }
    
    try {
      setIsRetrying(true);
      logger.info('Attempting to refresh markets after error');
      await refreshMarkets();
      
      // Record success to potentially reset circuit breaker
      breaker.recordSuccess();
    } catch (error) {
      // Record failure in circuit breaker
      breaker.recordFailure();
      logger.error('Error during refresh attempt:', {
        error,
        circuitState: breaker.getState(),
        failureCount: breaker.getFailureCount()
      });
    } finally {
      setIsRetrying(false);
    }
  }, [isRetrying, refreshMarkets, circuitBreakerKey]);

  if (marketError && errorType) {
    // Prepare system info for debugging
    const systemInfo = {
      retryCount,
      errorFirstDisplayedAt: displayedErrorTime,
      currentTime: new Date().toISOString(),
      browser: navigator.userAgent,
      viewport: `${window.innerWidth}x${window.innerHeight}`,
      circuitState: CircuitBreakerRegistry.getBreaker(circuitBreakerKey).getState(),
      failureCount: CircuitBreakerRegistry.getBreaker(circuitBreakerKey).getFailureCount(),
      ...(marketMetadata || {})
    };
    
    return (
      <DashboardSidebar>
        <div className="container mx-auto p-4">
          <EnhancedErrorDisplay
            error={marketError}
            errorType={errorType}
            retryCount={retryCount}
            onRetry={handleRefresh}
            systemInfo={systemInfo}
          />
        </div>
      </DashboardSidebar>
    );
  }

  return <>{children}</>;
};

export default ImprovedMarketsErrorHandler;
