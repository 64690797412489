
import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AdminMarketsList } from "@/components/admin/markets/AdminMarketsList";
import { useAuth } from '@/contexts/AuthContext';
import { useMarketsManagement } from '@/hooks/admin/markets/use-markets-management';
import { Button } from '@/components/ui/button';
import { RefreshCw } from 'lucide-react';

const MarketsAdmin = () => {
  const { session } = useAuth();
  const { 
    markets, 
    isLoading, 
    handleRefresh 
  } = useMarketsManagement(session);

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="pb-3 flex flex-row items-center justify-between">
          <CardTitle>Markets Management</CardTitle>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => handleRefresh()}
            disabled={isLoading}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        </CardHeader>
        <CardContent>
          <AdminMarketsList 
            markets={markets} 
            isLoading={isLoading} 
            onRefresh={handleRefresh}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default MarketsAdmin;
