import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { BarChart3, Lock, Shield, Users, ArrowRight } from "lucide-react";
import ScrollSection from "@/components/ScrollSection";
import { useNavigate } from "react-router-dom";
import { AspectRatio } from "@/components/ui/aspect-ratio";

const ExclusiveAccess = () => {
  const navigate = useNavigate();

  const features = [
    {
      icon: <Lock className="w-16 h-16 text-primary" />,
      title: "Power",
      description:
        "Access advanced trading tools and exclusive market opportunities that drive superior performance.",
    },
    {
      icon: <Shield className="w-16 h-16 text-primary" />,
      title: "Precision",
      description:
        "Leverage data-driven insights and automated workflows for precise market execution.",
    },
    {
      icon: <BarChart3 className="w-16 h-16 text-primary" />,
      title: "Performance",
      description:
        "Track and optimize your portfolio with real-time analytics and performance metrics.",
    },
    {
      icon: <Users className="w-16 h-16 text-primary" />,
      title: "Elite Network",
      description:
        "Join a curated community of successful investors and industry leaders.",
    },
  ];

  return (
    <div className="min-h-screen bg-background">
      {/* Hero Section */}
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-background">
          <div className="absolute inset-0 bg-[url('/lovable-uploads/342a542b-fe5e-4b1f-a550-d5707f90b1c3.png')] bg-cover bg-center opacity-40" />
        </div>
        
        <div className="container mx-auto px-4 pt-20 pb-32 relative">
          <ScrollSection>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center max-w-5xl mx-auto"
            >
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-primary mb-6 leading-tight">
                Power. Precision. Performance.
              </h1>
              <p className="text-xl sm:text-2xl text-primary-foreground/90 mb-8 leading-relaxed">
                A cutting-edge web platform for seamless member management, market tracking, and automated financial workflows—built for efficiency, driven by data.
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <Button
                  size="lg"
                  className="bg-primary hover:bg-primary/90 text-primary-foreground group"
                  onClick={() => navigate("/apply-now")}
                >
                  Request Access
                  <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
                </Button>
                <Button
                  size="lg"
                  variant="outline"
                  className="border-primary text-primary hover:bg-primary/10"
                  onClick={() => navigate("/benefits")}
                >
                  Explore Benefits
                </Button>
              </div>
            </motion.div>
          </ScrollSection>

          {/* Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-24">
            {features.map((feature, index) => (
              <ScrollSection key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="roman-card hover:scale-105 transition-all duration-300"
                >
                  <div className="flex flex-col items-center text-center p-8">
                    <div className="mb-6 transform hover:scale-110 transition-all duration-300">
                      {feature.icon}
                    </div>
                    <h3 className="text-2xl font-bold text-primary mb-4">
                      {feature.title}
                    </h3>
                    <p className="text-lg text-primary-foreground/80">
                      {feature.description}
                    </p>
                  </div>
                </motion.div>
              </ScrollSection>
            ))}
          </div>
        </div>
      </div>

      {/* Bottom CTA Section */}
      <div className="bg-muted/50 py-20">
        <div className="container mx-auto px-4">
          <ScrollSection>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h2 className="text-3xl font-bold text-primary mb-6">
                Ready to Join The Colosseum?
              </h2>
              <p className="text-xl text-primary-foreground/80 mb-8 max-w-2xl mx-auto">
                Take the first step towards exclusive market access and join our elite community of traders and investors.
              </p>
              <Button
                size="lg"
                className="bg-primary hover:bg-primary/90 text-primary-foreground group"
                onClick={() => navigate("/apply-now")}
              >
                Apply Now
                <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
              </Button>
            </motion.div>
          </ScrollSection>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveAccess;