
import { QueryClient } from "@tanstack/react-query";
import { logger } from "@/utils/logger";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
    mutations: {}
  }
});

// Add global error handlers using the correct subscription method
queryClient.getQueryCache().subscribe(event => {
  if (event.type === 'updated') {
    // Only access error if it exists on the query
    const query = event.query;
    if (query.state.status === 'error') {
      logger.error('Query error:', query.state.error);
    }
  }
});

queryClient.getMutationCache().subscribe(event => {
  if (event.type === 'updated') {
    // Only access error if it exists on the mutation
    const mutation = event.mutation;
    if (mutation.state.status === 'error') {
      logger.error('Mutation error:', mutation.state.error);
    }
  }
});
