
import { useState, useEffect, useCallback } from "react";
import { Market } from "@/types/market";
import { Session } from "@supabase/supabase-js";
import { getMarketParticipationCounts } from "@/utils/market/getMarketParticipationCounts";
import { logger } from "@/utils/logger";

export const useParticipationCounts = (
  markets: Market[] | null,
  session: Session | null
) => {
  const [participationCounts, setParticipationCounts] = useState<Record<string, number>>({});
  const [loadingParticipations, setLoadingParticipations] = useState(false);

  const refreshCounts = useCallback(async () => {
    if (!markets || !markets.length || !session?.user?.id) {
      return;
    }

    try {
      setLoadingParticipations(true);
      
      // Get market IDs where the user is participating
      const participatingMarketIds = markets
        .filter(m => m.isParticipating)
        .map(m => m.id);
        
      if (participatingMarketIds.length === 0) {
        setParticipationCounts({});
        return;
      }
      
      // Fetch participation counts for these markets
      const counts = await getMarketParticipationCounts(
        session.user.id,
        participatingMarketIds,
        session
      );
      
      if (counts) {
        setParticipationCounts(counts);
      }
    } catch (error) {
      logger.error('Error fetching participation counts:', error);
    } finally {
      setLoadingParticipations(false);
    }
  }, [markets, session]);

  // Initial load of participation counts
  useEffect(() => {
    refreshCounts();
  }, [refreshCounts]);

  return { participationCounts, loadingParticipations, refreshCounts };
};
