
import { useState, useEffect } from "react";
import { Switch } from "@/components/ui/switch";
import { Sun, Moon } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

const ThemeToggle = () => {
  const { toast } = useToast();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Initialize from localStorage or system preference
    if (typeof window !== 'undefined') {
      const stored = localStorage.getItem("theme");
      if (stored) return stored === "dark";
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
    return false;
  });

  useEffect(() => {
    // Apply theme on mount and when it changes
    if (typeof document !== 'undefined') {
      document.documentElement.classList.toggle("dark", isDarkMode);
      localStorage.setItem("theme", isDarkMode ? "dark" : "light");
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    toast({
      title: !isDarkMode ? "Dark mode enabled" : "Light mode enabled",
      description: "Your theme preference has been updated.",
    });
  };

  return (
    <div className="flex items-center gap-3">
      <Sun className={`h-5 w-5 transition-colors ${isDarkMode ? 'text-gray-500' : 'text-amber-500'}`} />
      <Switch 
        checked={isDarkMode}
        onCheckedChange={toggleDarkMode} 
        aria-label="Toggle dark mode"
        className="data-[state=checked]:bg-zinc-800 data-[state=unchecked]:bg-blue-600"
      />
      <Moon className={`h-5 w-5 transition-colors ${isDarkMode ? 'text-blue-400' : 'text-gray-500'}`} />
    </div>
  );
};

export default ThemeToggle;
