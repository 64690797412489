
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { TrendingUp, Award, Star } from "lucide-react";

interface StreakTrackerCardProps {
  currentStreak: number;
  isLoading?: boolean;
}

export function StreakTrackerCard({ currentStreak, isLoading = false }: StreakTrackerCardProps) {
  const getStreakMessage = () => {
    if (currentStreak === 0) {
      return "Make your first deposit today!";
    } else if (currentStreak === 1) {
      return "You've started! Keep going!";
    } else if (currentStreak < 5) {
      return "Building momentum! Keep it up!";
    } else if (currentStreak < 10) {
      return "Impressive streak! You're on fire!";
    } else {
      return "Outstanding commitment! Amazing work!";
    }
  };

  return (
    <Card className="shadow-sm transition-all hover:shadow-md dark:bg-card bg-white">
      <CardHeader className="pb-2">
        <CardTitle className="flex items-center text-xl font-semibold text-primary">
          <TrendingUp className="mr-2 h-5 w-5" />
          Daily Banking Streak
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col items-center justify-center py-4">
          {currentStreak > 0 ? (
            <>
              <div className="flex items-center text-2xl font-bold dark:text-zinc-100 text-zinc-800">
                <Award className="mr-2 h-7 w-7 text-amber-500" />
                {currentStreak}-Day Streak!
              </div>
              <div className="mt-2 flex justify-center">
                {[...Array(Math.min(currentStreak, 7))].map((_, i) => (
                  <Star 
                    key={i}
                    className={`h-6 w-6 ${i < currentStreak ? 'text-amber-500 fill-amber-500' : 'text-gray-300'}`}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="text-center py-2 text-lg dark:text-zinc-300 text-zinc-700">Start your streak today!</div>
          )}
          
          <p className="mt-3 text-center text-sm dark:text-zinc-400 text-zinc-600">
            {getStreakMessage()}
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
