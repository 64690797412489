
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Market } from "@/types/market";
import { Loader2 } from "lucide-react";
import { useCallback, useState } from "react";
import { logger } from "@/utils/logger";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { MarketStatusBadge } from "@/components/markets/MarketStatusBadge";

interface MarketStatusControlsProps {
  market: Market;
  loading: boolean;
  onStatusUpdate: (status: Market['status']) => Promise<void>;
}

export const MarketStatusControls = ({
  market,
  loading,
  onStatusUpdate
}: MarketStatusControlsProps) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<Market['status']>(market.status);
  
  // Simplified handler that just passes the status to the parent without validation
  const handleStatusUpdate = useCallback(async () => {
    if (loading || localLoading || market.status === selectedStatus) return;
    
    try {
      setLocalLoading(true);
      logger.info(`Admin triggering market status update for ${market.id} from ${market.status} to ${selectedStatus}`);
      
      // Just call the provided handler without internal validation logic
      await onStatusUpdate(selectedStatus);
      
      logger.info(`Market ${market.id} status update triggered successfully`);
    } catch (error) {
      logger.error("Error triggering market status update:", error);
    } finally {
      setLocalLoading(false);
    }
  }, [loading, market.status, market.id, onStatusUpdate, localLoading, selectedStatus]);
  
  return (
    <div className="status-controls-container space-y-4 p-4 border rounded-md bg-muted/20">
      <div className="flex items-center justify-between">
        <Label className="font-medium">Current Market Status</Label>
        <MarketStatusBadge status={market.status} />
      </div>
      
      <div className="space-y-4">
        <Label>Request Market Status Change</Label>
        <Select
          value={selectedStatus}
          onValueChange={(value) => setSelectedStatus(value as Market['status'])}
          disabled={loading || localLoading}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select new status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="active">Active</SelectItem>
            <SelectItem value="closed">Closed</SelectItem>
            <SelectItem value="closed_successful">Closed (Successful)</SelectItem>
            <SelectItem value="closed_failed">Closed (Failed)</SelectItem>
          </SelectContent>
        </Select>
        
        <Button 
          onClick={handleStatusUpdate}
          disabled={loading || localLoading || market.status === selectedStatus} 
          type="button" 
          className="w-full"
        >
          {localLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
          Request Status Update
        </Button>
      </div>
    </div>
  );
};
