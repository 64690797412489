
import { useQuery } from "@tanstack/react-query";
import { Session } from "@supabase/supabase-js";
import { Market } from "@/types/market";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";

/**
 * Returns a consistent query key for markets
 * This ensures we're using the same key everywhere
 */
export const getMarketsQueryKey = (userId?: string) => {
  if (userId) {
    return ['markets', userId];
  }
  return ['markets'];
};

export const useMarkets = (session: Session | null) => {
  return useQuery({
    queryKey: getMarketsQueryKey(session?.user?.id),
    queryFn: async () => {
      if (!session?.user?.id) {
        return [] as Market[];
      }

      try {
        // Get user's market participations with approval status
        const { data: participations, error: participationError } = await supabase
          .from('market_participants')
          .select('market_id, approval_status')
          .eq('user_id', session.user.id);

        if (participationError) {
          logger.error('Error fetching participations:', participationError);
          return [] as Market[];
        }

        // Create a map of market IDs to approval status
        const participationMap = new Map<string, string>();
        participations?.forEach(p => {
          if (p.market_id) {
            participationMap.set(p.market_id, p.approval_status);
          }
        });

        // Fetch markets
        const { data: markets, error: marketsError } = await supabase
          .from('markets')
          .select('*')
          .order('created_at', { ascending: false });

        if (marketsError) {
          logger.error('Error fetching markets:', marketsError);
          return [] as Market[];
        }

        // Add isParticipating flag based on approval status and ensure all required Market properties exist
        return (markets?.map(market => {
          // Handle metadata to ensure it has the correct type
          let processedMetadata: Record<string, any> | string | null = null;
          
          if (market.metadata !== null) {
            if (typeof market.metadata === 'string') {
              // If it's already a string, use it directly
              processedMetadata = market.metadata;
            } else if (typeof market.metadata === 'object') {
              // If it's an object, use it as is
              processedMetadata = market.metadata as Record<string, any>;
            } else {
              // For other cases (including numbers), convert to string
              processedMetadata = String(market.metadata);
            }
          }
          
          // Create a properly typed Market object
          const marketWithRequiredProps: Market = {
            ...market,
            id: market.id,
            name: market.name,
            status: market.status,
            target_value: market.target_value,
            current_value: market.current_value,
            participant_count: market.active_participants || 0,
            daily_contributions: market.total_contributions || 0,
            daily_contribution_percentage: market.daily_contribution_target ? 
              (market.total_contributions || 0) / market.daily_contribution_target : 0,
            region: market.region || 'global',
            market_type: market.market_type || 'standard',
            market_risk_level: market.market_risk_level || 'medium',
            category: 'available',
            required_contributions: market.required_contributions || 8,
            growth_rate: 0,
            isParticipating: participationMap.has(market.id) && 
                            participationMap.get(market.id) === 'approved',
            metadata: processedMetadata
          };
          
          return marketWithRequiredProps;
        }) || []) as Market[];

      } catch (error) {
        logger.error('Exception in useMarkets:', error);
        return [] as Market[];
      }
    },
    enabled: !!session?.user?.id
  });
};
