
import { useState, useEffect } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { FormItem } from "@/components/ui/form";
import { 
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from "@/components/ui/collapsible";
import { EyeIcon, ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "@/components/ui/button";

interface DescriptionFieldProps {
  description: string;
  setDescription: (description: string) => void;
  sequencePosition: number | null;
  setSequencePosition: (position: number | null) => void;
  disabled?: boolean;
}

export const DescriptionField = ({ 
  description, 
  setDescription, 
  sequencePosition,
  setSequencePosition,
  disabled = false 
}: DescriptionFieldProps) => {
  const [open, setOpen] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const MAX_CHARS = 1000;

  useEffect(() => {
    setCharCount(description?.length || 0);
  }, [description]);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_CHARS) {
      setDescription(newValue);
    }
  };

  const handleSequenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setSequencePosition(null);
      return;
    }
    
    const numValue = parseInt(value, 10);
    if (!isNaN(numValue) && numValue >= 1 && numValue <= 8) {
      setSequencePosition(numValue);
    }
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Market Information</h3>
      
      <FormItem className="space-y-2">
        <Label htmlFor="description">
          Description
          <span className="text-xs text-muted-foreground ml-2">
            ({charCount}/{MAX_CHARS})
          </span>
        </Label>
        <Textarea
          id="description"
          placeholder="Enter market description"
          value={description}
          onChange={handleDescriptionChange}
          className="resize-none min-h-[120px]"
          disabled={disabled}
        />
        <p className="text-xs text-muted-foreground">
          Provide a detailed description of the market's purpose and guidelines.
        </p>
      </FormItem>

      <FormItem className="space-y-2">
        <Label htmlFor="sequencePosition">Sequence Position (1-8)</Label>
        <Input
          id="sequencePosition"
          type="number"
          min={1}
          max={8}
          placeholder="Optional position number"
          value={sequencePosition || ''}
          onChange={handleSequenceChange}
          disabled={disabled}
        />
        <p className="text-xs text-muted-foreground">
          Optional: Set the display order position (1-8) for this market in the sequence.
        </p>
      </FormItem>

      {description && (
        <Collapsible
          open={open}
          onOpenChange={setOpen}
          className="border rounded-md p-2 bg-muted/30"
        >
          <div className="flex items-center justify-between">
            <Label className="text-sm font-medium">Preview Description</Label>
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="sm">
                {open ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
              </Button>
            </CollapsibleTrigger>
          </div>
          <CollapsibleContent className="mt-2">
            <div className="rounded-md bg-card p-4 text-sm whitespace-pre-wrap">
              {description}
            </div>
          </CollapsibleContent>
        </Collapsible>
      )}
    </div>
  );
};
