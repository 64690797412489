
import { Market } from "@/types/market";
import { Settings, Users, FileText, Bell, History } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ConfigurationTab } from "./tabs/ConfigurationTab";
import { MembersTab } from "./tabs/MembersTab";
import { ReportsTab } from "./tabs/ReportsTab";
import { NotificationsTab } from "./tabs/NotificationsTab";
import { HistoryTab } from "./tabs/HistoryTab";

interface MarketConfigurationPanelProps {
  market: Market;
  onUpdate: (updatedMarket?: Market) => void;
}

export const MarketConfigurationPanel = ({ market, onUpdate }: MarketConfigurationPanelProps) => {
  return (
    <Tabs defaultValue="configuration" className="w-full">
      <TabsList className="grid w-full grid-cols-5 gap-4">
        <TabsTrigger value="configuration" className="flex items-center gap-2">
          <Settings className="h-4 w-4" />
          Configuration
        </TabsTrigger>
        <TabsTrigger value="members" className="flex items-center gap-2">
          <Users className="h-4 w-4" />
          Members
        </TabsTrigger>
        <TabsTrigger value="reports" className="flex items-center gap-2">
          <FileText className="h-4 w-4" />
          Reports
        </TabsTrigger>
        <TabsTrigger value="notifications" className="flex items-center gap-2">
          <Bell className="h-4 w-4" />
          Notifications
        </TabsTrigger>
        <TabsTrigger value="history" className="flex items-center gap-2">
          <History className="h-4 w-4" />
          History
        </TabsTrigger>
      </TabsList>

      <TabsContent value="configuration" className="space-y-4 mt-4">
        <ConfigurationTab market={market} onUpdate={onUpdate} />
      </TabsContent>

      <TabsContent value="members" className="mt-4">
        <MembersTab market={market} onUpdate={onUpdate} />
      </TabsContent>

      <TabsContent value="reports" className="mt-4">
        <ReportsTab market={market} />
      </TabsContent>

      <TabsContent value="notifications" className="mt-4">
        <NotificationsTab market={market} />
      </TabsContent>

      <TabsContent value="history" className="mt-4">
        <HistoryTab market={market} />
      </TabsContent>
    </Tabs>
  );
};
