
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import PortfolioChart from "./PortfolioChart";
import RecentMarkets from "./RecentMarkets";
import type { Market } from "@/types/market";

interface PortfolioSectionProps {
  chartData: Array<{ name: string; value: number }>;
  markets: Market[];
  isLoading: boolean;
  recentMarkets: {
    id: string;
    name: string;
    status: string;
    value: number;
    change: number;
  }[];
}

const PortfolioSection = ({ chartData, markets, isLoading, recentMarkets }: PortfolioSectionProps) => {
  return (
    <div className="grid gap-6 lg:grid-cols-7">
      <Card className="lg:col-span-4 bg-white dark:bg-card/50 dark:backdrop-blur-sm w-full border border-zinc-200 dark:border-zinc-700/50">
        <CardHeader className="p-6">
          <CardTitle className="text-lg md:text-xl font-semibold text-zinc-800 dark:text-zinc-100">
            Portfolio Performance
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6 pt-0">
          <div className="h-[300px] md:h-[400px] w-full">
            <PortfolioChart data={chartData} />
          </div>
        </CardContent>
      </Card>

      <RecentMarkets 
        recentMarkets={recentMarkets}
        isLoading={isLoading}
      />
    </div>
  );
};

export default PortfolioSection;
