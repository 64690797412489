
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { UserMarketsView } from "@/components/admin/markets/UserMarketsView";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { ChevronLeft } from "lucide-react";

export default function UserManagement() {
  const { userId } = useParams<{ userId: string }>();
  const [activeTab, setActiveTab] = useState('markets');
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    // You can add any initialization logic here if needed
  }, [userId]);

  return (
    <DashboardSidebar isAdmin>
      <div className="p-6 max-w-[1400px] mx-auto">
        <div className="mb-6">
          <Button
            variant="outline"
            onClick={() => navigate('/admin/users')}
            className="mb-4 gap-2"
          >
            <ChevronLeft className="h-4 w-4" />
            Back to Users
          </Button>
          <h1 className="text-3xl font-bold mb-2">User Management</h1>
          <p className="text-muted-foreground">
            Manage user markets, finances, and settings.
          </p>
        </div>

        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="mb-6">
            <TabsTrigger value="markets">Markets</TabsTrigger>
            <TabsTrigger value="finances">Finances</TabsTrigger>
            <TabsTrigger value="settings">Settings</TabsTrigger>
          </TabsList>

          <TabsContent value="markets">
            <UserMarketsView />
          </TabsContent>

          <TabsContent value="finances">
            <Card>
              <CardHeader>
                <CardTitle>Financial Overview</CardTitle>
              </CardHeader>
              <CardContent>
                <p>Financial management features will be available here.</p>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="settings">
            <Card>
              <CardHeader>
                <CardTitle>User Settings</CardTitle>
              </CardHeader>
              <CardContent>
                <p>User settings management will be available here.</p>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </DashboardSidebar>
  );
}
