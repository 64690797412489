
import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FileSpreadsheet, FileText, Download } from "lucide-react";
import { Market } from "@/types/market";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";

interface ReportsTabProps {
  market: Market;
}

export const ReportsTab = ({ market }: ReportsTabProps) => {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const generateReport = async (type: 'financial' | 'participation' | 'performance') => {
    setLoading(true);
    try {
      const reportData = {
        market_id: market.id,
        current_value: market.current_value,
        target_value: market.target_value,
        growth_rate: market.growth_rate,
        total_contributions: market.total_contributions,
        active_participants: market.active_participants,
        status: market.status
      };

      const { data, error } = await supabase
        .from('market_reports')
        .insert({
          market_id: market.id,
          report_type: type,
          data: reportData,
          metadata: {
            generated_at: new Date().toISOString(),
            type
          }
        })
        .select()
        .single();

      if (error) throw error;

      toast({
        title: "Report Generated",
        description: "Your report has been generated successfully.",
      });

    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to generate report.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Generate Reports</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Button
            variant="outline"
            className="h-24 flex flex-col items-center justify-center gap-2"
            onClick={() => generateReport('financial')}
            disabled={loading}
          >
            <FileSpreadsheet className="h-8 w-8 text-primary" />
            <span>Financial Report</span>
          </Button>

          <Button
            variant="outline"
            className="h-24 flex flex-col items-center justify-center gap-2"
            onClick={() => generateReport('participation')}
            disabled={loading}
          >
            <FileText className="h-8 w-8 text-primary" />
            <span>Participation Report</span>
          </Button>

          <Button
            variant="outline"
            className="h-24 flex flex-col items-center justify-center gap-2"
            onClick={() => generateReport('performance')}
            disabled={loading}
          >
            <Download className="h-8 w-8 text-primary" />
            <span>Performance Report</span>
          </Button>
        </div>
      </Card>

      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Recent Reports</h3>
        <p className="text-muted-foreground text-sm">
          Generated reports will appear here.
        </p>
      </Card>
    </div>
  );
};
