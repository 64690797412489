
import { useEffect, useState } from "react";
import { supabase } from "@/lib/supabase";
import { Card, CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { User } from "@/components/admin/users/types";
import { MarketForManagement } from "@/hooks/admin/use-user-market-management";
import { AlertCircle, Loader2 } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";

interface MemberMarketSelectorProps {
  onSelectMemberMarket: (userId: string, marketId: string) => void;
}

export const MemberMarketSelector = ({ onSelectMemberMarket }: MemberMarketSelectorProps) => {
  const [members, setMembers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [userMarkets, setUserMarkets] = useState<MarketForManagement[]>([]);
  const [selectedMarketId, setSelectedMarketId] = useState<string>("");
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [loadingMarkets, setLoadingMarkets] = useState(false);
  const { toast } = useToast();

  // Fetch all members
  useEffect(() => {
    const fetchMembers = async () => {
      setLoadingMembers(true);
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('id, first_name, last_name, email, status')
          .eq('is_admin', false)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setMembers(data as User[]);
      } catch (error) {
        console.error('Error fetching members:', error);
        toast({
          title: "Failed to load members",
          description: "Please try again later",
          variant: "destructive",
        });
      } finally {
        setLoadingMembers(false);
      }
    };

    fetchMembers();
  }, [toast]);

  // Fetch markets when a user is selected
  useEffect(() => {
    const fetchUserMarkets = async () => {
      if (!selectedUserId) {
        setUserMarkets([]);
        return;
      }

      setLoadingMarkets(true);
      try {
        const { data, error } = await supabase
          .from('market_participants')
          .select(`
            markets:market_id (
              id, name, status, region, target_value, current_value,
              market_type, market_risk_level, max_participations_per_user,
              entry_amount, active_participants
            )
          `)
          .eq('user_id', selectedUserId);

        if (error) throw error;

        if (data && data.length > 0) {
          const markets = data.map(item => ({
            id: item.markets.id,
            name: item.markets.name,
            status: item.markets.status,
            target_value: item.markets.target_value,
            current_value: item.markets.current_value,
            region: item.markets.region,
            market_type: item.markets.market_type,
            market_risk_level: item.markets.market_risk_level,
            active_participants: item.markets.active_participants,
            daily_contribution_target: 0,
            total_contributions: 0,
            max_participations_per_user: item.markets.max_participations_per_user,
            entry_amount: item.markets.entry_amount
          }));
          setUserMarkets(markets);
        } else {
          setUserMarkets([]);
        }
      } catch (error) {
        console.error('Error fetching user markets:', error);
        toast({
          title: "Failed to load markets",
          description: "Could not fetch markets for the selected user",
          variant: "destructive",
        });
        setUserMarkets([]);
      } finally {
        setLoadingMarkets(false);
      }
    };

    fetchUserMarkets();
  }, [selectedUserId, toast]);

  const handleUserChange = (value: string) => {
    // Reset market selection when user changes
    setSelectedUserId(value);
    setSelectedMarketId("");
  };

  const handleMarketChange = (value: string) => {
    setSelectedMarketId(value);
    // Call the parent callback with both selected values
    onSelectMemberMarket(selectedUserId, value);
  };

  return (
    <Card className="border-primary/20">
      <CardContent className="pt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <Label htmlFor="member-select">Select Member</Label>
            <Select 
              value={selectedUserId} 
              onValueChange={handleUserChange}
              disabled={loadingMembers}
            >
              <SelectTrigger id="member-select">
                <SelectValue placeholder="Select a member" />
              </SelectTrigger>
              <SelectContent>
                {loadingMembers ? (
                  <div className="flex items-center justify-center py-2">
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    Loading...
                  </div>
                ) : members.length === 0 ? (
                  <SelectItem value="no-members-found" disabled>
                    No members found
                  </SelectItem>
                ) : (
                  members.map(member => (
                    <SelectItem key={member.id} value={member.id}>
                      {member.first_name} {member.last_name} ({member.email})
                    </SelectItem>
                  ))
                )}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label htmlFor="market-select">Select Market</Label>
            <Select 
              value={selectedMarketId} 
              onValueChange={handleMarketChange}
              disabled={!selectedUserId || loadingMarkets}
            >
              <SelectTrigger id="market-select">
                <SelectValue placeholder={selectedUserId ? "Select a market" : "Select a member first"} />
              </SelectTrigger>
              <SelectContent>
                {!selectedUserId ? (
                  <SelectItem value="no-user-selected" disabled>
                    Please select a member first
                  </SelectItem>
                ) : loadingMarkets ? (
                  <div className="flex items-center justify-center py-2">
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    Loading markets...
                  </div>
                ) : userMarkets.length === 0 ? (
                  <SelectItem value="no-markets-found" disabled>
                    No markets found for this member
                  </SelectItem>
                ) : (
                  userMarkets.map(market => (
                    <SelectItem key={market.id} value={market.id}>
                      {market.name} ({market.region})
                    </SelectItem>
                  ))
                )}
              </SelectContent>
            </Select>
          </div>
        </div>

        {selectedUserId && userMarkets.length === 0 && !loadingMarkets && (
          <Alert variant="default" className="mt-4 bg-blue-50 border-blue-200">
            <AlertCircle className="h-5 w-5 text-blue-600" />
            <AlertDescription className="text-blue-800">
              This member is not participating in any markets.
            </AlertDescription>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};
