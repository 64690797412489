
import { useReducer, useRef } from "react";
import { authReducer, initialState } from "./authReducer";

export const useAuthState = () => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const mounted = useRef(true);
  const initializingAuth = useRef(false);

  return {
    state,
    dispatch,
    mounted,
    initializingAuth
  };
};
