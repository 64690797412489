
import { useState } from "react";
import { Market } from "@/types/market";
import { supabase } from "@/lib/supabase";
import { toast } from "sonner";
import { logger } from "@/utils/logger";
import { useMarketValidation } from "./use-market-validation";
import { useQueryInvalidation } from "./use-query-invalidation";
import { updateMarketDescription, updateSequencePosition } from "@/utils/market/metadata-update";

type ConfigValues = {
  dailyTarget: string;
  adjustmentFactor: string;
  requiresApproval: boolean;
  maxParticipationsPerUser: number;
  marketType: 'standard' | 'premium' | 'exclusive' | 'duplicate';
  marketRiskLevel: 'low' | 'medium' | 'high';
  expectedROI: number;
  maxDuplications: number;
  description?: string;
  sequencePosition?: number | null;
};

/**
 * Hook for updating market configuration
 */
export const useMarketUpdate = (market: Market, onUpdate: (updatedMarket?: Market) => void) => {
  const [saveError, setSaveError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  
  const { validateInputs } = useMarketValidation();
  const { invalidateMarketQueries } = useQueryInvalidation();

  const handleUpdateConfiguration = async (
    loading: boolean,
    setLoading: (loading: boolean) => void,
    configValues: ConfigValues
  ) => {
    const {
      dailyTarget,
      adjustmentFactor,
      requiresApproval,
      maxParticipationsPerUser,
      marketType,
      marketRiskLevel,
      expectedROI,
      maxDuplications,
      description,
      sequencePosition
    } = configValues;

    if (!validateInputs(
      dailyTarget, 
      adjustmentFactor, 
      maxParticipationsPerUser, 
      maxDuplications, 
      expectedROI
    )) return;

    try {
      setLoading(true);
      setIsSaving(true);
      setSaveError(null);
      
      logger.info('Starting market configuration update', { 
        marketId: market.id,
        dailyTarget,
        adjustmentFactor,
        requiresApproval,
        maxParticipationsPerUser,
        marketType,
        marketRiskLevel,
        expectedROI,
        maxDuplications,
        description,
        sequencePosition
      });

      // Parse numeric values before sending to ensure they're the right type
      const numericDailyTarget = parseFloat(dailyTarget);
      const numericAdjustmentFactor = parseFloat(adjustmentFactor);
      
      if (isNaN(numericDailyTarget) || isNaN(numericAdjustmentFactor)) {
        throw new Error('Invalid numeric values. Please check daily target and adjustment factor.');
      }

      // Get the current session to ensure we have a valid token
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError || !sessionData.session) {
        throw new Error('Authentication error: Please sign in again.');
      }

      // Use the edge function to update market configuration EXCEPT for description and sequence_position
      // which need to be handled separately via metadata
      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/update-market-config`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionData.session.access_token}`
        },
        body: JSON.stringify({
          marketId: market.id,
          dailyTarget: numericDailyTarget,
          adjustmentFactor: numericAdjustmentFactor,
          requiresApproval,
          maxParticipations: maxParticipationsPerUser,
          marketType,
          marketRiskLevel,
          expectedROI,
          maxDuplications
        })
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to update market configuration');
      }

      // Update description and sequence position via metadata if provided
      if (description !== undefined) {
        const { success, error } = await updateMarketDescription(market.id, description);
        if (!success) {
          logger.warn(`Failed to update market description: ${error}`, { marketId: market.id });
        }
      }
      
      if (sequencePosition !== undefined) {
        const { success, error } = await updateSequencePosition(market.id, sequencePosition);
        if (!success) {
          logger.warn(`Failed to update sequence position: ${error}`, { marketId: market.id });
        }
      }

      // Ensure we invalidate all relevant queries immediately
      await invalidateMarketQueries(market.id);
      
      toast.success("Market configuration has been updated successfully.");

      // Call the onUpdate callback with the updated market data
      if (responseData.market) {
        onUpdate(responseData.market);
      } else {
        onUpdate();
      }
    } catch (error) {
      const err = error as Error;
      logger.error('Failed to update market configuration:', { 
        error: err, 
        marketId: market.id 
      });
      setSaveError(err.message || "Failed to update market configuration.");
      toast.error(err.message || "Failed to update market configuration.");
    } finally {
      setLoading(false);
      setIsSaving(false);
    }
  };

  return { 
    handleUpdateConfiguration,
    saveError,
    isSaving
  };
};
