
import React from "react";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import * as z from "zod";
import { marketFormSchema } from "../config/formConfig";

interface DescriptionFieldProps {
  form: UseFormReturn<z.infer<typeof marketFormSchema>>;
  fields: string[];
}

export const DescriptionField = ({ form, fields }: DescriptionFieldProps) => {
  return (
    <>
      {fields.includes("description") && (
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Market Description</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter market description (optional)"
                  className="resize-none"
                  {...field}
                  value={field.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {fields.includes("sequence_position") && (
        <FormField
          control={form.control}
          name="sequence_position"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Sequence Position (1-8)</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  placeholder="Enter position number (optional)"
                  min={1}
                  max={8}
                  {...field}
                  onChange={(e) => field.onChange(e.target.value ? Number(e.target.value) : undefined)}
                  value={field.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </>
  );
};
