
import { useState, useEffect, useCallback } from "react";
import { supabase } from "@/lib/supabase";
import { Market } from "@/types/market";
import { useAuth } from "@/contexts/AuthContext";
import { logger } from "@/utils/logger";

export function useDashboardData() {
  const [markets, setMarkets] = useState<Market[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState(0);
  const { session } = useAuth();

  const fetchNotifications = async () => {
    try {
      const { data, error } = await supabase
        .from('market_notifications')
        .select('*')
        .eq('status', 'pending');
  
      if (error) {
        logger.error('Error fetching notifications:', error);
        return;
      }
  
      setNotifications(data?.length || 0);
    } catch (error) {
      logger.error('Unexpected error in fetchNotifications:', error);
    }
  };

  const fetchMarkets = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: marketsData, error } = await supabase
        .from('markets')
        .select(`
          *,
          market_participants (
            user_id
          )
        `)
        .eq('status', 'active')
        .order('created_at', { ascending: false });

      if (error) {
        logger.error('Error fetching markets:', error);
        return;
      }

      // Get user's participations
      let participatingMarketIds = new Set<string>();
      
      if (session?.user?.id) {
        const { data: participations, error: participationError } = await supabase
          .from('market_participants')
          .select('market_id')
          .eq('user_id', session.user.id);
  
        if (participationError) {
          logger.error('Error fetching participations:', participationError);
        } else {
          participatingMarketIds = new Set(participations?.map(p => p.market_id) || []);
          logger.info('Fetched user participations:', participatingMarketIds.size);
        }
      }

      const formattedMarkets: Market[] = marketsData.map(market => ({
        id: market.id,
        name: market.name,
        status: market.status,
        target_value: market.target_value,
        current_value: market.current_value,
        participant_count: market.market_participants?.length || 0,
        daily_contributions: market.total_contributions || 0,
        daily_contribution_percentage: (market.total_contributions / market.daily_contribution_target) * 100 || 0,
        region: market.region,
        growth_rate: market.growth_rate,
        category: market.category || 'available',
        required_contributions: market.required_contributions || 0,
        market_type: market.market_type || 'standard',
        market_risk_level: market.market_risk_level || 'medium',
        isParticipating: participatingMarketIds.has(market.id),
        // New fields for market duplication
        version: market.version,
        parent_market_id: market.parent_market_id,
        auto_retry_enabled: market.auto_retry_enabled,
        market_version_number: market.market_version_number,
        duplication_settings: market.duplication_settings
      }));

      setMarkets(formattedMarkets);
    } catch (error) {
      logger.error('Error in fetchMarkets:', error);
    } finally {
      setIsLoading(false);
    }
  }, [session]);

  // Define the refetch function as useCallback to avoid recreating it on each render
  const refetch = useCallback(async () => {
    try {
      await Promise.all([
        fetchMarkets(),
        fetchNotifications()
      ]);
      logger.info('Dashboard data refreshed successfully');
    } catch (error) {
      logger.error('Error refreshing dashboard data:', error);
    }
  }, [fetchMarkets]);

  // Initial data load
  useEffect(() => {
    refetch();
  }, [refetch]);

  // Sample chart data for portfolio performance
  const chartData = [
    { name: 'Jan', value: 1000 },
    { name: 'Feb', value: 1200 },
    { name: 'Mar', value: 1100 },
    { name: 'Apr', value: 1400 },
    { name: 'May', value: 1600 },
    { name: 'Jun', value: 1800 }
  ];

  // Get recent active markets for the RecentMarkets component
  const recentMarkets = markets
    .filter(market => market.status === 'active')
    .slice(0, 5)
    .map(market => ({
      id: market.id,
      name: market.name,
      status: market.status,
      value: market.current_value,
      change: market.growth_rate
    }));

  return {
    markets,
    isLoading,
    notifications,
    chartData,
    recentMarkets,
    refetch  // Now we're returning the refetch function
  };
}
