
import { useState, useCallback } from 'react';
import { useToast } from '@/hooks/use-toast';
import { logger } from '@/utils/logger';

export const useMarketsRefresh = (refetch: () => Promise<any>) => {
  const { toast } = useToast();
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshMarkets = useCallback(async () => {
    try {
      // Track refresh count to limit excessive refreshes
      const newRefreshCount = refreshCount + 1;
      
      // Limit refresh frequency
      if (newRefreshCount > 5) {
        const cooldownTime = Math.min(newRefreshCount * 2, 30);
        toast({
          title: "Too many refreshes",
          description: `Please wait ${cooldownTime} seconds before refreshing again`,
          variant: "default",
        });
        return;
      }
      
      setRefreshCount(newRefreshCount);
      
      logger.info('Manual refresh requested by user');
      
      // Show toast for user feedback
      toast({
        title: "Refreshing",
        description: "Updating market data...",
      });
      
      await refetch();
      logger.info('Markets refreshed successfully');
    } catch (error) {
      logger.error('Error refreshing markets:', error);
      toast({
        title: "Refresh Error",
        description: "There was an error refreshing the markets data",
        variant: "destructive",
      });
    }
  }, [refreshCount, refetch, toast]);

  return { refreshMarkets };
};
