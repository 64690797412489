
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { formatCurrency } from "@/utils/formatters";

interface ChartData {
  name: string;
  value: number;
}

interface PortfolioChartProps {
  data: ChartData[];
}

const PortfolioChart = ({ data }: PortfolioChartProps) => {
  return (
    <Card className="col-span-4 bg-white dark:bg-card/50 dark:backdrop-blur-sm border border-zinc-200 dark:border-zinc-700/50">
      <CardHeader>
        <CardTitle className="text-zinc-800 dark:text-zinc-100">Portfolio Performance</CardTitle>
      </CardHeader>
      <CardContent className="pl-2">
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <XAxis 
                dataKey="name" 
                stroke="#888888" 
                fontSize={12} 
                tickLine={false} 
                axisLine={false} 
              />
              <YAxis 
                stroke="#888888" 
                fontSize={12} 
                tickLine={false} 
                axisLine={false} 
                tickFormatter={(value) => `R${value}`} 
              />
              <Tooltip 
                formatter={(value) => formatCurrency(value as number)}
                contentStyle={{ 
                  backgroundColor: 'white', 
                  borderColor: '#e2e8f0',
                  color: '#1f2937',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                }}
                labelStyle={{ color: '#4b5563' }}
              />
              <Line 
                type="monotone" 
                dataKey="value" 
                stroke="#CDA434" 
                strokeWidth={2} 
                dot={false} 
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default PortfolioChart;
