
import React from 'react';
import { cn } from '@/lib/utils';
import { Globe } from 'lucide-react';

interface GroupSeparatorProps {
  title: string;
  count?: number;
  icon?: React.ReactNode;
  className?: string;
}

export const GroupSeparator: React.FC<GroupSeparatorProps> = ({ 
  title, 
  count, 
  icon,
  className 
}) => {
  return (
    <div className={cn("flex items-center gap-2 py-1", className)}>
      <div className="flex items-center gap-2 bg-muted/60 px-3 py-1 rounded-full shadow-sm">
        {icon || <Globe className="h-4 w-4 text-primary" />}
        <h3 className="text-md font-medium capitalize text-zinc-800 dark:text-zinc-50">{title}</h3>
      </div>
      {count !== undefined && (
        <div className="text-sm font-medium text-zinc-700 dark:text-zinc-300 px-2 py-0.5 bg-muted/40 rounded-full">
          {count} {count === 1 ? 'market' : 'markets'}
        </div>
      )}
      <div className="flex-1 h-px bg-border" />
    </div>
  );
};
