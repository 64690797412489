
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { LucideIcon } from "lucide-react";
import { cn } from "@/lib/utils";

interface MarketStatsCardProps {
  title: string;
  value: string | number;
  icon: LucideIcon;
  subValue?: string;
  trend?: 'up' | 'down' | 'neutral';
  iconColor?: string;
  badgeText?: string;
  badgeVariant?: "default" | "secondary" | "destructive" | "outline" | null;
}

export const MarketStatsCard = ({
  title,
  value,
  icon: Icon,
  subValue,
  trend,
  iconColor = "text-muted-foreground",
  badgeText,
  badgeVariant = "secondary"
}: MarketStatsCardProps) => {
  return (
    <Card className="overflow-hidden border-border/50 hover:border-primary/30 transition-all duration-300">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 bg-muted/20">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <Icon className={cn("h-4 w-4", iconColor)} />
      </CardHeader>
      <CardContent className="pt-4">
        <div className="flex items-center gap-2">
          <div className="text-2xl font-bold">{value}</div>
          {badgeText && (
            <Badge variant={badgeVariant} className="text-xs">
              {badgeText}
            </Badge>
          )}
        </div>
        {subValue && (
          <p className="text-xs text-muted-foreground mt-1">
            {subValue}
          </p>
        )}
      </CardContent>
    </Card>
  );
};
