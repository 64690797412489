import { useEffect, useState } from "react";
import { Card } from "@/components/ui/card";
import { Market, MarketParticipant } from "@/types/market";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { MembersTable } from "../members/MembersTable";
import { ErrorDisplay } from "../members/ErrorDisplay";
import { transformMemberData } from "../members/MemberDataTransformer";
import { Skeleton } from "@/components/ui/skeleton";
import { AlertCircle } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMarketStatus } from "@/hooks/markets/use-market-status";
import { toast } from "sonner";
import { logMarketHistory, MarketHistoryAction } from "@/utils/market/history/log-market-history";
import { MemberMarketSelector } from "../selection/MemberMarketSelector";
import { MemberMarketConfiguration } from "../MemberMarketConfiguration";
import { extractErrorDetails, logSupabaseError } from "@/utils/error-handling/supabase-error";
import { AppError } from "@/utils/error-handling/error-types";

interface MembersTabProps {
  market: Market;
  onUpdate: () => void;
}

export const MembersTab = ({ market, onUpdate }: MembersTabProps) => {
  const [participants, setParticipants] = useState<MarketParticipant[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState<string>("Initializing");
  const [error, setError] = useState<string | null>(null);
  const [errorDetails, setErrorDetails] = useState<string | null>(null);
  const { toast: uiToast } = useToast();
  const [selectedParticipant, setSelectedParticipant] = useState<string | null>(null);
  const [closeDialogOpen, setCloseDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedMarketId, setSelectedMarketId] = useState<string | null>(null);
  
  const { updateMarketStatus, isUpdating: statusUpdating } = useMarketStatus();

  useEffect(() => {
    if (selectedUserId && selectedMarketId) {
      fetchParticipants(selectedMarketId);
    }
  }, [selectedUserId, selectedMarketId]);

  const fetchParticipants = async (marketId: string) => {
    try {
      setLoading(true);
      setLoadingStage("Preparing query");
      setError(null);
      setErrorDetails(null);

      logger.info('Fetching participants for market:', marketId);
      setLoadingStage("Accessing database");
      
      const { data, error, status } = await supabase
        .from('market_participants')
        .select('*')
        .eq('market_id', marketId)
        .limit(50);

      logger.info('Query response:', { 
        dataReceived: !!data, 
        dataCount: data?.length,
        hasError: !!error
      });

      if (error) {
        const errorInfo = extractErrorDetails(error);
        logSupabaseError('Fetching market participants', error, { marketId });
        setError(errorInfo.message);
        setErrorDetails(`Status: ${status}, Code: ${errorInfo.code || 'None'}, Hint: ${errorInfo.hint || 'None'}, Details: ${errorInfo.details || 'None'}`);
        throw new AppError({
          message: `Failed to fetch participants: ${errorInfo.message}`,
          type: 'server',
          code: errorInfo.code,
          retryable: true,
          details: { marketId, error: errorInfo }
        });
      }

      if (data) {
        logger.info('Participants data received:', data.length);
        setLoadingStage("Processing participant data");
        
        const typedParticipants = transformMemberData(data);
        setParticipants(typedParticipants);
        setLoadingStage("Complete");
        logger.info('Participants data processed successfully');
      } else {
        logger.info('No participants data received');
        setParticipants([]);
      }
    } catch (error) {
      const err = error instanceof Error ? error : new Error('Unknown error');
      logger.error('Error fetching participants:', {
        message: err.message,
        stack: err.stack,
        isAppError: AppError.isAppError(error),
        errorType: AppError.isAppError(error) ? (error as AppError).type : 'unknown'
      });
      
      setError(err.message);
      setErrorDetails(err.stack || 'No stack trace available');
      
      uiToast({
        title: "Error loading participants",
        description: err.message,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleParticipantAction = async (userId: string, action: 'approve' | 'suspend') => {
    try {
      setLoadingStage(`Processing ${action} action`);
      
      const { error } = await supabase.rpc('manage_market_member', {
        p_market_id: selectedMarketId,
        p_user_id: userId,
        p_action: action,
        p_reason: action === 'suspend' ? 'Administrative action' : null
      });

      if (error) {
        logger.error('Participant action error:', error);
        throw new Error(`${error.message} (Code: ${error.code})`);
      }

      uiToast({
        title: "Success",
        description: `User ${action === 'approve' ? 'approved' : 'suspended'} successfully.`,
      });

      if (selectedMarketId) {
        fetchParticipants(selectedMarketId);
      }
      onUpdate();
    } catch (error) {
      const err = error as Error;
      logger.error('Action error:', err);
      uiToast({
        title: "Error",
        description: `Failed to ${action} user: ${err.message}`,
        variant: "destructive",
      });
    }
  };

  const handleCloseForParticipant = (userId: string) => {
    setSelectedParticipant(userId);
    setCloseDialogOpen(true);
  };

  const confirmCloseForParticipant = async () => {
    if (!selectedParticipant || !selectedMarketId) return;
    
    try {
      logger.info('Closing market for user:', {
        marketId: selectedMarketId,
        userId: selectedParticipant
      });
      
      const { error: participantError } = await supabase
        .from('market_participants')
        .update({
          status: 'completed',
          approval_status: 'closed_by_admin',
          close_time: new Date().toISOString(),
          metadata: {
            closed_by_admin: true,
            closed_at: new Date().toISOString()
          }
        })
        .eq('market_id', selectedMarketId)
        .eq('user_id', selectedParticipant);
        
      if (participantError) {
        throw new Error(`Failed to update participant: ${participantError.message}`);
      }
      
      const currentUser = await supabase.auth.getUser().then(res => res.data.user?.id);
      
      await logMarketHistory({
        marketId: selectedMarketId,
        action: 'member_removed' as MarketHistoryAction,
        details: {
          user_id: selectedParticipant,
          closed_by: currentUser,
          timestamp: new Date().toISOString()
        }
      });
      
      toast("Market closed for user successfully");
      setCloseDialogOpen(false);
      
      if (selectedMarketId) {
        fetchParticipants(selectedMarketId);
      }
      onUpdate();
      
    } catch (error) {
      const err = error as Error;
      logger.error('Error closing market for user:', err);
      toast(`Failed to close market for user: ${err.message}`);
    }
  };
  
  const handleSelection = (userId: string, marketId: string) => {
    setSelectedUserId(userId);
    setSelectedMarketId(marketId);
  };

  return (
    <div className="space-y-6">
      <MemberMarketSelector onSelect={handleSelection} />
      
      {selectedUserId && selectedMarketId ? (
        <div className="space-y-6">
          <Card className="p-4">
            {loading ? (
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <Skeleton className="h-4 w-4 rounded-full" />
                  <Skeleton className="h-4 w-36" />
                </div>
                <p className="text-sm text-muted-foreground">
                  {loadingStage}...
                </p>
                <div className="space-y-2">
                  <Skeleton className="h-10 w-full" />
                  <Skeleton className="h-20 w-full" />
                  <Skeleton className="h-20 w-full" />
                </div>
              </div>
            ) : error ? (
              <ErrorDisplay 
                error={error} 
                errorDetails={errorDetails} 
                onRetry={() => selectedMarketId && fetchParticipants(selectedMarketId)} 
              />
            ) : participants.length === 0 ? (
              <div className="text-center py-10">
                <AlertCircle className="h-8 w-8 mx-auto mb-2 text-muted-foreground" />
                <h3 className="text-lg font-medium mb-1">No participants found</h3>
                <p className="text-sm text-muted-foreground mb-4">This market currently has no participants.</p>
              </div>
            ) : (
              <MembersTable 
                members={participants}
                onApprove={(userId) => handleParticipantAction(userId, 'approve')}
                onSuspend={(userId) => handleParticipantAction(userId, 'suspend')}
                onCloseForMember={handleCloseForParticipant}
              />
            )}
          </Card>
          
          <MemberMarketConfiguration />
        </div>
      ) : (
        <Card className="p-8">
          <div className="text-center">
            <AlertCircle className="h-12 w-12 mx-auto mb-3 text-primary/50" />
            <h3 className="text-xl font-medium mb-2">Select a Member and Market</h3>
            <p className="text-muted-foreground mb-2">
              Please select both a member and market from above to view participation details.
            </p>
          </div>
        </Card>
      )}
      
      <Dialog open={closeDialogOpen} onOpenChange={setCloseDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Close Market for User</DialogTitle>
            <DialogDescription>
              Are you sure you want to close this market for the selected user? 
              This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setCloseDialogOpen(false)}>
              Cancel
            </Button>
            <Button 
              variant="destructive" 
              onClick={confirmCloseForParticipant}
            >
              Close Market for User
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
