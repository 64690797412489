
import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { Market } from "@/types/market";

// Define a specific interface for market data to be used in the management components
export interface MarketForManagement {
  id: string;
  name: string;
  status: string;
  target_value: number;
  current_value: number;
  region: string;
  market_type: string;
  market_risk_level: string;
  active_participants: number;
  daily_contribution_target: number;
  total_contributions: number;
  max_participations_per_user: number;
  entry_amount: number;
  created_at?: string;
  updated_at?: string;
  metadata?: Record<string, any> | null;
}

// Helper function to convert Market to MarketForManagement
export const convertToMarketForManagement = (market: any): MarketForManagement => {
  const metadata = typeof market.metadata === 'string'
    ? JSON.parse(market.metadata)
    : market.metadata || {};

  return {
    id: market.id,
    name: market.name,
    status: market.status || 'unknown',
    target_value: market.target_value || 0,
    current_value: market.current_value || 0,
    region: market.region || '',
    market_type: market.market_type || 'standard',
    market_risk_level: market.market_risk_level || 'medium',
    active_participants: market.active_participants || 0,
    daily_contribution_target: market.daily_contribution_target || 0,
    total_contributions: market.total_contributions || 0,
    max_participations_per_user: market.max_participations_per_user || 1,
    entry_amount: market.entry_amount || 0,
    created_at: market.created_at,
    updated_at: market.updated_at,
    metadata
  };
};

export const useUserMarketManagement = (userId: string, marketId: string) => {
  const [userMarkets, setUserMarkets] = useState<MarketForManagement[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { toast } = useToast();

  useEffect(() => {
    const fetchUserMarkets = async () => {
      if (!userId || !marketId) {
        setUserMarkets([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        // Fetch user participation in the specific market
        const { data, error } = await supabase
          .from('market_participants')
          .select(`
            markets:market_id (*)
          `)
          .eq('user_id', userId)
          .eq('market_id', marketId);

        if (error) throw error;

        if (data && data.length > 0) {
          // Format the markets data to match the MarketForManagement interface
          const marketList: MarketForManagement[] = data.map(item => 
            convertToMarketForManagement(item.markets)
          );
          
          setUserMarkets(marketList);
        } else {
          setUserMarkets([]);
        }
      } catch (error) {
        console.error('Error fetching user markets:', error);
        toast({
          title: "Error",
          description: "Failed to load market data",
          variant: "destructive",
        });
        setUserMarkets([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUserMarkets();
  }, [userId, marketId, refreshTrigger, toast]);

  const updateMarketStatus = async (marketId: string, status: string): Promise<boolean> => {
    try {
      const { error } = await supabase
        .from('market_participants')
        .update({ status })
        .eq('market_id', marketId)
        .eq('user_id', userId);

      if (error) throw error;

      toast({
        title: "Status Updated",
        description: `Market participation status updated to ${status}`,
      });

      // Refresh data after successful update
      refreshData();
      
      return true;
    } catch (error) {
      console.error('Error updating market status:', error);
      toast({
        title: "Update Failed",
        description: "Could not update market status",
        variant: "destructive",
      });
      return false;
    }
  };

  const refreshData = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  return {
    userMarkets,
    loading,
    updateMarketStatus,
    refreshData
  };
};
