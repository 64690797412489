
import { TableRow, TableCell } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { MarketParticipant } from "@/types/market";
import { formatCurrency } from "@/utils/formatters";
import { BadgeCheck, XCircle, Ban, DoorClosed } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface MemberRowProps {
  member: MarketParticipant;
  onApprove: (userId: string) => void;
  onSuspend: (userId: string) => void;
  onCloseForMember?: (userId: string) => void;
}

export const MemberRow = ({ member, onApprove, onSuspend, onCloseForMember }: MemberRowProps) => {
  return (
    <TableRow className="hover:bg-zinc-50 dark:hover:bg-zinc-800/80 border-b border-zinc-200 dark:border-zinc-700 transition-colors">
      <TableCell className="font-mono text-xs text-zinc-700 dark:text-zinc-300 transition-colors">{member.user_id}</TableCell>
      <TableCell>
        <span className={`${
          member.approval_status === 'approved' 
            ? 'text-green-500 dark:text-green-400' 
            : member.approval_status === 'suspended' 
              ? 'text-red-500 dark:text-red-400' 
              : 'text-amber-500 dark:text-amber-400'
        } transition-colors`}>
          {member.approval_status || 'pending'}
        </span>
      </TableCell>
      <TableCell className="text-zinc-700 dark:text-zinc-300 transition-colors">{formatCurrency(member.entry_amount || 0)}</TableCell>
      <TableCell className="text-zinc-700 dark:text-zinc-300 transition-colors">{formatCurrency(member.current_earnings || 0)}</TableCell>
      <TableCell>
        <div className="flex space-x-2">
          {member.approval_status !== 'approved' && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onApprove(member.user_id)}
                    className="h-8 px-2 border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-800 hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors"
                  >
                    <BadgeCheck className="h-4 w-4 text-green-500" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="bg-white dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 border-zinc-200 dark:border-zinc-700">
                  <p>Approve User</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          
          {member.approval_status !== 'suspended' && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onSuspend(member.user_id)}
                    className="h-8 px-2 border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-800 hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors"
                  >
                    <Ban className="h-4 w-4 text-red-500" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="bg-white dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 border-zinc-200 dark:border-zinc-700">
                  <p>Suspend User</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          
          {onCloseForMember && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onCloseForMember(member.user_id)}
                    className="h-8 px-2 border-zinc-300 dark:border-zinc-600 bg-white dark:bg-zinc-800 hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors"
                  >
                    <DoorClosed className="h-4 w-4 text-amber-500" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="bg-white dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 border-zinc-200 dark:border-zinc-700">
                  <p>Close Market for User</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};
