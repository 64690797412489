
import React from 'react';
import { Badge } from '@/components/ui/badge';

interface MarketStatusBadgeProps {
  status: string;
  participationStatus?: string | null | undefined;
  className?: string;
  showUserStatus?: boolean; // Added to toggle between market status and user status
  startDate?: string | null; // Added startDate property
}

export const MarketStatusBadge = ({ 
  status, 
  participationStatus, 
  className = '',
  showUserStatus = false, // Default to showing market status
  startDate
}: MarketStatusBadgeProps) => {
  // If showing user status and we have a participation status, use the UserMarketStatusBadge
  if (showUserStatus && participationStatus) {
    return <UserMarketStatusBadge status={participationStatus} className={className} />;
  }

  // Otherwise show the regular market status
  const getStatusDetails = (status: string): { label: string; variant: 'default' | 'success' | 'destructive' | 'outline' | 'secondary' } => {
    switch (status.toLowerCase()) {
      case 'active':
        return { label: 'Active', variant: 'default' };
      case 'closed':
        return { label: 'Closed', variant: 'secondary' };
      case 'closed_successful':
      case 'completed':
        return { label: 'Completed', variant: 'success' };
      case 'closed_failed':
      case 'failed':
        return { label: 'Failed', variant: 'destructive' };
      case 'pending':
        return { label: 'Pending', variant: 'outline' };
      default:
        return { label: status, variant: 'outline' };
    }
  };

  const { label, variant } = getStatusDetails(status);

  return (
    <Badge variant={variant} className={className}>
      {label}
    </Badge>
  );
};

import { UserMarketStatusBadge } from './UserMarketStatusBadge';
