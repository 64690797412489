
import { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';
import { normalizeRegionCode } from '@/utils/market/formatters';
import { toast } from 'sonner';
import { syncRegionalTablesFromTargets } from '@/utils/market/sync-regional-tables';

export interface RegionalTargetData {
  regionCode: string;
  baseTarget: number;
  adjustment: number;
  factor: number;
  targetValue: number;
}

export const useRegionalTargets = (userId?: string) => {
  const [targets, setTargets] = useState<Record<string, RegionalTargetData>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const fetchTargets = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      logger.info(`Fetching regional targets for user: ${userId || 'system'}`);
      
      let query = supabase
        .from('regional_targets')
        .select('*');
        
      if (userId) {
        query = query.eq('user_id', userId);
      }
      
      const { data, error } = await query;
      
      if (error) throw error;
      
      // Transform and normalize data
      const normalizedTargets: Record<string, RegionalTargetData> = {};
      
      data?.forEach(target => {
        // Always normalize region code to our standard format
        const regionCode = normalizeRegionCode(target.region_code);
        
        normalizedTargets[regionCode] = {
          regionCode,
          baseTarget: target.base_target,
          adjustment: target.adjustment,
          factor: target.factor,
          targetValue: target.target_value
        };
      });
      
      logger.info(`Fetched ${Object.keys(normalizedTargets).length} regional targets for user ${userId || 'system'}`);
      
      setTargets(normalizedTargets);
      setLastUpdated(new Date());
    } catch (err) {
      const errorObj = err instanceof Error ? err : new Error('Unknown error fetching targets');
      logger.error('Error fetching regional targets:', errorObj);
      setError(errorObj);
      toast.error(`Failed to load targets: ${errorObj.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  const updateTarget = useCallback(async (
    region: string,
    targetValue: number,
    baseTarget?: number,
    adjustment?: number,
    factor?: number
  ) => {
    if (!userId) {
      toast.error('Cannot update target: No user specified');
      return false;
    }
    
    try {
      // Normalize region code to uppercase
      const regionCode = normalizeRegionCode(region);
      
      // Get current values if not provided
      if (baseTarget === undefined || adjustment === undefined || factor === undefined) {
        const currentTarget = targets[regionCode];
        
        if (currentTarget) {
          baseTarget = currentTarget.baseTarget;
          adjustment = currentTarget.adjustment;
          factor = currentTarget.factor;
        } else {
          // Get default values from system target
          const { data, error } = await supabase
            .from('regional_targets')
            .select('base_target, adjustment, factor')
            .eq('user_id', '00000000-0000-0000-0000-000000000000')
            .eq('region_code', regionCode)
            .single();
            
          if (error) throw error;
          
          baseTarget = data.base_target;
          adjustment = data.adjustment;
          factor = data.factor;
        }
      }
      
      // Directly update the regional_targets table for this specific user's target
      const { error } = await supabase
        .from('regional_targets')
        .upsert({
          user_id: userId,
          region_code: regionCode,
          base_target: baseTarget!,
          adjustment: adjustment!,
          factor: factor!,
          target_value: targetValue,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,region_code'
        });
      
      if (error) {
        logger.error(`Error updating target for user ${userId}, region ${regionCode}:`, error);
        toast.error(`Failed to update target: ${error.message}`);
        return false;
      }
      
      // Update local state immediately for better UX
      setTargets(prev => ({
        ...prev,
        [regionCode]: {
          regionCode,
          baseTarget: baseTarget!,
          adjustment: adjustment!,
          factor: factor!,
          targetValue
        }
      }));
      
      setLastUpdated(new Date());
      
      // Dispatch event to notify of the update
      window.dispatchEvent(new CustomEvent('user-target-updated', {
        detail: {
          userId,
          region: regionCode,
          targetValue,
          timestamp: new Date().toISOString()
        }
      }));
      
      return true;
    } catch (err) {
      const errorObj = err instanceof Error ? err : new Error('Unknown error updating target');
      logger.error('Error updating regional target:', errorObj);
      toast.error(`Failed to update target: ${errorObj.message}`);
      return false;
    }
  }, [userId, targets]);
  
  const forceRefresh = useCallback(() => {
    setRefreshCounter(prev => prev + 1);
  }, []);

  // Initial fetch
  useEffect(() => {
    fetchTargets();
  }, [fetchTargets, refreshCounter]);

  // Set up realtime subscription with improved event handling
  useEffect(() => {
    const subscription = supabase
      .channel('regional_targets_changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'regional_targets',
        filter: userId ? `user_id=eq.${userId}` : undefined
      }, (payload) => {
        logger.info(`Realtime update for regional targets (${userId || 'system'})`, payload);
        fetchTargets();
      })
      .subscribe();
      
    // Listen for custom events with improved user-specific handling
    const handleTargetUpdate = (event: Event) => {
      const customEvent = event as CustomEvent;
      const detail = customEvent.detail || {};
      const eventUserId = detail.userId;
      
      logger.info(`Regional target event received: ${event.type}`, {
        ...detail,
        currentUserId: userId,
        eventUserId: eventUserId
      });
      
      // Only update if this event is for our specific user or is a system-wide event
      // This ensures member-specific updates don't affect other members
      if (
        // No user filter is set (system-wide view)
        !userId ||
        // This event is specifically for our user
        eventUserId === userId ||
        // This is a system-wide event that should refresh all views
        event.type === 'system-targets-updated' ||
        // This is a user-target-updated event for our specific user
        (event.type === 'user-target-updated' && eventUserId === userId) ||
        // Member targets synced event that includes our user
        (event.type === 'member-targets-synced' && 
          detail.members && 
          Array.isArray(detail.members) && 
          detail.members.includes(userId))
      ) {
        fetchTargets();
      }
    };
    
    const eventTypes = [
      'user-target-updated',
      'member-target-updated',
      'member-targets-synced',
      'regional-targets-updated',
      'regional-settings-updated',
      'system-targets-updated',
      'all-member-targets-refreshed',
    ];
    
    eventTypes.forEach(type => {
      window.addEventListener(type, handleTargetUpdate);
    });
    
    return () => {
      supabase.removeChannel(subscription);
      
      eventTypes.forEach(type => {
        window.removeEventListener(type, handleTargetUpdate);
      });
    };
  }, [fetchTargets, userId]);

  return {
    targets,
    isLoading,
    error,
    lastUpdated,
    fetchTargets,
    updateTarget,
    forceRefresh
  };
};
