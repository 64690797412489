
import { Session } from "@supabase/supabase-js";
import { Card, CardContent } from "@/components/ui/card";
import { format } from "date-fns";
import { BarChart3, Calendar, TrendingUp } from "lucide-react";

interface WelcomeBannerProps {
  session: Session | null;
}

export function WelcomeBanner({ session }: WelcomeBannerProps) {
  const firstName = session?.user?.user_metadata?.first_name || "Member";
  const now = new Date();
  const greeting = getGreeting(now);
  const formattedDate = format(now, "EEEE, MMMM d, yyyy");
  
  return (
    <Card className="border-none shadow-sm bg-gradient-to-r from-primary/10 to-primary/5">
      <CardContent className="p-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="space-y-2">
            <h2 className="text-2xl font-bold text-primary">
              {greeting}, {firstName}
            </h2>
            <p className="text-muted-foreground flex items-center gap-2">
              <Calendar className="h-4 w-4" />
              {formattedDate}
            </p>
          </div>
          
          <div className="flex items-center gap-4 mt-4 md:mt-0">
            <div className="flex flex-col items-center p-3 bg-background rounded-lg border border-border/50 transition-colors hover:border-primary/30">
              <BarChart3 className="h-5 w-5 text-primary mb-1" />
              <span className="text-xs font-medium">Analytics</span>
            </div>
            <div className="flex flex-col items-center p-3 bg-background rounded-lg border border-border/50 transition-colors hover:border-primary/30">
              <TrendingUp className="h-5 w-5 text-green-500 mb-1" />
              <span className="text-xs font-medium">Performance</span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function getGreeting(date: Date): string {
  const hours = date.getHours();
  if (hours < 12) {
    return "Good morning";
  } else if (hours < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
}
