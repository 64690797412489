
import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { ArrowUpIcon, ArrowDownIcon, Info } from "lucide-react";
import { MonthlyFinancialSummary as Summary } from "@/utils/financials/member-financials";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface MonthlyFinancialSummaryProps {
  summary: Summary | null;
  dailyTotals: {
    totalDeposits: number;
    startBalance: number;
    endBalance: number;
  };
  isLoading: boolean;
  memberSharePercentage: number;
}

export const MonthlyFinancialSummary: React.FC<MonthlyFinancialSummaryProps> = ({
  summary,
  dailyTotals,
  isLoading,
  memberSharePercentage = 15
}) => {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  const deposits = summary?.total_deposits || dailyTotals.totalDeposits;
  const memberShare = summary?.member_share_amount || (deposits * memberSharePercentage / 100);
  const companyShare = summary?.company_share_amount || (deposits * (100 - memberSharePercentage) / 100);
  const ytdTotal = summary?.ytd_total || 0;
  
  const openingBalance = dailyTotals.startBalance;
  const closingBalance = dailyTotals.endBalance;
  const balanceChange = closingBalance - openingBalance;
  const isPositiveChange = balanceChange >= 0;

  return (
    <TooltipProvider>
      <Card className="shadow-sm border-zinc-200 dark:border-zinc-700 bg-white dark:bg-card">
        <CardHeader className="pb-3">
          <CardTitle className="text-lg text-zinc-800 dark:text-zinc-200">Monthly Financial Summary</CardTitle>
        </CardHeader>
        <CardContent className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="space-y-1">
            <p className="text-sm text-zinc-700 dark:text-zinc-400 flex items-center">
              Month Bank Deposits
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="h-4 w-4 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                </TooltipTrigger>
                <TooltipContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                  <p className="text-zinc-800 dark:text-zinc-200">Total bank deposits recorded for the month.</p>
                  <p className="text-zinc-700 dark:text-zinc-300">These are amounts you've entered as deposited.</p>
                </TooltipContent>
              </Tooltip>
            </p>
            {isLoading ? (
              <Skeleton className="h-8 w-32" />
            ) : (
              <p className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">{formatCurrency(deposits)}</p>
            )}
            <p className="text-xs text-zinc-600 dark:text-zinc-500">Total bank deposits for the month</p>
          </div>
          
          <div className="space-y-1">
            <p className="text-sm text-zinc-700 dark:text-zinc-400 flex items-center">
              Balance Change
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="h-4 w-4 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                </TooltipTrigger>
                <TooltipContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                  <p className="text-zinc-800 dark:text-zinc-200">The difference between your manually entered opening and closing balances for the month.</p>
                  <p className="text-zinc-700 dark:text-zinc-300">This reflects your overall cash position change according to your daily records.</p>
                </TooltipContent>
              </Tooltip>
            </p>
            {isLoading ? (
              <Skeleton className="h-8 w-32" />
            ) : (
              <div className="flex items-center">
                {isPositiveChange ? (
                  <ArrowUpIcon className="h-4 w-4 mr-1 text-green-500" />
                ) : (
                  <ArrowDownIcon className="h-4 w-4 mr-1 text-red-500" />
                )}
                <p className={`text-2xl font-semibold ${isPositiveChange ? 'text-green-500' : 'text-red-500'}`}>
                  {formatCurrency(Math.abs(balanceChange))}
                </p>
              </div>
            )}
            <p className="text-xs text-zinc-600 dark:text-zinc-500">
              From {formatCurrency(openingBalance)} to {formatCurrency(closingBalance)}
            </p>
          </div>
          
          <div className="space-y-1">
            <p className="text-sm text-zinc-700 dark:text-zinc-400 flex items-center">
              Your Share ({memberSharePercentage}%)
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="h-4 w-4 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                </TooltipTrigger>
                <TooltipContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                  <p className="text-zinc-800 dark:text-zinc-200">Your personal portion of the total bank deposits.</p>
                  <p className="text-zinc-700 dark:text-zinc-300">This is calculated as {memberSharePercentage}% of all deposits.</p>
                </TooltipContent>
              </Tooltip>
            </p>
            {isLoading ? (
              <Skeleton className="h-8 w-32" />
            ) : (
              <p className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">{formatCurrency(memberShare)}</p>
            )}
            <p className="text-xs text-zinc-600 dark:text-zinc-500">Personal portion of bank deposits</p>
          </div>
          
          <div className="space-y-1">
            <p className="text-sm text-zinc-700 dark:text-zinc-400 flex items-center">
              Company Share ({100 - memberSharePercentage}%)
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="h-4 w-4 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                </TooltipTrigger>
                <TooltipContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                  <p className="text-zinc-800 dark:text-zinc-200">Amount that should be deposited to the company.</p>
                  <p className="text-zinc-700 dark:text-zinc-300">This is calculated as {100 - memberSharePercentage}% of all deposits.</p>
                </TooltipContent>
              </Tooltip>
            </p>
            {isLoading ? (
              <Skeleton className="h-8 w-32" />
            ) : (
              <p className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">{formatCurrency(companyShare)}</p>
            )}
            <p className="text-xs text-zinc-600 dark:text-zinc-500">Amount to deposit to company</p>
          </div>
        </CardContent>
        
        <Separator className="my-2 bg-zinc-200 dark:bg-zinc-700" />
        
        <CardContent className="pt-4">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-sm text-zinc-700 dark:text-zinc-400 flex items-center">
                Year-to-Date Total Bank Deposits
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Info className="h-4 w-4 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                  </TooltipTrigger>
                  <TooltipContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                    <p className="text-zinc-800 dark:text-zinc-200">Total bank deposits recorded since January 1st.</p>
                  </TooltipContent>
                </Tooltip>
              </p>
              {isLoading ? (
                <Skeleton className="h-8 w-40" />
              ) : (
                <p className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">{formatCurrency(ytdTotal)}</p>
              )}
            </div>
            
            <div className="text-right">
              <p className="text-sm text-zinc-700 dark:text-zinc-400 flex items-center justify-end">
                Monthly Member Share
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Info className="h-4 w-4 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                  </TooltipTrigger>
                  <TooltipContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                    <p className="text-zinc-800 dark:text-zinc-200">Your {memberSharePercentage}% share of the month's total bank deposits.</p>
                  </TooltipContent>
                </Tooltip>
              </p>
              {isLoading ? (
                <Skeleton className="h-8 w-32 ml-auto" />
              ) : (
                <p className="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">{formatCurrency(memberShare)}</p>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </TooltipProvider>
  );
}
