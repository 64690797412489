
/**
 * Centralized list of all market sync event types
 */
export const MARKET_SYNC_EVENTS = {
  REGIONAL_TARGETS_UPDATED: 'regional-targets-updated',
  ALL_MEMBER_TARGETS_REFRESHED: 'all-member-targets-refreshed',
  USER_REGIONAL_TARGETS_SYNCED: 'user-regional-targets-synced',
  SYSTEM_TARGETS_LOADED: 'system-targets-loaded',
  DASHBOARD_TARGETS_UPDATED: 'dashboard-targets-updated',
  MARKET_SYNC_STARTED: 'market-sync-started',
  MARKET_SYNC_COMPLETED: 'market-sync-completed',
  MARKET_SYNC_FAILED: 'market-sync-failed',
  CALCULATOR_SETTINGS_UPDATED: 'calculator-settings-updated',
  MEMBER_TARGET_EDITED: 'member-target-edited',
  // Sync events
  SYNC_START: 'sync-start',
  SYNC_COMPLETE: 'sync-complete',
  FORCE_SYNC: 'force-sync',
  // Add new event for member adjusted target updates
  MEMBER_ADJUSTED_TARGET_UPDATED: 'member-adjusted-target-updated'
};

// Active sync operations tracking
const activeSyncOperations = new Map();

/**
 * Helper function to dispatch market sync events
 */
export const dispatchMarketSyncEvent = (eventType: string, detail: any = {}) => {
  window.dispatchEvent(new CustomEvent(eventType, { detail }));
};

/**
 * Add event listeners for multiple market sync events with a single callback
 */
export const addMarketSyncListeners = (eventTypes: string[], callback: (event: CustomEvent) => void) => {
  const wrappedCallback = (event: Event) => {
    callback(event as CustomEvent);
  };
  
  eventTypes.forEach(eventType => {
    window.addEventListener(eventType, wrappedCallback);
  });
  
  // Return a cleanup function to remove all event listeners
  return () => {
    eventTypes.forEach(eventType => {
      window.removeEventListener(eventType, wrappedCallback);
    });
  };
};

/**
 * Add a listener for a single market sync event
 */
export const addMarketSyncListener = (eventType: string, callback: (event: CustomEvent) => void) => {
  const wrappedCallback = (event: Event) => {
    callback(event as CustomEvent);
  };
  
  window.addEventListener(eventType, wrappedCallback);
  
  // Return a cleanup function to remove the event listener
  return () => {
    window.removeEventListener(eventType, wrappedCallback);
  };
};

/**
 * Set up listeners for sync start and complete events
 */
export const setupMarketSyncEventListeners = (
  onStart: () => void,
  onComplete: (success: boolean, error?: Error) => void
) => {
  // Listen for sync start
  const startListener = (event: Event) => {
    onStart();
  };
  
  // Listen for sync complete
  const completeListener = (event: Event) => {
    const customEvent = event as CustomEvent;
    const { success, error } = customEvent.detail || {};
    onComplete(success !== false, error ? new Error(error) : undefined);
  };
  
  // Add the event listeners
  window.addEventListener(MARKET_SYNC_EVENTS.SYNC_START, startListener);
  window.addEventListener(MARKET_SYNC_EVENTS.MARKET_SYNC_STARTED, startListener);
  window.addEventListener(MARKET_SYNC_EVENTS.SYNC_COMPLETE, completeListener);
  window.addEventListener(MARKET_SYNC_EVENTS.MARKET_SYNC_COMPLETED, completeListener);
  window.addEventListener(MARKET_SYNC_EVENTS.MARKET_SYNC_FAILED, (event) => {
    onComplete(false, new Error('Sync failed'));
  });
  
  // Return cleanup function
  return () => {
    window.removeEventListener(MARKET_SYNC_EVENTS.SYNC_START, startListener);
    window.removeEventListener(MARKET_SYNC_EVENTS.MARKET_SYNC_STARTED, startListener);
    window.removeEventListener(MARKET_SYNC_EVENTS.SYNC_COMPLETE, completeListener);
    window.removeEventListener(MARKET_SYNC_EVENTS.MARKET_SYNC_COMPLETED, completeListener);
    window.removeEventListener(MARKET_SYNC_EVENTS.MARKET_SYNC_FAILED, completeListener);
  };
};

/**
 * Trigger a market sync operation
 */
export const triggerMarketSync = (userId?: string, options?: any) => {
  const operationId = `sync-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
  const detail = {
    operationId,
    userId,
    timestamp: new Date().toISOString(),
    ...options
  };
  
  // Register the operation
  registerSyncOperation(operationId, 'market-sync', detail);
  
  // Dispatch the event
  dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.FORCE_SYNC, detail);
  
  return operationId;
};

/**
 * Trigger calculator settings update
 */
export const triggerCalculatorSettingsUpdate = (settings: any, metadata?: any) => {
  const operationId = `calc-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
  const detail = {
    operationId,
    settings,
    timestamp: new Date().toISOString(),
    ...metadata
  };
  
  // Register the operation
  registerSyncOperation(operationId, 'calculator-settings-update', detail);
  
  // Dispatch the event
  dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.CALCULATOR_SETTINGS_UPDATED, detail);
  
  return operationId;
};

/**
 * Register a sync operation for tracking
 */
export const registerSyncOperation = (operationId: string, type: string, initialData: any = {}) => {
  activeSyncOperations.set(operationId, {
    operationId,
    type,
    startTime: new Date().toISOString(),
    status: 'in-progress',
    stages: [{
      name: 'initialized',
      status: 'completed',
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString()
    }],
    ...initialData
  });
  
  return operationId;
};

/**
 * Update a sync operation's status
 */
export const updateSyncOperation = (operationId: string, updates: any) => {
  if (!activeSyncOperations.has(operationId)) {
    return false;
  }
  
  const operation = activeSyncOperations.get(operationId);
  activeSyncOperations.set(operationId, {
    ...operation,
    ...updates
  });
  
  return true;
};

/**
 * Add a stage to a sync operation
 */
export const addSyncOperationStage = (operationId: string, stageName: string) => {
  if (!activeSyncOperations.has(operationId)) {
    return false;
  }
  
  const operation = activeSyncOperations.get(operationId);
  const newStage = {
    name: stageName,
    status: 'in-progress',
    startTime: new Date().toISOString()
  };
  
  operation.stages.push(newStage);
  activeSyncOperations.set(operationId, operation);
  
  return true;
};

/**
 * Complete a sync operation stage
 */
export const completeSyncOperationStage = (operationId: string, stageName: string, success: boolean = true) => {
  if (!activeSyncOperations.has(operationId)) {
    return false;
  }
  
  const operation = activeSyncOperations.get(operationId);
  const stageIndex = operation.stages.findIndex((s: any) => s.name === stageName);
  
  if (stageIndex === -1) {
    return false;
  }
  
  operation.stages[stageIndex].status = success ? 'completed' : 'failed';
  operation.stages[stageIndex].endTime = new Date().toISOString();
  
  activeSyncOperations.set(operationId, operation);
  
  return true;
};

/**
 * Complete a sync operation
 */
export const completeSyncOperation = (operationId: string, success: boolean = true, result?: any) => {
  if (!activeSyncOperations.has(operationId)) {
    return false;
  }
  
  const operation = activeSyncOperations.get(operationId);
  operation.status = success ? 'completed' : 'failed';
  operation.endTime = new Date().toISOString();
  
  if (result) {
    operation.result = result;
  }
  
  activeSyncOperations.set(operationId, operation);
  
  // Clean up old operations
  cleanupSyncOperations();
  
  return true;
};

/**
 * Clean up old sync operations
 */
const cleanupSyncOperations = () => {
  const MAX_OPERATIONS = 20;
  
  if (activeSyncOperations.size <= MAX_OPERATIONS) {
    return;
  }
  
  // Sort operations by start time (oldest first)
  const operations = Array.from(activeSyncOperations.values())
    .sort((a: any, b: any) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
  
  // Remove oldest operations
  const toRemove = operations.slice(0, operations.length - MAX_OPERATIONS);
  
  toRemove.forEach((op: any) => {
    activeSyncOperations.delete(op.operationId);
  });
};

/**
 * Get all active sync operations
 */
export const getActiveSyncOperations = () => {
  return Array.from(activeSyncOperations.values());
};

/**
 * Get details for a specific sync operation
 */
export const getSyncOperationDetails = (operationId: string) => {
  return activeSyncOperations.get(operationId) || null;
};

/**
 * Trigger adjusted target update for a specific member-market combination
 * This ensures the member market view reflects the correct adjusted target
 */
export const triggerMemberAdjustedTargetUpdate = (
  memberId: string, 
  marketId: string, 
  adjustedTargetValue: number,
  metadata?: any
) => {
  const operationId = `target-update-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
  const detail = {
    operationId,
    memberId,
    marketId,
    adjustedTargetValue,
    timestamp: new Date().toISOString(),
    ...metadata
  };
  
  // Register the operation
  registerSyncOperation(operationId, 'member-adjusted-target-update', detail);
  
  // Dispatch the specific event for member adjusted target updates
  dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.MEMBER_ADJUSTED_TARGET_UPDATED, detail);
  
  // Also dispatch general target update event
  dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.MEMBER_TARGET_EDITED, {
    userId: memberId,
    marketId: marketId,
    adjustedTarget: adjustedTargetValue,
    timestamp: new Date().toISOString()
  });
  
  return operationId;
};

