
import { useToast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";
import { useAuthUtils } from "./useAuthUtils";
import { AuthDispatch } from "@/types/auth";

interface UseDevelopmentSessionProps {
  dispatch: AuthDispatch;
}

export const useDevelopmentSession = ({ dispatch }: UseDevelopmentSessionProps) => {
  const { toast } = useToast();
  const { createDevSession } = useAuthUtils();

  const setDevelopmentSession = async (role: 'admin' | 'member'): Promise<void> => {
    if (import.meta.env.PROD) return;

    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      localStorage.setItem('userRole', role);
      dispatch({ type: 'SET_ADMIN', payload: role === 'admin' });
      
      if (import.meta.env.DEV) {
        const { mockSession } = createDevSession(role);
        dispatch({ 
          type: 'SET_SESSION', 
          payload: { session: mockSession, user: mockSession.user } 
        });
        logger.debug('Development session created', { role });
      }
    } catch (error) {
      const authError = error as Error;
      logger.error('Failed to set development session', {
        error: authError.message,
      });
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to set development session",
      });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  return { setDevelopmentSession };
};
