
import * as z from "zod";

// Making the schema match the TypeScript interface requirements
// Important: Make all fields required that are required in the CreateMarketForm interface
export const marketFormSchema = z.object({
  // Market Information
  name: z.string().min(1, "Market name is required"),
  region: z.string().min(1, "Region is required"),
  description: z.string().optional(),
  sequence_position: z.number().int().min(1).max(8).optional(),
  
  // Market Classification
  market_type: z.enum(["standard", "premium", "exclusive", "duplicate"]),
  market_risk_level: z.enum(["low", "medium", "high"]),
  
  // Financial Details
  target_value: z.number().min(0, "Target value must be positive"),
  entry_amount: z.number().min(0, "Entry amount must be positive"),
  expected_roi: z.number().min(0, "ROI percentage must be positive"),
  
  // Participation Rules
  max_participants: z.number().int().min(1, "Must allow at least 1 participant"),
  max_participations_per_user: z.number().int().min(1, "Must allow at least 1 participation per user"),
  max_duplications: z.number().int().min(0, "Maximum duplications must be 0 or greater"),
  auto_approve_participants: z.boolean().default(false),
  
  // Timer & Scheduling
  start_date: z.date(),
  close_time: z.date().optional(),
  countdown_enabled: z.boolean().default(true),
  
  // Status & Controls
  auto_activate: z.boolean().default(true),
  draft: z.boolean().default(false)
});

export const formSteps = [
  {
    title: "Basic Information",
    description: "Let's start with the market name, region, and description.",
    fields: ["name", "region", "description", "sequence_position"]
  },
  {
    title: "Market Classification",
    description: "Choose the type and risk level of your market.",
    fields: ["market_type", "market_risk_level"]
  },
  {
    title: "Financial Details",
    description: "Set the target value, entry amount, and expected ROI.",
    fields: ["target_value", "entry_amount", "expected_roi"]
  },
  {
    title: "Participation Rules",
    description: "Define participation limits and approval settings.",
    fields: ["max_participants", "max_participations_per_user", "max_duplications", "auto_approve_participants"]
  },
  {
    title: "Schedule & Status",
    description: "Configure when the market starts, closes, and its initial status.",
    fields: ["start_date", "close_time", "countdown_enabled", "auto_activate", "draft"]
  }
];
