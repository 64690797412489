
import React, { createContext, useContext, useReducer, useEffect } from "react";
import { AuthContextType } from "@/types/auth";
import { initialState, authReducer } from "./authReducer";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { useAuthActions } from "./useAuthActions";

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  signOut: async () => {},
  hardSignOut: async () => {},
  checkAndUpdateAdminStatus: async () => false,
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { signOut, hardSignOut, checkAndUpdateAdminStatus } = useAuthActions(dispatch);

  // Listen for auth changes
  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        logger.info('Auth state changed:', { event });
        
        if (event === 'SIGNED_IN' && session) {
          dispatch({ type: 'SET_SESSION', payload: { session, user: session.user } });
          dispatch({ type: 'SET_LOADING', payload: true });
          await checkAndUpdateAdminStatus();
          dispatch({ type: 'SET_LOADING', payload: false });
        }
        
        if (event === 'SIGNED_OUT') {
          logger.info('User signed out, resetting auth state');
          dispatch({ type: 'RESET' });
        }
      }
    );

    // Initial session check
    const initializeSession = async () => {
      dispatch({ type: 'SET_LOADING', payload: true });
      
      try {
        const { data: { session } } = await supabase.auth.getSession();
        
        if (session) {
          logger.info('Existing session found');
          dispatch({ 
            type: 'SET_SESSION', 
            payload: { session, user: session.user } 
          });
          await checkAndUpdateAdminStatus();
        } else {
          logger.info('No session found');
        }
      } catch (error) {
        logger.error('Error initializing session:', error);
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    initializeSession();

    return () => {
      subscription?.unsubscribe();
    };
  }, [checkAndUpdateAdminStatus]);

  const value = {
    ...state,
    signOut,
    hardSignOut,
    checkAndUpdateAdminStatus,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
