
import React, { useState } from "react";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Trash2, AlertTriangle, Loader2, ShieldAlert } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { withAdminPrivileges } from "@/utils/auth/admin-verification";
import { useAuth } from "@/contexts/AuthContext";
import { logger } from "@/utils/logger";
import { useQueryInvalidation } from "@/hooks/admin/markets/use-query-invalidation";

export const DeleteAllMarketsDialog = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();
  const { session } = useAuth();
  const { invalidateAllMarketQueries } = useQueryInvalidation();
  
  const isConfirmationValid = confirmText === 'DELETE ALL MARKETS';
  
  // Check for authentication before proceeding
  const checkAuthentication = (): boolean => {
    if (!session) {
      const errorMessage = "You must be logged in to perform this action.";
      setError(errorMessage);
      toast({
        title: "Authentication error",
        description: errorMessage,
        variant: "destructive"
      });
      return false;
    }
    return true;
  };
  
  const handleDeleteAllMarkets = async () => {
    if (!isConfirmationValid) return;
    if (!checkAuthentication()) return;
    
    setIsDeleting(true);
    setError(null);
    
    try {
      logger.info("Market purge operation initiated", {
        userId: session?.user?.id,
        email: session?.user?.email
      });
      
      const result = await withAdminPrivileges(async () => {
        // First delete all market_participants
        const {
          error: participantsError
        } = await supabase.from('market_participants').delete().neq('id', '00000000-0000-0000-0000-000000000000');
        if (participantsError) {
          logger.error("Error deleting market participants:", participantsError);
          throw participantsError;
        }

        // Then delete all market_contributions
        const {
          error: contributionsError
        } = await supabase.from('market_contributions').delete().neq('id', '00000000-0000-0000-0000-000000000000');
        if (contributionsError) {
          logger.error("Error deleting market contributions:", contributionsError);
          throw contributionsError;
        }

        // Then delete all market_history
        const {
          error: historyError
        } = await supabase.from('market_history').delete().neq('id', '00000000-0000-0000-0000-000000000000');
        if (historyError) {
          logger.error("Error deleting market history:", historyError);
          throw historyError;
        }

        // Then delete all market_notifications
        const {
          error: notificationsError
        } = await supabase.from('market_notifications').delete().neq('id', '00000000-0000-0000-0000-000000000000');
        if (notificationsError) {
          logger.error("Error deleting market notifications:", notificationsError);
          throw notificationsError;
        }

        // Then delete all market_financial_snapshots
        const {
          error: snapshotsError
        } = await supabase.from('market_financial_snapshots').delete().neq('id', '00000000-0000-0000-0000-000000000000');
        if (snapshotsError) {
          logger.error("Error deleting market financial snapshots:", snapshotsError);
          throw snapshotsError;
        }

        // Then delete all market_reports
        const {
          error: reportsError
        } = await supabase.from('market_reports').delete().neq('id', '00000000-0000-0000-0000-000000000000');
        if (reportsError) {
          logger.error("Error deleting market reports:", reportsError);
          throw reportsError;
        }

        // Delete any market_join_events
        const {
          error: joinEventsError
        } = await supabase.from('market_join_events').delete().neq('id', '00000000-0000-0000-0000-000000000000');
        if (joinEventsError) {
          logger.error("Error deleting market join events:", joinEventsError);
          throw joinEventsError;
        }

        // Finally delete all markets
        const {
          error: marketsError
        } = await supabase.from('markets').delete().neq('id', '00000000-0000-0000-0000-000000000000');
        if (marketsError) {
          logger.error("Error deleting markets:", marketsError);
          throw marketsError;
        }
        
        logger.info("Market purge operation completed successfully");
        return {
          success: true
        };
      });
      
      if (result.success) {
        // Invalidate all market-related queries to update the UI
        await invalidateAllMarketQueries();
        
        toast({
          title: "Markets purged",
          description: "All markets and related data have been permanently deleted.",
          variant: "default"
        });
        setConfirmText('');
      } else {
        setError(result.error);
        throw new Error(result.error);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      logger.error("Error deleting markets:", {
        error: errorMessage
      });
      toast({
        title: "Operation failed",
        description: errorMessage || "Could not delete all markets. Please try again.",
        variant: "destructive"
      });
      setError(errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };

  return <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive" className="gap-2">
          <Trash2 className="h-4 w-4" />
          Delete All Markets
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-md">
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center gap-2 text-destructive">
            <AlertTriangle className="h-5 w-5" />
            Delete All Markets
          </AlertDialogTitle>
          <AlertDialogDescription className="text-left">
            <p className="mb-4">
              This will permanently delete <strong>ALL</strong> markets and their related data, including:
            </p>
            <ul className="list-disc pl-5 space-y-1 mb-4">
              <li>All market participants</li>
              <li>All market contributions</li>
              <li>All market history records</li>
              <li>All market notifications</li>
              <li>All market financial snapshots</li>
              <li>All market reports</li>
              <li>All market join events</li>
            </ul>
            <p className="font-semibold text-destructive mb-4">
              This action cannot be undone.
            </p>
            <div className="mb-4">
              <p className="mb-2">Type <strong>DELETE ALL MARKETS</strong> to confirm:</p>
              <input 
                type="text" 
                value={confirmText} 
                onChange={e => setConfirmText(e.target.value)} 
                disabled={isDeleting} 
                placeholder="DELETE ALL MARKETS" 
                className="w-full p-4 border border-input rounded-md bg-slate-950" 
              />
            </div>
            
            {error && <div className="p-3 border-l-4 border-destructive bg-destructive/10 text-destructive text-sm mb-4 flex items-start gap-2">
                <ShieldAlert className="h-4 w-4 mt-0.5 flex-shrink-0" />
                <span>{error}</span>
              </div>}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isDeleting}>Cancel</AlertDialogCancel>
          <AlertDialogAction 
            onClick={handleDeleteAllMarkets} 
            disabled={!isConfirmationValid || isDeleting} 
            className="bg-destructive hover:bg-destructive/90 text-destructive-foreground"
          >
            {isDeleting ? <>
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                Deleting...
              </> : 'Delete All Markets'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>;
};
