
import React, { useState, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { format } from "date-fns";
import { ArrowRight, Save, Loader2, Edit, Info } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { DailyFinancialRecord as DailyFinancialRecordType, updateDailyDeposit, calculateCommissionAmount } from "@/utils/financials/member-financials";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useNavigate } from "react-router-dom";

interface DailyFinancialRecordProps {
  record: DailyFinancialRecordType;
  isEditable?: boolean;
  onRecordUpdated: () => void;
  userId: string;
  isCurrentMonth?: boolean;
  memberSharePercentage: number;
}

export const DailyFinancialRecord: React.FC<DailyFinancialRecordProps> = ({
  record,
  isEditable = true,
  onRecordUpdated,
  userId,
  isCurrentMonth = true,
  memberSharePercentage = 15
}) => {
  const [bankDeposit, setBankDeposit] = useState<number>(record.bank_deposit || 0);
  const [closingBalance, setClosingBalance] = useState<number>(record.closing_balance || 0);
  const [isEditingClosing, setIsEditingClosing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [companyShareAmount, setCompanyShareAmount] = useState<number>(0);
  const { toast } = useToast();
  const navigate = useNavigate();
  
  const displayDate = new Date(record.record_date);
  const dayNumber = displayDate.getDate();
  const formattedDate = format(displayDate, 'MMM d, yyyy');
  
  const nextDay = new Date(displayDate);
  nextDay.setDate(nextDay.getDate() + 1);
  const nextDayFormatted = format(nextDay, 'MMM d, yyyy');
  
  useEffect(() => {
    const calculatedCompanyShare = calculateCommissionAmount(bankDeposit, memberSharePercentage);
    setCompanyShareAmount(calculatedCompanyShare);
  }, [bankDeposit, memberSharePercentage]);
  
  useEffect(() => {
    setClosingBalance(record.closing_balance);
    setBankDeposit(record.bank_deposit || 0);
  }, [record]);
  
  const handleUpdate = async () => {
    if (isUpdating) return;
    
    try {
      setIsUpdating(true);
      
      await updateDailyDeposit(
        userId, 
        displayDate, 
        bankDeposit, 
        closingBalance
      );
      
      toast({
        title: "Record updated",
        description: `Financial record for ${formattedDate} has been updated. The closing balance will be used as the opening balance for ${nextDayFormatted}.`,
        variant: "default",
      });

      if (bankDeposit > 0 && companyShareAmount > 0) {
        toast({
          title: "Deposit Required",
          description: (
            <div className="flex flex-col space-y-2">
              <p>Please deposit {formatCurrency(companyShareAmount)} to the company account.</p>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => navigate('/dashboard/transactions')}
                className="mt-1 w-full"
              >
                Go to Deposit Page
              </Button>
            </div>
          ),
          variant: "warning",
          duration: 10000,
        });
      }
      
      onRecordUpdated();
    } catch (error) {
      console.error("Error updating record:", error);
      toast({
        title: "Update failed",
        description: "Could not update the financial record. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsUpdating(false);
      setIsEditingClosing(false);
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  const isInFuture = isCurrentMonth && dayNumber > new Date().getDate();
  const isEditable_final = isEditable && !isInFuture;

  return (
    <TooltipProvider>
      <Card className={`mb-2 border-zinc-200 dark:border-zinc-700 bg-white dark:bg-card/70 shadow-sm hover:shadow transition-shadow duration-150 ${isInFuture ? 'opacity-50' : ''}`}>
        <CardContent className="p-4">
          <div className="flex flex-wrap lg:flex-nowrap items-center gap-2">
            <div className="flex items-center bg-zinc-100 dark:bg-zinc-800/70 rounded-md px-3 py-2 w-full sm:w-auto">
              <div className="flex flex-col items-center mr-2">
                <span className="text-xl font-bold text-zinc-800 dark:text-zinc-200">{dayNumber}</span>
                <span className="text-xs text-zinc-600 dark:text-zinc-500">{format(displayDate, 'E')}</span>
              </div>
              <div className="border-l border-zinc-300 dark:border-zinc-600 pl-2 ml-2">
                <span className="text-sm text-zinc-700 dark:text-zinc-300">{format(displayDate, 'MMMM yyyy')}</span>
              </div>
            </div>
            
            <div className="flex-1 grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div className="flex flex-col">
                <div className="text-xs text-zinc-600 dark:text-zinc-500 flex items-center">
                  Opening Balance
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Info className="inline h-3.5 w-3.5 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                      </TooltipTrigger>
                      <TooltipContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                        <p className="text-zinc-800 dark:text-zinc-200">Opening balance from the previous day's closing balance.</p>
                        {dayNumber === 1 && (
                          <p className="font-medium mt-1 text-zinc-800 dark:text-zinc-200">This is the first day of the month.</p>
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <div className="font-medium text-zinc-800 dark:text-zinc-200">{formatCurrency(record.opening_balance)}</div>
              </div>
              
              <div className="flex flex-col">
                <div className="text-xs text-zinc-600 dark:text-zinc-500 flex items-center">
                  Bank Deposit
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Info className="inline h-3.5 w-3.5 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-xs bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                        <p className="text-zinc-800 dark:text-zinc-200">Bank deposit is the amount you record as deposited.</p>
                        <p className="mt-1 text-zinc-800 dark:text-zinc-200">For a bank deposit of {formatCurrency(bankDeposit || 0)}:</p>
                        <p className="text-zinc-700 dark:text-zinc-300">- Your share ({memberSharePercentage}%): {formatCurrency((bankDeposit || 0) * memberSharePercentage / 100)}</p>
                        <p className="text-zinc-700 dark:text-zinc-300">- Company share ({100-memberSharePercentage}%): {formatCurrency(companyShareAmount)}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                {isEditable_final ? (
                  <Input
                    type="number"
                    value={bankDeposit}
                    onChange={(e) => setBankDeposit(parseFloat(e.target.value) || 0)}
                    className="mt-1 h-9 text-zinc-800 bg-white dark:bg-zinc-800 dark:text-zinc-200 border-zinc-300 dark:border-zinc-700"
                    placeholder="Enter amount"
                    disabled={isUpdating || isInFuture}
                    min="0"
                    step="0.01"
                  />
                ) : (
                  <div className="font-medium mt-1 text-zinc-800 dark:text-zinc-200">{formatCurrency(record.bank_deposit || 0)}</div>
                )}
              </div>
              
              <div className="flex flex-col">
                <div className="text-xs text-zinc-600 dark:text-zinc-500 flex items-center">
                  Closing Balance
                  <span className="ml-1 text-xs font-medium text-amber-600 dark:text-amber-500">(Manual)</span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Info className="inline h-3.5 w-3.5 ml-1 text-zinc-500 dark:text-zinc-500 cursor-help" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-xs bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                        <p className="font-medium text-zinc-800 dark:text-zinc-200">This is a manual entry field.</p>
                        <p className="text-zinc-700 dark:text-zinc-300">Enter your actual end-of-day cash balance.</p>
                        <p className="text-primary mt-1">This value will become the opening balance for the next day ({nextDayFormatted}).</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                
                {isEditable_final ? (
                  <Input
                    type="number"
                    value={closingBalance}
                    onChange={(e) => setClosingBalance(parseFloat(e.target.value) || 0)}
                    className="mt-1 h-9 text-zinc-800 bg-white dark:bg-zinc-800 dark:text-zinc-200 border-zinc-300 dark:border-zinc-700"
                    placeholder="Enter closing balance"
                    disabled={isUpdating || isInFuture}
                    min="0"
                    step="0.01"
                  />
                ) : (
                  <div className="font-medium mt-1 text-zinc-800 dark:text-zinc-200">{formatCurrency(record.closing_balance)}</div>
                )}
              </div>
            </div>
            
            {isEditable_final && (
              <Button 
                variant="outline" 
                size="sm" 
                className="whitespace-nowrap ml-auto border-zinc-300 dark:border-zinc-700 text-zinc-700 dark:text-zinc-300 hover:bg-zinc-100 dark:hover:bg-zinc-800"
                onClick={handleUpdate}
                disabled={isUpdating || (
                  record.bank_deposit === bankDeposit && 
                  record.closing_balance === closingBalance
                ) || isInFuture}
              >
                {isUpdating ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Saving
                  </>
                ) : (
                  <>
                    <Save className="h-4 w-4 mr-2" />
                    Save
                  </>
                )}
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </TooltipProvider>
  );
};
