
import { toast } from "sonner";
import { logger } from "@/utils/logger";

/**
 * Hook for validating market configuration inputs
 */
export const useMarketValidation = () => {
  const validateInputs = (
    dailyTarget: string,
    adjustmentFactor: string,
    maxParticipationsPerUser: number,
    maxDuplications: number,
    expectedROI: number
  ) => {
    try {
      // Parse and validate daily target
      const targetValue = parseFloat(dailyTarget);
      if (isNaN(targetValue) || targetValue < 0) {
        toast.error("Daily target must be a positive number.");
        logger.error("Invalid daily target", { value: dailyTarget });
        return false;
      }

      // Parse and validate adjustment factor
      const factorValue = parseFloat(adjustmentFactor);
      if (isNaN(factorValue) || factorValue <= 0) {
        toast.error("Adjustment factor must be a positive number.");
        logger.error("Invalid adjustment factor", { value: adjustmentFactor });
        return false;
      }

      // Validate maxParticipationsPerUser
      if (!Number.isInteger(maxParticipationsPerUser) || maxParticipationsPerUser < 1) {
        toast.error("Maximum participations per user must be at least 1.");
        logger.error("Invalid maxParticipationsPerUser", { value: maxParticipationsPerUser });
        return false;
      }

      // Validate maxDuplications
      if (!Number.isInteger(maxDuplications) || maxDuplications < 0) {
        toast.error("Maximum duplications must be 0 or greater.");
        logger.error("Invalid maxDuplications", { value: maxDuplications });
        return false;
      }

      // Validate expectedROI
      if (isNaN(expectedROI) || expectedROI < 0) {
        toast.error("Expected ROI must be 0 or greater.");
        logger.error("Invalid expectedROI", { value: expectedROI });
        return false;
      }

      return true;
    } catch (error) {
      logger.error("Validation error:", error);
      toast.error("Validation error: Please check your inputs.");
      return false;
    }
  };

  return { validateInputs };
};
