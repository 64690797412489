
import { Market } from "@/types/market";
import { logger } from "@/utils/logger";
import { FilterOptions } from "./types/filter-types";
import { filterByCriteria } from "./filters/criteria-filter";
import { tabStrategies } from "./filters/tab-strategies-improved"; // Use improved version

/**
 * Primary filter function for markets that ensures data consistency
 * and applies appropriate filters based on the active tab
 */
export const filterMarkets = (
  markets: Market[] | null, 
  { 
    searchQuery = '', 
    selectedRegion = 'All', 
    riskLevel = 'All',
    activeTab = 'available'
  }: FilterOptions
): Market[] => {
  if (!markets) return [];
  
  // Log the filtering operation
  logger.info(`Filtering markets for tab: ${activeTab}, total markets: ${markets.length}`);
  
  // If we have no markets, just return empty array without extensive processing
  if (markets.length === 0) {
    return [];
  }
  
  // Define comprehensive status mapping for different tabs
  const validStatuses = {
    available: ['open', 'active'],
    joined: ['open', 'active', 'scheduled', 'pending'],
    closed: [
      'closed', 'completed', 'failed', 'success', 
      'closed_successful', 'closed_failed', 'failed_final'
    ],
    upcoming: ['scheduled', 'pending'],
    all: [] // Empty array means all statuses are valid
  };
  
  // Get the appropriate tab strategy using the tabStrategies object
  const tabStrategy = tabStrategies[activeTab as keyof typeof tabStrategies] || tabStrategies.all;
  
  // Apply the tab strategy first
  const tabFiltered = markets.filter(market => tabStrategy(market));
  
  // For closed tab, specifically log the filtering results
  if (activeTab === 'closed') {
    const participatingClosedMarkets = markets.filter(m => 
      m.isParticipating === true && 
      validStatuses.closed.includes(m.status)
    );
    
    logger.info('Closed tab filtering details:', {
      allMarketsCount: markets.length,
      participatingClosedMarkets: participatingClosedMarkets.length,
      tabFilteredResult: tabFiltered.length,
      statuses: participatingClosedMarkets.reduce((acc, market) => {
        acc[market.status] = (acc[market.status] || 0) + 1;
        return acc;
      }, {} as Record<string, number>)
    });
  }
  
  // Apply secondary filtering criteria (search, region, risk level)
  const result = filterByCriteria(tabFiltered, searchQuery, selectedRegion, riskLevel);
  
  // Log filtering results
  logger.info('Filter results:', {
    initialCount: markets.length,
    afterTabFiltering: tabFiltered.length,
    finalCount: result.length,
    tab: activeTab
  });
  
  return result;
};
