
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { 
  DialogContent, 
  DialogDescription, 
  DialogHeader, 
  DialogTitle 
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { FormStepCard } from "./FormStepCard";
import { CreateMarketForm, MarketFormType } from "../types";
import { formSteps } from "../config/formConfig";
import { useMarketFormSubmission } from "../hooks/useMarketFormSubmission";
import { ScrollArea } from "@/components/ui/scroll-area";
import * as z from "zod";
import { marketFormSchema } from "../config/formConfig";

interface MarketFormContentProps {
  form: UseFormReturn<z.infer<typeof marketFormSchema>>;
  onMarketCreated: () => void;
  onClose: () => void;
}

export function MarketFormContent({ 
  form, 
  onMarketCreated, 
  onClose 
}: MarketFormContentProps) {
  const [step, setStep] = useState(0);
  const { isSubmitting, submitMarketForm } = useMarketFormSubmission(onMarketCreated);

  const handleSubmit = async (values: z.infer<typeof marketFormSchema>) => {
    // Ensure all required values are present before submitting
    if (!values.name || !values.region || !values.market_type || !values.market_risk_level) {
      console.error("Required form values are missing");
      return;
    }
    
    const success = await submitMarketForm(values as CreateMarketForm);
    if (success) {
      onClose();
      setStep(0);
      form.reset();
    }
  };

  // Explicitly type the current step fields
  const currentStepFields = formSteps[step].fields as Array<keyof z.infer<typeof marketFormSchema>>;
  const isLastStep = step === formSteps.length - 1;

  const handleNext = async () => {
    const fieldsToValidate = formSteps[step].fields as Array<keyof z.infer<typeof marketFormSchema>>;
    const isValid = await form.trigger(fieldsToValidate);
    
    if (isValid) {
      if (isLastStep) {
        form.handleSubmit(handleSubmit)();
      } else {
        setStep(s => s + 1);
      }
    }
  };

  const handleBack = () => {
    setStep(s => Math.max(0, s - 1));
  };

  return (
    <DialogContent className="max-w-4xl max-h-[85vh] p-0 overflow-hidden border-none bg-background/95 backdrop-blur-sm shadow-xl">
      <DialogHeader className="p-6 border-b bg-gradient-to-r from-primary/10 to-background/80">
        <DialogTitle className="text-2xl font-bold text-primary">Create New Market</DialogTitle>
        <DialogDescription className="text-muted-foreground">
          Complete each step to create your market. Scroll horizontally to see all fields if needed.
        </DialogDescription>
      </DialogHeader>

      <div className="relative px-6 pt-4">
        <div className="absolute top-0 left-0 w-full h-2 bg-muted rounded-full overflow-hidden">
          <div 
            className="h-full bg-primary rounded-full transition-all duration-300"
            style={{ width: `${((step + 1) / formSteps.length) * 100}%` }}
          />
        </div>
        <div className="flex justify-between text-xs text-muted-foreground mt-3 px-1">
          {formSteps.map((s, idx) => (
            <span key={idx} className={`${idx <= step ? 'text-primary font-medium' : ''}`}>
              {s.title}
            </span>
          ))}
        </div>
      </div>

      <ScrollArea className="max-h-[calc(85vh-180px)] overflow-y-auto p-6">
        <div className="min-w-[500px]">
          <Form {...form}>
            <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
              <FormStepCard
                title={formSteps[step].title}
                description={formSteps[step].description}
                currentFields={currentStepFields}
                form={form}
                step={step}
                isLastStep={isLastStep}
                onBack={handleBack}
                onNext={handleNext}
                isSubmitting={isSubmitting}
              />
            </form>
          </Form>
        </div>
      </ScrollArea>
    </DialogContent>
  );
}
