
import { logger } from "@/utils/logger";
import { participationVerifier } from "@/utils/market/verification/participation-verifier";
import { webhookManager, WebhookPayload } from "@/utils/market/participation/webhook-manager";
import { v4 as uuidv4 } from "uuid";

export interface JoinMarketParams {
  userId: string;
  marketId: string;
  customSettings?: {
    targetEarnings?: number;
    closeTime?: string;
  };
  waitForCallback?: boolean;
  skipValidation?: boolean;
}

export interface JoinMarketResult {
  success: boolean;
  error?: string;
  participationId?: string;
  alreadyParticipating?: boolean;
  pendingCallback?: boolean;
  requestId?: string;
  approvalStatus?: string;
}

export class MarketJoinService {
  private static instance: MarketJoinService;
  
  // Store callbacks waiting for webhook responses
  private pendingCallbacks: Map<string, (success: boolean, message: string) => void> = new Map();
  
  private constructor() {
    // Initialize any necessary setup
  }
  
  /**
   * Get singleton instance
   */
  static getInstance(): MarketJoinService {
    if (!MarketJoinService.instance) {
      MarketJoinService.instance = new MarketJoinService();
    }
    return MarketJoinService.instance;
  }
  
  /**
   * Join a market with retry logic and webhook integration
   */
  async joinMarket(params: JoinMarketParams): Promise<JoinMarketResult> {
    const { userId, marketId, customSettings, waitForCallback = false, skipValidation = false } = params;
    
    try {
      // Log the request
      logger.info('Processing market join request:', {
        userId,
        marketId,
        hasCustomSettings: !!customSettings,
        waitForCallback,
        skipValidation
      });

      // Basic validation
      if (!userId || !marketId) {
        logger.error('Invalid parameters for joinMarket', {
          hasUserId: !!userId,
          hasMarketId: !!marketId
        });
        return { 
          success: false, 
          error: 'Missing required parameters: userId and marketId must be provided' 
        };
      }
      
      // Check for valid UUID format to prevent database errors
      if (!this.isValidUUID(userId) || !this.isValidUUID(marketId)) {
        logger.error('Invalid UUID format for userId or marketId', {
          userId,
          marketId
        });
        return {
          success: false,
          error: 'Invalid UUID format for userId or marketId'
        };
      }
      
      // Check if already participating (unless validation is skipped)
      if (!skipValidation) {
        const participationResult = await participationVerifier.verifyParticipation(userId, marketId);
        
        if (participationResult.exists) {
          logger.info('User is already participating in this market', {
            userId,
            marketId,
            participationId: participationResult.participationId
          });
          
          return {
            success: true,
            alreadyParticipating: true,
            participationId: participationResult.participationId,
            approvalStatus: participationResult.approvalStatus
          };
        }
      }
      
      // Generate unique request ID for tracking
      const requestId = uuidv4();
      
      // Build webhook payload
      const payload: WebhookPayload = {
        member_id: userId,
        market_id: marketId,
        timestamp: new Date().toISOString(),
        requestId,
        targetEarnings: customSettings?.targetEarnings,
        closeTime: customSettings?.closeTime,
        source: 'web_client'
      };
      
      // Configure retries for webhook if needed
      const maxRetries = 3; // Increased from 2 to 3
      let retryCount = 0;
      let webhookSuccess = false;
      let webhookError: string | undefined;
      
      while (retryCount <= maxRetries && !webhookSuccess) {
        if (retryCount > 0) {
          // Add retry information to payload
          payload.retryAttempt = retryCount;
          
          // Calculate exponential backoff delay
          const backoffDelay = Math.min(1000 * Math.pow(2, retryCount - 1), 5000);
          
          logger.info(`Retrying webhook with backoff delay ${backoffDelay}ms (${retryCount}/${maxRetries})`, {
            requestId,
            userId,
            marketId,
            retryCount,
            backoffDelay
          });
          
          // Wait before retry using exponential backoff
          await new Promise(resolve => setTimeout(resolve, backoffDelay));
        }
        
        // Call Make.com webhook to request market join
        const webhookResult = await webhookManager.sendWebhook(payload);
        
        webhookSuccess = webhookResult.success;
        webhookError = webhookResult.error;
        
        if (webhookSuccess) {
          logger.info('Webhook call successful', {
            requestId,
            responseData: webhookResult.responseData,
            attempt: retryCount + 1
          });
          break;
        } else {
          const isNetworkError = webhookError?.toLowerCase().includes('network') || 
                                webhookError?.toLowerCase().includes('fetch') ||
                                webhookError?.toLowerCase().includes('timeout');
          
          logger.error('Webhook call failed', {
            requestId,
            error: webhookError,
            isNetworkError,
            attempt: retryCount + 1,
            maxRetries,
            willRetry: retryCount < maxRetries
          });
          
          // Only retry network errors
          if (!isNetworkError && retryCount >= 1) {
            logger.info('Not a network error, stopping retry attempts', {
              error: webhookError
            });
            break;
          }
          
          retryCount++;
        }
      }
      
      // If all retries failed, try alternate approaches
      if (!webhookSuccess) {
        logger.error(`Failed to send market join request after ${retryCount} attempts`, {
          userId,
          marketId,
          lastError: webhookError
        });
        
        try {
          // Try alternate fallback method here if needed
          // For now, just return the error
          return {
            success: false,
            error: `Failed to send market join request after ${retryCount} attempts: ${webhookError}`,
            requestId
          };
        } catch (fallbackError) {
          logger.error('Fallback mechanism also failed', {
            fallbackError,
            originalError: webhookError
          });
          
          return {
            success: false,
            error: `All communication methods failed: ${webhookError}`
          };
        }
      }
      
      // If we're waiting for a callback, return with pendingCallback flag
      if (waitForCallback) {
        return {
          success: true,
          pendingCallback: true,
          requestId
        };
      }
      
      // Default success case - the webhook was sent successfully
      return {
        success: true,
        requestId
      };
    } catch (error) {
      logger.error('Exception in joinMarket:', {
        error,
        userId,
        marketId
      });
      
      return { 
        success: false, 
        error: error instanceof Error ? error.message : String(error) 
      };
    }
  }
  
  /**
   * Process webhook callback from Make.com
   */
  handleWebhookCallback(requestId: string, success: boolean, message: string): boolean {
    const callback = this.pendingCallbacks.get(requestId);
    
    if (callback) {
      logger.info('Processing webhook callback', {
        requestId,
        success,
        message
      });
      
      callback(success, message);
      this.pendingCallbacks.delete(requestId);
      return true;
    } else {
      logger.warn('No pending callback found for requestId', {
        requestId
      });
      return false;
    }
  }
  
  /**
   * Helper method to validate UUID format
   */
  private isValidUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
  }
}

// Export singleton instance
export const marketJoinService = MarketJoinService.getInstance();
