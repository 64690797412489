
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";
import { Session } from "@supabase/supabase-js";

export const useMarketActions = (session: Session | null) => {
  const { toast } = useToast();

  const handleParticipate = async (marketId: string) => {
    if (!session?.user?.id) {
      logger.warn('Unauthorized market update attempt', {
        marketId,
        sessionExists: !!session,
        timestamp: new Date().toISOString()
      });
      
      toast({
        title: "Authentication required",
        description: "You must be logged in to manage markets.",
        variant: "destructive",
      });
      return false;
    }

    try {
      logger.info('Market status update initiated:', { 
        marketId,
        userEmail: session.user.email,
        timestamp: new Date().toISOString()
      });
      
      const { data: market, error: marketError } = await supabase
        .from('markets')
        .select('id, status')
        .eq('id', marketId)
        .maybeSingle();

      if (marketError) {
        logger.error('Error fetching market for status update:', {
          error: marketError,
          marketId,
          userEmail: session.user.email,
          timestamp: new Date().toISOString()
        });
        throw marketError;
      }

      if (!market) {
        logger.error('Market not found for status update:', {
          marketId,
          userEmail: session.user.email,
          timestamp: new Date().toISOString()
        });
        throw new Error('Market not found');
      }

      const newStatus = market.status === 'active' ? 'completed' : 'active';
      
      const { error: updateError } = await supabase
        .from('markets')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', marketId);

      if (updateError) {
        logger.error('Error updating market status:', {
          error: updateError,
          marketId,
          newStatus,
          userEmail: session.user.email,
          timestamp: new Date().toISOString()
        });
        throw updateError;
      }

      logger.info('Market status update successful:', {
        marketId,
        oldStatus: market.status,
        newStatus,
        userEmail: session.user.email,
        timestamp: new Date().toISOString()
      });

      toast({
        title: "Market Updated",
        description: `Market status changed to ${newStatus}`,
      });

      return true;

    } catch (error) {
      const err = error as Error;
      logger.error('Market status update failed:', {
        error: err,
        marketId,
        userEmail: session?.user?.email,
        stack: err.stack,
        timestamp: new Date().toISOString()
      });
      
      toast({
        title: "Error",
        description: err.message || "Failed to update market status",
        variant: "destructive",
      });

      return false;
    }
  };

  return {
    handleParticipate
  };
};
