
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Table, LayoutGrid } from "lucide-react";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "@/components/ui/tooltip";

interface ExcelViewToggleProps {
  isExcelView: boolean;
  onToggleChange: (enabled: boolean) => void;
}

export const ExcelViewToggle = ({ isExcelView, onToggleChange }: ExcelViewToggleProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center gap-2 bg-white/80 dark:bg-zinc-800/80 px-3 py-2 rounded-lg border border-zinc-200 dark:border-zinc-700 transition-colors hover:bg-zinc-100 dark:hover:bg-zinc-700/70">
            <div className="flex items-center">
              <Switch
                id="excel-view-toggle"
                checked={isExcelView}
                onCheckedChange={onToggleChange}
                className="mr-2 data-[state=checked]:bg-primary"
              />
              <Label htmlFor="excel-view-toggle" className="flex items-center text-sm cursor-pointer select-none text-zinc-800 dark:text-zinc-200 transition-colors">
                <span className="mr-2">{isExcelView ? "Excel View" : "Normal View"}</span>
                {isExcelView ? (
                  <Table className="h-4 w-4 text-primary" />
                ) : (
                  <LayoutGrid className="h-4 w-4 text-zinc-600 dark:text-zinc-400 transition-colors" />
                )}
              </Label>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent className="bg-white dark:bg-zinc-800 text-zinc-800 dark:text-zinc-200 border-zinc-200 dark:border-zinc-700 transition-colors">
          <p>Toggle between Excel spreadsheet view and normal dashboard view</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
