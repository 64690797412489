
import { useState, useEffect, useCallback } from 'react';
import { 
  MonthlyFinancialSummary,
  getMonthlyFinancialSummary,
  generateMonthlySummary
} from '@/utils/financials/member-financials';
import { logger } from '@/utils/logger';

export const useMonthlyFinancialSummary = (
  userId: string | undefined,
  month: number,
  year: number
) => {
  const [summary, setSummary] = useState<MonthlyFinancialSummary | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchSummary = useCallback(async () => {
    if (!userId) return;
    
    try {
      setIsLoading(true);
      setError(null);

      logger.info('Fetching monthly financial summary', { userId, month, year });
      const data = await getMonthlyFinancialSummary(userId, month, year);
      setSummary(data);
      
    } catch (err) {
      logger.error('Error fetching monthly summary', { error: err, userId, month, year });
      setError(err instanceof Error ? err : new Error('Error fetching summary'));
    } finally {
      setIsLoading(false);
    }
  }, [userId, month, year]);

  const regenerateSummary = useCallback(async () => {
    if (!userId) return;
    
    try {
      setIsLoading(true);
      setError(null);

      logger.info('Regenerating monthly financial summary', { userId, month, year });
      await generateMonthlySummary(userId, month, year);
      
      // Fetch the updated summary
      await fetchSummary();
      
    } catch (err) {
      logger.error('Error regenerating monthly summary', { error: err, userId, month, year });
      setError(err instanceof Error ? err : new Error('Error regenerating summary'));
    } finally {
      setIsLoading(false);
    }
  }, [userId, month, year, fetchSummary]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  return {
    summary,
    isLoading,
    error,
    refresh: fetchSummary,
    regenerate: regenerateSummary
  };
};
