
import { Market } from "@/types/market";
import { isFuture, isPast } from "date-fns"; 

// Define the filter strategy for the "available" tab
export const availableTabStrategy = (market: Market): boolean => {
  // Market is active and not already participated in
  const isActive = market.status === 'active' || market.status === 'open';
  const isNotParticipating = market.isParticipating !== true;
  
  // Not in a closed state
  const isNotClosed = !['closed', 'completed', 'failed', 'success', 'closed_successful', 'closed_failed'].includes(market.status);
  
  // ENHANCED: Check for scheduled markets that should now be available
  // A scheduled market with a passed start date should appear in available tab
  const isScheduledButShouldBeAvailable = 
    market.status === 'scheduled' && 
    market.start_date && 
    isPast(new Date(market.start_date));
  
  // Market should be shown in available if:
  // - It's explicitly active OR scheduled but time has passed to make it available
  // - User is not already participating
  // - It's not in a closed state
  return (isActive || isScheduledButShouldBeAvailable) && isNotParticipating && isNotClosed;
};

// Define the filter strategy for the "joined" tab
export const joinedTabStrategy = (market: Market): boolean => {
  // CRITICAL FIX: Check for isParticipating flag first
  if (market.isParticipating !== true) {
    return false;
  }
  
  // For joined tab, we DON'T want to show closed markets
  const closedStatuses = ['closed', 'completed', 'failed', 'success', 'closed_successful', 'closed_failed'];
  const isNotClosed = !closedStatuses.includes(market.status);
  
  return isNotClosed;
};

// Define the filter strategy for the "closed" tab
export const closedTabStrategy = (market: Market): boolean => {
  // CRITICAL FIX: For closed tab, we need both participation flag AND closed status
  const isParticipating = market.isParticipating === true;
  
  // Define closed statuses
  const closedStatuses = ['closed', 'completed', 'failed', 'success', 'closed_successful', 'closed_failed'];
  
  // Market is in a closed state
  const isClosed = closedStatuses.includes(market.status);
  
  // Must be both participating AND closed to show in closed tab
  return isParticipating && isClosed;
};

// Define the filter strategy for the "upcoming" tab
export const upcomingTabStrategy = (market: Market): boolean => {
  // UPDATED: Only show truly upcoming markets - either scheduled with future date or explicitly future start date
  // Market is in scheduled state AND has a future start date
  const isScheduledAndFuture = 
    market.status === 'scheduled' && 
    (!market.start_date || isFuture(new Date(market.start_date)));
  
  // Or market has a future start date
  const hasFutureStartDate = market.start_date != null && isFuture(new Date(market.start_date));
  
  return isScheduledAndFuture || hasFutureStartDate;
};

// Export a function to get the strategy for a specific tab
export const getTabStrategy = (tabName: string): ((markets: Market[]) => Market[]) => {
  // Return a function that filters an array of markets based on the selected tab
  return (markets: Market[]): Market[] => {
    if (!markets || markets.length === 0) {
      return [];
    }
    
    switch(tabName) {
      case 'available':
        return markets.filter(availableTabStrategy);
      case 'joined':
        return markets.filter(joinedTabStrategy);
      case 'closed':
        return markets.filter(closedTabStrategy);
      case 'upcoming':
        return markets.filter(upcomingTabStrategy);
      default:
        return markets; // Default case returns all markets
    }
  };
};

// Map tab names to their filter strategies (for direct access)
export const tabStrategies = {
  'available': (markets: Market[]) => markets.filter(availableTabStrategy),
  'joined': (markets: Market[]) => markets.filter(joinedTabStrategy),
  'closed': (markets: Market[]) => markets.filter(closedTabStrategy),
  'upcoming': (markets: Market[]) => markets.filter(upcomingTabStrategy),
  'all': (markets: Market[]) => markets // Default strategy that returns all markets
};
