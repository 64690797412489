
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { AuthDispatch } from "@/types/auth";

interface UseSessionInitializationProps {
  dispatch: AuthDispatch;
  mounted: boolean;
}

export const useSessionInitialization = ({ dispatch, mounted }: UseSessionInitializationProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const initializeAuth = useCallback(async () => {
    try {
      logger.info('Initializing authentication');
      
      // Get current session
      const { data: { session: initialSession }, error: sessionError } = 
        await supabase.auth.getSession();

      if (sessionError) {
        logger.error('Session retrieval failed', { error: sessionError });
        localStorage.removeItem('app-session');
        throw sessionError;
      }

      if (!mounted) return;

      if (initialSession?.user) {
        logger.info('User session found', { userId: initialSession.user.id });
        
        try {
          // Set session immediately to prevent loading flicker
          dispatch({ 
            type: 'SET_SESSION', 
            payload: { session: initialSession, user: initialSession.user } 
          });

          // Check token expiration and refresh if needed
          const expiresAt = initialSession.expires_at || 0;
          const currentTime = Math.floor(Date.now() / 1000);
          
          if (expiresAt - currentTime < 300) { // Less than 5 minutes left
            logger.info('Session token expiring soon, refreshing');
            await supabase.auth.refreshSession();
          }

          // Check admin status using secure RPC function
          const { data: isAdmin, error: adminError } = await supabase.rpc('is_admin_email_direct');
          
          if (adminError) {
            logger.error('Admin check error:', adminError);
            throw adminError;
          }
          
          if (mounted) {
            dispatch({ type: 'SET_ADMIN', payload: !!isAdmin });
            dispatch({ type: 'SET_LOADING', payload: false });

            // Log successful initialization
            logger.info('Auth initialized successfully', { 
              userId: initialSession.user.id,
              isAdmin: !!isAdmin
            });
          }
        } catch (error) {
          logger.error('Error during session verification', { error });
          dispatch({ type: 'SIGN_OUT' });
          localStorage.removeItem('app-session');
          navigate('/signin', { replace: true });
        }
      } else {
        logger.info('No user session found');
        dispatch({ type: 'SET_LOADING', payload: false });
        
        // Only redirect if not already on auth pages
        if (!window.location.pathname.includes('/signin')) {
          navigate('/signin', { replace: true });
        }
      }
    } catch (error) {
      const authError = error as Error;
      logger.error('Authentication initialization failed', {
        error: authError.message,
      });
      
      localStorage.removeItem('app-session');
      
      navigate('/signin', { replace: true });
      
      toast({
        variant: "destructive",
        title: "Authentication Error",
        description: "Please sign in again to continue.",
      });
    } finally {
      if (mounted) {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    }
  }, [dispatch, mounted, navigate, toast]);

  return { initializeAuth };
};
