import { supabase } from "@/lib/supabase";
import { extractErrorDetails } from "@/utils/error-handling/supabase-error";

export interface DailyFinancialRecord {
  record_date: string;
  opening_balance: number;
  bank_deposit: number | null;
  closing_balance: number;
}

export interface MonthlyFinancialSummary {
  month: number;
  year: number;
  total_deposits: number;
  member_share_amount: number;
  company_share_amount: number;
  ytd_total: number;
}

/**
 * Fetches daily financial records for a specific month and year
 */
export async function getDailyFinancialRecords(
  userId: string,
  month: number,
  year: number
): Promise<DailyFinancialRecord[]> {
  try {
    const startDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const endDate = `${year}-${month.toString().padStart(2, '0')}-${new Date(year, month, 0).getDate()}`;

    const { data, error } = await supabase
      .from('daily_financial_records')
      .select('*')
      .eq('user_id', userId)
      .gte('record_date', startDate)
      .lte('record_date', endDate)
      .order('record_date', { ascending: true });

    if (error) {
      throw error;
    }

    return data || [];
  } catch (error) {
    console.error("Error fetching daily financial records:", error);
    return [];
  }
}

/**
 * Get all days in a month with financial records, filling in any missing days
 */
export async function getAllDaysInMonthWithRecords(
  userId: string,
  month: number,
  year: number
): Promise<DailyFinancialRecord[]> {
  try {
    // Fetch existing records
    const existingRecords = await getDailyFinancialRecords(userId, month, year);
    
    // Calculate the number of days in the month
    const daysInMonth = new Date(year, month, 0).getDate();
    
    // Create a map of existing records by date
    const recordMap = new Map<string, DailyFinancialRecord>();
    existingRecords.forEach(record => {
      recordMap.set(record.record_date, record);
    });
    
    // Create complete record set with any missing days filled in
    const completeRecords: DailyFinancialRecord[] = [];
    let previousClosingBalance = 0;
    
    // Try to get the previous month's last day's closing balance for first day initialization
    if (existingRecords.length === 0 || !recordMap.has(`${year}-${month.toString().padStart(2, '0')}-01`)) {
      const prevMonth = month === 1 ? 12 : month - 1;
      const prevYear = month === 1 ? year - 1 : year;
      const prevRecords = await getDailyFinancialRecords(userId, prevMonth, prevYear);
      
      if (prevRecords.length > 0) {
        previousClosingBalance = prevRecords[prevRecords.length - 1].closing_balance;
      } else {
        // If no previous month records, try to get user's current balance
        const { data } = await supabase
          .from('profiles')
          .select('balance')
          .eq('id', userId)
          .single();
          
        if (data?.balance) {
          previousClosingBalance = data.balance;
        }
      }
    }
    
    for (let day = 1; day <= daysInMonth; day++) {
      const dateStr = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      
      if (recordMap.has(dateStr)) {
        // Use existing record
        const record = recordMap.get(dateStr)!;
        completeRecords.push(record);
        // Update previous closing balance for next day
        previousClosingBalance = record.closing_balance;
      } else {
        // Create a new record with default values
        const newRecord: DailyFinancialRecord = {
          record_date: dateStr,
          opening_balance: previousClosingBalance,
          bank_deposit: null,
          closing_balance: previousClosingBalance // Default closing = opening if no deposit
        };
        completeRecords.push(newRecord);
      }
    }
    
    return completeRecords;
  } catch (error) {
    console.error("Error getting all days with records:", error);
    return [];
  }
}

/**
 * Calculate totals from daily financial records
 */
export function calculateDailyTotals(records: DailyFinancialRecord[]) {
  if (!records || records.length === 0) {
    return {
      totalDeposits: 0,
      startBalance: 0,
      endBalance: 0
    };
  }
  
  // Calculate total deposits (bank_deposit only)
  const totalDeposits = records.reduce((sum, record) => {
    return sum + (record.bank_deposit || 0);
  }, 0);
  
  // Get starting balance from first day
  const startBalance = records[0].opening_balance;
  
  // Get ending balance from last day
  const endBalance = records[records.length - 1].closing_balance;
  
  return {
    totalDeposits,
    startBalance,
    endBalance
  };
}

/**
 * Generates monthly financial summary
 */
export async function generateMonthlySummary(
    userId: string,
    month: number,
    year: number
): Promise<MonthlyFinancialSummary | null> {
    try {
        // Call the RPC function and get the PostgrestResponse
        const response = await supabase.rpc('generate_monthly_financial_summary', {
            p_user_id: userId,
            p_month: month,
            p_year: year,
        });
        
        // Extract data and error using object destructuring
        const { data, error } = response;

        if (error) {
            throw error;
        }

        // Since the RPC function should return a single row, we can return the first element
        return data && data.length > 0 ? data[0] : null;
    } catch (error) {
        console.error("Error generating monthly summary:", error);
        return null;
    }
}

/**
 * Fetches monthly financial summary
 */
export async function getMonthlyFinancialSummary(
    userId: string,
    month: number,
    year: number
): Promise<MonthlyFinancialSummary | null> {
    try {
        const response = await supabase
            .from('monthly_financial_summaries')
            .select('*')
            .eq('user_id', userId)
            .eq('month', month)
            .eq('year', year);
            
        const { data, error } = response;

        if (error && error.code !== 'PGRST116') {
            throw error;
        }

        return data?.[0] || null;
    } catch (error) {
        console.error("Error fetching monthly summary:", error);
        return null;
    }
}

/**
 * Updates the daily deposit amount for a user
 * 
 * @param userId User ID
 * @param date Record date
 * @param bankDeposit Bank deposit amount
 * @param manualClosingBalance Optional manual closing balance
 */
export async function updateDailyDeposit(
  userId: string,
  date: Date,
  bankDeposit: number,
  manualClosingBalance?: number
): Promise<boolean> {
  try {
    const formattedDate = date.toISOString().split('T')[0];
    
    // Check if the manual closing balance parameter is provided
    if (manualClosingBalance !== undefined) {
      // Call the function with manual closing balance parameter
      const { error } = await supabase.rpc('update_daily_financial_record', {
        p_user_id: userId,
        p_date: formattedDate,
        p_bank_deposit: bankDeposit,
        p_manual_closing: manualClosingBalance
      } as any); // Use type assertion to avoid TypeScript errors
      
      if (error) throw error;
      
      // Update the next day's opening balance
      const nextDay = new Date(date);
      nextDay.setDate(date.getDate() + 1);
      const nextDayFormatted = nextDay.toISOString().split('T')[0];
      
      // Check if the next day record exists
      const { data: nextDayRecord } = await supabase
        .from('daily_financial_records')
        .select('id')
        .eq('user_id', userId)
        .eq('record_date', nextDayFormatted)
        .single();
      
      if (nextDayRecord) {
        // Update the next day's opening balance to match today's closing balance
        const { error: updateError } = await supabase
          .from('daily_financial_records')
          .update({ opening_balance: manualClosingBalance })
          .eq('user_id', userId)
          .eq('record_date', nextDayFormatted);
          
        if (updateError) console.error('Error updating next day opening balance:', updateError);
      } else {
        // Create the next day record with opening balance from today's closing
        const { error: insertError } = await supabase
          .from('daily_financial_records')
          .insert({
            user_id: userId,
            record_date: nextDayFormatted,
            opening_balance: manualClosingBalance,
            closing_balance: manualClosingBalance, // Default closing is same as opening
            bank_deposit: null
          });
          
        if (insertError) console.error('Error creating next day record:', insertError);
      }
    } else {
      // Call the original version without manual closing
      // This will automatically calculate closing balance as opening_balance + bank_deposit
      const { error } = await supabase.rpc('update_daily_financial_record', {
        p_user_id: userId,
        p_date: formattedDate,
        p_bank_deposit: bankDeposit
      });
      
      if (error) throw error;
    }
    
    return true;
  } catch (error) {
    console.error('Error updating daily deposit:', error);
    return false;
  }
}

/**
 * Update member share percentage
 */
export async function updateMemberSharePercentage(
  userId: string,
  percentage: number
): Promise<boolean> {
  try {
    const { error } = await supabase
      .from('profiles')
      .update({ member_share_percentage: percentage })
      .eq('id', userId);
    
    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error updating member share percentage:', error);
    return false;
  }
}

/**
 * Calculates the commission amount based on a percentage
 */
export const calculateCommissionAmount = (
  bankDeposit: number,
  memberSharePercentage: number
): number => {
  if (isNaN(bankDeposit) || isNaN(memberSharePercentage)) {
    return 0;
  }
  
  // Calculate the company's share (deposit amount to be deposited)
  // Company gets (100 - memberSharePercentage)% of the deposit
  return bankDeposit * ((100 - memberSharePercentage) / 100);
};
