
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { Search, Percent, Save, RefreshCw } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { Skeleton } from "@/components/ui/skeleton";
import { User } from '@/types/user';

export const CommissionManagement = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [commissionPercentage, setCommissionPercentage] = useState("");
  const [commissionDialogOpen, setCommissionDialogOpen] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('profiles')
        .select('id, email, first_name, last_name, status, member_share_percentage, balance')
        .order('created_at', { ascending: false });

      if (error) {
        throw error;
      }

      // Add default member_share_percentage if not set
      const usersWithDefaults = (data || []).map(user => ({
        ...user,
        member_share_percentage: user.member_share_percentage || 15,
        balance: user.balance || 0
      })) as User[];

      setUsers(usersWithDefaults);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast({
        title: "Error loading members",
        description: "Could not fetch member data. Please try again later.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredUsers = users.filter(user => {
    const searchStr = `${user.first_name || ''} ${user.last_name || ''} ${user.email || ''}`.toLowerCase();
    return searchStr.includes(searchTerm.toLowerCase());
  });

  const openCommissionDialog = (user: User) => {
    setSelectedUser(user);
    setCommissionPercentage((user.member_share_percentage || 15).toString());
    setCommissionDialogOpen(true);
  };

  const updateCommissionPercentage = async () => {
    if (!selectedUser) return;
    
    try {
      const percentageValue = parseInt(commissionPercentage);
      
      if (isNaN(percentageValue) || percentageValue < 0 || percentageValue > 100) {
        toast({
          title: "Invalid Percentage",
          description: "Please enter a valid percentage between 0 and 100",
          variant: "destructive",
        });
        return;
      }

      const { error } = await supabase
        .from('profiles')
        .update({ member_share_percentage: percentageValue })
        .eq('id', selectedUser.id);

      if (error) throw error;

      // Update local state
      setUsers(users.map(user => 
        user.id === selectedUser.id 
          ? { ...user, member_share_percentage: percentageValue } 
          : user
      ));

      toast({
        title: "Commission Updated",
        description: `Commission for ${selectedUser.first_name || selectedUser.email} has been updated to ${percentageValue}%`,
        variant: "default",
      });

      setCommissionDialogOpen(false);
    } catch (error) {
      console.error('Error updating commission percentage:', error);
      toast({
        title: "Update Failed",
        description: "Failed to update commission percentage. Please try again.",
        variant: "destructive",
      });
    }
  };

  const calculateCompanyPercentage = (memberPercentage: number): number => {
    return 100 - memberPercentage;
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Percent className="h-5 w-5" />
          Commission Management
        </CardTitle>
        <CardDescription>Configure member/company commission splits</CardDescription>
        <div className="relative mt-2">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search members..."
            className="pl-8"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="space-y-2">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
          </div>
        ) : (
          <>
            <div className="flex justify-end mb-4">
              <Button variant="outline" size="sm" onClick={fetchUsers}>
                <RefreshCw className="h-4 w-4 mr-2" />
                Refresh List
              </Button>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Member</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Member %</TableHead>
                  <TableHead>Company %</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredUsers.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center py-8 text-muted-foreground">
                      No members found
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredUsers.map((user) => {
                    const memberPercentage = user.member_share_percentage || 15;
                    const companyPercentage = calculateCompanyPercentage(memberPercentage);
                    
                    return (
                      <TableRow key={user.id} className="hover:bg-muted/50">
                        <TableCell>
                          <div className="font-medium">{user.first_name} {user.last_name}</div>
                          <div className="text-sm text-muted-foreground">{user.email}</div>
                        </TableCell>
                        <TableCell>
                          <span className={`inline-flex items-center rounded-full px-2 py-1 text-xs ${
                            user.status === 'active' ? 'bg-green-100 text-green-700' : 
                            user.status === 'suspended' ? 'bg-red-100 text-red-700' : 
                            'bg-yellow-100 text-yellow-700'
                          }`}>
                            {user.status || 'pending'}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className="font-medium">{memberPercentage}%</span>
                        </TableCell>
                        <TableCell>
                          <span className="font-medium">{companyPercentage}%</span>
                        </TableCell>
                        <TableCell>
                          <Button 
                            variant="outline" 
                            size="sm" 
                            onClick={() => openCommissionDialog(user)}
                            className="flex items-center gap-1"
                          >
                            <Percent className="h-4 w-4" />
                            Edit Split
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </>
        )}

        <Dialog open={commissionDialogOpen} onOpenChange={setCommissionDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Commission Split Settings</DialogTitle>
              <DialogDescription>
                Update commission percentage split for {selectedUser?.first_name} {selectedUser?.last_name}
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-6 py-4">
              <div className="space-y-4">
                <div>
                  <div className="flex justify-between mb-2">
                    <label className="text-sm font-medium">Member Percentage</label>
                    <label className="text-sm font-medium">Company Percentage</label>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex-1 relative">
                      <Input
                        type="number"
                        min="0"
                        max="100"
                        value={commissionPercentage}
                        onChange={(e) => setCommissionPercentage(e.target.value)}
                        className="pr-8"
                      />
                      <span className="absolute right-3 top-2.5 text-sm font-medium">%</span>
                    </div>
                    <span className="text-lg font-medium">/</span>
                    <div className="flex-1 relative">
                      <Input
                        type="number"
                        value={isNaN(parseInt(commissionPercentage)) ? '85' : (100 - parseInt(commissionPercentage)).toString()}
                        disabled
                        className="pr-8 bg-muted"
                      />
                      <span className="absolute right-3 top-2.5 text-sm font-medium">%</span>
                    </div>
                  </div>
                  <p className="text-xs text-muted-foreground mt-1">
                    Current split: {selectedUser?.member_share_percentage || 15}% / {selectedUser ? (100 - (selectedUser.member_share_percentage || 15)) : 85}%
                  </p>
                </div>

                <div className="bg-muted p-4 rounded-md">
                  <h4 className="text-sm font-medium mb-2">Split Effect Example:</h4>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-xs text-muted-foreground">Bank Input: R1,000</p>
                      <p className="text-xs font-medium mt-1">
                        Member: R{(parseInt(commissionPercentage) || 0) * 10} 
                        <span className="text-muted-foreground"> ({commissionPercentage || 0}%)</span>
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-muted-foreground">Physical Deposit:</p>
                      <p className="text-xs font-medium mt-1">
                        R{(100 - (parseInt(commissionPercentage) || 0)) * 10}
                        <span className="text-muted-foreground"> ({100 - (parseInt(commissionPercentage) || 0)}%)</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setCommissionDialogOpen(false)}>Cancel</Button>
              <Button onClick={updateCommissionPercentage} className="gap-2">
                <Save className="h-4 w-4" />
                Save Changes
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};
