
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { formatCurrency } from '@/utils/formatters';
import { Download } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import type { FinancialSummary } from '@/types/financial';

interface AdminFinancialSummaryProps {
  financialData: FinancialSummary;
  isLoading: boolean;
}

export const AdminFinancialSummary: React.FC<AdminFinancialSummaryProps> = ({
  financialData,
  isLoading
}) => {
  const { toast } = useToast();
  
  const handleExport = () => {
    toast({
      title: "Export Started",
      description: "Financial summary export has been initiated.",
    });
    
    // In a real implementation, we would generate and download a CSV/Excel file here
    setTimeout(() => {
      toast({
        title: "Export Completed",
        description: "Financial summary has been exported successfully.",
      });
    }, 1500);
  };

  const chartData = financialData.monthlyTotals.map(item => ({
    name: item.month,
    total: item.totalDeposits,
    member: item.memberShare,
    company: item.companyShare,
  }));

  return (
    <Card className="col-span-3">
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <CardTitle>Financial Summary</CardTitle>
          <CardDescription>An overview of financial performance</CardDescription>
        </div>
        <Button variant="outline" size="sm" onClick={handleExport}>
          <Download className="mr-2 h-4 w-4" />
          Export
        </Button>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="w-full">
            <Skeleton className="h-[200px] w-full" />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={chartData}>
              <XAxis
                dataKey="name"
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value}`}
              />
              <Tooltip 
                formatter={(value: number) => [formatCurrency(value), 'Amount']}
                labelFormatter={(label) => `Month: ${label}`}
              />
              <Bar
                dataKey="total"
                fill="#adfa1d"
                radius={[4, 4, 0, 0]}
                name="Total Deposits"
              />
              <Bar
                dataKey="member"
                fill="#2563eb"
                radius={[4, 4, 0, 0]}
                name="Member Share"
              />
              <Bar
                dataKey="company"
                fill="#ec4899"
                radius={[4, 4, 0, 0]}
                name="Company Share"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
          <div className="bg-muted/50 p-4 rounded-md">
            <div className="text-sm font-medium text-muted-foreground mb-1">Total Deposits</div>
            {isLoading ? (
              <Skeleton className="h-7 w-20" />
            ) : (
              <div className="text-xl font-semibold">
                {formatCurrency(financialData.yearlyTotals.totalDeposits)}
              </div>
            )}
          </div>
          
          <div className="bg-muted/50 p-4 rounded-md">
            <div className="text-sm font-medium text-muted-foreground mb-1">Member Share</div>
            {isLoading ? (
              <Skeleton className="h-7 w-20" />
            ) : (
              <div className="text-xl font-semibold text-blue-600 dark:text-blue-400">
                {formatCurrency(financialData.yearlyTotals.memberShare)}
              </div>
            )}
          </div>
          
          <div className="bg-muted/50 p-4 rounded-md">
            <div className="text-sm font-medium text-muted-foreground mb-1">Company Share</div>
            {isLoading ? (
              <Skeleton className="h-7 w-20" />
            ) : (
              <div className="text-xl font-semibold text-pink-600 dark:text-pink-400">
                {formatCurrency(financialData.yearlyTotals.companyShare)}
              </div>
            )}
          </div>
          
          <div className="bg-muted/50 p-4 rounded-md">
            <div className="text-sm font-medium text-muted-foreground mb-1">Average ROI</div>
            {isLoading ? (
              <Skeleton className="h-7 w-20" />
            ) : (
              <div className="text-xl font-semibold text-green-600 dark:text-green-400">
                {financialData.yearlyTotals.averageROI}%
              </div>
            )}
          </div>
        </div>
      </CardContent>
      
      <CardFooter className="text-sm text-muted-foreground border-t pt-4">
        Updated {new Date().toLocaleDateString()}
      </CardFooter>
    </Card>
  );
};
