import { useState, useEffect } from "react";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { 
  Tabs, 
  TabsContent, 
  TabsList, 
  TabsTrigger 
} from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { 
  ResponsiveContainer, 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  BarChart,
  Bar
} from "recharts";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { 
  Download, 
  Calendar as CalendarIcon, 
  Filter, 
  Users, 
  Calculator, 
  PieChart, 
  RefreshCw 
} from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { format } from "date-fns";
import { useMemberFinancials } from "@/hooks/admin/use-member-financials";
import { MemberListTable } from "@/components/admin/financials/MemberListTable";
import { MemberFinancialDetails } from "@/components/admin/financials/MemberFinancialDetails";
import { MarketTargetCalculator } from "@/components/admin/financials/MarketTargetCalculator";
import { FinancialOverviewStats } from "@/components/admin/financials/FinancialOverviewStats";
import { ExportMenu } from "@/components/admin/financials/ExportMenu";
import { User } from "@/types/user";

const FinancialTracking = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [showUserDetails, setShowUserDetails] = useState(false);
  
  const { toast } = useToast();

  const {
    users,
    selectedUser,
    loading,
    fetchUsers,
    fetchUserDetails,
    formatCurrency
  } = useMemberFinancials();

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleYearChange = (year: string) => {
    setSelectedYear(parseInt(year));
  };

  const handleMonthChange = (month: string) => {
    setSelectedMonth(parseInt(month));
  };

  const handleDateSelect = (date: Date | undefined) => {
    setSelectedDate(date);
  };

  const handleRefresh = async () => {
    await fetchUsers();
    toast({
      title: "Data refreshed",
      description: `Financial data updated as of ${format(new Date(), 'PPP')}`,
    });
  };

  const handleViewUserDetails = async (userId: string) => {
    await fetchUserDetails(userId);
    setSelectedUserId(userId);
    setShowUserDetails(true);
    setActiveTab("members");
  };

  const handleBackToList = () => {
    setShowUserDetails(false);
    setSelectedUserId(null);
  };

  const monthlyData = [
    { month: 'Jan', deposits: 5000, memberShare: 4250, companyShare: 750 },
    { month: 'Feb', deposits: 6200, memberShare: 5270, companyShare: 930 },
    { month: 'Mar', deposits: 8100, memberShare: 6885, companyShare: 1215 },
    { month: 'Apr', deposits: 7400, memberShare: 6290, companyShare: 1110 },
    { month: 'May', deposits: 9200, memberShare: 7820, companyShare: 1380 },
    { month: 'Jun', deposits: 11000, memberShare: 9350, companyShare: 1650 },
  ];
  
  const yearComparisonData = [
    { month: 'Jan', currentYear: 5000, previousYear: 4200 },
    { month: 'Feb', currentYear: 6200, previousYear: 5100 },
    { month: 'Mar', currentYear: 8100, previousYear: 6800 },
    { month: 'Apr', currentYear: 7400, previousYear: 6300 },
    { month: 'May', currentYear: 9200, previousYear: 7500 },
    { month: 'Jun', currentYear: 11000, previousYear: 8200 },
  ];

  return (
    <DashboardSidebar isAdmin>
      <div className="flex-1 w-full">
        <div className="sticky top-0 z-10 border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex h-16 items-center gap-4 px-6">
            <h1 className="text-3xl font-bold text-primary">Financial Tracking</h1>
          </div>
        </div>
        
        <div className="p-6 max-w-[1400px] mx-auto w-full">
          <div className="flex flex-col md:flex-row gap-3 mb-6 justify-between">
            <div className="flex flex-wrap gap-3">
              <Select value={selectedYear.toString()} onValueChange={handleYearChange}>
                <SelectTrigger className="w-32">
                  <SelectValue placeholder="Year" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="2023">2023</SelectItem>
                  <SelectItem value="2024">2024</SelectItem>
                  <SelectItem value="2025">2025</SelectItem>
                </SelectContent>
              </Select>
              
              <Select value={selectedMonth.toString()} onValueChange={handleMonthChange}>
                <SelectTrigger className="w-32">
                  <SelectValue placeholder="Month" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="1">January</SelectItem>
                  <SelectItem value="2">February</SelectItem>
                  <SelectItem value="3">March</SelectItem>
                  <SelectItem value="4">April</SelectItem>
                  <SelectItem value="5">May</SelectItem>
                  <SelectItem value="6">June</SelectItem>
                  <SelectItem value="7">July</SelectItem>
                  <SelectItem value="8">August</SelectItem>
                  <SelectItem value="9">September</SelectItem>
                  <SelectItem value="10">October</SelectItem>
                  <SelectItem value="11">November</SelectItem>
                  <SelectItem value="12">December</SelectItem>
                </SelectContent>
              </Select>
              
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" className="w-32 pl-3 text-left font-normal flex justify-start">
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {selectedDate ? format(selectedDate, 'PPP') : 'Pick a date'}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={selectedDate}
                    onSelect={handleDateSelect}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              
              <Button variant="outline" onClick={handleRefresh} className="gap-2">
                <RefreshCw className="h-4 w-4" />
                Refresh
              </Button>
            </div>
            
            <div className="flex gap-2">
              <ExportMenu
                selectedYear={selectedYear}
                selectedMonth={selectedMonth} 
              />
            </div>
          </div>

          {showUserDetails && selectedUserId ? (
            <MemberFinancialDetails 
              userId={selectedUserId} 
              onBack={handleBackToList}
            />
          ) : (
            <Tabs value={activeTab} onValueChange={setActiveTab}>
              <TabsList className="mb-4">
                <TabsTrigger value="overview" className="flex gap-2 items-center">
                  <PieChart className="h-4 w-4" />
                  Financial Overview
                </TabsTrigger>
                <TabsTrigger value="members" className="flex gap-2 items-center">
                  <Users className="h-4 w-4" />
                  Member Management
                </TabsTrigger>
                <TabsTrigger value="calculator" className="flex gap-2 items-center">
                  <Calculator className="h-4 w-4" />
                  Market Calculator
                </TabsTrigger>
              </TabsList>
              
              <TabsContent value="overview">
                <div className="space-y-6">
                  <FinancialOverviewStats 
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                  />
                  
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <Card>
                      <CardHeader className="pb-2">
                        <CardTitle>Monthly Financial Distribution</CardTitle>
                        <CardDescription>Breakdown of deposits, member share and company ROI</CardDescription>
                      </CardHeader>
                      <CardContent className="h-[350px]">
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart data={monthlyData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip formatter={(value) => formatCurrency(Number(value))} />
                            <Legend />
                            <Line type="monotone" dataKey="deposits" stroke="#8884d8" name="Total Deposits" />
                            <Line type="monotone" dataKey="memberShare" stroke="#82ca9d" name="Member Share" />
                            <Line type="monotone" dataKey="companyShare" stroke="#ff7300" name="Company ROI" />
                          </LineChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                    
                    <Card>
                      <CardHeader className="pb-2">
                        <CardTitle>Year-over-Year Comparison</CardTitle>
                        <CardDescription>Current year vs Previous year deposits</CardDescription>
                      </CardHeader>
                      <CardContent className="h-[350px]">
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart data={yearComparisonData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip formatter={(value) => formatCurrency(Number(value))} />
                            <Legend />
                            <Bar dataKey="currentYear" fill="#8884d8" name="2024" />
                            <Bar dataKey="previousYear" fill="#82ca9d" name="2023" />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </TabsContent>
              
              <TabsContent value="members">
                <MemberListTable 
                  users={users}
                  loading={loading}
                  onViewDetails={handleViewUserDetails}
                />
              </TabsContent>
              
              <TabsContent value="calculator">
                <MarketTargetCalculator />
              </TabsContent>
            </Tabs>
          )}
        </div>
      </div>
    </DashboardSidebar>
  );
};

export default FinancialTracking;
