
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { SignInHeader } from "@/components/auth/SignInHeader";
import { SignInModeToggle } from "@/components/auth/SignInModeToggle";
import { SignInForm } from "@/components/auth/SignInForm";
import { logger } from "@/utils/logger";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [redirectInitiated, setRedirectInitiated] = useState(false);
  const [errors, setErrors] = useState<{
    email?: string;
    password?: string;
    general?: string;
  }>({});
  
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (isLoading || redirectInitiated) return;
    
    // Validate form
    if (!email || !password) {
      setErrors({
        email: !email ? "Email is required" : undefined,
        password: !password ? "Password is required" : undefined
      });
      return;
    }

    setIsLoading(true);
    setErrors({});

    try {
      logger.info('Starting sign in process');
      
      // Sign in with email/password
      const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
        email: email.trim().toLowerCase(),
        password
      });

      if (signInError) {
        logger.error('Sign in error:', signInError);
        throw signInError;
      }

      if (!signInData?.user) {
        throw new Error('No user data returned');
      }

      logger.info('Sign in successful, checking admin status');
      setRedirectInitiated(true);

      // Check admin status using our new function
      const { data: isAdmin, error: adminCheckError } = await supabase.rpc('is_admin_email_direct');
      
      if (adminCheckError) {
        logger.error('Admin check error:', adminCheckError);
        throw adminCheckError;
      }

      logger.info('Admin check completed:', { isAdmin });

      // Check user status - CRITICAL for waiting list enforcement
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('status')
        .eq('id', signInData.user.id)
        .single();
        
      if (profileError) {
        logger.error('Profile fetch error:', profileError);
        throw profileError;
      }
      
      // Force a small delay to ensure state updates are processed
      await new Promise(resolve => setTimeout(resolve, 100));

      // STRENGTHENED CHECK: Only allow 'active' users or admins to access the application
      if (profile?.status !== 'active' && !isAdmin) {
        logger.info('User not active, redirecting to waiting list:', { status: profile?.status });
        toast({
          title: "Account pending approval",
          description: "Your account is waiting for administrator approval. Please check back later.",
          duration: 5000,
        });
        navigate('/waiting-list');
        return;
      }

      if (isAdmin) {
        logger.info('Admin access granted, redirecting to /admin');
        toast({
          title: "Welcome Admin!",
          description: "Redirecting to admin portal...",
        });
        // Use window.location for a hard redirect
        window.location.href = '/admin';
      } else {
        logger.info('Regular user access granted, redirecting to /dashboard');
        toast({
          title: "Welcome back!",
          description: "Signed in successfully",
        });
        // Use window.location for a hard redirect
        window.location.href = '/dashboard';
      }
      
    } catch (error: any) {
      logger.error('Sign in failed:', error);
      
      let errorMessage = "An error occurred during sign in. Please try again.";
      
      if (error.message === 'Invalid login credentials') {
        errorMessage = "Invalid email or password.";
      } else if (error.message?.includes('database')) {
        errorMessage = "Unable to connect to the service. Please try again later.";
      }
      
      setErrors({ general: errorMessage });
      
      toast({
        variant: "destructive",
        title: "Sign in failed",
        description: errorMessage,
      });
      
    } finally {
      if (!redirectInitiated) {
        setIsLoading(false);
        setRedirectInitiated(false);
      }
    }
  }, [email, password, isLoading, redirectInitiated, toast, navigate]);

  return (
    <div className="min-h-screen flex flex-col justify-center items-center p-4 bg-gradient-to-b from-muted/80 to-muted/95">
      <div className="w-full max-w-md space-y-8">
        <SignInHeader
          isAdmin={isAdmin}
          isLoading={isLoading}
          isCheckingAdmin={false}
        />

        <SignInModeToggle
          isAdmin={isAdmin}
          isLoading={isLoading}
          isCheckingAdmin={false}
          onModeSwitch={setIsAdmin}
        />

        <SignInForm
          isAdmin={isAdmin}
          isLoading={isLoading}
          isCheckingAdmin={false}
          redirectInitiated={redirectInitiated}
          email={email}
          password={password}
          errors={errors}
          onEmailChange={(e) => setEmail(e.target.value)}
          onPasswordChange={(e) => setPassword(e.target.value)}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default SignIn;
