
import { useQueryClient } from "@tanstack/react-query";
import { logger } from "@/utils/logger";

/**
 * Hook for invalidating market-related queries
 */
export const useQueryInvalidation = () => {
  const queryClient = useQueryClient();

  const invalidateMarketQueries = async (marketId: string) => {
    logger.info('Invalidating market queries', { marketId });
    
    // Invalidate all market-related queries to ensure data consistency
    await queryClient.invalidateQueries({ queryKey: ['markets'] });
    await queryClient.invalidateQueries({ queryKey: ['admin-markets'] });
    await queryClient.invalidateQueries({ queryKey: ['market', marketId] });
  };

  return { invalidateMarketQueries };
};
