
import React from "react";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { Switch } from "@/components/ui/switch";
import * as z from "zod";
import { marketFormSchema } from "../config/formConfig";

interface StatusFieldsProps {
  form: UseFormReturn<z.infer<typeof marketFormSchema>>;
  fields: string[];
}

export const StatusFields = ({ form, fields }: StatusFieldsProps) => {
  return (
    <>
      {fields.includes("auto_activate") && (
        <FormField
          control={form.control}
          name="auto_activate"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
              <div className="space-y-0.5">
                <FormLabel>Auto Activate Market</FormLabel>
                <p className="text-sm text-muted-foreground">
                  Automatically activate the market on start date
                </p>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
      )}

      {fields.includes("draft") && (
        <FormField
          control={form.control}
          name="draft"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
              <div className="space-y-0.5">
                <FormLabel>Save as Draft</FormLabel>
                <p className="text-sm text-muted-foreground">
                  Save the market without publishing it
                </p>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
      )}

      {fields.includes("auto_approve_participants") && (
        <FormField
          control={form.control}
          name="auto_approve_participants"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
              <div className="space-y-0.5">
                <FormLabel>Auto Approve Participants</FormLabel>
                <p className="text-sm text-muted-foreground">
                  Automatically approve members who join this market
                </p>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
      )}
    </>
  );
};
