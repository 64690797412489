
import { Market } from "@/types/market";
import MarketsList from "@/components/markets/MarketsList";
import { EmptyMarketsList } from "@/components/markets/empty/EmptyMarketsList";
import { useEffect, useRef, useState } from "react";
import { logger } from "@/utils/logger";

interface TabContentDisplayProps {
  markets: Market[] | null;
  hasMarkets: boolean;
  isLoading: boolean;
  value: string;
  onParticipate: (marketId: string) => Promise<void>;
  onContributionSubmitted: () => Promise<void>;
  onCloseTimeUpdate: (marketId: string, closeTime: string) => Promise<void>;
  onRefresh?: () => Promise<void>;
}

export const TabContentDisplay = ({
  markets,
  hasMarkets,
  isLoading,
  value,
  onParticipate,
  onContributionSubmitted,
  onCloseTimeUpdate,
  onRefresh
}: TabContentDisplayProps) => {
  // Track previous tab to avoid unnecessary refreshes
  const previousTabRef = useRef<string | null>(null);
  
  // Track if we've verified participation status for joined tab
  const [participationVerified, setParticipationVerified] = useState<boolean>(false);
  
  // Track last refresh time to prevent too frequent refreshes
  const lastRefreshTimeRef = useRef<number>(Date.now());

  // Enhanced debugging effect to log markets and participation status for each tab
  useEffect(() => {
    if (markets) {
      // Get a count of participating markets
      const participatingMarkets = markets.filter(m => m.isParticipating === true);
      
      logger.info(`TabContentDisplay [${value}] tab markets:`, {
        totalMarkets: markets.length,
        participatingCount: participatingMarkets.length,
        hasMarketsFlag: hasMarkets,
        isLoadingFlag: isLoading,
        tabChanged: previousTabRef.current !== value,
        participatingIds: participatingMarkets.map(m => m.id),
        participatingFlags: markets.slice(0, 5).map(m => ({
          id: m.id,
          name: m.name,
          isParticipating: m.isParticipating
        }))
      });
      
      // CRITICAL: Verify participation status for joined tab
      if (value === 'joined' && !participationVerified && !isLoading) {
        // If we're on the joined tab but no markets show as participating,
        // check if this might be a data inconsistency
        if (participatingMarkets.length === 0 && markets.length > 0) {
          logger.warn('Joined tab has no participating markets but markets exist', {
            totalMarkets: markets.length,
            firstFewMarkets: markets.slice(0, 5).map(m => ({
              id: m.id,
              name: m.name,
              isParticipating: m.isParticipating
            }))
          });
          
          // Request a refresh if we're on the joined tab but don't have any participating markets
          // and it's been at least 2 seconds since the last refresh
          const now = Date.now();
          if (onRefresh && previousTabRef.current !== value && now - lastRefreshTimeRef.current > 2000) {
            logger.info('Requesting refresh due to potential inconsistency in joined tab');
            onRefresh().catch(err => {
              logger.error('Error during joined tab refresh:', err);
            });
            lastRefreshTimeRef.current = now;
          }
          
          // Check if there's participation data in localStorage we can use as a fallback
          try {
            const storedData = localStorage.getItem('full_participation_data');
            if (storedData) {
              const parsed = JSON.parse(storedData);
              if (parsed && parsed.participatingMarketIds && Array.isArray(parsed.participatingMarketIds)) {
                logger.info('Found participation data in localStorage:', {
                  participatingIds: parsed.participatingMarketIds,
                  timestamp: new Date(parsed.timestamp).toISOString()
                });
                
                // If the data is recent (within last hour), we trust it
                if (now - parsed.timestamp < 3600000) {
                  if (parsed.participatingMarketIds.length > 0) {
                    logger.info('Using localStorage participation data to recover state');
                    // Apply the participation data from localStorage as a fallback
                    // We'll log this but the actual state update needs to happen elsewhere
                  }
                }
              }
            }
          } catch (e) {
            logger.error('Error checking localStorage participation data:', e);
          }
        }
        
        setParticipationVerified(true);
      }
      
      // Reset verification flag when tab changes
      if (previousTabRef.current !== value) {
        setParticipationVerified(false);
        
        // If tab changes to 'joined', schedule a refresh after a short delay
        if (value === 'joined' && onRefresh) {
          const now = Date.now();
          if (now - lastRefreshTimeRef.current > 2000) {
            setTimeout(() => {
              logger.info('Scheduling refresh after tab change to joined');
              onRefresh().catch(err => {
                logger.error('Error during tab change refresh:', err);
              });
            }, 500);
            lastRefreshTimeRef.current = now;
          }
        }
      }
      
      // Update previous tab reference
      previousTabRef.current = value;
    }
  }, [markets, value, hasMarkets, isLoading, onRefresh, participationVerified]);
  
  if (isLoading) {
    return (
      <MarketsList 
        markets={[]}
        onParticipate={onParticipate}
        onContributionSubmitted={() => onContributionSubmitted()}
        onCloseTimeUpdate={onCloseTimeUpdate}
        isLoading={true}
        onRefresh={onRefresh || (() => Promise.resolve())}
      />
    );
  }

  if (hasMarkets) {
    return (
      <MarketsList 
        markets={markets}
        onParticipate={onParticipate}
        onContributionSubmitted={() => onContributionSubmitted()}
        onCloseTimeUpdate={onCloseTimeUpdate}
        isLoading={isLoading}
        onRefresh={onRefresh || (() => Promise.resolve())}
      />
    );
  }

  return (
    <EmptyMarketsList 
      onRefresh={onRefresh} 
      filterActive={markets !== null && markets.length === 0} 
      tabContext={value}
    />
  );
};
