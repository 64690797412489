
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { Loader2, Users, BarChart3 } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";

interface User {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
}

interface Market {
  id: string;
  name: string;
}

interface MemberMarketSelectorProps {
  onSelect: (userId: string, marketId: string) => void;
}

export const MemberMarketSelector: React.FC<MemberMarketSelectorProps> = ({ onSelect }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [selectedMarketId, setSelectedMarketId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { toast } = useToast();
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch users
        const { data: userData, error: userError } = await supabase
          .from('profiles')
          .select('id, email, first_name, last_name')
          .eq('is_admin', false)
          .order('created_at', { ascending: false });
        
        if (userError) throw userError;
        
        // Fetch markets
        const { data: marketData, error: marketError } = await supabase
          .from('markets')
          .select('id, name')
          .order('name');
        
        if (marketError) throw marketError;
        
        setUsers(userData || []);
        setMarkets(marketData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error loading data",
          description: "Failed to load users and markets. Please try again.",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [toast]);
  
  const handleView = () => {
    if (selectedUserId && selectedMarketId) {
      onSelect(selectedUserId, selectedMarketId);
    } else {
      toast({
        title: "Selection required",
        description: "Please select both a member and a market.",
        variant: "destructive",
      });
    }
  };
  
  return (
    <Card className="mb-6">
      <CardHeader className="pb-3">
        <CardTitle className="text-xl flex items-center gap-2">
          <Users className="h-5 w-5 text-primary" />
          Select Member and Market
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {isLoading ? (
          <div className="space-y-3">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-1/3" />
          </div>
        ) : (
          <>
            <div className="grid gap-4 md:grid-cols-2">
              <div className="space-y-2">
                <label className="text-sm font-medium">Select Member</label>
                <Select 
                  value={selectedUserId} 
                  onValueChange={setSelectedUserId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a member" />
                  </SelectTrigger>
                  <SelectContent>
                    {users.map(user => (
                      <SelectItem key={user.id} value={user.id}>
                        {user.first_name} {user.last_name} ({user.email})
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium">Select Market</label>
                <Select 
                  value={selectedMarketId} 
                  onValueChange={setSelectedMarketId}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a market" />
                  </SelectTrigger>
                  <SelectContent>
                    {markets.map(market => (
                      <SelectItem key={market.id} value={market.id}>
                        {market.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            
            <div className="flex justify-end">
              <Button 
                onClick={handleView} 
                className="gap-2"
              >
                <BarChart3 className="h-4 w-4" />
                View Member Market Details
              </Button>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};
