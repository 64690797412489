
import { 
  BarChart, Bar, AreaChart, Area, XAxis, YAxis, CartesianGrid, 
  Tooltip, ResponsiveContainer, Legend, PieChart, Pie, Cell 
} from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { 
  TrendingUp, Users, Clock, Target, Percent, AlertTriangle, 
  DollarSign, ArrowUpRight, ArrowDownRight 
} from "lucide-react";
import { useMemo } from "react";
import { formatCurrency } from "@/utils/formatters";

interface Market {
  id: string;
  name: string;
  current_value: number;
  target_value: number;
  growth_rate: number;
  status: string;
  region?: string;
  participant_count?: number;
  active_participants?: number;
}

interface MarketPerformanceDetailsProps {
  market: Market;
}

export const MarketPerformanceDetails = ({ market }: MarketPerformanceDetailsProps) => {
  // Generate some sample data based on the market for visualization
  const performanceData = useMemo(() => {
    return [
      { month: 'Jan', value: market.current_value * 0.7, target: market.target_value * 0.6 },
      { month: 'Feb', value: market.current_value * 0.75, target: market.target_value * 0.65 },
      { month: 'Mar', value: market.current_value * 0.8, target: market.target_value * 0.7 },
      { month: 'Apr', value: market.current_value * 0.85, target: market.target_value * 0.75 },
      { month: 'May', value: market.current_value * 0.9, target: market.target_value * 0.8 },
      { month: 'Jun', value: market.current_value * 0.95, target: market.target_value * 0.85 },
      { month: 'Jul', value: market.current_value, target: market.target_value * 0.9 },
    ];
  }, [market]);

  const participationData = useMemo(() => {
    const baseParticipants = market.active_participants || market.participant_count || 50;
    return [
      { month: 'Jan', participants: Math.round(baseParticipants * 0.6) },
      { month: 'Feb', participants: Math.round(baseParticipants * 0.65) },
      { month: 'Mar', participants: Math.round(baseParticipants * 0.7) },
      { month: 'Apr', participants: Math.round(baseParticipants * 0.8) },
      { month: 'May', participants: Math.round(baseParticipants * 0.85) },
      { month: 'Jun', participants: Math.round(baseParticipants * 0.9) },
      { month: 'Jul', participants: baseParticipants },
    ];
  }, [market]);

  const distributionData = useMemo(() => {
    return [
      { name: 'Completed', value: 60, color: '#10b981' },
      { name: 'Active', value: 20, color: '#3b82f6' },
      { name: 'Pending', value: 15, color: '#f59e0b' },
      { name: 'Failed', value: 5, color: '#ef4444' },
    ];
  }, []);

  const COLORS = ['#10b981', '#3b82f6', '#f59e0b', '#ef4444'];

  // Calculate progress percentage
  const progressPercentage = Math.round((market.current_value / market.target_value) * 100);

  // Determine if market is performing well
  const isPerformingWell = market.growth_rate >= 0 && market.current_value >= market.target_value * 0.8;

  return (
    <div className="space-y-6">
      {/* Market overview stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card className="bg-background/60 shadow-sm">
          <CardHeader className="p-4 pb-2">
            <CardTitle className="text-sm text-muted-foreground">Current Value</CardTitle>
          </CardHeader>
          <CardContent className="p-4 pt-0">
            <div className="flex items-center justify-between">
              <div className="text-2xl font-bold">{formatCurrency(market.current_value)}</div>
              <DollarSign className="h-5 w-5 text-primary" />
            </div>
          </CardContent>
        </Card>

        <Card className="bg-background/60 shadow-sm">
          <CardHeader className="p-4 pb-2">
            <CardTitle className="text-sm text-muted-foreground">Target Progress</CardTitle>
          </CardHeader>
          <CardContent className="p-4 pt-0">
            <div className="flex items-center justify-between">
              <div className="text-2xl font-bold">{progressPercentage}%</div>
              <Target className="h-5 w-5 text-primary" />
            </div>
            <div className="w-full h-2 bg-gray-200 rounded-full mt-2">
              <div 
                className={`h-full rounded-full ${
                  progressPercentage >= 90 ? 'bg-green-500' : 
                  progressPercentage >= 70 ? 'bg-blue-500' : 
                  progressPercentage >= 40 ? 'bg-yellow-500' : 'bg-red-500'
                }`}
                style={{ width: `${Math.min(100, progressPercentage)}%` }}
              ></div>
            </div>
          </CardContent>
        </Card>

        <Card className="bg-background/60 shadow-sm">
          <CardHeader className="p-4 pb-2">
            <CardTitle className="text-sm text-muted-foreground">Growth Rate</CardTitle>
          </CardHeader>
          <CardContent className="p-4 pt-0">
            <div className="flex items-center justify-between">
              <div className={`text-2xl font-bold ${market.growth_rate >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {market.growth_rate >= 0 ? '+' : ''}{market.growth_rate}%
              </div>
              {market.growth_rate >= 0 ? (
                <ArrowUpRight className="h-5 w-5 text-green-500" />
              ) : (
                <ArrowDownRight className="h-5 w-5 text-red-500" />
              )}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Performance insights */}
      <Card className="border border-primary/10">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <AlertTriangle className={`h-5 w-5 ${isPerformingWell ? 'text-green-500' : 'text-yellow-500'}`} />
            Performance Insights
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">
              {isPerformingWell 
                ? `This market is performing well with a positive growth rate of ${market.growth_rate}% and has achieved ${progressPercentage}% of its target value.`
                : `This market requires attention. It is currently at ${progressPercentage}% of its target with a growth rate of ${market.growth_rate}%.`
              }
            </p>

            <div className="flex flex-wrap gap-2 mt-4">
              <div className="bg-muted p-2 rounded-md text-xs flex items-center gap-1.5">
                <Users className="h-3.5 w-3.5 text-primary" />
                <span>{market.active_participants || market.participant_count || 0} participants</span>
              </div>
              <div className="bg-muted p-2 rounded-md text-xs flex items-center gap-1.5">
                <Target className="h-3.5 w-3.5 text-primary" />
                <span>Target: {formatCurrency(market.target_value)}</span>
              </div>
              <div className="bg-muted p-2 rounded-md text-xs flex items-center gap-1.5">
                <Percent className="h-3.5 w-3.5 text-primary" />
                <span>Growth: {market.growth_rate}%</span>
              </div>
              <div className="bg-muted p-2 rounded-md text-xs flex items-center gap-1.5">
                <Clock className="h-3.5 w-3.5 text-primary" />
                <span>Region: {market.region || 'Global'}</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Charts */}
      <Tabs defaultValue="performance" className="w-full">
        <TabsList className="grid grid-cols-3 mb-4">
          <TabsTrigger value="performance">Performance</TabsTrigger>
          <TabsTrigger value="participation">Participation</TabsTrigger>
          <TabsTrigger value="distribution">Distribution</TabsTrigger>
        </TabsList>

        <TabsContent value="performance" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Value vs Target</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={performanceData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip 
                      formatter={(value: any) => [formatCurrency(Number(value)), undefined]}
                      labelFormatter={(label) => `Month: ${label}`}
                    />
                    <Legend />
                    <Area 
                      type="monotone" 
                      dataKey="value" 
                      stroke="#3b82f6" 
                      fill="#3b82f680"
                      name="Current Value" 
                    />
                    <Area 
                      type="monotone" 
                      dataKey="target" 
                      stroke="#f59e0b" 
                      fill="#f59e0b80"
                      name="Target Value"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="participation" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Monthly Participants</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={participationData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar 
                      dataKey="participants" 
                      name="Active Participants" 
                      fill="#10b981" 
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="distribution" className="space-y-4">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Status Distribution</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={distributionData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                    >
                      {distributionData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value: any) => [`${Number(value)}%`, undefined]} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};
