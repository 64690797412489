
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { useRegionalSettings } from "@/hooks/admin/use-regional-settings";
import { Save, Trash, Plus, Calculator, RefreshCw, LoaderIcon } from "lucide-react";
import { MarketTargetInput } from "../markets/tabs/configuration/MarketTargetInput";
import { MarketAdjustmentInput } from "../markets/tabs/configuration/MarketAdjustmentInput";
import { AdjustmentAmountInput } from "../markets/tabs/configuration/AdjustmentAmountInput";
import { AdjustedTargetDisplay } from "../markets/tabs/configuration/AdjustedTargetDisplay";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { normalizeRegionCode } from "@/utils/market/formatters";
import { syncRegionalTablesFromTargets } from "@/utils/market/sync-regional-tables";
import { toast } from "sonner";

export const MarketTargetCalculator: React.FC = () => {
  const [baseTarget, setBaseTarget] = useState<number>(5000);
  const [adjustmentAmount, setAdjustmentAmount] = useState<number>(500);
  const [adjustmentFactor, setAdjustmentFactor] = useState<number>(1.07);
  const [adjustedTarget, setAdjustedTarget] = useState<number>(0);
  const [markets, setMarkets] = useState<{id: string, name: string, region: string}[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string>("");
  const [selectedRegion, setSelectedRegion] = useState<string>("");
  const [savedTargets, setSavedTargets] = useState<{
    id: string;
    marketId: string;
    marketName: string;
    baseTarget: number;
    adjustmentAmount: number;
    adjustmentFactor: number;
    adjustedTarget: number;
  }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [syncInProgress, setSyncInProgress] = useState(false);
  
  const { toast: useToastHook } = useToast();
  const { settings: regionalSettings, loading: loadingSettings, refresh: refreshRegionalSettings } = useRegionalSettings();

  useEffect(() => {
    const fetchMarkets = async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from('markets')
          .select('id, name, region')
          .order('name');
          
        if (error) {
          throw error;
        }
        
        setMarkets(data || []);
        logger.info(`Fetched ${data?.length || 0} markets for calculator`);
      } catch (err) {
        logger.error('Error fetching markets:', err);
        useToastHook({
          title: "Error",
          description: "Failed to load markets",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchMarkets();
  }, []);
  
  useEffect(() => {
    const fetchSavedTargets = async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from('regional_targets')
          .select(`
            id,
            user_id,
            region_code,
            base_target,
            target_value,
            adjustment,
            factor,
            updated_at
          `)
          .order('region_code');
          
        if (error) {
          throw error;
        }
        
        const formattedTargets = data.map(target => ({
          id: target.id,
          marketId: target.region_code,
          marketName: `${target.region_code} Region`,
          baseTarget: target.base_target,
          adjustmentAmount: target.adjustment,
          adjustmentFactor: target.factor,
          adjustedTarget: target.target_value
        }));
        
        setSavedTargets(formattedTargets);
        logger.info(`Fetched ${formattedTargets.length} saved targets`);
      } catch (err) {
        logger.error('Error fetching saved targets:', err);
        useToastHook({
          title: "Error",
          description: "Failed to load saved targets",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSavedTargets();
    
    const handleRegionalSettingsUpdate = (event: Event) => {
      const customEvent = event as CustomEvent;
      logger.info('Regional settings updated, refreshing targets', customEvent.detail);
      fetchSavedTargets();
      refreshRegionalSettings();
    };
    
    // Listen for both regional settings and member-specific target updates
    window.addEventListener('regional-settings-updated', handleRegionalSettingsUpdate);
    window.addEventListener('user-target-updated', handleRegionalSettingsUpdate);
    window.addEventListener('market-targets-updated', handleRegionalSettingsUpdate);
    window.addEventListener('member-targets-synced', handleRegionalSettingsUpdate);
    
    return () => {
      window.removeEventListener('regional-settings-updated', handleRegionalSettingsUpdate);
      window.removeEventListener('user-target-updated', handleRegionalSettingsUpdate);
      window.removeEventListener('market-targets-updated', handleRegionalSettingsUpdate);
      window.removeEventListener('member-targets-synced', handleRegionalSettingsUpdate);
    };
  }, [refreshRegionalSettings]);

  useEffect(() => {
    calculateAdjustedTarget();
  }, [baseTarget, adjustmentAmount, adjustmentFactor]);

  const calculateAdjustedTarget = () => {
    if (adjustmentFactor <= 0) return;
    
    const calculated = baseTarget + (adjustmentAmount / adjustmentFactor);
    setAdjustedTarget(parseFloat(calculated.toFixed(2)));
  };

  // Handle saving and synchronizing the target with regional settings
  const handleSaveAndSync = async () => {
    if (!selectedMarket && !selectedRegion) {
      useToastHook({
        title: "Error",
        description: "Please select a market or region",
        variant: "destructive",
      });
      return;
    }
    
    setIsSaving(true);
    setSyncInProgress(true);
    
    try {
      const { data: sessionData } = await supabase.auth.getSession();
      const userId = sessionData.session?.user.id;
      
      if (!userId) {
        throw new Error("You must be logged in to save targets");
      }

      // Determine the region code from either selected market or direct region selection
      let regionCode: string;
      
      if (selectedRegion) {
        regionCode = selectedRegion.toUpperCase();
      } else {
        const marketInfo = markets.find(m => m.id === selectedMarket);
        if (!marketInfo) {
          throw new Error("Selected market not found");
        }
        regionCode = marketInfo.region.toUpperCase();
      }
      
      // First save to regional_targets
      const { data, error } = await supabase
        .from('regional_targets')
        .upsert({
          user_id: userId,
          region_code: regionCode,
          base_target: baseTarget,
          adjustment: adjustmentAmount,
          factor: adjustmentFactor,
          target_value: adjustedTarget,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,region_code'
        });
      
      if (error) throw error;
      
      // Also update the system default (user_id = 00000000-0000-0000-0000-000000000000)
      const { error: systemError } = await supabase
        .from('regional_targets')
        .upsert({
          user_id: '00000000-0000-0000-0000-000000000000',
          region_code: regionCode,
          base_target: baseTarget,
          adjustment: adjustmentAmount,
          factor: adjustmentFactor,
          target_value: adjustedTarget,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,region_code'
        });
        
      if (systemError) {
        logger.warn(`Warning: Could not update system defaults: ${systemError.message}`);
      }
      
      // Now sync these values to regional_settings and trigger cascade updates
      const syncSuccess = await syncRegionalTablesFromTargets(
        regionCode,
        baseTarget,
        adjustmentAmount,
        adjustmentFactor
      );
      
      if (!syncSuccess) {
        throw new Error(`Failed to sync changes to regional settings for ${regionCode}`);
      }
      
      // Update the saved targets list
      const targetId = selectedRegion || selectedMarket;
      const targetName = selectedRegion 
        ? `${selectedRegion.toUpperCase()} Region` 
        : markets.find(m => m.id === selectedMarket)?.name || "Unknown Market";
      
      const existingIndex = savedTargets.findIndex(t => 
        t.marketId === targetId || 
        t.marketId === regionCode
      );
      
      if (existingIndex >= 0) {
        const updatedTargets = [...savedTargets];
        updatedTargets[existingIndex] = {
          ...updatedTargets[existingIndex],
          baseTarget,
          adjustmentAmount,
          adjustmentFactor,
          adjustedTarget
        };
        setSavedTargets(updatedTargets);
      } else {
        const newTarget = {
          id: Date.now().toString(),
          marketId: targetId,
          marketName: `${targetName} (${regionCode})`,
          baseTarget,
          adjustmentAmount,
          adjustmentFactor,
          adjustedTarget
        };
        setSavedTargets([...savedTargets, newTarget]);
      }
      
      // Update regional_settings table to ensure UI components get updated
      const { error: settingsError } = await supabase
        .from('regional_settings')
        .upsert({
          region_code: regionCode,
          base_target: baseTarget,
          adjustment: adjustmentAmount,
          adjustment_factor: adjustmentFactor,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'region_code'
        });
        
      if (settingsError) {
        logger.warn(`Warning: Could not update regional_settings: ${settingsError.message}`);
      }
      
      // Force a refresh of the regional settings
      refreshRegionalSettings();
      
      // Refresh specific member targets if needed
      if (selectedMarket) {
        // Trigger update for all members of this market
        await supabase.rpc('refresh_member_targets_for_market', {
          p_market_id: selectedMarket
        });
      }
      
      // Notify the rest of the application
      window.dispatchEvent(new CustomEvent('market-targets-updated', {
        detail: {
          region: regionCode,
          settings: {
            baseTarget,
            adjustment: adjustmentAmount,
            factor: adjustmentFactor,
            targetValue: adjustedTarget
          },
          timestamp: new Date().toISOString()
        }
      }));
      
      // Also dispatch system-targets-updated event to ensure member views refresh
      window.dispatchEvent(new CustomEvent('system-targets-updated', {
        detail: {
          region: regionCode,
          timestamp: new Date().toISOString()
        }
      }));
      
      // Dispatch specific event to trigger member target refreshes
      window.dispatchEvent(new CustomEvent('all-member-targets-refreshed', {
        detail: {
          region: regionCode,
          timestamp: new Date().toISOString()
        }
      }));
      
      useToastHook({
        title: "Success",
        description: `Target saved and synchronized for ${regionCode}`,
      });
      
      toast.success(`Settings for ${regionCode} updated and synced`);
      
    } catch (err) {
      logger.error('Error saving and syncing target:', err);
      useToastHook({
        title: "Error",
        description: err instanceof Error ? err.message : "Failed to save and sync target",
        variant: "destructive",
      });
      toast.error("Failed to save and sync target");
    } finally {
      setIsSaving(false);
      setSyncInProgress(false);
      
      // Force refresh all data
      setTimeout(() => {
        handleRefresh();
      }, 500);
    }
  };

  const handleSaveTarget = async () => {
    if (!selectedMarket && !selectedRegion) {
      useToastHook({
        title: "Error",
        description: "Please select a market or region",
        variant: "destructive",
      });
      return;
    }
    
    setIsSaving(true);
    
    try {
      const { data: sessionData } = await supabase.auth.getSession();
      const userId = sessionData.session?.user.id;
      
      if (!userId) {
        throw new Error("You must be logged in to save targets");
      }
      
      let regionCode: string;
      let targetName: string;
      
      if (selectedRegion) {
        regionCode = selectedRegion.toUpperCase();
        targetName = `${regionCode} Region`;
      } else {
        const marketInfo = markets.find(m => m.id === selectedMarket);
        if (!marketInfo) throw new Error("Selected market not found");
        regionCode = marketInfo.region.toUpperCase();
        targetName = marketInfo.name;
      }
      
      const { data, error } = await supabase
        .from('regional_targets')
        .upsert({
          user_id: userId,
          region_code: regionCode,
          base_target: baseTarget,
          adjustment: adjustmentAmount,
          factor: adjustmentFactor,
          target_value: adjustedTarget,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,region_code'
        });
      
      if (error) throw error;
      
      const targetId = selectedRegion || selectedMarket;
      
      const existingIndex = savedTargets.findIndex(t => 
        t.marketId === targetId || 
        t.marketId === regionCode
      );
      
      if (existingIndex >= 0) {
        const updatedTargets = [...savedTargets];
        updatedTargets[existingIndex] = {
          ...updatedTargets[existingIndex],
          baseTarget,
          adjustmentAmount,
          adjustmentFactor,
          adjustedTarget
        };
        setSavedTargets(updatedTargets);
      } else {
        const newTarget = {
          id: Date.now().toString(),
          marketId: targetId,
          marketName: `${targetName} (${regionCode})`,
          baseTarget,
          adjustmentAmount,
          adjustmentFactor,
          adjustedTarget
        };
        setSavedTargets([...savedTargets, newTarget]);
      }
      
      window.dispatchEvent(new CustomEvent('market-targets-updated', {
        detail: {
          region: regionCode,
          settings: {
            baseTarget,
            adjustment: adjustmentAmount,
            factor: adjustmentFactor,
            targetValue: adjustedTarget
          },
          timestamp: new Date().toISOString()
        }
      }));
      
      useToastHook({
        title: "Success",
        description: `Target saved for ${targetName}`,
      });
      
    } catch (err) {
      logger.error('Error saving target:', err);
      useToastHook({
        title: "Error",
        description: "Failed to save target",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteTarget = async (id: string, regionCode: string) => {
    try {
      setIsLoading(true);
      
      const { error } = await supabase
        .from('regional_targets')
        .delete()
        .eq('region_code', regionCode);
        
      if (error) throw error;
      
      setSavedTargets(savedTargets.filter(target => target.id !== id));
      
      useToastHook({
        title: "Success",
        description: "Target deleted",
      });
      
      // Trigger refresh of other components
      window.dispatchEvent(new CustomEvent('regional-targets-updated', {
        detail: {
          region: regionCode,
          deleted: true,
          timestamp: new Date().toISOString()
        }
      }));
      
    } catch (err) {
      logger.error('Error deleting target:', err);
      useToastHook({
        title: "Error",
        description: "Failed to delete target",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectTarget = (target: any) => {
    setBaseTarget(target.baseTarget);
    setAdjustmentAmount(target.adjustmentAmount);
    setAdjustmentFactor(target.adjustmentFactor);
    
    // Try to match the target with a market or set region directly
    const matchingMarket = markets.find(m => m.id === target.marketId);
    if (matchingMarket) {
      setSelectedMarket(target.marketId);
      setSelectedRegion("");
    } else {
      setSelectedRegion(target.marketId);
      setSelectedMarket("");
    }
  };
  
  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      // Fetch targets
      const { data, error } = await supabase
        .from('regional_targets')
        .select('*')
        .order('region_code');
        
      if (error) throw error;
      
      // Format targets
      const formattedTargets = data.map(target => ({
        id: target.id,
        marketId: target.region_code,
        marketName: `${target.region_code} Region`,
        baseTarget: target.base_target,
        adjustmentAmount: target.adjustment,
        adjustmentFactor: target.factor,
        adjustedTarget: target.target_value
      }));
      
      setSavedTargets(formattedTargets);
      
      // Also refresh regional settings
      refreshRegionalSettings();
      
      toast.success("Targets refreshed");
      logger.info("Targets refreshed successfully");
      
      // Trigger a refresh of all related components
      window.dispatchEvent(new CustomEvent('calculator-refresh-complete', {
        detail: {
          timestamp: new Date().toISOString()
        }
      }));
      
    } catch (err) {
      logger.error('Error refreshing targets:', err);
      toast.error("Failed to refresh targets");
    } finally {
      setIsLoading(false);
    }
  };

  const handleApplyRegionalDefaults = () => {
    if (!selectedRegion && selectedMarket) {
      const market = markets.find(m => m.id === selectedMarket);
      if (market) {
        setSelectedRegion(market.region);
      }
    }
    
    if (!selectedRegion) {
      useToastHook({
        title: "Error",
        description: "Please select a region or market first",
        variant: "destructive",
      });
      return;
    }
    
    // Find the regional target for this region
    const regionCode = normalizeRegionCode(selectedRegion);
    const regionalTarget = savedTargets.find(t => t.marketId === regionCode);
    
    if (regionalTarget) {
      setBaseTarget(regionalTarget.baseTarget);
      setAdjustmentAmount(regionalTarget.adjustmentAmount);
      setAdjustmentFactor(regionalTarget.adjustmentFactor);
      toast.success(`Applied defaults for ${regionCode} region`);
    } else {
      toast.error(`No defaults found for ${regionCode} region`);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Market Target Calculator</CardTitle>
        <CardDescription>
          Configure and calculate target values for markets and regions
        </CardDescription>
      </CardHeader>
      
      <CardContent className="space-y-6">
        <div className="grid gap-6 md:grid-cols-2">
          <div className="space-y-4">
            <div className="space-y-4">
              <Label>Select Target Type</Label>
              <div className="grid grid-cols-2 gap-4">
                <Select
                  value={selectedRegion}
                  onValueChange={(value) => {
                    setSelectedRegion(value);
                    setSelectedMarket("");
                    
                    // If a region is selected, try to populate default values
                    const regionalTarget = savedTargets.find(t => t.marketId === value);
                    if (regionalTarget) {
                      setBaseTarget(regionalTarget.baseTarget);
                      setAdjustmentAmount(regionalTarget.adjustmentAmount);
                      setAdjustmentFactor(regionalTarget.adjustmentFactor);
                    }
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Region" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="">None</SelectItem>
                    <SelectItem value="AU">Australia (AU)</SelectItem>
                    <SelectItem value="ZA">South Africa (ZA)</SelectItem>
                    <SelectItem value="UK">United Kingdom (UK)</SelectItem>
                    <SelectItem value="US">United States (US)</SelectItem>
                  </SelectContent>
                </Select>
                
                <Select
                  value={selectedMarket}
                  onValueChange={(value) => {
                    setSelectedMarket(value);
                    setSelectedRegion("");
                    
                    // If a market is selected, get the region for default values
                    const marketInfo = markets.find(m => m.id === value);
                    if (marketInfo) {
                      const region = marketInfo.region;
                      const regionalTarget = savedTargets.find(t => t.marketId === region);
                      if (regionalTarget) {
                        setBaseTarget(regionalTarget.baseTarget);
                        setAdjustmentAmount(regionalTarget.adjustmentAmount);
                        setAdjustmentFactor(regionalTarget.adjustmentFactor);
                      }
                    }
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Market" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="">None</SelectItem>
                    {markets.map(market => (
                      <SelectItem key={market.id} value={market.id}>
                        {market.name} ({market.region})
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="baseTarget">Base Target Value</Label>
              <Input
                id="baseTarget"
                type="number"
                value={baseTarget}
                onChange={(e) => setBaseTarget(parseFloat(e.target.value))}
                placeholder="Enter base target"
              />
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="adjustmentAmount">Adjustment Amount</Label>
              <Input
                id="adjustmentAmount"
                type="number"
                value={adjustmentAmount}
                onChange={(e) => setAdjustmentAmount(parseFloat(e.target.value))}
                placeholder="Enter adjustment amount"
              />
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="adjustmentFactor">Adjustment Factor</Label>
              <Input
                id="adjustmentFactor"
                type="number"
                step="0.01"
                value={adjustmentFactor}
                onChange={(e) => setAdjustmentFactor(parseFloat(e.target.value))}
                placeholder="Enter adjustment factor"
              />
            </div>
            
            <div className="flex space-x-2 pt-4">
              <Button 
                className="flex-1"
                onClick={handleSaveTarget} 
                disabled={isLoading || isSaving}
              >
                {isSaving ? (
                  <>
                    <LoaderIcon className="mr-2 h-4 w-4 animate-spin" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    Save Target
                  </>
                )}
              </Button>
              
              <Button 
                className="flex-1"
                variant="default" 
                onClick={handleSaveAndSync}
                disabled={isLoading || isSaving || syncInProgress}
              >
                {syncInProgress ? (
                  <>
                    <LoaderIcon className="mr-2 h-4 w-4 animate-spin" />
                    Syncing...
                  </>
                ) : (
                  <>
                    <RefreshCw className="mr-2 h-4 w-4" />
                    Save & Sync
                  </>
                )}
              </Button>
            </div>
            
            <Button 
              variant="outline" 
              className="w-full mt-2"
              onClick={handleApplyRegionalDefaults}
            >
              <Calculator className="mr-2 h-4 w-4" />
              Apply Regional Defaults
            </Button>
          </div>
          
          <div className="space-y-4">
            <Card className="bg-muted/30">
              <CardHeader className="pb-2">
                <CardTitle className="text-lg">Calculated Results</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="text-sm">Base Target:</div>
                    <div className="text-sm font-medium text-right">
                      {new Intl.NumberFormat('en-ZA', {
                        style: 'currency',
                        currency: 'ZAR'
                      }).format(baseTarget)}
                    </div>
                    
                    <div className="text-sm">Adjustment:</div>
                    <div className="text-sm font-medium text-right">
                      {new Intl.NumberFormat('en-ZA', {
                        style: 'currency',
                        currency: 'ZAR'
                      }).format(adjustmentAmount)}
                    </div>
                    
                    <div className="text-sm">Factor:</div>
                    <div className="text-sm font-medium text-right">
                      {adjustmentFactor}
                    </div>
                    
                    <div className="border-t pt-1 text-base font-medium">Adjusted Target:</div>
                    <div className="border-t pt-1 text-base font-bold text-right text-primary">
                      {new Intl.NumberFormat('en-ZA', {
                        style: 'currency',
                        currency: 'ZAR'
                      }).format(adjustedTarget)}
                    </div>
                  </div>
                  
                  <div className="text-xs text-muted-foreground">
                    Formula: Base Target + (Adjustment / Factor) = {baseTarget} + ({adjustmentAmount} / {adjustmentFactor})
                  </div>
                </div>
              </CardContent>
            </Card>
            
            <Card>
              <CardHeader className="pb-2">
                <div className="flex items-center justify-between">
                  <CardTitle className="text-lg">Saved Targets</CardTitle>
                  <Button 
                    variant="ghost" 
                    size="icon" 
                    onClick={handleRefresh}
                    disabled={isLoading}
                  >
                    <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
                  </Button>
                </div>
              </CardHeader>
              <CardContent className="p-0">
                {isLoading ? (
                  <div className="flex items-center justify-center py-4">
                    <LoaderIcon className="h-4 w-4 animate-spin text-primary mr-2" />
                    <span>Loading targets...</span>
                  </div>
                ) : savedTargets.length === 0 ? (
                  <div className="py-4 text-center text-muted-foreground">
                    No saved targets found
                  </div>
                ) : (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Region</TableHead>
                        <TableHead className="text-right">Target</TableHead>
                        <TableHead></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {savedTargets.map(target => (
                        <TableRow key={target.id}>
                          <TableCell>{target.marketName}</TableCell>
                          <TableCell className="text-right font-medium">
                            {new Intl.NumberFormat('en-ZA', {
                              style: 'currency',
                              currency: 'ZAR'
                            }).format(target.adjustedTarget)}
                          </TableCell>
                          <TableCell className="flex justify-end space-x-1">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleSelectTarget(target)}
                            >
                              <Calculator className="h-4 w-4" />
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleDeleteTarget(target.id, target.marketId)}
                            >
                              <Trash className="h-4 w-4" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </CardContent>
      
      <CardFooter className="text-xs text-muted-foreground">
        <div className="space-y-1 w-full">
          <p><strong>Save Target:</strong> Saves the current values to the database without syncing to region settings</p>
          <p><strong>Save & Sync:</strong> Saves and synchronizes these values across all regional tables and market participants</p>
          <p><strong>Apply Regional Defaults:</strong> Loads the default values for the selected region or market's region</p>
        </div>
      </CardFooter>
    </Card>
  );
};
