
import { useState } from "react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { PersonalInfoStep } from "@/components/registration/PersonalInfoStep";
import { IdentificationStep } from "@/components/registration/IdentificationStep";
import { SecurityStep } from "@/components/registration/SecurityStep";
import { RegistrationHeader } from "@/components/registration/RegistrationHeader";
import { RegistrationNavigation } from "@/components/registration/RegistrationNavigation";
import { useRegistrationForm } from "@/hooks/useRegistrationForm";
import { useRegistrationHandler } from "@/hooks/useRegistrationHandler";

const Register = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { formData, errors, validateStep, validateField, setFormData } = useRegistrationForm();
  const { handleRegistration, isLoading, error } = useRegistrationHandler();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    validateField(name as keyof typeof formData, value);
  };

  const handleCheckboxChange = (checked: boolean) => {
    setFormData(prev => ({ ...prev, agreeToTerms: checked }));
    validateField('agreeToTerms', checked);
  };

  const handleCountryChange = (value: string) => {
    setFormData(prev => ({ ...prev, country: value }));
    validateField('country', value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateStep(3)) return;
    await handleRegistration(formData);
  };

  const nextStep = () => {
    if (validateStep(currentStep) && currentStep < 3) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center p-4 bg-gradient-to-b from-muted/80 to-muted/95 relative">
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-10"
        style={{ 
          backgroundImage: 'url("/lovable-uploads/6f1f027e-5208-4ce9-ad8d-7c7347ccbb06.png")',
          filter: 'grayscale(50%)'
        }}
      />
      
      <div className="w-full max-w-md space-y-8 relative">
        <RegistrationHeader currentStep={currentStep} />

        <div className="mt-8 roman-card backdrop-blur-md">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            {currentStep === 1 && (
              <PersonalInfoStep 
                formData={formData} 
                errors={errors}
                onChange={handleChange} 
              />
            )}

            {currentStep === 2 && (
              <IdentificationStep 
                formData={formData} 
                errors={errors}
                onChange={handleChange}
                onCountryChange={handleCountryChange}
              />
            )}

            {currentStep === 3 && (
              <SecurityStep 
                formData={formData} 
                errors={errors}
                onChange={handleChange}
                onCheckboxChange={handleCheckboxChange}
              />
            )}

            <RegistrationNavigation 
              currentStep={currentStep}
              isLoading={isLoading}
              onPrevStep={prevStep}
              onNextStep={nextStep}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
