
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';

export const useMarketsState = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  // Ensure consistent lowercase for default values
  const [selectedRegion, setSelectedRegion] = useState("all");
  const [riskLevel, setRiskLevel] = useState("all");
  const [activeTab, setActiveTab] = useState("available");
  const [retryCount, setRetryCount] = useState(0);
  const [authChecked, setAuthChecked] = useState(false);
  
  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  const handleRegionChange = (value: string) => {
    // Store the value as provided but ensure consistent comparison in the filter
    setSelectedRegion(value);
    logger.info('Region filter changed:', { newValue: value });
  };

  const handleRiskLevelChange = (value: string) => {
    // Store the value as provided but ensure consistent comparison in the filter
    setRiskLevel(value);
    logger.info('Risk level filter changed:', { newValue: value });
  };

  const handleRefresh = async () => {
    logger.info('Manually refreshing markets', { retryCount: retryCount + 1 });
    setRetryCount(prev => prev + 1);
    toast({
      title: "Refreshing",
      description: "Updating market data...",
    });
    
    // Verify auth session is still valid before refetching
    const { data: { session: currentSession } } = await supabase.auth.getSession();
    if (!currentSession) {
      toast({
        title: "Session Expired",
        description: "Your session has expired. Please sign in again.",
        variant: "destructive",
      });
      navigate('/signin');
      return;
    }
    
    return retryCount + 1;
  };

  return {
    searchQuery,
    selectedRegion,
    riskLevel,
    activeTab,
    setActiveTab,
    retryCount,
    authChecked,
    setAuthChecked,
    handleSearchChange,
    handleRegionChange,
    handleRiskLevelChange,
    handleRefresh
  };
};
