
import { useState } from "react";
import { Market } from "@/types/market";
import { useToast } from "@/hooks/use-toast";
import { cloneMarket } from "@/utils/market/clone-market";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { toast } from "sonner";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Loader2 } from "lucide-react";

interface CloneMarketDialogProps {
  market: Market;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCloneSuccess: () => void;
}

type MarketType = "standard" | "premium" | "exclusive" | "duplicate";
type MarketRiskLevel = "low" | "medium" | "high";

export const CloneMarketDialog = ({ 
  market, 
  open, 
  onOpenChange, 
  onCloneSuccess 
}: CloneMarketDialogProps) => {
  const { toast: uiToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Form state
  const [name, setName] = useState(`${market.name} (Clone)`);
  const [description, setDescription] = useState(market.description || "");
  const [marketType, setMarketType] = useState<MarketType>((market.market_type || "standard") as MarketType);
  const [marketRiskLevel, setMarketRiskLevel] = useState<MarketRiskLevel>((market.market_risk_level || "medium") as MarketRiskLevel);
  
  // Type-safe handlers for Select components
  const handleMarketTypeChange = (value: string) => {
    setMarketType(value as MarketType);
  };

  const handleRiskLevelChange = (value: string) => {
    setMarketRiskLevel(value as MarketRiskLevel);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setIsSubmitting(true);
      
      // Get current user
      const { data: userData } = await supabase.auth.getUser();
      if (!userData || !userData.user) {
        throw new Error("Authentication required");
      }
      
      logger.info('Cloning market with modifications:', { 
        marketId: market.id,
        newName: name,
        newType: marketType,
        newRiskLevel: marketRiskLevel
      });
      
      // Clone the market
      const result = await cloneMarket({
        sourceMarketId: market.id, 
        userId: userData.user.id,
        newName: name,
        newDescription: description,
        newRiskLevel: marketRiskLevel
      });
      
      if (result.success) {
        toast.success("Market cloned successfully", {
          description: "A copy of this market has been created with your changes."
        });
        
        // Close dialog and trigger refresh
        onOpenChange(false);
        onCloneSuccess();
      } else {
        throw new Error(result.error || "Failed to clone market");
      }
    } catch (error) {
      const err = error as Error;
      logger.error('Error cloning market:', err);
      toast.error("Failed to clone market", {
        description: err.message
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Clone Market</DialogTitle>
          <DialogDescription>
            Make changes to the market before creating a clone.
          </DialogDescription>
        </DialogHeader>
        
        <form onSubmit={handleSubmit} className="space-y-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="name">Market Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
            />
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="marketType">Market Type</Label>
              <Select 
                value={marketType} 
                onValueChange={handleMarketTypeChange}
              >
                <SelectTrigger id="marketType">
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="standard">Standard</SelectItem>
                  <SelectItem value="premium">Premium</SelectItem>
                  <SelectItem value="exclusive">Exclusive</SelectItem>
                  <SelectItem value="duplicate">Duplicate</SelectItem>
                </SelectContent>
              </Select>
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="riskLevel">Risk Level</Label>
              <Select 
                value={marketRiskLevel} 
                onValueChange={handleRiskLevelChange}
              >
                <SelectTrigger id="riskLevel">
                  <SelectValue placeholder="Select risk" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="low">Low</SelectItem>
                  <SelectItem value="medium">Medium</SelectItem>
                  <SelectItem value="high">High</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          
          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Cloning...
                </>
              ) : (
                "Clone Market"
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
