
import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { MemberMarketSelector } from "./MemberMarketSelector";
import { RegionalTargetSettings } from "./RegionalTargetSettings";
import { Settings, Calculator, Users, TrendingUp, AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";

export const MemberMarketConfiguration = () => {
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [selectedMarketId, setSelectedMarketId] = useState<string>("");
  const [activeTab, setActiveTab] = useState("target-settings");
  
  const handleSelectMemberMarket = (userId: string, marketId: string) => {
    setSelectedUserId(userId);
    setSelectedMarketId(marketId);
  };
  
  const handleSettingsUpdated = () => {
    // Refresh data or show notifications if needed
    console.log("Settings updated for user", selectedUserId, "and market", selectedMarketId);
  };

  const bothSelected = selectedUserId && selectedMarketId;

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-xl flex items-center gap-2">
          <Settings className="h-6 w-6 text-primary" />
          Member Market Configuration
        </CardTitle>
        <CardDescription>
          Configure market settings for specific member participations
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <MemberMarketSelector onSelectMemberMarket={handleSelectMemberMarket} />
        
        {!bothSelected && (
          <Alert variant="default" className="bg-amber-50 border-amber-200">
            <AlertCircle className="h-5 w-5 text-amber-600" />
            <AlertDescription className="text-amber-800">
              Please select both a member and a market above to configure settings.
            </AlertDescription>
          </Alert>
        )}
        
        {bothSelected && (
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="target-settings" className="flex items-center gap-2">
                <TrendingUp className="h-4 w-4" />
                Target Settings
              </TabsTrigger>
              <TabsTrigger value="calculator" className="flex items-center gap-2">
                <Calculator className="h-4 w-4" />
                Market Calculator
              </TabsTrigger>
              <TabsTrigger value="members" className="flex items-center gap-2">
                <Users className="h-4 w-4" />
                Member Management
              </TabsTrigger>
            </TabsList>
            
            <TabsContent value="target-settings" className="pt-4">
              <RegionalTargetSettings 
                userId={selectedUserId} 
                marketId={selectedMarketId}
                onSettingsUpdated={handleSettingsUpdated}
              />
            </TabsContent>
            
            <TabsContent value="calculator" className="pt-4">
              <div className="p-6 text-center border rounded-md">
                <p>Market calculator functionality will be implemented here.</p>
              </div>
            </TabsContent>
            
            <TabsContent value="members" className="pt-4">
              <div className="p-6 text-center border rounded-md">
                <p>Member management functionality will be implemented here.</p>
              </div>
            </TabsContent>
          </Tabs>
        )}
      </CardContent>
    </Card>
  );
};
