
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@/hooks/use-toast';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';
import { Session } from '@supabase/supabase-js';

/**
 * Hook to verify admin access and redirect to dashboard if unauthorized
 */
export const useAdminAuth = (
  session: Session | null,
  isLoading: boolean,
  redirectTo: string = '/dashboard'
) => {
  const [isVerifyingAdmin, setIsVerifyingAdmin] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminVerificationError, setAdminVerificationError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { toast } = useToast();

  // Verify admin status whenever session changes
  useEffect(() => {
    const verifyAdmin = async () => {
      if (isLoading || !session) {
        if (!isLoading && !session) {
          // Not authenticated at all
          logger.warn('Admin verification failed: No active session');
          toast({
            title: 'Authentication Required',
            description: 'You must be logged in to access this area.',
            variant: 'destructive',
          });
          navigate('/signin', { replace: true });
        }
        return;
      }

      try {
        setIsVerifyingAdmin(true);
        setAdminVerificationError(null);
        
        // Use Supabase RPC to reliably check admin status
        const { data: isAdminUser, error } = await supabase.rpc('is_admin_email_direct');
        
        if (error) {
          logger.error('Admin verification error:', error);
          setAdminVerificationError(error.message);
          throw error;
        }
        
        if (!isAdminUser) {
          logger.warn('Admin access denied:', { 
            userId: session.user.id, 
            email: session.user.email 
          });
          
          toast({
            title: 'Access Denied',
            description: 'You need administrator privileges to access this area.',
            variant: 'destructive',
          });
          
          navigate(redirectTo, { replace: true });
          return;
        }
        
        // Successfully verified admin
        setIsAdmin(true);
        logger.info('Admin access verified for:', session.user.email);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        
        logger.error('Admin verification failed:', error);
        setAdminVerificationError(errorMessage);
        
        toast({
          title: 'Verification Error',
          description: 'Could not verify administrator privileges. Please try again.',
          variant: 'destructive',
        });
        
        navigate(redirectTo, { replace: true });
      } finally {
        setIsVerifyingAdmin(false);
      }
    };

    verifyAdmin();
  }, [session, isLoading, navigate, toast, redirectTo]);

  return {
    isVerifyingAdmin,
    isAdmin,
    adminVerificationError
  };
};
