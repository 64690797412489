
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { Session } from "@supabase/supabase-js";

/**
 * Safely gets market participation counts for a user
 * This function provides a fallback mechanism for getting participation data
 * if direct table access fails
 */
export const getMarketParticipationCounts = async (
  userId: string,
  marketIds: string[],
  session: Session
): Promise<Record<string, number> | null> => {
  try {
    logger.info('Getting participation counts', { marketCount: marketIds.length });
    
    if (!marketIds.length) {
      return {};
    }
    
    // Use the secure RPC function to get participation counts
    const { data: participations, error } = await supabase
      .rpc('get_user_market_participation_counts', {
        p_user_id: userId,
        p_market_ids: marketIds
      });
    
    if (error) {
      logger.warn('Participation count RPC failed:', error.message);
      
      // Fallback to direct query if RPC fails
      const { data: directParticipations, error: directError } = await supabase
        .from('market_participants')
        .select('market_id, id')
        .eq('user_id', userId)
        .in('market_id', marketIds);
        
      if (directError) {
        logger.error('Direct participation query failed:', directError.message);
        
        // Create empty counts as last resort fallback
        const emptyCounts: Record<string, number> = {};
        marketIds.forEach(id => {
          emptyCounts[id] = 0;
        });
        
        return emptyCounts;
      }
      
      // Process direct query results
      const counts: Record<string, number> = {};
      if (directParticipations) {
        // Group by market_id and count
        directParticipations.forEach(p => {
          counts[p.market_id] = (counts[p.market_id] || 0) + 1;
        });
        
        // Ensure all requested markets have an entry
        marketIds.forEach(id => {
          if (counts[id] === undefined) {
            counts[id] = 0;
          }
        });
      }
      
      return counts;
    }
    
    // Convert the RPC response to the expected format
    const counts: Record<string, number> = {};
    
    if (Array.isArray(participations)) {
      // Process the participations data
      participations.forEach(p => {
        if (p && p.market_id) {
          counts[p.market_id] = p.participation_count || 0;
        }
      });
      
      // Ensure all requested market IDs are in the result
      marketIds.forEach(id => {
        if (counts[id] === undefined) {
          counts[id] = 0;
        }
      });
    } else {
      // If not an array, create empty counts
      marketIds.forEach(id => {
        counts[id] = 0;
      });
    }
    
    logger.info('Successfully fetched participation counts', { 
      markets: Object.keys(counts).length 
    });
    
    return counts;
  } catch (error) {
    logger.error('getMarketParticipationCounts error:', 
      error instanceof Error ? error.message : 'Unknown error');
    
    // Return empty counts on error
    const fallbackCounts: Record<string, number> = {};
    marketIds.forEach(id => {
      fallbackCounts[id] = 0;
    });
    
    return fallbackCounts;
  }
};
