import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { supabase } from "@/lib/supabase";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useToast } from "@/hooks/use-toast";
import { Download, Users, TrendingUp, Activity, BarChart } from "lucide-react";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import DashboardOverview from "@/components/admin/dashboard/DashboardOverview";
import ActivityLog from "@/components/admin/dashboard/ActivityLog";
import { MembersOverview } from "@/components/admin/dashboard/MembersOverview";
import MarketsOverview from "@/components/admin/dashboard/MarketsOverview";
import { logger } from "@/utils/logger";
import { toast } from "sonner";
import { useRealtimeData } from "@/hooks/use-realtime-data";

interface Market {
  id: string;
  name: string;
  status: string;
  target_value: number;
  current_value: number;
  growth_rate: number;
  region?: string;
  participant_count?: number;
  active_participants?: number;
}

const fetchMarkets = async (): Promise<Market[]> => {
  const { data, error } = await supabase
    .from('markets')
    .select('*');

  if (error) {
    logger.error('Error fetching markets:', error);
    toast.error('Failed to load markets data');
    throw error;
  }

  const formattedMarkets: Market[] = data.map(market => ({
    id: market.id,
    name: market.name || 'Unnamed Market',
    status: market.status || 'unknown',
    target_value: market.target_value || 0,
    current_value: market.current_value || 0,
    growth_rate: market.growth_rate || 0,
    region: market.region,
    active_participants: market.active_participants || 0,
    participant_count: market.active_participants || 0
  }));

  logger.info(`Fetched ${formattedMarkets.length} markets`);
  return formattedMarkets;
};

const fetchRecentActivity = async () => {
  try {
    const { data, error } = await supabase
      .from('market_history')
      .select('id, action, timestamp, details, market_id, performed_by')
      .order('timestamp', { ascending: false })
      .limit(10);
      
    if (error) throw error;
    
    return data.map(item => ({
      id: item.id,
      action: item.action,
      user: item.performed_by, // We'd normally fetch user details
      timestamp: item.timestamp
    }));
  } catch (error) {
    logger.error('Error fetching recent activity:', error);
    return [
      { id: '1', action: 'User Approved', user: 'John Doe', timestamp: new Date().toISOString() },
      { id: '2', action: 'Market Updated', user: 'Admin', timestamp: new Date().toISOString() },
      { id: '3', action: 'Deposit Approved', user: 'Jane Smith', timestamp: new Date().toISOString() },
    ];
  }
};

const AdminDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { toast: uiToast } = useToast();
  const activeTab = searchParams.get("tab") || "overview";

  const { 
    data: markets,
    isLoading: marketsLoading,
    error: marketsError,
    refresh: refreshMarkets
  } = useRealtimeData<Market[]>(
    fetchMarkets,
    [{ table: 'markets', events: ['INSERT', 'UPDATE', 'DELETE'] }]
  );
  
  const {
    data: recentActivity,
    isLoading: activityLoading,
    refresh: refreshActivity
  } = useRealtimeData(
    fetchRecentActivity,
    [{ table: 'market_history', events: ['INSERT'] }]
  );

  useEffect(() => {
    const handleRegionalSettingsUpdate = () => {
      logger.info('Regional settings updated, refreshing dashboard data');
      handleRefresh();
    };
    
    window.addEventListener('regional-settings-updated', handleRegionalSettingsUpdate);
    window.addEventListener('regional-targets-updated', handleRegionalSettingsUpdate);
    
    return () => {
      window.removeEventListener('regional-settings-updated', handleRegionalSettingsUpdate);
      window.removeEventListener('regional-targets-updated', handleRegionalSettingsUpdate);
    };
  }, []);

  const handleTabChange = (value: string) => {
    setSearchParams({ tab: value });
  };

  const handleRefresh = async () => {
    await Promise.all([refreshMarkets(), refreshActivity()]);
    uiToast({
      title: "Data Refreshed",
      description: "The dashboard data has been refreshed.",
    });
  };

  const downloadReport = () => {
    const headers = ["ID", "First Name", "Last Name", "Status", "Created At"];
    
    const csvData = [
      ["1", "John", "Doe", "active", new Date().toISOString()],
      ["2", "Jane", "Smith", "active", new Date().toISOString()],
      ["3", "Bob", "Johnson", "pending", new Date().toISOString()]
    ];

    const csvContent = [
      headers.join(","),
      ...csvData.map(row => row.join(","))
    ].join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'users-report.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    uiToast({
      title: "Report Downloaded",
      description: "The users report has been downloaded successfully.",
    });
  };

  return (
    <DashboardSidebar isAdmin>
      <div className="flex-1 w-full">
        <div className="sticky top-0 z-10 border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex h-16 items-center gap-4 px-6">
            <h1 className="text-3xl font-bold text-primary">Admin Dashboard</h1>
            <div className="ml-auto flex gap-2">
              <Button variant="outline" onClick={handleRefresh} className="gap-2 bg-card hover:bg-muted/20">
                <Activity className="h-4 w-4" />
                Refresh Data
              </Button>
              <Button variant="outline" onClick={downloadReport} className="gap-2 bg-card hover:bg-muted/20">
                <Download className="h-4 w-4" />
                Download Report
              </Button>
            </div>
          </div>
        </div>

        <div className="p-6 max-w-[1400px] mx-auto w-full">
          <Tabs value={activeTab} onValueChange={handleTabChange} className="space-y-6">
            <div className="bg-muted/30 p-2 rounded-lg">
              <TabsList className="inline-flex h-12 items-center justify-center rounded-md bg-transparent w-full gap-1">
                <TabsTrigger 
                  value="overview" 
                  className="inline-flex items-center justify-center gap-2 h-full px-6 data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all"
                >
                  <BarChart className="h-5 w-5" />
                  Overview
                </TabsTrigger>
                <TabsTrigger 
                  value="users"
                  className="inline-flex items-center justify-center gap-2 h-full px-6 data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all"
                >
                  <Users className="h-5 w-5" />
                  Users
                </TabsTrigger>
                <TabsTrigger 
                  value="markets"
                  className="inline-flex items-center justify-center gap-2 h-full px-6 data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all"
                >
                  <TrendingUp className="h-5 w-5" />
                  Markets
                </TabsTrigger>
              </TabsList>
            </div>

            <TabsContent value="overview" className="space-y-6">
              <DashboardOverview 
                membersCount={0} // Will be fetched by the component
                activeMarketsCount={markets?.filter(m => m.status === 'active').length || 0}
                totalMarketsCount={markets?.length || 0}
                totalValue={markets?.reduce((acc, m) => acc + m.current_value, 0) || 0}
              />
              <ActivityLog 
                activities={recentActivity || []} 
              />
            </TabsContent>

            <TabsContent value="users">
              <MembersOverview />
            </TabsContent>

            <TabsContent value="markets">
              <MarketsOverview 
                markets={markets || []} 
              />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </DashboardSidebar>
  );
};

export default AdminDashboard;
