
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { useDailyFinancialRecords } from '@/hooks/financials/use-daily-financial-records';
import { useMonthlyFinancialSummary } from '@/hooks/financials/use-monthly-financial-summary';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';

export interface MonthlyGoalData {
  month: string;
  amount: number;
}

export interface GoalsData {
  monthlyTarget: number;
  currentProgress: number;
  percentComplete: number;
  currentStreak: number;
  historicalData: MonthlyGoalData[];
  isLoading: boolean;
}

const DEFAULT_MONTHLY_TARGET = 5000; // Default monthly target in Rand

// Helper to get month abbr from month number
const getMonthAbbr = (monthNum: number): string => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthNum - 1];
};

export const useBankingGoals = () => {
  const { session } = useAuth();
  const userId = session?.user?.id;
  
  // Get current month and year
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // JS months are 0-indexed
  const currentYear = today.getFullYear();
  
  // Fetch current month's daily financial records
  const { records, dailyTotals, isLoading: isLoadingRecords } = useDailyFinancialRecords(
    userId,
    currentMonth,
    currentYear
  );
  
  const [monthlyTarget, setMonthlyTarget] = useState(DEFAULT_MONTHLY_TARGET);
  const [historicalData, setHistoricalData] = useState<MonthlyGoalData[]>([]);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [isLoadingHistorical, setIsLoadingHistorical] = useState(true);
  
  // Calculate current streak
  useEffect(() => {
    if (!records || records.length === 0) return;
    
    let streak = 0;
    const sortedRecords = [...records].sort((a, b) => 
      new Date(b.record_date).getTime() - new Date(a.record_date).getTime()
    );
    
    // Start from most recent day and count consecutive days with deposits
    for (const record of sortedRecords) {
      if (record.bank_deposit && record.bank_deposit > 0) {
        streak++;
      } else {
        break;
      }
    }
    
    setCurrentStreak(streak);
  }, [records]);
  
  // Fetch historical monthly data
  const fetchHistoricalData = useCallback(async () => {
    if (!userId) return;
    
    try {
      setIsLoadingHistorical(true);
      
      // Fetch monthly financial summaries for the past 6 months
      const startMonth = currentMonth - 5;
      const startYear = startMonth <= 0 ? currentYear - 1 : currentYear;
      const adjustedStartMonth = startMonth <= 0 ? startMonth + 12 : startMonth;
      
      const { data, error } = await supabase
        .from('monthly_financial_summaries')
        .select('month, year, total_deposits')
        .eq('user_id', userId)
        .or(`year.gt.${startYear - 1}`)
        .order('year', { ascending: true })
        .order('month', { ascending: true });
      
      if (error) {
        throw error;
      }
      
      if (data) {
        const monthlyData = data.map(item => ({
          month: getMonthAbbr(item.month),
          amount: item.total_deposits
        }));
        
        setHistoricalData(monthlyData);
      }
      
    } catch (err) {
      logger.error('Error fetching historical goals data:', err);
    } finally {
      setIsLoadingHistorical(false);
    }
  }, [userId, currentMonth, currentYear]);
  
  // Fetch user's monthly target
  const fetchMonthlyTarget = useCallback(async () => {
    if (!userId) return;
    
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('monthly_banking_target')
        .eq('id', userId)
        .single();
      
      if (error && error.code !== 'PGRST116') {
        throw error;
      }
      
      // Check if data exists, data has the property, and the value is not null
      if (data && 'monthly_banking_target' in data && data.monthly_banking_target !== null) {
        setMonthlyTarget(data.monthly_banking_target);
      }
    } catch (err) {
      logger.error('Error fetching monthly target:', err);
    }
  }, [userId]);
  
  useEffect(() => {
    fetchHistoricalData();
    fetchMonthlyTarget();
  }, [fetchHistoricalData, fetchMonthlyTarget]);
  
  const currentProgress = dailyTotals.totalDeposits;
  const percentComplete = monthlyTarget > 0 ? Math.min(Math.round((currentProgress / monthlyTarget) * 100), 100) : 0;
  
  return {
    monthlyTarget,
    currentProgress,
    percentComplete,
    currentStreak,
    historicalData,
    isLoading: isLoadingRecords || isLoadingHistorical
  };
};
