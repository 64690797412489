
import React from 'react';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

interface MarketProgressBarProps {
  currentValue: number;
  targetValue: number;
  status: string;
}

export const MarketProgressBar: React.FC<MarketProgressBarProps> = ({ currentValue, targetValue, status }) => {
  const progressPercentage = targetValue > 0 
    ? Math.min(100, Math.round((currentValue / targetValue) * 100)) 
    : 0;
    
  const getProgressColor = () => {
    if (['failed', 'closed_failed'].includes(status)) {
      return 'bg-red-600 dark:bg-red-500';
    }
    if (['success', 'closed_successful', 'completed'].includes(status)) {
      return 'bg-green-600 dark:bg-green-500';
    }
    
    // For active markets, return color based on progress
    if (progressPercentage < 25) {
      return 'bg-red-600 dark:bg-red-500';
    } else if (progressPercentage < 50) {
      return 'bg-orange-600 dark:bg-orange-500';
    } else if (progressPercentage < 75) {
      return 'bg-yellow-600 dark:bg-yellow-500';
    } else {
      return 'bg-green-600 dark:bg-green-500';
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center text-xs text-foreground">
        <span>Progress</span>
        <span className="font-medium">{progressPercentage}%</span>
      </div>
      <div className="relative">
        <Progress 
          value={progressPercentage} 
          className={cn("h-2", progressPercentage === 100 ? "rounded-full" : "rounded-l-full")}
          indicatorClassName={cn(
            getProgressColor(),
            "transition-all duration-500 ease-in-out"
          )}
        />
        
        {progressPercentage > 0 && progressPercentage < 100 && (
          <div 
            className="absolute top-0 right-0 h-full w-[2px] bg-primary/30 transform translate-x-px"
            style={{ left: `${progressPercentage}%` }}
          />
        )}
      </div>
    </div>
  );
};
