
import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';
import { useQueryClient } from '@tanstack/react-query';
import { getMarketsQueryKey } from '@/hooks/markets/use-markets-query-key';
import { useAuth } from '@/contexts/AuthContext';

export interface UpdateResult {
  success: boolean;
  message?: string;
}

export const useMarketStatus = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const queryClient = useQueryClient();
  const { session } = useAuth();
  
  const updateMarketStatus = async (marketId: string, newStatus: string): Promise<UpdateResult> => {
    if (isUpdating) {
      return { success: false, message: 'Status update already in progress' };
    }
    
    setIsUpdating(true);
    
    try {
      logger.info(`Updating market status for ${marketId} to ${newStatus}`);
      
      // First, update metadata with the reason for failure if applicable
      if (newStatus === 'closed_failed') {
        const metadata = {
          closure_reason: 'Maximum contribution attempts reached without success',
          max_attempts_reached: true,
          closed_at: new Date().toISOString(),
          failed_at: new Date().toISOString()
        };
        
        // Update the metadata
        const { error: metadataError } = await supabase.rpc('update_market_metadata_field', {
          market_id_param: marketId, 
          field_name: 'status_details', 
          field_value: metadata
        });
        
        if (metadataError) {
          logger.error('Error updating market metadata:', metadataError);
        }
      }
      
      // Use the controlled function to update market status
      // Changed from 'Member' to 'Admin' as per error message requirements
      const { error } = await supabase.rpc('update_market_status_controlled', {
        market_id_param: marketId,
        new_status: newStatus,
        source: 'Admin',
        admin_id: session?.user?.id || null
      });
      
      if (error) {
        logger.error('Error updating market status:', error);
        return { 
          success: false, 
          message: error.message || 'Failed to update market status' 
        };
      }
      
      // Invalidate queries to refresh data
      await queryClient.invalidateQueries({
        queryKey: getMarketsQueryKey(session?.user?.id),
      });
      
      return { success: true };
    } catch (error) {
      logger.error('Exception updating market status:', error);
      return { 
        success: false, 
        message: error instanceof Error ? error.message : 'An unexpected error occurred' 
      };
    } finally {
      setIsUpdating(false);
    }
  };
  
  return {
    updateMarketStatus,
    isUpdating
  };
};
