
import { useLocation } from "react-router-dom";
import Navigation from "./Navigation";
import { useAuth } from "@/contexts/AuthContext";
import { ChatBubble } from "./admin/messaging/ChatBubble";
import { DashboardTopBar } from "./dashboard/DashboardTopBar";

interface AppLayoutProps {
  children: React.ReactNode;
}

export function AppLayout({ children }: AppLayoutProps) {
  const location = useLocation();
  const { session } = useAuth();
  const isDashboardRoute = location.pathname.startsWith('/admin') || location.pathname.startsWith('/dashboard');
  const isAuthRoute = location.pathname === '/signin' || location.pathname === '/register';

  return (
    <div className="min-h-screen bg-background flex flex-col">
      {!isDashboardRoute && !isAuthRoute && <Navigation />}
      {isDashboardRoute && <DashboardTopBar />}
      
      <div className={`flex-grow ${!isDashboardRoute && !isAuthRoute ? "pt-24" : ""} ${isDashboardRoute ? "pt-20" : ""}`}>
        {children}
      </div>
      
      {/* Show chat bubble on all pages when a user is logged in */}
      {session && <ChatBubble />}
    </div>
  );
}
