
import { useEffect, useState } from "react";
import { useMemberFinancials } from "@/hooks/admin/use-member-financials";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { User } from "./types";
import { MemberListTable } from "../financials/MemberListTable";
import { ArrowLeft, Download, FileSpreadsheet, Loader2, RefreshCw } from "lucide-react";
import { formatCurrency } from "@/utils/formatters";
import { MemberTransactionHistory } from "./MemberTransactionHistory";
import { AdminProtectedRoute } from "../AdminProtectedRoute";
import { Badge } from "@/components/ui/badge";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { toast } from "sonner";

export const MemberFinancials: React.FC = () => {
  const {
    users,
    selectedUser,
    transactions,
    loading,
    transactionsLoading,
    fetchUsers,
    fetchUserDetails,
    fetchUserTransactions,
    updateUserBalance,
    formatCurrency: formatCurrencyLocal,
    setSelectedUser,
  } = useMemberFinancials();

  const [isExporting, setIsExporting] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleViewDetails = async (userId: string) => {
    await fetchUserDetails(userId);
    await fetchUserTransactions(userId);
  };

  const handleBackToList = () => {
    setSelectedUser(null);
  };

  const handleExportData = () => {
    setIsExporting(true);
    
    setTimeout(() => {
      // Prepare CSV data
      const headers = ["ID", "Name", "Email", "Status", "Balance", "Share %"];
      
      const rows = users.map(user => [
        user.id,
        `${user.first_name || ''} ${user.last_name || ''}`,
        user.email,
        user.status || 'pending',
        user.balance?.toString() || '0',
        (user.member_share_percentage?.toString() || '85') + '%'
      ]);

      const csvContent = [
        headers.join(","),
        ...rows.map(row => row.join(","))
      ].join("\n");

      // Create download link
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'member-financials.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setIsExporting(false);
      toast.success("Data exported successfully");
    }, 1000);
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchUsers();
    if (selectedUser) {
      await fetchUserDetails(selectedUser.id);
      await fetchUserTransactions(selectedUser.id);
    }
    setIsRefreshing(false);
    toast.success("Data refreshed successfully");
  };

  const handleUpdateStatus = async (userId: string, status: string) => {
    try {
      const response = await fetch(`/api/admin/users/${userId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to update user status');
      }
      
      await fetchUsers();
      if (selectedUser && selectedUser.id === userId) {
        await fetchUserDetails(userId);
      }
      
      return Promise.resolve();
    } catch (error) {
      console.error('Error updating user status:', error);
      return Promise.reject(error);
    }
  };

  const getInitials = (user: User) => {
    return `${user.first_name?.charAt(0) || ''}${user.last_name?.charAt(0) || ''}`.toUpperCase();
  };

  return (
    <AdminProtectedRoute>
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          <h2 className="text-2xl font-bold">Member Financial Management</h2>
          <div className="flex gap-2">
            <Button 
              variant="outline" 
              size="sm" 
              onClick={handleRefresh}
              disabled={isRefreshing}
              className="gap-2"
            >
              {isRefreshing ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <RefreshCw className="h-4 w-4" />
              )}
              Refresh
            </Button>
            <Button 
              variant="outline" 
              size="sm" 
              onClick={handleExportData}
              disabled={isExporting}
              className="gap-2"
            >
              {isExporting ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <FileSpreadsheet className="h-4 w-4" />
              )}
              Export
            </Button>
          </div>
        </div>

        {selectedUser ? (
          <div className="space-y-6">
            <Button 
              variant="ghost" 
              onClick={handleBackToList} 
              className="mb-4 gap-2"
            >
              <ArrowLeft className="h-4 w-4" />
              Back to Members List
            </Button>

            <Card className="border-primary/20">
              <CardHeader className="pb-4">
                <CardTitle className="flex items-center gap-4">
                  <Avatar className="h-10 w-10 border">
                    <AvatarImage src={selectedUser.avatar_url || ''} alt={`${selectedUser.first_name} ${selectedUser.last_name}`} />
                    <AvatarFallback>{getInitials(selectedUser)}</AvatarFallback>
                  </Avatar>
                  <div>
                    {selectedUser.first_name} {selectedUser.last_name}
                    <div className="flex items-center gap-2 mt-1">
                      <Badge variant="outline" className={`text-xs ${
                        selectedUser.status === 'active' ? 'bg-green-100 text-green-800' :
                        selectedUser.status === 'suspended' ? 'bg-red-100 text-red-800' :
                        'bg-blue-100 text-blue-800'
                      }`}>
                        {selectedUser.status || 'pending'}
                      </Badge>
                      <span className="text-sm text-muted-foreground">{selectedUser.email}</span>
                    </div>
                  </div>
                </CardTitle>
                <CardDescription>
                  Member ID: {selectedUser.id}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-lg">Current Balance</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold text-primary">
                        {formatCurrencyLocal(selectedUser.balance)}
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-lg">Member Share</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold">
                        {selectedUser.member_share_percentage || 85}%
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader className="pb-2">
                      <CardTitle className="text-lg">Company ROI</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold">
                        {100 - (selectedUser.member_share_percentage || 85)}%
                      </div>
                    </CardContent>
                  </Card>
                </div>

                <Tabs defaultValue="transactions">
                  <TabsList className="mb-4">
                    <TabsTrigger value="transactions">Transaction History</TabsTrigger>
                    <TabsTrigger value="settings">Account Settings</TabsTrigger>
                  </TabsList>
                  <TabsContent value="transactions">
                    <MemberTransactionHistory
                      transactions={transactions}
                      isLoading={transactionsLoading}
                      onExport={() => {
                        // Handle export
                        toast.success("Transactions exported successfully");
                      }}
                    />
                  </TabsContent>
                  <TabsContent value="settings">
                    <Card>
                      <CardHeader>
                        <CardTitle>Account Settings</CardTitle>
                        <CardDescription>
                          Manage balance and share percentages for this member
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="space-y-4">
                        <div>
                          <label htmlFor="balance" className="text-sm font-medium">
                            Current Balance
                          </label>
                          <div className="flex gap-2 mt-1">
                            <Input
                              id="balance"
                              type="number"
                              placeholder="Current Balance"
                              defaultValue={selectedUser.balance || 0}
                              disabled={true}
                            />
                            <Button 
                              variant="outline"
                              onClick={() => {
                                toast.info("This feature will be enabled soon");
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                        
                        <div>
                          <label htmlFor="share" className="text-sm font-medium">
                            Member Share Percentage
                          </label>
                          <div className="flex gap-2 mt-1">
                            <Input
                              id="share"
                              type="number"
                              placeholder="Share Percentage"
                              defaultValue={selectedUser.member_share_percentage || 85}
                              min={0}
                              max={100}
                              disabled={true}
                            />
                            <Button 
                              variant="outline"
                              onClick={() => {
                                toast.info("This feature will be enabled soon");
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </TabsContent>
                </Tabs>
              </CardContent>
            </Card>
          </div>
        ) : (
          <MemberListTable 
            users={users} 
            loading={loading} 
            onViewDetails={handleViewDetails}
            onUpdateStatus={handleUpdateStatus}
            onUpdateBalance={updateUserBalance}
          />
        )}
      </div>
    </AdminProtectedRoute>
  );
};
