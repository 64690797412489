
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";

interface MarketRiskSelectorProps {
  value: 'low' | 'medium' | 'high';
  onChange: (value: 'low' | 'medium' | 'high') => void;
}

export const MarketRiskSelector = ({ value, onChange }: MarketRiskSelectorProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="riskLevel">Risk Level</Label>
      <Select
        value={value}
        onValueChange={(val) => onChange(val as 'low' | 'medium' | 'high')}
      >
        <SelectTrigger id="riskLevel">
          <SelectValue placeholder="Select risk level" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="low">Low</SelectItem>
          <SelectItem value="medium">Medium</SelectItem>
          <SelectItem value="high">High</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
