
import { Button } from "@/components/ui/button";
import { Shield } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="w-full max-w-4xl mx-auto text-center"
    >
      <div className="space-y-8 backdrop-blur-sm p-8 sm:p-12 md:p-16 rounded-2xl border border-primary/20 shadow-2xl bg-black/40">
        <Shield className="w-20 h-20 sm:w-24 sm:h-24 text-primary mx-auto animate-float" />
        
        <div className="space-y-6">
          <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold text-primary-foreground mb-6 tracking-tight">Veni, Vidi, Vici</h1>
          <div className="space-y-4">
            <p className="text-2xl sm:text-3xl text-primary-foreground/90 font-medium">
              All Roads Lead to Rome
            </p>
            <p className="text-lg sm:text-xl text-primary-foreground/80 max-w-2xl mx-auto leading-relaxed">
              Where Ancient Wisdom Meets Modern Finance
            </p>
          </div>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-6 justify-center items-center pt-6">
          <Button 
            variant="default" 
            size="lg" 
            className="w-full sm:w-auto bg-primary hover:bg-primary/90 text-primary-foreground text-lg px-10 py-6 rounded-xl transition-all duration-300 shadow-lg hover:shadow-xl" 
            onClick={() => navigate("/register")}
          >
            Register Now
          </Button>
          <Button 
            variant="outline" 
            size="lg" 
            className="w-full sm:w-auto border-2 border-primary text-primary-foreground hover:bg-primary/10 text-lg px-10 py-6 rounded-xl transition-all duration-300" 
            onClick={() => navigate("/signin")}
          >
            Sign In
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export default Hero;
