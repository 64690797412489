
type LogLevel = 'info' | 'warn' | 'error' | 'debug';

interface LogEntry {
  timestamp: string;
  level: LogLevel;
  message: string;
  context?: unknown;
}

class Logger {
  private static instance: Logger;
  private isDevelopment: boolean;

  private constructor() {
    this.isDevelopment = import.meta.env.DEV;
  }

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  private formatMessage(level: LogLevel, message: string, context?: unknown): LogEntry {
    return {
      timestamp: new Date().toISOString(),
      level,
      message,
      context
    };
  }

  private log(level: LogLevel, message: string, context?: unknown) {
    const logEntry = this.formatMessage(level, message, context);

    if (this.isDevelopment) {
      const logFn = level === 'error' ? console.error : 
                    level === 'warn' ? console.warn : 
                    level === 'debug' ? console.debug : 
                    console.log;

      logFn(`[${logEntry.timestamp}] [${level.toUpperCase()}] ${message}`, context || '');
    }

    // In production, you might want to send logs to a service
    if (!this.isDevelopment && (level === 'error' || level === 'warn')) {
      console.error(`[${logEntry.timestamp}] [${level.toUpperCase()}] ${message}`, context || '');
      // Here you could integrate with an error reporting service
    }
  }

  info(message: string, context?: unknown) {
    this.log('info', message, context);
  }

  warn(message: string, context?: unknown) {
    this.log('warn', message, context);
  }

  error(message: string, context?: unknown) {
    this.log('error', message, context);
  }

  debug(message: string, context?: unknown) {
    this.log('debug', message, context);
  }
}

export const logger = Logger.getInstance();
