
import { useState, useEffect } from "react";
import { Bell, RefreshCw, ShieldCheck } from "lucide-react";
import { Button } from "@/components/ui/button";
import { supabase } from "@/lib/supabase";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Separator } from "@/components/ui/separator";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { useToast } from "@/hooks/use-toast";

interface Notification {
  id: string;
  title: string;
  description: string;
  content: string;
  is_read: boolean;
  created_at: string;
  sender_id: string;
  sender_name?: string;
  is_admin?: boolean;
}

const NotificationsMenu = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const [activeFilter, setActiveFilter] = useState<'all' | 'unread'>('all');
  const { toast } = useToast();

  // Fetch notifications for the current user
  const fetchNotifications = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('announcements_with_read_status')
        .select('id, title, content, created_at, is_read, sender_id')
        .order('created_at', { ascending: false })
        .limit(30);
        
      if (error) throw error;

      // Get sender information for each notification
      const notificationsWithSenderInfo = await Promise.all(data.map(async item => {
        let senderName = "Unknown";
        let isAdmin = false;
        
        if (item.sender_id) {
          const { data: senderData } = await supabase
            .from('profiles')
            .select('first_name, last_name, is_admin')
            .eq('id', item.sender_id)
            .single();
            
          if (senderData) {
            senderName = senderData.is_admin ? "Admin" : `${senderData.first_name} ${senderData.last_name}`;
            isAdmin = senderData.is_admin;
          }
        }
        
        return {
          id: item.id,
          title: item.title,
          content: item.content,
          description: new Date(item.created_at).toLocaleString(),
          is_read: item.is_read || false,
          created_at: item.created_at,
          sender_id: item.sender_id,
          sender_name: senderName,
          is_admin: isAdmin
        };
      }));
      
      setNotifications(notificationsWithSenderInfo);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]);
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  };

  // Refresh notifications
  const handleRefresh = () => {
    setIsRefreshing(true);
    fetchNotifications();
    toast({
      title: "Refreshing notifications",
      description: "Getting your latest notifications..."
    });
  };

  // Mark a notification as read
  const markAsRead = async (id: string) => {
    try {
      await supabase.rpc('mark_announcement_as_read', {
        p_announcement_id: id
      });

      // Update local state
      setNotifications(prev => prev.map(notification => 
        notification.id === id ? { ...notification, is_read: true } : notification
      ));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  // Mark all notifications as read
  const markAllAsRead = async () => {
    try {
      const unreadIds = notifications.filter(n => !n.is_read).map(n => n.id);
      if (unreadIds.length === 0) return;

      // Mark each notification as read
      await Promise.all(unreadIds.map(id => 
        supabase.rpc('mark_announcement_as_read', {
          p_announcement_id: id
        })
      ));

      // Update local state
      setNotifications(prev => prev.map(notification => ({
        ...notification,
        is_read: true
      })));
      
      toast({
        title: "All notifications marked as read",
        description: `${unreadIds.length} notification${unreadIds.length > 1 ? 's' : ''} marked as read`
      });
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
      toast({
        title: "Error",
        description: "Could not mark all notifications as read",
        variant: "destructive"
      });
    }
  };

  useEffect(() => {
    fetchNotifications();

    // Set up realtime subscription
    const channel = supabase
      .channel('notifications_changes')
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'announcements'
      }, () => {
        fetchNotifications();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  // Get filtered notifications
  const filteredNotifications = activeFilter === 'all' 
    ? notifications 
    : notifications.filter(n => !n.is_read);

  // Count unread notifications
  const unreadCount = notifications.filter(n => !n.is_read).length;

  return (
    <Sheet open={openSheet} onOpenChange={setOpenSheet}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="hover:bg-primary/10 transition-colors duration-200 rounded-full relative"
        >
          <Bell className="h-5 w-5 text-primary" />
          {unreadCount > 0 && (
            <span className="absolute -top-1 -right-1 h-4 w-4 bg-red-500 rounded-full text-xs text-white flex items-center justify-center">
              {unreadCount}
            </span>
          )}
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[400px] sm:w-[450px] py-[3px] my-0 mx-0 px-[12px]">
        <div className="flex justify-end pr-12 pt-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={handleRefresh}
            disabled={isRefreshing}
            className="rounded-full hover:bg-primary/10"
          >
            <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
            <span className="sr-only">Refresh notifications</span>
          </Button>
        </div>
        <SheetHeader>
          <div className="flex items-center justify-between">
            <SheetTitle className="text-xl font-bold">Notifications</SheetTitle>
          </div>
          <div className="flex items-center justify-between mt-2">
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                className={`text-xs ${activeFilter === 'all' ? 'bg-primary/10 border-primary/20' : ''}`}
                onClick={() => setActiveFilter('all')}
              >
                All
              </Button>
              <Button
                variant="outline"
                size="sm"
                className={`text-xs ${activeFilter === 'unread' ? 'bg-primary/10 border-primary/20' : ''}`}
                onClick={() => setActiveFilter('unread')}
              >
                Unread {unreadCount > 0 && `(${unreadCount})`}
              </Button>
            </div>
            {unreadCount > 0 && (
              <Button
                variant="ghost"
                size="sm"
                className="text-xs text-primary"
                onClick={markAllAsRead}
              >
                Mark all as read
              </Button>
            )}
          </div>
        </SheetHeader>
        <Separator className="my-4" />
        <ScrollArea className="h-[calc(100vh-150px)]">
          {isLoading ? (
            <div className="p-4 flex justify-center">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-primary"></div>
            </div>
          ) : filteredNotifications.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-40 text-center p-4">
              <Bell className="h-8 w-8 text-muted-foreground/50 mb-2" />
              <p className="text-muted-foreground">
                {activeFilter === 'all' ? "No notifications yet" : "No unread notifications"}
              </p>
            </div>
          ) : (
            <div className="space-y-2 px-1">
              {filteredNotifications.map(notification => (
                <Card
                  key={notification.id}
                  className={`p-3 hover:bg-primary/5 cursor-pointer transition-colors ${
                    !notification.is_read ? 'bg-primary/5 border-l-2 border-primary' : 'border'
                  }`}
                  onClick={() => markAsRead(notification.id)}
                >
                  <div className="flex items-start space-x-2">
                    <Avatar className="h-8 w-8 flex-shrink-0">
                      {notification.is_admin ? (
                        <ShieldCheck className="h-4 w-4 text-primary m-2" />
                      ) : (
                        <AvatarFallback className="text-xs bg-primary/10 text-primary">
                          {notification.sender_name?.charAt(0) || 'U'}
                        </AvatarFallback>
                      )}
                    </Avatar>
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <h4 className={`font-medium text-sm ${!notification.is_read ? 'text-primary' : ''}`}>
                          {notification.title}
                        </h4>
                        {!notification.is_read && <span className="w-2 h-2 rounded-full bg-primary"></span>}
                      </div>
                      <p className="text-xs text-muted-foreground line-clamp-2">
                        {notification.content}
                      </p>
                      <div className="flex items-center justify-between mt-1">
                        <p className="text-xs text-muted-foreground">
                          {new Date(notification.created_at).toLocaleString()}
                        </p>
                        <div className="flex items-center">
                          <Badge variant="outline" className="text-xs px-1.5 py-0">
                            {notification.is_admin ? "Admin" : "User"}
                          </Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          )}
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default NotificationsMenu;
