
import { supabase } from "@/lib/supabase";
import { Market } from "@/types/market";
import { logger } from "@/utils/logger";
import { parseMarketMetadata } from "./metadata-parser";
import { v4 as uuidv4 } from "uuid";

interface CloneMarketOptions {
  sourceMarketId: string;
  userId: string;
  newName?: string;
  newDescription?: string;
  newTargetValue?: number;
  newAdjustmentFactor?: number;
  newMaxParticipations?: number;
  newRiskLevel?: "low" | "medium" | "high";
}

interface CloneMarketResult {
  success: boolean;
  marketId?: string;
  error?: string;
}

/**
 * Clones a market with optional new parameters
 */
export async function cloneMarket({
  sourceMarketId,
  userId,
  newName,
  newDescription,
  newTargetValue,
  newAdjustmentFactor,
  newMaxParticipations,
  newRiskLevel
}: CloneMarketOptions): Promise<CloneMarketResult> {
  try {
    // Fetch the source market
    const { data: sourceMarket, error: fetchError } = await supabase
      .from('markets')
      .select('*')
      .eq('id', sourceMarketId)
      .single();
    
    if (fetchError) {
      logger.error('Error fetching source market for cloning:', fetchError);
      return { success: false, error: 'Failed to fetch source market' };
    }
    
    if (!sourceMarket) {
      return { success: false, error: 'Source market not found' };
    }
    
    // Generate a new ID for the cloned market
    const newMarketId = uuidv4();
    
    // Parse metadata from source market
    const metadata = parseMarketMetadata(sourceMarket.metadata);
    
    // Create new metadata with optional new description
    const newMetadata = {
      ...(metadata || {}),
      description: newDescription || (metadata && metadata.description ? metadata.description : ''),
      cloned_from: sourceMarketId,
      cloned_at: new Date().toISOString()
    };
    
    // Create the new market - fix the property names to match the database schema
    const { error: insertError } = await supabase
      .from('markets')
      .insert({
        id: newMarketId,
        name: newName || `${sourceMarket.name} (Copy)`,
        // Use created_at instead of created_by as per the error message
        created_at: new Date().toISOString(),
        status: 'draft',
        target_value: newTargetValue || sourceMarket.target_value,
        adjustment_factor: newAdjustmentFactor || sourceMarket.adjustment_factor,
        max_participations_per_user: newMaxParticipations || sourceMarket.max_participations_per_user,
        market_risk_level: newRiskLevel || sourceMarket.market_risk_level,
        metadata: newMetadata,
        updated_at: new Date().toISOString(),
        region: sourceMarket.region,
        // Add these required fields from the source market
        market_type: sourceMarket.market_type,
        current_value: 0
      });
    
    if (insertError) {
      logger.error('Error inserting cloned market:', insertError);
      return { success: false, error: 'Failed to create cloned market' };
    }
    
    // Add the creator as a participant
    const { error: participantError } = await supabase
      .from('market_participants')
      .insert({
        market_id: newMarketId,
        user_id: userId,
        role: 'owner',
        joined_at: new Date().toISOString()
      });
    
    if (participantError) {
      logger.error('Error adding participant to cloned market:', participantError);
      // Don't fail the operation, just log the error
    }
    
    logger.info('Market cloned successfully:', {
      sourceMarketId,
      newMarketId,
      userId
    });
    
    return {
      success: true,
      marketId: newMarketId
    };
  } catch (error) {
    logger.error('Unexpected error cloning market:', error);
    return {
      success: false,
      error: 'An unexpected error occurred while cloning the market'
    };
  }
}
