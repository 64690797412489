
import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";

interface MonthlyData {
  month: string;
  totalDeposits: number;
  memberShare: number;
  companyShare: number;
}

interface YearlyTotals {
  totalDeposits: number;
  memberShare: number;
  companyShare: number;
  averageROI: number;
}

interface TargetSettings {
  baseTarget: number;
  adjustmentFactor: number;
}

interface YearlyData {
  monthlyData: MonthlyData[];
  yearlyTotals: YearlyTotals;
}

export function useAdminFinancials() {
  const [monthlyData, setMonthlyData] = useState<MonthlyData[]>([]);
  const [yearlyTotals, setYearlyTotals] = useState<YearlyTotals>({
    totalDeposits: 0,
    memberShare: 0,
    companyShare: 0,
    averageROI: 0
  });
  const [targetSettings, setTargetSettings] = useState<TargetSettings>({
    baseTarget: 0,
    adjustmentFactor: 1.07
  });
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(true);
  const [previousYearData, setPreviousYearData] = useState<YearlyData | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    fetchFinancialData();
    fetchTargetSettings();
  }, [selectedYear]);

  const fetchFinancialData = async () => {
    try {
      setIsLoading(true);
      
      // Fetch current year data
      await fetchYearData(selectedYear);
      
      // Fetch previous year data for comparison
      await fetchYearData(selectedYear - 1, true);
      
    } catch (error) {
      console.error('Error fetching financial data:', error);
      toast({
        title: "Error",
        description: "Failed to load financial data",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchYearData = async (year: number, isPreviousYear: boolean = false) => {
    try {
      // Fetch monthly summary data
      const { data: summariesData, error: summariesError } = await supabase
        .from('monthly_financial_summaries')
        .select('month, year, total_deposits, member_share_amount, company_share_amount')
        .eq('year', year)
        .order('month', { ascending: true });
      
      if (summariesError) throw summariesError;
      
      // Process monthly data
      const processed = (summariesData || []).map(item => ({
        month: new Date(year, item.month - 1, 1).toLocaleString('default', { month: 'short' }),
        totalDeposits: item.total_deposits || 0,
        memberShare: item.member_share_amount || 0,
        companyShare: item.company_share_amount || 0
      }));
      
      // Calculate yearly totals
      const deposits = processed.reduce((sum, item) => sum + item.totalDeposits, 0);
      const memberShares = processed.reduce((sum, item) => sum + item.memberShare, 0);
      const companyShares = processed.reduce((sum, item) => sum + item.companyShare, 0);
      
      // Calculate ROI (company share as percentage of total deposits)
      const roi = deposits > 0 ? (companyShares / deposits) * 100 : 0;
      
      const yearData: YearlyData = {
        monthlyData: processed,
        yearlyTotals: {
          totalDeposits: deposits,
          memberShare: memberShares,
          companyShare: companyShares,
          averageROI: roi
        }
      };
      
      if (isPreviousYear) {
        setPreviousYearData(yearData);
      } else {
        setMonthlyData(processed);
        setYearlyTotals(yearData.yearlyTotals);
      }
    } catch (error) {
      console.error(`Error fetching ${year} financial data:`, error);
      // Just log this error but don't show toast for previous year
      if (!isPreviousYear) {
        toast({
          title: "Error",
          description: "Failed to load financial data for " + year,
          variant: "destructive",
        });
      }
    }
  };
  
  const fetchTargetSettings = async () => {
    try {
      // Using RPC function to fetch settings
      const { data, error } = await supabase
        .rpc('get_financial_settings');
      
      if (error) {
        console.error('Error in get_financial_settings RPC:', error);
        throw error;
      }
      
      // Update target settings if data exists
      if (data && Array.isArray(data) && data.length > 0) {
        setTargetSettings({
          baseTarget: data[0].base_target || 0,
          adjustmentFactor: data[0].adjustment_factor || 1.07
        });
      }
    } catch (error) {
      console.error('Error fetching target settings:', error);
      toast({
        title: "Error",
        description: "Failed to load target configuration settings",
        variant: "destructive",
      });
    }
  };
  
  const updateTargetSettings = async (settings: TargetSettings): Promise<boolean> => {
    try {
      // Use RPC function for update
      const { data, error } = await supabase
        .rpc('set_financial_settings', {
          p_base_target: settings.baseTarget,
          p_adjustment_factor: settings.adjustmentFactor
        });
      
      if (error) throw error;
      
      // Update local state
      setTargetSettings(settings);
      
      toast({
        title: "Success",
        description: "Financial target settings have been updated",
      });
      
      return true;
    } catch (error) {
      console.error('Error updating target settings:', error);
      toast({
        title: "Error",
        description: "Failed to update target settings",
        variant: "destructive",
      });
      return false;
    }
  };

  const changeYear = (year: number) => {
    setSelectedYear(year);
  };
  
  const exportFinancialData = () => {
    // Create CSV content
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Month,Total Deposits,Member Share,Company Share\n";
    
    monthlyData.forEach(item => {
      csvContent += `${item.month},${item.totalDeposits},${item.memberShare},${item.companyShare}\n`;
    });
    
    // Add yearly totals
    csvContent += `\nYearly Totals,${yearlyTotals.totalDeposits},${yearlyTotals.memberShare},${yearlyTotals.companyShare}\n`;
    csvContent += `Average ROI,${yearlyTotals.averageROI.toFixed(2)}%\n`;
    
    // Create download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `financial_summary_${selectedYear}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    toast({
      title: "Export Successful",
      description: `Financial data for ${selectedYear} has been exported`,
    });
  };

  return {
    monthlyData,
    yearlyTotals,
    targetSettings,
    selectedYear,
    isLoading,
    previousYearData,
    changeYear,
    refreshData: fetchFinancialData,
    updateTargetSettings,
    exportFinancialData
  };
}
