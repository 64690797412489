
/**
 * Returns a consistent query key for markets
 * This ensures we're using the same key everywhere
 */
export const getMarketsQueryKey = (userId?: string) => {
  if (userId) {
    return ['markets', userId];
  }
  return ['markets'];
};
