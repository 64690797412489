
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { UseFormReturn } from "react-hook-form";
import * as z from "zod";
import { marketFormSchema } from "../config/formConfig";

interface BasicInfoFieldsProps {
  form: UseFormReturn<z.infer<typeof marketFormSchema>>;
  fields: string[];
}

export function BasicInfoFields({ form, fields }: BasicInfoFieldsProps) {
  const regionOptions = [
    { value: "RSA", label: "South Africa" },
    { value: "USA", label: "USA" },
    { value: "UK", label: "UK" },
    { value: "Australia", label: "Australia" },
    { value: "NZ", label: "New Zealand" }
  ];

  return (
    <>
      {fields.includes("name") && (
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Market Name</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter market name" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      
      {fields.includes("region") && (
        <FormField
          control={form.control}
          name="region"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Region</FormLabel>
              <Select 
                onValueChange={field.onChange} 
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select region" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {regionOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </>
  );
}
