
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { Market } from "@/types/market";
import { ParticipationDialog } from "./ParticipationDialog";
import { logger } from "@/utils/logger";

interface ParticipateButtonProps {
  market: Market; // Changed from marketId to market
  isDisabled?: boolean;
  markets?: Market[] | null;
  onSuccess?: () => void;
  onParticipate?: (marketId: string, customSettings?: { 
    targetEarnings?: number, 
    closeTime?: string 
  }) => Promise<void>;
  variant?: "default" | "outline" | "secondary";
  size?: "default" | "sm" | "lg";
  className?: string;
}

export function ParticipateButton({
  market, // Changed from marketId to market
  isDisabled = false,
  markets,
  onSuccess,
  onParticipate,
  variant = "default",
  size = "default",
  className = ""
}: ParticipateButtonProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // No need to find the current market from the list since we're passing it directly
  if (!market) {
    logger.error('Market not provided');
    return null;
  }
  
  const handleOpenDialog = () => {
    if (!isDisabled) {
      logger.info('Opening participation dialog for market:', {
        marketId: market.id,
        marketName: market.name
      });
      setIsDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleParticipate = async (customSettings?: { 
    targetEarnings?: number, 
    closeTime?: string 
  }) => {
    setIsSubmitting(true);
    try {
      if (onParticipate) {
        await onParticipate(market.id, customSettings);
      }
      
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      logger.error('Error in handle participate:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        variant={variant}
        size={size}
        className={className}
        disabled={isDisabled || isSubmitting}
        onClick={handleOpenDialog}
      >
        {isSubmitting ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Processing...
          </>
        ) : "Participate"}
      </Button>
      
      <ParticipationDialog
        market={market}
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onParticipate={handleParticipate}
        isSubmitting={isSubmitting}
      />
    </>
  );
}
