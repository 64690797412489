
import { useNavigate, useLocation } from "react-router-dom";
import { cn } from "@/lib/utils";
import { Sidebar, SidebarProvider } from "@/components/ui/sidebar";
import { Menu, ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/contexts/AuthContext";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { useMediaQuery } from "@/hooks/use-media-query";
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { SidebarContents } from "./dashboard/SidebarContents";
import { adminNavItems, memberNavItems } from "./dashboard/navigation-items";

interface DashboardSidebarProps {
  children: React.ReactNode;
  isAdmin?: boolean;
}

export function DashboardSidebar({ children, isAdmin = false }: DashboardSidebarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut, session, isSigningOut } = useAuth();
  const { toast } = useToast();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(() => {
    if (isMobile) return false;
    const stored = localStorage.getItem("sidebarCollapsed");
    return stored ? JSON.parse(stored) : false;
  });

  // Check authentication status
  useEffect(() => {
    if (!session && location.pathname !== '/signin') {
      navigate('/signin');
    }
  }, [session, navigate, location.pathname]);

  // Persist collapse state only for desktop
  useEffect(() => {
    if (!isMobile) {
      localStorage.setItem("sidebarCollapsed", JSON.stringify(isCollapsed));
    }
  }, [isCollapsed, isMobile]);

  // Reset collapse state when switching between mobile and desktop
  useEffect(() => {
    if (isMobile) {
      setIsCollapsed(false);
    } else {
      const stored = localStorage.getItem("sidebarCollapsed");
      setIsCollapsed(stored ? JSON.parse(stored) : false);
    }
  }, [isMobile]);

  // Close mobile sidebar on route change
  useEffect(() => {
    setMobileOpen(false);
  }, [location.pathname]);

  const handleSignOut = async () => {
    if (isSigningOut) return;

    try {
      toast({
        title: "Signing out...",
        description: "Please wait while we sign you out.",
      });
      
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
      toast({
        title: "Error signing out",
        description: "Failed to sign out. Please try again.",
        variant: "destructive",
      });
    }
  };

  const navItems = isAdmin ? adminNavItems : memberNavItems;

  const sidebarContent = (
    <SidebarContents
      isAdmin={isAdmin}
      isCollapsed={isCollapsed}
      isMobile={isMobile}
      navItems={navItems}
      onCollapse={() => setIsCollapsed(!isCollapsed)}
      onSignOut={handleSignOut}
      onNavigate={() => setMobileOpen(false)}
    />
  );

  const mainContent = (
    <main className={cn(
      "flex-1 w-full h-full transition-all duration-300",
      isMobile ? "pt-16 px-4" : "p-6",
      !isMobile && (isCollapsed ? "ml-16" : "ml-64")
    )}>
      {children}
    </main>
  );

  return (
    <SidebarProvider>
      <div className="min-h-screen flex w-full">
        {isMobile ? (
          <>
            <Sheet open={mobileOpen} onOpenChange={setMobileOpen}>
              <SheetTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  className="md:hidden fixed top-4 left-4 z-50 bg-background/80 backdrop-blur-sm border border-border/10 shadow-lg"
                  aria-label="Open menu"
                >
                  <Menu className="h-5 w-5 text-zinc-700 dark:text-zinc-300" />
                </Button>
              </SheetTrigger>
              <SheetContent 
                side="left" 
                className="p-0 w-[280px] bg-card/95 backdrop-blur-sm border-r border-border/10 shadow-xl"
              >
                <SheetHeader className="p-4 text-left border-b border-border/10">
                  <SheetTitle className="flex items-center gap-2 text-primary">
                    <ArrowLeft className="h-4 w-4" />
                    {isAdmin ? "Admin Portal" : "Member Portal"}
                  </SheetTitle>
                </SheetHeader>
                {sidebarContent}
              </SheetContent>
            </Sheet>
            {mainContent}
          </>
        ) : (
          <>
            <Sidebar 
              className={cn(
                "group transition-all duration-300 ease-in-out",
                isCollapsed ? "w-16" : "w-64",
                "bg-card/95 backdrop-blur-sm",
                "border-r border-border/10",
                "fixed left-0 top-0 h-full z-30", 
                "pt-14", 
                "shadow-lg shadow-black/10 dark:shadow-black/30" // Added shadow here
              )}
            >
              {sidebarContent}
            </Sidebar>
            {mainContent}
          </>
        )}
      </div>
    </SidebarProvider>
  );
}
