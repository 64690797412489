
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface MaxDuplicationsInputProps {
  value: number;
  onChange: (value: number) => void;
}

export const MaxDuplicationsInput = ({ value, onChange }: MaxDuplicationsInputProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="maxDuplications">Maximum Duplications</Label>
      <Input
        id="maxDuplications"
        type="number"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value))}
        placeholder="Enter max duplications"
        min="0"
        step="1"
      />
    </div>
  );
};
