
import React from 'react';
import { AdminFinancialSummary } from "@/components/admin/financials/AdminFinancialSummary";
import { FinancialOverviewStats } from "@/components/admin/financials/FinancialOverviewStats";
import { AdminQuickAccessCard } from "@/components/admin/dashboard/AdminQuickAccessCard";
import { Users, TrendingUp, DollarSign, FileText, Bell, Database } from "lucide-react";

interface OverviewTabProps {
  currentYear: number;
  currentMonth: number;
  loading: boolean;
  financialData: any;
}

export const OverviewTab: React.FC<OverviewTabProps> = ({
  currentYear,
  currentMonth,
  loading,
  financialData
}) => {
  return (
    <div className="space-y-6">
      <FinancialOverviewStats 
        selectedYear={currentYear} 
        selectedMonth={currentMonth} 
      />
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <AdminQuickAccessCard
          icon={Users}
          title="Member Management"
          description="View and manage member financial records and permissions"
          path="/admin/users"
        />
        <AdminQuickAccessCard
          icon={TrendingUp}
          title="Market Operations"
          description="Configure and monitor market activities"
          path="/admin/markets"
        />
        <AdminQuickAccessCard
          icon={DollarSign}
          title="Financial Management"
          description="Track financial metrics and manage commission structures"
          path="/admin/financial"
        />
        <AdminQuickAccessCard
          icon={FileText}
          title="Reports & Analytics"
          description="Generate financial and performance reports"
          path="/admin/reports"
        />
        <AdminQuickAccessCard
          icon={Bell}
          title="Notifications"
          description="Send and manage system notifications"
          path="/admin/messaging"
        />
        <AdminQuickAccessCard
          icon={Database}
          title="System Settings"
          description="Configure global system parameters and settings"
          path="#configuration"
          isTab
        />
      </div>
      
      <AdminFinancialSummary 
        financialData={financialData}
        isLoading={loading}
      />
    </div>
  );
};
