import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { createFinancialTransaction } from "@/utils/market/transaction-handler";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Upload, AlertCircle, Info } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
export const DepositForm = () => {
  const [amount, setAmount] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [proofOfPayment, setProofOfPayment] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const {
    toast
  } = useToast();
  const {
    session
  } = useAuth();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    // Basic validation
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount) || numericAmount <= 0) {
      setError("Please enter a valid deposit amount");
      return;
    }
    if (!reference) {
      setError("Please enter a payment reference");
      return;
    }
    if (!proofOfPayment) {
      setError("Please upload proof of payment");
      return;
    }
    setIsSubmitting(true);
    setError(null);
    try {
      // Upload proof of payment
      let proofOfPaymentUrl = "";
      if (proofOfPayment) {
        const fileExt = proofOfPayment.name.split('.').pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `${session?.user.id}/proof-of-payment/${fileName}`;
        const {
          data: uploadData,
          error: uploadError
        } = await supabase.storage.from('deposits').upload(filePath, proofOfPayment, {
          cacheControl: '3600',
          upsert: false
        });
        if (uploadError) {
          throw new Error(`Error uploading file: ${uploadError.message}`);
        }

        // Get the public URL
        const {
          data: publicUrl
        } = supabase.storage.from('deposits').getPublicUrl(filePath);
        proofOfPaymentUrl = publicUrl.publicUrl;
      }

      // Create deposit transaction
      const {
        success,
        error: transactionError
      } = await createFinancialTransaction(session?.user.id || '', numericAmount, 'deposit', description || `Deposit - ${reference}`, reference, proofOfPaymentUrl);
      if (!success) {
        throw transactionError;
      }

      // Success message
      toast({
        title: "Deposit Submitted",
        description: "Your deposit has been submitted for approval"
      });

      // Reset form
      setAmount("");
      setReference("");
      setDescription("");
      setProofOfPayment(null);
      setUploadProgress(0);
    } catch (error) {
      setError(error instanceof Error ? error.message : "Failed to submit deposit");
      toast({
        title: "Error",
        description: "Failed to submit deposit. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Check file size (5MB max)
      if (file.size > 5 * 1024 * 1024) {
        setError("File size exceeds 5MB limit");
        return;
      }

      // Check file type (only images and PDFs)
      if (!file.type.match('image.*') && file.type !== 'application/pdf') {
        setError("Only image or PDF files are allowed");
        return;
      }
      setProofOfPayment(file);
      setError(null);
    }
  };
  return <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle>Deposit Funds</CardTitle>
        <CardDescription>
          Make a deposit to participate in markets
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Alert className="mb-4 border-blue-200 bg-zinc-50">
          <Info className="h-4 w-4 text-blue-500" />
          <AlertDescription className="text-sm text-black">
            <strong>Bank Details:</strong><br />
            Bank Name: First National Bank (FNB)<br />
            Account Name: Unisa Student Deposits<br />
            Account Number: 627 9963 0382<br />
            Account Type: Cheque<br />
            Branch Code: 210554
          </AlertDescription>
        </Alert>
        
        {error && <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>}
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="amount">Deposit Amount (R)</Label>
            <Input id="amount" type="number" step="0.01" min="0.01" value={amount} onChange={e => setAmount(e.target.value)} placeholder="Enter deposit amount" required disabled={isSubmitting} className="text-lg" />
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="reference">Payment Reference</Label>
            <Input id="reference" value={reference} onChange={e => setReference(e.target.value)} placeholder="Enter your payment reference" required disabled={isSubmitting} />
            <p className="text-xs text-muted-foreground">
              Use the reference you included when making the bank transfer
            </p>
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="description">Description (Optional)</Label>
            <Textarea id="description" value={description} onChange={e => setDescription(e.target.value)} placeholder="Additional information about this deposit" disabled={isSubmitting} rows={3} />
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="proofOfPayment">Proof of Payment</Label>
            <div className="border-2 border-dashed border-gray-300 rounded-md px-6 pt-5 pb-6">
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label htmlFor="proofOfPayment" className="relative cursor-pointer rounded-md bg-white font-medium text-primary hover:text-primary-dark focus-within:outline-none focus-within:ring-2 focus-within:ring-primary">
                    <span>Upload a file</span>
                    <Input id="proofOfPayment" type="file" className="sr-only" onChange={handleFileChange} accept="image/*,application/pdf" disabled={isSubmitting} />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF, or PDF up to 5MB
                </p>
              </div>
              {proofOfPayment && <div className="mt-2 flex items-center text-sm text-green-600">
                  <span>File selected: {proofOfPayment.name}</span>
                </div>}
            </div>
          </div>
        </form>
      </CardContent>
      <CardFooter>
        <Button onClick={handleSubmit} className="w-full" disabled={isSubmitting || !amount || !reference || !proofOfPayment}>
          {isSubmitting ? "Processing..." : "Submit Deposit"}
        </Button>
      </CardFooter>
    </Card>;
};