import React, { useState, useEffect } from "react";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { Edit2, Check, X, ArrowUp, ArrowDown, UserX, UserCheck } from "lucide-react";
import { Button } from "@/components/ui/button";
import { FullscreenButton } from "./FullscreenButton";
import { toast } from "sonner";
import { calculateAdjustedTarget } from "@/utils/marketCalculations";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { updateDashboardTargets } from "@/utils/market/target-sync";

interface MemberData {
  id: string;
  fullName: string;
  status: {
    status: string;
    lastActive: string;
  };
  timeActive: string;
  memberSharePercentage: number;
  companySharePercentage: number;
  targetMarketAus: number;
  targetMarketRsa: number;
  targetMarketUk: number;
  targetMarketUsa: number;
  marketStatus: {
    totalYtd: number;
    previousYtd: number;
    currentMonth: number;
  };
  memberStatus: {
    totalYtd: number;
    previousYtd: number;
    currentMonth: number;
  };
  roiStatus: {
    totalYtd: number;
    previousYtd: number;
    currentMonth: number;
  };
  marketCalculator?: {
    aus?: {
      baseTarget: number;
      adjustment: number;
      factor: number;
      adjustedTarget: number;
    };
    rsa?: {
      baseTarget: number;
      adjustment: number;
      factor: number;
      adjustedTarget: number;
    };
    uk?: {
      baseTarget: number;
      adjustment: number;
      factor: number;
      adjustedTarget: number;
    };
    usa?: {
      baseTarget: number;
      adjustment: number;
      factor: number;
      adjustedTarget: number;
    };
  };
}

interface MarketSettings {
  baseTarget: number;
  adjustment: number;
  factor: number;
  adjustedTarget: number;
}

const DEFAULT_MARKET_SETTINGS: MarketSettings = {
  baseTarget: 5000,
  adjustment: 500,
  factor: 1.07,
  adjustedTarget: 5467.29
};

interface OverviewDashboardTableProps {
  data: MemberData[];
  isLoading: boolean;
  onUpdateMemberShare: (userId: string, newSharePercentage: number) => Promise<void>;
  onUpdateMemberStatus: (userId: string, newStatus: 'online' | 'offline' | 'suspended') => Promise<void>;
  onSortColumn?: (key: string) => void;
  sortConfig?: { key: string; direction: 'asc' | 'desc' };
}

export const OverviewDashboardTable = ({
  data = [],
  isLoading,
  onUpdateMemberShare,
  onUpdateMemberStatus,
  onSortColumn,
  sortConfig = { key: '', direction: 'asc' }
}: OverviewDashboardTableProps) => {
  const [editingMember, setEditingMember] = useState<{id: string, field: string, value: any} | null>(null);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [processedData, setProcessedData] = useState<MemberData[]>([]);
  const [localEdits, setLocalEdits] = useState<Record<string, any>>({});
  const [pendingUpdates, setPendingUpdates] = useState<boolean>(false);

  const getMarketCalculatorForRegion = (marketCalculator: MemberData['marketCalculator'] | undefined, region: string): MarketSettings => {
    if (!marketCalculator) return DEFAULT_MARKET_SETTINGS;
    
    const regionKey = region.toLowerCase() as keyof typeof marketCalculator;
    const regionSettings = marketCalculator[regionKey];
    
    if (!regionSettings) return DEFAULT_MARKET_SETTINGS;
    
    return {
      baseTarget: regionSettings.baseTarget || DEFAULT_MARKET_SETTINGS.baseTarget,
      adjustment: regionSettings.adjustment || DEFAULT_MARKET_SETTINGS.adjustment,
      factor: regionSettings.factor || DEFAULT_MARKET_SETTINGS.factor,
      adjustedTarget: regionSettings.adjustedTarget || DEFAULT_MARKET_SETTINGS.adjustedTarget
    };
  };

  useEffect(() => {
    return () => {
      if (Object.keys(localEdits).length > 0) {
        syncLocalEditsToDatabase();
      }
    };
  }, [localEdits]);

  const syncLocalEditsToDatabase = async () => {
    if (Object.keys(localEdits).length === 0) return;
    
    logger.info("Syncing local edits to database:", localEdits);
    setPendingUpdates(true);
    
    try {
      const updates = Object.entries(localEdits).map(([userId, fields]) => {
        const updateData = {
          id: userId,
          targets: {}
        };
        
        if (fields.targetMarketAus) {
          updateData.targets['aus'] = { adjustedTarget: fields.targetMarketAus };
        }
        
        if (fields.targetMarketRsa) {
          updateData.targets['rsa'] = { adjustedTarget: fields.targetMarketRsa };
        }
        
        if (fields.targetMarketUk) {
          updateData.targets['uk'] = { adjustedTarget: fields.targetMarketUk };
        }
        
        if (fields.targetMarketUsa) {
          updateData.targets['usa'] = { adjustedTarget: fields.targetMarketUsa };
        }
        
        return updateData;
      });
      
      await updateDashboardTargets(updates);
      
      for (const [userId, fields] of Object.entries(localEdits)) {
        localStorage.setItem(`member_targets_${userId}`, JSON.stringify(fields));
      }
      
      logger.info("Successfully synced local edits to database");
    } catch (error) {
      logger.error("Error syncing local edits to database:", error);
      toast.error("Failed to save some member target values");
    } finally {
      setPendingUpdates(false);
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const synced = data.map(member => {
        const storedValues = localStorage.getItem(`member_targets_${member.id}`);
        const storedTargets = storedValues ? JSON.parse(storedValues) : null;
        
        const marketCalculator = member.marketCalculator || {
          aus: { baseTarget: member.targetMarketAus, adjustment: 500, factor: 1.07, adjustedTarget: member.targetMarketAus },
          rsa: { baseTarget: member.targetMarketRsa, adjustment: 500, factor: 1.07, adjustedTarget: member.targetMarketRsa },
          uk: { baseTarget: member.targetMarketUk, adjustment: 500, factor: 1.07, adjustedTarget: member.targetMarketUk },
          usa: { baseTarget: member.targetMarketUsa, adjustment: 500, factor: 1.07, adjustedTarget: member.targetMarketUsa }
        };
        
        const ausSettings = getMarketCalculatorForRegion(marketCalculator, 'aus');
        if (!ausSettings.adjustedTarget) {
          ausSettings.adjustedTarget = calculateAdjustedTarget(
            ausSettings.baseTarget,
            ausSettings.adjustment,
            ausSettings.factor
          );
        }
        
        const rsaSettings = getMarketCalculatorForRegion(marketCalculator, 'rsa');
        if (!rsaSettings.adjustedTarget) {
          rsaSettings.adjustedTarget = calculateAdjustedTarget(
            rsaSettings.baseTarget,
            rsaSettings.adjustment,
            rsaSettings.factor
          );
        }
        
        const ukSettings = getMarketCalculatorForRegion(marketCalculator, 'uk');
        if (!ukSettings.adjustedTarget) {
          ukSettings.adjustedTarget = calculateAdjustedTarget(
            ukSettings.baseTarget,
            ukSettings.adjustment,
            ukSettings.factor
          );
        }
        
        const usaSettings = getMarketCalculatorForRegion(marketCalculator, 'usa');
        if (!usaSettings.adjustedTarget) {
          usaSettings.adjustedTarget = calculateAdjustedTarget(
            usaSettings.baseTarget,
            usaSettings.adjustment,
            usaSettings.factor
          );
        }
        
        if (localEdits[member.id]) {
          const edits = localEdits[member.id];
          
          if (edits.targetMarketAus) ausSettings.adjustedTarget = edits.targetMarketAus;
          if (edits.targetMarketRsa) rsaSettings.adjustedTarget = edits.targetMarketRsa;
          if (edits.targetMarketUk) ukSettings.adjustedTarget = edits.targetMarketUk;
          if (edits.targetMarketUsa) usaSettings.adjustedTarget = edits.targetMarketUsa;
        }
        
        if (storedTargets) {
          if (storedTargets.targetMarketAus && !localEdits[member.id]?.targetMarketAus) {
            ausSettings.adjustedTarget = storedTargets.targetMarketAus;
          }
          if (storedTargets.targetMarketRsa && !localEdits[member.id]?.targetMarketRsa) {
            rsaSettings.adjustedTarget = storedTargets.targetMarketRsa;
          }
          if (storedTargets.targetMarketUk && !localEdits[member.id]?.targetMarketUk) {
            ukSettings.adjustedTarget = storedTargets.targetMarketUk;
          }
          if (storedTargets.targetMarketUsa && !localEdits[member.id]?.targetMarketUsa) {
            usaSettings.adjustedTarget = storedTargets.targetMarketUsa;
          }
        }
        
        return {
          ...member,
          marketCalculator: {
            aus: ausSettings,
            rsa: rsaSettings,
            uk: ukSettings,
            usa: usaSettings
          },
          targetMarketAus: ausSettings.adjustedTarget,
          targetMarketRsa: rsaSettings.adjustedTarget,
          targetMarketUk: ukSettings.adjustedTarget,
          targetMarketUsa: usaSettings.adjustedTarget
        };
      });
      
      setProcessedData(synced);
      logger.info("Processed initial data for OverviewDashboardTable with persisted values");
    } else {
      setProcessedData(data);
    }
  }, [data]);
  
  useEffect(() => {
    const handleDashboardTargetsUpdate = (event: CustomEvent) => {
      const { updates } = event.detail || {};
      
      if (updates && Array.isArray(updates)) {
        logger.info("Received dashboard-targets-bulk-update event", updates.length);
        
        setProcessedData(prevData => {
          return prevData.map(member => {
            const update = updates.find(u => u.userId === member.id);
            if (update) {
              logger.info(`Updating member ${member.id} targets`);
              
              const storedValues = localStorage.getItem(`member_targets_${member.id}`);
              const storedTargets = storedValues ? JSON.parse(storedValues) : {};
              const newStoredTargets = {
                ...storedTargets,
                targetMarketAus: update.targets.aus,
                targetMarketRsa: update.targets.rsa,
                targetMarketUk: update.targets.uk,
                targetMarketUsa: update.targets.usa
              };
              localStorage.setItem(`member_targets_${member.id}`, JSON.stringify(newStoredTargets));
              
              return {
                ...member,
                targetMarketAus: update.targets.aus,
                targetMarketRsa: update.targets.rsa,
                targetMarketUk: update.targets.uk,
                targetMarketUsa: update.targets.usa,
                marketCalculator: {
                  ...member.marketCalculator,
                  aus: {
                    ...member.marketCalculator?.aus,
                    adjustedTarget: update.targets.aus
                  },
                  rsa: {
                    ...member.marketCalculator?.rsa,
                    adjustedTarget: update.targets.rsa
                  },
                  uk: {
                    ...member.marketCalculator?.uk,
                    adjustedTarget: update.targets.uk
                  },
                  usa: {
                    ...member.marketCalculator?.usa,
                    adjustedTarget: update.targets.usa
                  }
                }
              };
            }
            return member;
          });
        });
        
        const updatedUserIds = updates.map(u => u.userId);
        setLocalEdits(prev => {
          const newEdits = { ...prev };
          
          for (const userId of updatedUserIds) {
            if (newEdits[userId]) {
              delete newEdits[userId];
            }
          }
          
          return newEdits;
        });
        
        toast.success("Member target values updated successfully");
      }
    };
    
    const handleTargetDataUpdated = (event: CustomEvent) => {
      const { memberId, targets, forceRefresh } = event.detail || {};
      
      if (memberId && targets) {
        logger.info(`Target data updated for member: ${memberId}`, {
          memberId,
          targets
        });
        
        if (targets.aus || targets.rsa || targets.uk || targets.usa) {
          const storedValues = localStorage.getItem(`member_targets_${memberId}`);
          const storedTargets = storedValues ? JSON.parse(storedValues) : {};
          const newStoredTargets = {
            ...storedTargets,
            targetMarketAus: targets.aus || storedTargets.targetMarketAus,
            targetMarketRsa: targets.rsa || storedTargets.targetMarketRsa,
            targetMarketUk: targets.uk || storedTargets.targetMarketUk,
            targetMarketUsa: targets.usa || storedTargets.targetMarketUsa
          };
          localStorage.setItem(`member_targets_${memberId}`, JSON.stringify(newStoredTargets));
        }
        
        setProcessedData(prevData => {
          return prevData.map(member => {
            if (member.id === memberId) {
              return {
                ...member,
                targetMarketAus: targets.aus || member.targetMarketAus,
                targetMarketRsa: targets.rsa || member.targetMarketRsa,
                targetMarketUk: targets.uk || member.targetMarketUk,
                targetMarketUsa: targets.usa || member.targetMarketUsa
              };
            }
            return member;
          });
        });
        
        if (forceRefresh) {
          setLocalEdits(prev => {
            const newEdits = { ...prev };
            if (newEdits[memberId]) {
              delete newEdits[memberId];
            }
            return newEdits;
          });
        }
      }
    };
    
    const handleForceRefresh = (event: CustomEvent) => {
      const { forceRefresh } = event.detail || {};
      
      if (forceRefresh) {
        logger.info("Force refresh requested, but preserving local edits for continuity");
        
        syncLocalEditsToDatabase();
      }
    };
    
    window.addEventListener('dashboard-targets-bulk-update', handleDashboardTargetsUpdate as EventListener);
    window.addEventListener('target-data-updated', handleTargetDataUpdated as EventListener);
    window.addEventListener('market-targets-updated', handleTargetDataUpdated as EventListener);
    window.addEventListener('member-targets-synced', handleForceRefresh as EventListener);
    window.addEventListener('all-member-targets-refreshed', handleForceRefresh as EventListener);
    window.addEventListener('dashboard-targets-updated', handleForceRefresh as EventListener);
    
    return () => {
      window.removeEventListener('dashboard-targets-bulk-update', handleDashboardTargetsUpdate as EventListener);
      window.removeEventListener('target-data-updated', handleTargetDataUpdated as EventListener);
      window.removeEventListener('market-targets-updated', handleTargetDataUpdated as EventListener);
      window.removeEventListener('member-targets-synced', handleForceRefresh as EventListener);
      window.removeEventListener('all-member-targets-refreshed', handleForceRefresh as EventListener);
      window.removeEventListener('dashboard-targets-updated', handleForceRefresh as EventListener);
    };
  }, []);
  
  useEffect(() => {
    const handleDashboardUpdateRequired = (event: CustomEvent) => {
      const { forceUpdate } = event.detail || {};
      
      if (forceUpdate) {
        logger.info("Forced dashboard update required, refreshing target data while preserving local edits");
        
        syncLocalEditsToDatabase();
        
        const fetchTargetData = async () => {
          try {
            const memberIds = processedData.map(member => member.id);
            
            if (memberIds.length === 0) return;
            
            const { data: targetData, error } = await supabase
              .from('regional_targets')
              .select('user_id, region_code, target_value')
              .in('user_id', memberIds);
              
            if (error) {
              logger.error("Error fetching regional targets:", error);
              return;
            }
            
            if (targetData && targetData.length > 0) {
              const targetsByUser: Record<string, Record<string, number>> = {};
              
              targetData.forEach((target: any) => {
                if (!targetsByUser[target.user_id]) {
                  targetsByUser[target.user_id] = {};
                }
                
                const region = target.region_code.toLowerCase();
                targetsByUser[target.user_id][region] = target.target_value;
                
                const storedValues = localStorage.getItem(`member_targets_${target.user_id}`);
                const storedTargets = storedValues ? JSON.parse(storedValues) : {};
                
                if (region === 'aus') storedTargets.targetMarketAus = target.target_value;
                if (region === 'rsa') storedTargets.targetMarketRsa = target.target_value;
                if (region === 'uk') storedTargets.targetMarketUk = target.target_value;
                if (region === 'usa') storedTargets.targetMarketUsa = target.target_value;
                
                localStorage.setItem(`member_targets_${target.user_id}`, JSON.stringify(storedTargets));
              });
              
              setProcessedData(prevData => {
                return prevData.map(member => {
                  const userTargets = targetsByUser[member.id];
                  
                  if (userTargets) {
                    const memberLocalEdits = localEdits[member.id] || {};
                    
                    return {
                      ...member,
                      targetMarketAus: memberLocalEdits.targetMarketAus || userTargets.aus || member.targetMarketAus,
                      targetMarketRsa: memberLocalEdits.targetMarketRsa || userTargets.rsa || member.targetMarketRsa,
                      targetMarketUk: memberLocalEdits.targetMarketUk || userTargets.uk || member.targetMarketUk,
                      targetMarketUsa: memberLocalEdits.targetMarketUsa || userTargets.usa || member.targetMarketUsa
                    };
                  }
                  
                  return member;
                });
              });
              
              logger.info("Target data refreshed for all members while preserving local edits");
            }
          } catch (error) {
            logger.error("Error in fetchTargetData:", error);
          }
        };
        
        fetchTargetData();
      }
    };
    
    window.addEventListener('dashboard-update-required', handleDashboardUpdateRequired as EventListener);
    
    return () => {
      window.removeEventListener('dashboard-update-required', handleDashboardUpdateRequired as EventListener);
    };
  }, [processedData, localEdits]);
  
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2
    }).format(amount);
  };
  
  const handleEditClick = (memberId: string, field: string, currentValue: any) => {
    setEditingMember({ id: memberId, field, value: currentValue });
  };
  
  const handleSaveClick = async () => {
    if (!editingMember) return;
    
    try {
      if (editingMember.field === 'memberSharePercentage') {
        if (editingMember.value < 0 || editingMember.value > 100) {
          toast.error("Invalid percentage value", {
            description: "Member share must be between 0 and 100%"
          });
          return;
        }
        await onUpdateMemberShare(editingMember.id, editingMember.value);
      } else if (
        editingMember.field === 'targetMarketAus' || 
        editingMember.field === 'targetMarketRsa' || 
        editingMember.field === 'targetMarketUk' || 
        editingMember.field === 'targetMarketUsa'
      ) {
        const numericValue = Number(editingMember.value);
        
        setLocalEdits(prev => ({
          ...prev, 
          [editingMember.id]: {
            ...prev[editingMember.id],
            [editingMember.field]: numericValue
          }
        }));
        
        setProcessedData(prevData => {
          return prevData.map(member => {
            if (member.id === editingMember.id) {
              return {
                ...member,
                [editingMember.field]: numericValue
              };
            }
            return member;
          });
        });
        
        const storedValues = localStorage.getItem(`member_targets_${editingMember.id}`);
        const storedTargets = storedValues ? JSON.parse(storedValues) : {};
        storedTargets[editingMember.field] = numericValue;
        localStorage.setItem(`member_targets_${editingMember.id}`, JSON.stringify(storedTargets));
        
        const regionMap: Record<string, string> = {
          targetMarketAus: 'aus',
          targetMarketRsa: 'rsa',
          targetMarketUk: 'uk',
          targetMarketUsa: 'usa'
        };
        
        const region = regionMap[editingMember.field];
        const member = processedData.find(m => m.id === editingMember.id);
        
        if (member && region) {
          const marketCalculator = member.marketCalculator || {};
          
          const regionSettings = getMarketCalculatorForRegion(marketCalculator, region);
          
          const updateData = [{
            id: editingMember.id,
            targets: {
              [region]: {
                baseTarget: regionSettings.baseTarget,
                adjustment: regionSettings.adjustment,
                factor: regionSettings.factor,
                adjustedTarget: numericValue
              }
            }
          }];
          
          try {
            const success = await updateDashboardTargets(updateData);
            
            if (success) {
              toast.success(`Updated ${region.toUpperCase()} target value`);
              
              const { error } = await supabase
                .from('regional_targets')
                .upsert({
                  user_id: editingMember.id,
                  region_code: region.toUpperCase(),
                  target_value: numericValue,
                  updated_at: new Date().toISOString()
                }, {
                  onConflict: 'user_id,region_code'
                });
                
              if (error) {
                logger.error(`Error updating regional target:`, error);
              }
            } else {
              toast.error("Failed to update target value in database");
            }
          } catch (error) {
            logger.error("Error updating target value:", error);
            toast.error("Failed to update target value");
          }
        }
      }
      
      setEditingMember(null);
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Failed to save changes");
    }
  };
  
  const handleCancelClick = () => {
    setEditingMember(null);
  };
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!editingMember) return;
    
    const newValue = e.target.type === 'number' 
      ? parseFloat(e.target.value)
      : e.target.value;
      
    setEditingMember({ ...editingMember, value: newValue });
  };
  
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSaveClick();
    } else if (e.key === 'Escape') {
      handleCancelClick();
    }
  };
  
  const handleStatusChange = async (memberId: string, newStatus: 'online' | 'offline' | 'suspended') => {
    try {
      await onUpdateMemberStatus(memberId, newStatus);
      toast.success(`Member status updated to ${newStatus}`);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
    }
  };
  
  const renderSortIcon = (columnKey: string) => {
    if (sortConfig.key !== columnKey) return null;
    
    return sortConfig.direction === 'asc' 
      ? <ArrowUp className="h-4 w-4 inline-block ml-1 text-foreground" /> 
      : <ArrowDown className="h-4 w-4 inline-block ml-1 text-foreground" />;
  };
  
  const handleSort = (columnKey: string) => {
    if (onSortColumn) {
      onSortColumn(columnKey);
    }
  };
  
  const renderSortableHeader = (label: string, columnKey: string) => {
    return (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => handleSort(columnKey)}
      >
        <span>{label}</span>
        {renderSortIcon(columnKey)}
      </div>
    );
  };
  
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'online':
        return 'text-green-500';
      case 'suspended':
        return 'text-red-500';
      default:
        return 'text-yellow-500';
    }
  };
  
  const handleSyncTargets = async () => {
    await syncLocalEditsToDatabase();
    
    toast.info("Syncing with Market Calculator...");
    
    window.dispatchEvent(new CustomEvent('dashboard-update-required', {
      detail: {
        forceUpdate: true,
        timestamp: new Date().toISOString()
      }
    }));
  };
  
  return (
    <div className={`rounded-lg border bg-card shadow ${fullscreenMode ? 'fixed inset-0 z-50 p-6 overflow-auto' : ''}`}>
      <div className="flex justify-between items-center p-4">
        <h3 className="text-lg font-medium">Member Overview Dashboard</h3>
        <div className="flex items-center gap-2">
          <Button 
            variant="outline" 
            size="sm"
            className="text-xs"
            onClick={handleSyncTargets}
            disabled={pendingUpdates}
          >
            {pendingUpdates ? "Syncing..." : "Sync Target Data"}
          </Button>
          <FullscreenButton 
            isFullscreen={fullscreenMode} 
            onToggle={() => setFullscreenMode(prev => !prev)} 
          />
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[200px]">{renderSortableHeader('Member', 'fullName')}</TableHead>
              <TableHead>{renderSortableHeader('Status', 'status.status')}</TableHead>
              <TableHead>{renderSortableHeader('Time Active', 'timeActive')}</TableHead>
              <TableHead className="whitespace-nowrap">
                {renderSortableHeader('Member Share %', 'memberSharePercentage')}
              </TableHead>
              <TableHead className="whitespace-nowrap">
                {renderSortableHeader('Company ROI %', 'companySharePercentage')}
              </TableHead>
              <TableHead className="whitespace-nowrap text-right">
                {renderSortableHeader('Target AUS', 'targetMarketAus')}
              </TableHead>
              <TableHead className="whitespace-nowrap text-right">
                {renderSortableHeader('Target RSA', 'targetMarketRsa')}
              </TableHead>
              <TableHead className="whitespace-nowrap text-right">
                {renderSortableHeader('Target UK', 'targetMarketUk')}
              </TableHead>
              <TableHead className="whitespace-nowrap text-right">
                {renderSortableHeader('Target USA', 'targetMarketUsa')}
              </TableHead>
              <TableHead className="whitespace-nowrap text-right">
                {renderSortableHeader('YTD Total', 'marketStatus.totalYtd')}
              </TableHead>
              <TableHead className="whitespace-nowrap text-right">
                {renderSortableHeader('Member YTD', 'memberStatus.totalYtd')}
              </TableHead>
              <TableHead className="whitespace-nowrap text-right">
                {renderSortableHeader('ROI YTD', 'roiStatus.totalYtd')}
              </TableHead>
              <TableHead className="whitespace-nowrap text-right">
                Current Month
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={13}>
                    <Skeleton className="h-10 w-full" />
                  </TableCell>
                </TableRow>
              ))
            ) : processedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={13} className="text-center py-8 text-muted-foreground">
                  No member data found
                </TableCell>
              </TableRow>
            ) : (
              processedData.map((member) => (
                <TableRow key={member.id}>
                  <TableCell className="font-medium">{member.fullName}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <span className={`font-medium ${getStatusColor(member.status.status)}`}>
                        {member.status.status}
                      </span>
                      <div className="flex gap-1">
                        {member.status.status !== 'suspended' && (
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-6 w-6"
                            onClick={() => handleStatusChange(member.id, 'suspended')}
                            title="Suspend member"
                          >
                            <UserX className="h-3.5 w-3.5 text-destructive" />
                          </Button>
                        )}
                        {member.status.status === 'suspended' && (
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-6 w-6"
                            onClick={() => handleStatusChange(member.id, 'online')}
                            title="Activate member"
                          >
                            <UserCheck className="h-3.5 w-3.5 text-green-500" />
                          </Button>
                        )}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{member.timeActive}</TableCell>
                  <TableCell>
                    {editingMember?.id === member.id && editingMember?.field === 'memberSharePercentage' ? (
                      <div className="flex items-center gap-1">
                        <input
                          type="number"
                          value={editingMember.value}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          className="w-16 p-1 border rounded bg-background text-foreground dark:border-gray-600 focus:outline-none focus:ring-1 focus:ring-primary"
                          min="0"
                          max="100"
                          autoFocus
                        />
                        <Button variant="ghost" size="icon" className="h-6 w-6" onClick={handleSaveClick}>
                          <Check className="h-3.5 w-3.5 text-green-500" />
                        </Button>
                        <Button variant="ghost" size="icon" className="h-6 w-6" onClick={handleCancelClick}>
                          <X className="h-3.5 w-3.5 text-destructive" />
                        </Button>
                      </div>
                    ) : (
                      <div className="flex items-center gap-1">
                        <span>{member.memberSharePercentage}%</span>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-6 w-6 opacity-0 group-hover:opacity-100"
                          onClick={() => handleEditClick(member.id, 'memberSharePercentage', member.memberSharePercentage)}
                        >
                          <Edit2 className="h-3.5 w-3.5 text-foreground" />
                        </Button>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>{member.companySharePercentage}%</TableCell>
                  <TableCell className="text-right">
                    {formatCurrency(member.targetMarketAus)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatCurrency(member.targetMarketRsa)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatCurrency(member.targetMarketUk)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatCurrency(member.targetMarketUsa)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatCurrency(member.marketStatus.totalYtd)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatCurrency(member.memberStatus.totalYtd)}
                  </TableCell>
                  <TableCell className="text-right font-medium text-primary">
                    {formatCurrency(member.roiStatus.totalYtd)}
                  </TableCell>
                  <TableCell className="text-right">
                    {formatCurrency(member.marketStatus.currentMonth)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
