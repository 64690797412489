
import React from 'react';
import SignIn from '@/pages/SignIn';
import Register from '@/pages/Register';
import Index from '@/pages/Index';
import ApplyNow from '@/pages/ApplyNow';
import Benefits from '@/pages/Benefits';
import ExclusiveAccess from '@/pages/ExclusiveAccess';
import WaitingList from '@/pages/WaitingList';

interface RouteConfig {
  path: string;
  element: React.ReactNode;
}

export const publicRoutes: RouteConfig[] = [
  {
    path: '/',
    element: <Index />
  },
  {
    path: '/signin',
    element: <SignIn />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/apply',
    element: <ApplyNow />
  },
  {
    path: '/benefits',
    element: <Benefits />
  },
  {
    path: '/exclusive',
    element: <ExclusiveAccess />
  },
  {
    path: '/waiting-list',
    element: <WaitingList />
  }
];
