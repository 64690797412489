import { useState, useEffect } from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Search, MoreHorizontal, EyeIcon, Edit2, UserPlus, RefreshCw, LoaderIcon } from "lucide-react";
import { useMembersOverview } from "@/hooks/admin/use-members-overview";
import { format } from "date-fns";
import { logger } from "@/utils/logger";
import { findMatchingRegionCode, normalizeRegionCode, formatCurrencyForRegion } from "@/utils/market/formatters";
import { refreshMemberTargets } from "@/utils/market/target-sync";
import { toast } from "sonner";

export const MembersOverview = () => {
  const { 
    members, 
    isLoading, 
    error,
    refreshMembers,
    forceRefresh,
    lastUpdated
  } = useMembersOverview();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMembers, setFilteredMembers] = useState(members || []);
  const [refreshing, setRefreshing] = useState(false);
  const [hasPendingRefresh, setHasPendingRefresh] = useState(false);
  
  useEffect(() => {
    const savedQuery = localStorage.getItem('members_overview_search_query');
    if (savedQuery) {
      setSearchQuery(savedQuery);
    }
  }, []);
  
  useEffect(() => {
    localStorage.setItem('members_overview_search_query', searchQuery);
  }, [searchQuery]);
  
  useEffect(() => {
    if (members) {
      const filtered = members.filter(member => {
        const fullName = `${member.first_name || ''} ${member.last_name || ''}`.toLowerCase();
        const email = (member.email || '').toLowerCase();
        const query = searchQuery.toLowerCase();
        
        return fullName.includes(query) || email.includes(query);
      });
      
      setFilteredMembers(filtered);
      
      if (hasPendingRefresh) {
        setHasPendingRefresh(false);
        toast.success("Members data has been refreshed");
      }
    }
  }, [members, searchQuery, hasPendingRefresh]);
  
  useEffect(() => {
    const handleBackgroundRefresh = (event: Event) => {
      const customEvent = event as CustomEvent;
      logger.info('Background refresh requested for MembersOverview', customEvent.detail);
      
      refreshMembers();
    };
    
    window.addEventListener('admin-panel-background-refresh', handleBackgroundRefresh as EventListener);
    
    return () => {
      window.removeEventListener('admin-panel-background-refresh', handleBackgroundRefresh as EventListener);
    };
  }, [refreshMembers]);
  
  useEffect(() => {
    const handleTargetUpdates = (event: Event) => {
      const customEvent = event as CustomEvent;
      logger.info('Target update event received in MembersOverview', {
        type: event.type,
        detail: customEvent.detail
      });
      
      const { forceRefresh: shouldForceRefresh } = customEvent.detail || {};
      if (shouldForceRefresh) {
        forceRefresh();
        setHasPendingRefresh(true);
      }
    };
    
    const targetEvents = [
      'member-targets-synced',
      'all-member-targets-refreshed',
      'system-targets-updated',
      'system-targets-loaded',
      'regional-targets-updated',
      'dashboard-targets-updated'
    ];
    
    targetEvents.forEach(eventType => {
      window.addEventListener(eventType, handleTargetUpdates as EventListener);
    });
    
    return () => {
      targetEvents.forEach(eventType => {
        window.removeEventListener(eventType, handleTargetUpdates as EventListener);
      });
    };
  }, [forceRefresh]);
  
  const handleRefresh = async () => {
    logger.info('Manual refresh requested for members overview');
    setRefreshing(true);
    toast.info('Refreshing member data...');
    
    try {
      const syncSuccess = await refreshMemberTargets();
      
      if (syncSuccess) {
        logger.info('Successfully refreshed member targets from system');
      } else {
        logger.warn('Issue refreshing member targets from system');
      }
      
      await forceRefresh();
      
      localStorage.setItem('members_overview_last_refresh', new Date().toISOString());
      
      toast.success('Member data successfully refreshed');
    } catch (err) {
      logger.error('Error during manual refresh:', err);
      toast.error('Failed to refresh member data');
    } finally {
      setTimeout(() => {
        setRefreshing(false);
      }, 1000);
    }
  };
  
  const getStatusBadge = (status: string | null) => {
    switch (status) {
      case 'active':
        return <Badge className="bg-green-500">Active</Badge>;
      case 'suspended':
        return <Badge variant="destructive">Suspended</Badge>;
      case 'pending':
        return <Badge variant="outline" className="bg-yellow-100 text-yellow-800 border-yellow-200">Pending</Badge>;
      default:
        return <Badge variant="secondary">Unknown</Badge>;
    }
  };
  
  const getRegionalTarget = (member: any, region: string) => {
    if (!member.regional_targets) return 0;
    
    const regionKey = findMatchingRegionCode(region, member.regional_targets);
    
    if (regionKey) {
      return member.regional_targets[regionKey];
    }
    
    const normalizedRegion = normalizeRegionCode(region);
    return member.regional_targets[normalizedRegion] || 0;
  };
  
  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <div>
            <CardTitle>Member Accounts Overview</CardTitle>
            <CardDescription>
              Manage and monitor member accounts and their financial settings
            </CardDescription>
          </div>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={handleRefresh}
            disabled={isLoading || refreshing}
            className="flex items-center gap-2"
          >
            {isLoading || refreshing ? <LoaderIcon className="h-4 w-4 animate-spin" /> : <RefreshCw className="h-4 w-4" />}
            Refresh
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex justify-between items-center mb-4">
          <div className="relative w-full max-w-sm">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search members..."
              className="pl-8"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <Button size="sm" className="gap-2">
            <UserPlus className="h-4 w-4" />
            Add New Member
          </Button>
        </div>

        {isLoading || refreshing ? (
          <div className="flex justify-center items-center h-40">
            <LoaderIcon className="h-8 w-8 animate-spin text-primary" />
            <span className="ml-2 text-lg">Loading members...</span>
          </div>
        ) : error ? (
          <div className="p-6 text-center text-red-500">
            Error loading members: {error.message}
          </div>
        ) : filteredMembers.length === 0 ? (
          <div className="p-6 text-center text-muted-foreground">
            No members found.
          </div>
        ) : (
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Member</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Joined</TableHead>
                  <TableHead className="text-right">Balance</TableHead>
                  <TableHead className="text-right">AUS Target</TableHead>
                  <TableHead className="text-right">RSA Target</TableHead>
                  <TableHead className="text-right">UK Target</TableHead>
                  <TableHead className="text-right">USA Target</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredMembers.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell className="font-medium">
                      <div>
                        {member.first_name} {member.last_name}
                      </div>
                      <div className="text-sm text-muted-foreground">
                        {member.email}
                      </div>
                    </TableCell>
                    <TableCell>
                      {getStatusBadge(member.status)}
                    </TableCell>
                    <TableCell>
                      {member.created_at ? format(new Date(member.created_at), 'dd MMM yyyy') : 'Unknown'}
                    </TableCell>
                    <TableCell className="text-right">
                      {formatCurrencyForRegion(member.balance || 0, 'RSA')}
                    </TableCell>
                    <TableCell className="text-right">
                      {formatCurrencyForRegion(getRegionalTarget(member, 'AUS'), 'AUS')}
                    </TableCell>
                    <TableCell className="text-right">
                      {formatCurrencyForRegion(getRegionalTarget(member, 'RSA'), 'RSA')}
                    </TableCell>
                    <TableCell className="text-right">
                      {formatCurrencyForRegion(getRegionalTarget(member, 'UK'), 'UK')}
                    </TableCell>
                    <TableCell className="text-right">
                      {formatCurrencyForRegion(getRegionalTarget(member, 'USA'), 'USA')}
                    </TableCell>
                    <TableCell className="text-right">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Actions</DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>
                            <EyeIcon className="mr-2 h-4 w-4" />
                            View Details
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <Edit2 className="mr-2 h-4 w-4" />
                            Edit Member
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={() => refreshMemberTargets()}>
                            <RefreshCw className="mr-2 h-4 w-4" />
                            Refresh Targets
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
      <CardFooter className="flex justify-between text-sm text-muted-foreground">
        <div>
          {members?.length > 0 ? `Showing ${filteredMembers.length} of ${members.length} members` : 'No members found'}
        </div>
        <div>
          Last updated: {lastUpdated ? format(lastUpdated, 'dd MMM yyyy HH:mm:ss') : 'Never'}
        </div>
      </CardFooter>
    </Card>
  );
};
