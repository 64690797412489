
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";

interface UpdateResult {
  success: boolean;
  error?: any;
}

/**
 * Updates a specific field in a market's metadata
 */
export const updateMarketMetadataField = async (
  marketId: string,
  fieldName: string,
  fieldValue: any
): Promise<UpdateResult> => {
  try {
    // First get the current metadata
    const { data: market, error: getError } = await supabase
      .from('markets')
      .select('metadata')
      .eq('id', marketId)
      .single();
      
    if (getError) {
      logger.error('Error fetching market metadata:', {
        error: getError,
        marketId,
        fieldName
      });
      return { success: false, error: getError };
    }
    
    // Initialize or use existing metadata
    let metadata = market.metadata || {};
    
    // If metadata is a string, parse it
    if (typeof metadata === 'string') {
      try {
        metadata = JSON.parse(metadata);
      } catch (parseError) {
        logger.error('Error parsing market metadata:', {
          error: parseError,
          marketId,
          metadata
        });
        metadata = {};
      }
    }
    
    // Update the specific field
    metadata[fieldName] = fieldValue;
    
    // Update the metadata in the database
    const { error: updateError } = await supabase
      .from('markets')
      .update({
        metadata,
        updated_at: new Date().toISOString()
      })
      .eq('id', marketId);
      
    if (updateError) {
      logger.error('Error updating market metadata:', {
        error: updateError,
        marketId,
        fieldName,
        fieldValue
      });
      return { success: false, error: updateError };
    }
    
    logger.info('Market metadata field updated successfully:', {
      marketId,
      fieldName,
      timestamp: new Date().toISOString()
    });
    
    return { success: true };
  } catch (error) {
    logger.error('Exception in updateMarketMetadataField:', error);
    return { success: false, error };
  }
};

/**
 * Updates the contribution status for a market
 */
export const updateMarketContributionStatus = async (
  marketId: string,
  hasContributions: boolean
): Promise<UpdateResult> => {
  return updateMarketMetadataField(
    marketId,
    'has_received_contributions',
    hasContributions
  );
};

/**
 * Updates the market description in metadata
 */
export const updateMarketDescription = async (
  marketId: string,
  description: string
): Promise<UpdateResult> => {
  return updateMarketMetadataField(
    marketId,
    'description',
    description
  );
};

/**
 * Updates the market sequence position in metadata
 */
export const updateSequencePosition = async (
  marketId: string,
  position: number | null
): Promise<UpdateResult> => {
  return updateMarketMetadataField(
    marketId,
    'sequence_position',
    position
  );
};
