
import React from "react";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import * as z from "zod";
import { marketFormSchema } from "../config/formConfig";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";

interface TimeFieldsProps {
  form: UseFormReturn<z.infer<typeof marketFormSchema>>;
  fields: string[];
}

export const TimeFields = ({ form, fields }: TimeFieldsProps) => {
  return (
    <>
      {fields.includes("start_date") && (
        <FormField
          control={form.control}
          name="start_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Start Date</FormLabel>
              <FormControl>
                <Input
                  type="datetime-local"
                  {...field}
                  value={field.value && new Date(field.value).toISOString().slice(0, 16)}
                  onChange={(e) => {
                    const date = e.target.value ? new Date(e.target.value) : new Date();
                    field.onChange(date);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {fields.includes("close_time") && (
        <FormField
          control={form.control}
          name="close_time"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Close Time (Optional)</FormLabel>
              <FormControl>
                <Input
                  type="datetime-local"
                  {...field}
                  value={field.value ? new Date(field.value).toISOString().slice(0, 16) : ''}
                  onChange={(e) => {
                    const date = e.target.value ? new Date(e.target.value) : undefined;
                    field.onChange(date);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {fields.includes("countdown_enabled") && (
        <FormField
          control={form.control}
          name="countdown_enabled"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
              <div className="space-y-0.5">
                <FormLabel>Enable Countdown</FormLabel>
                <p className="text-sm text-muted-foreground">
                  Show countdown timer for market start and end
                </p>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
      )}
    </>
  );
};
