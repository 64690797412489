
import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { marketDataSynchronizer } from '@/utils/market/sync/market-data-synchronizer';
import { toast } from 'sonner';
import { logger } from '@/utils/logger';
import { addMarketSyncListeners, MARKET_SYNC_EVENTS } from '@/utils/market/sync/market-sync-events';

// Define the context type
interface MarketDataContextType {
  isSyncing: boolean;
  lastSyncTime: Date | null;
  triggerSync: (showToast?: boolean) => void;
  startAutoSync: (intervalMs?: number) => void;
  stopAutoSync: () => void;
  handleParticipate: (marketId: string, options?: { targetEarnings?: number; closeTime?: string }) => Promise<boolean>;
  isProcessingParticipation: boolean;
}

// Create the context with default values
const MarketDataContext = createContext<MarketDataContextType>({
  isSyncing: false,
  lastSyncTime: null,
  triggerSync: () => {},
  startAutoSync: () => {},
  stopAutoSync: () => {},
  handleParticipate: async () => false,
  isProcessingParticipation: false
});

// Hook to use the market data context
export const useMarketData = () => useContext(MarketDataContext);

interface MarketDataProviderProps {
  children: ReactNode;
  initialTab?: string;
}

// Provider component
export const MarketDataProvider = ({ children, initialTab }: MarketDataProviderProps) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useState<Date | null>(marketDataSynchronizer.lastSync);
  const [isProcessingParticipation, setIsProcessingParticipation] = useState(false);
  
  // Initialize auto-sync on component mount
  useEffect(() => {
    const syncIntervalMs = 5 * 60 * 1000; // 5 minutes
    marketDataSynchronizer.startAutoSync(syncIntervalMs);
    
    // Update our local state based on synchronizer state
    setIsSyncing(marketDataSynchronizer.isSyncInProgress);
    setLastSyncTime(marketDataSynchronizer.lastSync);
    
    // Clean up on unmount
    return () => {
      marketDataSynchronizer.stopAutoSync();
    };
  }, []);
  
  // Listen for sync events to update UI state
  useEffect(() => {
    const cleanupListeners = addMarketSyncListeners(
      [MARKET_SYNC_EVENTS.SYNC_START, MARKET_SYNC_EVENTS.SYNC_COMPLETE, MARKET_SYNC_EVENTS.FORCE_SYNC],
      (event) => {
        if (event.type === MARKET_SYNC_EVENTS.SYNC_START || event.type === MARKET_SYNC_EVENTS.FORCE_SYNC) {
          setIsSyncing(true);
        } else if (event.type === MARKET_SYNC_EVENTS.SYNC_COMPLETE) {
          setIsSyncing(false);
          
          // Update last sync time
          setLastSyncTime(marketDataSynchronizer.lastSync);
          
          // Show toast for sync completion if it has error info
          const { success, error } = event.detail || {};
          if (success === false && error) {
            toast.error(`Sync failed: ${error}`);
          }
        }
      }
    );
    
    return cleanupListeners;
  }, []);
  
  // Handle manual sync trigger with custom wrapper (to capture any errors)
  const triggerSync = (showToast: boolean = true) => {
    try {
      if (marketDataSynchronizer.isSyncInProgress) {
        if (showToast) {
          toast.info('Sync already in progress');
        }
        return;
      }
      
      setIsSyncing(true);
      marketDataSynchronizer.syncStarted();
      
      // Perform API calls or database operations
      fetch('/api/sync-market-data')
        .then(response => {
          if (!response.ok) {
            throw new Error(`API responded with status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          marketDataSynchronizer.syncSucceeded(data);
          setLastSyncTime(marketDataSynchronizer.lastSync);
          setIsSyncing(false);
          
          if (showToast) {
            toast.success('Market data synchronized successfully');
          }
        })
        .catch(error => {
          marketDataSynchronizer.syncFailed(error);
          setIsSyncing(false);
          
          if (showToast) {
            toast.error(`Sync failed: ${error.message}`);
          }
        });
    } catch (error) {
      const err = error as Error;
      logger.error('Error triggering sync:', err);
      
      marketDataSynchronizer.syncFailed(err);
      setIsSyncing(false);
      
      if (showToast) {
        toast.error(`Failed to start sync: ${err.message}`);
      }
    }
  };
  
  // Handle market participation
  const handleParticipate = async (
    marketId: string, 
    options?: { targetEarnings?: number; closeTime?: string }
  ): Promise<boolean> => {
    try {
      setIsProcessingParticipation(true);
      logger.info(`Handling market participation for ${marketId}`, options);
      
      const response = await fetch('/api/market/participate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          marketId,
          targetEarnings: options?.targetEarnings,
          closeTime: options?.closeTime
        }),
      });
      
      if (!response.ok) {
        throw new Error(`Failed to join market: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        toast.success('Successfully joined market');
        return true;
      } else {
        toast.error(data.error || 'Failed to join market');
        return false;
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      logger.error('Error joining market:', error);
      toast.error(`Error joining market: ${errorMessage}`);
      return false;
    } finally {
      setIsProcessingParticipation(false);
    }
  };
  
  // Define context value
  const contextValue: MarketDataContextType = {
    isSyncing,
    lastSyncTime,
    triggerSync,
    startAutoSync: marketDataSynchronizer.startAutoSync.bind(marketDataSynchronizer),
    stopAutoSync: marketDataSynchronizer.stopAutoSync.bind(marketDataSynchronizer),
    handleParticipate,
    isProcessingParticipation
  };

  return (
    <MarketDataContext.Provider value={contextValue}>
      {children}
    </MarketDataContext.Provider>
  );
};
