
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from "@/components/ui/table";
import { AlertCircle } from "lucide-react";
import { MarketParticipant } from "@/types/market";
import { MemberRow } from "./MemberRow";

interface MembersTableProps {
  members: MarketParticipant[];
  onApprove: (userId: string) => void;
  onSuspend: (userId: string) => void;
  onCloseForMember?: (userId: string) => void;
}

export const MembersTable = ({ members, onApprove, onSuspend, onCloseForMember }: MembersTableProps) => {
  return (
    <Table className="border border-zinc-200 dark:border-zinc-700 rounded-lg overflow-hidden transition-colors">
      <TableHeader className="bg-zinc-50 dark:bg-zinc-800 transition-colors">
        <TableRow>
          <TableHead className="text-zinc-700 dark:text-zinc-300 transition-colors">User ID</TableHead>
          <TableHead className="text-zinc-700 dark:text-zinc-300 transition-colors">Status</TableHead>
          <TableHead className="text-zinc-700 dark:text-zinc-300 transition-colors">Entry Amount</TableHead>
          <TableHead className="text-zinc-700 dark:text-zinc-300 transition-colors">Current Earnings</TableHead>
          <TableHead className="text-zinc-700 dark:text-zinc-300 transition-colors">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {members.map((member) => (
          <MemberRow 
            key={member.user_id} 
            member={member} 
            onApprove={onApprove} 
            onSuspend={onSuspend}
            onCloseForMember={onCloseForMember} 
          />
        ))}
        {members.length === 0 && (
          <TableRow className="hover:bg-zinc-50 dark:hover:bg-zinc-800 transition-colors">
            <TableCell colSpan={5} className="text-center py-8 text-zinc-600 dark:text-zinc-400 transition-colors">
              <AlertCircle className="h-6 w-4 mx-auto mb-2 text-zinc-400 dark:text-zinc-500 transition-colors" />
              <p>No users found in this market</p>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
