
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Download, RefreshCw, Calculator, CalendarDays, List } from "lucide-react";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { TransactionHistory } from "@/components/transactions/TransactionHistory";
import { DepositForm } from "@/components/transactions/DepositForm";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { supabase } from "@/lib/supabase";
import { Skeleton } from "@/components/ui/skeleton";
import { DailyFinancialRecord } from "@/components/financials/DailyFinancialRecord";
import { DailyFinancialCalendar } from "@/components/financials/DailyFinancialCalendar";
import { MonthlyFinancialSummary } from "@/components/financials/MonthlyFinancialSummary";
import { MonthSelector } from "@/components/financials/MonthSelector";
import { useDailyFinancialRecords } from "@/hooks/financials/use-daily-financial-records";
import { useMonthlyFinancialSummary } from "@/hooks/financials/use-monthly-financial-summary";
import { logger } from "@/utils/logger";

const Finances = () => {
  const now = new Date();
  const [selectedMonth, setSelectedMonth] = useState<number>(now.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState<number>(now.getFullYear());
  
  const [activeTab, setActiveTab] = useState<string>("daily");
  const [userBalance, setUserBalance] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState<"calendar" | "list">("calendar");
  const [memberSharePercentage, setMemberSharePercentage] = useState<number>(15);
  const { toast } = useToast();
  const { session } = useAuth();
  
  const {
    records,
    isLoading: isLoadingRecords,
    error: recordsError,
    dailyTotals,
    refresh: refreshRecords
  } = useDailyFinancialRecords(session?.user?.id, selectedMonth, selectedYear);
  
  const {
    summary,
    isLoading: isLoadingSummary,
    error: summaryError,
    refresh: refreshSummary,
    regenerate: regenerateSummary
  } = useMonthlyFinancialSummary(session?.user?.id, selectedMonth, selectedYear);

  useEffect(() => {
    if (session?.user?.id) {
      fetchUserBalance();
      fetchMemberSharePercentage();
    }
  }, [session?.user?.id]);

  const fetchUserBalance = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('profiles')
        .select('balance')
        .eq('id', session?.user?.id)
        .single();

      if (error) throw error;
      
      setUserBalance(data?.balance || 0);
    } catch (error) {
      console.error('Error fetching user balance:', error);
      toast({
        title: "Error",
        description: "Failed to load your account balance",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchMemberSharePercentage = async () => {
    try {
      if (!session?.user?.id) return;
      
      const { data, error } = await supabase
        .from('profiles')
        .select('member_share_percentage')
        .eq('id', session.user.id)
        .single();

      if (error) throw error;
      
      if (data?.member_share_percentage) {
        setMemberSharePercentage(data.member_share_percentage);
      }
    } catch (error) {
      console.error('Error fetching member share percentage:', error);
    }
  };

  const formatCurrency = (amount: number | null) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount || 0);
  };

  const handleMonthYearChange = (month: number, year: number) => {
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  const handleRecordUpdated = () => {
    refreshRecords();
    refreshSummary();
  };

  const handleRegenerateSummary = async () => {
    try {
      await regenerateSummary();
      toast({
        title: "Summary Regenerated",
        description: "The monthly summary has been recalculated successfully.",
        variant: "default",
      });
    } catch (error) {
      logger.error('Error regenerating summary', { error });
      toast({
        title: "Error",
        description: "Failed to regenerate the monthly summary",
        variant: "destructive",
      });
    }
  };

  const exportTransactions = () => {
    toast({
      title: "Export Initiated",
      description: "Your transactions export will begin shortly",
    });
  };

  const isCurrentMonth = (
    selectedMonth === now.getMonth() + 1 && 
    selectedYear === now.getFullYear()
  );

  return (
    <DashboardSidebar>
      <div className="p-4 md:p-6 lg:p-8 w-full">
        <header className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
          <div>
            <h1 className="text-3xl font-bold text-primary mb-2">Financial Management</h1>
            <p className="text-zinc-600 dark:text-zinc-400">Manage your deposits and track your market earnings</p>
          </div>
          <div className="flex gap-4">
            <Button variant="outline" onClick={exportTransactions} className="gap-2 text-zinc-700 dark:text-zinc-300 border-zinc-300 dark:border-zinc-700">
              <Download className="h-4 w-4" />
              Export
            </Button>
            <Button variant="outline" onClick={() => window.location.reload()} className="gap-2 text-zinc-700 dark:text-zinc-300 border-zinc-300 dark:border-zinc-700">
              <RefreshCw className="h-4 w-4" />
              Refresh
            </Button>
          </div>
        </header>

        <Tabs defaultValue="daily" value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="mb-8 bg-zinc-100 dark:bg-zinc-800/50">
            <TabsTrigger value="daily" className="data-[state=active]:bg-white dark:data-[state=active]:bg-zinc-800 data-[state=active]:text-primary">Daily Financial Tracking</TabsTrigger>
            <TabsTrigger value="history" className="data-[state=active]:bg-white dark:data-[state=active]:bg-zinc-800 data-[state=active]:text-primary">Transaction History</TabsTrigger>
            <TabsTrigger value="deposit" className="data-[state=active]:bg-white dark:data-[state=active]:bg-zinc-800 data-[state=active]:text-primary">Make Deposit</TabsTrigger>
          </TabsList>
          
          <TabsContent value="daily" className="space-y-8">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
              <div className="flex items-center gap-4">
                <MonthSelector 
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                  onMonthYearChange={handleMonthYearChange}
                />
                
                <div className="flex border rounded-lg overflow-hidden border-zinc-300 dark:border-zinc-700">
                  <Button 
                    variant={viewMode === "calendar" ? "default" : "ghost"}
                    size="sm"
                    onClick={() => setViewMode("calendar")}
                    className="rounded-r-none"
                  >
                    <CalendarDays className="h-4 w-4 mr-1" />
                    Calendar
                  </Button>
                  <Button 
                    variant={viewMode === "list" ? "default" : "ghost"}
                    size="sm"
                    onClick={() => setViewMode("list")}
                    className="rounded-l-none"
                  >
                    <List className="h-4 w-4 mr-1" />
                    List
                  </Button>
                </div>
              </div>
              
              <Button 
                variant="outline"
                onClick={handleRegenerateSummary}
                className="mt-4 md:mt-0 gap-2 text-zinc-700 dark:text-zinc-300 border-zinc-300 dark:border-zinc-700"
              >
                <Calculator className="h-4 w-4" />
                Recalculate Summary
              </Button>
            </div>
            
            <MonthlyFinancialSummary 
              summary={summary}
              dailyTotals={dailyTotals}
              isLoading={isLoadingSummary}
              memberSharePercentage={memberSharePercentage}
            />
            
            {isLoadingRecords ? (
              <div className="space-y-2">
                {[1, 2, 3, 4, 5].map(i => (
                  <Skeleton key={i} className="h-16 w-full" />
                ))}
              </div>
            ) : recordsError ? (
              <div className="text-red-500 p-4 bg-red-50 dark:bg-red-900/20 rounded-md">
                Error loading financial records. Please try again later.
              </div>
            ) : records.length === 0 ? (
              <div className="text-center py-8 text-zinc-600 dark:text-zinc-400 bg-zinc-50 dark:bg-zinc-800/30 rounded-md border border-zinc-200 dark:border-zinc-700">
                No financial records found for this month
              </div>
            ) : viewMode === "calendar" ? (
              <DailyFinancialCalendar
                records={records}
                month={selectedMonth}
                year={selectedYear}
                userId={session?.user?.id || ''}
                onRecordUpdated={handleRecordUpdated}
                isCurrentMonth={isCurrentMonth}
                memberSharePercentage={memberSharePercentage}
              />
            ) : (
              <div className="bg-zinc-50 dark:bg-zinc-800/30 p-4 rounded-md border border-zinc-200 dark:border-zinc-700">
                <h3 className="font-medium mb-4 text-zinc-800 dark:text-zinc-200">Daily Records for {new Date(selectedYear, selectedMonth - 1, 1).toLocaleString('default', { month: 'long' })} {selectedYear}</h3>
                <div className="space-y-2">
                  {records.map((record) => (
                    <DailyFinancialRecord 
                      key={record.record_date}
                      record={record}
                      isEditable={true}
                      onRecordUpdated={handleRecordUpdated}
                      userId={session?.user?.id || ''}
                      isCurrentMonth={isCurrentMonth}
                      memberSharePercentage={memberSharePercentage}
                    />
                  ))}
                </div>
              </div>
            )}
          </TabsContent>
          
          <TabsContent value="history" className="space-y-8">
            <TransactionHistory showTitle={false} />
          </TabsContent>
          
          <TabsContent value="deposit">
            <div className="max-w-2xl mx-auto bg-white dark:bg-zinc-800/50 p-6 rounded-lg shadow-sm border border-zinc-200 dark:border-zinc-700">
              <DepositForm />
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </DashboardSidebar>
  );
};

export default Finances;
