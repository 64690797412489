
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { BasicInfoFields } from "./BasicInfoFields";
import { DescriptionField } from "./DescriptionField";
import { MarketTypeFields } from "./MarketTypeFields";
import { NumericFields } from "./NumericFields";
import { StatusFields } from "./StatusFields";
import { TimeFields } from "../form-fields/TimeFields";
import * as z from "zod";
import { marketFormSchema } from "../config/formConfig";

export const renderFormFields = (
  currentFields: Array<keyof z.infer<typeof marketFormSchema>>,
  form: UseFormReturn<z.infer<typeof marketFormSchema>>
) => {
  // Group fields by category for easier rendering
  const basicInfoFieldNames = ["name", "region"];
  const descriptionFieldNames = ["description", "sequence_position"];
  const marketTypeFieldNames = ["market_type", "market_risk_level"];
  const numericFieldNames = [
    "target_value", 
    "entry_amount", 
    "expected_roi", 
    "max_participants",
    "max_participations_per_user", 
    "max_duplications"
  ];
  const timeFieldNames = ["start_date", "close_time", "countdown_enabled"];
  const statusFieldNames = ["auto_activate", "draft", "auto_approve_participants"];

  // Filter field names for the current step
  const currentBasicInfoFields = currentFields.filter(field => 
    basicInfoFieldNames.includes(field as string));
  
  const currentDescriptionFields = currentFields.filter(field => 
    descriptionFieldNames.includes(field as string));
  
  const currentMarketTypeFields = currentFields.filter(field => 
    marketTypeFieldNames.includes(field as string));
  
  const currentNumericFields = currentFields.filter(field => 
    numericFieldNames.includes(field as string));
  
  const currentTimeFields = currentFields.filter(field => 
    timeFieldNames.includes(field as string));
  
  const currentStatusFields = currentFields.filter(field => 
    statusFieldNames.includes(field as string));

  return (
    <div className="space-y-6">
      {currentBasicInfoFields.length > 0 && (
        <div className="space-y-4">
          <BasicInfoFields 
            form={form} 
            fields={currentBasicInfoFields as string[]} 
          />
        </div>
      )}

      {currentDescriptionFields.length > 0 && (
        <div className="space-y-4">
          <DescriptionField 
            form={form} 
            fields={currentDescriptionFields as string[]} 
          />
        </div>
      )}

      {currentMarketTypeFields.length > 0 && (
        <div className="space-y-4">
          <MarketTypeFields 
            form={form} 
            fields={currentMarketTypeFields as string[]} 
          />
        </div>
      )}

      {currentNumericFields.length > 0 && (
        <div className="space-y-4">
          <NumericFields 
            form={form} 
            fields={currentNumericFields as string[]} 
          />
        </div>
      )}

      {currentTimeFields.length > 0 && (
        <div className="space-y-4">
          <TimeFields 
            form={form} 
            fields={currentTimeFields as string[]} 
          />
        </div>
      )}

      {currentStatusFields.length > 0 && (
        <div className="space-y-4">
          <StatusFields 
            form={form} 
            fields={currentStatusFields as string[]} 
          />
        </div>
      )}
    </div>
  );
};
