import { Market } from "@/types/market";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { MarketStatusBadge } from "./MarketStatusBadge";
import { MarketActions } from "./MarketActions";
import { MarketProgressBar } from "./MarketProgressBar";
import { MarketMetadata } from "./MarketMetadata";
import { formatCurrency } from "@/utils/formatters";
import { logger } from "@/utils/logger";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useRef, useState, useEffect } from "react";
import { Sparkle, TrendingUp, TrendingDown, Calendar, Users, Layers, Target, RefreshCw, Loader2, CheckCircle } from "lucide-react";
import { isNewMarket } from "@/utils/market/is-new-market";
import { CLOSED_STATUSES } from "@/utils/market/filters/tab-strategies-improved";
import { useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { parseMarketMetadata } from "@/utils/market/metadata-parser";
import { 
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter
} from "@/components/ui/dialog";
import { useMarketStatus } from "@/hooks/markets/use-market-status";

interface MarketCardProps {
  market: Market;
  markets?: Market[];
  onParticipate: (marketId: string) => Promise<void>;
  onParticipateSuccess: (marketId: string) => Promise<void>;
  onCloseTimeUpdate: (marketId: string, closeTime: string) => Promise<void>;
  isAdminView?: boolean;
  showConfiguration?: boolean;
  showParticipationSummary?: boolean;
  variant?: 'default' | 'horizontal';
}

export const MarketCard = ({
  market,
  markets,
  onParticipate,
  onParticipateSuccess,
  onCloseTimeUpdate,
  isAdminView = false,
  showConfiguration = false,
  showParticipationSummary = false,
  variant = 'default'
}: MarketCardProps) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isCreatingDuplicate, setIsCreatingDuplicate] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const { session } = useAuth();
  const { updateMarketStatus } = useMarketStatus();
  
  const hasShownSuccessDialog = useRef(false);
  
  const handleParticipate = async (marketId: string, customSettings?: any) => {
    logger.info(`Participating in market: ${marketId}`);
    try {
      await onParticipate(marketId);
      await onParticipateSuccess(marketId);
    } catch (error) {
      logger.error(`Error participating in market ${marketId}:`, error);
      // Error will be handled by the participation hook
    }
  };
  
  const handleContributionSubmitted = async () => {
    logger.info(`Contribution submitted for market: ${market.id}`);
    try {
      await onParticipateSuccess(market.id);
      
      if (market.participationStatus === 'success' && !hasShownSuccessDialog.current) {
        setIsSuccessDialogOpen(true);
        hasShownSuccessDialog.current = true;
      }
    } catch (error) {
      logger.error(`Error updating after contribution for market ${market.id}:`, error);
      // Error will be handled by the participation hook
    }
  };
  
  const scrollToBottom = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  };

  const isNew = isNewMarket(market.created_at);

  const isFailed = ['failed', 'closed_failed', 'failed_final'].includes(market.status);
  const isSuccessful = ['success', 'closed_successful', 'completed'].includes(market.status);
  const isClosed = CLOSED_STATUSES.includes(market.status);

  const hasNoAttemptsLeft = market.max_participations_per_user && 
    market.participation_count && 
    market.participation_count >= market.max_participations_per_user;
    
  const parsedMetadata = parseMarketMetadata(market.metadata);
  
  const duplicateCount = parsedMetadata?.duplicate_count as number || 0;
  const canDuplicate = market.max_duplications && (duplicateCount < market.max_duplications);
  
  const isDuplicate = !!market.parent_market_id;
  
  const handleDuplicateMarket = async () => {
    if (!market.id) return;
    
    setIsCreatingDuplicate(true);
    try {
      const { data, error } = await supabase.functions.invoke("duplicate-market", {
        body: {
          market_id: market.id
        }
      });
      
      if (error) throw error;
      
      if (data && typeof data === 'object' && 'new_market_id' in data) {
        toast.success("Market duplicated successfully! You can now try again.");
        navigate(`/dashboard/markets/details/${data.new_market_id}`);
      } else {
        throw new Error("Failed to duplicate market. No new market ID returned.");
      }
    } catch (err) {
      logger.error('Error duplicating market', err);
      toast.error("Failed to duplicate market. Please try again.");
    } finally {
      setIsCreatingDuplicate(false);
    }
  };
  
  const handleCardClick = () => {
    if (isClosed) {
      navigate(`/dashboard/markets/details/${market.id}`);
    }
  };
  
  const handleMoveToClosedTab = async () => {
    try {
      if (isSuccessful && !isClosed) {
        await updateMarketStatus(market.id, 'closed_successful');
        toast.success("Market has been marked as closed successful!");
      }
      setIsSuccessDialogOpen(false);
      
      navigate('/dashboard/markets?tab=closed');
    } catch (error) {
      logger.error('Error moving market to closed tab:', error);
      toast.error("Failed to update market status. Please try again.");
    }
  };

  const progressPercentage = market.target_value > 0 ? Math.min(100, Math.round(market.current_value / market.target_value * 100)) : 0;
  
  const isParticipationFailed = market.participationStatus === 'failed';
  const isParticipationSuccess = market.participationStatus === 'success';
  
  const showRetryButton = isFailed && hasNoAttemptsLeft && canDuplicate;
  
  useEffect(() => {
    if (isParticipationSuccess && market.isParticipating && !hasShownSuccessDialog.current) {
      setIsSuccessDialogOpen(true);
      hasShownSuccessDialog.current = true;
    }
  }, [isParticipationSuccess, market.isParticipating]);
  
  return (
    <>
      <Card 
        className={cn(
          "overflow-hidden h-full flex flex-col transition-all duration-300 hover:shadow-md", 
          isParticipationFailed ? "border-red-500 dark:border-red-700 border-l-4 border-l-red-500 bg-red-50/50 dark:bg-red-950/20" :
          isParticipationSuccess ? "border-green-500 dark:border-green-700 border-l-4 border-l-green-500 bg-green-50/50 dark:bg-green-950/20" :
          isFailed && hasNoAttemptsLeft ? "border-red-500 dark:border-red-700 border-l-4 border-l-red-500 bg-red-50/20 dark:bg-red-950/10" : 
          isFailed ? "border-red-400 dark:border-red-700 border-l-4 border-l-red-500" : "", 
          isSuccessful ? "border-green-400 dark:border-green-700 border-l-4 border-l-green-500" : "", 
          isClosed && !isFailed && !isSuccessful ? "border-amber-400 dark:border-amber-700 border-l-4 border-l-amber-400" : "", 
          !isClosed && !isFailed && !isSuccessful ? "border-l-4 border-l-primary" : "", 
          isClosed ? "opacity-100 hover:shadow-xl cursor-pointer" : "", 
          variant === 'horizontal' ? "md:flex-row" : "", 
          "relative rounded-xl"
        )}
        onClick={isClosed ? handleCardClick : undefined}
      >
        {isNew && (
          <div className="absolute right-0 top-0 z-10">
            <div className="bg-gradient-to-r from-primary to-primary/80 text-primary-foreground py-1 px-3 flex items-center gap-1 rounded-bl-md shadow-md">
              <Sparkle className="h-4 w-4" />
              <span className="text-xs font-medium">New</span>
            </div>
          </div>
        )}
        
        {isDuplicate && (
          <div className="absolute left-0 top-0 z-10">
            <div className="bg-purple-500 text-white py-1 px-3 flex items-center gap-1 rounded-br-md shadow-md">
              <Layers className="h-3 w-3 mr-1" />
              <span className="text-xs font-medium">Duplicated</span>
            </div>
          </div>
        )}
        
        <CardHeader className={cn("pb-2", 
          isParticipationFailed ? "bg-red-100/80 dark:bg-red-950/40" :
          isParticipationSuccess ? "bg-green-100/80 dark:bg-green-950/40" :
          isFailed && hasNoAttemptsLeft ? "bg-red-100/80 dark:bg-red-950/40" :
          isFailed ? "bg-red-100/60 dark:bg-red-950/30" : "", 
          isSuccessful ? "bg-green-100/60 dark:bg-green-950/30" : "", 
          isClosed && !isFailed && !isSuccessful ? "bg-amber-100/60 dark:bg-amber-900/20" : "", 
          variant === 'horizontal' ? "md:w-1/3" : ""
        )}>
          <div className="flex justify-between items-start">
            <CardTitle title={market.name} className="text-xl font-bold text-foreground">
              {market.name}
            </CardTitle>
            <MarketStatusBadge status={market.status} participationStatus={market.participationStatus} startDate={market.start_date} />
          </div>
          
          <CardDescription className="flex justify-between items-center mt-1">
            <div className="flex items-center gap-2">
              <span className="font-medium text-foreground/70">
                {market.region || "Global"}
              </span>
              <span className="w-1 h-1 rounded-full bg-zinc-600 dark:bg-zinc-400"></span>
              <span className="font-medium text-foreground/70">
                {market.market_type || "Standard"}
              </span>
            </div>
          </CardDescription>
        </CardHeader>
        
        <CardContent className={cn(
          "py-3 flex-grow", 
          isParticipationFailed ? "bg-red-50/40 dark:bg-red-950/20" :
          isParticipationSuccess ? "bg-green-50/40 dark:bg-green-950/20" :
          isFailed && hasNoAttemptsLeft ? "bg-red-50/30 dark:bg-red-950/20" : "",
          variant === 'horizontal' ? "md:w-1/3 md:border-l md:border-r" : ""
        )}>
          <div className="space-y-4">
            <div className="grid grid-cols-3 gap-3">
              <div className="p-2 rounded-md bg-muted/50 border border-border/40 transition-colors hover:bg-muted/60">
                <div className="flex items-center gap-1 mb-1">
                  <Target className="h-3 w-3 text-foreground/70" />
                  <p className="text-sm text-foreground/90 font-medium">Target</p>
                </div>
                <p className="font-medium text-lg text-foreground">{formatCurrency(market.target_value)}</p>
              </div>
              
              <div className="p-2 rounded-md bg-muted/50 border border-border/40 transition-colors hover:bg-muted/60">
                <div className="flex items-center gap-1 mb-1">
                  <Layers className="h-3 w-3 text-foreground/70" />
                  <p className="text-sm text-foreground/90 font-medium">Current</p>
                </div>
                <p className="font-medium text-lg text-foreground">{formatCurrency(market.current_value)}</p>
              </div>
              
              <div className="p-2 rounded-md bg-muted/50 border border-border/40 transition-colors hover:bg-muted/60">
                <div className="flex items-center gap-1 mb-1">
                  <Target className="h-3 w-3 text-foreground/70" />
                  <p className="text-sm text-foreground/90 font-medium">Progress</p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="font-medium text-foreground">{progressPercentage}%</p>
                  <div className="flex items-center">
                    {market.growth_rate >= 0 ? (
                      <TrendingUp className="h-4 w-4 text-green-600 dark:text-green-500" />
                    ) : (
                      <TrendingDown className="h-4 w-4 text-red-600 dark:text-red-500" />
                    )}
                    <span className={cn(
                      market.growth_rate >= 0 ? "text-green-700 dark:text-green-400" : "text-red-700 dark:text-red-400",
                      "text-sm font-medium"
                    )}>
                      {market.growth_rate >= 0 ? "+" : ""}{market.growth_rate}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="space-y-2">
              <MarketProgressBar currentValue={market.current_value} targetValue={market.target_value} status={market.status} />
              
              <div className="flex justify-between items-center text-sm mt-2">
                <div className="flex items-center gap-1 text-foreground/80 font-medium">
                  <Users className="h-4 w-4 text-foreground/70 dark:text-foreground/80" />
                  <span>{market.active_participants || market.participant_count || 0} participants</span>
                </div>
                
                {market.close_time && (
                  <div className="flex items-center gap-1 text-foreground/80 font-medium">
                    <Calendar className="h-4 w-4 text-foreground/70 dark:text-foreground/80" />
                    <span>
                      {isClosed ? "Closed: " : "Closes: "}
                      {new Date(market.close_time).toLocaleDateString()}
                    </span>
                  </div>
                )}
              </div>
            </div>
            
            {isParticipationFailed && canDuplicate && (
              <div className="pt-2">
                <Button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDuplicateMarket();
                  }}
                  className="w-full bg-primary hover:bg-primary/90"
                  disabled={isCreatingDuplicate}
                >
                  {isCreatingDuplicate ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Creating...
                    </>
                  ) : (
                    <>
                      <RefreshCw className="mr-2 h-4 w-4" />
                      Retry with New Market
                    </>
                  )}
                </Button>
              </div>
            )}
            
            {showRetryButton && !isParticipationFailed && (
              <div className="pt-2">
                <Button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDuplicateMarket();
                  }}
                  className="w-full bg-primary hover:bg-primary/90"
                  disabled={isCreatingDuplicate}
                >
                  {isCreatingDuplicate ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Creating...
                    </>
                  ) : (
                    <>
                      <RefreshCw className="mr-2 h-4 w-4" />
                      Retry with New Market
                    </>
                  )}
                </Button>
              </div>
            )}
            
            <MarketMetadata market={market} />
          </div>
        </CardContent>
        
        <CardFooter 
          ref={footerRef} 
          className={cn(
            "pt-2 bg-background border-t", 
            isParticipationFailed ? "bg-red-50/20 dark:bg-red-950/10" :
            isParticipationSuccess ? "bg-green-50/20 dark:bg-green-950/10" :
            isFailed && hasNoAttemptsLeft ? "bg-red-50/10 dark:bg-red-950/10" : "",
            variant === 'horizontal' ? "md:w-1/3" : "", 
            "max-h-[250px] overflow-auto"
          )}
        >
          <ScrollArea className="w-full" orientation="both">
            <MarketActions 
              market={market} 
              onContributionSubmitted={handleContributionSubmitted} 
              onParticipate={handleParticipate} 
              onCloseTimeUpdate={onCloseTimeUpdate} 
              isAdminView={isAdminView} 
              showConfiguration={showConfiguration} 
              onScrollToBottom={scrollToBottom} 
            />
          </ScrollArea>
        </CardFooter>
      </Card>
      
      <Dialog open={isSuccessDialogOpen} onOpenChange={setIsSuccessDialogOpen}>
        <DialogContent className="sm:max-w-md border-green-500 dark:border-green-700">
          <DialogHeader>
            <DialogTitle className="flex items-center text-green-700 dark:text-green-500">
              <CheckCircle className="w-6 h-6 mr-2" />
              Market Success
            </DialogTitle>
            <DialogDescription className="text-foreground/80">
              This market has successfully reached its target!
            </DialogDescription>
          </DialogHeader>
          
          <div className="p-4 bg-green-50 dark:bg-green-950/20 rounded-md">
            <p className="text-sm text-green-800 dark:text-green-200">
              {isParticipationSuccess 
                ? "Congratulations! Your participation was successful!" 
                : `Congratulations! The market "${market.name}" has successfully reached its target value of ${formatCurrency(market.target_value)}.`}
            </p>
          </div>
          
          <DialogFooter className="sm:justify-end">
            <Button
              onClick={handleMoveToClosedTab}
              className="w-full sm:w-auto bg-green-600 hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-800 text-white"
            >
              Okay
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
