
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import type { User } from "@supabase/supabase-js";

interface RegisterUserParams {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
}

// Check if email is from an approved admin domain
const isColosseumEmail = (email: string): boolean => {
  const email_lower = email.toLowerCase();
  return email_lower.endsWith('@thecolosseum.co.za') || 
         email_lower.endsWith('@api.thecolosseum.co.za');
};

export async function registerUser({
  email,
  password,
  firstName,
  lastName,
  phone,
  country,
}: RegisterUserParams) {
  try {
    logger.info('Starting user registration process', { email });
    
    // Sign up with Supabase
    const { data, error: signUpError } = await supabase.auth.signUp({
      email: email.trim().toLowerCase(),
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
          phone,
          country
        }
      }
    });

    if (signUpError) {
      // Handle potential duplicate key error from database
      if (signUpError.message && (
          signUpError.message.includes('duplicate key value') ||
          signUpError.message.includes('unique constraint')
      )) {
        logger.error('User already exists:', { email });
        throw new Error('User already registered with this email');
      }
      
      // Log detailed error for system analysis
      logger.error('Registration error details:', { 
        message: signUpError.message,
        name: signUpError.name, 
        status: signUpError.status 
      });
      
      throw signUpError;
    }

    if (data?.user) {
      // Explicitly create/update the profile record to ensure it exists
      // This is in addition to the database trigger
      const { error: profileError } = await supabase
        .from('profiles')
        .upsert({
          id: data.user.id,
          first_name: firstName,
          last_name: lastName,
          phone: phone || '',
          country: country || '',
          status: 'waiting',  // Always start as waiting
          email: email.trim().toLowerCase(),
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });
        
      if (profileError) {
        logger.error('Error creating profile during registration:', profileError);
      }
      
      logger.info('User registered successfully and profile created', { 
        userId: data.user.id,
        status: 'waiting'
      });
      
      return data;
    } else {
      throw new Error('No user data returned from registration');
    }
  } catch (error: any) {
    // If the error includes a message about duplicate user, enhance the message
    if (error.message && (
        error.message.includes('duplicate key value') ||
        error.message.includes('User already registered') ||
        error.message.includes('unique constraint') ||
        error.message.toLowerCase().includes('already registered')
      )) {
      logger.error('User already exists:', { email });
      throw new Error('User already registered with this email');
    }
    
    logger.error('Registration error:', error);
    throw error;
  }
}

export async function registerAdminUser(adminData: RegisterUserParams) {
  logger.info('Starting admin registration process...', { email: adminData.email });
  
  try {
    logger.info('Validating admin data...');
    if (!adminData.email || !adminData.password) {
      throw new Error('Email and password are required for admin registration');
    }

    // Verify admin email domain
    const isAdmin = isColosseumEmail(adminData.email);
    if (!isAdmin) {
      throw new Error('Admin registration requires a @thecolosseum.co.za or @api.thecolosseum.co.za email address');
    }
    
    // Register the user (the trigger will handle setting up admin role)
    const result = await registerUser(adminData);
    
    if (result?.user) {
      logger.info('Admin user created successfully', { userId: result.user.id });
      
      // Since we have the trigger in place, we just need to ensure the profile record is updated properly
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ 
          status: 'active'
        })
        .eq('id', result.user.id);

      if (updateError) {
        logger.error("Error updating admin status:", updateError);
        throw updateError;
      }
      
      // Sign in the admin user immediately if in development
      if (import.meta.env.DEV) {
        const { error: signInError } = await supabase.auth.signInWithPassword({
          email: adminData.email.trim().toLowerCase(),
          password: adminData.password
        });

        if (signInError) {
          throw signInError;
        }
      }

      return {
        user: result.user,
        session: result.session,
        isAdmin: true,
        message: 'Admin account created successfully',
        shouldRedirectToAdmin: true
      };
    }

    throw new Error('User registration failed without error');
  } catch (error: any) {
    logger.error('Admin registration error:', error);
    throw error;
  }
}
