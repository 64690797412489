
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface MarketAdjustmentInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const MarketAdjustmentInput = ({ value, onChange }: MarketAdjustmentInputProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="adjustmentFactor">Market Adjustment Factor</Label>
      <Input
        id="adjustmentFactor"
        type="number"
        step="0.01"
        min="0.01"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter adjustment factor"
      />
      <p className="text-xs text-muted-foreground">
        Factor used to adjust market calculations
      </p>
    </div>
  );
};
