import { logger } from "@/utils/logger";

/**
 * CircuitBreaker provides protection against repeatedly trying operations that are likely to fail
 */
class CircuitBreaker {
  private static instance: CircuitBreaker;
  private breakerState: Map<string, {
    isOpen: boolean,
    failureCount: number,
    lastFailureTime: number,
    resetTimeout: number | null
  }> = new Map();
  
  private readonly FAILURE_THRESHOLD = 3;
  private readonly RESET_TIMEOUT = 30000; // 30 seconds

  private constructor() {
    // Singleton pattern
  }

  public static getInstance(): CircuitBreaker {
    if (!CircuitBreaker.instance) {
      CircuitBreaker.instance = new CircuitBreaker();
    }
    return CircuitBreaker.instance;
  }

  /**
   * Check if an operation can proceed
   */
  public canProceed(operationKey: string): boolean {
    const state = this.breakerState.get(operationKey);
    
    if (!state) {
      // Initialize state for new operation
      this.breakerState.set(operationKey, {
        isOpen: false,
        failureCount: 0,
        lastFailureTime: 0,
        resetTimeout: null
      });
      return true;
    }
    
    if (state.isOpen) {
      const now = Date.now();
      // If enough time has passed, allow one test request
      if (now - state.lastFailureTime > this.RESET_TIMEOUT) {
        logger.info(`Circuit half-open for ${operationKey}, allowing test request`);
        return true;
      }
      
      logger.warn(`Circuit open for ${operationKey}, blocking request`);
      return false;
    }
    
    return true;
  }

  /**
   * Record a success, resetting failure count
   */
  public recordSuccess(operationKey: string): void {
    const state = this.breakerState.get(operationKey);
    if (state) {
      // Reset failure count and close circuit
      state.failureCount = 0;
      state.isOpen = false;
      
      // Clear any reset timeout
      if (state.resetTimeout) {
        clearTimeout(state.resetTimeout);
        state.resetTimeout = null;
      }
      
      logger.info(`Circuit closed for ${operationKey} after success`);
    }
  }

  /**
   * Record a failure, potentially opening the circuit
   */
  public recordFailure(operationKey: string): void {
    let state = this.breakerState.get(operationKey);
    
    if (!state) {
      state = {
        isOpen: false,
        failureCount: 0,
        lastFailureTime: 0,
        resetTimeout: null
      };
      this.breakerState.set(operationKey, state);
    }
    
    state.failureCount++;
    state.lastFailureTime = Date.now();
    
    logger.info(`Failure recorded for ${operationKey} (${state.failureCount}/${this.FAILURE_THRESHOLD})`);
    
    // Open circuit if threshold reached
    if (state.failureCount >= this.FAILURE_THRESHOLD) {
      state.isOpen = true;
      
      // Set up automatic reset after timeout
      if (state.resetTimeout) {
        clearTimeout(state.resetTimeout);
      }
      
      state.resetTimeout = window.setTimeout(() => {
        this.halfOpenCircuit(operationKey);
      }, this.RESET_TIMEOUT);
      
      logger.warn(`Circuit opened for ${operationKey} after ${state.failureCount} failures`);
      
      // Dispatch event to notify UI
      this.dispatchCircuitEvent(operationKey, true);
    }
  }

  /**
   * Put circuit in half-open state for testing
   */
  private halfOpenCircuit(operationKey: string): void {
    const state = this.breakerState.get(operationKey);
    if (state) {
      state.resetTimeout = null;
      logger.info(`Circuit half-open for ${operationKey}, will test next request`);
    }
  }

  /**
   * Reset a circuit completely
   */
  public resetCircuit(operationKey: string): void {
    const state = this.breakerState.get(operationKey);
    if (state) {
      if (state.resetTimeout) {
        clearTimeout(state.resetTimeout);
      }
      
      this.breakerState.set(operationKey, {
        isOpen: false,
        failureCount: 0,
        lastFailureTime: 0,
        resetTimeout: null
      });
      
      logger.info(`Circuit reset for ${operationKey}`);
      
      // Dispatch event to notify UI
      this.dispatchCircuitEvent(operationKey, false);
    }
  }

  /**
   * Get the state of a circuit
   */
  public getCircuitState(operationKey: string): { 
    isOpen: boolean, 
    failureCount: number, 
    lastFailureTime: number 
  } {
    const state = this.breakerState.get(operationKey);
    
    if (!state) {
      return { isOpen: false, failureCount: 0, lastFailureTime: 0 };
    }
    
    return {
      isOpen: state.isOpen,
      failureCount: state.failureCount,
      lastFailureTime: state.lastFailureTime
    };
  }
  
  /**
   * Dispatch circuit state change event
   */
  private dispatchCircuitEvent(operationKey: string, isOpen: boolean): void {
    try {
      const event = new CustomEvent('circuit-state-change', {
        detail: { operationKey, isOpen }
      });
      window.dispatchEvent(event);
    } catch (error) {
      // Ignore dispatch errors
    }
  }
}

/**
 * QueryManager centralizes query state management with circuit breaking,
 * backoff, and caching to prevent excessive retries and race conditions
 */
export class QueryManager {
  private static instance: QueryManager;
  private circuitBreaker = CircuitBreaker.getInstance();
  private queryInProgress: Map<string, boolean> = new Map();
  private abortControllers: Map<string, AbortController> = new Map();
  private queryTimeouts: Map<string, number> = new Map();
  private lastQueryTime: Map<string, number> = new Map();
  private cacheData: Map<string, any> = new Map();
  private cacheTimestamp: Map<string, number> = new Map();
  
  private readonly MIN_QUERY_INTERVAL = 2000; // 2 seconds
  private readonly CACHE_TTL = 5 * 60 * 1000; // 5 minutes
  private readonly LONG_CACHE_TTL = 30 * 60 * 1000; // 30 minutes (for when circuit is open)

  private constructor() {
    // Singleton pattern
  }

  public static getInstance(): QueryManager {
    if (!QueryManager.instance) {
      QueryManager.instance = new QueryManager();
    }
    return QueryManager.instance;
  }
  
  /**
   * Get access to the circuit breaker
   */
  public getCircuitBreaker(): CircuitBreaker {
    return this.circuitBreaker;
  }
  
  /**
   * Check if a query can proceed based on circuit state and rate limiting
   */
  public canQuery(queryKey: string): boolean {
    // Check circuit breaker first
    if (!this.circuitBreaker.canProceed(queryKey)) {
      logger.warn(`Query ${queryKey} blocked by circuit breaker`);
      return false;
    }
    
    // Check if query is already in progress
    if (this.queryInProgress.get(queryKey)) {
      logger.info(`Query ${queryKey} already in progress`);
      return false;
    }
    
    // Apply rate limiting
    const now = Date.now();
    const lastQuery = this.lastQueryTime.get(queryKey) || 0;
    
    if (now - lastQuery < this.MIN_QUERY_INTERVAL) {
      logger.info(`Query ${queryKey} rate limited, try again in ${this.MIN_QUERY_INTERVAL - (now - lastQuery)}ms`);
      return false;
    }
    
    return true;
  }
  
  /**
   * Prepare for a query, returning abort signal and setting up state
   */
  public prepareQuery(queryKey: string): AbortSignal | undefined {
    if (!this.canQuery(queryKey)) {
      return undefined;
    }
    
    // Abort any existing query
    this.abortQuery(queryKey);
    
    // Set up new abort controller
    const abortController = new AbortController();
    this.abortControllers.set(queryKey, abortController);
    
    // Mark query as in progress
    this.queryInProgress.set(queryKey, true);
    this.lastQueryTime.set(queryKey, Date.now());
    
    logger.info(`Query ${queryKey} prepared`);
    return abortController.signal;
  }
  
  /**
   * Record query success and clean up
   */
  public recordQuerySuccess(queryKey: string, data: any): void {
    // Update circuit breaker
    this.circuitBreaker.recordSuccess(queryKey);
    
    // Cache the successful data
    this.cacheData.set(queryKey, data);
    this.cacheTimestamp.set(queryKey, Date.now());
    
    // Clean up
    this.queryInProgress.set(queryKey, false);
    this.abortControllers.delete(queryKey);
    
    logger.info(`Query ${queryKey} completed successfully`);
  }
  
  /**
   * Record query failure, update circuit breaker
   */
  public recordQueryFailure(queryKey: string, error: any): void {
    // Only record as failure if it's not an abort error
    const isAbortError = error?.name === 'AbortError' || 
                          error?.message?.includes('aborted') ||
                          error?.isAborted;
    
    if (!isAbortError) {
      // Update circuit breaker
      this.circuitBreaker.recordFailure(queryKey);
      logger.warn(`Query ${queryKey} failed: ${error?.message || 'Unknown error'}`);
    } else {
      logger.info(`Query ${queryKey} aborted, not counting as failure`);
    }
    
    // Clean up
    this.queryInProgress.set(queryKey, false);
    this.abortControllers.delete(queryKey);
  }
  
  /**
   * Abort an in-progress query
   */
  public abortQuery(queryKey: string): void {
    const controller = this.abortControllers.get(queryKey);
    if (controller && !controller.signal.aborted) {
      controller.abort('Manual abort request');
      logger.info(`Query ${queryKey} aborted`);
    }
    
    // Clean up any query timeout
    const timeoutId = this.queryTimeouts.get(queryKey);
    if (timeoutId) {
      window.clearTimeout(timeoutId);
      this.queryTimeouts.delete(queryKey);
    }
    
    this.queryInProgress.set(queryKey, false);
    this.abortControllers.delete(queryKey);
  }
  
  /**
   * Get cached data if available and valid
   */
  public getCachedData<T>(queryKey: string, fallbackData?: T): T | undefined {
    const data = this.cacheData.get(queryKey) as T;
    const timestamp = this.cacheTimestamp.get(queryKey) || 0;
    const now = Date.now();
    
    // Use longer cache TTL when circuit is open
    const ttl = this.circuitBreaker.getCircuitState(queryKey).isOpen
      ? this.LONG_CACHE_TTL
      : this.CACHE_TTL;
    
    if (data && now - timestamp < ttl) {
      logger.info(`Using cached data for ${queryKey}, age: ${Math.round((now - timestamp)/1000)}s`);
      return data;
    }
    
    return fallbackData;
  }
  
  /**
   * Manually cache data
   */
  public cacheQueryData<T>(queryKey: string, data: T): void {
    this.cacheData.set(queryKey, data);
    this.cacheTimestamp.set(queryKey, Date.now());
  }
  
  /**
   * Check if a query is currently in progress
   */
  public isQueryInProgress(queryKey: string): boolean {
    return !!this.queryInProgress.get(queryKey);
  }
  
  /**
   * Reset all state for a query
   */
  public resetQueryState(queryKey: string): void {
    this.abortQuery(queryKey);
    this.circuitBreaker.resetCircuit(queryKey);
    this.queryInProgress.set(queryKey, false);
    this.lastQueryTime.delete(queryKey);
    
    logger.info(`Query state reset for ${queryKey}`);
  }
  
  /**
   * Create a standardized key for identifying a query
   */
  public static createQueryKey(baseKey: string, userId?: string): string {
    return userId ? `${baseKey}:${userId}` : baseKey;
  }
}
