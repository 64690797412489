
import { AlertCircle, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";

interface ErrorDisplayProps {
  error: string;
  errorDetails: string | null;
  onRetry: () => void;
}

export const ErrorDisplay = ({ error, errorDetails, onRetry }: ErrorDisplayProps) => {
  // Check if error is related to permissions (403)
  const isPermissionError = error.includes('403') || 
                           error.toLowerCase().includes('forbidden') || 
                           error.toLowerCase().includes('permission');

  return (
    <div className="text-center py-4">
      <AlertCircle className="h-6 w-6 text-destructive mx-auto mb-2" />
      <p className="text-destructive font-medium mb-3">
        {isPermissionError ? 'Permission Error' : 'Error loading members'}
      </p>
      
      <Alert variant="destructive" className="mt-4 text-left">
        <AlertTitle className="flex items-center gap-2">
          <AlertCircle className="h-4 w-4" />
          {isPermissionError ? 'Access Denied' : 'Error details'}
        </AlertTitle>
        <AlertDescription className="text-xs overflow-auto max-h-40">
          <p><span className="font-semibold">Message:</span> {error}</p>
          {errorDetails && (
            <div className="mt-2">
              <span className="font-semibold">Details:</span>
              <pre className="mt-1 bg-destructive/10 p-2 rounded-sm whitespace-pre-wrap text-xs">
                {errorDetails}
              </pre>
            </div>
          )}
          
          {isPermissionError && (
            <p className="mt-2 text-xs italic">
              This error typically occurs when your account doesn't have the necessary permissions 
              to access this data due to row-level security policies.
            </p>
          )}
        </AlertDescription>
      </Alert>

      <Button 
        variant="outline" 
        size="sm" 
        className="mt-4"
        onClick={onRetry}
      >
        <RefreshCw className="mr-2 h-3 w-3" /> Try Again
      </Button>
    </div>
  );
};
