
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";

interface MarketTypeSelectorProps {
  value: 'standard' | 'premium' | 'exclusive' | 'duplicate';
  onChange: (value: 'standard' | 'premium' | 'exclusive' | 'duplicate') => void;
}

export const MarketTypeSelector = ({ value, onChange }: MarketTypeSelectorProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="marketType">Market Type</Label>
      <Select
        value={value}
        onValueChange={(val) => onChange(val as 'standard' | 'premium' | 'exclusive' | 'duplicate')}
      >
        <SelectTrigger id="marketType">
          <SelectValue placeholder="Select market type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="standard">Standard</SelectItem>
          <SelectItem value="premium">Premium</SelectItem>
          <SelectItem value="exclusive">Exclusive</SelectItem>
          <SelectItem value="duplicate">Duplicate</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
