import { useState } from "react";
import { validateEmail, validatePhone, validatePassword } from "@/utils/validation";

export interface FormData {
  fullName: string;
  email: string;
  phone: string;
  govId: string;
  country: string;
  password: string;
  confirmPassword: string;
  agreeToTerms: boolean;
}

export interface FormErrors {
  fullName?: string;
  email?: string;
  phone?: string;
  govId?: string;
  country?: string;
  password?: string;
  confirmPassword?: string;
  agreeToTerms?: string;
}

export const useRegistrationForm = () => {
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    phone: "",
    govId: "",
    country: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: false
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const validateField = (name: keyof FormData, value: string | boolean) => {
    const newErrors = { ...errors };
    delete newErrors[name];

    switch (name) {
      case 'fullName':
        if (typeof value === 'string' && value.length < 2) {
          newErrors.fullName = "Full name must be at least 2 characters";
        }
        break;
      case 'email':
        if (typeof value === 'string' && !validateEmail(value)) {
          newErrors.email = "Please enter a valid email address";
        }
        break;
      case 'phone':
        if (typeof value === 'string' && !validatePhone(value)) {
          newErrors.phone = "Please enter a valid phone number with country code";
        }
        break;
      case 'govId':
        if (typeof value === 'string' && value.length < 5) {
          newErrors.govId = "Please enter a valid government ID number";
        }
        break;
      case 'country':
        if (typeof value === 'string' && value.length < 2) {
          newErrors.country = "Please enter a valid country name";
        }
        break;
      case 'password':
        if (typeof value === 'string') {
          if (!validatePassword(value)) {
            newErrors.password = "Password must meet all requirements";
          }
          if (formData.confirmPassword && value !== formData.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match";
          }
        }
        break;
      case 'confirmPassword':
        if (typeof value === 'string' && value !== formData.password) {
          newErrors.confirmPassword = "Passwords do not match";
        }
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep = (step: number): boolean => {
    const newErrors: FormErrors = {};

    switch (step) {
      case 1:
        if (!formData.fullName) newErrors.fullName = "Full name is required";
        if (!formData.email) newErrors.email = "Email is required";
        else if (!validateEmail(formData.email)) newErrors.email = "Please enter a valid email address";
        if (!formData.phone) newErrors.phone = "Phone number is required";
        else if (!validatePhone(formData.phone)) newErrors.phone = "Please enter a valid phone number";
        break;
      case 2:
        if (!formData.govId) newErrors.govId = "Government ID is required";
        if (!formData.country) newErrors.country = "Country is required";
        break;
      case 3:
        if (!formData.password) newErrors.password = "Password is required";
        else if (!validatePassword(formData.password)) newErrors.password = "Password must meet all requirements";
        if (!formData.confirmPassword) newErrors.confirmPassword = "Please confirm your password";
        else if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = "Passwords do not match";
        if (!formData.agreeToTerms) newErrors.agreeToTerms = "You must agree to the Terms & Conditions";
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return {
    formData,
    setFormData,
    errors,
    setErrors,
    validateField,
    validateStep
  };
};
