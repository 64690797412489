
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { Session } from "@supabase/supabase-js";

export const useAdminAccess = (session: Session | null) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    let isMounted = true;

    const checkAdminAccess = async () => {
      if (!session?.user?.id) {
        logger.info('No session found, redirecting to signin');
        navigate('/signin', { replace: true });
        return;
      }

      try {
        logger.info('Checking admin access for user:', session.user.id);
        
        // Use the new admin check function
        const { data: isAdmin, error: adminError } = await supabase.rpc('is_admin_email_direct');
        
        // Check if component is still mounted before proceeding
        if (!isMounted) return;

        if (adminError) {
          logger.error('Error checking admin access:', adminError);
          throw adminError;
        }

        if (!isAdmin) {
          logger.warn('Non-admin access attempt:', {
            userId: session.user.id,
            email: session.user.email
          });
          
          toast({
            title: "Access Denied",
            description: "You don't have permission to access this area.",
            variant: "destructive",
          });
          
          // Use replace to prevent back navigation to admin routes
          navigate('/dashboard', { replace: true });
          return;
        }

        // If we get here, user is confirmed admin
        logger.info('Admin access confirmed for user:', session.user.id);

      } catch (error) {
        if (!isMounted) return;
        
        logger.error('Error during admin access check:', error);
        
        toast({
          title: "Error",
          description: "There was a problem verifying your access. Please try again.",
          variant: "destructive",
        });
        
        navigate('/dashboard', { replace: true });
      }
    };

    checkAdminAccess();

    // Cleanup function to prevent state updates after unmount
    return () => {
      isMounted = false;
    };
  }, [session, navigate, toast]);
};
