
import React, { useState, useEffect, useCallback } from 'react';
import { AlertCircle, CheckCircle, RefreshCw } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { QueryManager } from '@/hooks/markets/fetch/query-manager';
import { logger } from '@/utils/logger';

export const MarketOperationsStatus = () => {
  const [circuitStatus, setCircuitStatus] = useState<{
    marketsOpen: boolean,
    participationOpen: boolean,
    contributionOpen: boolean
  }>({
    marketsOpen: true,
    participationOpen: true,
    contributionOpen: true
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const userId = localStorage.getItem('current_user_id') || 'unknown';
  
  // Check circuit state
  const checkCircuitStatus = useCallback(() => {
    const queryManager = QueryManager.getInstance();
    const marketsKey = QueryManager.createQueryKey('markets', userId);
    const participationKey = QueryManager.createQueryKey('participation', userId);
    const contributionKey = QueryManager.createQueryKey('contribution', userId);
    
    // Get circuit breaker state using the correct method
    const marketsCircuit = queryManager.getCircuitBreaker().getCircuitState(marketsKey);
    const participationCircuit = queryManager.getCircuitBreaker().getCircuitState(participationKey);
    const contributionCircuit = queryManager.getCircuitBreaker().getCircuitState(contributionKey);
    
    setCircuitStatus({
      marketsOpen: !marketsCircuit.isOpen,
      participationOpen: !participationCircuit.isOpen,
      contributionOpen: !contributionCircuit.isOpen
    });
    
    // Auto-expand if any circuit is closed
    if (marketsCircuit.isOpen || participationCircuit.isOpen || contributionCircuit.isOpen) {
      setIsExpanded(true);
    }
  }, [userId]);
  
  useEffect(() => {
    // Check on mount
    checkCircuitStatus();
    
    // Check periodically
    const interval = setInterval(checkCircuitStatus, 5000);
    
    // Listen for circuit state changes
    const handleCircuitChange = () => checkCircuitStatus();
    window.addEventListener('circuit-state-change', handleCircuitChange);
    
    return () => {
      clearInterval(interval);
      window.removeEventListener('circuit-state-change', handleCircuitChange);
    };
  }, [checkCircuitStatus]);

  // Reset all circuits
  const handleReset = () => {
    const queryManager = QueryManager.getInstance();
    const marketsKey = QueryManager.createQueryKey('markets', userId);
    const participationKey = QueryManager.createQueryKey('participation', userId);
    const contributionKey = QueryManager.createQueryKey('contribution', userId);
    
    queryManager.resetQueryState(marketsKey);
    queryManager.resetQueryState(participationKey);
    queryManager.resetQueryState(contributionKey);
    
    logger.info('All circuits manually reset');
    
    // Update status
    checkCircuitStatus();
  };

  // Don't show anything if all systems are operational and not expanded
  const allOperational = circuitStatus.marketsOpen && 
                        circuitStatus.participationOpen && 
                        circuitStatus.contributionOpen;
                        
  if (allOperational && !isExpanded) {
    return null;
  }

  return (
    <div className="mb-4 px-4 py-3 rounded-md border bg-background">
      <div className="flex justify-between items-center">
        <div 
          className="flex items-center cursor-pointer" 
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!allOperational ? (
            <AlertCircle className="h-5 w-5 text-amber-500 dark:text-amber-400 mr-2" />
          ) : (
            <CheckCircle className="h-5 w-5 text-green-500 dark:text-green-400 mr-2" />
          )}
          <span className={!allOperational ? "text-amber-500 dark:text-amber-400 font-medium" : "text-green-500 dark:text-green-400 font-medium"}>
            {!allOperational ? "System Recovery Mode Active" : "All Systems Operational"}
          </span>
          {isExpanded && <RefreshCw className="h-4 w-4 ml-2 text-muted-foreground" />}
        </div>
        <Button 
          variant="outline" 
          size="sm" 
          onClick={handleReset}
          className="text-xs"
        >
          Reset
        </Button>
      </div>
      
      {isExpanded && (
        <div className="mt-2 text-sm space-y-1 text-muted-foreground">
          <div className="flex items-center">
            <div className={`w-2 h-2 rounded-full mr-2 ${circuitStatus.marketsOpen ? 'bg-green-500 dark:bg-green-400' : 'bg-amber-500 dark:bg-amber-400'}`}></div>
            <span>Market Data: {circuitStatus.marketsOpen ? 'Operational' : 'Recovery Mode'}</span>
          </div>
          <div className="flex items-center">
            <div className={`w-2 h-2 rounded-full mr-2 ${circuitStatus.participationOpen ? 'bg-green-500 dark:bg-green-400' : 'bg-amber-500 dark:bg-amber-400'}`}></div>
            <span>Market Joining: {circuitStatus.participationOpen ? 'Operational' : 'Recovery Mode'}</span>
          </div>
          <div className="flex items-center">
            <div className={`w-2 h-2 rounded-full mr-2 ${circuitStatus.contributionOpen ? 'bg-green-500 dark:bg-green-400' : 'bg-amber-500 dark:bg-amber-400'}`}></div>
            <span>Contributions: {circuitStatus.contributionOpen ? 'Operational' : 'Recovery Mode'}</span>
          </div>
          
          {!allOperational && (
            <div className="mt-2 text-xs text-muted-foreground">
              Recovery mode means we're using cached data until the system recovers. Refresh or reset to attempt recovery.
            </div>
          )}
        </div>
      )}
    </div>
  );
};
