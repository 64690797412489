import React, { useState, useEffect } from 'react';
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Activity } from "lucide-react";
import { useFinancialSettings } from "@/hooks/admin/use-financial-settings";
import { useRegionalSettings } from "@/hooks/admin/use-regional-settings";
import { ExcelViewToggle } from "@/components/admin/excel-view/ExcelViewToggle";
import { AdminExcelView } from "@/components/admin/excel-view/AdminExcelView";
import { AdminTabs } from "@/components/admin/tabs/AdminTabs";
import { toast } from "sonner";
import { RegionalSettings } from '@/types/financial';
import { convertToRegionalSettings, convertToMarketTargetSettingsRecord } from '@/utils/adapters/regional-settings-adapter';
import { marketDataSynchronizer } from '@/utils/market/sync/market-data-synchronizer';
import { fetchSystemRegionalTargets } from '@/utils/market/target-sync';

const AdminPanel = () => {
  const { toast: uiToast } = useToast();
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth() + 1);
  const [isExcelView, setIsExcelView] = useState(true); // Default to Excel view
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  
  const { settings, loading, updateSettings } = useFinancialSettings();
  const { settings: regionalSettingsData, loading: isLoading } = useRegionalSettings();

  const regionalSettings: RegionalSettings[] = regionalSettingsData?.map(setting => ({
    region: setting.region_code,
    baseTarget: setting.base_target,
    adjustment: setting.adjustment,
    adjustmentFactor: setting.adjustment_factor,
    factor: setting.adjustment_factor,
    adjustedTarget: setting.base_target + (setting.adjustment / setting.adjustment_factor)
  })) || [];

  useEffect(() => {
    if (isFirstLoad) {
      const savedViewPreference = localStorage.getItem('admin_panel_view_preference');
      if (savedViewPreference) {
        setIsExcelView(savedViewPreference === 'excel');
      }
      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  useEffect(() => {
    localStorage.setItem('admin_panel_view_preference', isExcelView ? 'excel' : 'tabs');
  }, [isExcelView]);

  const handleAction = (action: string) => {
    if (action === "Refresh") {
      if (marketDataSynchronizer && !marketDataSynchronizer.isSyncInProgress) {
        marketDataSynchronizer.triggerSync();
        toast.success("Sync triggered for market data");
        
        fetchSystemRegionalTargets().then(data => {
          if (data) {
            window.dispatchEvent(new CustomEvent('system-targets-updated', {
              detail: { targets: data, timestamp: new Date().toISOString() }
            }));
          }
        });
      } else {
        toast.info("Sync already in progress");
      }
    }
    
    uiToast({
      title: "Action triggered",
      description: `You clicked on ${action}`,
    });
  };

  useEffect(() => {
    if (marketDataSynchronizer) {
      marketDataSynchronizer.startAutoSync(30000);
      
      fetchSystemRegionalTargets().then(data => {
        if (data) {
          window.dispatchEvent(new CustomEvent('system-targets-loaded', {
            detail: { targets: data, timestamp: new Date().toISOString() }
          }));
        }
      });
      
      return () => {
        marketDataSynchronizer.stopAutoSync();
      };
    }
  }, []);
  
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      const now = new Date();
      console.info('Performing background refresh of admin panel data');
      
      window.dispatchEvent(new CustomEvent('admin-panel-background-refresh', {
        detail: { timestamp: now.toISOString() }
      }));
    }, 60 * 1000);
    
    return () => clearInterval(refreshInterval);
  }, []);

  const sampleFinancialData = {
    monthlyTotals: [
      { month: "Jan", totalDeposits: 5000, memberShare: 4250, companyShare: 750 },
      { month: "Feb", totalDeposits: 6000, memberShare: 5100, companyShare: 900 },
      { month: "Mar", totalDeposits: 4500, memberShare: 3825, companyShare: 675 }
    ],
    yearlyTotals: {
      totalDeposits: 15500,
      memberShare: 13175,
      companyShare: 2325,
      averageROI: 15.0
    }
  };

  const handleUpdateRegionalSettings = async (regionData: any) => {
    return await updateSettings(
      regionData.baseTarget,
      regionData.adjustmentFactor
    );
  };

  useEffect(() => {
    const handleTargetUpdate = (event: Event) => {
      try {
        const customEvent = event as CustomEvent;
        const { region, settings, userId, timestamp } = customEvent.detail || {};
        
        if (region && settings) {
          console.info(`Target settings updated for region ${region}`, {
            region,
            settings,
            eventType: event.type,
            timestamp
          });
        }
        
        if (userId) {
          console.info(`Member target settings updated for user ${userId}`, {
            userId,
            eventType: event.type,
            timestamp
          });
        }
      } catch (err) {
        console.error('Error handling target update event in AdminPanel', err);
      }
    };
    
    const handleBulkUpdate = (event: Event) => {
      try {
        const customEvent = event as CustomEvent;
        const { complete, regions } = customEvent.detail || {};
        
        if (complete && regions) {
          toast(`Bulk target update completed for ${regions.length} region(s)`);
          console.info('Bulk target update completed', {
            regions,
            eventType: event.type
          });
        }
      } catch (err) {
        console.error('Error handling bulk update event in AdminPanel', err);
      }
    };
    
    const targetEvents = [
      'regional-targets-updated',
      'regional-settings-updated',
      'user-targets-updated',
      'member-targets-updated',
      'market-targets-updated',
      'region-targets-updated',
      'market-sync-completed'
    ];
    
    const bulkEvents = [
      'dashboard-targets-bulk-update',
      'member-targets-synced',
      'calculator-targets-synced'
    ];
    
    targetEvents.forEach(eventType => {
      window.addEventListener(eventType, handleTargetUpdate);
    });
    
    bulkEvents.forEach(eventType => {
      window.addEventListener(eventType, handleBulkUpdate);
    });
    
    return () => {
      targetEvents.forEach(eventType => {
        window.removeEventListener(eventType, handleTargetUpdate);
      });
      
      bulkEvents.forEach(eventType => {
        window.removeEventListener(eventType, handleBulkUpdate);
      });
    };
  }, []);

  const handleSyncEvent = (event: Event) => {
    try {
      const customEvent = event as CustomEvent;
      console.info('Market sync event received in AdminPanel:', {
        type: event.type,
        detail: customEvent.detail
      });
      
      if (event.type === 'marketDataSyncComplete' && customEvent.detail?.success) {
        toast.success("Market data synchronized successfully");
      }
    } catch (err) {
      console.error('Error handling sync event in AdminPanel', err);
    }
  };

  useEffect(() => {
    const syncEvents = [
      'marketDataSync',
      'marketDataSyncComplete',
      'forceMarketDataSync'
    ];
    
    syncEvents.forEach(eventType => {
      window.addEventListener(eventType, handleSyncEvent);
    });
    
    return () => {
      syncEvents.forEach(eventType => {
        window.removeEventListener(eventType, handleSyncEvent);
      });
    };
  }, []);

  return (
    <DashboardSidebar isAdmin>
      <div className="flex-1 w-full">
        <div className="sticky top-0 z-10 border-b border-border bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex h-16 items-center gap-4 px-6">
            <h1 className="text-3xl font-bold text-primary">Admin Control Panel</h1>
            <div className="ml-auto flex gap-2 items-center">
              <ExcelViewToggle isExcelView={isExcelView} onToggleChange={setIsExcelView} />
              <Button 
                variant="outline" 
                onClick={() => handleAction("Refresh")} 
                className="gap-2 text-foreground dark:text-gray-200 bg-background/80 dark:bg-zinc-800/80 hover:bg-muted/20 dark:hover:bg-zinc-700/80"
              >
                <Activity className={`h-4 w-4 ${marketDataSynchronizer?.isSyncInProgress ? 'animate-spin' : ''}`} />
                {marketDataSynchronizer?.isSyncInProgress ? 'Syncing...' : 'Refresh Data'}
              </Button>
            </div>
          </div>
        </div>

        <div className="p-6 max-w-[1400px] mx-auto w-full">
          {isExcelView ? (
            <AdminExcelView isAdmin={true} />
          ) : (
            <AdminTabs 
              currentYear={currentYear}
              currentMonth={currentMonth}
              settings={settings}
              regionalSettings={regionalSettings}
              loading={loading}
              isLoading={isLoading}
              financialData={sampleFinancialData}
              updateSettings={updateSettings}
              handleUpdateRegionalSettings={handleUpdateRegionalSettings}
            />
          )}
        </div>
      </div>
    </DashboardSidebar>
  );
};

export default AdminPanel;
