
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { PiggyBank, MessageSquare, TrendingUp, PlusCircle, Bell, Settings } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { joinMarket } from "@/utils/market/joinMarket";
import { useAuth } from "@/contexts/AuthContext";
import { logger } from "@/utils/logger";

const QuickActions = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { session } = useAuth();
  const [isProcessing, setIsProcessing] = useState<string | null>(null);
  
  const handleAction = async (action: string) => {
    setIsProcessing(action);
    
    try {
      switch (action) {
        case "Deposit Funds":
          navigate("/dashboard/finances");
          break;
          
        case "Contact Support":
          // Open support form or chat
          toast({
            title: "Support",
            description: "Opening support chat...",
          });
          navigate("/dashboard/settings");
          break;
          
        case "View Reports":
          navigate("/dashboard/reports");
          break;
          
        case "Manage Notifications":
          toast({
            title: "Notifications",
            description: "Opening notification settings...",
          });
          navigate("/dashboard/settings");
          break;
          
        case "Settings":
          navigate("/dashboard/settings");
          break;
          
        case "New Market":
          if (session?.user?.id) {
            toast({
              title: "Creating market participation",
              description: "Please wait while we process your request...",
            });
            
            // Attempt to join the featured market (this would be dynamic in a real implementation)
            const featuredMarketId = "featured-market-123"; // This would be a real ID in production
            try {
              const result = await joinMarket(session.user.id, featuredMarketId);
              
              if (result.success) {
                toast({
                  title: "Success!",
                  description: result.alreadyParticipating 
                    ? "You're already participating in this market." 
                    : "You've joined the featured market.",
                  variant: "default",
                });
                navigate(`/dashboard/markets`);
              } else {
                toast({
                  title: "Couldn't join market",
                  description: result.error || "Please try again later",
                  variant: "destructive",
                });
              }
            } catch (error) {
              logger.error("Error joining market:", error);
              toast({
                title: "Error",
                description: "Failed to join market. Please try again later.",
                variant: "destructive",
              });
            }
          } else {
            toast({
              title: "Authentication required",
              description: "Please sign in to participate in markets",
              variant: "destructive",
            });
          }
          break;
          
        default:
          navigate("/dashboard");
      }
    } catch (error) {
      logger.error(`Error handling ${action} action:`, error);
      toast({
        title: "Error",
        description: `Could not complete ${action}. Please try again.`,
        variant: "destructive",
      });
    } finally {
      setIsProcessing(null);
    }
  };
  
  return (
    <Card className="bg-card/50 backdrop-blur-sm border-primary/10 shadow-sm hover:shadow-md transition-all">
      <CardHeader className="pb-2">
        <CardTitle className="text-lg flex items-center gap-2 text-primary">
          <PlusCircle className="h-5 w-5" />
          Quick Actions
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
          <Button 
            variant="default" 
            className="h-auto py-4 flex flex-col items-center gap-2 bg-primary/80 hover:bg-primary"
            onClick={() => handleAction("Deposit Funds")}
            disabled={isProcessing === "Deposit Funds"}
          >
            {isProcessing === "Deposit Funds" ? (
              <span className="animate-pulse">Processing...</span>
            ) : (
              <>
                <PiggyBank className="h-5 w-5" />
                <span className="text-xs font-medium">Deposit</span>
              </>
            )}
          </Button>
          
          <Button 
            variant="outline" 
            className="h-auto py-4 flex flex-col items-center gap-2 border-primary/20 hover:bg-primary/10"
            onClick={() => handleAction("Contact Support")}
            disabled={isProcessing === "Contact Support"}
          >
            {isProcessing === "Contact Support" ? (
              <span className="animate-pulse">Processing...</span>
            ) : (
              <>
                <MessageSquare className="h-5 w-5 text-primary" />
                <span className="text-xs font-medium">Support</span>
              </>
            )}
          </Button>
          
          <Button 
            variant="outline" 
            className="h-auto py-4 flex flex-col items-center gap-2 border-primary/20 hover:bg-primary/10"
            onClick={() => handleAction("View Reports")}
            disabled={isProcessing === "View Reports"}
          >
            {isProcessing === "View Reports" ? (
              <span className="animate-pulse">Processing...</span>
            ) : (
              <>
                <TrendingUp className="h-5 w-5 text-primary" />
                <span className="text-xs font-medium">Reports</span>
              </>
            )}
          </Button>
          
          <Button 
            variant="outline" 
            className="h-auto py-4 flex flex-col items-center gap-2 border-primary/20 hover:bg-primary/10"
            onClick={() => handleAction("Manage Notifications")}
            disabled={isProcessing === "Manage Notifications"}
          >
            {isProcessing === "Manage Notifications" ? (
              <span className="animate-pulse">Processing...</span>
            ) : (
              <>
                <Bell className="h-5 w-5 text-primary" />
                <span className="text-xs font-medium">Alerts</span>
              </>
            )}
          </Button>
          
          <Button 
            variant="outline" 
            className="h-auto py-4 flex flex-col items-center gap-2 border-primary/20 hover:bg-primary/10"
            onClick={() => handleAction("Settings")}
            disabled={isProcessing === "Settings"}
          >
            {isProcessing === "Settings" ? (
              <span className="animate-pulse">Processing...</span>
            ) : (
              <>
                <Settings className="h-5 w-5 text-primary" />
                <span className="text-xs font-medium">Settings</span>
              </>
            )}
          </Button>
          
          <Button 
            variant="outline" 
            className="h-auto py-4 flex flex-col items-center gap-2 bg-gradient-to-r from-green-500/20 to-blue-500/20 border-primary/20 hover:bg-primary/10"
            onClick={() => handleAction("New Market")}
            disabled={isProcessing === "New Market"}
          >
            {isProcessing === "New Market" ? (
              <span className="animate-pulse">Processing...</span>
            ) : (
              <>
                <PlusCircle className="h-5 w-5 text-primary" />
                <span className="text-xs font-medium">New Market</span>
              </>
            )}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default QuickActions;
