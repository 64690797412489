
import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { formatCurrency } from "@/utils/formatters";
import { DollarSign, TrendingUp, Users, Calculator, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
import { AppError } from "@/utils/error-handling/error-types";
import { extractErrorDetails } from "@/utils/error-handling/supabase-error";

interface FinancialStatsData {
  total_deposits: number;
  total_withdrawals: number;
  total_market_value: number;
  total_earnings: number;
  active_markets: number;
  total_members: number;
  average_roi: number;
}

interface FinancialOverviewStatsProps {
  selectedYear: number;
  selectedMonth: number;
}

export const FinancialOverviewStats: React.FC<FinancialOverviewStatsProps> = ({
  selectedYear,
  selectedMonth
}) => {
  const [stats, setStats] = useState<FinancialStatsData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const { toast } = useToast();

  const fetchFinancialStats = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      // Use direct query instead of RPC for more stability
      const { data: transactionTotals } = await supabase
        .from('transactions')
        .select('type, amount')
        .in('status', ['completed', 'approved']);
      
      const { data: marketData } = await supabase
        .from('markets')
        .select('id, status, current_value');
      
      const { data: marketParticipants } = await supabase
        .from('market_participants')
        .select('user_id, current_earnings');
      
      // Calculate stats from raw data
      if (transactionTotals && marketData && marketParticipants) {
        const deposits = transactionTotals
          .filter(t => t.type === 'deposit')
          .reduce((sum, t) => sum + (t.amount || 0), 0);
        
        const withdrawals = transactionTotals
          .filter(t => t.type === 'withdrawal')
          .reduce((sum, t) => sum + (t.amount || 0), 0);
        
        const activeMarkets = marketData.filter(m => m.status === 'active').length;
        
        const totalMarketValue = marketData.reduce((sum, m) => sum + (m.current_value || 0), 0);
        
        const totalEarnings = marketParticipants.reduce((sum, mp) => sum + (mp.current_earnings || 0), 0);
        
        // Get unique members count
        const uniqueMembers = new Set(marketParticipants.map(mp => mp.user_id)).size;
        
        // Calculate average ROI (placeholder for now)
        const averageRoi = marketData.length > 0 ? 
          (totalEarnings / totalMarketValue * 100) || 0 : 0;
        
        setStats({
          total_deposits: deposits,
          total_withdrawals: withdrawals,
          total_market_value: totalMarketValue,
          total_earnings: totalEarnings,
          active_markets: activeMarkets,
          total_members: uniqueMembers,
          average_roi: averageRoi
        });
      }
    } catch (error) {
      setError(error instanceof Error ? error : new Error(String(error)));
      
      // Use improved error feedback
      toast({
        title: "Financial data error",
        description: error instanceof AppError 
          ? error.message 
          : "Could not retrieve financial statistics. Please try again.",
        variant: "destructive",
      });
      
      // Set default values as fallback
      setStats({
        total_deposits: 0,
        total_withdrawals: 0,
        total_market_value: 0,
        total_earnings: 0,
        active_markets: 0,
        total_members: 0,
        average_roi: 0
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchFinancialStats();
  }, [fetchFinancialStats, selectedYear, selectedMonth]);

  // Retry mechanism with exponential backoff
  const handleRetry = () => {
    setRetryCount(prev => prev + 1);
    fetchFinancialStats();
  };

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
      {error && (
        <div className="md:col-span-2 lg:col-span-4 p-4 border border-red-300 bg-red-50 dark:bg-red-900/20 rounded-md mb-4 flex items-center justify-between">
          <p className="text-red-700 dark:text-red-300 font-medium">Error loading financial statistics</p>
          <Button 
            onClick={handleRetry}
            size="sm"
            variant="outline"
            className="gap-2"
            disabled={isLoading}
          >
            <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
            {isLoading ? 'Retrying...' : 'Retry'}
          </Button>
        </div>
      )}
      
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Total Deposits</CardTitle>
          <DollarSign className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <Skeleton className="h-7 w-[120px]" />
          ) : (
            <>
              <div className="text-2xl font-bold">{formatCurrency(stats?.total_deposits || 0)}</div>
              <p className="text-xs text-muted-foreground">
                Total member deposits
              </p>
            </>
          )}
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Active Markets</CardTitle>
          <TrendingUp className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <Skeleton className="h-7 w-[120px]" />
          ) : (
            <>
              <div className="text-2xl font-bold">{stats?.active_markets || 0}</div>
              <p className="text-xs text-muted-foreground">
                Currently active markets
              </p>
            </>
          )}
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Total Members</CardTitle>
          <Users className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <Skeleton className="h-7 w-[120px]" />
          ) : (
            <>
              <div className="text-2xl font-bold">{stats?.total_members || 0}</div>
              <p className="text-xs text-muted-foreground">
                Across all markets
              </p>
            </>
          )}
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Average ROI</CardTitle>
          <Calculator className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <Skeleton className="h-7 w-[120px]" />
          ) : (
            <>
              <div className="text-2xl font-bold">{(stats?.average_roi || 0).toFixed(2)}%</div>
              <p className="text-xs text-muted-foreground">
                Return on investment
              </p>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
