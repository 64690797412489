import { useRef, useEffect, useState } from "react";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { useAuth } from "@/contexts/AuthContext";
import { useMarkets } from "@/hooks/markets/use-markets-core";
import { useMarketsState } from "@/hooks/member/use-markets-state";
import { MarketOperationsStatus } from "@/components/markets/MarketOperationsStatus";
import { useAuthRedirect } from "@/hooks/auth/useAuthRedirect";
import { useMarketsRefresh } from "@/hooks/markets/use-markets-refresh";
import { useMarketsHandlers } from "@/hooks/markets/use-markets-handlers";
import { useSessionCheck } from "@/hooks/markets/use-session-check";
import MarketsAuthCheck from "@/components/markets/auth/MarketsAuthCheck";
import ImprovedMarketsErrorHandler from "@/components/markets/error/ImprovedMarketsErrorHandler";
import { MarketErrorBoundary } from "@/components/markets/error/MarketErrorBoundary";
import { useMarketsErrorState } from "@/hooks/markets/error/use-markets-error-state";
import { MarketDataProvider } from "@/providers/MarketDataProvider";
import { useMarketTabs } from "@/hooks/markets/use-market-tabs";
import { MarketErrorType } from "@/utils/error-handling/error-types";
import { useMarketsReconciliation } from "@/hooks/markets/use-markets-reconciliation";
import { logger } from "@/utils/logger";
import { ensureParticipationConsistency } from "@/utils/market/filters/data-consistency";
import { toast } from "sonner";
import { isPast } from "date-fns";
import { updateScheduledMarkets } from "@/utils/market/scheduled-market-handler";
import { MarketHeader } from "@/components/markets/header/MarketHeader";
import { TabContent } from "@/components/markets/tabs/TabContent";
import { TabTriggers } from "@/components/markets/tabs/TabTriggers";
import { filterMarkets } from "@/utils/market/filterMarkets";

const Markets = () => {
  const { session, isLoading: authLoading } = useAuth();
  const isInitialMount = useRef(true);
  const scheduledMarketsCheckRef = useRef(false);
  const closedTabRefreshedRef = useRef(false);
  
  useAuthRedirect(session, authLoading);

  const {
    error: marketStateError, 
    errorType: marketStateErrorType,
    retryCount, 
    handleRefresh
  } = useMarketsErrorState();

  const {
    searchQuery,
    selectedRegion,
    riskLevel,
    activeTab,
    setActiveTab,
    authChecked,
    setAuthChecked,
    handleSearchChange,
    handleRegionChange,
    handleRiskLevelChange,
  } = useMarketsState();

  useSessionCheck(session, authLoading, setAuthChecked);

  const { 
    data, 
    isLoading: marketsLoading, 
    error: marketError,
    refetch 
  } = useMarkets(session);
  
  const markets = data || [];
  
  const fixedMarkets = ensureParticipationConsistency(markets, session?.user?.id);

  const networkErrorType: MarketErrorType | null = 
    marketError?.name === 'NetworkError' ? 'network' : 
    marketError?.name === 'AuthError' ? 'auth' : 
    marketError ? 'unknown' : null;

  const { getParticipationCounts, participationCounts } = useMarketTabs(fixedMarkets, activeTab, setActiveTab);

  const wrappedRefetch = async (): Promise<void> => {
    logger.info('Initiating market data refresh');
    await refetch();
  };

  const { refreshMarkets } = useMarketsRefresh(wrappedRefetch);

  const {
    handleContributionSubmitted,
    handleCloseTimeUpdate,
    handleParticipate
  } = useMarketsHandlers(session, wrappedRefetch);

  const participatingMarkets = fixedMarkets.filter(m => m.isParticipating === true);
  const participatingCount = participatingMarkets.length;
  const displayedCount = participationCounts['joined'] || 0;

  const closedStatuses = [
    'closed', 'completed', 'failed', 'success', 
    'closed_successful', 'closed_failed', 'failed_final'
  ];

  const closedParticipatingMarkets = fixedMarkets.filter(m => 
    m.isParticipating === true && 
    closedStatuses.includes(m.status)
  );
  const closedCount = closedParticipatingMarkets.length;
  const displayedClosedCount = participationCounts['closed'] || 0;

  const { reconcileMarketData, isReconciling } = useMarketsReconciliation(
    session,
    activeTab, 
    participatingCount,
    displayedCount
  );

  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');

  const checkAndUpdateScheduledMarkets = async () => {
    if (scheduledMarketsCheckRef.current || !fixedMarkets || !session?.user) {
      return;
    }
    
    scheduledMarketsCheckRef.current = true;
    
    const wasUpdated = await updateScheduledMarkets(fixedMarkets);
    
    if (wasUpdated) {
      await refreshMarkets();
    }
    
    setTimeout(() => {
      scheduledMarketsCheckRef.current = false;
    }, 5 * 60 * 1000);
  };

  useEffect(() => {
    if (!isInitialMount.current && fixedMarkets.length > 0) {
      if (activeTab === 'closed' && closedCount > 0 && displayedClosedCount === 0) {
        logger.warn('Data inconsistency detected: participating markets not shown in closed tab', {
          closedCount,
          displayedClosedCount,
          timestamp: Date.now()
        });
        
        reconcileMarketData();
      }
    }
    
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, [activeTab, closedCount, displayedClosedCount, reconcileMarketData, fixedMarkets.length]);

  useEffect(() => {
    if (activeTab === 'closed' && !closedTabRefreshedRef.current) {
      closedTabRefreshedRef.current = true;
      
      logger.info('Refreshing closed markets data (one-time)', {
        closedMarketsCount: closedParticipatingMarkets.length,
        timestamp: Date.now()
      });
      
      refreshMarkets().catch(err => {
        logger.error('Error refreshing closed tab data:', err);
      });
      
      if (closedParticipatingMarkets.length > 0) {
        toast.info(`Showing ${closedParticipatingMarkets.length} closed markets`);
      }
      
      setTimeout(() => {
        closedTabRefreshedRef.current = false;
      }, 30000);
    } else if (activeTab !== 'closed') {
      closedTabRefreshedRef.current = false;
    }
  }, [activeTab, refreshMarkets, closedParticipatingMarkets.length]);

  useEffect(() => {
    if (!marketsLoading && fixedMarkets.length > 0 && session?.user) {
      checkAndUpdateScheduledMarkets();
    }
  }, [marketsLoading, fixedMarkets.length, session?.user, activeTab]);

  const handleParticipateWrapper = async (marketId: string): Promise<void> => {
    logger.info('Handling market participation request', { marketId });
    try {
      const result = await handleParticipate(marketId, fixedMarkets);
      
      if (result) {
        logger.info('Market participation successful, scheduling reconciliation');
        setTimeout(() => {
          reconcileMarketData();
        }, 2000);
      }
    } catch (error) {
      logger.error('Error in market participation:', {
        marketId,
        error: error instanceof Error ? error.message : String(error)
      });
    }
  };

  const displayError = marketError || marketStateError;
  const displayErrorType = networkErrorType || marketStateErrorType;

  const errorMetadata = {
    sessionId: session?.user?.id,
    marketCount: fixedMarkets?.length || 0,
    lastRefreshAttempt: Date.now(),
    activeTab,
    searchQuery: searchQuery || undefined,
    region: selectedRegion !== 'all' ? selectedRegion : undefined,
    riskLevel: riskLevel !== 'all' ? riskLevel : undefined,
    participatingCount,
    displayedCount,
    closedCount,
    displayedClosedCount,
    dataInconsistency: (
      (participatingCount > 0 && displayedCount === 0) || 
      (closedCount > 0 && displayedClosedCount === 0)
    )
  };

  const tabCountsAsRecord: Record<string, number> = {
    ...participationCounts
  };

  const scheduledMarketsWithPassedStartDate = fixedMarkets.filter(m => 
    m.status === 'scheduled' && 
    m.start_date && 
    isPast(new Date(m.start_date))
  );

  useEffect(() => {
    if (scheduledMarketsWithPassedStartDate.length > 0) {
      logger.info('Found scheduled markets with passed start dates that should be available:', {
        count: scheduledMarketsWithPassedStartDate.length,
        markets: scheduledMarketsWithPassedStartDate.map(m => ({
          id: m.id,
          name: m.name,
          start_date: m.start_date,
          current_time: new Date().toISOString()
        }))
      });
      
      if (activeTab === 'available' || activeTab === 'upcoming') {
        checkAndUpdateScheduledMarkets();
      }
    }
  }, [scheduledMarketsWithPassedStartDate.length, activeTab]);

  return (
    <MarketsAuthCheck session={session} authLoading={authLoading}>
      <MarketErrorBoundary onReset={() => refreshMarkets()}>
        <ImprovedMarketsErrorHandler 
          marketError={displayError} 
          retryCount={retryCount}
          refreshMarkets={refreshMarkets}
          errorType={displayErrorType}
          marketMetadata={errorMetadata}
        >
          <MarketDataProvider>
            <DashboardSidebar>
              <div className="bg-zinc-50/50 dark:bg-background min-h-screen">
                <MarketOperationsStatus />
                <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
                  <MarketHeader 
                    marketsCount={fixedMarkets.length} 
                    regionsCount={fixedMarkets ? [...new Set(fixedMarkets.map(m => m.region))].filter(Boolean).length : 0}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    onRefresh={async () => {
                      await refreshMarkets();
                      reconcileMarketData();
                      checkAndUpdateScheduledMarkets();
                    }}
                    isLoading={marketsLoading || isReconciling}
                  />
                  
                  <div className="space-y-4">
                    <div className="bg-white dark:bg-card/80 shadow-sm rounded-xl p-1.5 border border-zinc-100 dark:border-primary/10">
                      <TabTriggers 
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        participationCounts={tabCountsAsRecord}
                      />
                    </div>
                    
                    <div className="bg-white dark:bg-card/80 rounded-xl shadow-sm border border-zinc-100 dark:border-primary/10">
                      <TabContent 
                        markets={fixedMarkets}
                        filteredMarkets={filterMarkets(fixedMarkets, {
                          activeTab,
                          selectedRegion,
                          riskLevel,
                          searchQuery
                        })}
                        isLoading={marketsLoading || isReconciling}
                        activeTab={activeTab}
                        onRefresh={async () => {
                          closedTabRefreshedRef.current = false;
                          await refreshMarkets();
                          reconcileMarketData();
                          checkAndUpdateScheduledMarkets();
                        }}
                        onParticipate={handleParticipateWrapper}
                        onContributionSubmitted={handleContributionSubmitted}
                        onCloseTimeUpdate={handleCloseTimeUpdate}
                        setActiveTab={setActiveTab}
                        viewMode={viewMode}
                        value={activeTab}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DashboardSidebar>
          </MarketDataProvider>
        </ImprovedMarketsErrorHandler>
      </MarketErrorBoundary>
    </MarketsAuthCheck>
  );
};

export default Markets;
