
import React, { useState, useRef } from "react";
import { TableHead } from "@/components/ui/table";

interface ResizableTableHeaderProps extends React.HTMLAttributes<HTMLTableCellElement> {
  children: React.ReactNode;
  minWidth?: number;
}

export const ResizableTableHeader = ({ 
  children, 
  minWidth = 100,
  ...props 
}: ResizableTableHeaderProps) => {
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [isResizing, setIsResizing] = useState(false);
  const startX = useRef<number>(0);
  const startWidth = useRef<number>(0);
  
  const startResize = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsResizing(true);
    startX.current = e.clientX;
    
    // Get the current width of the th element
    const thElement = e.currentTarget.parentElement;
    if (thElement) {
      startWidth.current = thElement.getBoundingClientRect().width;
    }
    
    // Add event listeners for mouse movement and mouse up
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', stopResize);
    
    // Prevent text selection during resize
    e.preventDefault();
  };
  
  const resize = (e: MouseEvent) => {
    if (!isResizing) return;
    
    const diffX = e.clientX - startX.current;
    const newWidth = Math.max(minWidth, startWidth.current + diffX);
    setWidth(newWidth);
  };
  
  const stopResize = () => {
    setIsResizing(false);
    document.removeEventListener('mousemove', resize);
    document.removeEventListener('mouseup', stopResize);
  };
  
  return (
    <TableHead
      style={{ 
        width: width ? `${width}px` : undefined,
        position: 'relative',
        userSelect: isResizing ? 'none' : 'auto',
        cursor: isResizing ? 'col-resize' : 'default'
      }}
      className="font-medium text-zinc-800 dark:text-gray-200 bg-excel-header-light dark:bg-muted/10 transition-colors"
      {...props}
    >
      {children}
      <div
        className="absolute top-0 right-0 h-full w-2 cursor-col-resize opacity-0 hover:opacity-100 hover:bg-primary/10"
        onMouseDown={startResize}
      />
    </TableHead>
  );
};
