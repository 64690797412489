
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";

// Define a type for metadata to avoid spread issues
interface ParticipantMetadata {
  base_target?: number;
  adjustment?: number;
  adjustment_factor?: number;
  target_value?: number;
  [key: string]: any; // Allow for other properties
}

// Get all regional settings from the database
export const getAllRegionalSettings = async () => {
  try {
    const { data, error } = await supabase
      .from('regional_settings')
      .select('*');
      
    if (error) throw error;
    
    // Convert to a map for easier access
    const settingsMap: Record<string, any> = {};
    if (data) {
      for (const setting of data) {
        settingsMap[setting.region_code.toLowerCase()] = setting;
      }
    }
    
    return settingsMap;
  } catch (error) {
    logger.error('Error fetching regional settings:', error);
    throw error;
  }
};

// Propagate regional targets to all users in a region
export const propagateRegionalTargets = async (
  region: string,
  baseTarget: number,
  adjustment: number,
  factor: number,
  updateGlobalSettings: boolean = false
) => {
  try {
    const calculatedTarget = baseTarget + (adjustment / factor);
    
    // First update the global settings if required
    if (updateGlobalSettings) {
      const { error: settingsError } = await supabase
        .from('regional_settings')
        .upsert({
          region_code: region,
          base_target: baseTarget,
          adjustment: adjustment,
          adjustment_factor: factor,
          target_value: calculatedTarget,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'region_code'
        });
        
      if (settingsError) {
        logger.error('Error updating regional settings:', settingsError);
        throw settingsError;
      }
      
      // Dispatch event for global settings update
      window.dispatchEvent(new CustomEvent('regional-settings-updated', {
        detail: {
          region,
          settings: {
            baseTarget,
            adjustment,
            factor
          },
          timestamp: new Date().toISOString()
        }
      }));
    }
    
    // Get all users who have an active profile
    const { data: users, error: usersError } = await supabase
      .from('profiles')
      .select('id')
      .eq('status', 'active');
      
    if (usersError) {
      logger.error('Error fetching users:', usersError);
      throw usersError;
    }
    
    if (!users || users.length === 0) {
      logger.warn('No active users found to update');
      return true;
    }
    
    // Update regional_targets table for all users
    for (const user of users) {
      const { error: targetError } = await supabase
        .from('regional_targets')
        .upsert({
          user_id: user.id,
          region_code: region,
          base_target: baseTarget,
          adjustment: adjustment,
          factor: factor,
          target_value: calculatedTarget,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,region_code'
        });
        
      if (targetError) {
        logger.error(`Error updating target for user ${user.id}:`, targetError);
        // Continue with other users even if one fails
        continue;
      }
    }
    
    // Update market participants for this region
    const { data: markets, error: marketsError } = await supabase
      .from('markets')
      .select('id')
      .eq('region', region);
      
    if (marketsError) {
      logger.error('Error fetching markets for region:', marketsError);
      throw marketsError;
    }
    
    if (markets && markets.length > 0) {
      const marketIds = markets.map(m => m.id);
      
      // Get all participations for these markets
      const { data: participations, error: partError } = await supabase
        .from('market_participants')
        .select('id, user_id, metadata')
        .eq('status', 'active')
        .in('market_id', marketIds);
        
      if (partError) {
        logger.error('Error fetching market participations:', partError);
        throw partError;
      }
      
      if (participations && participations.length > 0) {
        // Update each participation
        for (const participation of participations) {
          // Properly handle metadata with null checking and type assertion
          const currentMetadata = participation.metadata || {} as ParticipantMetadata;
          const updatedMetadata = {
            ...(typeof currentMetadata === 'object' ? currentMetadata : {}),
            base_target: baseTarget,
            adjustment: adjustment,
            adjustment_factor: factor,
            target_value: calculatedTarget
          };
          
          const { error: updateError } = await supabase
            .from('market_participants')
            .update({ 
              metadata: updatedMetadata,
              updated_at: new Date().toISOString()
            })
            .eq('id', participation.id);
            
          if (updateError) {
            logger.error(`Error updating participant ${participation.id}:`, updateError);
            // Continue with others even if this one fails
            continue;
          }
          
          // Dispatch event for this specific user
          window.dispatchEvent(new CustomEvent('user-targets-updated', {
            detail: {
              userId: participation.user_id,
              region,
              marketId: null,
              settings: {
                baseTarget,
                adjustment,
                factor
              },
              timestamp: new Date().toISOString()
            }
          }));
        }
      }
    }
    
    // Dispatch a bulk update completion event
    window.dispatchEvent(new CustomEvent('region-targets-updated', {
      detail: {
        region,
        updateType: 'bulk',
        userCount: users.length,
        settings: {
          baseTarget,
          adjustment,
          factor,
          calculatedTarget
        },
        timestamp: new Date().toISOString()
      }
    }));
    
    return true;
  } catch (error) {
    logger.error('Error propagating regional targets:', error);
    throw error;
  }
};

// Calculate an adjusted target value
export const calculateAdjustedTarget = (
  baseTarget: number,
  adjustment: number,
  factor: number
): number => {
  if (factor <= 0) return 0;
  return Math.round((baseTarget + (adjustment / factor)) * 100) / 100;
};

// Get regional settings for a specific market
export const getMarketRegionalSettings = async (marketId: string) => {
  try {
    // First get the market to determine its region
    const { data: market, error: marketError } = await supabase
      .from('markets')
      .select('region')
      .eq('id', marketId)
      .single();
      
    if (marketError) throw marketError;
    
    if (!market) {
      throw new Error('Market not found');
    }
    
    // Now get the regional settings for this region
    const { data: settings, error: settingsError } = await supabase
      .from('regional_settings')
      .select('*')
      .eq('region_code', market.region)
      .single();
      
    if (settingsError && settingsError.code !== 'PGRST116') { // Ignore not found error
      throw settingsError;
    }
    
    if (!settings) {
      // Return default settings if none found
      return {
        region_code: market.region,
        base_target: 5000,
        adjustment: 500,
        adjustment_factor: 1.07,
        target_value: 5467.29
      };
    }
    
    return settings;
  } catch (error) {
    logger.error('Error fetching market regional settings:', error);
    throw error;
  }
};
