
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface MarketTargetInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const MarketTargetInput = ({ value, onChange }: MarketTargetInputProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="dailyTarget">Market Target Amount</Label>
      <Input
        id="dailyTarget"
        type="number"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter target amount"
        min="0"
        step="0.01"
      />
      <p className="text-xs text-muted-foreground">
        Base target amount for market calculations
      </p>
    </div>
  );
};
