
import { logger } from "@/utils/logger";

interface ProgressiveCalculationResult {
  success: boolean;
  contribution?: number;
  requiredContribution?: number;
  message?: string;
  previousFailedTotal?: number;
  odds?: number;
  willSucceed?: boolean;
}

/**
 * Calculates the contribution amount based on progressive loss recovery model with odds
 * 
 * @param adjustmentFactor The market's adjustment factor (default: 1.07)
 * @param targetValue The market's target value
 * @param inputOdds The odds provided by the user
 * @param previousFailedContributions Array of previous failed contribution amounts
 * @returns A ProgressiveCalculationResult object with success status and contribution details
 */
export const calculateProgressiveContribution = async (
  adjustmentFactor: number = 1.07,
  targetValue: number,
  inputOdds: number,
  inputValue: number = 0, // Kept for backward compatibility, not used in new calculation
  previousFailedContributions: number[] = []
): Promise<ProgressiveCalculationResult> => {
  try {
    logger.info('Calculating progressive contribution with odds', {
      adjustmentFactor,
      targetValue,
      inputOdds,
      previousFailedContributions
    });

    // Basic validation
    if (!inputOdds || isNaN(inputOdds) || inputOdds <= 0) {
      return {
        success: false,
        message: 'Please enter valid odds (greater than 0)'
      };
    }

    if (!adjustmentFactor || isNaN(adjustmentFactor) || adjustmentFactor <= 0) {
      adjustmentFactor = 1.07; // Default fallback
      logger.warn('Invalid adjustment factor, using default', { adjustmentFactor });
    }

    if (!targetValue || isNaN(targetValue) || targetValue <= 0) {
      return {
        success: false,
        message: 'Invalid target value for market'
      };
    }

    // Calculate total of previous failed contributions
    const previousFailedTotal = previousFailedContributions.reduce((total, amount) => total + amount, 0);
    
    // Calculate required contribution using progressive formula WITH ODDS:
    // required_contribution = ((targetValue + sum_of_previous_failed_contributions) / odds) * adjustmentFactor
    const requiredContribution = Math.round(((targetValue + previousFailedTotal) / inputOdds) * adjustmentFactor * 100) / 100;
    
    // In the new model, the calculated contribution is the same as the required contribution
    // The user no longer provides an input value, we calculate it for them
    const calculatedContribution = requiredContribution;
    
    // Check if this contribution will succeed (meets or exceeds the target value)
    const willSucceed = calculatedContribution >= targetValue;
    
    // For the calculation stage, we're returning success: true because the calculation itself succeeded,
    // not because the contribution will necessarily be successful
    // The actual success/failure will be determined during submission based on this calculation

    logger.info('Progressive contribution calculated with odds', {
      calculatedContribution,
      requiredContribution,
      inputOdds,
      previousFailedTotal,
      willSucceed,
      calculationSuccess: true
    });

    return {
      success: true,
      contribution: calculatedContribution,
      requiredContribution: requiredContribution,
      previousFailedTotal: previousFailedTotal,
      odds: inputOdds,
      willSucceed: willSucceed,
      message: 'Contribution calculation completed successfully'
    };
  } catch (error) {
    const err = error instanceof Error ? error : new Error(String(error));
    
    logger.error('Error calculating progressive contribution with odds', {
      error: err.message,
      stack: err.stack,
      inputOdds,
      adjustmentFactor,
      targetValue
    });
    
    return {
      success: false,
      message: 'An error occurred while calculating contribution'
    };
  }
};

/**
 * Helper function to get previous failed contributions for a market and user
 * 
 * @param marketId The market ID
 * @param userId The user ID
 * @returns Array of previous failed contribution amounts
 */
export const getPreviousFailedContributions = async (
  marketId: string,
  userId: string
): Promise<number[]> => {
  try {
    const { supabase } = await import('@/lib/supabase');
    
    const { data, error } = await supabase
      .from('market_contributions')
      .select('calculated_contribution')
      .eq('market_id', marketId)
      .eq('user_id', userId)
      .eq('status', 'failed')
      .order('contribution_round', { ascending: true });
    
    if (error) {
      logger.error('Error fetching previous failed contributions', {
        error: error.message,
        marketId,
        userId
      });
      return [];
    }
    
    return (data || []).map(item => Number(item.calculated_contribution) || 0);
  } catch (error) {
    logger.error('Exception fetching previous failed contributions', error);
    return [];
  }
};
