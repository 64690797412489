
import { logger } from "@/utils/logger";
import { marketJoinService } from "./participation/market-join-service";

export interface JoinMarketResult {
  success: boolean;
  error?: string | null;
  participationId?: string;
  alreadyParticipating?: boolean;
  needsContribution?: boolean;
  status?: string;
  approvalStatus?: string;
}

export interface CustomMarketSettings {
  targetEarnings?: number;
  closeTime?: string;
}

/**
 * Join a market - This function now always forwards requests to Make.com webhook
 * with minimal validation - ensuring requests are never blocked on the client
 */
export const joinMarket = async (
  userId: string,
  marketId: string,
  customSettings?: CustomMarketSettings
): Promise<JoinMarketResult> => {
  try {
    // Basic validation of required parameters
    if (!userId || !marketId) {
      logger.error('Invalid parameters for joinMarket', {
        hasUserId: !!userId,
        hasMarketId: !!marketId
      });
      return { 
        success: false, 
        error: 'Missing required parameters: userId and marketId must be provided' 
      };
    }

    logger.info('Always forwarding market join request to Make.com webhook:', {
      marketId,
      userId,
      hasCustomSettings: !!customSettings
    });

    // Always call our centralized join service with skipValidation=true
    // This ensures the request always gets sent to Make.com webhook
    const result = await marketJoinService.joinMarket({
      userId,
      marketId,
      customSettings,
      waitForCallback: false,
      skipValidation: true  // Skip all validation and always send to webhook
    });
    
    if (!result.success) {
      logger.error('Join market request failed:', {
        marketId,
        error: result.error
      });
      
      return {
        success: false,
        error: result.error || 'Failed to send join market request'
      };
    }
    
    // For consistency with existing code, maintain expected return format
    if (result.alreadyParticipating) {
      return { 
        success: true, 
        alreadyParticipating: true,
        participationId: result.participationId,
        approvalStatus: result.approvalStatus
      };
    }

    logger.info('Join market request sent successfully:', {
      marketId,
      participationId: result.participationId
    });
    
    return {
      success: true,
      participationId: result.participationId,
      status: 'pending',
      approvalStatus: result.approvalStatus
    };
  } catch (error) {
    logger.error('Exception in joinMarket:', error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : String(error) 
    };
  }
};
