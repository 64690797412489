
import { useMemo } from "react";
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, 
  Tooltip, Legend, ResponsiveContainer, BarChart, Bar
} from "recharts";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";

interface Market {
  id: string;
  name: string;
  current_value: number;
  target_value: number;
  growth_rate: number;
  status: string;
  region?: string;
}

interface MarketPerformanceChartProps {
  markets: Market[];
  totalValue: number;
  growthRate: number;
}

export const MarketPerformanceChart = ({ 
  markets, 
  totalValue,
  growthRate 
}: MarketPerformanceChartProps) => {
  // Generate regional performance data
  const regionalData = useMemo(() => {
    const regions = Array.from(new Set(markets.map(m => m.region || 'Unknown')));
    
    return regions.map(region => {
      const regionMarkets = markets.filter(m => (m.region || 'Unknown') === region);
      const totalValue = regionMarkets.reduce((sum, m) => sum + m.current_value, 0);
      const avgGrowth = regionMarkets.length 
        ? regionMarkets.reduce((sum, m) => sum + m.growth_rate, 0) / regionMarkets.length 
        : 0;
      
      return {
        region,
        totalValue,
        avgGrowth,
        marketCount: regionMarkets.length,
      };
    }).sort((a, b) => b.totalValue - a.totalValue); // Sort by total value
  }, [markets]);

  // Create data for status distribution
  const statusData = useMemo(() => {
    const statuses = ['active', 'completed', 'pending', 'failed'];
    
    return statuses.map(status => {
      const count = markets.filter(m => m.status === status).length;
      return {
        status: status.charAt(0).toUpperCase() + status.slice(1),
        count,
      };
    });
  }, [markets]);

  // Generate monthly performance data
  const monthlyData = useMemo(() => {
    // Create synthetic monthly data based on current values and growth rates
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];
    
    return months.map((month, index) => {
      const factor = 0.7 + (index * 0.05); // Synthetic growth factor
      return {
        month,
        value: totalValue * factor,
        growth: growthRate * (1 + (index * 0.1)),
      };
    });
  }, [totalValue, growthRate]);

  const config = {
    value: { label: "Value" },
    growth: { label: "Growth" },
    totalValue: { label: "Total Value" },
    avgGrowth: { label: "Avg Growth" },
    count: { label: "Count" }
  };

  return (
    <div className="space-y-4">
      <Tabs defaultValue="regional" className="w-full">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="regional">Regional Performance</TabsTrigger>
          <TabsTrigger value="monthly">Monthly Trends</TabsTrigger>
          <TabsTrigger value="status">Status Distribution</TabsTrigger>
        </TabsList>
        
        <TabsContent value="regional" className="pt-4">
          <div className="h-80">
            <ChartContainer config={config}>
              <BarChart
                data={regionalData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                <XAxis dataKey="region" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <ChartTooltip content={<ChartTooltipContent />} />
                <Legend />
                <Bar yAxisId="left" dataKey="totalValue" name="Total Value" fill="#8884d8" />
                <Bar yAxisId="right" dataKey="marketCount" name="Market Count" fill="#82ca9d" />
              </BarChart>
            </ChartContainer>
          </div>
        </TabsContent>
        
        <TabsContent value="monthly" className="pt-4">
          <div className="h-80">
            <ChartContainer config={config}>
              <LineChart
                data={monthlyData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                <XAxis dataKey="month" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <ChartTooltip content={<ChartTooltipContent />} />
                <Legend />
                <Line 
                  yAxisId="left" 
                  type="monotone" 
                  dataKey="value" 
                  name="Value" 
                  stroke="#8884d8" 
                  activeDot={{ r: 8 }} 
                />
                <Line 
                  yAxisId="right" 
                  type="monotone" 
                  dataKey="growth" 
                  name="Growth %" 
                  stroke="#82ca9d" 
                />
              </LineChart>
            </ChartContainer>
          </div>
        </TabsContent>
        
        <TabsContent value="status" className="pt-4">
          <div className="h-80">
            <ChartContainer config={config}>
              <BarChart
                data={statusData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                <XAxis dataKey="status" />
                <YAxis />
                <ChartTooltip content={<ChartTooltipContent />} />
                <Legend />
                <Bar 
                  dataKey="count" 
                  name="Market Count" 
                  fill="#10b981" 
                  radius={[4, 4, 0, 0]} 
                />
              </BarChart>
            </ChartContainer>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};
