
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import { Clock, AlertCircle, CheckCircle, XCircle, LogOut } from "lucide-react";
import { logger } from "@/utils/logger";

const WaitingList = () => {
  const [status, setStatus] = useState<string>("waiting");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const checkStatus = async () => {
      setIsLoading(true);
      
      try {
        // First check if we have a session
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!session) {
          logger.info('No session found, redirecting to signin');
          navigate("/signin");
          return;
        }

        // Check if the user is an admin (they shouldn't be on the waiting list)
        try {
          const { data: isAdmin, error: adminError } = await supabase.rpc('is_admin_email_direct');
          
          if (adminError) {
            logger.error('Error checking admin status:', adminError);
          } else if (isAdmin) {
            logger.info('Admin user detected, redirecting to admin');
            navigate("/admin");
            return;
          }
        } catch (error) {
          logger.error("Error checking admin status:", error);
        }

        // Get profile status
        const { data: profile, error } = await supabase
          .from('profiles')
          .select('status')
          .eq('id', session.user.id)
          .maybeSingle(); // Use maybeSingle to handle missing profiles

        if (error) {
          logger.error('Error fetching profile status:', error);
          toast({
            variant: "destructive",
            title: "Error",
            description: "Could not fetch registration status",
          });
          setStatus('waiting'); // Default to waiting
        } else if (!profile) {
          // If profile doesn't exist, create one with waiting status
          logger.info('Profile not found, creating with waiting status');
          
          try {
            const { error: insertError } = await supabase
              .from('profiles')
              .insert({
                id: session.user.id,
                email: session.user.email,
                first_name: session.user.user_metadata?.first_name || '',
                last_name: session.user.user_metadata?.last_name || '',
                status: 'waiting'
              });
              
            if (insertError) {
              logger.error('Error creating profile:', insertError);
            }
            
            setStatus('waiting');
          } catch (insertErr) {
            logger.error('Exception creating profile:', insertErr);
            setStatus('waiting');
          }
        } else if (profile.status === 'active') {
          logger.info('User is active, redirecting to dashboard');
          navigate("/dashboard");
          return;
        } else {
          setStatus(profile.status);
        }
      } catch (err) {
        logger.error('Exception in checkStatus:', err);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to check account status",
        });
        setStatus('waiting'); // Default to waiting on error
      } finally {
        setIsLoading(false);
      }
    };

    checkStatus();
  }, [navigate, toast]);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/signin');
    } catch (error) {
      logger.error('Sign out error:', error);
      toast({
        variant: "destructive",
        title: "Sign Out Failed",
        description: "There was an error signing out. Please try again.",
      });
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  const getStatusDisplay = () => {
    switch (status) {
      case 'waiting':
      case 'pending':
        return {
          icon: <Clock className="w-12 h-12 text-yellow-500" />,
          title: "Application Under Review",
          description: "Your registration is being reviewed by our team. We'll notify you via email once a decision has been made."
        };
      case 'rejected':
        return {
          icon: <XCircle className="w-12 h-12 text-red-500" />,
          title: "Application Rejected",
          description: "Unfortunately, your registration has been rejected. Please contact support for more information."
        };
      case 'active':
        return {
          icon: <CheckCircle className="w-12 h-12 text-green-500" />,
          title: "Application Approved",
          description: "Your registration has been approved! You can now sign in to access your account."
        };
      default:
        return {
          icon: <AlertCircle className="w-12 h-12 text-gray-500" />,
          title: "Status Unknown",
          description: "There was an error fetching your registration status. Please try again later."
        };
    }
  };

  const statusInfo = getStatusDisplay();

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-md mx-auto">
        <CardHeader className="text-center">
          <div className="flex justify-center mb-4">
            {statusInfo.icon}
          </div>
          <CardTitle className="text-2xl font-bold">
            {statusInfo.title}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-center text-muted-foreground mb-6">
            {statusInfo.description}
          </p>
          
          <div className="flex justify-center">
            <Button 
              variant="outline" 
              onClick={handleSignOut}
              className="flex items-center gap-2"
            >
              <LogOut className="h-4 w-4" />
              Sign Out
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default WaitingList;
