
import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Bell, Send } from "lucide-react";
import { Market } from "@/types/market";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";

interface NotificationsTabProps {
  market: Market;
}

export const NotificationsTab = ({ market }: NotificationsTabProps) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({
    deposit_reminders: true,
    low_participation_alerts: true,
    performance_updates: true
  });
  const { toast } = useToast();

  const handleSendNotification = async () => {
    if (!message.trim()) return;

    setLoading(true);
    try {
      const { error } = await supabase
        .from('market_notifications')
        .insert({
          market_id: market.id,
          type: 'admin_message',
          title: 'Admin Notification',
          content: message,
          status: 'pending'
        });

      if (error) throw error;

      toast({
        title: "Notification Sent",
        description: "Your message has been queued for delivery.",
      });

      setMessage("");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send notification.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Send Notification</h3>
        <div className="space-y-4">
          <Textarea
            placeholder="Type your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="min-h-[120px]"
          />
          <Button
            onClick={handleSendNotification}
            disabled={!message.trim() || loading}
            className="w-full gap-2"
          >
            <Send className="h-4 w-4" />
            Send to All Members
          </Button>
        </div>
      </Card>

      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4">Notification Settings</h3>
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <Label>Daily Deposit Reminders</Label>
              <p className="text-sm text-muted-foreground">
                Remind members about their daily deposits
              </p>
            </div>
            <Switch
              checked={settings.deposit_reminders}
              onCheckedChange={(checked) => 
                setSettings(prev => ({ ...prev, deposit_reminders: checked }))
              }
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <Label>Low Participation Alerts</Label>
              <p className="text-sm text-muted-foreground">
                Alert when market participation drops
              </p>
            </div>
            <Switch
              checked={settings.low_participation_alerts}
              onCheckedChange={(checked) => 
                setSettings(prev => ({ ...prev, low_participation_alerts: checked }))
              }
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="space-y-0.5">
              <Label>Performance Updates</Label>
              <p className="text-sm text-muted-foreground">
                Send periodic performance reports
              </p>
            </div>
            <Switch
              checked={settings.performance_updates}
              onCheckedChange={(checked) => 
                setSettings(prev => ({ ...prev, performance_updates: checked }))
              }
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
