
import { Market } from "@/types/market";
import { supabase } from "@/lib/supabase";
import { toast } from "sonner";
import { logger } from "@/utils/logger";
import { useQueryInvalidation } from "./use-query-invalidation";

/**
 * Hook for handling market status update requests
 * Includes validation to ensure status changes follow business rules
 */
export const useStatusUpdate = (marketId: string, onUpdate: () => void) => {
  const { invalidateMarketQueries } = useQueryInvalidation();

  const handleStatusUpdate = async (
    newStatus: Market['status'], 
    loading: boolean,
    setLoading: (loading: boolean) => void
  ) => {
    if (loading) return;

    try {
      setLoading(true);
      
      logger.info('Triggering market status update request', { 
        marketId, 
        requestedStatus: newStatus 
      });
      
      // Verify admin status
      const { data: isAdmin, error: adminCheckError } = await supabase.rpc('is_admin_email_direct');
      
      if (adminCheckError) {
        logger.error('Admin check error:', adminCheckError);
        throw new Error('Failed to verify admin privileges');
      }
      
      if (!isAdmin) {
        throw new Error('Only administrators can request market status updates');
      }
      
      // Get market data to validate status transition
      const { data: marketData, error: marketError } = await supabase
        .from('markets')
        .select('target_value, current_value, close_time, status')
        .eq('id', marketId)
        .single();
        
      if (marketError) {
        throw new Error('Failed to fetch market data for validation');
      }
      
      // ENHANCED VALIDATION: Check pending contributions before status change
      if (['closed_successful', 'closed_failed'].includes(newStatus)) {
        // Check for any pending_contribution participants
        const { count: pendingCount, error: pendingError } = await supabase
          .from('market_participants')
          .select('*', { count: 'exact', head: true })
          .eq('market_id', marketId)
          .eq('status', 'pending_contribution');
          
        if (pendingError) {
          logger.error('Error checking pending contributions:', pendingError);
        } else if (pendingCount && pendingCount > 0) {
          throw new Error(`Cannot close market: ${pendingCount} participants have pending contributions`);
        }
      }
      
      // Perform client-side validation for status transitions
      if (['closed_successful', 'closed_failed'].includes(newStatus)) {
        // Check if close_time has passed
        if (marketData.close_time && new Date(marketData.close_time) > new Date()) {
          throw new Error('Cannot close market before scheduled close time');
        }
        
        // For success status, ensure target is met
        if (newStatus === 'closed_successful' && 
            marketData.current_value < marketData.target_value) {
          throw new Error('Cannot mark market as successful: target not met');
        }
        
        // For failed status, ensure target is NOT met (if there's value)
        if (newStatus === 'closed_failed' && 
            marketData.current_value > 0 && 
            marketData.current_value >= marketData.target_value) {
          throw new Error('Cannot mark market as failed: target has been met');
        }
      }
      
      // Call the controlled function to update market status
      const { error: updateError } = await supabase
        .rpc('update_market_status_controlled', {
          market_id_param: marketId,
          new_status: newStatus,
          source: 'Admin',
          admin_id: await supabase.auth.getUser().then(res => res.data.user?.id)
        });
        
      if (updateError) {
        logger.error('Failed to update market status:', {
          error: updateError,
          message: updateError.message
        });
        throw new Error(updateError.message || 'Failed to update market status');
      }

      // Ensure we invalidate all relevant queries
      await invalidateMarketQueries(marketId);
      
      toast.success(`Market status changed to ${newStatus} successfully`);

      // Call the onUpdate callback to refresh the parent component
      onUpdate();
    } catch (error) {
      const err = error as Error;
      logger.error('Failed to request market status update:', { 
        error: err, 
        marketId,
        attemptedStatus: newStatus
      });
      
      toast.error(err.message || "Failed to request market status update");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { handleStatusUpdate };
};
