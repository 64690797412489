
import React from "react";
import { format, getDaysInMonth, isSameDay, isWeekend } from "date-fns";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DailyFinancialRecord as DailyFinancialRecordType, calculateCommissionAmount } from "@/utils/financials/member-financials";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { Edit, AlertCircle, Info } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";

interface DailyFinancialCalendarProps {
  records: DailyFinancialRecordType[];
  month: number;
  year: number;
  userId: string;
  onRecordUpdated: () => void;
  isCurrentMonth?: boolean;
  memberSharePercentage: number;
}

export const DailyFinancialCalendar: React.FC<DailyFinancialCalendarProps> = ({
  records,
  month,
  year,
  userId,
  onRecordUpdated,
  isCurrentMonth = true,
  memberSharePercentage = 15
}) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [editRecord, setEditRecord] = React.useState<DailyFinancialRecordType | null>(null);
  const [bankDeposit, setBankDeposit] = React.useState<number>(0);
  const [closingBalance, setClosingBalance] = React.useState<number>(0);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [companyShareAmount, setCompanyShareAmount] = React.useState<number>(0);
  
  const daysInMonth = getDaysInMonth(new Date(year, month - 1));
  
  const calendarDays = Array.from({ length: daysInMonth }, (_, i) => {
    const day = i + 1;
    const date = new Date(year, month - 1, day);
    const dayOfWeek = date.getDay();
    
    const record = records.find(r => {
      const recordDate = new Date(r.record_date);
      return isSameDay(date, recordDate);
    });
    
    return { day, date, dayOfWeek, record };
  });
  
  const calendarWeeks: Array<typeof calendarDays> = [];
  let currentWeek: typeof calendarDays = [];
  
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay();
  for (let i = 0; i < firstDayOfMonth; i++) {
    currentWeek.push({ day: 0, date: new Date(), dayOfWeek: i, record: null });
  }
  
  calendarDays.forEach((dayInfo) => {
    if (currentWeek.length === 7) {
      calendarWeeks.push([...currentWeek]);
      currentWeek = [];
    }
    currentWeek.push(dayInfo);
  });
  
  while (currentWeek.length < 7) {
    currentWeek.push({ day: 0, date: new Date(), dayOfWeek: currentWeek.length, record: null });
  }
  
  if (currentWeek.length > 0) {
    calendarWeeks.push([...currentWeek]);
  }
  
  const handleSelectDate = (dayInfo: typeof calendarDays[0]) => {
    if (dayInfo.day === 0) return;
    
    setSelectedDate(dayInfo.date);
    setEditRecord(dayInfo.record || null);
    
    if (dayInfo.record) {
      setBankDeposit(dayInfo.record.bank_deposit || 0);
      setClosingBalance(dayInfo.record.closing_balance);
      
      const calculatedCommission = calculateCommissionAmount(dayInfo.record.bank_deposit || 0, memberSharePercentage);
      setCompanyShareAmount(calculatedCommission);
    } else {
      setBankDeposit(0);
      setClosingBalance(0);
      setCompanyShareAmount(0);
    }
  };
  
  React.useEffect(() => {
    const calculatedCommission = calculateCommissionAmount(bankDeposit, memberSharePercentage);
    setCompanyShareAmount(calculatedCommission);
  }, [bankDeposit, memberSharePercentage]);
  
  const handleUpdateRecord = async () => {
    if (!selectedDate || isUpdating) return;
    
    try {
      setIsUpdating(true);
      
      const { updateDailyDeposit } = await import('@/utils/financials/member-financials');
      await updateDailyDeposit(userId, selectedDate, bankDeposit, closingBalance);
      
      toast({
        title: "Record updated",
        description: `Financial record for ${format(selectedDate, 'MMM d, yyyy')} has been updated.`,
      });

      if (bankDeposit > 0 && companyShareAmount > 0) {
        toast({
          title: "Deposit Required",
          description: (
            <div className="flex flex-col space-y-2">
              <p>Please deposit {formatCurrency(companyShareAmount)} to the company account.</p>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => navigate('/dashboard/transactions')}
                className="mt-1 w-full"
              >
                Go to Deposit Page
              </Button>
            </div>
          ),
          variant: "warning",
          duration: 10000,
        });
      }
      
      onRecordUpdated();
      setSelectedDate(null);
      setEditRecord(null);
    } catch (error) {
      console.error("Error updating record:", error);
      toast({
        title: "Update failed",
        description: "Could not update the financial record. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsUpdating(false);
    }
  };
  
  const formatCurrency = (amount: number | undefined): string => {
    if (amount === undefined) return 'R0.00';
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };
  
  const isInFuture = (date: Date) => {
    return isCurrentMonth && date > new Date();
  };
  
  const isDayEditable = (dayInfo: typeof calendarDays[0]) => {
    if (dayInfo.day === 0) return false;
    return !isInFuture(dayInfo.date);
  };
  
  return (
    <TooltipProvider>
      <Card className="border-zinc-200 dark:border-zinc-700 bg-white dark:bg-card shadow-sm">
        <CardHeader className="pb-3">
          <CardTitle className="text-lg text-zinc-800 dark:text-zinc-200">
            Daily Financial Records - {format(new Date(year, month - 1, 1), 'MMMM yyyy')}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-7 gap-1 mb-2">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
              <div key={day} className="text-center font-medium text-sm py-1 text-zinc-700 dark:text-zinc-300">
                {day}
              </div>
            ))}
          </div>
          
          <div className="space-y-1">
            {calendarWeeks.map((week, weekIndex) => (
              <div key={`week-${weekIndex}`} className="grid grid-cols-7 gap-1">
                {week.map((dayInfo, dayIndex) => {
                  if (dayInfo.day === 0) {
                    return <div key={`empty-${weekIndex}-${dayIndex}`} className="aspect-square"></div>;
                  }
                  
                  const isWeekendDay = isWeekend(dayInfo.date);
                  const isToday = isSameDay(dayInfo.date, new Date());
                  const isFuture = isInFuture(dayInfo.date);
                  const hasDeposit = dayInfo.record && dayInfo.record.bank_deposit && dayInfo.record.bank_deposit > 0;
                  
                  return (
                    <Tooltip key={`day-${dayInfo.day}`}>
                      <TooltipTrigger asChild>
                        <div 
                          className={`
                            aspect-square border rounded p-1 cursor-pointer transition-colors
                            ${isToday ? 'border-primary' : 'border-zinc-200 dark:border-zinc-700'}
                            ${isFuture ? 'opacity-50 bg-zinc-100 dark:bg-zinc-800/50' : 'hover:bg-zinc-100 dark:hover:bg-zinc-800/70'}
                            ${isWeekendDay ? 'bg-zinc-50 dark:bg-zinc-800/30' : 'bg-white dark:bg-zinc-900/20'}
                            ${hasDeposit ? 'ring-1 ring-primary/70' : ''}
                          `}
                          onClick={() => handleSelectDate(dayInfo)}
                        >
                          <div className="flex flex-col h-full">
                            <div className={`
                              text-right text-xs 
                              ${isToday ? 'font-bold text-primary' : 'text-zinc-700 dark:text-zinc-300'}
                            `}>
                              {dayInfo.day}
                            </div>
                            
                            {dayInfo.record && (
                              <div className="flex-1 flex flex-col justify-center items-center text-center">
                                {hasDeposit && (
                                  <div className="text-xs truncate font-medium text-primary">
                                    {formatCurrency(dayInfo.record.bank_deposit)}
                                  </div>
                                )}
                                <div className="text-xs truncate text-zinc-600 dark:text-zinc-400">
                                  {formatCurrency(dayInfo.record.closing_balance)}
                                </div>
                              </div>
                            )}
                            
                            {isDayEditable(dayInfo) && (
                              <div className="text-xs text-center mt-auto">
                                <Edit className="h-3 w-3 mx-auto text-zinc-500 dark:text-zinc-500 opacity-60" />
                              </div>
                            )}
                          </div>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
                        <p className="text-zinc-800 dark:text-zinc-200">{format(dayInfo.date, 'EEEE, MMMM d')}</p>
                        {dayInfo.record && (
                          <>
                            <p className="text-zinc-700 dark:text-zinc-300">Opening: {formatCurrency(dayInfo.record.opening_balance)}</p>
                            <p className="text-zinc-700 dark:text-zinc-300">Bank: {formatCurrency(dayInfo.record.bank_deposit)}</p>
                            <p className="text-zinc-700 dark:text-zinc-300">Closing: {formatCurrency(dayInfo.record.closing_balance)}</p>
                          </>
                        )}
                        {!dayInfo.record && <p className="text-zinc-500 dark:text-zinc-500">No financial record</p>}
                        {isDayEditable(dayInfo) && <p className="text-xs mt-1 text-primary/80">Click to edit</p>}
                      </TooltipContent>
                    </Tooltip>
                  );
                })}
              </div>
            ))}
          </div>
          
          <Dialog open={selectedDate !== null} onOpenChange={(open) => {
            if (!open) setSelectedDate(null);
          }}>
            <DialogContent className="bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700">
              <DialogHeader>
                <DialogTitle className="text-zinc-800 dark:text-zinc-200">
                  {selectedDate && format(selectedDate, 'EEEE, MMMM d, yyyy')}
                </DialogTitle>
              </DialogHeader>
              
              {selectedDate && (
                <div className="space-y-4 py-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm font-medium mb-1 block text-zinc-700 dark:text-zinc-300">Opening Balance</label>
                      <div className="text-lg font-medium text-zinc-800 dark:text-zinc-200">{formatCurrency(editRecord?.opening_balance || 0)}</div>
                      <p className="text-xs text-zinc-500 dark:text-zinc-500">
                        Automatically carried over from previous day's closing balance
                      </p>
                    </div>
                  </div>
                  
                  <div>
                    <label className="text-sm font-medium mb-1 flex items-center">
                      Bank Deposit
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Info className="h-4 w-4 ml-1 text-muted-foreground cursor-help" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Bank deposit is the amount you record as deposited.</p>
                          <p>Company receives {100-memberSharePercentage}% of this amount.</p>
                        </TooltipContent>
                      </Tooltip>
                    </label>
                    <div className="flex space-x-2">
                      <Input
                        type="number"
                        value={bankDeposit}
                        onChange={(e) => setBankDeposit(parseFloat(e.target.value) || 0)}
                        min="0"
                        step="0.01"
                      />
                    </div>
                  </div>
                  
                  <div>
                    <label className="text-sm font-medium mb-1 flex items-center">
                      Closing Balance
                      <span className="ml-1 text-xs font-medium text-amber-600">(Manual Entry)</span>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Info className="inline h-3.5 w-3.5 ml-1 text-muted-foreground cursor-help" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className="font-medium">This is a manual entry field.</p>
                          <p>Enter your actual end-of-day cash balance.</p>
                          <p>This value will be the opening balance for the next day.</p>
                          <p className="mt-1 font-medium">Note: The closing balance is NOT automatically calculated and does not need to match your deposits.</p>
                        </TooltipContent>
                      </Tooltip>
                    </label>
                    <div className="flex space-x-2">
                      <Input
                        type="number"
                        value={closingBalance}
                        onChange={(e) => setClosingBalance(parseFloat(e.target.value) || 0)}
                        min="0"
                        step="0.01"
                      />
                    </div>
                  </div>
                  
                  {isInFuture(selectedDate) && (
                    <div className="flex items-center text-sm text-amber-600 dark:text-amber-500">
                      <AlertCircle className="h-4 w-4 mr-1" />
                      Cannot edit future dates
                    </div>
                  )}
                  
                  <div className="flex justify-end space-x-2">
                    <Button
                      variant="outline"
                      onClick={() => {
                        setSelectedDate(null);
                        setEditRecord(null);
                      }}
                      className="border-zinc-300 dark:border-zinc-700 text-zinc-700 dark:text-zinc-300"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleUpdateRecord}
                      disabled={isUpdating || isInFuture(selectedDate)}
                      className="bg-primary hover:bg-primary/90 text-white"
                    >
                      {isUpdating ? 'Saving...' : 'Save Changes'}
                    </Button>
                  </div>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </TooltipProvider>
  );
};
