import { useState } from "react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";
import { User } from "@/components/admin/users/types";

interface Transaction {
  id: string;
  amount: number;
  type: string;
  status: string;
  created_at: string;
  description: string;
  reference_number?: string;
}

export function useMemberFinancials() {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const { toast } = useToast();

  const fetchUsers = async () => {
    try {
      setLoading(true);
      
      // Updated query to only select columns that exist in the profiles table
      // Removing the problematic last_sign_in_at column from the direct query
      const { data, error } = await supabase
        .from('profiles')
        .select('id, email, first_name, last_name, status, balance, member_share_percentage, is_admin, created_at, updated_at, avatar_url')
        .eq('is_admin', false) // Select only non-admin users
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      if (data) {
        // Set default member_share_percentage if it's missing
        const usersWithDefaults = data.map(user => ({
          id: user.id,
          email: user.email || '',
          first_name: user.first_name,
          last_name: user.last_name,
          status: user.status,
          is_admin: user.is_admin || false,
          created_at: user.created_at || new Date().toISOString(),
          updated_at: user.updated_at || null,
          last_sign_in_at: null, // Default to null since we're not fetching it
          balance: user.balance || 0,
          member_share_percentage: user.member_share_percentage || 85, // Default to 85% if not set
          avatar_url: user.avatar_url || null
        })) as User[];
        
        setUsers(usersWithDefaults);
        return usersWithDefaults;
      }
      
      return [];
    } catch (error) {
      logger.error('Error fetching users:', error);
      toast({
        title: "Error loading users",
        description: "Could not fetch user data. Please try again later.",
        variant: "destructive",
      });
      return [];
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetails = async (userId: string) => {
    try {
      setLoading(true);
      
      // Updated query to only select columns that exist in the profiles table
      const { data, error } = await supabase
        .from('profiles')
        .select('id, email, first_name, last_name, status, balance, member_share_percentage, is_admin, created_at, updated_at, avatar_url')
        .eq('id', userId)
        .single();

      if (error) throw error;
      
      if (data) {
        const userWithDefaults: User = {
          id: data.id,
          email: data.email || '',
          first_name: data.first_name,
          last_name: data.last_name,
          status: data.status,
          is_admin: data.is_admin || false,
          created_at: data.created_at || new Date().toISOString(),
          updated_at: data.updated_at || null,
          last_sign_in_at: null, // Default to null since we're not fetching it
          balance: data.balance || 0,
          member_share_percentage: data.member_share_percentage || 85, // Default to 85% if not set
          avatar_url: data.avatar_url || null
        };
        
        setSelectedUser(userWithDefaults);
        return userWithDefaults;
      }
      
      return null;
    } catch (error) {
      logger.error('Error fetching user details:', error);
      toast({
        title: "Error",
        description: "Failed to load user details",
        variant: "destructive",
      });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const fetchUserTransactions = async (userId: string) => {
    try {
      setTransactionsLoading(true);
      const { data, error } = await supabase
        .from('transactions')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      setTransactions(data || []);
      return data;
    } catch (error) {
      logger.error('Error fetching transactions:', error);
      toast({
        title: "Error",
        description: "Failed to load transactions",
        variant: "destructive",
      });
      return [];
    } finally {
      setTransactionsLoading(false);
    }
  };

  const updateUserBalance = async (userId: string, newBalance: number): Promise<void> => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ balance: newBalance })
        .eq('id', userId);

      if (error) throw error;

      toast({
        title: "Success",
        description: "User balance has been updated",
        variant: "default",
      });
      
      // Update local state
      if (selectedUser?.id === userId) {
        setSelectedUser(prev => prev ? { ...prev, balance: newBalance } : null);
      }
      
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user.id === userId ? { ...user, balance: newBalance } : user
        )
      );
    } catch (error) {
      logger.error('Error updating balance:', error);
      toast({
        title: "Update Failed",
        description: "Failed to update balance",
        variant: "destructive",
      });
    }
  };

  const formatCurrency = (amount: number | null | undefined) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount || 0);
  };

  return {
    users,
    selectedUser,
    transactions,
    loading,
    transactionsLoading,
    fetchUsers,
    fetchUserDetails,
    fetchUserTransactions,
    updateUserBalance,
    formatCurrency,
    setSelectedUser,
  };
}
