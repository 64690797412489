
import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";

export type WaitingUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  status: string;
  created_at: string;
};

export function useWaitingList() {
  const [users, setUsers] = useState<WaitingUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  const fetchWaitingUsers = async () => {
    try {
      setIsLoading(true);
      
      // Get profiles with waiting or pending status
      // Modified query to be more inclusive and reliable
      const { data: profilesData, error: profilesError } = await supabase
        .from('profiles')
        .select('*')
        .in('status', ['waiting', 'pending']) 
        .order('created_at', { ascending: false });

      if (profilesError) {
        logger.error('Error fetching waiting users:', profilesError);
        throw profilesError;
      }

      // Create user list from profiles
      const waitingUsers: WaitingUser[] = profilesData.map(profile => ({
        id: profile.id,
        email: profile.email || `User-${profile.id.substring(0, 8)}`,
        first_name: profile.first_name || '',
        last_name: profile.last_name || '',
        status: profile.status || 'waiting',
        created_at: profile.created_at
      }));
      
      setUsers(waitingUsers);
      logger.info(`Fetched ${waitingUsers.length} waiting users`);
    } catch (error) {
      logger.error('Error in fetchWaitingUsers:', error);
      toast({
        title: "Error",
        description: "Failed to fetch waiting list users. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWaitingUsers();
    
    // Set up realtime subscription for profiles table
    const channel = supabase
      .channel('waiting-list-changes')
      .on('postgres_changes', 
        { event: '*', schema: 'public', table: 'profiles' }, 
        () => {
          logger.info('Profiles table changed, refreshing waiting list');
          fetchWaitingUsers();
        }
      )
      .subscribe((status) => {
        logger.info(`Waiting list subscription status: ${status}`);
      });

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const approveUser = async (userId: string) => {
    try {
      // Verify admin status
      const { data: isAdmin, error: adminCheckError } = await supabase.rpc('is_admin_email_direct');
      
      if (adminCheckError) {
        logger.error('Admin check error:', adminCheckError);
        throw new Error('Failed to verify admin privileges');
      }
      
      if (!isAdmin) {
        throw new Error('Only administrators can approve users');
      }

      // Update user status to active
      const { error } = await supabase
        .from('profiles')
        .update({ status: 'active' })
        .eq('id', userId);

      if (error) throw error;

      toast({
        title: "Success",
        description: `User approved successfully`,
      });

      fetchWaitingUsers();
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to approve user",
        variant: "destructive",
      });
    }
  };

  const rejectUser = async (userId: string) => {
    try {
      // Verify admin status
      const { data: isAdmin, error: adminCheckError } = await supabase.rpc('is_admin_email_direct');
      
      if (adminCheckError) {
        logger.error('Admin check error:', adminCheckError);
        throw new Error('Failed to verify admin privileges');
      }
      
      if (!isAdmin) {
        throw new Error('Only administrators can reject users');
      }

      // Update user status to rejected
      const { error } = await supabase
        .from('profiles')
        .update({ status: 'rejected' })
        .eq('id', userId);

      if (error) throw error;

      toast({
        title: "User Rejected",
        description: `User has been rejected`,
      });

      fetchWaitingUsers();
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to reject user",
        variant: "destructive",
      });
    }
  };

  return {
    users,
    isLoading,
    approveUser,
    rejectUser,
    refreshList: fetchWaitingUsers
  };
}
