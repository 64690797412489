import React, { useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardFooter, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Progress } from "@/components/ui/progress";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { 
  Pause, Play, X, MessageSquare, Calculator, Wallet, RefreshCw, 
  Settings, ChevronDown, ChevronUp, Edit
} from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/hooks/use-toast";
import { MemberMarketCalculator } from "./MemberMarketCalculator";
import { MemberFinancialManagement } from "./MemberFinancialManagement";
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination";
import { MarketForManagement } from "@/hooks/admin/use-user-market-management";
import { 
  Dialog, 
  DialogContent, 
  DialogDescription, 
  DialogFooter, 
  DialogHeader, 
  DialogTitle 
} from "@/components/ui/dialog";
import { 
  Form, 
  FormControl, 
  FormDescription, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { supabase } from "@/lib/supabase";
import { toast } from "sonner";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface User {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  balance: number;
  status: string | null;
  member_share_percentage: number;
}

interface MemberMarketManagementProps {
  user: User;
  markets: MarketForManagement[];
  loading: boolean;
  onUpdateMarketStatus: (marketId: string, status: string) => Promise<void>;
  onRefresh: () => void;
}

const configurationSchema = z.object({
  targetEarnings: z.number().min(0, "Target earnings must be positive"),
  dailyContribution: z.number().min(0, "Daily contribution must be positive"),
  maximumRisk: z.number().min(0, "Risk level must be positive").max(100, "Risk level must be 100 or less"),
  participationType: z.string(),
  allowAutoRenewal: z.boolean().default(false),
});

type ConfigurationValues = z.infer<typeof configurationSchema>;

export const MemberMarketManagement: React.FC<MemberMarketManagementProps> = ({
  user,
  markets,
  loading,
  onUpdateMarketStatus,
  onRefresh
}) => {
  const [activeTab, setActiveTab] = useState("markets");
  const [selectedMarket, setSelectedMarket] = useState<MarketForManagement | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isConfigDialogOpen, setIsConfigDialogOpen] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [expandedMarketId, setExpandedMarketId] = useState<string | null>(null);
  const itemsPerPage = 10;
  const { toast: uiToast } = useToast();
  
  const form = useForm<ConfigurationValues>({
    resolver: zodResolver(configurationSchema),
    defaultValues: {
      targetEarnings: 0,
      dailyContribution: 0,
      maximumRisk: 50,
      participationType: "standard",
      allowAutoRenewal: false,
    },
  });
  
  const totalPages = Math.ceil(markets.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentMarkets = markets.slice(startIndex, endIndex);
  
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR'
    }).format(amount);
  };
  
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active': return 'bg-green-100 text-green-800';
      case 'paused': return 'bg-amber-100 text-amber-800';
      case 'closed': return 'bg-red-100 text-red-800';
      case 'pending': return 'bg-blue-100 text-blue-800';
      case 'completed': return 'bg-purple-100 text-purple-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };
  
  const calculateProgress = (current: number, target: number) => {
    if (target === 0) return 0;
    const progress = (current / target) * 100;
    return Math.min(100, Math.max(0, progress));
  };
  
  const handleNotify = (marketId: string, marketName: string) => {
    toast.success(`Notification has been sent to ${user.first_name} ${user.last_name} about ${marketName}`);
  };
  
  const handleOpenConfigDialog = (market: MarketForManagement) => {
    setSelectedMarket(market);
    
    fetchMarketParticipantConfig(market.id, user.id).then(config => {
      if (config) {
        let allowAutoRenewal = false;
        
        if (config.metadata) {
          if (typeof config.metadata === 'string') {
            try {
              const parsedMetadata = JSON.parse(config.metadata);
              allowAutoRenewal = !!parsedMetadata.allow_auto_renewal;
            } catch (e) {
              allowAutoRenewal = false;
            }
          } else if (typeof config.metadata === 'object' && config.metadata !== null) {
            allowAutoRenewal = !!(config.metadata as any).allow_auto_renewal;
          }
        }
        
        form.reset({
          targetEarnings: config.target_earnings || 0,
          dailyContribution: market.daily_contribution_target || 0,
          maximumRisk: config.risk_assessment_score || 50,
          participationType: market.market_type || "standard",
          allowAutoRenewal: allowAutoRenewal,
        });
      } else {
        form.reset({
          targetEarnings: 0,
          dailyContribution: market.daily_contribution_target || 0,
          maximumRisk: 50,
          participationType: market.market_type || "standard",
          allowAutoRenewal: false,
        });
      }
    });
    
    setIsConfigDialogOpen(true);
  };
  
  const fetchMarketParticipantConfig = async (marketId: string, userId: string) => {
    try {
      const { data, error } = await supabase
        .from('market_participants')
        .select('*')
        .eq('market_id', marketId)
        .eq('user_id', userId)
        .single();
        
      if (error) {
        console.error('Error fetching market participant config:', error);
        return null;
      }
      
      return data;
    } catch (err) {
      console.error('Error in fetchMarketParticipantConfig:', err);
      return null;
    }
  };
  
  const handleSaveConfiguration = async (values: ConfigurationValues) => {
    if (!selectedMarket || !user.id) return;
    
    setConfigLoading(true);
    
    try {
      const metadata = {
        allow_auto_renewal: values.allowAutoRenewal,
        configured_by_admin: true,
        configured_at: new Date().toISOString(),
        participation_type: values.participationType,
        ...(values.dailyContribution !== selectedMarket.daily_contribution_target 
          ? { daily_contribution_override: values.dailyContribution } 
          : {})
      };
      
      const { error: participantError } = await supabase
        .from('market_participants')
        .update({
          target_earnings: values.targetEarnings,
          risk_assessment_score: values.maximumRisk,
          metadata: metadata,
          updated_at: new Date().toISOString()
        })
        .eq('market_id', selectedMarket.id)
        .eq('user_id', user.id);
      
      if (participantError) {
        throw new Error(`Failed to update participant configuration: ${participantError.message}`);
      }
      
      await supabase
        .from('market_history')
        .insert({
          market_id: selectedMarket.id,
          action: 'member_config_updated',
          details: {
            user_id: user.id,
            updated_by: 'admin',
            previous_config: {
              target_earnings: form.getValues().targetEarnings,
              risk_assessment_score: form.getValues().maximumRisk,
              allow_auto_renewal: form.getValues().allowAutoRenewal,
              participation_type: form.getValues().participationType
            },
            new_config: values
          },
          performed_by: (await supabase.auth.getUser()).data.user?.id
        });
      
      toast.success("Member market configuration updated successfully");
      setIsConfigDialogOpen(false);
      onRefresh();
    } catch (error) {
      console.error('Error updating configuration:', error);
      toast.error(`Configuration update failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setConfigLoading(false);
    }
  };
  
  const toggleMarketExpansion = (marketId: string) => {
    if (expandedMarketId === marketId) {
      setExpandedMarketId(null);
    } else {
      setExpandedMarketId(marketId);
    }
  };
  
  const generatePaginationItems = () => {
    const items = [];
    const maxPagesShown = 5;
    
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + maxPagesShown - 1);
    
    if (endPage - startPage < maxPagesShown - 1) {
      startPage = Math.max(1, endPage - maxPagesShown + 1);
    }
    
    if (startPage > 1) {
      items.push(
        <PaginationItem key="first">
          <PaginationLink isActive={currentPage === 1} onClick={() => goToPage(1)}>
            1
          </PaginationLink>
        </PaginationItem>
      );
      
      if (startPage > 2) {
        items.push(
          <PaginationItem key="ellipsis-start">
            <PaginationEllipsis />
          </PaginationItem>
        );
      }
    }
    
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i}>
          <PaginationLink isActive={currentPage === i} onClick={() => goToPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(
          <PaginationItem key="ellipsis-end">
            <PaginationEllipsis />
          </PaginationItem>
        );
      }
      
      items.push(
        <PaginationItem key="last">
          <PaginationLink isActive={currentPage === totalPages} onClick={() => goToPage(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }
    
    return items;
  };

  const handleCalculatorButtonClick = (market: MarketForManagement) => {
    setSelectedMarket(market);
    setActiveTab('calculator');
  };

  const convertToMarketForManagement = (market: any): MarketForManagement => {
    return {
      id: market.id,
      name: market.name,
      status: market.status || 'unknown',
      target_value: market.target_value || 0,
      current_value: market.current_value || 0,
      region: market.region || '',
      market_type: market.market_type || 'standard',
      market_risk_level: market.market_risk_level || 'medium',
      active_participants: market.active_participants || 0,
      daily_contribution_target: market.daily_contribution_target || 0,
      total_contributions: market.total_contributions || 0,
      max_participations_per_user: market.max_participations_per_user || 1,
      entry_amount: market.entry_amount || 0,
      created_at: market.created_at,
      updated_at: market.updated_at,
      metadata: market.metadata ? 
        (typeof market.metadata === 'string' ? JSON.parse(market.metadata) : market.metadata) : {}
    };
  };

  return (
    <div className="space-y-6">
      <Card className="bg-muted/20 border-primary/20">
        <CardHeader className="pb-3">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-2">
            <div>
              <CardTitle className="text-xl text-primary">
                {user.first_name} {user.last_name}
              </CardTitle>
              <CardDescription>{user.email}</CardDescription>
            </div>
            <div className="flex gap-2">
              <Button onClick={onRefresh} variant="outline" size="sm" className="gap-2">
                <RefreshCw className="h-4 w-4" />
                Refresh
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="p-4 border bg-card rounded-lg">
              <div className="text-sm font-medium text-muted-foreground mb-1">Current Balance</div>
              <div className="text-lg font-semibold">{formatCurrency(user.balance)}</div>
            </div>
            <div className="p-4 border bg-card rounded-lg">
              <div className="text-sm font-medium text-muted-foreground mb-1">Member Share %</div>
              <div className="text-lg font-semibold">{user.member_share_percentage}%</div>
            </div>
            <div className="p-4 border bg-card rounded-lg">
              <div className="text-sm font-medium text-muted-foreground mb-1">Active Markets</div>
              <div className="text-lg font-semibold">{markets.filter(m => m.status === 'active').length} / {markets.length}</div>
            </div>
          </div>
        </CardContent>
      </Card>
      
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="mb-4">
          <TabsTrigger value="markets">Markets</TabsTrigger>
          <TabsTrigger value="calculator">Market Calculator</TabsTrigger>
          <TabsTrigger value="financials">Financial Management</TabsTrigger>
        </TabsList>
        
        <TabsContent value="markets">
          {loading ? (
            <div className="space-y-2">
              <Skeleton className="h-10 w-full" />
              <Skeleton className="h-10 w-full" />
              <Skeleton className="h-10 w-full" />
            </div>
          ) : markets.length === 0 ? (
            <div className="text-center py-8 text-muted-foreground border rounded-md">
              This member is not participating in any markets.
            </div>
          ) : (
            <div className="space-y-4">
              <div className="rounded-md border overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[30%] min-w-[180px]">Market Name</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead>Region</TableHead>
                      <TableHead>Risk</TableHead>
                      <TableHead>Progress</TableHead>
                      <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentMarkets.map((market) => (
                      <React.Fragment key={market.id}>
                        <TableRow className="hover:bg-muted/50">
                          <TableCell className="font-medium">
                            <div className="flex items-center gap-2">
                              <Button 
                                variant="ghost" 
                                size="sm" 
                                className="h-6 w-6 p-0" 
                                onClick={() => toggleMarketExpansion(market.id)}
                              >
                                {expandedMarketId === market.id ? (
                                  <ChevronUp className="h-4 w-4 text-muted-foreground" />
                                ) : (
                                  <ChevronDown className="h-4 w-4 text-muted-foreground" />
                                )}
                              </Button>
                              {market.name}
                            </div>
                          </TableCell>
                          <TableCell>
                            <Badge variant="outline" className={getStatusColor(market.status)}>
                              {market.status.charAt(0).toUpperCase() + market.status.slice(1)}
                            </Badge>
                          </TableCell>
                          <TableCell>{market.region}</TableCell>
                          <TableCell>{market.market_risk_level}</TableCell>
                          <TableCell>
                            <div className="w-full max-w-[120px]">
                              <div className="flex justify-between text-xs mb-1">
                                <span>{formatCurrency(market.current_value)}</span>
                                <span>{formatCurrency(market.target_value)}</span>
                              </div>
                              <Progress value={calculateProgress(market.current_value, market.target_value)} className="h-2" />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center justify-end gap-1">
                              {market.status === 'active' ? (
                                <Button 
                                  variant="outline" 
                                  size="icon"
                                  title="Pause Market for Member"
                                  onClick={() => onUpdateMarketStatus(market.id, 'paused')}
                                >
                                  <Pause className="h-4 w-4" />
                                </Button>
                              ) : market.status === 'paused' ? (
                                <Button 
                                  variant="outline" 
                                  size="icon"
                                  title="Resume Market for Member"
                                  onClick={() => onUpdateMarketStatus(market.id, 'active')}
                                >
                                  <Play className="h-4 w-4" />
                                </Button>
                              ) : null}
                              
                              <Button 
                                variant="outline" 
                                size="icon"
                                title="Configure Member Market"
                                onClick={() => handleOpenConfigDialog(market)}
                              >
                                <Settings className="h-4 w-4" />
                              </Button>
                              
                              <Button 
                                variant="outline" 
                                size="icon"
                                title="Close Market for Member"
                                onClick={() => onUpdateMarketStatus(market.id, 'closed')}
                              >
                                <X className="h-4 w-4" />
                              </Button>
                              
                              <Button 
                                variant="outline" 
                                size="icon"
                                title="Notify Member"
                                onClick={() => handleNotify(market.id, market.name)}
                              >
                                <MessageSquare className="h-4 w-4" />
                              </Button>
                              
                              <Button 
                                variant="outline" 
                                size="icon"
                                title="Calculate"
                                onClick={() => handleCalculatorButtonClick(market)}
                              >
                                <Calculator className="h-4 w-4" />
                              </Button>
                              
                              <Button 
                                variant="outline" 
                                size="sm"
                                onClick={() => window.location.href = `/admin/markets/${market.id}`}
                              >
                                Details
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                        
                        {expandedMarketId === market.id && (
                          <TableRow className="bg-muted/30">
                            <TableCell colSpan={6} className="p-4">
                              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                <div>
                                  <h4 className="font-semibold mb-2">Market Details</h4>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="text-muted-foreground">Market Type:</div>
                                    <div>{market.market_type}</div>
                                    <div className="text-muted-foreground">Daily Target:</div>
                                    <div>{formatCurrency(market.daily_contribution_target)}</div>
                                    <div className="text-muted-foreground">Max Participations:</div>
                                    <div>{market.max_participations_per_user || 1}</div>
                                    <div className="text-muted-foreground">Created:</div>
                                    <div>{new Date(market.created_at).toLocaleDateString()}</div>
                                  </div>
                                </div>
                                
                                <div>
                                  <h4 className="font-semibold mb-2">Member Settings</h4>
                                  <div className="space-y-2">
                                    <Button 
                                      variant="outline" 
                                      size="sm" 
                                      className="w-full gap-2"
                                      onClick={() => handleOpenConfigDialog(market)}
                                    >
                                      <Edit className="h-4 w-4" />
                                      Configure Member Settings
                                    </Button>
                                    
                                    <Button 
                                      variant="outline" 
                                      size="sm" 
                                      className="w-full gap-2"
                                      onClick={() => handleCalculatorButtonClick(market)}
                                    >
                                      <Calculator className="h-4 w-4" />
                                      Open Market Calculator
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </div>
              
              {totalPages > 1 && (
                <Pagination className="justify-center">
                  <PaginationContent>
                    <PaginationItem>
                      <PaginationPrevious 
                        onClick={() => goToPage(currentPage - 1)} 
                        className={currentPage === 1 ? "pointer-events-none opacity-50" : "cursor-pointer"}
                      />
                    </PaginationItem>
                    
                    {generatePaginationItems()}
                    
                    <PaginationItem>
                      <PaginationNext 
                        onClick={() => goToPage(currentPage + 1)} 
                        className={currentPage === totalPages ? "pointer-events-none opacity-50" : "cursor-pointer"}
                      />
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              )}
              
              {markets.length > 0 && (
                <div className="text-sm text-muted-foreground text-center">
                  Showing {startIndex + 1} to {Math.min(endIndex, markets.length)} of {markets.length} markets
                </div>
              )}
            </div>
          )}
        </TabsContent>
        
        <TabsContent value="calculator">
          <MemberMarketCalculator 
            user={user}
            market={selectedMarket} 
            markets={markets as any} 
            onMarketSelect={setSelectedMarket}
          />
        </TabsContent>
        
        <TabsContent value="financials">
          <MemberFinancialManagement 
            user={user}
            markets={markets as any}
          />
        </TabsContent>
      </Tabs>
      
      <Dialog open={isConfigDialogOpen} onOpenChange={setIsConfigDialogOpen}>
        <DialogContent className="max-w-md">
          <DialogHeader>
            <DialogTitle>Configure Member Market</DialogTitle>
            <DialogDescription>
              Customize settings for {user.first_name} {user.last_name} in {selectedMarket?.name}
            </DialogDescription>
          </DialogHeader>
          
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSaveConfiguration)} className="space-y-4">
              <FormField
                control={form.control}
                name="targetEarnings"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Target Earnings</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <span className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">R</span>
                        <Input 
                          type="number" 
                          {...field} 
                          value={field.value || 0}
                          onChange={e => field.onChange(parseFloat(e.target.value) || 0)}
                          className="pl-8" 
                        />
                      </div>
                    </FormControl>
                    <FormDescription>The target amount this member aims to earn</FormDescription>
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="dailyContribution"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Daily Contribution Target</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <span className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">R</span>
                        <Input 
                          type="number" 
                          {...field} 
                          value={field.value || 0}
                          onChange={e => field.onChange(parseFloat(e.target.value) || 0)}
                          className="pl-8" 
                        />
                      </div>
                    </FormControl>
                    <FormDescription>Custom daily target for this member</FormDescription>
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="maximumRisk"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Risk Assessment Score</FormLabel>
                    <FormControl>
                      <div className="flex items-center gap-4">
                        <Input 
                          type="number" 
                          {...field} 
                          value={field.value || 0}
                          onChange={e => field.onChange(parseFloat(e.target.value) || 0)}
                        />
                        <span>{field.value}%</span>
                      </div>
                    </FormControl>
                    <FormDescription>Member's risk assessment score (0-100)</FormDescription>
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="participationType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Participation Type</FormLabel>
                    <Select 
                      onValueChange={field.onChange} 
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select participation type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="standard">Standard</SelectItem>
                        <SelectItem value="premium">Premium</SelectItem>
                        <SelectItem value="limited">Limited</SelectItem>
                        <SelectItem value="vip">VIP</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormDescription>The type of participation for this member</FormDescription>
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="allowAutoRenewal"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                    <div className="space-y-0.5">
                      <FormLabel>Allow Auto-Renewal</FormLabel>
                      <FormDescription>
                        Automatically renew participation when market completes
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              
              <DialogFooter>
                <Button 
                  type="button" 
                  variant="outline" 
                  onClick={() => setIsConfigDialogOpen(false)}
                  disabled={configLoading}
                >
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={configLoading}
                >
                  {configLoading ? "Saving..." : "Save Configuration"}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
