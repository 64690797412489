
// Function to generate a unique gradient based on user ID
export function generateUniqueGradient(userId?: string): string {
  if (!userId) return "linear-gradient(135deg, #5f75ee, #7950f2)"; // Default gradient
  
  // Use userId to generate a deterministic but unique hash
  const hash = userId
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  
  // Create HSL colors with different hues but consistent saturation and lightness
  const hue1 = hash % 360;
  const hue2 = (hue1 + 40) % 360; // Offset for a nice contrast
  
  return `linear-gradient(135deg, hsl(${hue1}, 70%, 60%), hsl(${hue2}, 70%, 50%))`;
}

// Function to get initials from first and last name
export function getInitials(firstName?: string | null, lastName?: string | null): string {
  const firstInitial = firstName && firstName.length > 0 ? firstName[0].toUpperCase() : "";
  const lastInitial = lastName && lastName.length > 0 ? lastName[0].toUpperCase() : "";
  
  // If no name is available, use a generic avatar indicator
  if (!firstInitial && !lastInitial) return "U";
  
  return `${firstInitial}${lastInitial}`;
}
