
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";
import { Session } from "@supabase/supabase-js";

export const useAuthRedirect = (session: Session | null, authLoading: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  useEffect(() => {
    // Store this to prevent multiple redirects
    const redirectKey = 'auth_redirect_' + location.pathname;
    
    if (!authLoading && !session && !localStorage.getItem(redirectKey)) {
      logger.info('No session found, redirecting to signin');
      const currentPath = location.pathname;
      
      // Mark as redirected to prevent infinite redirects
      localStorage.setItem(redirectKey, 'true');
      
      // Set a timeout to clear the redirect key
      setTimeout(() => {
        localStorage.removeItem(redirectKey);
      }, 5000);
      
      toast({
        title: "Authentication Required",
        description: "Please sign in to access this page",
        variant: "destructive",
      });

      navigate(`/signin?redirect=${encodeURIComponent(currentPath)}`, { replace: true });
    }
  }, [session, authLoading, navigate, toast, location]);
};
