
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { AppError, MarketErrorType } from '@/utils/error-handling/error-types';
import { extractErrorDetails, logSupabaseError } from '@/utils/error-handling/supabase-error';

/**
 * Verifies if the current user has admin privileges
 * @returns Object with isAdmin status and error message if any
 */
export const verifyAdminStatus = async (): Promise<{ 
  isAdmin: boolean; 
  error?: string;
  userId?: string;
}> => {
  try {
    // First check if the user is authenticated
    const { data, error: sessionError } = await supabase.auth.getSession();
    
    if (sessionError) {
      logSupabaseError("Session error during admin verification", sessionError);
      throw new AppError({
        message: `Authentication error: ${sessionError.message}`,
        type: 'auth'
      });
    }
    
    if (!data.session) {
      return { isAdmin: false, error: "User not authenticated" };
    }

    // Get the current user ID
    const userId = data.session.user.id;
    
    // Check admin status based on email domain (most reliable method)
    const userEmail = data.session.user.email;
    
    if (userEmail && userEmail.endsWith('@thecolosseum.co.za')) {
      logger.info('Admin verified via email domain', { email: userEmail });
      return { isAdmin: true, userId };
    }
    
    // Fallback to RPC function for additional verification
    try {
      const { data: isAdmin, error: adminCheckError } = await supabase.rpc('is_admin_email_direct');
      
      if (adminCheckError) {
        logSupabaseError('Admin check RPC error', adminCheckError);
        // Continue with email-based check result
      } else if (isAdmin) {
        return { isAdmin: true, userId };
      }
    } catch (rpcError) {
      logger.warn('RPC admin check failed, using email verification only:', rpcError);
    }
    
    // Return false as a fallback
    return { 
      isAdmin: false,
      error: "User does not have admin privileges",
      userId
    };
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    const errorType = error instanceof AppError ? error.type : 'auth' as MarketErrorType;
    
    logger.error("Admin verification error:", { 
      error: errorMessage,
      type: errorType
    });
    
    return { isAdmin: false, error: errorMessage };
  }
};

/**
 * Handles market operations requiring admin privileges
 * @param operation The async operation to execute if user is admin
 * @returns The operation result or error
 */
export const withAdminPrivileges = async <T>(
  operation: () => Promise<T>
): Promise<{ success: boolean; data?: T; error?: string }> => {
  try {
    // First verify current authentication
    const { data } = await supabase.auth.getSession();
    
    if (!data?.session) {
      logger.warn("Admin operation attempted without authentication");
      return { 
        success: false, 
        error: "You must be logged in to perform this operation" 
      };
    }
    
    // Then check admin status
    const { isAdmin, error, userId } = await verifyAdminStatus();
    
    if (!isAdmin) {
      logger.warn("Non-admin user attempted admin operation", { 
        userId,
        error 
      });
      
      return { 
        success: false, 
        error: error || "Only administrators can perform this operation" 
      };
    }
    
    // Execute the operation with proper error handling
    const result = await operation();
    logger.info("Admin operation executed successfully", { userId });
    return { success: true, data: result };
  } catch (error) {
    // Use our enhanced error handling
    const errorDetails = error instanceof Error ? extractErrorDetails(error) : { message: String(error) };
    const errorType = error instanceof AppError ? error.type : 'unknown' as MarketErrorType;
    
    logger.error("Admin operation error:", { 
      error: errorDetails.message,
      code: errorDetails.code,
      type: errorType 
    });
    
    return { success: false, error: errorDetails.message };
  }
};
