
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Download, RefreshCw, Calendar, Wallet, PiggyBank, TrendingUp, ArrowUpRight, ChevronRight } from "lucide-react";
import ScrollSection from "@/components/ScrollSection";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import { toast } from "@/hooks/use-toast";
import { formatCurrency } from "@/utils/formatters";
import { DailyFinancialRecord } from "@/components/financials/DailyFinancialRecord";
import { MonthlyFinancialSummary } from "@/components/financials/MonthlyFinancialSummary";
import { StreakTrackerCard } from "@/components/goals/StreakTrackerCard";
import { useBankingGoals } from "@/hooks/goals/use-banking-goals";
import { useDailyFinancialRecords } from "@/hooks/financials/use-daily-financial-records";
import { useMonthlyFinancialSummary } from "@/hooks/financials/use-monthly-financial-summary";

const Portfolio = () => {
  const { session } = useAuth();
  const userId = session?.user?.id;
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  
  const [memberSharePercentage, setMemberSharePercentage] = useState<number>(15);
  const [isLoading, setIsLoading] = useState(true);
  
  // Fetch banking goals data
  const { currentStreak } = useBankingGoals();
  
  // Fetch daily financial records
  const {
    records,
    isLoading: isLoadingRecords,
    error: recordsError,
    dailyTotals,
    refresh: refreshRecords
  } = useDailyFinancialRecords(userId, currentMonth, currentYear);
  
  // Fetch monthly financial summary
  const {
    summary,
    isLoading: isLoadingSummary,
    error: summaryError
  } = useMonthlyFinancialSummary(userId, currentMonth, currentYear);
  
  // Today's record for editing
  const todayRecord = records.find(record => 
    new Date(record.record_date).toDateString() === today.toDateString()
  );
  
  // Fetch member share percentage
  useEffect(() => {
    const fetchMemberSharePercentage = async () => {
      if (!userId) return;
      
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('member_share_percentage')
          .eq('id', userId)
          .single();
          
        if (error) throw error;
        
        if (data?.member_share_percentage) {
          setMemberSharePercentage(data.member_share_percentage);
        }
      } catch (error) {
        console.error('Error fetching member share percentage:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchMemberSharePercentage();
  }, [userId]);
  
  const handleRecordUpdated = () => {
    refreshRecords();
  };
  
  // Calculate stats
  const currentBalance = todayRecord?.closing_balance || dailyTotals.endBalance || 0;
  const openingBalance = todayRecord?.opening_balance || dailyTotals.startBalance || 0;
  const bankedToday = todayRecord?.bank_deposit || 0;
  const monthlyDeposits = dailyTotals.totalDeposits || 0;
  const memberShareAmount = monthlyDeposits * (memberSharePercentage / 100);
  const adminShareAmount = monthlyDeposits * ((100 - memberSharePercentage) / 100);

  return (
    <DashboardSidebar>
      <div className="flex-1 h-full bg-background">
        <div className="p-4 md:p-6 lg:p-8 w-full">
          <header className="mb-8">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent mb-1">
                  Portfolio Overview
                </h1>
                <p className="text-zinc-600 dark:text-muted-foreground max-w-xl">
                  Track your daily financial activities and performance. Your current records show a
                  {bankedToday > 0 ? ' positive' : ' pending'} banking activity today.
                </p>
              </div>
              <div className="flex gap-4">
                <Button variant="outline" className="gap-2 hover:bg-primary/10 text-zinc-700 dark:text-zinc-200 border-zinc-300 dark:border-zinc-700 transition-colors">
                  <Download className="h-4 w-4" />
                  Export
                </Button>
                <Button className="gap-2 bg-primary hover:bg-primary/90 text-primary-foreground" onClick={() => window.location.reload()}>
                  <RefreshCw className="h-4 w-4" />
                  Refresh
                </Button>
              </div>
            </div>
          </header>

          <ScrollSection className="w-full space-y-8">
            {/* Portfolio Summary Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 w-full">
              <Card className="bg-white dark:bg-gradient-to-br dark:from-card/50 dark:to-card/30 backdrop-blur-sm hover:shadow-lg transition-all duration-300 border border-zinc-200 dark:border-primary/20 overflow-hidden relative group animate-fade-in">
                <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <CardHeader className="pb-2">
                  <CardTitle className="text-lg font-medium flex items-center text-zinc-800 dark:text-zinc-100">
                    <Wallet className="mr-2 h-5 w-5 text-primary" />
                    Cash Balance
                  </CardTitle>
                </CardHeader>
                <CardContent className="pb-6">
                  <p className="text-3xl font-bold group-hover:scale-105 transform transition-transform text-zinc-900 dark:text-zinc-50">{formatCurrency(currentBalance)}</p>
                  <p className="text-sm text-zinc-600 dark:text-muted-foreground mt-1">
                    Today's closing balance
                  </p>
                  <div className={`absolute bottom-0 left-0 h-1 bg-primary transition-all duration-300 ${currentBalance > openingBalance ? 'w-full' : 'w-1/3'}`}></div>
                </CardContent>
              </Card>
              
              <Card className="bg-white dark:bg-gradient-to-br dark:from-card/50 dark:to-card/30 backdrop-blur-sm hover:shadow-lg transition-all duration-300 border border-zinc-200 dark:border-primary/20 overflow-hidden relative group animate-fade-in" style={{animationDelay: "0.1s"}}>
                <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <CardHeader className="pb-2">
                  <CardTitle className="text-lg font-medium flex items-center text-zinc-800 dark:text-zinc-100">
                    <PiggyBank className="mr-2 h-5 w-5 text-primary" />
                    Banked Today
                  </CardTitle>
                </CardHeader>
                <CardContent className="pb-6">
                  <p className="text-3xl font-bold group-hover:scale-105 transform transition-transform text-zinc-900 dark:text-zinc-50">{formatCurrency(bankedToday)}</p>
                  <p className="text-sm text-zinc-600 dark:text-muted-foreground mt-1">
                    Commission to keep: {formatCurrency(bankedToday * memberSharePercentage / 100)}
                  </p>
                  <div className={`absolute bottom-0 left-0 h-1 bg-primary transition-all duration-300 ${bankedToday > 0 ? 'w-full' : 'w-0'}`}></div>
                </CardContent>
              </Card>
              
              <Card className="bg-white dark:bg-gradient-to-br dark:from-card/50 dark:to-card/30 backdrop-blur-sm hover:shadow-lg transition-all duration-300 border border-zinc-200 dark:border-primary/20 overflow-hidden relative group animate-fade-in" style={{animationDelay: "0.2s"}}>
                <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <CardHeader className="pb-2">
                  <CardTitle className="text-lg font-medium flex items-center text-zinc-800 dark:text-zinc-100">
                    <TrendingUp className="mr-2 h-5 w-5 text-primary" />
                    Monthly Total
                  </CardTitle>
                </CardHeader>
                <CardContent className="pb-6">
                  <p className="text-3xl font-bold group-hover:scale-105 transform transition-transform text-zinc-900 dark:text-zinc-50">{formatCurrency(monthlyDeposits)}</p>
                  <p className="text-sm text-zinc-600 dark:text-muted-foreground mt-1">
                    Total banked this month
                  </p>
                  <div className="absolute bottom-0 left-0 h-1 bg-primary transition-all duration-300 w-2/3"></div>
                </CardContent>
              </Card>
            </div>
            
            {/* Streak Tracker */}
            <div className="animate-fade-in" style={{animationDelay: "0.3s"}}>
              <StreakTrackerCard currentStreak={currentStreak} isLoading={isLoading} />
            </div>
            
            {/* Daily Entry Section */}
            <Card className="bg-white dark:bg-gradient-to-br dark:from-card/80 dark:to-card/60 backdrop-blur-sm border border-zinc-200 dark:border-primary/20 shadow-md animate-fade-in" style={{animationDelay: "0.4s"}}>
              <CardHeader className="border-b border-zinc-200 dark:border-border/20 pb-4">
                <CardTitle className="flex items-center text-xl text-primary">
                  <Calendar className="mr-2 h-5 w-5 text-primary" />
                  Today's Financial Record
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6">
                {isLoadingRecords ? (
                  <div className="flex justify-center py-4">
                    <div className="flex flex-col items-center gap-2">
                      <RefreshCw className="h-8 w-8 animate-spin text-primary" />
                      <p className="text-sm text-zinc-600 dark:text-muted-foreground">Loading today's record...</p>
                    </div>
                  </div>
                ) : todayRecord ? (
                  <DailyFinancialRecord 
                    record={todayRecord}
                    isEditable={true}
                    onRecordUpdated={handleRecordUpdated}
                    userId={userId || ''}
                    isCurrentMonth={true}
                    memberSharePercentage={memberSharePercentage}
                  />
                ) : (
                  <div className="flex flex-col items-center justify-center py-8 space-y-3">
                    <p className="text-zinc-600 dark:text-muted-foreground text-center max-w-md">No record available for today. Please check the Finances page to create one.</p>
                    <Button 
                      className="mt-2 bg-primary hover:bg-primary/90 text-primary-foreground"
                      onClick={() => window.location.href = '/dashboard/finances'}
                    >
                      Go to Finances
                    </Button>
                  </div>
                )}
              </CardContent>
            </Card>
            
            {/* Monthly Summary */}
            <div className="animate-fade-in" style={{animationDelay: "0.5s"}}>
              <MonthlyFinancialSummary 
                summary={summary}
                dailyTotals={dailyTotals}
                isLoading={isLoadingSummary}
                memberSharePercentage={memberSharePercentage}
              />
            </div>
            
            {/* Recent Activity */}
            <Card className="bg-white border border-zinc-200 dark:border-primary/20 shadow-md animate-fade-in" style={{animationDelay: "0.6s"}}>
              <CardHeader className="border-b border-zinc-200 dark:border-border/20">
                <CardTitle className="text-xl text-primary">Recent Financial Activity</CardTitle>
              </CardHeader>
              <CardContent className="p-0">
                <div className="divide-y divide-zinc-200 dark:divide-border/20">
                  {isLoadingRecords ? (
                    <div className="flex justify-center py-6">
                      <div className="flex flex-col items-center gap-2">
                        <RefreshCw className="h-6 w-6 animate-spin text-primary" />
                        <p className="text-sm text-zinc-600 dark:text-muted-foreground">Loading recent activity...</p>
                      </div>
                    </div>
                  ) : records.slice(0, 5).map((record) => (
                    <div key={record.record_date} className="flex justify-between items-center p-4 hover:bg-zinc-50 dark:hover:bg-muted/10 transition-colors">
                      <div>
                        <p className="font-medium text-zinc-800 dark:text-zinc-100">{new Date(record.record_date).toLocaleDateString('en-ZA', { weekday: 'short', day: 'numeric', month: 'short' })}</p>
                        <p className="text-sm text-zinc-600 dark:text-muted-foreground flex items-center">
                          {record.bank_deposit ? (
                            <>
                              <ArrowUpRight className="h-3.5 w-3.5 mr-1 text-green-500" />
                              Banked {formatCurrency(record.bank_deposit)}
                            </>
                          ) : (
                            'No banking activity'
                          )}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="font-medium text-zinc-800 dark:text-zinc-100">{formatCurrency(record.closing_balance)}</p>
                        <p className="text-xs text-zinc-500 dark:text-muted-foreground">Closing Balance</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="p-4 border-t border-zinc-200 dark:border-border/20">
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    className="w-full text-zinc-700 hover:text-white hover:bg-primary dark:text-zinc-300 dark:hover:text-primary-foreground transition-colors flex items-center justify-center"
                    onClick={() => window.location.href = '/dashboard/finances'}
                  >
                    View All Financial Records
                    <ChevronRight className="ml-1 h-4 w-4" />
                  </Button>
                </div>
              </CardContent>
            </Card>
          </ScrollSection>
        </div>
      </div>
    </DashboardSidebar>
  );
};

export default Portfolio;

