
import { AlertCircle, Timer, AlertTriangle } from "lucide-react";
import { Market } from "@/types/market";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { getRiskLevelColor, calculateDaysRemaining } from "@/utils/market";
import { cn } from "@/lib/utils";

interface MarketRiskInfoProps {
  market: Market;
  variant?: 'grid' | 'list';
}

export const MarketRiskInfo = ({ market, variant = 'grid' }: MarketRiskInfoProps) => {
  const daysRemaining = calculateDaysRemaining(market.end_date);

  return (
    <div className="space-y-4">
      <div className={cn(
        "grid gap-4",
        variant === 'grid' ? "grid-cols-2" : "grid-cols-1"
      )}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="bg-background/50 p-3 rounded cursor-help">
                <div className="flex items-center gap-2">
                  <AlertCircle className={`w-4 h-4 ${getRiskLevelColor(market.market_risk_level)}`} />
                  <p className="text-sm text-muted-foreground">Risk Level</p>
                </div>
                <p className="font-semibold capitalize">{market.market_risk_level}</p>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Market risk assessment based on historical performance</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="bg-background/50 p-3 rounded cursor-help">
                <div className="flex items-center gap-2">
                  <Timer className="w-4 h-4 text-primary dark:text-primary" />
                  <p className="text-sm text-muted-foreground">Time Left</p>
                </div>
                <p className="font-semibold">{daysRemaining} days</p>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Days remaining until market closes</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      {market.predicted_success_rate < 40 && (
        <div className="flex items-center gap-2 text-yellow-500 dark:text-yellow-400 bg-yellow-500/10 dark:bg-yellow-400/10 p-2 rounded-md">
          <AlertTriangle className="w-4 h-4" />
          <p className="text-sm">Low success rate prediction: {market.predicted_success_rate.toFixed(1)}%</p>
        </div>
      )}
    </div>
  );
};
