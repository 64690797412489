
import { Shield } from "lucide-react";

interface RegistrationHeaderProps {
  currentStep: number;
}

export const RegistrationHeader = ({ currentStep }: RegistrationHeaderProps) => {
  return (
    <div className="text-center">
      <Shield className="w-12 h-12 mx-auto text-primary mb-4 animate-float" />
      <h2 className="text-3xl font-bold text-primary">Join The Colosseum</h2>
      <p className="mt-2 text-muted-foreground">Step {currentStep} of 3</p>
    </div>
  );
};
