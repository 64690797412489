
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Loader2 } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobileMenu = ({ isOpen, onClose }: MobileMenuProps) => {
  const navigate = useNavigate();
  const { session, signOut, isSigningOut } = useAuth();
  const { toast } = useToast();

  const navigateToSection = (path: string) => {
    navigate(path);
    onClose();
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      onClose();
    } catch (error) {
      toast({
        title: "Error signing out",
        description: "There was a problem signing you out. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="md:hidden py-4 space-y-1 animate-fade-in">
      {session ? (
        <>
          <div 
            className="block px-4 py-2.5 text-primary-foreground hover:bg-primary/10 rounded-md transition-colors duration-200 cursor-pointer"
            onClick={() => navigateToSection("/dashboard")}
          >
            Dashboard
          </div>
          <div 
            className="block px-4 py-2.5 text-primary-foreground hover:bg-primary/10 rounded-md transition-colors duration-200 cursor-pointer"
            onClick={handleSignOut}
            style={{ opacity: isSigningOut ? 0.7 : 1 }}
          >
            {isSigningOut ? (
              <span className="flex items-center">
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                Signing Out...
              </span>
            ) : (
              "Sign Out"
            )}
          </div>
        </>
      ) : (
        <div 
          className="block px-4 py-2.5 text-primary-foreground hover:bg-primary/10 rounded-md transition-colors duration-200 cursor-pointer"
          onClick={() => navigateToSection("/signin")}
        >
          Sign In
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
