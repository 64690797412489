
import { logger } from "@/utils/logger";

interface WebhookSendResult {
  success: boolean;
  error?: string;
  responseData?: any;
}

export interface WebhookPayload {
  member_id: string;
  market_id: string;
  timestamp: string;
  requestId: string;
  targetEarnings?: number;
  closeTime?: string;
  source?: string;
  retryAttempt?: number;
}

/**
 * Class to manage sending webhooks to Make.com for market participation
 */
class WebhookManager {
  private static instance: WebhookManager;
  
  // Make.com webhook URL for market join - would be in env for production
  private webhookUrl = 'https://hook.eu2.make.com/vamj4sbwu58qgmwz6lcytjez0ir9urdc';
  
  // Track processed webhook requests to prevent duplicates
  private processedRequests = new Set<string>();
  
  private constructor() {
    // Private constructor to enforce singleton
  }
  
  /**
   * Get singleton instance
   */
  static getInstance(): WebhookManager {
    if (!WebhookManager.instance) {
      WebhookManager.instance = new WebhookManager();
    }
    return WebhookManager.instance;
  }
  
  /**
   * Clear processed requests - useful to prevent memory leaks and for testing
   */
  clearProcessedRequests(): void {
    this.processedRequests.clear();
    logger.info('Cleared processed webhook requests');
  }
  
  /**
   * Check if a request has already been processed
   */
  hasProcessedRequest(requestId: string): boolean {
    return this.processedRequests.has(requestId);
  }
  
  /**
   * Mark a request as processed
   */
  markRequestProcessed(requestId: string): void {
    this.processedRequests.add(requestId);
  }
  
  /**
   * Send a webhook request to Make.com
   */
  async sendWebhook(payload: WebhookPayload): Promise<WebhookSendResult> {
    try {
      // Validate inputs before sending
      if (!payload.member_id || !payload.market_id) {
        return { 
          success: false, 
          error: 'Missing required payload fields: member_id and market_id are required'
        };
      }
      
      // Check UUID format
      if (!this.isValidUUID(payload.member_id) || !this.isValidUUID(payload.market_id)) {
        return { 
          success: false, 
          error: 'Invalid UUID format for member_id or market_id'
        };
      }
      
      // Check if this request has already been processed
      if (payload.requestId && this.processedRequests.has(payload.requestId)) {
        logger.warn('Duplicate webhook request detected and skipped', {
          requestId: payload.requestId,
          marketId: payload.market_id
        });
        
        return {
          success: false,
          error: 'Duplicate request detected'
        };
      }
      
      // Log request
      logger.info('Sending webhook request to Make.com', {
        requestId: payload.requestId,
        marketId: payload.market_id,
        memberId: payload.member_id,
        timestamp: payload.timestamp,
        retryAttempt: payload.retryAttempt
      });
      
      // Configure fetch options with a timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout
      
      try {
        // Send the request with timeout
        const response = await fetch(this.webhookUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
          signal: controller.signal
        });
        
        clearTimeout(timeoutId);
        
        // Log the raw response for debugging
        const responseText = await response.text();
        logger.info('Webhook response received:', {
          status: response.status,
          statusText: response.statusText,
          responseText,
          requestId: payload.requestId
        });
        
        // Mark this request as processed if it has an ID
        if (payload.requestId) {
          this.processedRequests.add(payload.requestId);
        }
        
        // Check if it's a simple acceptance message (not JSON)
        if (!response.headers.get('content-type')?.includes('application/json')) {
          // If simple text message like "Accepted", consider it a success
          // This is a common pattern with Make.com webhooks
          if (response.ok || responseText.toLowerCase().includes('accept')) {
            return {
              success: true,
              responseData: { message: responseText }
            };
          }
        }
        
        // Try to parse JSON response, but handle potential parsing errors
        let responseData;
        try {
          responseData = responseText ? JSON.parse(responseText) : null;
        } catch (parseError) {
          logger.warn('Unable to parse webhook response as JSON', {
            responseText,
            error: parseError instanceof Error ? parseError.message : String(parseError)
          });
          
          // For non-JSON responses that are OK, consider them successful
          if (response.ok) {
            return {
              success: true,
              responseData: { message: responseText }
            };
          } else {
            return {
              success: false,
              error: `Non-JSON error response: ${responseText}`,
              responseData: { message: responseText }
            };
          }
        }
        
        // If we got this far, we have a parsed JSON response
        if (response.ok) {
          return {
            success: true,
            responseData
          };
        } else {
          return {
            success: false,
            error: responseData?.error || `HTTP error: ${response.status} ${response.statusText}`,
            responseData
          };
        }
      } catch (fetchError) {
        clearTimeout(timeoutId);
        
        // Handle fetch errors (network issues, CORS, etc.)
        const errorMessage = fetchError instanceof Error ? fetchError.message : String(fetchError);
        const isAborted = errorMessage.includes('aborted') || errorMessage.includes('timeout');
        
        logger.error('Fetch error sending webhook:', { 
          error: errorMessage,
          isTimeout: isAborted,
          requestId: payload.requestId
        });
        
        return {
          success: false,
          error: isAborted 
            ? 'Request timed out after 10 seconds' 
            : `Network error: ${errorMessage}`
        };
      }
    } catch (error) {
      logger.error('Exception sending webhook:', { 
        error: error instanceof Error ? error.message : String(error),
        payload 
      });
      
      return {
        success: false,
        error: error instanceof Error ? error.message : String(error)
      };
    }
  }
  
  /**
   * Helper method to validate UUID format
   */
  private isValidUUID(str: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
  }
}

// Export singleton instance
export const webhookManager = WebhookManager.getInstance();
