
// Define public routes that don't require authentication
export const PUBLIC_ROUTES = [
  '/',
  '/signin',
  '/register',
  '/apply',
  '/benefits',
  '/exclusive',
  '/waiting-list'
];

// Check if a path is a public route
export const isPublicRoute = (path: string): boolean => {
  return PUBLIC_ROUTES.some(route => 
    path === route || 
    path.startsWith(`${route}/`)
  );
};
