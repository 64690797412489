
import { useState, useMemo } from "react";
import { Market } from "@/types/market";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { TrendingUp, TrendingDown, CheckCircle, XCircle, Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Sparkle } from "lucide-react";
import { isNewMarket } from "@/utils/market/is-new-market";
import { formatCurrency } from "@/utils/formatters";

interface MarketOverviewProps {
  markets: Market[];
}

export function MarketOverview({ markets }: MarketOverviewProps) {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  
  // Filter state
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [participationFilter, setParticipationFilter] = useState('all');

  // Apply filters
  const filteredMarkets = useMemo(() => {
    return markets.filter(market => {
      // Filter by search query (name or region)
      const matchesSearch = 
        market.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (market.region && market.region.toLowerCase().includes(searchQuery.toLowerCase()));
      
      // Filter by status
      const matchesStatus = statusFilter === 'all' ? true : market.status === statusFilter;
      
      // Filter by participation
      const matchesParticipation = 
        participationFilter === 'all' ? true : 
        participationFilter === 'participating' ? market.isParticipating === true :
        market.isParticipating !== true;
      
      return matchesSearch && matchesStatus && matchesParticipation;
    });
  }, [markets, searchQuery, statusFilter, participationFilter]);

  // Pagination calculations
  const totalPages = Math.ceil(filteredMarkets.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedMarkets = filteredMarkets.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Card className="hover:shadow-lg transition-all duration-300 border-primary/20">
      <CardHeader className="pb-3">
        <CardTitle className="text-xl font-semibold flex items-center gap-2">
          <TrendingUp className="h-5 w-5 text-primary" />
          Market Overview
        </CardTitle>
        <CardDescription>
          Available markets and your participations
        </CardDescription>
      </CardHeader>
      <CardContent>
        {/* Filtering controls */}
        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search by name or region..."
                className="pl-8"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1); // Reset to first page on search
                }}
              />
            </div>
          </div>
          <div className="w-full md:w-40">
            <Select 
              value={statusFilter} 
              onValueChange={(value) => {
                setStatusFilter(value);
                setCurrentPage(1);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Statuses</SelectItem>
                <SelectItem value="active">Active</SelectItem>
                <SelectItem value="completed">Completed</SelectItem>
                <SelectItem value="failed">Failed</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="w-full md:w-40">
            <Select 
              value={participationFilter}
              onValueChange={(value) => {
                setParticipationFilter(value);
                setCurrentPage(1);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Participation" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Markets</SelectItem>
                <SelectItem value="participating">Participating</SelectItem>
                <SelectItem value="not-participating">Not Participating</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="w-full md:w-40">
            <Select 
              value={itemsPerPage.toString()} 
              onValueChange={(value) => {
                setItemsPerPage(Number(value));
                setCurrentPage(1);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Items per page" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="5">5 per page</SelectItem>
                <SelectItem value="10">10 per page</SelectItem>
                <SelectItem value="15">15 per page</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Markets table */}
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Market</TableHead>
                <TableHead>Current Value</TableHead>
                <TableHead>Target Value</TableHead>
                <TableHead>Growth Rate</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {paginatedMarkets.length > 0 ? (
                paginatedMarkets.map((market) => (
                  <TableRow 
                    key={market.id} 
                    className={`hover:bg-muted/5 ${market.isParticipating ? 'bg-primary/5' : ''}`}
                  >
                    <TableCell className="font-medium">
                      {market.name}
                      {market.isParticipating && (
                        <span className="ml-2 text-xs text-primary">(Participating)</span>
                      )}
                      {isNewMarket(market.created_at) && (
                        <span className="ml-2 bg-primary text-primary-foreground text-xs px-1.5 py-0.5 rounded-sm inline-flex items-center gap-0.5">
                          <Sparkle className="h-3 w-3" />
                          New
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(market.current_value)}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(market.target_value)}
                    </TableCell>
                    <TableCell>
                      <span className={`inline-flex items-center gap-1 ${
                        market.growth_rate >= 0 ? 'text-green-500' : 'text-red-500'
                      }`}>
                        {market.growth_rate >= 0 ? (
                          <TrendingUp className="h-4 w-4" />
                        ) : (
                          <TrendingDown className="h-4 w-4" />
                        )}
                        {market.growth_rate >= 0 ? '+' : ''}{market.growth_rate}%
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={`inline-flex items-center gap-1 ${
                        market.status === 'active' ? 'text-green-500' :
                        market.status === 'completed' ? 'text-blue-500' :
                        'text-red-500'
                      }`}>
                        {market.status === 'active' && <CheckCircle className="h-4 w-4" />}
                        {market.status === 'completed' && <CheckCircle className="h-4 w-4" />}
                        {market.status === 'failed' && <XCircle className="h-4 w-4" />}
                        <span className="capitalize">{market.status}</span>
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="h-24 text-center">
                    No markets found matching your filters.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {/* Pagination controls */}
        {filteredMarkets.length > 0 && (
          <div className="flex items-center justify-between space-x-2 py-4">
            <div className="text-sm text-muted-foreground">
              Showing {Math.min(filteredMarkets.length, 1) + startIndex}-{Math.min(startIndex + itemsPerPage, filteredMarkets.length)} of {filteredMarkets.length} markets
            </div>
            <div className="flex space-x-2">
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setCurrentPage(1)}
                disabled={currentPage <= 1}
              >
                First
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
                disabled={currentPage <= 1}
              >
                Previous
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
                disabled={currentPage >= totalPages}
              >
                Next
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage >= totalPages}
              >
                Last
              </Button>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
