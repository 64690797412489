import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Lock, Check, X } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Progress } from "@/components/ui/progress";

interface SecurityStepProps {
  formData: {
    password: string;
    confirmPassword: string;
    agreeToTerms: boolean;
  };
  errors: {
    password?: string;
    confirmPassword?: string;
    agreeToTerms?: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (checked: boolean) => void;
}

export const SecurityStep = ({ formData, errors, onChange, onCheckboxChange }: SecurityStepProps) => {
  const calculatePasswordStrength = (password: string): number => {
    let strength = 0;
    if (password.length >= 8) strength += 25;
    if (password.match(/[A-Z]/)) strength += 25;
    if (password.match(/[0-9]/)) strength += 25;
    if (password.match(/[^A-Za-z0-9]/)) strength += 25;
    return strength;
  };

  const passwordStrength = calculatePasswordStrength(formData.password);

  const getStrengthColor = (strength: number) => {
    if (strength <= 25) return "bg-red-500";
    if (strength <= 50) return "bg-orange-500";
    if (strength <= 75) return "bg-yellow-500";
    return "bg-green-500";
  };

  return (
    <div className="space-y-4 animate-fade-in">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <Label htmlFor="password">Password</Label>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-sm text-muted-foreground">
                ?
              </TooltipTrigger>
              <TooltipContent>
                <p>Must be at least 8 characters with letters, numbers, and symbols</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="relative">
          <Lock className="absolute left-3 top-3 h-5 w-5 text-muted-foreground" />
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="Create a password"
            value={formData.password}
            onChange={onChange}
            className={`pl-10 ${errors.password ? 'border-red-500' : ''}`}
            required
          />
        </div>
        {errors.password && (
          <p className="text-sm text-red-500 mt-1">{errors.password}</p>
        )}
        <div className="space-y-2">
          <Progress value={passwordStrength} className={`h-2 ${getStrengthColor(passwordStrength)}`} />
          <div className="text-sm space-y-1">
            <div className="flex items-center gap-2">
              {formData.password.length >= 8 ? <Check className="h-4 w-4 text-green-500" /> : <X className="h-4 w-4 text-red-500" />}
              <span>At least 8 characters</span>
            </div>
            <div className="flex items-center gap-2">
              {/[A-Z]/.test(formData.password) ? <Check className="h-4 w-4 text-green-500" /> : <X className="h-4 w-4 text-red-500" />}
              <span>Contains uppercase letter</span>
            </div>
            <div className="flex items-center gap-2">
              {/[0-9]/.test(formData.password) ? <Check className="h-4 w-4 text-green-500" /> : <X className="h-4 w-4 text-red-500" />}
              <span>Contains number</span>
            </div>
            <div className="flex items-center gap-2">
              {/[^A-Za-z0-9]/.test(formData.password) ? <Check className="h-4 w-4 text-green-500" /> : <X className="h-4 w-4 text-red-500" />}
              <span>Contains special character</span>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="confirmPassword">Confirm Password</Label>
        <div className="relative">
          <Lock className="absolute left-3 top-3 h-5 w-5 text-muted-foreground" />
          <Input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="Confirm your password"
            value={formData.confirmPassword}
            onChange={onChange}
            className={`pl-10 ${errors.confirmPassword ? 'border-red-500' : ''}`}
            required
          />
        </div>
        {errors.confirmPassword && (
          <p className="text-sm text-red-500 mt-1">{errors.confirmPassword}</p>
        )}
      </div>

      <div className="flex items-start space-x-2">
        <Checkbox
          id="terms"
          checked={formData.agreeToTerms}
          onCheckedChange={onCheckboxChange}
          className={errors.agreeToTerms ? 'border-red-500' : ''}
        />
        <div className="grid gap-1.5 leading-none">
          <label
            htmlFor="terms"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            I agree to the Terms & Conditions
          </label>
          <p className="text-sm text-muted-foreground">
            By checking this box, you agree to our Terms of Service and Privacy Policy
          </p>
        </div>
      </div>
      {errors.agreeToTerms && (
        <p className="text-sm text-red-500">{errors.agreeToTerms}</p>
      )}
    </div>
  );
};