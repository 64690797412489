
import React from 'react';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import AdminDashboard from '@/pages/AdminDashboard';
import UserManagement from '@/pages/admin/UserManagement';
import WaitingList from '@/pages/admin/WaitingList';
import MarketManagement from '@/pages/admin/MarketManagement';
import TransactionManagement from '@/pages/admin/TransactionManagement';
import FinancialTracking from '@/pages/admin/FinancialTracking';
import AdminPanel from '@/pages/admin/AdminPanel';

interface RouteConfig {
  path: string;
  element: React.ReactNode;
}

export const adminRoutes: RouteConfig[] = [
  {
    path: '/admin',
    element: <ProtectedRoute requireAdmin={true}><AdminDashboard /></ProtectedRoute>
  },
  {
    path: '/admin/panel',
    element: <ProtectedRoute requireAdmin={true}><AdminPanel /></ProtectedRoute>
  },
  {
    path: '/admin/markets/:marketId',
    element: <ProtectedRoute requireAdmin={true}><MarketManagement /></ProtectedRoute>
  },
  {
    path: '/admin/users',
    element: <ProtectedRoute requireAdmin={true}><UserManagement /></ProtectedRoute>
  },
  {
    path: '/admin/waiting-list',
    element: <ProtectedRoute requireAdmin={true}><WaitingList /></ProtectedRoute>
  },
  {
    path: '/admin/markets',
    element: <ProtectedRoute requireAdmin={true}><MarketManagement /></ProtectedRoute>
  },
  {
    path: '/admin/financial',
    element: <ProtectedRoute requireAdmin={true}><FinancialTracking /></ProtectedRoute>
  },
  {
    path: '/admin/financial-tracking',
    element: <ProtectedRoute requireAdmin={true}><FinancialTracking /></ProtectedRoute>
  }
];
