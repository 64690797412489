
import { AuthState, AuthAction } from "@/types/auth";

export const initialState: AuthState = {
  session: null,
  user: null,
  isLoading: true,
  isAdmin: false,
  isSigningOut: false,
};

export function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'SET_SESSION':
      return {
        ...state,
        session: action.payload.session,
        user: action.payload.user,
      };
    case 'SET_ADMIN':
      return {
        ...state,
        isAdmin: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_SIGNING_OUT':
      return {
        ...state,
        isSigningOut: action.payload,
      };
    case 'SIGN_OUT':
      return {
        ...initialState,
        isLoading: false,
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
}
