
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Loader2 } from "lucide-react";

interface SignInFormProps {
  isAdmin: boolean;
  isLoading: boolean;
  isCheckingAdmin: boolean;
  redirectInitiated: boolean;
  email: string;
  password: string;
  errors: {
    email?: string;
    password?: string;
    general?: string;
  };
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
}

export const SignInForm = ({
  isAdmin,
  isLoading,
  isCheckingAdmin,
  redirectInitiated,
  email,
  password,
  errors,
  onEmailChange,
  onPasswordChange,
  onSubmit
}: SignInFormProps) => {
  return (
    <form onSubmit={onSubmit} className="mt-8 space-y-6">
      {errors.general && (
        <div className="p-4 text-sm bg-destructive/10 border border-destructive/20 rounded-md text-destructive">
          {errors.general}
        </div>
      )}

      <div className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-foreground">
            {isAdmin ? "Admin Email" : "Email address"}
          </label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={onEmailChange}
            className={errors.email ? 'border-destructive' : ''}
            placeholder={isAdmin ? "Enter your admin email" : "Enter your email"}
            disabled={isLoading || isCheckingAdmin}
            autoComplete="email"
          />
          {errors.email && (
            <p className="mt-2 text-sm text-destructive">{errors.email}</p>
          )}
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-foreground">
            Password
          </label>
          <Input
            id="password"
            type="password"
            value={password}
            onChange={onPasswordChange}
            className={errors.password ? 'border-destructive' : ''}
            placeholder="Enter your password"
            disabled={isLoading || isCheckingAdmin}
            autoComplete="current-password"
          />
          {errors.password && (
            <p className="mt-2 text-sm text-destructive">{errors.password}</p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="text-sm">
          <Link 
            to={isAdmin ? "/admin/register" : "/register"} 
            className="text-primary hover:text-primary/80"
          >
            {isAdmin ? "Create admin account" : "Don't have an account?"}
          </Link>
        </div>
        <div className="text-sm">
          <Link to="/forgot-password" className="text-primary hover:text-primary/80">
            Forgot password?
          </Link>
        </div>
      </div>

      <Button
        type="submit"
        className="w-full"
        disabled={isLoading || isCheckingAdmin || redirectInitiated}
      >
        {isLoading || isCheckingAdmin ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {isCheckingAdmin ? "Verifying access..." : "Signing in..."}
          </>
        ) : (
          isAdmin ? "Sign in to Admin Portal" : "Sign in"
        )}
      </Button>
    </form>
  );
};
