
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { useWaitingList } from "@/hooks/admin/use-waiting-list";
import { WaitingListCard } from "@/components/admin/users/WaitingListCard";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { RefreshCw, UserPlus, AlertTriangle } from "lucide-react";
import { useState, useEffect } from "react";
import { logger } from "@/utils/logger";

const WaitingList = () => {
  const {
    users,
    isLoading,
    approveUser,
    rejectUser,
    refreshList
  } = useWaitingList();
  
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  const handleRefresh = async () => {
    setIsRefreshing(true);
    await refreshList();
    setIsRefreshing(false);
  };

  // Log when the component mounts and how many users are found
  useEffect(() => {
    logger.info('Admin WaitingList component mounted');
    if (!isLoading) {
      logger.info(`Waiting list has ${users.length} users`);
    }
  }, [isLoading, users.length]);

  return (
    <DashboardSidebar isAdmin>
      <div className="flex-1 w-full">
        <div className="sticky top-0 z-10 border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex h-16 items-center gap-4 px-6">
            <div className="flex items-center gap-3">
              <UserPlus className="h-5 w-5 text-primary" />
              <h1 className="text-3xl font-bold text-primary">Waiting List</h1>
            </div>
            <div className="ml-auto">
              <Button 
                variant="outline" 
                onClick={handleRefresh} 
                disabled={isRefreshing}
                className="flex items-center gap-2 bg-card hover:bg-muted/20"
              >
                <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
                Refresh List
              </Button>
            </div>
          </div>
        </div>

        <div className="p-6 max-w-[1400px] mx-auto w-full">
          {users.length === 0 && !isLoading ? (
            <Card className="bg-gradient-to-br from-card to-muted/30 rounded-lg p-0.5 shadow-lg">
              <CardHeader className="pb-2">
                <CardTitle className="flex items-center gap-2">
                  <AlertTriangle className="text-amber-500 h-5 w-5" />
                  No Users in Waiting List
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">
                  There are currently no users waiting for approval. When users register, they will appear here.
                </p>
                <Button 
                  variant="outline" 
                  onClick={handleRefresh} 
                  className="mt-4 flex items-center gap-2"
                >
                  <RefreshCw className="h-4 w-4" />
                  Refresh
                </Button>
              </CardContent>
            </Card>
          ) : (
            <div className="bg-gradient-to-br from-card to-muted/30 rounded-lg p-0.5 shadow-lg">
              <WaitingListCard
                users={users}
                isLoading={isLoading}
                onApprove={approveUser}
                onReject={rejectUser}
                onRefresh={refreshList}
              />
            </div>
          )}
        </div>
      </div>
    </DashboardSidebar>
  );
};

export default WaitingList;
