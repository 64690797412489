
import { RefreshCcw } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

interface MarketErrorProps {
  onRetry: () => void;
}

export const MarketError = ({ onRetry }: MarketErrorProps) => (
  <Card className="bg-destructive/10">
    <CardHeader>
      <CardTitle className="text-destructive">Error Loading Markets</CardTitle>
      <CardDescription>
        There was a problem loading the market data. Please try again.
      </CardDescription>
    </CardHeader>
    <CardContent>
      <Button onClick={onRetry} variant="outline" className="gap-2">
        <RefreshCcw className="h-4 w-4" />
        Retry
      </Button>
    </CardContent>
  </Card>
);
