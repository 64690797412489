
// Import improved sync helpers
import { logger } from '@/utils/logger';
import { supabase } from '@/lib/supabase';
import { MARKET_SYNC_EVENTS, dispatchMarketSyncEvent } from './sync/market-sync-events';
import { normalizeRegionCode } from './formatters';
import { toast } from 'sonner';

// MarketTargetSettings interface for consistency
export interface MarketTargetSettings {
  baseTarget: number;
  adjustment: number;
  factor: number;
  adjustedTarget: number;
}

// Validate target settings to ensure they're valid
export const validateTargetSettings = (settings: Partial<MarketTargetSettings>): { valid: boolean; errors: string[] } => {
  const errors: string[] = [];
  
  if (settings.baseTarget !== undefined && (isNaN(settings.baseTarget) || settings.baseTarget <= 0)) {
    errors.push('Base target must be a positive number');
  }
  
  if (settings.factor !== undefined && (isNaN(settings.factor) || settings.factor <= 0)) {
    errors.push('Factor must be a positive number');
  }
  
  if (settings.adjustment !== undefined && isNaN(settings.adjustment)) {
    errors.push('Adjustment must be a number');
  }
  
  return {
    valid: errors.length === 0,
    errors
  };
};

// Sync regional targets with the specified settings - now with real database integration and no localStorage
export const syncRegionalTargets = async (
  regions: string[],
  settings: MarketTargetSettings
): Promise<boolean> => {
  try {
    logger.info(`Syncing regions ${regions.join(', ')} with settings:`, settings);
    
    let success = true;
    
    // Update each region in the database
    for (const region of regions) {
      const normalizedRegion = normalizeRegionCode(region);
      
      try {
        // First update the regional_settings table
        const { error: settingsError } = await supabase
          .rpc('set_regional_settings', {
            p_region: normalizedRegion,
            p_base_target: settings.baseTarget,
            p_adjustment: settings.adjustment,
            p_adjustment_factor: settings.factor
          });
        
        if (settingsError) {
          logger.error(`Error updating regional settings for ${normalizedRegion}:`, settingsError);
          success = false;
          continue;
        }
        
        // Then update the system regional target
        const { error: targetError } = await supabase
          .rpc('update_system_regional_target', {
            p_region: normalizedRegion,
            p_base_target: settings.baseTarget,
            p_adjustment: settings.adjustment,
            p_factor: settings.factor,
            p_target_value: settings.adjustedTarget
          });
        
        if (targetError) {
          logger.error(`Error updating system regional target for ${normalizedRegion}:`, targetError);
          success = false;
          continue;
        }
        
        logger.info(`Successfully updated regional settings for ${normalizedRegion}`);
      } catch (err) {
        logger.error(`Exception updating region ${normalizedRegion}:`, err);
        success = false;
      }
    }
    
    // Dispatch event regardless of success to ensure UI updates
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.REGIONAL_TARGETS_UPDATED, {
      regions,
      settings,
      success,
      timestamp: new Date().toISOString()
    });
    
    return success;
  } catch (error) {
    logger.error('Error syncing regional targets:', error);
    
    // Dispatch event with error information
    dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.REGIONAL_TARGETS_UPDATED, {
      regions,
      settings,
      success: false,
      error: error instanceof Error ? error.message : 'Unknown error',
      timestamp: new Date().toISOString()
    });
    
    return false;
  }
};

// Fetch system-level regional target settings - directly from database, no localStorage caching
export const fetchSystemRegionalTargets = async (): Promise<Record<string, MarketTargetSettings> | null> => {
  try {
    // Fetch the latest data from the database
    logger.info('Fetching system regional targets from database');
    
    const { data: settingsData, error: settingsError } = await supabase
      .from('regional_settings')
      .select('*');
    
    if (settingsError) {
      throw settingsError;
    }
    
    const result: Record<string, MarketTargetSettings> = {};
    
    // Transform database results into the expected format
    if (settingsData && settingsData.length > 0) {
      settingsData.forEach(setting => {
        const region = normalizeRegionCode(setting.region_code);
        result[region] = {
          baseTarget: setting.base_target,
          adjustment: setting.adjustment,
          factor: setting.adjustment_factor,
          adjustedTarget: setting.base_target + (setting.adjustment / setting.adjustment_factor)
        };
      });
      
      // Dispatch event to notify that system targets are loaded
      dispatchMarketSyncEvent(MARKET_SYNC_EVENTS.SYSTEM_TARGETS_LOADED, {
        regions: Object.keys(result),
        timestamp: new Date().toISOString()
      });
      
      return result;
    }
    
    // If no data found, return default values
    const defaultData: Record<string, MarketTargetSettings> = {
      US: { baseTarget: 5000, adjustment: 500, factor: 1.07, adjustedTarget: 5467.29 },
      UK: { baseTarget: 5000, adjustment: 500, factor: 1.07, adjustedTarget: 5467.29 },
      AU: { baseTarget: 5000, adjustment: 500, factor: 1.07, adjustedTarget: 5467.29 },
      ZA: { baseTarget: 5000, adjustment: 500, factor: 1.07, adjustedTarget: 5467.29 }
    };
    
    return defaultData;
  } catch (error) {
    logger.error('Error fetching system regional targets:', error);
    
    // Don't fall back to localStorage as a last resort
    return null;
  }
};

// Fetch regional settings - using the database
export const fetchRegionalSettings = async (): Promise<Record<string, MarketTargetSettings> | null> => {
  // Re-use the same function for consistency
  return fetchSystemRegionalTargets();
};

// Export the refreshMemberTargets function from regional-sync-utils
export const refreshMemberTargets = async (userId?: string): Promise<boolean> => {
  try {
    logger.info(`Refreshing targets for ${userId ? 'member ' + userId : 'all members'}`);
    
    if (userId) {
      // Refresh targets for a specific member only
      const { data: systemTargets, error: systemError } = await supabase
        .from('regional_targets')
        .select('*')
        .eq('user_id', '00000000-0000-0000-0000-000000000000');
        
      if (systemError) {
        logger.error('Error fetching system targets:', systemError);
        toast.error('Failed to load system targets');
        return false;
      }
      
      // For each system target, update this specific member's target
      const results = await Promise.all(systemTargets.map(async (target) => {
        const { error } = await supabase
          .from('regional_targets')
          .upsert({
            user_id: userId,
            region_code: target.region_code,
            base_target: target.base_target,
            adjustment: target.adjustment,
            factor: target.factor,
            target_value: target.target_value,
            updated_at: new Date().toISOString()
          }, {
            onConflict: 'user_id,region_code'
          });
          
        if (error) {
          logger.error(`Error updating target for user ${userId}, region ${target.region_code}:`, error);
          return false;
        }
        
        return true;
      }));
      
      const success = results.every(Boolean);
      
      if (success) {
        // Emit event for just this member's targets
        window.dispatchEvent(new CustomEvent('member-target-updated', {
          detail: {
            userId,
            timestamp: new Date().toISOString(),
            forceRefresh: true
          }
        }));
        
        toast.success(`Successfully refreshed targets for member`);
      } else {
        toast.warning('Some target updates failed');
      }
      
      return success;
    } else {
      // Call the stored procedure to sync all member targets from system
      const { data, error } = await supabase
        .rpc('sync_member_targets_from_system');
        
      if (error) {
        logger.error('Error syncing member targets from system:', error);
        toast.error('Failed to sync member targets');
        return false;
      }
      
      toast.success('Successfully updated all member targets from system defaults');
      
      // Dispatch event to refresh UI
      window.dispatchEvent(new CustomEvent('all-member-targets-refreshed', {
        detail: {
          timestamp: new Date().toISOString(),
          forceRefresh: true
        }
      }));
      
      return true;
    }
  } catch (error) {
    logger.error('Error in refreshMemberTargets:', error);
    toast.error(`Failed to refresh member targets: ${error instanceof Error ? error.message : 'Unknown error'}`);
    return false;
  }
};

// Sync member targets with the specified settings - direct database update, no localStorage
export const syncMemberTargets = async (
  userId: string,
  data: any[]
): Promise<boolean> => {
  try {
    // Implementation logic would go here
    // This function syncs the targets for a specific member
    logger.info(`Syncing targets for member ${userId} with data:`, data);
    
    // Create a transaction to ensure all updates are atomic
    let success = true;
    
    for (const item of data) {
      // Type check for the item and ensure it has the needed properties
      if (!item) continue;
      
      // Get region code from various possible properties
      let regionCode = '';
      if (typeof item === 'object') {
        // Try to extract regionCode from different possible object structures
        if ('regionCode' in item && typeof item.regionCode === 'string') {
          regionCode = item.regionCode;
        } else if ('id' in item && typeof item.id === 'string' && item.id.length <= 3) {
          // If id is short, it might be a region code
          regionCode = item.id;
        }
      }
      
      if (!regionCode) continue;
      
      const region = normalizeRegionCode(regionCode);
      
      // Extract target data with proper type checking
      let targetData: MarketTargetSettings | undefined;
      
      if (typeof item === 'object' && item !== null) {
        // Check if the item itself is a MarketTargetSettings
        if ('baseTarget' in item && 'adjustment' in item && 'factor' in item) {
          targetData = {
            baseTarget: Number(item.baseTarget) || 5000,
            adjustment: Number(item.adjustment) || 500,
            factor: Number(item.factor) || 1.07,
            adjustedTarget: Number(item.adjustedTarget) || (Number(item.baseTarget) + (Number(item.adjustment) / Number(item.factor)))
          };
        } 
        // Check for nested target structure
        else if ('targets' in item && typeof item.targets === 'object' && item.targets && region in item.targets) {
          const regionTarget = item.targets[region];
          if (typeof regionTarget === 'object' && regionTarget) {
            targetData = {
              baseTarget: Number(regionTarget.baseTarget) || 5000,
              adjustment: Number(regionTarget.adjustment) || 500,
              factor: Number(regionTarget.factor) || 1.07,
              adjustedTarget: Number(regionTarget.adjustedTarget) || (Number(regionTarget.baseTarget) + (Number(regionTarget.adjustment) / Number(regionTarget.factor)))
            };
          }
        }
      }
      
      if (!targetData) {
        // Use default values if we couldn't extract proper data
        targetData = {
          baseTarget: 5000,
          adjustment: 500,
          factor: 1.07,
          adjustedTarget: 5467.29
        };
      }
      
      try {
        // Update the member's regional target directly in the database
        const { error } = await supabase
          .from('regional_targets')
          .upsert({
            user_id: userId,
            region_code: region,
            base_target: targetData.baseTarget,
            adjustment: targetData.adjustment,
            factor: targetData.factor,
            target_value: targetData.adjustedTarget,
            updated_at: new Date().toISOString()
          }, {
            onConflict: 'user_id,region_code'
          });
        
        if (error) {
          logger.error(`Error updating target for region ${region}:`, error);
          success = false;
        }
      } catch (err) {
        logger.error(`Error syncing target for region ${region}:`, err);
        success = false;
      }
    }
    
    // Emit custom event for target update
    window.dispatchEvent(new CustomEvent('member-targets-synced', {
      detail: {
        userId,
        timestamp: new Date().toISOString(),
        data,
        forceRefresh: true
      }
    }));
    
    return success;
  } catch (error) {
    logger.error(`Error syncing targets for member ${userId}:`, error);
    return false;
  }
};

// Update dashboard targets based on calculator data - direct database update
export const updateDashboardTargets = async (data: any[]): Promise<boolean> => {
  try {
    // This function updates the dashboard with the latest target data
    logger.info('Updating dashboard targets with data:', data);
    
    if (!Array.isArray(data) || data.length === 0) {
      logger.error('Invalid data provided to updateDashboardTargets');
      return false;
    }
    
    // Process each target update directly to the database
    let success = true;
    
    for (const item of data) {
      if (!item || !item.id) continue;
      
      const userId = item.id;
      
      // Ensure targets is an object before proceeding
      const targets = (typeof item.targets === 'object' && item.targets !== null) ? item.targets : {};
      
      // Update each region's target for this user
      for (const [region, targetData] of Object.entries(targets)) {
        try {
          const normalizedRegion = normalizeRegionCode(region);
          
          // Type guard to ensure targetData is an object and has the expected properties
          if (!targetData || typeof targetData !== 'object') continue;
          
          // Create a properly typed target object from the data
          const typedTargetData: MarketTargetSettings = {
            baseTarget: typeof targetData === 'object' && 'baseTarget' in targetData ? Number(targetData.baseTarget) || 5000 : 5000,
            adjustment: typeof targetData === 'object' && 'adjustment' in targetData ? Number(targetData.adjustment) || 500 : 500,
            factor: typeof targetData === 'object' && 'factor' in targetData ? Number(targetData.factor) || 1.07 : 1.07,
            adjustedTarget: typeof targetData === 'object' && 'adjustedTarget' in targetData ? 
              Number(targetData.adjustedTarget) : 
              (typeof targetData === 'number' ? targetData : 5467.29)
          };
          
          // Update the regional target directly in the database
          const { error } = await supabase
            .from('regional_targets')
            .upsert({
              user_id: userId,
              region_code: normalizedRegion,
              base_target: typedTargetData.baseTarget,
              adjustment: typedTargetData.adjustment,
              factor: typedTargetData.factor,
              target_value: typedTargetData.adjustedTarget,
              updated_at: new Date().toISOString()
            }, {
              onConflict: 'user_id,region_code'
            });
          
          if (error) {
            logger.error(`Error updating regional target for user ${userId}, region ${normalizedRegion}:`, error);
            success = false;
          }
        } catch (err) {
          logger.error(`Error updating regional target:`, err);
          success = false;
        }
      }
    }
    
    // Emit custom event for dashboard update with more detailed data
    window.dispatchEvent(new CustomEvent('dashboard-targets-updated', {
      detail: {
        timestamp: new Date().toISOString(),
        data,
        count: Array.isArray(data) ? data.length : 0,
        forceRefresh: true
      }
    }));
    
    return success;
  } catch (error) {
    logger.error('Error updating dashboard targets:', error);
    return false;
  }
};

// Refresh all markets with system targets - direct database update
export const updateMarketsWithSystemTargets = async (): Promise<boolean> => {
  try {
    // This function updates all markets with the latest system target settings
    logger.info('Updating all markets with system target settings');
    
    // We need to sync all member targets from system defaults
    const { error } = await supabase
      .rpc('sync_member_targets_from_system');
      
    if (error) {
      logger.error('Error syncing member targets from system:', error);
      return false;
    }
    
    // Emit custom event for market update
    window.dispatchEvent(new CustomEvent('markets-updated-with-system-targets', {
      detail: {
        timestamp: new Date().toISOString(),
        success: true,
        marketsAffected: true,
        forceRefresh: true
      }
    }));
    
    return true;
  } catch (error) {
    logger.error('Error updating markets with system targets:', error);
    return false;
  }
};
