
import { useCallback } from "react";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { useAuthUtils } from "./useAuthUtils";
import { AuthDispatch } from "@/types/auth";
import { isPublicRoute } from "@/config/routes";

interface UseAuthStateChangeProps {
  dispatch: AuthDispatch;
  mounted: boolean;
}

export const useAuthStateChange = ({ dispatch, mounted }: UseAuthStateChangeProps) => {
  const { verifyAndUpdateAdminStatus } = useAuthUtils();

  const setupAuthStateChange = useCallback(() => {
    return supabase.auth.onAuthStateChange(
      async (event, currentSession) => {
        if (!mounted) return;

        logger.info('Auth state changed', { event });

        if ((event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') && currentSession?.user) {
          logger.info(`User ${event.toLowerCase()}`, { userId: currentSession.user.id });
          
          // Update session state
          dispatch({ 
            type: 'SET_SESSION', 
            payload: { session: currentSession, user: currentSession.user } 
          });
          
          // Set admin status based on email domain
          const isAdmin = currentSession.user.email?.endsWith('@thecolosseum.co.za') || false;
          dispatch({ type: 'SET_ADMIN', payload: isAdmin });
          
          if (isAdmin) {
            logger.info('Admin privileges confirmed', { userId: currentSession.user.id });
          }
          
        } else if (event === 'SIGNED_OUT') {
          logger.info('User session ended', { event });
          dispatch({ type: 'SIGN_OUT' });
          localStorage.removeItem('userRole');
          localStorage.removeItem('app-session');
          
          const currentPath = window.location.pathname;
          if (!isPublicRoute(currentPath)) {
            window.location.href = "/signin";
          }
        }
      }
    );
  }, [dispatch, mounted]);

  return { setupAuthStateChange };
};
