
import React from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Archive, RefreshCcw } from "lucide-react";
import { Button } from "@/components/ui/button";

interface EmptyStateProps {
  onRefresh: () => void;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ onRefresh }) => {
  return (
    <Card className="border-t-4 border-t-muted-foreground/50 shadow-md">
      <CardHeader className="pb-3 bg-zinc-100 dark:bg-zinc-900/60">
        <div className="flex justify-between items-center">
          <CardTitle className="text-xl font-semibold flex items-center gap-2 text-zinc-800 dark:text-zinc-100">
            <Archive className="h-5 w-5 text-muted-foreground" /> Closed Markets
          </CardTitle>
        </div>
        <CardDescription className="text-zinc-600 dark:text-zinc-400">
          Markets that have been completed or closed for participation
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col items-center justify-center py-12 text-center">
          <div className="bg-muted/30 p-4 rounded-full mb-4">
            <Archive className="h-12 w-12 text-muted-foreground" />
          </div>
          <h3 className="text-xl font-medium mb-2 text-zinc-800 dark:text-zinc-200">No closed markets found</h3>
          <p className="text-zinc-600 dark:text-zinc-400 max-w-md mb-6">
            When markets are completed or closed, they will appear here for your reference.
          </p>
          <Button variant="outline" onClick={onRefresh} className="gap-2 text-zinc-700 dark:text-zinc-300">
            <RefreshCcw className="h-4 w-4" />
            Refresh
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
