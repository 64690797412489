import { Market } from "@/types/market";
import { supabase } from "@/lib/supabase";
import { toast } from "sonner";
import { logger } from "@/utils/logger";
import { useQueryInvalidation } from "./use-query-invalidation";
import { extractErrorDetails } from "@/utils/error-handling/supabase-error";

/**
 * Hook for handling market deletion
 */
export const useMarketDelete = (market: Market, onUpdate: () => void) => {
  const { invalidateMarketQueries } = useQueryInvalidation();

  const handleDelete = async (
    loading: boolean,
    setLoading: (loading: boolean) => void
  ) => {
    if (loading) return;

    try {
      setLoading(true);
      
      // First verify admin status
      const { data: isAdmin, error: adminCheckError } = await supabase.rpc('is_admin_email_direct');
      
      if (adminCheckError) {
        logger.error('Admin check error:', adminCheckError);
        throw new Error('Failed to verify admin privileges');
      }
      
      if (!isAdmin) {
        throw new Error('Only administrators can delete markets');
      }
      
      // After verification, get user ID
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      logger.info('Deleting market', { marketId: market.id });

      const { error } = await supabase.rpc('delete_market', {
        market_id_param: market.id,
        admin_id: user.id
      });

      if (error) {
        const errorDetails = extractErrorDetails(error);
        logger.error('Error deleting market:', {
          marketId: market.id,
          error: errorDetails.message,
          code: errorDetails.code || 'None',
          details: errorDetails.details || 'None',
          hint: errorDetails.hint || 'None'
        });
        throw error;
      }

      // Ensure we invalidate all relevant queries
      await invalidateMarketQueries(market.id);
      
      toast.success("Market has been deleted successfully.");

      onUpdate();
    } catch (error) {
      const err = error as Error;
      toast.error(err.message || "Failed to delete market.");
      throw err; // Re-throw to allow caller to handle it
    } finally {
      setLoading(false);
    }
  };

  return { handleDelete };
};
