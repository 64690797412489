
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { FinancialTargetConfig } from "@/components/admin/financials/FinancialTargetConfig";
import { RegionalTargetSettings } from "@/components/admin/financials/RegionalTargetSettings";
import { DeleteAllMarketsDialog } from "@/components/admin/system/DeleteAllMarketsDialog";
import { FinancialSettings, RegionalSettings } from '@/types/financial';

interface ConfigurationTabProps {
  settings: FinancialSettings | null;
  regionalSettings: RegionalSettings[];
  isLoading: boolean;
  updateSettings: (baseTarget: number, adjustmentFactor: number) => Promise<any>;
  handleUpdateRegionalSettings: (regionData: any) => Promise<any>;
}

export const ConfigurationTab: React.FC<ConfigurationTabProps> = ({
  settings,
  regionalSettings,
  isLoading,
  updateSettings,
  handleUpdateRegionalSettings
}) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="border-primary/20 shadow-lg hover:shadow-xl transition-all">
          <CardHeader className="bg-gradient-to-br from-card to-muted/30 rounded-t-lg pb-4">
            <CardTitle className="text-xl text-primary">Financial Settings</CardTitle>
            <CardDescription>
              Configure global financial parameters
            </CardDescription>
          </CardHeader>
          <CardContent className="pt-6">
            {settings && (
              <FinancialTargetConfig 
                currentSettings={settings}
                onSettingsUpdated={updateSettings}
              />
            )}
          </CardContent>
        </Card>
        
        <Card className="border-primary/20 shadow-lg hover:shadow-xl transition-all">
          <CardHeader className="bg-gradient-to-br from-card to-muted/30 rounded-t-lg pb-4">
            <CardTitle className="text-xl text-primary">Regional Target Settings</CardTitle>
            <CardDescription>
              Configure region-specific target parameters
            </CardDescription>
          </CardHeader>
          <CardContent className="pt-6">
            <RegionalTargetSettings
              regions={regionalSettings.map(rs => ({
                region: rs.region,
                baseTarget: rs.baseTarget,
                adjustment: rs.adjustment,
                adjustmentFactor: rs.adjustmentFactor
              }))}
              isLoading={isLoading}
              onSaveRegion={handleUpdateRegionalSettings}
            />
          </CardContent>
        </Card>
      </div>
      
      <Card className="border-primary/20 shadow-lg hover:shadow-xl transition-all">
        <CardHeader className="bg-gradient-to-br from-card to-muted/30 rounded-t-lg pb-4">
          <CardTitle className="text-xl text-primary text-destructive">Danger Zone</CardTitle>
          <CardDescription>
            Destructive operations that cannot be undone
          </CardDescription>
        </CardHeader>
        <CardContent className="pt-6">
          <div className="space-y-6">
            <div className="border border-destructive/30 rounded-lg p-4">
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="font-medium mb-1 text-destructive">Delete All Markets</h3>
                  <p className="text-sm text-muted-foreground mb-2">
                    Permanently delete all markets and related data from the database.
                  </p>
                </div>
                <DeleteAllMarketsDialog />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
