
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Download, FileDown, Loader2 } from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { addDays } from "date-fns";
import { useToast } from "@/hooks/use-toast";
import { DateRange } from "react-day-picker";
import { useAuth } from "@/contexts/AuthContext";

const Reports = () => {
  const { toast } = useToast();
  const { session } = useAuth();
  const [date, setDate] = useState<DateRange>({
    from: addDays(new Date(), -30),
    to: new Date(),
  });

  const { data: reports, isLoading } = useQuery({
    queryKey: ['reports', date],
    queryFn: async () => {
      if (!date.from || !date.to || !session) return [];
      
      const { data, error } = await supabase
        .from('reports')
        .select('*')
        .gte('date_range_start', date.from.toISOString())
        .lte('date_range_end', date.to.toISOString())
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching reports:', error);
        toast({
          title: "Error fetching reports",
          description: error.message,
          variant: "destructive",
        });
        throw error;
      }
      return data;
    },
    enabled: !!session, // Only run query if user is authenticated
  });

  const handleExport = async (format: 'pdf' | 'csv') => {
    toast({
      title: "Export started",
      description: `Your ${format.toUpperCase()} report is being generated...`,
    });
    // TODO: Implement export functionality
  };

  if (isLoading) {
    return (
      <DashboardSidebar>
        <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
        </div>
      </DashboardSidebar>
    );
  }

  return (
    <DashboardSidebar>
      <div className="p-8 space-y-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold tracking-tight">Reports & Analytics</h1>
            <p className="text-muted-foreground mt-2">
              View and analyze your financial and market performance
            </p>
          </div>
          <div className="flex gap-4">
            <Button variant="outline" onClick={() => handleExport('csv')}>
              <FileDown className="mr-2 h-4 w-4" />
              Export CSV
            </Button>
            <Button variant="outline" onClick={() => handleExport('pdf')}>
              <Download className="mr-2 h-4 w-4" />
              Export PDF
            </Button>
          </div>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Date Range</CardTitle>
          </CardHeader>
          <CardContent>
            <DateRangePicker 
              value={date} 
              onChange={(newDate) => newDate && setDate(newDate)} 
            />
          </CardContent>
        </Card>

        <div className="grid gap-6 md:grid-cols-2">
          <Card>
            <CardHeader>
              <CardTitle>Market Performance</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={reports}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="created_at" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#CDA434" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>AI Insights</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {reports?.map((report) => (
                  <div key={report.id} className="p-4 border rounded-lg">
                    <h3 className="font-semibold">{report.title}</h3>
                    <p className="text-sm text-muted-foreground mt-1">
                      {JSON.stringify(report.content)}
                    </p>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </DashboardSidebar>
  );
};

export default Reports;
