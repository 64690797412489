
import React, { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Search, X, Filter } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { cn } from '@/lib/utils';

interface MarketFiltersProps {
  searchQuery: string;
  selectedRegion: string;
  riskLevel: string;
  onSearchChange: (value: string) => void;
  onRegionChange: (value: string) => void;
  onRiskLevelChange: (value: string) => void;
  onRefresh?: () => Promise<void>;
  regions?: string[];
  className?: string;
  isVisible?: boolean;
}

const MarketFilters = ({
  searchQuery,
  selectedRegion,
  riskLevel,
  onSearchChange,
  onRegionChange,
  onRiskLevelChange,
  onRefresh,
  regions = ['all', 'south africa', 'nigeria', 'kenya', 'ghana'],
  className = '',
  isVisible = true
}: MarketFiltersProps) => {
  const [localSearch, setLocalSearch] = useState(searchQuery);
  const [localRegion, setLocalRegion] = useState(selectedRegion);
  const [localRisk, setLocalRisk] = useState(riskLevel);
  const [filtersActive, setFiltersActive] = useState(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalSearch(value);
    onSearchChange(value);
  };

  const handleRegionChange = (value: string) => {
    setLocalRegion(value);
    onRegionChange(value);
    setFiltersActive(true);
  };
  
  const handleRiskLevelChange = (value: string) => {
    setLocalRisk(value);
    onRiskLevelChange(value);
    setFiltersActive(true);
  };

  const clearFilters = () => {
    setLocalSearch('');
    setLocalRegion('all');
    setLocalRisk('all');
    onSearchChange('');
    onRegionChange('all');
    onRiskLevelChange('all');
    setFiltersActive(false);
  };

  const hasActiveFilters = searchQuery !== '' || selectedRegion !== 'all' || riskLevel !== 'all';

  if (!isVisible) {
    return null;
  }

  return (
    <Card className={cn("border-border/50 bg-card/80 backdrop-blur-sm shadow-sm", className)}>
      <CardContent className="pt-4 pb-4">
        <div className="flex flex-col md:flex-row gap-4 items-start md:items-end">
          <div className="w-full md:w-1/3 lg:w-1/4 space-y-2">
            <Label htmlFor="search" className="text-sm flex items-center gap-1">
              <Search className="h-3 w-3" />
              Search Markets
            </Label>
            <div className="relative">
              <Input
                id="search"
                placeholder="Search by name or description"
                value={localSearch}
                onChange={handleSearchChange}
                className="w-full"
              />
              {localSearch && (
                <Button
                  variant="ghost"
                  size="sm"
                  className="absolute right-1 top-1/2 -translate-y-1/2 h-7 w-7 p-0"
                  onClick={() => {
                    setLocalSearch('');
                    onSearchChange('');
                  }}
                >
                  <X className="h-4 w-4" />
                  <span className="sr-only">Clear search</span>
                </Button>
              )}
            </div>
          </div>
          
          <div className="w-full md:w-1/4 space-y-2">
            <Label htmlFor="region" className="text-sm">Region</Label>
            <Select value={localRegion} onValueChange={handleRegionChange}>
              <SelectTrigger id="region" className="w-full">
                <SelectValue placeholder="Select region" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Regions</SelectItem>
                {regions.filter(r => r !== 'all').map((region) => (
                  <SelectItem key={region} value={region} className="capitalize">
                    {region}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          
          <div className="w-full md:w-1/4 space-y-2">
            <Label htmlFor="risk" className="text-sm">Risk Level</Label>
            <Select value={localRisk} onValueChange={handleRiskLevelChange}>
              <SelectTrigger id="risk" className="w-full">
                <SelectValue placeholder="Select risk level" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Risk Levels</SelectItem>
                <SelectItem value="low">Low Risk</SelectItem>
                <SelectItem value="medium">Medium Risk</SelectItem>
                <SelectItem value="high">High Risk</SelectItem>
              </SelectContent>
            </Select>
          </div>
          
          <div className="flex gap-2 self-stretch md:self-auto mt-2 md:mt-0">
            {hasActiveFilters && (
              <Button 
                variant="outline" 
                size="sm" 
                onClick={clearFilters}
                className="flex-1 md:flex-none"
              >
                <X className="h-4 w-4 mr-1" />
                Clear Filters
              </Button>
            )}
            
            {onRefresh && (
              <Button 
                variant={hasActiveFilters ? "outline" : "default"} 
                size="sm"
                onClick={onRefresh}
                className="flex-1 md:flex-none gap-1"
              >
                <Filter className="h-4 w-4" />
                Apply
              </Button>
            )}
          </div>
        </div>
        
        {filtersActive && hasActiveFilters && (
          <div className="flex items-center gap-2 mt-3 text-sm text-muted-foreground">
            <Filter className="h-3.5 w-3.5" />
            <span>
              Filtering by: {selectedRegion !== 'all' && <span className="font-medium capitalize">{selectedRegion}</span>}
              {selectedRegion !== 'all' && riskLevel !== 'all' && <span>, </span>}
              {riskLevel !== 'all' && <span className="font-medium">{riskLevel} risk</span>}
              {(selectedRegion !== 'all' || riskLevel !== 'all') && searchQuery && <span>, </span>}
              {searchQuery && <span className="font-medium">"{searchQuery}"</span>}
            </span>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MarketFilters;
