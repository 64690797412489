
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Clock, Flag, Layers, LucideIcon, Users } from "lucide-react";
import { formatDistanceToNow } from 'date-fns';
import { Market } from '@/types/market';

interface MarketMetadataProps {
  market: Market;
}

const iconMap: Record<string, LucideIcon> = {
  region: Flag,
  participants: Users,
  risk: Layers,
  closeTime: Clock,
};

export const MarketMetadata: React.FC<MarketMetadataProps> = ({ market }) => {
  // Handle closing time properly, ensuring it's a valid Date object
  const closeTime = market.close_time ? new Date(market.close_time) : new Date();
  const formattedCloseTime = closeTime instanceof Date && !isNaN(closeTime.getTime())
    ? formatDistanceToNow(closeTime, { addSuffix: true })
    : 'Not specified';

  const metadataItems = [
    { label: 'Region', value: market.region, icon: iconMap['region'] || Flag },
    { label: 'Participants', value: market.active_participants, icon: iconMap['participants'] || Users },
    { label: 'Risk Level', value: market.market_risk_level, icon: iconMap['risk'] || Layers },
    { label: 'Closing', value: formattedCloseTime, icon: iconMap['closeTime'] || Clock },
  ];

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-foreground">Market Details</CardTitle>
        <CardDescription className="text-foreground/70">Quick overview of the market</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        {metadataItems.map((item, index) => (
          <div key={index} className="flex items-center space-x-3">
            <item.icon className="h-4 w-4 text-foreground/70" />
            <div>
              <p className="text-sm font-medium leading-none text-foreground">{item.label}</p>
              <p className="text-sm text-foreground/80">
                {item.value}
              </p>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
