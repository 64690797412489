
import React, { useState, useRef, useEffect } from "react";
import { Market } from "@/types/market";
import { ImprovedMarketCard } from "../cards/ImprovedMarketCard";
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination";
import { RegionMarketsDisplay } from "../region/RegionMarketsDisplay";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Archive, Folder, Grid3X3, List, RefreshCcw, Search } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { EmptyState } from "./EmptyState";
import { MarketCard } from "../MarketCard";
import { useNavigate } from "react-router-dom";
import { logger } from "@/utils/logger";

interface PaginatedClosedMarketsProps {
  markets: Market[] | null;
  onParticipate: (marketId: string) => Promise<void>;
  onContributionSubmitted: () => Promise<void>;
  onCloseTimeUpdate: (marketId: string, closeTime: string) => Promise<void>;
  viewMode: 'grid' | 'list';
  groupByRegion?: boolean;
}

export const PaginatedClosedMarkets: React.FC<PaginatedClosedMarketsProps> = ({
  markets,
  onParticipate,
  onContributionSubmitted,
  onCloseTimeUpdate,
  viewMode,
  groupByRegion = true
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [displayMode, setDisplayMode] = useState<'grid' | 'list'>(viewMode);
  const [groupedView, setGroupedView] = useState<boolean>(groupByRegion);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();
  
  // Add refresh tracking
  const lastRefreshTimeRef = useRef<number>(Date.now());
  const REFRESH_COOLDOWN = 10000; // 10 seconds cooldown between refreshes

  // Update displayMode when viewMode prop changes
  useEffect(() => {
    setDisplayMode(viewMode);
  }, [viewMode]);

  if (!markets) {
    return <EmptyState onRefresh={() => {}} />;
  }

  const handleViewDetails = (marketId: string) => {
    navigate(`/dashboard/markets/details/${marketId}`);
  };
  
  const handleRefresh = () => {
    const now = Date.now();
    const timeSinceLastRefresh = now - lastRefreshTimeRef.current;
    
    if (timeSinceLastRefresh < REFRESH_COOLDOWN) {
      logger.info('Closed markets refresh blocked (cooling down)', {
        timeSinceLastRefresh,
        cooldown: REFRESH_COOLDOWN
      });
      return;
    }
    
    lastRefreshTimeRef.current = now;
    logger.info('Manual closed markets refresh triggered');
    onContributionSubmitted();
  };

  // Filter markets based on search query if provided
  const filteredMarkets = searchQuery.trim() ? markets.filter(m => m.name.toLowerCase().includes(searchQuery.toLowerCase()) || m.region.toLowerCase().includes(searchQuery.toLowerCase())) : markets;
  
  if (filteredMarkets.length === 0) {
    return searchQuery ? <Card className="border-t-4 border-t-muted-foreground/40 shadow-lg">
        <CardHeader className="pb-3 bg-zinc-100 dark:bg-zinc-900/60">
          <div className="flex justify-between items-center">
            <CardTitle className="text-xl font-semibold flex items-center gap-2">
              <Archive className="h-5 w-5 text-muted-foreground" /> Closed Markets
            </CardTitle>
          </div>
          <CardDescription className="text-foreground/80">
            Markets that have been completed or closed for participation
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-6">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input type="search" placeholder="Search closed markets..." className="pl-9 w-full md:w-[300px]" value={searchQuery} onChange={e => {
              setSearchQuery(e.target.value);
              setCurrentPage(1); // Reset to first page on new search
            }} />
            </div>
          </div>
          <div className="text-center py-12">
            <Archive className="h-12 w-12 text-muted-foreground/70 mx-auto mb-4" />
            <h3 className="text-xl font-medium mb-2 text-foreground">No matching markets found</h3>
            <p className="text-muted-foreground mb-6">
              No closed markets match your search criteria.
            </p>
            <Button variant="outline" onClick={() => setSearchQuery("")}>
              Clear Search
            </Button>
          </div>
        </CardContent>
      </Card> : <EmptyState onRefresh={handleRefresh} />;
  }

  // Calculate pagination values
  const totalItems = filteredMarkets.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredMarkets.slice(startIndex, startIndex + itemsPerPage);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };
  const toggleDisplayMode = () => {
    setDisplayMode(prev => prev === 'grid' ? 'list' : 'grid');
  };
  const toggleGrouping = () => {
    setGroupedView(prev => !prev);
  };
  
  return <Card className="border-t-4 border-t-muted-foreground/40 shadow-lg">
      <CardHeader className="pb-3 bg-slate-950">
        <div className="flex justify-between items-center">
          <CardTitle className="text-xl font-semibold flex items-center gap-2 text-foreground">
            <Archive className="h-5 w-5 text-muted-foreground" /> Closed Markets
          </CardTitle>
          <div className="flex gap-2">
            <Button variant="outline" size="icon" className="h-8 w-8" onClick={toggleGrouping} title={groupedView ? "Disable region grouping" : "Enable region grouping"}>
              <Folder className={`h-4 w-4 ${groupedView ? 'text-primary' : 'text-muted-foreground'}`} />
            </Button>
            <Button variant="outline" size="icon" className="h-8 w-8" onClick={toggleDisplayMode} title={displayMode === 'grid' ? "Switch to list view" : "Switch to grid view"}>
              {displayMode === 'grid' ? <List className="h-4 w-4" /> : <Grid3X3 className="h-4 w-4" />}
            </Button>
          </div>
        </div>
        <CardDescription className="text-foreground/80">
          Markets that have been completed or closed for participation
          {totalItems > 0 && ` (${totalItems} markets)`}
        </CardDescription>
        <div className="mt-4">
          <div className="relative">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input type="search" placeholder="Search closed markets..." className="pl-9 w-full md:w-[300px]" value={searchQuery} onChange={e => {
              setSearchQuery(e.target.value);
              setCurrentPage(1); // Reset to first page on new search
            }} />
          </div>
        </div>
      </CardHeader>
      <CardContent className="pt-6">
        <div className="space-y-6">
          {groupedView ? (
            <RegionMarketsDisplay 
              markets={currentItems} 
              viewMode={displayMode} 
              onParticipate={onParticipate} 
              onContributionSubmitted={onContributionSubmitted} 
              onCloseTimeUpdate={onCloseTimeUpdate} 
              showParticipationSummary={true} 
            />
          ) : (
            <div className={displayMode === 'grid' ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" : "space-y-4"}>
              {currentItems.map(market => (
                displayMode === 'grid' ? (
                  <MarketCard
                    key={market.id}
                    market={market}
                    onParticipate={onParticipate}
                    onParticipateSuccess={async () => { await onContributionSubmitted(); }}
                    onCloseTimeUpdate={onCloseTimeUpdate}
                    showParticipationSummary={true}
                  />
                ) : (
                  <MarketCard
                    key={market.id}
                    market={market}
                    onParticipate={onParticipate}
                    onParticipateSuccess={async () => { await onContributionSubmitted(); }}
                    onCloseTimeUpdate={onCloseTimeUpdate}
                    variant="horizontal"
                    showParticipationSummary={true}
                  />
                )
              ))}
            </div>
          )}

          {totalPages > 1 && <div className="flex justify-center mt-8 border-t pt-6">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)} className={currentPage === 1 ? "pointer-events-none opacity-50" : "cursor-pointer"} />
                  </PaginationItem>
                  
                  {Array.from({ length: Math.min(5, totalPages) }).map((_, idx) => {
                    let pageNumber: number;
                    if (totalPages <= 5) {
                      pageNumber = idx + 1;
                    } else if (currentPage <= 3) {
                      pageNumber = idx + 1;
                    } else if (currentPage >= totalPages - 2) {
                      pageNumber = totalPages - 4 + idx;
                    } else {
                      pageNumber = currentPage - 2 + idx;
                    }
                    return <PaginationItem key={pageNumber}>
                        <PaginationLink isActive={currentPage === pageNumber} onClick={() => handlePageChange(pageNumber)}>
                          {pageNumber}
                        </PaginationLink>
                      </PaginationItem>;
                  })}
                  
                  <PaginationItem>
                    <PaginationNext onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)} className={currentPage === totalPages ? "pointer-events-none opacity-50" : "cursor-pointer"} />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>}
        </div>
      </CardContent>
    </Card>;
};
