import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { TrendingUp, TrendingDown } from "lucide-react";
import type { Market } from "@/types/market";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "@/utils/formatters";

interface RecentMarketsProps {
  recentMarkets: {
    id: string;
    name: string;
    status: string;
    value: number;
    change: number;
  }[];
  isLoading: boolean;
}

const RecentMarkets = ({ recentMarkets, isLoading }: RecentMarketsProps) => {
  const navigate = useNavigate();
  
  return (
    <Card className="lg:col-span-3 bg-card/50 backdrop-blur-sm border-primary/10 shadow-sm hover:shadow-md transition-all w-full">
      <CardHeader className="p-6">
        <CardTitle className="text-lg md:text-xl font-semibold flex items-center gap-2 text-primary">
          <TrendingUp className="h-5 w-5" />
          Recent Markets
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6 pt-0">
        <div className="space-y-3">
          {isLoading ? (
            <div className="flex items-center justify-center py-8">
              <p className="text-sm text-muted-foreground">Loading markets...</p>
            </div>
          ) : recentMarkets.length > 0 ? (
            recentMarkets.map((market) => (
              <div 
                key={market.id} 
                className="flex items-center justify-between p-4 rounded-lg bg-background/50 hover:bg-background/70 transition-colors duration-200 cursor-pointer border border-border/30 hover:border-primary/30"
                onClick={() => navigate(`/dashboard/markets/details/${market.id}`)}
              >
                <div className="space-y-1 flex-1 min-w-0 mr-4">
                  <p className="text-sm md:text-base font-medium line-clamp-1">
                    {market.name}
                  </p>
                  <p className="text-xs md:text-sm text-muted-foreground capitalize">
                    {market.status}
                  </p>
                </div>
                <div className={`text-sm md:text-base font-medium whitespace-nowrap px-3 py-1 rounded-full ${
                  market.change >= 0 ? 'bg-green-500/10 text-green-500' : 'bg-red-500/10 text-red-500'
                }`}>
                  {market.change >= 0 ? (
                    <TrendingUp className="h-4 w-4 inline mr-1" />
                  ) : (
                    <TrendingDown className="h-4 w-4 inline mr-1" />
                  )}
                  {market.change >= 0 ? '+' : ''}{market.change}%
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center py-8 space-y-3">
              <p className="text-sm text-muted-foreground">
                No active markets found
              </p>
              <Button
                size="sm"
                variant="outline"
                className="border-primary/20 hover:bg-primary/10"
                onClick={() => navigate('/dashboard/markets')}
              >
                Browse Markets
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default RecentMarkets;
