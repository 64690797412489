
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Loader2, Trash2 } from "lucide-react";
import { useState } from "react";

interface DeleteMarketDialogProps {
  marketName: string;
  loading: boolean;
  onDelete: () => Promise<void>;
  setIsDeleting: (isDeleting: boolean) => void;
}

export const DeleteMarketDialog = ({ marketName, loading, onDelete, setIsDeleting }: DeleteMarketDialogProps) => {
  const [isDeleting, setIsDeletingLocal] = useState(false);

  const handleDelete = async () => {
    try {
      setIsDeletingLocal(true);
      setIsDeleting(true);
      await onDelete();
    } finally {
      setIsDeletingLocal(false);
      setIsDeleting(false);
    }
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant="destructive"
          className="gap-2"
          disabled={loading || isDeleting}
        >
          <Trash2 className="h-4 w-4 text-destructive-foreground" />
          Delete Market
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Market</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to delete "{marketName}"? This action cannot be undone.
            All related data including participant records and contributions will be permanently deleted.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={loading || isDeleting}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={handleDelete}
            disabled={loading || isDeleting}
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
          >
            {isDeleting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Deleting...
              </>
            ) : "Delete Market"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
