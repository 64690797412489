
import { useState, useCallback } from 'react';
import { Market } from '@/types/market';
import { normalizeRegionName } from '@/utils/market/groupMarketsByRegion';

export const useMarketFilters = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [riskLevel, setRiskLevel] = useState('all');

  const filterMarkets = useCallback(
    (markets: Market[] | null) => {
      if (!markets) return [];

      return markets.filter((market) => {
        const matchesSearch = market.name.toLowerCase().includes(searchQuery.toLowerCase());
        
        // Normalize region names for consistent comparison
        const normalizedMarketRegion = normalizeRegionName(market.region).toLowerCase();
        const normalizedSelectedRegion = selectedRegion.toLowerCase();
        
        const matchesRegion = 
          selectedRegion === 'all' || 
          normalizedMarketRegion === normalizedSelectedRegion;
          
        const matchesRiskLevel = riskLevel === 'all' || market.market_risk_level?.toLowerCase() === riskLevel.toLowerCase();

        return matchesSearch && matchesRegion && matchesRiskLevel;
      });
    },
    [searchQuery, selectedRegion, riskLevel]
  );

  return {
    searchQuery,
    selectedRegion,
    riskLevel,
    setSearchQuery,
    setSelectedRegion,
    setRiskLevel,
    filterMarkets
  };
};
