
import { Button } from "@/components/ui/button";
import { Shield, User } from "lucide-react";

interface SignInModeToggleProps {
  isAdmin: boolean;
  isLoading: boolean;
  isCheckingAdmin: boolean;
  onModeSwitch: (mode: boolean) => void;
}

export const SignInModeToggle = ({ 
  isAdmin, 
  isLoading, 
  isCheckingAdmin, 
  onModeSwitch 
}: SignInModeToggleProps) => {
  return (
    <div className="flex justify-center space-x-2 p-1 bg-muted rounded-lg">
      <Button
        type="button"
        variant={!isAdmin ? "secondary" : "ghost"}
        className="w-full"
        onClick={() => onModeSwitch(false)}
        disabled={isLoading || isCheckingAdmin}
      >
        <User className="w-4 h-4 mr-2" />
        Member
      </Button>
      <Button
        type="button"
        variant={isAdmin ? "secondary" : "ghost"}
        className="w-full"
        onClick={() => onModeSwitch(true)}
        disabled={isLoading || isCheckingAdmin}
      >
        <Shield className="w-4 h-4 mr-2" />
        Admin
      </Button>
    </div>
  );
};
