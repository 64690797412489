
import { useAuth } from "@/contexts/AuthContext";
import { AuthLoadingState } from "./auth/AuthLoadingState";
import { useEffect, useState } from "react";
import { logger } from "@/utils/logger";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireAdmin?: boolean;
}

export const ProtectedRoute = ({ children, requireAdmin = false }: ProtectedRouteProps) => {
  const { session, isLoading: authLoading } = useAuth();
  const { toast } = useToast();
  const [isVerifyingAdmin, setIsVerifyingAdmin] = useState(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);

  // Handle admin access verification
  useEffect(() => {
    const verifyAdminAccess = async () => {
      if (!requireAdmin || !session?.user) return;

      try {
        setIsVerifyingAdmin(true);
        logger.info('Verifying admin access for user:', session.user.id);
        
        // Use the new is_admin_email_direct function we created
        const { data: isAdmin, error: directError } = await supabase.rpc('is_admin_email_direct');
        
        if (directError) {
          logger.warn('Admin check failed:', directError);
          toast({
            variant: "destructive",
            title: "Verification Failed",
            description: "Unable to verify admin access. Please try again.",
          });
          window.location.href = "/dashboard";
          return;
        }
        
        if (!isAdmin) {
          logger.warn('Admin access denied:', { 
            userId: session.user.id,
            email: session.user.email
          });
          
          toast({
            variant: "destructive",
            title: "Access Denied",
            description: "You don't have permission to access this area.",
          });
          
          window.location.href = "/dashboard";
          return;
        }

        logger.info('Admin access confirmed for:', session.user.email);
        
      } catch (error) {
        logger.error('Admin verification failed:', error);
        toast({
          variant: "destructive",
          title: "Verification Failed",
          description: "Unable to verify admin access. Please try again.",
        });
        window.location.href = "/dashboard";
      } finally {
        setIsVerifyingAdmin(false);
      }
    };

    verifyAdminAccess();
  }, [session, requireAdmin, toast]);

  // Handle non-authenticated users
  useEffect(() => {
    if (!authLoading && !session) {
      logger.info('No session found, redirecting to signin');
      window.location.href = '/signin';
    }
  }, [session, authLoading]);

  // Check user status more thoroughly
  useEffect(() => {
    const checkUserStatus = async () => {
      if (!session?.user || requireAdmin) return;
      
      try {
        setIsCheckingStatus(true);
        
        // First check if the profile exists - make sure to handle the empty response case
        const { data: profile, error } = await supabase
          .from('profiles')
          .select('status')
          .eq('id', session.user.id)
          .maybeSingle();  // Use maybeSingle instead of single to handle empty results

        if (error) {
          logger.error('Error fetching profile:', error);
          setIsCheckingStatus(false);
          
          // If we can't verify the profile, redirect to the waiting list
          toast({
            variant: "warning",
            title: "Account Status Unknown",
            description: "Unable to verify your account status. Please contact an administrator.",
          });
          
          window.location.href = '/waiting-list';
          return;
        }

        // If profile doesn't exist, redirect to waiting list
        if (!profile) {
          logger.info('Profile not found, redirecting to waiting list');
          
          toast({
            variant: "warning",
            title: "Account Setup Incomplete",
            description: "Your account is pending administrator setup.",
          });
          
          window.location.href = '/waiting-list';
          return;
        }

        // STRICT ENFORCEMENT: Only allow 'active' users to access protected routes
        if (profile?.status !== 'active') {
          logger.info('User not active, redirecting to waiting list:', {
            userId: session.user.id,
            status: profile?.status
          });
          
          toast({
            variant: "warning",
            title: "Account Pending Approval",
            description: "Your account requires administrator approval to access this area.",
          });
          
          window.location.href = '/waiting-list';
          return;
        }
        
        setIsCheckingStatus(false);
      } catch (error) {
        logger.error('Status check failed:', error);
        setIsCheckingStatus(false);
        
        // On any error, redirect to waiting list to be safe
        window.location.href = '/waiting-list';
      }
    };

    checkUserStatus();
  }, [session, requireAdmin, toast]);

  // Show loading state during auth loading, admin verification, or status checks
  if (authLoading || isVerifyingAdmin || isCheckingStatus) {
    return <AuthLoadingState isVerifyingAdmin={requireAdmin} showTimeout={isVerifyingAdmin || isCheckingStatus} />;
  }

  // If we have a session and proper access, render children
  if (session) {
    return <>{children}</>;
  }

  // Return null while redirecting
  return null;
};
