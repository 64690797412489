
import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { User, Users, Calendar, TrendingUp, BarChart4, Clock } from "lucide-react";
import { Market } from "@/types/market";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

interface MarketHistoryDetailsModalProps {
  marketId: string | null;
  marketName?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const MarketHistoryDetailsModal = ({ 
  marketId, 
  marketName, 
  isOpen, 
  onClose 
}: MarketHistoryDetailsModalProps) => {
  const [market, setMarket] = useState<Market | null>(null);
  const [participants, setParticipants] = useState<any[]>([]);
  const [contributions, setContributions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && marketId) {
      fetchMarketDetails();
    }
  }, [isOpen, marketId]);

  const fetchMarketDetails = async () => {
    if (!marketId) return;
    
    try {
      setLoading(true);
      setError(null);
      
      // Fetch market details
      const { data: marketData, error: marketError } = await supabase
        .from('markets')
        .select('*')
        .eq('id', marketId)
        .single();
      
      if (marketError) throw marketError;
      
      setMarket(marketData as Market);
      
      // Fetch participants
      const { data: participantsData, error: participantsError } = await supabase
        .from('market_participants')
        .select(`
          id, 
          user_id,
          status,
          approval_status,
          entry_amount,
          current_earnings,
          created_at,
          profiles:user_id (email, first_name, last_name)
        `)
        .eq('market_id', marketId)
        .order('created_at', { ascending: false });
      
      if (participantsError) throw participantsError;
      
      setParticipants(participantsData || []);
      
      // Fetch contributions - fixing the relation issue by NOT using the profiles join
      const { data: contributionsData, error: contributionsError } = await supabase
        .from('market_contributions')
        .select(`
          id,
          user_id,
          input_value,
          calculated_contribution,
          contribution_date,
          status,
          created_at
        `)
        .eq('market_id', marketId)
        .order('created_at', { ascending: false })
        .limit(10);
      
      if (contributionsError) throw contributionsError;
      
      // If we have contributions and user_ids, fetch the emails separately
      if (contributionsData && contributionsData.length > 0) {
        const userIds = [...new Set(contributionsData.map(c => c.user_id))];
        
        const { data: profilesData, error: profilesError } = await supabase
          .from('profiles')
          .select('id, email')
          .in('id', userIds);
          
        if (profilesError) {
          logger.warn('Error fetching profiles for contributions:', profilesError);
        } else if (profilesData) {
          // Create a map of user_id to email
          const userEmailMap = new Map();
          profilesData.forEach(profile => {
            userEmailMap.set(profile.id, profile.email);
          });
          
          // Add email to each contribution
          const enhancedContributions = contributionsData.map(contribution => ({
            ...contribution,
            email: userEmailMap.get(contribution.user_id) || 'Unknown'
          }));
          
          setContributions(enhancedContributions);
        } else {
          setContributions(contributionsData);
        }
      } else {
        setContributions(contributionsData || []);
      }
      
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error occurred';
      logger.error('Error fetching market details:', err);
      setError(`Failed to load market details: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToMarket = () => {
    if (marketId) {
      navigate(`/admin/markets/${marketId}`);
      onClose();
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(amount);
  };

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl flex items-center gap-2">
            <BarChart4 className="h-5 w-5 text-primary" />
            {marketName || "Market Details"}
          </DialogTitle>
          <DialogDescription>
            Detailed information for this market
          </DialogDescription>
        </DialogHeader>

        {loading ? (
          <div className="space-y-4">
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-48 w-full" />
            <Skeleton className="h-48 w-full" />
          </div>
        ) : error ? (
          <div className="p-4 border border-red-200 bg-red-50 rounded-md text-red-800">
            {error}
          </div>
        ) : market ? (
          <div className="space-y-6">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-2xl font-bold">{market.name}</h2>
                <div className="flex items-center gap-2 mt-1">
                  <Badge variant={market.status === 'active' ? 'default' : market.status.includes('fail') ? 'destructive' : 'secondary'}>
                    {market.status}
                  </Badge>
                  <span className="text-sm text-muted-foreground">ID: {market.id}</span>
                </div>
              </div>
              <Button onClick={handleNavigateToMarket}>
                View Full Details
              </Button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Card>
                <CardHeader className="pb-2">
                  <CardTitle className="text-base flex items-center gap-2">
                    <TrendingUp className="h-4 w-4 text-primary" />
                    Financial Overview
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Target Value:</span>
                    <span className="font-medium">{formatCurrency(market.target_value)}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Current Value:</span>
                    <span className="font-medium">{formatCurrency(market.current_value)}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Progress:</span>
                    <span className="font-medium">
                      {market.target_value > 0 
                        ? `${Math.round((market.current_value / market.target_value) * 100)}%` 
                        : '0%'}
                    </span>
                  </div>
                  {market.expected_roi !== undefined && (
                    <div className="flex justify-between">
                      <span className="text-sm text-muted-foreground">Expected ROI:</span>
                      <span className="font-medium">{market.expected_roi}%</span>
                    </div>
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="pb-2">
                  <CardTitle className="text-base flex items-center gap-2">
                    <Users className="h-4 w-4 text-primary" />
                    Participation Stats
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Active Participants:</span>
                    <span className="font-medium">{market.active_participants || 0}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Max Participants:</span>
                    <span className="font-medium">{market.max_participants || 'Unlimited'}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Max Per User:</span>
                    <span className="font-medium">{market.max_participations_per_user || 1}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Type:</span>
                    <span className="font-medium capitalize">{market.market_type}</span>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader className="pb-2">
                  <CardTitle className="text-base flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-primary" />
                    Timeline
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Created:</span>
                    <span className="font-medium">
                      {market.created_at ? format(new Date(market.created_at), 'MMM d, yyyy') : 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Closes:</span>
                    <span className="font-medium">
                      {market.close_time ? format(new Date(market.close_time), 'MMM d, yyyy HH:mm') : 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Last Updated:</span>
                    <span className="font-medium">
                      {market.updated_at ? format(new Date(market.updated_at), 'MMM d, yyyy') : 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Region:</span>
                    <span className="font-medium capitalize">{market.region}</span>
                  </div>
                </CardContent>
              </Card>
            </div>

            <Tabs defaultValue="participants">
              <TabsList>
                <TabsTrigger value="participants" className="flex items-center gap-1">
                  <User className="h-4 w-4" />
                  Participants
                </TabsTrigger>
                <TabsTrigger value="contributions" className="flex items-center gap-1">
                  <TrendingUp className="h-4 w-4" />
                  Recent Contributions
                </TabsTrigger>
              </TabsList>
              
              <TabsContent value="participants" className="mt-4">
                <Card>
                  <CardContent className="p-0">
                    <div className="overflow-x-auto">
                      <table className="w-full">
                        <thead>
                          <tr className="border-b">
                            <th className="text-left p-3">User</th>
                            <th className="text-left p-3">Status</th>
                            <th className="text-left p-3">Entry Amount</th>
                            <th className="text-left p-3">Current Earnings</th>
                            <th className="text-left p-3">Joined</th>
                          </tr>
                        </thead>
                        <tbody>
                          {participants.length === 0 ? (
                            <tr>
                              <td colSpan={5} className="p-4 text-center text-muted-foreground">
                                No participants found
                              </td>
                            </tr>
                          ) : (
                            participants.map((participant) => (
                              <tr key={participant.id} className="border-b hover:bg-muted/50">
                                <td className="p-3">
                                  {participant.profiles?.email || 'Unknown'}
                                </td>
                                <td className="p-3">
                                  <Badge variant={
                                    participant.status === 'active' ? 'default' : 
                                    participant.status === 'suspended' ? 'destructive' : 
                                    'secondary'
                                  }>
                                    {participant.status}
                                  </Badge>
                                </td>
                                <td className="p-3">
                                  {formatCurrency(participant.entry_amount || 0)}
                                </td>
                                <td className="p-3">
                                  {formatCurrency(participant.current_earnings || 0)}
                                </td>
                                <td className="p-3">
                                  {format(new Date(participant.created_at), 'MMM d, yyyy')}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="contributions" className="mt-4">
                <Card>
                  <CardContent className="p-0">
                    <div className="overflow-x-auto">
                      <table className="w-full">
                        <thead>
                          <tr className="border-b">
                            <th className="text-left p-3">User</th>
                            <th className="text-left p-3">Input Value</th>
                            <th className="text-left p-3">Contribution</th>
                            <th className="text-left p-3">Date</th>
                            <th className="text-left p-3">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contributions.length === 0 ? (
                            <tr>
                              <td colSpan={5} className="p-4 text-center text-muted-foreground">
                                No contributions found
                              </td>
                            </tr>
                          ) : (
                            contributions.map((contribution) => (
                              <tr key={contribution.id} className="border-b hover:bg-muted/50">
                                <td className="p-3">
                                  {contribution.email || 'Unknown'}
                                </td>
                                <td className="p-3">
                                  {formatCurrency(contribution.input_value || 0)}
                                </td>
                                <td className="p-3">
                                  {formatCurrency(contribution.calculated_contribution || 0)}
                                </td>
                                <td className="p-3">
                                  {format(new Date(contribution.contribution_date), 'MMM d, yyyy')}
                                </td>
                                <td className="p-3">
                                  <Badge variant={
                                    contribution.status === 'approved' ? 'default' : 
                                    contribution.status === 'rejected' ? 'destructive' : 
                                    'secondary'
                                  }>
                                    {contribution.status}
                                  </Badge>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </div>
        ) : (
          <div className="p-4 border rounded-md text-center">
            <p className="text-muted-foreground">No market data available</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
