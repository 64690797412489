
import { useState } from "react";
import { CreateMarketForm } from "../types";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";
import { logMarketHistory } from "@/utils/market/history/log-market-history";
import { handleEdgeTransaction } from "@/utils/edge-functions/transaction-handler";

export function useMarketFormSubmission(onMarketCreated: () => void) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const submitMarketForm = async (values: CreateMarketForm) => {
    try {
      setIsSubmitting(true);
      logger.info('Attempting to create market with values:', values);
      
      // Verify admin status first
      const { data: isAdmin, error: adminError } = await supabase.rpc('is_admin_email_direct');
      
      if (adminError || !isAdmin) {
        throw new Error(adminError?.message || 'Admin privileges required to create markets');
      }
      
      // Preserve full date and time when converting to ISO strings
      const startDate = values.start_date ? values.start_date.toISOString() : new Date().toISOString();
      const closeTime = values.close_time ? values.close_time.toISOString() : null;
      
      // Determine if the market should be scheduled based on start_date
      const isScheduledForFuture = values.start_date && new Date(startDate) > new Date();
      
      // Determine the correct status based on user inputs and dates
      let marketStatus;
      if (values.draft) {
        marketStatus = 'draft';
      } else if (isScheduledForFuture) {
        marketStatus = 'scheduled';
      } else {
        marketStatus = values.auto_activate ? 'active' : 'pending';
      }
      
      logger.info('Setting market status to:', {
        status: marketStatus,
        isScheduled: isScheduledForFuture, 
        startDate: startDate,
        currentDate: new Date().toISOString()
      });
      
      // Prepare metadata for description, sequence_position, and creator info
      const metadata: Record<string, any> = {};
      if (values.description) {
        metadata.description = values.description;
      }
      if (values.sequence_position) {
        metadata.sequence_position = values.sequence_position;
      }
      
      const userId = await supabase.auth.getUser().then(res => res.data.user?.id);
      
      if (!userId) {
        throw new Error('User authentication failed. Please sign in again.');
      }
      
      // Add creator info to metadata instead of using created_by column
      metadata.created_by = userId;
      metadata.updated_by = userId;
      metadata.countdown_enabled = values.countdown_enabled;
      
      // Create market data object with snake_case keys to match database columns
      const marketDataObj = {
        name: values.name,
        region: values.region,
        market_type: values.market_type,
        market_risk_level: values.market_risk_level,
        target_value: values.target_value,
        entry_amount: values.entry_amount,
        max_participants: values.max_participants,
        max_participations_per_user: values.max_participations_per_user,
        max_duplications: values.max_duplications,
        requires_approval: !values.auto_approve_participants,
        expected_roi: values.expected_roi,
        start_date: startDate,
        close_time: closeTime,
        metadata: Object.keys(metadata).length > 0 ? metadata : null,
        status: marketStatus
      };
      
      // Insert the market directly
      const { data: insertResult, error: insertError } = await supabase
        .from('markets')
        .insert({
          ...marketDataObj,
          market_type: marketDataObj.market_type as any,
          market_risk_level: marketDataObj.market_risk_level as any
        })
        .select('id, name, region, market_type, market_risk_level, target_value, entry_amount, max_participants, max_participations_per_user, max_duplications, requires_approval, expected_roi, start_date, close_time, metadata, status, created_at')
        .single();

      if (insertError) {
        logger.error('Database error creating market:', insertError);
        throw new Error(insertError.message || 'Failed to create market');
      }
      
      if (!insertResult) {
        throw new Error('Failed to create market: No market ID returned');
      }

      logger.info('Market created successfully with ID:', insertResult.id);
        
      // Log market creation in history
      await logMarketHistory({
        marketId: insertResult.id,
        action: 'created',
        details: {
          name: values.name,
          region: values.region,
          market_type: values.market_type,
          status: marketStatus
        },
        performedBy: userId
      });

      // Send webhook notification about market creation
      logger.info('Sending market creation webhook for market:', insertResult.id);
      
      const { success: webhookSuccess, error: webhookError } = await handleEdgeTransaction({
        functionName: 'market-creation-webhook',
        payload: {
          marketData: insertResult
        }
      });
      
      if (!webhookSuccess) {
        logger.warn('Failed to send market creation webhook:', webhookError);
        // Don't block the main flow for webhook failures
      } else {
        logger.info('Market creation webhook sent successfully');
      }

      toast({
        title: values.draft ? "Draft Saved" : "Market Created",
        description: values.draft 
          ? "Market has been saved as a draft."
          : "New market has been created successfully.",
      });
      
      onMarketCreated();
      return true;
    } catch (error) {
      logger.error('Error in market creation:', error);
      
      let errorMessage = "Failed to create market. Please try again.";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    submitMarketForm
  };
}
