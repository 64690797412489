
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";

interface AdminQuickAccessCardProps {
  icon: React.FC<any>;
  title: string;
  description: string;
  path: string;
  isTab?: boolean;
}

export const AdminQuickAccessCard = ({ 
  icon: Icon, 
  title, 
  description, 
  path, 
  isTab 
}: AdminQuickAccessCardProps) => {
  const { toast } = useToast();
  
  const handleNavigate = () => {
    if (isTab) {
      const tabId = path.replace('#', '');
      const tabElement = document.querySelector(`[data-state="inactive"][value="${tabId}"]`) as HTMLElement;
      if (tabElement) {
        tabElement.click();
      } else {
        toast({
          title: "Tab not found",
          description: `Could not find tab: ${tabId}`,
          variant: "destructive",
        });
      }
    } else {
      window.location.href = path;
      toast({
        title: "Navigating",
        description: `Going to ${path}`,
      });
    }
  };

  return (
    <Card 
      className="hover:shadow-xl transition-all duration-300 border-primary/10 overflow-hidden bg-white dark:bg-zinc-800 cursor-pointer"
      onClick={handleNavigate}
    >
      <CardHeader className="pb-2 bg-gradient-to-br from-white to-gray-100 dark:from-zinc-800 dark:to-zinc-900">
        <div className="flex items-center">
          <div className="p-3 rounded-full bg-primary/10 mr-3 flex items-center justify-center">
            <Icon className="h-6 w-6 text-primary" />
          </div>
          <CardTitle className="text-lg text-zinc-800 dark:text-white">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="pt-4">
        <CardDescription className="mb-4 text-sm text-zinc-600 dark:text-gray-300">{description}</CardDescription>
        <Button 
          variant="outline" 
          className="w-full bg-white dark:bg-zinc-800 text-zinc-800 dark:text-gray-200 hover:bg-primary/10 dark:hover:bg-primary/20 transition-colors"
        >
          Open
        </Button>
      </CardContent>
    </Card>
  );
};
