
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";

interface AuthLoadingStateProps {
  isVerifyingAdmin: boolean;
  showTimeout: boolean;
}

export const AuthLoadingState = ({ isVerifyingAdmin, showTimeout }: AuthLoadingStateProps) => {
  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);

  useEffect(() => {
    if (showTimeout) {
      const timer = setTimeout(() => {
        setShowTimeoutMessage(true);
      }, 5000); // Show timeout message after 5 seconds

      return () => clearTimeout(timer);
    }
  }, [showTimeout]);

  return (
    <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
      <div className="text-center space-y-4">
        <Loader2 className="h-8 w-8 animate-spin text-primary mx-auto" />
        <p className="text-sm text-muted-foreground">
          {isVerifyingAdmin ? "Verifying admin access..." : "Loading..."}
        </p>
        {showTimeoutMessage && (
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">
              This is taking longer than expected.
            </p>
            <button 
              onClick={() => window.location.reload()}
              className="text-sm text-primary hover:underline"
            >
              Refresh Page
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
