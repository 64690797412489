
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { History } from "lucide-react";
import { formatCurrency } from '@/utils/formatters';

interface MonthlyData {
  month: string;
  amount: number;
}

interface HistoryComparisonCardProps {
  monthlyData: MonthlyData[];
  isLoading?: boolean;
}

export function HistoryComparisonCard({ monthlyData, isLoading = false }: HistoryComparisonCardProps) {
  // Sort data from oldest to newest
  const sortedData = [...monthlyData].sort((a, b) => {
    const monthsOrder = { 
      'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
      'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12 
    };
    return monthsOrder[a.month as keyof typeof monthsOrder] - monthsOrder[b.month as keyof typeof monthsOrder];
  });
  
  // Get most recent months for display
  const recentMonths = sortedData.slice(-3);

  return (
    <Card className="shadow-sm transition-all hover:shadow-md dark:bg-card bg-white">
      <CardHeader className="pb-2">
        <CardTitle className="flex items-center text-xl font-semibold text-primary">
          <History className="mr-2 h-5 w-5" />
          History & Comparison
        </CardTitle>
      </CardHeader>
      <CardContent>
        {recentMonths.length === 0 ? (
          <div className="text-center py-8 dark:text-zinc-400 text-zinc-600">
            No historical data available yet
          </div>
        ) : (
          <>
            <div className="mb-4 space-y-2">
              {recentMonths.reverse().map((item, index) => (
                <div key={item.month} className="flex justify-between">
                  <div className="text-sm font-medium dark:text-zinc-300 text-zinc-700">{item.month}:</div>
                  <div className="font-semibold dark:text-zinc-100 text-zinc-800">{formatCurrency(item.amount)} Banked</div>
                </div>
              ))}
            </div>
            
            <ResponsiveContainer width="100%" height={180}>
              <BarChart data={sortedData} margin={{ top: 10, right: 10, left: 0, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                <XAxis 
                  dataKey="month" 
                  tick={{ fill: 'currentColor' }}
                  stroke="currentColor"
                  className="dark:text-zinc-400 text-zinc-600"
                />
                <YAxis 
                  tickFormatter={(value) => `R${value / 1000}k`}
                  width={45}
                  tick={{ fill: 'currentColor' }}
                  stroke="currentColor"
                  className="dark:text-zinc-400 text-zinc-600"
                />
                <Tooltip 
                  formatter={(value: number) => formatCurrency(value)}
                  labelFormatter={(label) => `Month: ${label}`}
                  contentStyle={{ 
                    backgroundColor: 'var(--background)',
                    borderColor: 'var(--border)',
                    color: 'var(--foreground)'
                  }}
                />
                <Bar 
                  dataKey="amount" 
                  fill="var(--primary)" 
                  barSize={30} 
                  radius={[4, 4, 0, 0]} 
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </CardContent>
    </Card>
  );
}
