
import { logger } from "@/utils/logger";
import { toast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";
import React from "react";

type ErrorHandlerOptions = {
  context: string;
  fallbackMessage: string;
  logDetails?: Record<string, any>;
  showToast?: boolean;
  retryFn?: () => Promise<any>;
};

/**
 * Centralized error handler with consistent logging, user feedback, and retry options
 */
export const handleError = (error: unknown, options: ErrorHandlerOptions) => {
  const { context, fallbackMessage, logDetails = {}, showToast = true, retryFn } = options;
  
  // Determine the error message
  const errorMessage = error instanceof Error 
    ? error.message 
    : typeof error === 'string'
      ? error
      : fallbackMessage;
  
  // Create a structured error object for logging
  const errorDetails = {
    message: errorMessage,
    context,
    timestamp: new Date().toISOString(),
    stack: error instanceof Error ? error.stack : undefined,
    ...logDetails
  };
  
  // Log the error with consistent format
  logger.error(`Error in ${context}:`, errorDetails);
  
  // Show toast notification if enabled
  if (showToast) {
    let toastAction;
    if (retryFn) {
      // Create toast action using React.createElement instead of JSX
      toastAction = React.createElement(
        ToastAction,
        { altText: "Retry", onClick: retryFn },
        "Retry"
      );
    }
    
    toast({
      title: `Error: ${context}`,
      description: errorMessage,
      variant: "destructive",
      action: toastAction
    });
  }
  
  // Return the error details for potential further handling
  return {
    error,
    message: errorMessage,
    details: errorDetails
  };
};

/**
 * Process API responses with consistent error handling
 */
export const processApiResponse = async <T>(
  promise: Promise<{ data: T | null; error: any }>,
  options: ErrorHandlerOptions
): Promise<T> => {
  try {
    const { data, error } = await promise;
    
    if (error) {
      handleError(error, options);
      throw error;
    }
    
    if (!data) {
      const noDataError = new Error("No data received from API");
      handleError(noDataError, options);
      throw noDataError;
    }
    
    return data;
  } catch (error) {
    handleError(error, { ...options, showToast: options.showToast !== false });
    throw error;
  }
};

/**
 * Safely execute functions with proper error boundaries
 */
export const safelyExecute = async <T>(
  fn: () => Promise<T>, 
  options: ErrorHandlerOptions
): Promise<{ success: boolean; data?: T; error?: any }> => {
  try {
    const result = await fn();
    return { success: true, data: result };
  } catch (error) {
    const errorResult = handleError(error, options);
    return { success: false, error: errorResult.error };
  }
};
