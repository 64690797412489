
import { useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Market } from "@/types/market";
import { Loader2, Info, AlertCircle, ShieldAlert } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { DatePicker } from "@/components/ui/date-picker";
import { addDays } from "date-fns";
import { logger } from "@/utils/logger";

interface ParticipationDialogProps {
  market: Market;
  isOpen: boolean;
  onClose: () => void;
  onParticipate: (customSettings?: { 
    targetEarnings?: number, 
    closeTime?: string 
  }) => Promise<void>;
  isSubmitting: boolean;
  requiresApproval?: boolean;
  marketType?: string;
  participationStatus?: string | null;
  approvalStatus?: string | null;
  awaitingWebhook?: boolean;
}

export const ParticipationDialog = ({
  market,
  isOpen,
  onClose,
  onParticipate,
  isSubmitting,
  requiresApproval = false,
  marketType = "standard",
  participationStatus,
  approvalStatus,
  awaitingWebhook = false
}: ParticipationDialogProps) => {
  const [targetEarnings, setTargetEarnings] = useState<string>("");
  // Set default to tomorrow at noon if market doesn't have a close time
  const [closeDate, setCloseDate] = useState<Date | undefined>(
    market.close_time ? new Date(market.close_time) : addDays(new Date(), 1)
  );

  const isPendingContribution = participationStatus === 'pending_contribution';
  const isPendingApproval = approvalStatus === 'pending';
  
  const validateCloseDate = (date: Date | undefined): boolean => {
    if (!date) return false;
    
    const now = new Date();
    return date > now;
  };
  
  const handleSubmit = async () => {
    try {
      const customSettings: { 
        targetEarnings?: number, 
        closeTime?: string 
      } = {};
      
      if (targetEarnings && !isNaN(parseFloat(targetEarnings))) {
        customSettings.targetEarnings = parseFloat(targetEarnings);
      }
      
      if (closeDate) {
        // Verify close date is in the future
        if (!validateCloseDate(closeDate)) {
          logger.error('ParticipationDialog: Invalid close date (in the past)', { 
            date: closeDate.toISOString() 
          });
          return;
        }
        
        customSettings.closeTime = closeDate.toISOString();
      }
      
      logger.info('ParticipationDialog: Submitting participation', { 
        marketId: market.id,
        hasTargetEarnings: !!customSettings.targetEarnings,
        hasCloseTime: !!customSettings.closeTime
      });
      
      await onParticipate(customSettings);
    } catch (error) {
      logger.error('Error in participation submit:', error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {isPendingApproval 
              ? "Participation Pending Approval"
              : isPendingContribution 
                ? "Contribute to Market" 
                : awaitingWebhook
                  ? "Processing Request"
                  : "Join Market"}
          </DialogTitle>
          <DialogDescription>
            {isPendingApproval
              ? "Your participation request is awaiting admin approval."
              : isPendingContribution
                ? "Complete your participation by submitting a contribution."
                : awaitingWebhook
                  ? "Your request is being processed. Please wait for confirmation."
                  : "Set your preferences for joining this market."}
          </DialogDescription>
        </DialogHeader>

        {awaitingWebhook ? (
          <div className="py-4 flex flex-col items-center justify-center">
            <Loader2 className="h-8 w-8 animate-spin mb-4" />
            <p className="text-center text-muted-foreground">
              Your request is being processed. This may take a few moments.
              <br />
              Please do not close this dialog.
            </p>
          </div>
        ) : isPendingApproval ? (
          <div className="py-4">
            <Alert variant="default" className="mb-4">
              <ShieldAlert className="h-4 w-4" />
              <AlertTitle>Approval Required</AlertTitle>
              <AlertDescription>
                This market requires admin approval before you can participate. 
                You'll be notified once your request is approved.
              </AlertDescription>
            </Alert>
            <div className="text-sm text-muted-foreground">
              <p>Market: {market.name}</p>
              <p>Status: Awaiting Admin Approval</p>
            </div>
          </div>
        ) : (
          <>
            {isPendingContribution && (
              <Alert variant="warning" className="mb-4">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Contribution Required</AlertTitle>
                <AlertDescription>
                  You need to make a contribution to activate your participation in this market.
                </AlertDescription>
              </Alert>
            )}

            <div className="py-4">
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="targetEarnings">Target Earnings (Optional)</Label>
                  <Input
                    id="targetEarnings"
                    type="number"
                    placeholder="Set your target earnings amount"
                    value={targetEarnings}
                    onChange={(e) => setTargetEarnings(e.target.value)}
                  />
                  <p className="text-xs text-muted-foreground">
                    Your personal goal for this market.
                  </p>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="closeDate">Preferred Close Date (Optional)</Label>
                  <DatePicker
                    date={closeDate}
                    setDate={setCloseDate}
                    disabled={isSubmitting}
                    showTimePicker={true}
                  />
                  <p className="text-xs text-muted-foreground">
                    When you'd like to close your position.
                  </p>
                </div>

                <Alert variant="default">
                  <Info className="h-4 w-4" />
                  <AlertDescription>
                    {isPendingContribution 
                      ? "Contribute now to activate your participation."
                      : "You can always update these settings later."}
                  </AlertDescription>
                </Alert>
              </div>
            </div>
          </>
        )}

        <DialogFooter>
          {!awaitingWebhook && (
            <Button variant="outline" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
          )}
          {!isPendingApproval && !awaitingWebhook && (
            <Button onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing...
                </>
              ) : isPendingContribution ? (
                "Contribute Now"
              ) : (
                "Join Market"
              )}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
