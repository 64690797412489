import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";

export interface Announcement {
  id: string;
  title: string;
  content: string;
  created_at: string;
  sender_id: string;
  status: string;
  sender_name?: string;
  is_read?: boolean;
  read_at?: string;
}

interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
}

export function useAdminMessaging() {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [targetGroup, setTargetGroup] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAnnouncements, setFilteredAnnouncements] = useState<Announcement[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const { toast } = useToast();
  const { session, isAdmin } = useAuth();

  // Get users list if admin
  useEffect(() => {
    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin]);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('id, first_name, last_name, email')
        .order('first_name', { ascending: true });

      if (error) throw error;
      
      setUsers(data || []);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Filter announcements based on search query
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredAnnouncements(announcements);
    } else {
      const query = searchQuery.toLowerCase().trim();
      setFilteredAnnouncements(
        announcements.filter(
          (announcement) =>
            announcement.title.toLowerCase().includes(query) ||
            announcement.content.toLowerCase().includes(query)
        )
      );
    }
  }, [searchQuery, announcements]);

  // Fetch announcements
  const fetchAnnouncements = async () => {
    try {
      setIsLoading(true);
      
      // Get announcements
      const { data: announcementsData, error } = await supabase
        .from('announcements')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // For each announcement, fetch the sender's profile info
      const formattedData = await Promise.all(announcementsData.map(async (item: any) => {
        // Get sender profile if sender_id exists
        let senderName = "Unknown";
        
        if (item.sender_id) {
          const { data: senderData } = await supabase
            .from('profiles')
            .select('first_name, last_name')
            .eq('id', item.sender_id)
            .single();
          
          if (senderData) {
            senderName = `${senderData.first_name} ${senderData.last_name}`;
          }
        }
        
        return {
          ...item,
          sender_name: senderName
        };
      }));
      
      setAnnouncements(formattedData);
      setFilteredAnnouncements(formattedData);
    } catch (error) {
      console.error('Error fetching announcements:', error);
      toast({
        title: "Error fetching announcements",
        description: "Please try again later",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Send announcement
  const sendAnnouncement = async () => {
    if (!title.trim()) {
      toast({
        title: "Title required",
        description: "Please enter a title for your announcement",
        variant: "destructive",
      });
      return;
    }

    if (!message.trim()) {
      toast({
        title: "Message required",
        description: "Please enter a message",
        variant: "destructive",
      });
      return;
    }

    if (!session?.user?.id) {
      toast({
        title: "Authentication required",
        description: "You must be logged in to send announcements",
        variant: "destructive",
      });
      return;
    }

    setIsSending(true);
    try {
      // Insert the announcement - ensure sender_id is set to the current user's ID
      const { error } = await supabase
        .from('announcements')
        .insert([
          {
            title,
            content: message,
            sender_id: session.user.id,
            status: 'published'
          }
        ]);

      if (error) {
        console.error('Insert error details:', error);
        throw error;
      }

      toast({
        title: "Announcement sent",
        description: "Your announcement has been sent successfully",
      });

      setTitle("");
      setMessage("");
      setTargetGroup("all");
      fetchAnnouncements();
    } catch (error) {
      console.error('Error sending announcement:', error);
      toast({
        title: "Error sending announcement",
        description: "Please try again later",
        variant: "destructive",
      });
    } finally {
      setIsSending(false);
    }
  };

  // Delete announcement - modified to allow admins to delete any announcement
  const deleteAnnouncement = async (id: string) => {
    try {
      // Admins can delete any announcement, normal users can only delete their own
      // RLS policies will enforce this at the database level
      const { error } = await supabase
        .from('announcements')
        .delete()
        .eq('id', id);

      if (error) throw error;

      toast({
        title: "Announcement deleted",
        description: "The announcement has been deleted successfully",
      });

      fetchAnnouncements();
    } catch (error) {
      console.error('Error deleting announcement:', error);
      toast({
        title: "Error deleting announcement",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  // Initialize data
  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return {
    announcements: filteredAnnouncements,
    isLoading,
    isSending,
    message,
    setMessage,
    title, 
    setTitle,
    targetGroup,
    setTargetGroup,
    searchQuery,
    setSearchQuery,
    sendAnnouncement,
    deleteAnnouncement,
    refreshData: fetchAnnouncements,
    users
  };
}
