
import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Market } from "@/types/market";
import { Badge } from "@/components/ui/badge";
import { MarketContributionForm } from "@/components/markets/MarketContributionForm";
import { useAuth } from "@/contexts/AuthContext";
import { CalendarClock, Info, Target, TrendingUp, RefreshCw, AlertTriangle, CheckCircle } from "lucide-react";
import { formatCurrency } from "@/utils/formatters";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { cn } from "@/lib/utils";
import { supabase } from "@/lib/supabase";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { logger } from "@/utils/logger";
import { useMarketStatus } from "@/hooks/markets/use-market-status";

interface ContributionDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  market: Market;
  onContributionSubmitted: () => void;
  children?: React.ReactNode; // Made children optional with the ? operator
  onDuplicateMarket?: () => Promise<void>;
  canDuplicate?: boolean;
  remainingAttempts?: number;
}

export const ContributionDialog = ({
  isOpen,
  onOpenChange,
  market,
  onContributionSubmitted,
  children,
  onDuplicateMarket,
  canDuplicate = false,
  remainingAttempts = 0
}: ContributionDialogProps) => {
  const { session } = useAuth();
  const [userId, setUserId] = useState<string | null>(null);
  const [isCreatingDuplicate, setIsCreatingDuplicate] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const { updateMarketStatus } = useMarketStatus();
  
  useEffect(() => {
    if (session?.user?.id) {
      setUserId(session.user.id);
    }
  }, [session]);
  
  useEffect(() => {
    // Check if the market is successful
    if (isOpen && ['success', 'closed_successful', 'completed'].includes(market?.status || '')) {
      setShowSuccessMessage(true);
    } else {
      setShowSuccessMessage(false);
    }
  }, [market?.status, isOpen]);
  
  const isFailed = ['failed', 'closed_failed', 'failed_final'].includes(market?.status || '');
  const isSuccessful = ['success', 'closed_successful', 'completed'].includes(market?.status || '');
  const hasNoAttemptsLeft = remainingAttempts <= 0;
  
  const handleDuplicateMarket = async () => {
    if (onDuplicateMarket) {
      await onDuplicateMarket();
      return;
    }
    
    if (!market.id || !session?.user?.id) return;
    
    setIsCreatingDuplicate(true);
    try {
      const { data, error } = await supabase.functions.invoke("duplicate-market", {
        body: {
          market_id: market.id,
          user_id: session.user.id
        }
      });
      
      if (error) throw error;
      
      if (data && typeof data === 'object' && 'new_market_id' in data) {
        toast.success("Market duplicated successfully! You can now try again.");
        navigate(`/dashboard/markets/details/${data.new_market_id}`);
        onOpenChange(false);
      } else {
        throw new Error("Failed to duplicate market. No new market ID returned.");
      }
    } catch (err) {
      logger.error('Error duplicating market', err);
      toast.error("Failed to duplicate market. Please try again.");
    } finally {
      setIsCreatingDuplicate(false);
    }
  };
  
  const handleMoveToClosedTab = async () => {
    try {
      // Update market status to closed_successful if it's already successful
      if (isSuccessful && !['closed_successful', 'completed'].includes(market.status)) {
        await updateMarketStatus(market.id, 'closed_successful');
        toast.success("Market has been marked as closed successful!");
      }
      
      onOpenChange(false);
      onContributionSubmitted();
      navigate('/dashboard/markets?tab=closed');
    } catch (error) {
      logger.error('Error moving market to closed tab:', error);
      toast.error("Failed to update market status. Please try again.");
    }
  };
  
  if (!market) return null;
  
  return <Dialog open={isOpen} onOpenChange={onOpenChange}>
      {children && <DialogTrigger asChild>
        {children}
      </DialogTrigger>}
      <DialogContent className={cn(
        "sm:max-w-[600px] max-h-[90vh] overflow-y-auto",
        isFailed && hasNoAttemptsLeft ? "border-red-500 dark:border-red-700" : "",
        isSuccessful ? "border-green-500 dark:border-green-700" : ""
      )}>
        <DialogHeader>
          <div className="flex items-center justify-between">
            <DialogTitle>{market.name}</DialogTitle>
            <Badge variant={isSuccessful ? "success" : "outline"}>{market.status}</Badge>
          </div>
          <DialogDescription>
            {isSuccessful
              ? "This market has successfully reached its target!"
              : isFailed && hasNoAttemptsLeft
                ? "This market has failed. You have used all your contribution attempts."
                : "Submit your contribution for this market using the progressive model"}
          </DialogDescription>
        </DialogHeader>

        {showSuccessMessage && (
          <div className="space-y-4">
            <Alert variant="success" className="bg-green-50 dark:bg-green-950/20 border-green-500">
              <CheckCircle className="h-5 w-5 text-green-600" />
              <AlertTitle>Market Successful</AlertTitle>
              <AlertDescription>
                Congratulations! This market has successfully reached its target value of {formatCurrency(market.target_value)}.
              </AlertDescription>
            </Alert>
            
            <div className="flex justify-center mt-4">
              <Button 
                onClick={handleMoveToClosedTab}
                variant="success"
                className="w-full"
              >
                Okay
              </Button>
            </div>
          </div>
        )}
        
        {isFailed && hasNoAttemptsLeft ? (
          <div className="space-y-4">
            <Alert variant="destructive">
              <AlertTriangle className="h-4 w-4" />
              <AlertTitle>Market Failed</AlertTitle>
              <AlertDescription>
                This market failed to reach its target and you have used all available contribution attempts.
                {canDuplicate ? " You can try again with a new duplicate market." : ""}
              </AlertDescription>
            </Alert>
            
            {canDuplicate && (
              <div className="flex justify-center mt-4">
                <Button 
                  onClick={handleDuplicateMarket} 
                  className="bg-primary hover:bg-primary/90 w-full"
                  disabled={isCreatingDuplicate}
                >
                  {isCreatingDuplicate ? (
                    <>
                      <span className="mr-2 h-4 w-4 animate-spin">◌</span>
                      Creating...
                    </>
                  ) : (
                    <>
                      <RefreshCw className="mr-2 h-4 w-4" />
                      Retry with New Market
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>
        ) : !showSuccessMessage && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div className="bg-muted p-3 rounded-md flex items-start gap-2">
                <Target className="h-4 w-4 text-primary mt-0.5" />
                <div>
                  <p className="text-xs text-muted-foreground">Target</p>
                  <p className="font-semibold">{formatCurrency(market.target_value)}</p>
                </div>
              </div>
              
              <div className="bg-muted p-3 rounded-md flex items-start gap-2">
                <TrendingUp className="h-4 w-4 text-primary mt-0.5" />
                <div>
                  <p className="text-xs text-muted-foreground">Adjustment Factor</p>
                  <p className="font-semibold">{market.adjustment_factor || 1.07}x</p>
                </div>
              </div>
              
              <div className="bg-muted p-3 rounded-md flex items-start gap-2">
                <CalendarClock className="h-4 w-4 text-primary mt-0.5" />
                <div>
                  <p className="text-xs text-muted-foreground">Max Attempts</p>
                  <p className="font-semibold">{market.max_participations_per_user || 1}</p>
                </div>
              </div>
            </div>
            
            <Alert variant="default" className="mb-4">
              <Info className="h-4 w-4" />
              <AlertDescription>
                Required contribution = ((Target + Previous Failed) ÷ Odds) × {market.adjustment_factor || 1.07}
              </AlertDescription>
            </Alert>

            <div className="space-y-4">
              <MarketContributionForm market={market} onContributionSubmitted={onContributionSubmitted} />
            </div>
          </>
        )}

        <DialogFooter className="sm:justify-end">
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>;
};
