
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Dialog } from "@/components/ui/dialog";
import { TrendingUp, Plus, SparklesIcon } from "lucide-react";
import { CreateMarketForm } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { marketFormSchema } from "./config/formConfig";
import * as z from "zod";
import { MarketFormContent } from "./components/MarketFormContent";

interface CreateMarketDialogProps {
  onMarketCreated: () => void;
}

export function CreateMarketDialog({ onMarketCreated }: CreateMarketDialogProps) {
  const [open, setOpen] = useState(false);
  
  // Use the correct resolver with the appropriate type
  const form = useForm<z.infer<typeof marketFormSchema>>({
    resolver: zodResolver(marketFormSchema),
    defaultValues: {
      // Market Information
      name: '',
      region: '',
      description: '',
      sequence_position: undefined,
      
      // Market Classification
      market_type: 'standard' as const,
      market_risk_level: 'medium' as const,
      
      // Financial Details
      target_value: 0,
      entry_amount: 0,
      expected_roi: 5.0,
      
      // Participation Rules
      max_participants: 100,
      max_participations_per_user: 1,
      max_duplications: 3,
      auto_approve_participants: false,
      
      // Timer & Scheduling
      start_date: new Date(),
      close_time: undefined,
      countdown_enabled: true,
      
      // Status & Controls
      auto_activate: true,
      draft: false
    },
  });

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    if (!newOpen) {
      form.reset();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <Button
        variant="default"
        size="sm"
        className="gap-2 bg-gradient-to-r from-primary to-primary/80 hover:from-primary/90 hover:to-primary/70 transition-all duration-300 shadow-md"
        onClick={() => setOpen(true)}
      >
        <Plus className="h-4 w-4" />
        Create Market
      </Button>
      
      {open && (
        <MarketFormContent 
          form={form} 
          onMarketCreated={onMarketCreated} 
          onClose={handleClose}
        />
      )}
    </Dialog>
  );
}
