
import { useState, useEffect, useCallback } from 'react';
import { 
  DailyFinancialRecord,
  getAllDaysInMonthWithRecords,
  calculateDailyTotals
} from '@/utils/financials/member-financials';
import { logger } from '@/utils/logger';

export const useDailyFinancialRecords = (
  userId: string | undefined,
  month: number,
  year: number
) => {
  const [records, setRecords] = useState<DailyFinancialRecord[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [dailyTotals, setDailyTotals] = useState({
    totalDeposits: 0,
    startBalance: 0,
    endBalance: 0
  });

  const fetchRecords = useCallback(async () => {
    if (!userId) return;
    
    try {
      setIsLoading(true);
      setError(null);

      logger.info('Fetching daily financial records', { userId, month, year });
      const data = await getAllDaysInMonthWithRecords(userId, month, year);
      setRecords(data);
      
      // Calculate totals
      const totals = calculateDailyTotals(data);
      setDailyTotals(totals);
      
    } catch (err) {
      logger.error('Error fetching daily financial records', { error: err, userId, month, year });
      setError(err instanceof Error ? err : new Error('Error fetching records'));
    } finally {
      setIsLoading(false);
    }
  }, [userId, month, year]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return {
    records,
    isLoading,
    error,
    dailyTotals,
    refresh: fetchRecords
  };
};
