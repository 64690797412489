
import React from 'react';
import { UnifiedErrorBoundary } from '@/components/ui/error-boundary';
import { Button } from '@/components/ui/button';
import { AlertCircle, RefreshCw } from 'lucide-react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

interface MarketErrorBoundaryProps {
  children: React.ReactNode;
  onReset?: () => void;
}

export const MarketErrorBoundary: React.FC<MarketErrorBoundaryProps> = ({ children, onReset }) => {
  const fallback = (
    <div className="flex items-center justify-center min-h-[300px] p-4">
      <Card className="max-w-md w-full border-destructive/20">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <AlertCircle className="h-5 w-5 text-destructive" />
            <span>Market Error</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground">
            We encountered an error displaying your markets. This could be due to a temporary issue.
          </p>
        </CardContent>
        <CardFooter>
          <Button 
            onClick={onReset} 
            className="w-full gap-2"
          >
            <RefreshCw className="h-4 w-4" />
            Reload Markets
          </Button>
        </CardFooter>
      </Card>
    </div>
  );

  return (
    <UnifiedErrorBoundary onReset={onReset} fallback={fallback}>
      {children}
    </UnifiedErrorBoundary>
  );
};
