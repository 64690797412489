
import { useEffect, useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Session } from "@supabase/supabase-js";
import { participationVerifier } from "@/utils/market/verification/participation-verifier";
import { getMarketsQueryKey } from "@/hooks/markets/use-markets-core";
import { logger } from "@/utils/logger";
import { Market } from "@/types/market";
import { useToast } from "@/hooks/use-toast";

/**
 * Hook for reconciling participation data to ensure consistency between
 * backend state and frontend tab display
 */
export const useMarketsReconciliation = (
  session: Session | null,
  activeTab: string,
  participatingCount: number,
  displayedCount: number
) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const [isReconciling, setIsReconciling] = useState(false);
  const [lastReconciliation, setLastReconciliation] = useState<number | null>(null);
  
  /**
   * Reconcile market participation data to fix inconsistencies
   */
  const reconcileMarketData = useCallback(async () => {
    if (!session?.user?.id || isReconciling) return false;
    
    setIsReconciling(true);
    
    try {
      logger.info('Starting market data reconciliation:', {
        userId: session.user.id,
        activeTab,
        participatingCount,
        displayedCount
      });
      
      // First reconcile the participation verifier cache
      const reconciled = await participationVerifier.reconcileCache(session.user.id);
      
      logger.info('Cache reconciliation completed:', {
        reconciledEntries: reconciled
      });
      
      // Then invalidate the markets query to force a fresh fetch
      await queryClient.invalidateQueries({
        queryKey: getMarketsQueryKey(session.user.id),
      });
      
      // Update markets to fix isParticipating flags
      queryClient.setQueryData(
        getMarketsQueryKey(session.user.id),
        (oldData: Market[] | undefined) => {
          if (!oldData) return oldData;
          
          // Get all cache keys for this user
          const userId = session.user.id;
          const cacheKeys = Array.from(oldData).map(market => `${userId}:${market.id}`);
          
          // Update isParticipating flags based on reconciled participation data
          return oldData.map(market => {
            const cacheKey = `${userId}:${market.id}`;
            const cached = participationVerifier['verifiedParticipations'].get(cacheKey);
            
            if (cached && cached.exists) {
              return {
                ...market,
                isParticipating: true
              };
            }
            
            return market;
          });
        }
      );
      
      setLastReconciliation(Date.now());
      logger.info('Market data reconciliation completed');
      
      return true;
    } catch (error) {
      logger.error('Error reconciling market data:', error);
      return false;
    } finally {
      setIsReconciling(false);
    }
  }, [session, queryClient, activeTab, participatingCount, displayedCount, isReconciling]);
  
  /**
   * Check for data inconsistency threshold
   */
  useEffect(() => {
    // Skip if not on joined tab or no session
    if (activeTab !== 'joined' || !session?.user?.id) return;
    
    // Skip if already reconciling or recently reconciled (last 60 seconds)
    if (isReconciling || (lastReconciliation && Date.now() - lastReconciliation < 60000)) return;
    
    // Check if there's a significant inconsistency
    const hasInconsistency = participatingCount > 0 && displayedCount === 0;
    
    if (hasInconsistency) {
      logger.warn('Data inconsistency detected:', {
        participatingCount,
        displayedCount,
        activeTab
      });
      
      // Show toast to user
      toast({
        title: "Data Sync Issue Detected",
        description: "We're reconciling your participation data. Please wait a moment.",
        variant: "default",
      });
      
      // Trigger reconciliation
      reconcileMarketData();
    }
  }, [
    session, 
    activeTab, 
    participatingCount, 
    displayedCount, 
    reconcileMarketData, 
    isReconciling, 
    lastReconciliation,
    toast
  ]);
  
  return {
    reconcileMarketData,
    isReconciling,
    lastReconciliation
  };
};
