
import { logger } from '@/utils/logger';
import { ErrorDetails } from './error-types';

interface PostgrestError {
  message: string;
  details?: string;
  hint?: string;
  code?: string;
}

/**
 * Check if an error is a Postgrest error
 */
export function isPostgrestError(error: any): error is PostgrestError {
  return (
    error && 
    typeof error === 'object' && 
    'message' in error &&
    (
      'code' in error || 
      'details' in error || 
      'hint' in error
    )
  );
}

/**
 * Extract details from a Supabase error
 */
export function extractErrorDetails(error: any): ErrorDetails {
  if (!error) {
    return {
      message: 'Unknown error',
    };
  }
  
  // Handle PostgrestError objects
  if (isPostgrestError(error)) {
    return {
      message: error.message,
      code: error.code,
      details: error.details,
      hint: error.hint
    };
  }
  
  // Handle standard Error objects
  if (error instanceof Error) {
    return {
      message: error.message
    };
  }
  
  // Handle string errors
  if (typeof error === 'string') {
    return {
      message: error
    };
  }
  
  // Handle unknown object errors
  if (typeof error === 'object') {
    try {
      const message = error.message || error.error || JSON.stringify(error);
      return { message };
    } catch (e) {
      return { message: 'Unknown error object' };
    }
  }
  
  // Default fallback
  return {
    message: 'Unknown error type'
  };
}

/**
 * Log Supabase errors with consistent formatting
 */
export function logSupabaseError(
  context: string, 
  error: any, 
  metadata: Record<string, any> = {}
): void {
  const details = extractErrorDetails(error);
  
  logger.error(`Supabase error in ${context}:`, {
    message: details.message,
    code: details.code,
    details: details.details,
    hint: details.hint,
    ...metadata
  });
}
