import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { MarketForManagement } from "@/hooks/admin/use-user-market-management";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Slider } from "@/components/ui/slider";
import { Calculator, RefreshCw, TrendingUp, ArrowRight, Calendar, Wallet } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Progress } from "@/components/ui/progress";
import { Separator } from "@/components/ui/separator";
import { Badge } from "@/components/ui/badge";
import { 
  Table, 
  TableBody, 
  TableCaption, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";

interface User {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  balance: number;
  status: string | null;
  member_share_percentage: number;
}

interface MemberMarketCalculatorProps {
  user: User;
  market: MarketForManagement | null;
  markets: MarketForManagement[];
  onMarketSelect: (market: MarketForManagement) => void;
}

export const MemberMarketCalculator: React.FC<MemberMarketCalculatorProps> = ({
  user,
  market,
  markets,
  onMarketSelect
}) => {
  const [selectedMarketId, setSelectedMarketId] = useState<string>(market?.id || "");
  const [contributionAmount, setContributionAmount] = useState<number>(100);
  const [contributionPeriod, setContributionPeriod] = useState<number>(30);
  const [expectedROI, setExpectedROI] = useState<number>(5);
  const [calculatedResults, setCalculatedResults] = useState<{
    totalContribution: number;
    estimatedReturn: number;
    memberShare: number;
    platformFee: number;
    netReturn: number;
    returnDate: Date;
  } | null>(null);
  
  useEffect(() => {
    if (market) {
      setSelectedMarketId(market.id);
      // Initialize with market's daily contribution target if available
      setContributionAmount(market.daily_contribution_target || 100);
    }
  }, [market]);
  
  useEffect(() => {
    // When market selection changes, update the form
    const selectedMarket = markets.find(m => m.id === selectedMarketId);
    if (selectedMarket) {
      onMarketSelect(selectedMarket);
      setContributionAmount(selectedMarket.daily_contribution_target || 100);
    }
  }, [selectedMarketId, markets, onMarketSelect]);
  
  const handleCalculate = () => {
    if (!market) return;
    
    const totalContribution = contributionAmount * contributionPeriod;
    const estimatedReturn = totalContribution * (1 + (expectedROI / 100));
    const platformFee = estimatedReturn * 0.02; // Assuming 2% platform fee
    const memberShare = estimatedReturn * (user.member_share_percentage / 100);
    const netReturn = estimatedReturn - platformFee;
    
    // Calculate return date (today + contribution period days)
    const returnDate = new Date();
    returnDate.setDate(returnDate.getDate() + contributionPeriod);
    
    setCalculatedResults({
      totalContribution,
      estimatedReturn,
      memberShare,
      platformFee,
      netReturn,
      returnDate
    });
  };
  
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR'
    }).format(amount);
  };
  
  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-ZA', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };
  
  return (
    <div className="space-y-6">
      <Card className="border-primary/20">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Calculator className="h-5 w-5 text-primary" />
            Market Return Calculator
          </CardTitle>
          <CardDescription>
            Calculate potential returns for {user.first_name} {user.last_name} based on contribution amount and period
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="market-select">Select Market</Label>
                <Select 
                  value={selectedMarketId} 
                  onValueChange={setSelectedMarketId}
                >
                  <SelectTrigger id="market-select">
                    <SelectValue placeholder="Select a market" />
                  </SelectTrigger>
                  <SelectContent>
                    {markets.map(m => (
                      <SelectItem key={m.id} value={m.id}>
                        {m.name} ({m.region})
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              
              {market && (
                <div className="rounded-md border p-4 bg-muted/30">
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div className="text-muted-foreground">Market Type:</div>
                    <div>{market.market_type}</div>
                    <div className="text-muted-foreground">Risk Level:</div>
                    <div>{market.market_risk_level}</div>
                    <div className="text-muted-foreground">Target Value:</div>
                    <div>{formatCurrency(market.target_value)}</div>
                    <div className="text-muted-foreground">Current Value:</div>
                    <div>{formatCurrency(market.current_value)}</div>
                    <div className="text-muted-foreground">Progress:</div>
                    <div className="flex items-center gap-2">
                      <Progress 
                        value={(market.current_value / market.target_value) * 100} 
                        className="h-2 flex-1" 
                      />
                      <span className="text-xs">
                        {Math.round((market.current_value / market.target_value) * 100)}%
                      </span>
                    </div>
                  </div>
                </div>
              )}
              
              <div className="space-y-2">
                <Label htmlFor="contribution-amount">Daily Contribution Amount</Label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">R</span>
                  <Input 
                    id="contribution-amount"
                    type="number" 
                    value={contributionAmount} 
                    onChange={(e) => setContributionAmount(Number(e.target.value))}
                    className="pl-8"
                  />
                </div>
                <div className="pt-2">
                  <Slider
                    value={[contributionAmount]}
                    min={10}
                    max={1000}
                    step={10}
                    onValueChange={(value) => setContributionAmount(value[0])}
                  />
                  <div className="flex justify-between text-xs text-muted-foreground mt-1">
                    <span>R10</span>
                    <span>R1,000</span>
                  </div>
                </div>
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="contribution-period">Contribution Period (Days)</Label>
                <Input 
                  id="contribution-period"
                  type="number" 
                  value={contributionPeriod} 
                  onChange={(e) => setContributionPeriod(Number(e.target.value))}
                />
                <div className="pt-2">
                  <Slider
                    value={[contributionPeriod]}
                    min={1}
                    max={90}
                    step={1}
                    onValueChange={(value) => setContributionPeriod(value[0])}
                  />
                  <div className="flex justify-between text-xs text-muted-foreground mt-1">
                    <span>1 day</span>
                    <span>90 days</span>
                  </div>
                </div>
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="expected-roi">Expected ROI (%)</Label>
                <Input 
                  id="expected-roi"
                  type="number" 
                  value={expectedROI} 
                  onChange={(e) => setExpectedROI(Number(e.target.value))}
                />
                <div className="pt-2">
                  <Slider
                    value={[expectedROI]}
                    min={1}
                    max={20}
                    step={0.5}
                    onValueChange={(value) => setExpectedROI(value[0])}
                  />
                  <div className="flex justify-between text-xs text-muted-foreground mt-1">
                    <span>1%</span>
                    <span>20%</span>
                  </div>
                </div>
              </div>
              
              <Button 
                onClick={handleCalculate} 
                className="w-full gap-2"
                disabled={!market}
              >
                <Calculator className="h-4 w-4" />
                Calculate Returns
              </Button>
            </div>
            
            <div className="space-y-4">
              <div className="rounded-md border p-4">
                <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
                  <TrendingUp className="h-5 w-5 text-primary" />
                  Calculation Results
                </h3>
                
                {calculatedResults ? (
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-y-3">
                      <div className="text-muted-foreground">Total Contribution:</div>
                      <div className="font-medium">{formatCurrency(calculatedResults.totalContribution)}</div>
                      
                      <div className="text-muted-foreground">Estimated Return:</div>
                      <div className="font-medium">{formatCurrency(calculatedResults.estimatedReturn)}</div>
                      
                      <div className="text-muted-foreground">Platform Fee:</div>
                      <div className="font-medium">{formatCurrency(calculatedResults.platformFee)}</div>
                      
                      <div className="text-muted-foreground">Member Share:</div>
                      <div className="font-medium">{formatCurrency(calculatedResults.memberShare)}</div>
                      
                      <div className="text-muted-foreground">Net Return:</div>
                      <div className="font-medium text-green-600">{formatCurrency(calculatedResults.netReturn)}</div>
                      
                      <div className="text-muted-foreground">Return Date:</div>
                      <div className="font-medium">{formatDate(calculatedResults.returnDate)}</div>
                    </div>
                    
                    <Separator />
                    
                    <div className="rounded-md bg-muted/30 p-3">
                      <div className="flex items-center justify-between mb-2">
                        <span className="text-sm font-medium">Profit Summary</span>
                        <Badge variant="outline" className="bg-green-100 text-green-800">
                          {((calculatedResults.netReturn / calculatedResults.totalContribution - 1) * 100).toFixed(2)}% ROI
                        </Badge>
                      </div>
                      <div className="flex items-center gap-2 text-sm">
                        <span className="text-muted-foreground">{formatCurrency(calculatedResults.totalContribution)}</span>
                        <ArrowRight className="h-3 w-3 text-muted-foreground" />
                        <span className="font-medium">{formatCurrency(calculatedResults.netReturn)}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-8 text-muted-foreground">
                    <Calculator className="h-12 w-12 mx-auto mb-2 opacity-20" />
                    <p>Enter values and click Calculate to see results</p>
                  </div>
                )}
              </div>
              
              <Tabs defaultValue="daily">
                <TabsList className="grid grid-cols-3 mb-4">
                  <TabsTrigger value="daily">Daily</TabsTrigger>
                  <TabsTrigger value="weekly">Weekly</TabsTrigger>
                  <TabsTrigger value="monthly">Monthly</TabsTrigger>
                </TabsList>
                
                <TabsContent value="daily" className="space-y-4">
                  <Table>
                    <TableCaption>Daily contribution projection</TableCaption>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Day</TableHead>
                        <TableHead>Contribution</TableHead>
                        <TableHead>Balance</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {calculatedResults && Array.from({ length: Math.min(5, contributionPeriod) }).map((_, i) => (
                        <TableRow key={i}>
                          <TableCell>Day {i + 1}</TableCell>
                          <TableCell>{formatCurrency(contributionAmount)}</TableCell>
                          <TableCell>{formatCurrency(contributionAmount * (i + 1))}</TableCell>
                        </TableRow>
                      ))}
                      {calculatedResults && contributionPeriod > 5 && (
                        <TableRow>
                          <TableCell colSpan={3} className="text-center text-muted-foreground">
                            ... {contributionPeriod - 5} more days ...
                          </TableCell>
                        </TableRow>
                      )}
                      {calculatedResults && (
                        <TableRow>
                          <TableCell>Final (Day {contributionPeriod})</TableCell>
                          <TableCell>{formatCurrency(contributionAmount)}</TableCell>
                          <TableCell className="font-medium">{formatCurrency(calculatedResults.totalContribution)}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TabsContent>
                
                <TabsContent value="weekly" className="space-y-4">
                  <Table>
                    <TableCaption>Weekly contribution projection</TableCaption>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Week</TableHead>
                        <TableHead>Contribution</TableHead>
                        <TableHead>Balance</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {calculatedResults && Array.from({ length: Math.min(4, Math.ceil(contributionPeriod / 7)) }).map((_, i) => (
                        <TableRow key={i}>
                          <TableCell>Week {i + 1}</TableCell>
                          <TableCell>{formatCurrency(contributionAmount * 7)}</TableCell>
                          <TableCell>{formatCurrency(contributionAmount * 7 * (i + 1))}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TabsContent>
                
                <TabsContent value="monthly" className="space-y-4">
                  <Table>
                    <TableCaption>Monthly contribution projection</TableCaption>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Month</TableHead>
                        <TableHead>Contribution</TableHead>
                        <TableHead>Balance</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {calculatedResults && Array.from({ length: Math.min(3, Math.ceil(contributionPeriod / 30)) }).map((_, i) => (
                        <TableRow key={i}>
                          <TableCell>Month {i + 1}</TableCell>
                          <TableCell>{formatCurrency(contributionAmount * 30)}</TableCell>
                          <TableCell>{formatCurrency(contributionAmount * 30 * (i + 1))}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-between border-t pt-4">
          <Button variant="outline" size="sm" onClick={() => window.print()} className="gap-2">
            <Calendar className="h-4 w-4" />
            Save Calculation
          </Button>
          <Button variant="outline" size="sm" className="gap-2">
            <Wallet className="h-4 w-4" />
            Apply to Member
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};
