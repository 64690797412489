
import { useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { Market } from "@/types/market";
import { AlertTriangle } from "lucide-react";
import { ReactNode } from "react";

interface ReportMarketDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  market: Market;
  children?: ReactNode; // Add children prop as optional
}

export const ReportMarketDialog = ({ isOpen, onOpenChange, market, children }: ReportMarketDialogProps) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reason, setReason] = useState<string>("early_closure");
  const [comments, setComments] = useState<string>("");

  const handleSubmit = async () => {
    if (!comments.trim()) {
      toast({
        title: "Comments required",
        description: "Please provide additional details about the issue.",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsSubmitting(true);

      const { error } = await supabase
        .from('market_reports')
        .insert({
          market_id: market.id,
          report_type: "member_complaint",
          data: {
            reason,
            comments,
            market_status: market.status,
            market_name: market.name,
            reported_at: new Date().toISOString()
          }
        });

      if (error) throw error;

      toast({
        title: "Report submitted",
        description: "Your market report has been submitted successfully.",
      });

      setComments("");
      setReason("early_closure");
      onOpenChange(false);
    } catch (error) {
      toast({
        title: "Submission failed",
        description: "There was a problem submitting your report. Please try again.",
        variant: "destructive",
      });
      console.error("Error submitting market report:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-lg font-semibold">
            <AlertTriangle className="h-5 w-5 text-amber-500" />
            Report Market Issue
          </DialogTitle>
          <DialogDescription>
            Report an issue with "{market.name}" market closure or participation.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 py-3">
          <div className="space-y-3">
            <h3 className="font-medium text-sm">Reason for reporting:</h3>
            <RadioGroup value={reason} onValueChange={setReason} className="space-y-2">
              <div className="flex items-start space-x-2">
                <RadioGroupItem value="early_closure" id="early_closure" />
                <Label htmlFor="early_closure" className="cursor-pointer leading-tight">
                  Market closed before expected time
                </Label>
              </div>
              
              <div className="flex items-start space-x-2">
                <RadioGroupItem value="participation_issue" id="participation_issue" />
                <Label htmlFor="participation_issue" className="cursor-pointer leading-tight">
                  I participated but my contribution wasn't counted
                </Label>
              </div>
              
              <div className="flex items-start space-x-2">
                <RadioGroupItem value="incorrect_status" id="incorrect_status" />
                <Label htmlFor="incorrect_status" className="cursor-pointer leading-tight">
                  Market status is incorrect (failed/successful)
                </Label>
              </div>
              
              <div className="flex items-start space-x-2">
                <RadioGroupItem value="other" id="other" />
                <Label htmlFor="other" className="cursor-pointer leading-tight">
                  Other issue
                </Label>
              </div>
            </RadioGroup>
          </div>
          
          <div className="space-y-2">
            <Label htmlFor="comments">Additional details:</Label>
            <Textarea
              id="comments"
              placeholder="Please describe the issue in detail..."
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              className="min-h-[120px]"
            />
          </div>
        </div>

        <DialogFooter className="flex sm:flex-row gap-2 sm:justify-end">
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            disabled={isSubmitting || !comments.trim()}
            className="min-w-[100px]"
          >
            {isSubmitting ? "Submitting..." : "Submit Report"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
