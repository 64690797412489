import { logger } from '@/utils/logger';
import { toast } from '@/hooks/use-toast';
import { AppError, ErrorDetails, MarketErrorType } from './error-types';
import React from 'react';
import { ToastAction, ToastActionElement } from "@/components/ui/toast";
import { isPostgrestError } from './supabase-error';

interface ErrorHandlerOptions {
  context: string;
  fallbackMessage: string;
  logDetails?: Record<string, any>;
  showToast?: boolean;
  retryFn?: () => Promise<any>;
}

/**
 * Categorizes an error based on its message and properties
 */
export function categorizeError(error: any): MarketErrorType {
  if (!error) return 'unknown';
  
  // Handle AppErrors directly using their type
  if (AppError.isAppError(error)) {
    return error.type;
  }
  
  // Handle PostgrestErrors using their code
  if (isPostgrestError(error)) {
    const code = error.code;
    if (code) {
      if (code === '42501' || code === '42503') return 'forbidden';
      if (code === '28P01' || code === '28000') return 'auth';
      if (code === '57014') return 'timeout';
      if (code === '40P01') return 'deadlock';
      if (code === '42P01') return 'not-found';
      if (code.startsWith('23')) return 'validation';
      if (code.startsWith('5')) return 'server';
    }
  }
  
  const message = (error.message || '').toLowerCase();
  
  // Skip categorizing abort errors
  if (message.includes('abort') || 
      message.includes('aborted') || 
      error.name === 'AbortError' || 
      (error as any).isAborted) {
    return 'network';
  }
  
  if (message.includes('403') || message.includes('forbidden') || message.includes('permission denied')) {
    return 'forbidden';
  } else if (message.includes('cors')) {
    return 'cors';
  } else if (message.includes('network') || message.includes('fetch') || message.includes('failed to fetch')) {
    return 'network';
  } else if (message.includes('recursion') || message.includes('policy') || 
            message.includes('42p17') || message.includes('maximum call stack') ||
            message.includes('statement timeout')) {
    return 'recursion';
  } else if (message.includes('deadlock') || message.includes('40p01')) {
    return 'deadlock';
  } else if (message.includes('timeout') || message.includes('timed out')) {
    return 'timeout';
  } else if (message.includes('not found') || message.includes('404')) {
    return 'not-found';
  } else if (message.includes('validation') || message.includes('invalid')) {
    return 'validation';
  } else if (message.includes('auth') || message.includes('unauthorized') || 
            message.includes('401') || message.includes('unauthenticated') ||
            message.includes('authentication')) {
    return 'auth';
  } else if (message.includes('500') || message.includes('server error')) {
    return 'server';
  } else {
    return 'unknown';
  }
}

/**
 * Determines if an error is retryable
 */
export function isErrorRetryable(error: any): boolean {
  // AppErrors have an explicit retryable flag
  if (AppError.isAppError(error)) {
    return error.retryable;
  }
  
  // PostgrestErrors can be checked by code
  if (isPostgrestError(error)) {
    const code = error.code;
    // Server errors, timeouts, and deadlocks are typically retryable
    if (code && (code.startsWith('5') || code === '57014' || code === '40P01')) {
      return true;
    }
  }
  
  const type = categorizeError(error);
  
  // These error types are generally retryable
  const retryableTypes: MarketErrorType[] = [
    'network',
    'timeout',
    'deadlock',
    'server'
  ];
  
  // These error types are not retryable
  const nonRetryableTypes: MarketErrorType[] = [
    'forbidden',
    'auth',
    'validation',
    'not-found'
  ];
  
  if (nonRetryableTypes.includes(type)) {
    return false;
  }
  
  return retryableTypes.includes(type);
}

/**
 * Centralized error handler with consistent logging, user feedback, and retry options
 */
export function handleError(error: unknown, options: ErrorHandlerOptions) {
  const { context, fallbackMessage, logDetails = {}, showToast = true, retryFn } = options;
  
  // Determine error properties
  const isAppError = AppError.isAppError(error);
  const errorMessage = isAppError 
    ? error.message 
    : error instanceof Error 
      ? error.message 
      : typeof error === 'string'
        ? error
        : fallbackMessage;
  
  const errorType = isAppError
    ? error.type
    : categorizeError(error);
  
  const retryable = isAppError
    ? error.retryable
    : isErrorRetryable(error);
  
  // Create a structured error object for logging
  const errorDetails = {
    message: errorMessage,
    type: errorType,
    context,
    timestamp: new Date().toISOString(),
    retryable,
    stack: error instanceof Error ? error.stack : undefined,
    code: isPostgrestError(error) ? error.code : undefined,
    details: isPostgrestError(error) ? error.details : undefined,
    ...logDetails
  };
  
  // Log the error with consistent format
  logger.error(`Error in ${context}:`, errorDetails);
  
  // Show toast notification if enabled
  if (showToast) {
    // Create appropriate toast content based on error type
    const title = (() => {
      switch (errorType) {
        case 'network': return 'Network Error';
        case 'auth': return 'Authentication Error';
        case 'forbidden': return 'Access Denied';
        case 'timeout': return 'Request Timeout';
        case 'server': return 'Server Error';
        case 'validation': return 'Validation Error';
        case 'not-found': return 'Not Found';
        default: return `Error: ${context}`;
      }
    })();
    
    // Only create the retry action if there's a retry function and the error is retryable
    if (retryFn && retryable) {
      // Create the retry action using React.createElement instead of JSX
      const retryAction = React.createElement(
        ToastAction,
        { altText: "Retry", onClick: retryFn },
        "Retry"
      ) as unknown as ToastActionElement;
      
      toast({
        title,
        description: errorMessage,
        variant: "destructive",
        action: retryAction
      });
    } else {
      toast({
        title,
        description: errorMessage,
        variant: "destructive"
      });
    }
  }
  
  // Return the error details for potential further handling
  return {
    error,
    message: errorMessage,
    type: errorType,
    retryable,
    details: errorDetails
  };
}

/**
 * Process API responses with consistent error handling
 */
export const processApiResponse = async <T>(
  promise: Promise<{ data: T | null; error: any }>,
  options: ErrorHandlerOptions
): Promise<T> => {
  try {
    const { data, error } = await promise;
    
    if (error) {
      handleError(error, options);
      throw error;
    }
    
    if (!data) {
      const noDataError = new Error("No data received from API");
      handleError(noDataError, options);
      throw noDataError;
    }
    
    return data;
  } catch (error) {
    handleError(error, { ...options, showToast: options.showToast !== false });
    throw error;
  }
};

/**
 * Safely execute functions with proper error boundaries
 */
export const safelyExecute = async <T>(
  fn: () => Promise<T>, 
  options: ErrorHandlerOptions
): Promise<{ success: boolean; data?: T; error?: any }> => {
  try {
    const result = await fn();
    return { success: true, data: result };
  } catch (error) {
    const errorResult = handleError(error, options);
    return { success: false, error: errorResult.error };
  }
};
