
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { SidebarContent as BaseSidebarContent, SidebarFooter, SidebarHeader } from "@/components/ui/sidebar";
import { ChevronLeft, ChevronRight, LogOut } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { NavMenuItem } from "./NavMenuItem";
import { NavItem } from "./navigation-items";

interface SidebarContentsProps {
  isAdmin: boolean;
  isCollapsed: boolean;
  isMobile: boolean;
  navItems: NavItem[];
  onCollapse: () => void;
  onSignOut: () => void;
  onNavigate?: () => void;
}

export function SidebarContents({
  isAdmin,
  isCollapsed,
  isMobile,
  navItems,
  onCollapse,
  onSignOut,
  onNavigate
}: SidebarContentsProps) {
  return <>
      <SidebarHeader className="border-b border-border/30 py-6 pt-20">
        <div className="flex h-12 items-center px-4 justify-between">
          <span className={cn(
            "text-xl font-bold bg-gradient-to-r from-primary to-primary/80 bg-clip-text text-transparent",
            "transition-all duration-300",
            isCollapsed && !isMobile ? "scale-0 w-0 opacity-0" : "scale-100 w-auto opacity-100"
          )}>
            {isAdmin ? "Admin Portal" : "Member Portal"}
          </span>
          {!isMobile && (
            <Button 
              variant="ghost" 
              size="icon" 
              onClick={onCollapse} 
              aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
              className="rounded-full hover:bg-primary/10 transition-all duration-200"
            >
              {isCollapsed ? 
                <ChevronRight className="h-5 w-5 text-primary dark:text-primary" /> : 
                <ChevronLeft className="h-5 w-5 text-primary dark:text-primary" />
              }
            </Button>
          )}
        </div>
      </SidebarHeader>
      
      <BaseSidebarContent className="px-3 py-4">
        <nav className="space-y-2">
          {navItems.map(item => (
            <NavMenuItem 
              key={item.path} 
              item={item} 
              isCollapsed={isCollapsed} 
              isMobile={isMobile} 
              onNavigate={onNavigate} 
            />
          ))}
        </nav>
      </BaseSidebarContent>
      
      <SidebarFooter className="border-t border-border/30 p-4 mt-auto">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button 
                variant="ghost" 
                className={cn(
                  "w-full gap-3 transition-all duration-200",
                  "rounded-lg hover:bg-destructive/10 hover:text-destructive dark:hover:text-destructive",
                  isCollapsed && !isMobile ? "justify-center px-2" : "justify-start px-3.5 py-2.5"
                )}
                onClick={onSignOut}
              >
                <LogOut className="h-5 w-5 text-zinc-600 dark:text-zinc-400 hover:text-destructive dark:hover:text-destructive" />
                <span className={cn(
                  "font-medium whitespace-nowrap transition-all duration-300 text-zinc-700 dark:text-zinc-300",
                  !isMobile && isCollapsed ? "hidden" : "block"
                )}>
                  Sign Out
                </span>
              </Button>
            </TooltipTrigger>
            {!isMobile && isCollapsed && (
              <TooltipContent 
                side="right" 
                className="bg-card/95 backdrop-blur-sm text-card-foreground border border-border/30"
              >
                Sign Out
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </SidebarFooter>
    </>;
}
