
import { useState, useEffect } from "react";
import { Session } from "@supabase/supabase-js";
import { useToast } from "@/hooks/use-toast";
import { logger } from "@/utils/logger";
import { useMarketsQuery } from "./use-markets-query";
import { useMarketFilters } from "./use-market-filters";
import { useMarketActions } from "./use-market-actions";
import { setupMarketSyncEventListeners, triggerMarketSync } from "@/utils/market/sync/market-sync-events";
import { toast } from "sonner";

export const useMarketsManagement = (session: Session | null) => {
  const { toast: uiToast } = useToast();
  const [attempts, setAttempts] = useState(0);
  const [lastQueryTime, setLastQueryTime] = useState<number | null>(null);
  const [isSyncing, setIsSyncing] = useState(false);

  const { data: markets, isLoading, error, refetch } = useMarketsQuery(
    session,
    { attempts, lastQueryTime },
    { setAttempts, setLastQueryTime }
  );

  const {
    searchQuery,
    selectedRegion,
    riskLevel,
    setSearchQuery,
    setSelectedRegion,
    setRiskLevel,
    filterMarkets
  } = useMarketFilters();

  const { handleParticipate } = useMarketActions(session);
  
  // Set up listeners for market sync events
  useEffect(() => {
    const cleanup = setupMarketSyncEventListeners(
      // On sync start
      () => {
        setIsSyncing(true);
      },
      // On sync complete
      (success, error) => {
        setIsSyncing(false);
        
        if (success) {
          // Refetch data when sync completes successfully
          refetch();
        } else if (error) {
          logger.error('Sync error in useMarketsManagement:', error);
        }
      }
    );
    
    // Listen for a wide variety of events that should trigger market refreshes
    const handleMarketUpdates = (event: Event) => {
      const customEvent = event as CustomEvent;
      logger.info(`Market update event received: ${event.type}`, customEvent.detail);
      refetch();
    };
    
    const marketUpdateEvents = [
      'market-targets-updated',
      'regional-targets-updated',
      'regional-settings-updated',
      'member-targets-synced',
      'user-target-updated',
      'memberTargetsUpdated',
      'memberMarketsUpdated'
    ];
    
    marketUpdateEvents.forEach(eventType => {
      window.addEventListener(eventType, handleMarketUpdates);
    });
    
    return () => {
      cleanup();
      marketUpdateEvents.forEach(eventType => {
        window.removeEventListener(eventType, handleMarketUpdates);
      });
    };
  }, [refetch]);

  const handleRefresh = async (): Promise<void> => {
    logger.info('Manual refresh requested:', {
      attempts,
      lastQueryTime,
      userEmail: session?.user?.email,
      timestamp: new Date().toISOString()
    });
    
    toast.info("Refreshing markets data...");
    setIsSyncing(true);
    
    try {
      // Trigger a full market sync
      triggerMarketSync(session?.user?.id, {
        force: true,
        showToast: false,
        source: 'manual-refresh'
      });
      
      await refetch();
      toast.success("Markets data refreshed successfully");
    } catch (error) {
      logger.error('Error refreshing markets data:', error);
      toast.error("There was an error refreshing market data");
      
      uiToast({
        title: "Error refreshing",
        description: "There was an error refreshing market data.",
        variant: "destructive",
      });
    } finally {
      setIsSyncing(false);
    }
  };

  return {
    markets: filterMarkets(markets),
    isLoading: isLoading || isSyncing,
    error,
    searchQuery,
    selectedRegion,
    riskLevel,
    setSearchQuery,
    setSelectedRegion,
    setRiskLevel,
    handleParticipate: async (marketId: string) => {
      const success = await handleParticipate(marketId);
      if (success) await refetch();
    },
    handleRefresh,
    refetch,
    isSyncing,
    debugInfo: {
      queryAttempts: attempts,
      lastQueryTime,
      hasSession: !!session,
      userId: session?.user?.id
    }
  };
};
