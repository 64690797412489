
import React, { useState } from "react";
import { Market } from "@/types/market";
import { MarketCard } from "@/components/markets/MarketCard";
import { Loader2, GridIcon, List } from "lucide-react";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

export interface AdminMarketsListProps {
  markets: Market[] | null;
  isLoading: boolean;
  onRefresh?: () => Promise<void>;
  showConfiguration?: boolean;
}

export const AdminMarketsList = ({
  markets,
  isLoading,
  onRefresh,
  showConfiguration = true
}: AdminMarketsListProps) => {
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
      </div>
    );
  }
  
  if (!markets || markets.length === 0) {
    return (
      <div className="text-center py-8 text-muted-foreground">
        No markets found.
      </div>
    );
  }
  
  // Dummy functions to satisfy required props - these are needed but won't be used in admin view
  const handleParticipate = async (marketId: string) => {
    console.log("Admin participate clicked for market:", marketId);
    return Promise.resolve();
  };

  const handleParticipateSuccess = async (marketId: string) => {
    console.log("Admin participate success for market:", marketId);
    return Promise.resolve();
  };

  const handleCloseTimeUpdate = async (marketId: string, closeTime: string) => {
    console.log("Admin close time update for market:", marketId, closeTime);
    return Promise.resolve();
  };
  
  return (
    <div className="space-y-4">
      <div className="flex justify-end mb-4">
        <ToggleGroup type="single" value={viewMode} onValueChange={(value) => value && setViewMode(value as 'grid' | 'list')}>
          <ToggleGroupItem value="grid" aria-label="Grid view">
            <GridIcon className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem value="list" aria-label="List view">
            <List className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      
      <div className={viewMode === 'grid' 
        ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" 
        : "space-y-4"
      }>
        {markets.map((market) => (
          <MarketCard 
            key={market.id}
            market={market}
            markets={markets}
            isAdminView={true} // Explicitly set isAdminView to true for admin list
            showConfiguration={showConfiguration}
            onParticipate={handleParticipate}
            onParticipateSuccess={handleParticipateSuccess}
            onCloseTimeUpdate={handleCloseTimeUpdate}
            variant={viewMode === 'list' ? 'horizontal' : 'default'}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminMarketsList;
