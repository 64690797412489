
import { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';
import { toast } from 'sonner';

type RealtimeTableConfig = {
  table: string;
  schema?: string;
  events?: ('INSERT' | 'UPDATE' | 'DELETE')[];
  filter?: string;
  filterValue?: any;
}

export function useRealtimeData<T>(
  fetchFunction: () => Promise<T>, 
  tables: RealtimeTableConfig[],
  initialRefreshInterval?: number,
  forceRefreshToken?: number
) {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const result = await fetchFunction();
      setData(result);
      setLastUpdated(new Date());
      setError(null);
      logger.info('Data refreshed successfully', { timestamp: new Date().toISOString() });
    } catch (err) {
      logger.error('Error fetching data:', err);
      setError(err instanceof Error ? err : new Error('Failed to fetch data'));
      toast.error('Failed to load the latest data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to handle force refresh token changes
  useEffect(() => {
    if (forceRefreshToken && forceRefreshToken > 0) {
      logger.info('Force refresh token changed, triggering data refresh');
      fetchData();
    }
  }, [forceRefreshToken]);

  useEffect(() => {
    // Initial fetch
    fetchData();

    // Set up real-time listeners for all specified tables
    const channels = tables.map((tableConfig, index) => {
      const { table, schema = 'public', events = ['INSERT', 'UPDATE', 'DELETE'] } = tableConfig;
      
      const channelId = `${table}-changes-${index}-${Date.now()}`;  // Add timestamp to ensure unique channel IDs
      
      // Create subscription configurations
      const subscriptions = events.map(event => ({
        event,
        schema,
        table,
        ...(tableConfig.filter && tableConfig.filterValue 
          ? { filter: `${tableConfig.filter}=eq.${tableConfig.filterValue}` } 
          : {})
      }));
      
      // Create and configure the channel
      const channel = supabase.channel(channelId);
      
      // Add handlers for each subscription type
      subscriptions.forEach(subscription => {
        channel.on(
          'postgres_changes' as any, // Type cast to fix the TypeScript error
          subscription,
          (payload) => {
            logger.info(`Real-time update detected on ${table}`, { 
              event: subscription.event,
              payload
            });
            fetchData();
          }
        );
      });
      
      // Subscribe to the channel with improved error handling
      channel.subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          logger.info(`Successfully subscribed to ${table} changes`);
        } else if (status === 'CHANNEL_ERROR') {
          logger.error(`Failed to subscribe to ${table} changes: CHANNEL_ERROR`);
        } else if (status === 'CLOSED') {
          logger.error(`Failed to subscribe to ${table} changes: CLOSED`);
          // Don't automatically try to reconnect here as it may cause infinite loops
        } else if (status === 'TIMED_OUT') {
          logger.error(`Subscription to ${table} changes timed out`);
        } else {
          logger.warn(`Unexpected channel status for ${table}: ${status}`);
        }
      });
      
      return channel;
    });

    // Optional periodic refresh as a fallback
    let intervalId: number | undefined;
    if (initialRefreshInterval) {
      intervalId = window.setInterval(() => {
        logger.info('Triggering periodic refresh');
        fetchData();
      }, initialRefreshInterval);
    }

    // Cleanup
    return () => {
      if (intervalId) clearInterval(intervalId);
      
      // Properly clean up channels and handle any errors
      channels.forEach(channel => {
        try {
          supabase.removeChannel(channel);
        } catch (err) {
          logger.warn('Error removing channel:', err);
        }
      });
    };
  }, []); // Keep dependencies empty for initial setup

  const refresh = () => {
    logger.info('Manual refresh requested');
    return fetchData();
  };

  return {
    data,
    isLoading,
    error,
    refresh,
    lastUpdated
  };
}
