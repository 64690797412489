
import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { Save } from "lucide-react";
import type { RegionalSettings } from "@/types/financial";

const regionDisplayNames: Record<string, string> = {
  AUS: "Australia",
  RSA: "South Africa",
  UK: "United Kingdom",
  USA: "United States"
};

interface RegionalTargetSettingsProps {
  regions: RegionalSettings[];
  isLoading: boolean;
  onSaveRegion: (regionData: RegionalSettings) => Promise<boolean>;
}

export const RegionalTargetSettings: React.FC<RegionalTargetSettingsProps> = ({
  regions,
  isLoading,
  onSaveRegion
}) => {
  const { toast } = useToast();
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [regionData, setRegionData] = useState<RegionalSettings>({
    region: '',
    baseTarget: 5000,
    adjustment: 500,
    adjustmentFactor: 1.07
  });

  // Initialize with the first region or default values when regions are provided or updated
  useEffect(() => {
    if (regions && regions.length > 0) {
      const defaultRegion = regions[0].region;
      setSelectedRegion(defaultRegion);
      setRegionData(regions[0]);
      
      console.log("Initializing with region:", defaultRegion, regions[0]);
    }
  }, [regions]);

  // Update the region data when selection changes
  const handleRegionChange = (value: string) => {
    console.log("Region selected:", value);
    setSelectedRegion(value);
    
    const selected = regions.find(r => r.region === value);
    if (selected) {
      console.log("Found matching region data:", selected);
      setRegionData(selected);
    } else {
      console.warn("No matching region found for:", value);
      // If no matching region found (shouldn't happen but as a fallback)
      setRegionData({
        region: value,
        baseTarget: 5000,
        adjustment: 500,
        adjustmentFactor: 1.07
      });
    }
  };

  const handleSaveRegion = async () => {
    try {
      const success = await onSaveRegion(regionData);
      
      if (success) {
        toast({
          title: "Region Settings Saved",
          description: `Settings for ${regionDisplayNames[selectedRegion]} have been updated`,
        });
      }
    } catch (error) {
      console.error('Error saving region settings:', error);
      toast({
        title: "Save Failed",
        description: "Could not update regional settings. Please try again.",
        variant: "destructive",
      });
    }
  };

  const calculateMarketTarget = (baseTarget: number, adjustment: number, factor: number): number => {
    return baseTarget + (adjustment / factor);
  };

  // Don't render anything if there are no regions
  if (!regions || regions.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Regional Target Settings</CardTitle>
          <CardDescription>No regions available to configure</CardDescription>
        </CardHeader>
        <CardContent>
          <p className="text-center text-muted-foreground py-8">
            No regional data is currently available
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Regional Target Settings</CardTitle>
        <CardDescription>
          Configure region-specific target parameters
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Select Region</label>
            <Select
              value={selectedRegion}
              onValueChange={handleRegionChange}
              disabled={isLoading}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a region" />
              </SelectTrigger>
              <SelectContent>
                {regions.map((region) => (
                  <SelectItem key={region.region} value={region.region}>
                    {regionDisplayNames[region.region]}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {selectedRegion && (
            <div className="p-4 border border-border/50 rounded-md">
              <div className="flex items-center justify-between mb-4">
                <h4 className="text-lg font-semibold">{regionDisplayNames[selectedRegion]}</h4>
                <div className="text-sm px-3 py-1 bg-primary/10 rounded-md font-mono">
                  Target: {calculateMarketTarget(regionData.baseTarget, regionData.adjustment, regionData.adjustmentFactor).toFixed(2)}
                </div>
              </div>
              
              <div className="grid gap-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium">Base Target</label>
                  <Input
                    type="number"
                    value={regionData.baseTarget}
                    onChange={(e) => setRegionData({
                      ...regionData, 
                      baseTarget: parseFloat(e.target.value) || 0
                    })}
                    disabled={isLoading}
                  />
                </div>
                
                <div className="space-y-2">
                  <label className="text-sm font-medium">Adjustment</label>
                  <Input
                    type="number"
                    value={regionData.adjustment}
                    onChange={(e) => setRegionData({
                      ...regionData, 
                      adjustment: parseFloat(e.target.value) || 0
                    })}
                    disabled={isLoading}
                  />
                </div>
                
                <div className="space-y-2">
                  <label className="text-sm font-medium">Adjustment Factor</label>
                  <Input
                    type="number"
                    step="0.01"
                    value={regionData.adjustmentFactor}
                    onChange={(e) => setRegionData({
                      ...regionData, 
                      adjustmentFactor: parseFloat(e.target.value) || 1
                    })}
                    disabled={isLoading}
                  />
                </div>
              </div>
              
              <div className="mt-6">
                <Button 
                  className="w-full" 
                  disabled={isLoading}
                  onClick={handleSaveRegion}
                >
                  <Save className="h-4 w-4 mr-2" />
                  Save {regionDisplayNames[selectedRegion]} Settings
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="overflow-x-auto mt-6">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-muted/50">
                <th className="text-left p-2 border">Region</th>
                <th className="text-left p-2 border">Base Target</th>
                <th className="text-left p-2 border">Adjustment</th>
                <th className="text-left p-2 border">Factor</th>
                <th className="text-left p-2 border">Calculated Target</th>
              </tr>
            </thead>
            <tbody>
              {regions.map((region) => (
                <tr 
                  key={region.region} 
                  className={`hover:bg-muted/20 ${selectedRegion === region.region ? 'bg-primary/5' : ''}`}
                >
                  <td className="p-2 border">{regionDisplayNames[region.region]}</td>
                  <td className="p-2 border">{region.baseTarget.toFixed(2)}</td>
                  <td className="p-2 border">{region.adjustment.toFixed(2)}</td>
                  <td className="p-2 border">{region.adjustmentFactor.toFixed(2)}</td>
                  <td className="p-2 border font-medium">
                    {calculateMarketTarget(region.baseTarget, region.adjustment, region.adjustmentFactor).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardContent>
    </Card>
  );
};
