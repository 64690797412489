
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, Save } from "lucide-react";
import { renderFormFields } from "../form-fields/FormFields";
import * as z from "zod";
import { marketFormSchema } from "../config/formConfig";

interface FormStepCardProps {
  title: string;
  description: string;
  currentFields: Array<keyof z.infer<typeof marketFormSchema>>;
  form: UseFormReturn<z.infer<typeof marketFormSchema>>;
  step: number;
  isLastStep: boolean;
  onBack: () => void;
  onNext: () => void;
  isSubmitting: boolean;
}

export function FormStepCard({
  title,
  description,
  currentFields,
  form,
  step,
  isLastStep,
  onBack,
  onNext,
  isSubmitting
}: FormStepCardProps) {
  return (
    <Card className="border-primary/10 overflow-hidden">
      <CardHeader className="bg-muted/30 pb-4">
        <CardTitle className="text-xl flex items-center">
          {title}
        </CardTitle>
        <p className="text-muted-foreground text-sm">{description}</p>
      </CardHeader>
      <CardContent className="p-6">
        {renderFormFields(currentFields, form)}
      </CardContent>
      <CardFooter className="flex justify-between p-4 pt-0">
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={onBack}
          disabled={step === 0}
          className="gap-2"
        >
          <ChevronLeft className="h-4 w-4" />
          Back
        </Button>
        <Button
          type="button"
          variant={isLastStep ? "default" : "outline"}
          size="sm"
          onClick={onNext}
          disabled={isSubmitting}
          className="gap-2"
        >
          {isLastStep ? (
            <>
              {isSubmitting ? "Creating Market..." : "Create Market"}
              <Save className="h-4 w-4" />
            </>
          ) : (
            <>
              Next
              <ChevronRight className="h-4 w-4" />
            </>
          )}
        </Button>
      </CardFooter>
    </Card>
  );
}
