
import { supabase } from '@/lib/supabase';
import { isPast } from 'date-fns';
import { Market } from '@/types/market';
import { logger } from '@/utils/logger';
import { toast } from 'sonner';

/**
 * Checks for and updates scheduled markets that should be active
 * because their start date has passed
 */
export const updateScheduledMarkets = async (markets: Market[]): Promise<boolean> => {
  if (!markets || markets.length === 0) {
    return false;
  }

  // Find scheduled markets with passed start dates
  const scheduledMarketsWithPassedStartDate = markets.filter(m => 
    m.status === 'scheduled' && 
    m.start_date && 
    isPast(new Date(m.start_date))
  );

  if (scheduledMarketsWithPassedStartDate.length === 0) {
    return false;
  }

  // Log markets that need updating
  logger.info('Found scheduled markets with passed start dates that need to be activated:', {
    count: scheduledMarketsWithPassedStartDate.length,
    markets: scheduledMarketsWithPassedStartDate.map(m => ({
      id: m.id,
      name: m.name,
      start_date: m.start_date,
      current_time: new Date().toISOString()
    }))
  });

  // Update markets to active status
  try {
    const marketsToUpdate = scheduledMarketsWithPassedStartDate.map(m => m.id);
    
    // Update market status to 'active'
    const { error, count } = await supabase
      .from('markets')
      .update({ 
        status: 'active',
        status_update_source: 'Auto-scheduled'
      })
      .in('id', marketsToUpdate);

    if (error) {
      logger.error('Error updating scheduled markets to active:', error);
      return false;
    }
    
    logger.info(`Successfully updated ${count} scheduled markets to active status`);
    
    // Show notification based on how many markets were updated
    if (count === 1) {
      const marketName = scheduledMarketsWithPassedStartDate[0].name;
      toast.success(`Market "${marketName}" is now active and available for participation`);
    } else if (count > 1) {
      toast.success(`${count} scheduled markets are now active and available for participation`);
    }
    
    return count > 0;
  } catch (error) {
    logger.error('Exception updating scheduled markets:', error);
    return false;
  }
};
