import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { useAuth } from "@/contexts/AuthContext";
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Loader2, ArrowLeft, TrendingUp, TrendingDown, Clock, Target, CreditCard, Users, Briefcase, ChevronLeft, AlertTriangle, Flag, Timer, CheckCircle, XCircle, RefreshCw } from "lucide-react";
import { Market } from "@/types/market";
import { supabase } from "@/lib/supabase";
import { formatDistanceToNow } from "date-fns";
import { MarketRiskInfo } from "@/components/markets/MarketRiskInfo";
import { MarketHeader as MarketDetailHeader } from "@/components/markets/MarketHeader";
import { logger } from "@/utils/logger";
import { useMarketContributions } from "@/hooks/use-market-contributions";
import { ContributionHistoryTable } from "@/components/markets/ContributionHistoryTable";
import { ContributionDialog } from "@/components/markets/actions/ContributionDialog";
import { ReportMarketDialog } from "@/components/markets/ReportMarketDialog";
import { toast } from "sonner";
import { parseMarketMetadata, getClosureReason } from "@/utils/market/metadata-parser";
import { formatCurrency } from "@/utils/formatters";
import { CountdownTimer } from "@/components/markets/timer/CountdownTimer";
import { cn } from "@/lib/utils";
import { useMarketStatus } from "@/hooks/markets/use-market-status";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

const MarketDetails = () => {
  const { marketId } = useParams<{ marketId: string }>();
  const [market, setMarket] = useState<Market | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { session } = useAuth();
  const navigate = useNavigate();
  const [isContributionDialogOpen, setIsContributionDialogOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [closureReason, setClosureReason] = useState<string | null>(null);
  const [recentContribution, setRecentContribution] = useState<any>(null);
  const [canDuplicate, setCanDuplicate] = useState(false);
  const [isCreatingDuplicate, setIsCreatingDuplicate] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState<number>(0);
  const [maxAttempts, setMaxAttempts] = useState<number>(0);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const { updateMarketStatus } = useMarketStatus();
  
  const {
    contributions,
    isLoading: loadingContributions,
    refetch: refetchContributions
  } = useMarketContributions(marketId || '', session?.user?.id || '');

  const handleContributionSubmitted = () => {
    logger.info('Contribution submitted successfully, refreshing data', {
      marketId
    });
    refetchContributions();
    
    if (market && ['success', 'closed_successful', 'completed'].includes(market.status)) {
      setIsSuccessDialogOpen(true);
    }
  };

  useEffect(() => {
    if (contributions && contributions.length > 0) {
      const sorted = [...contributions].sort((a, b) => 
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      setRecentContribution(sorted[0]);
      
      if (market) {
        const maxAllowed = market.max_participations_per_user || 3;
        setMaxAttempts(maxAllowed);
        setRemainingAttempts(Math.max(0, maxAllowed - contributions.length));
      }
    }
  }, [contributions, market]);

  useEffect(() => {
    if (market && contributions) {
      const isFailed = ['failed', 'closed_failed', 'failed_final'].includes(market.status);
      const maxAllowed = market.max_participations_per_user || 3;
      
      if (isFailed || contributions.length >= maxAllowed) {
        setRemainingAttempts(0);
      } else {
        setRemainingAttempts(Math.max(0, maxAllowed - contributions.length));
      }
    }
  }, [market, contributions]);

  useEffect(() => {
    const fetchMarketDetails = async () => {
      if (!marketId || !session?.user?.id) return;
      try {
        setIsLoading(true);
        logger.info('Fetching market details', {
          marketId
        });
        const {
          data,
          error
        } = await supabase.from('markets').select('*').eq('id', marketId).single();
        if (error) {
          throw error;
        }
        
        const canDuplicate = await checkDuplicationAvailability(data);
        setCanDuplicate(canDuplicate);
        
        const {
          data: participationData,
          error: participationError
        } = await supabase.from('market_participants').select('*').eq('market_id', marketId).eq('user_id', session.user.id);
        if (participationError) {
          logger.error('Error fetching participation data', participationError);
          // Continue anyway, we'll just assume not participating
        }
        let processedMetadata = parseMarketMetadata(data.metadata);

        const reason = getClosureReason(processedMetadata);
        if (reason) {
          setClosureReason(reason);
        }
        const marketWithParticipation: Market = {
          ...data,
          metadata: processedMetadata,
          isParticipating: participationData && participationData.length > 0,
          participant_count: data.active_participants || 0,
          daily_contributions: 0,
          daily_contribution_percentage: 0,
          growth_rate: Number(data.growth_rate || 0),
          category: (data.category || 'available') as 'joined' | 'available' | 'closed',
          required_contributions: Number(data.required_contributions || 8),
          market_type: (data.market_type || 'standard') as 'standard' | 'premium' | 'exclusive' | 'duplicate',
          market_risk_level: (data.market_risk_level || 'medium') as 'low' | 'medium' | 'high',
          region: data.region || 'global'
        };
        setMarket(marketWithParticipation);
        
        setMaxAttempts(marketWithParticipation.max_participations_per_user || 3);

        if (isClosedStatus(data.status)) {
          fetchMarketHistory(marketId);
        }
        
        if (['success', 'closed_successful', 'completed'].includes(data.status)) {
          setTimeout(() => {
            setIsSuccessDialogOpen(true);
          }, 500);
        }
      } catch (err) {
        logger.error('Error fetching market details', err);
        setError('Failed to load market details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchMarketDetails();
  }, [marketId, session]);
  
  const checkDuplicationAvailability = async (marketData: any) => {
    if (!marketData || !session?.user?.id) return false;
    
    try {
      const { data: duplicates, error } = await supabase
        .from('markets')
        .select('id')
        .eq('parent_market_id', marketData.id);
        
      if (error) throw error;
      
      const maxDuplications = marketData.max_duplications || 3;
      
      return duplicates && duplicates.length < maxDuplications;
    } catch (err) {
      logger.error('Error checking duplication availability', err);
      return false;
    }
  };

  const fetchMarketHistory = async (marketId: string) => {
    try {
      const {
        data,
        error
      } = await supabase.from('market_history').select('*').eq('market_id', marketId).eq('action', 'status_change').order('timestamp', {
        ascending: false
      }).limit(1);
      if (error) throw error;
      if (data && data.length > 0 && data[0].details) {
        const historyDetails = data[0].details;

        if (historyDetails && typeof historyDetails === 'object' && 'reason' in historyDetails) {
          setClosureReason(String(historyDetails.reason));
        }
      }
    } catch (err) {
      logger.error('Error fetching market history', err);
    }
  };

  const handleGoBack = () => {
    navigate('/dashboard/markets');
  };

  const isClosedStatus = (status: string) => {
    return ['closed', 'completed', 'failed', 'success', 'closed_successful', 'closed_failed'].includes(status);
  };
  
  const isFailedStatus = (status: string) => {
    return ['failed', 'closed_failed', 'failed_final'].includes(status);
  };
  
  const isSuccessfulStatus = (status: string) => {
    return ['success', 'closed_successful', 'completed'].includes(status);
  };

  const canSubmitContribution = market && 
    market.isParticipating && 
    (market.status === 'active' || market.status === 'scheduled' && market.start_date && new Date(market.start_date) < new Date()) &&
    !isFailedStatus(market.status) &&
    remainingAttempts > 0;

  const getClosureStatusInfo = () => {
    if (!market) return null;
    if (isFailedStatus(market.status)) {
      return {
        title: "Market Failed",
        description: "This market did not reach its target value before closing.",
        icon: <AlertTriangle className="h-5 w-5 text-red-500" />,
        color: "text-red-500",
        bgColor: "bg-red-50 dark:bg-red-900/20"
      };
    } else if (isSuccessfulStatus(market.status)) {
      return {
        title: "Market Succeeded",
        description: "This market successfully reached its target value.",
        icon: <CheckCircle className="h-5 w-5 text-green-500" />,
        color: "text-green-500",
        bgColor: "bg-green-50 dark:bg-green-900/20"
      };
    } else {
      return {
        title: "Market Closed",
        description: "This market has been closed.",
        icon: <Clock className="h-5 w-5 text-yellow-500" />,
        color: "text-yellow-500",
        bgColor: "bg-yellow-50 dark:bg-yellow-900/20"
      };
    }
  };

  const handleReportMarket = () => {
    setIsReportDialogOpen(true);
  };
  
  const handleDuplicateMarket = async () => {
    if (!market || !session?.user?.id) return;
    
    setIsCreatingDuplicate(true);
    try {
      const { data, error } = await supabase.functions.invoke("duplicate-market", {
        body: {
          market_id: market.id,
          user_id: session.user.id
        }
      });
      
      if (error) throw error;
      
      if (data && typeof data === 'object' && 'new_market_id' in data) {
        toast.success("Market duplicated successfully! You can now try again.");
        navigate(`/dashboard/markets/details/${data.new_market_id}`);
      } else {
        throw new Error("Failed to duplicate market. No new market ID returned.");
      }
    } catch (err) {
      logger.error('Error duplicating market', err);
      toast.error("Failed to duplicate market. Please try again.");
    } finally {
      setIsCreatingDuplicate(false);
    }
  };
  
  const handleMoveToClosedTab = async () => {
    try {
      if (market && isSuccessfulStatus(market.status) && !market.status.startsWith('closed_')) {
        await updateMarketStatus(market.id, 'closed_successful');
        toast.success("Market has been marked as closed successful!");
      } else if (market && isFailedStatus(market.status) && !market.status.startsWith('closed_')) {
        await updateMarketStatus(market.id, 'closed_failed');
        toast.success("Market has been marked as closed failed!");
      }
      
      setIsSuccessDialogOpen(false);
      
      navigate('/dashboard/markets?tab=closed');
    } catch (error) {
      logger.error('Error moving market to closed tab:', error);
      toast.error("Failed to update market status. Please try again.");
    }
  };

  const closureInfo = getClosureStatusInfo();
  const isClosed = market ? isClosedStatus(market.status) : false;
  const isFailed = market ? isFailedStatus(market.status) : false;
  const isSuccessful = market ? isSuccessfulStatus(market.status) : false;

  return <DashboardSidebar>
      <div className="container mx-auto px-4 py-8">
        <Button variant="outline" onClick={handleGoBack} className="mb-6 group hover:bg-primary/10 transition-all">
          <ChevronLeft className="mr-2 h-4 w-4 group-hover:-translate-x-1 transition-transform" />
          Back to Markets
        </Button>

        {isLoading ? (
          <div className="flex flex-col justify-center items-center h-64 space-y-4">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
            <p className="text-foreground">Loading market details...</p>
          </div>
        ) : error ? (
          <Card>
            <CardContent className="pt-6 text-center">
              <p className="text-red-500 mb-4">{error}</p>
              <Button onClick={handleGoBack}>Return to Markets</Button>
            </CardContent>
          </Card>
        ) : market ? (
          <div className="space-y-6">
            <Card className={cn(
              isFailed ? "border-red-500 dark:border-red-700 border-2 bg-red-50/20 dark:bg-red-950/10" : 
              isSuccessful ? "border-green-500 dark:border-green-700 border-2 bg-green-50/20 dark:bg-green-950/10" : ""
            )}>
              <CardHeader className={cn(
                isFailed ? "bg-red-100/50 dark:bg-red-950/30" : 
                isSuccessful ? "bg-green-100/50 dark:bg-green-950/30" : ""
              )}>
                <div className="flex justify-between items-start">
                  <CardTitle className="text-2xl text-foreground">{market.name}</CardTitle>
                  <div className="flex items-center gap-2">
                    <Badge variant={market.market_risk_level === "low" ? "outline" : 
                        market.market_risk_level === "medium" ? "secondary" : "destructive"} 
                      className="capitalize text-foreground">
                      {market.market_risk_level} Risk
                    </Badge>
                    <Badge variant="outline" className="capitalize text-foreground">
                      {market.market_type || "Standard"}
                    </Badge>
                  </div>
                </div>
                <CardDescription className="text-foreground/80">{market.description}</CardDescription>
              </CardHeader>
              
              <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-6">
                    <div className={cn(
                      "p-4 rounded-md border",
                      isFailed ? "bg-red-100/60 dark:bg-red-950/20 border-red-300 dark:border-red-800" : 
                      isSuccessful ? "bg-green-100/60 dark:bg-green-950/20 border-green-300 dark:border-green-800" :
                      "bg-muted/50 border-border/50"
                    )}>
                      <h3 className={cn(
                        "font-semibold text-lg mb-3 text-foreground",
                        isFailed ? "text-red-600 dark:text-red-400" : 
                        isSuccessful ? "text-green-600 dark:text-green-400" : ""
                      )}>
                        {isFailed ? "Failed Market" : 
                         isSuccessful ? "Successful Market" : "Market Status"}
                      </h3>
                      
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="text-sm text-foreground/70 mb-1">Current Value</p>
                          <p className="font-medium text-lg text-foreground">{formatCurrency(market.current_value)}</p>
                        </div>
                        
                        <div>
                          <p className="text-sm text-foreground/70 mb-1">Target Value</p>
                          <p className="font-medium text-lg text-foreground">{formatCurrency(market.target_value)}</p>
                        </div>
                        
                        <div>
                          <p className="text-sm text-foreground/70 mb-1">Region</p>
                          <p className="font-medium text-foreground">{market.region || "Global"}</p>
                        </div>
                        
                        <div>
                          <p className="text-sm text-foreground/70 mb-1">Growth Rate</p>
                          <div className="flex items-center">
                            {market.growth_rate >= 0 ? (
                              <TrendingUp className="h-4 w-4 text-green-500 mr-1" />
                            ) : (
                              <TrendingDown className="h-4 w-4 text-red-500 mr-1" />
                            )}
                            <p className={`font-medium ${market.growth_rate >= 0 ? "text-green-600" : "text-red-600"}`}>
                              {market.growth_rate >= 0 ? "+" : ""}{market.growth_rate}%
                            </p>
                          </div>
                        </div>
                        
                        <div>
                          <p className="text-sm text-foreground/70 mb-1">Max Duplications</p>
                          <p className="font-medium text-foreground">{market.max_duplications || 3}</p>
                        </div>
                        
                        <div>
                          <p className="text-sm text-foreground/70 mb-1">Participants</p>
                          <div className="flex items-center">
                            <Users className="h-4 w-4 text-primary mr-1" />
                            <p className="font-medium text-foreground">{market.participant_count || market.active_participants || 0}</p>
                          </div>
                        </div>
                      </div>
                      
                      {market.close_time && (
                        <div className="mt-4 p-2 bg-background rounded border border-border/50">
                          <div className="flex items-center">
                            <Clock className="h-4 w-4 text-primary mr-2" />
                            <p className="text-sm font-medium text-foreground">
                              {isClosed ? "Closed " : "Closes "}
                              {formatDistanceToNow(new Date(market.close_time), { addSuffix: true })}
                            </p>
                          </div>
                        </div>
                      )}
                      
                      {isFailed && (
                        <div className="mt-4 p-3 bg-red-100 dark:bg-red-950/20 rounded-md border border-red-300 dark:border-red-800">
                          <div className="flex items-start">
                            <AlertTriangle className="h-5 w-5 text-red-500 mr-2 mt-0.5" />
                            <div>
                              <p className="font-medium text-red-700 dark:text-red-400">Market Failed</p>
                              <p className="text-sm text-red-600/80 dark:text-red-400/80 mt-1">
                                {closureReason || "Maximum contribution attempts reached without success"}
                              </p>
                              
                              {canDuplicate && (
                                <Button 
                                  onClick={handleDuplicateMarket} 
                                  className="mt-3 bg-primary hover:bg-primary/90"
                                  disabled={isCreatingDuplicate}
                                >
                                  {isCreatingDuplicate ? (
                                    <>
                                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                      Creating...
                                    </>
                                  ) : (
                                    <>
                                      <RefreshCw className="mr-2 h-4 w-4" />
                                      Retry with New Market
                                    </>
                                  )}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      
                      {isSuccessful && (
                        <div className="mt-4 p-3 bg-green-100 dark:bg-green-950/20 rounded-md border border-green-300 dark:border-green-800">
                          <div className="flex items-start">
                            <CheckCircle className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                            <div>
                              <p className="font-medium text-green-700 dark:text-green-400">Market Successful!</p>
                              <p className="text-sm text-green-600/80 dark:text-green-400/80 mt-1">
                                This market has successfully reached its target value.
                              </p>
                              
                              {!market.status.startsWith('closed_') && (
                                <Button 
                                  onClick={handleMoveToClosedTab} 
                                  className="mt-3 bg-green-600 hover:bg-green-700 text-white"
                                >
                                  Move to Closed Tab
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    
                    {market.isParticipating && (
                      <div className="p-4 bg-muted/50 rounded-md border border-border/50">
                        <h3 className="font-semibold text-lg mb-3">Your Participation</h3>
                        
                        <div className="space-y-3">
                          {recentContribution && (
                            <div className="p-3 bg-background rounded-md border border-border/30">
                              <h4 className="text-sm font-medium text-muted-foreground mb-2">Recent Contribution</h4>
                              <div className="grid grid-cols-2 gap-2 text-sm">
                                <div>Amount:</div>
                                <div className="font-medium">{formatCurrency(recentContribution.contribution_amount || 0)}</div>
                                
                                <div>Status:</div>
                                <div className="font-medium">
                                  <Badge variant={recentContribution.status === 'success' ? 'success' : 
                                      recentContribution.status === 'failed' ? 'destructive' : 'outline'}>
                                    {recentContribution.status}
                                  </Badge>
                                </div>
                                
                                <div>Date:</div>
                                <div className="font-medium">
                                  {new Date(recentContribution.created_at).toLocaleDateString()}
                                </div>
                              </div>
                            </div>
                          )}
                          
                          <div className={cn(
                            "p-3 rounded-md border",
                            isFailed && remainingAttempts <= 0 
                              ? "bg-red-100 dark:bg-red-950/30 border-red-300 dark:border-red-800" 
                              : "bg-background border-border/30"
                          )}>
                            <h4 className="text-sm font-medium text-muted-foreground mb-2">Contribution Attempts</h4>
                            <div className="flex justify-between items-center">
                              <span>Remaining:</span>
                              <Badge variant={remainingAttempts > 0 ? "outline" : "destructive"}>
                                {remainingAttempts} of {maxAttempts}
                              </Badge>
                            </div>
                            
                            {isFailed && remainingAttempts <= 0 && canDuplicate && (
                              <Button 
                                onClick={handleDuplicateMarket} 
                                className="mt-3 w-full"
                                disabled={isCreatingDuplicate}
                              >
                                {isCreatingDuplicate ? (
                                  <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Creating...
                                  </>
                                ) : (
                                  <>
                                    <RefreshCw className="mr-2 h-4 w-4" />
                                    Retry with New Market
                                  </>
                                )}
                              </Button>
                            )}
                          </div>
                        </div>
                        
                        <div className="mt-4">
                          <Button 
                            onClick={() => setIsContributionDialogOpen(true)} 
                            className={cn(
                              "w-full",
                              isFailed && remainingAttempts <= 0 ? "bg-red-500 hover:bg-red-600" : ""
                            )}
                            disabled={!canSubmitContribution}
                          >
                            {isFailed && remainingAttempts <= 0 
                              ? canDuplicate 
                                ? "Create New Market" 
                                : "Market Failed" 
                              : remainingAttempts <= 0 
                                ? "No Attempts Remaining" 
                                : "Submit Contribution"}
                          </Button>
                          {!canSubmitContribution && !isFailed && remainingAttempts > 0 && (
                            <p className="text-xs text-muted-foreground mt-2 text-center">
                              Cannot contribute to this market in its current state
                            </p>
                          )}
                          {remainingAttempts <= 0 && !isFailed && (
                            <p className="text-xs text-muted-foreground mt-2 text-center">
                              You have used all available contribution attempts
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    
                    <MarketRiskInfo market={market} />
                  </div>
                  
                  <div className="space-y-4">
                    <Card>
                      <CardHeader className="pb-2">
                        <CardTitle className="text-lg text-foreground">Contribution History</CardTitle>
                      </CardHeader>
                      <CardContent>
                        {loadingContributions ? (
                          <div className="flex justify-center items-center py-4">
                            <Loader2 className="h-6 w-6 animate-spin text-primary" />
                          </div>
                        ) : contributions && contributions.length > 0 ? (
                          <ContributionHistoryTable 
                            contributions={contributions}
                            isLoading={loadingContributions}
                          />
                        ) : (
                          <p className="text-center text-foreground/80 py-4">
                            No contribution history found
                          </p>
                        )}
                      </CardContent>
                    </Card>
                    
                    {isClosed && closureInfo && (
                      <Card className={cn(
                        isFailed ? "border-red-500" : 
                        isSuccessful ? "border-green-500" : "border-yellow-500"
                      )}>
                        <CardHeader className={cn(
                          "pb-2",
                          closureInfo.bgColor
                        )}>
                          <div className="flex items-center">
                            {closureInfo.icon}
                            <CardTitle className={`text-lg ml-2 ${closureInfo.color}`}>
                              {closureInfo.title}
                            </CardTitle>
                          </div>
                        </CardHeader>
                        <CardContent className="pt-4">
                          <p className="text-foreground/80 mb-2">
                            {closureInfo.description}
                          </p>
                          {closureReason && (
                            <div className="p-3 bg-muted rounded-md mt-2">
                              <h4 className="text-sm font-medium text-foreground mb-1">Closure Reason:</h4>
                              <p className="text-sm text-foreground/90">{closureReason}</p>
                            </div>
                          )}
                          
                          {!market.status.startsWith('closed_') && (
                            <Button 
                              onClick={handleMoveToClosedTab} 
                              className={cn(
                                "mt-4 w-full",
                                isFailed ? "bg-red-500 hover:bg-red-600 text-white" : 
                                isSuccessful ? "bg-green-600 hover:bg-green-700 text-white" : 
                                "bg-amber-500 hover:bg-amber-600 text-white"
                              )}
                            >
                              Move to Closed Tab
                            </Button>
                          )}
                        </CardContent>
                      </Card>
                    )}
                    
                    <div className="flex justify-end mt-4">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={handleReportMarket}
                        className="text-foreground/80"
                      >
                        <Flag className="h-4 w-4 mr-2" />
                        Report Market
                      </Button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
            
            {isSuccessDialogOpen && isSuccessful && (
              <Dialog open={isSuccessDialogOpen} onOpenChange={setIsSuccessDialogOpen}>
                <DialogContent className="sm:max-w-md border-green-500 dark:border-green-700">
                  <DialogHeader>
                    <DialogTitle className="flex items-center text-green-700 dark:text-green-500">
                      <CheckCircle className="w-6 h-6 mr-2" />
                      Market Success
                    </DialogTitle>
                    <DialogDescription className="text-foreground/80">
                      This market has successfully reached its target!
                    </DialogDescription>
                  </DialogHeader>
                  
                  <div className="p-4 bg-green-50 dark:bg-green-950/20 rounded-md">
                    <p className="text-sm text-green-800 dark:text-green-200">
                      Congratulations! The market "{market.name}" has successfully reached its target value of {formatCurrency(market.target_value)}.
                    </p>
                  </div>
                  
                  <DialogFooter className="sm:justify-end">
                    <Button
                      onClick={handleMoveToClosedTab}
                      className="w-full sm:w-auto bg-green-600 hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-800 text-white"
                    >
                      Okay
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
            
            {isContributionDialogOpen && (
              <ContributionDialog
                market={market}
                isOpen={isContributionDialogOpen}
                onOpenChange={setIsContributionDialogOpen}
                onContributionSubmitted={handleContributionSubmitted}
                onDuplicateMarket={handleDuplicateMarket}
                canDuplicate={canDuplicate}
                remainingAttempts={remainingAttempts}
              />
            )}
            
            {isReportDialogOpen && (
              <ReportMarketDialog
                market={market}
                isOpen={isReportDialogOpen}
                onOpenChange={setIsReportDialogOpen}
              />
            )}
          </div>
        ) : (
          <Card>
            <CardContent className="pt-6 text-center">
              <p className="text-foreground">Market not found.</p>
              <Button onClick={handleGoBack} className="mt-4">
                Return to Markets
              </Button>
            </CardContent>
          </Card>
        )}
      </div>
    </DashboardSidebar>;
};

export default MarketDetails;
