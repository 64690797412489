
import { Button } from "@/components/ui/button";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

interface RegistrationNavigationProps {
  currentStep: number;
  isLoading: boolean;
  onPrevStep: () => void;
  onNextStep: () => void;
}

export const RegistrationNavigation = ({
  currentStep,
  isLoading,
  onPrevStep,
  onNextStep,
}: RegistrationNavigationProps) => {
  return (
    <>
      <div className="flex justify-between space-x-4">
        {currentStep > 1 && (
          <Button
            type="button"
            variant="outline"
            onClick={onPrevStep}
            className="w-1/2"
          >
            <ArrowLeft className="mr-2 h-5 w-5" /> Previous
          </Button>
        )}
        
        {currentStep < 3 ? (
          <Button
            type="button"
            onClick={onNextStep}
            className={`${currentStep === 1 ? "w-full" : "w-1/2"} bg-primary hover:bg-primary/90`}
          >
            Next <ArrowRight className="ml-2 h-5 w-5" />
          </Button>
        ) : (
          <Button
            type="submit"
            className="w-full bg-primary hover:bg-primary/90"
            disabled={isLoading}
          >
            {isLoading ? "Creating account..." : "Complete Registration"}
          </Button>
        )}
      </div>

      <div className="text-center text-sm">
        <span className="text-muted-foreground">Already have an account? </span>
        <Link to="/signin" className="text-primary hover:text-primary/80">
          Sign in here
        </Link>
      </div>
    </>
  );
};
