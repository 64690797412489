import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { User, Mail, Phone } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface PersonalInfoStepProps {
  formData: {
    fullName: string;
    email: string;
    phone: string;
  };
  errors: {
    fullName?: string;
    email?: string;
    phone?: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PersonalInfoStep = ({ formData, errors, onChange }: PersonalInfoStepProps) => {
  return (
    <div className="space-y-4 animate-fade-in">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <Label htmlFor="fullName">Full Name</Label>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-sm text-muted-foreground">
                ?
              </TooltipTrigger>
              <TooltipContent>
                <p>Enter your legal full name as it appears on your ID</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="relative">
          <User className="absolute left-3 top-3 h-5 w-5 text-muted-foreground" />
          <Input
            id="fullName"
            name="fullName"
            type="text"
            placeholder="Enter your full name"
            value={formData.fullName}
            onChange={onChange}
            className={`pl-10 ${errors.fullName ? 'border-red-500' : ''}`}
            required
          />
        </div>
        {errors.fullName && (
          <p className="text-sm text-red-500 mt-1">{errors.fullName}</p>
        )}
        <p className="text-sm text-muted-foreground">
          This will be used for official communications
        </p>
      </div>

      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <Label htmlFor="email">Email Address</Label>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-sm text-muted-foreground">
                ?
              </TooltipTrigger>
              <TooltipContent>
                <p>Use a valid email address you have access to</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="relative">
          <Mail className="absolute left-3 top-3 h-5 w-5 text-muted-foreground" />
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={onChange}
            className={`pl-10 ${errors.email ? 'border-red-500' : ''}`}
            required
          />
        </div>
        {errors.email && (
          <p className="text-sm text-red-500 mt-1">{errors.email}</p>
        )}
        <p className="text-sm text-muted-foreground">
          We'll send verification and important updates here
        </p>
      </div>

      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <Label htmlFor="phone">Phone Number</Label>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-sm text-muted-foreground">
                ?
              </TooltipTrigger>
              <TooltipContent>
                <p>Include country code (e.g., +1 for US)</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="relative">
          <Phone className="absolute left-3 top-3 h-5 w-5 text-muted-foreground" />
          <Input
            id="phone"
            name="phone"
            type="tel"
            placeholder="Enter your phone number"
            value={formData.phone}
            onChange={onChange}
            className={`pl-10 ${errors.phone ? 'border-red-500' : ''}`}
            required
          />
        </div>
        {errors.phone && (
          <p className="text-sm text-red-500 mt-1">{errors.phone}</p>
        )}
        <p className="text-sm text-muted-foreground">
          For account security and notifications
        </p>
      </div>
    </div>
  );
};