
import { Market } from "@/types/market";
import { logger } from "@/utils/logger";

/**
 * Checks for data consistency issues between isParticipating flag and market category
 */
export const checkDataConsistency = (markets: Market[], tabName: string): Market[] => {
  if (!markets?.length) return markets;
  
  // For joined tab, we should have isParticipating = true
  if (tabName === 'joined') {
    const originalParticipatingCount = markets.filter(m => m.isParticipating === true).length;
    const displayedCount = markets.filter(m => m.category === 'joined').length;
    
    if (originalParticipatingCount > 0 && displayedCount === 0) {
      logger.warn('Data inconsistency detected: Joined tab shows no markets but we have participating markets', {
        originalParticipatingCount,
        tabName
      });
    }
  }
  
  // For closed tab, check if we have any closed markets that are also participating
  if (tabName === 'closed') {
    const closedStatuses = [
      'closed', 'completed', 'failed', 'success', 
      'closed_successful', 'closed_failed', 'failed_final'
    ];
    
    const participatingClosedMarkets = markets.filter(m => 
      m.isParticipating === true && 
      closedStatuses.includes(m.status)
    );
    
    const categorizedClosedMarkets = markets.filter(m => m.category === 'closed');
    
    if (participatingClosedMarkets.length > 0 && categorizedClosedMarkets.length === 0) {
      logger.warn('Data inconsistency detected: Closed tab shows no markets but we have participating closed markets', {
        participatingClosedCount: participatingClosedMarkets.length,
        tabName
      });
    }
  }

  return markets;
};

/**
 * Ensures that market participation is consistent
 * Expects just markets and userId parameters
 */
export const ensureParticipationConsistency = (
  markets: Market[], 
  userId?: string
): Market[] => {
  if (!markets?.length || !userId) return markets;
  
  // Log current participation state
  const participatingBefore = markets.filter(m => m.isParticipating === true).length;
  
  // Apply fixes to market data
  const fixedMarkets = markets.map(market => {
    const closedStatuses = [
      'closed', 'completed', 'failed', 'success', 
      'closed_successful', 'closed_failed', 'failed_final'
    ];
    
    // Fix category based on participation and status
    let category = market.category;
    
    if (market.isParticipating === true) {
      if (closedStatuses.includes(market.status)) {
        category = 'closed';
      } else {
        category = 'joined';
      }
    } else if (closedStatuses.includes(market.status)) {
      category = 'closed';
    } else if (market.status === 'scheduled') {
      // IMPORTANT FIX: Ensure scheduled markets always go to upcoming tab
      category = 'upcoming'; 
    } else if (market.start_date && new Date(market.start_date) > new Date()) {
      category = 'upcoming';
    } else {
      category = 'available';
    }
    
    return {
      ...market,
      category
    };
  });
  
  // Log after participation state
  const participatingAfter = fixedMarkets.filter(m => m.isParticipating === true).length;
  const joinedCategoryCount = fixedMarkets.filter(m => m.category === 'joined').length;
  const closedCategoryCount = fixedMarkets.filter(m => m.category === 'closed').length;
  const upcomingCategoryCount = fixedMarkets.filter(m => m.category === 'upcoming').length;
  
  logger.info('Market participation consistency check:', {
    totalMarkets: markets.length,
    participatingBefore,
    participatingAfter,
    joinedCategoryCount,
    closedCategoryCount,
    upcomingCategoryCount,
    scheduledMarkets: markets.filter(m => m.status === 'scheduled').length,
  });
  
  return fixedMarkets;
};
