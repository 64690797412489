
import { Button } from "@/components/ui/button";
import { MarketTargetInput } from "./MarketTargetInput";
import { MarketAdjustmentInput } from "./MarketAdjustmentInput";
import { MaxParticipationsInput } from "./MaxParticipationsInput";
import { MarketApprovalToggle } from "./MarketApprovalToggle";
import { MarketStatusControls } from "./MarketStatusControls";
import { DeleteMarketDialog } from "./DeleteMarketDialog";
import { Market } from "@/types/market";
import { Loader2, AlertTriangle, Save, RefreshCw, CheckCircle } from "lucide-react"; // Changed ArrowPathIcon to RefreshCw
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useState, useCallback, useEffect } from "react";
import { MarketTypeSelector } from "./MarketTypeSelector";
import { MarketRiskSelector } from "./MarketRiskSelector";
import { ExpectedROIInput } from "./ExpectedROIInput";
import { MaxDuplicationsInput } from "./MaxDuplicationsInput";
import { Separator } from "@/components/ui/separator";
import { useMarketConfiguration } from "@/hooks/admin/markets/use-market-configuration";
import { logger } from "@/utils/logger";
import { DescriptionField } from "./DescriptionField";
import { Card, CardContent } from "@/components/ui/card";

export interface ConfigurationFormProps {
  market: Market;
  onUpdate: (updatedMarket?: Market) => void;
}

export const ConfigurationForm = ({ market, onUpdate }: ConfigurationFormProps) => {
  // Use the market configuration hook to get state and handlers
  const {
    loading,
    dailyTarget,
    adjustmentFactor,
    maxParticipationsPerUser,
    requiresApproval,
    marketType,
    marketRiskLevel,
    expectedROI,
    maxDuplications,
    description,
    sequencePosition,
    setDailyTarget,
    setAdjustmentFactor,
    setMaxParticipationsPerUser,
    setRequiresApproval,
    setMarketType,
    setMarketRiskLevel,
    setExpectedROI,
    setMaxDuplications,
    setDescription,
    setSequencePosition,
    saveError,
    isSaving,
    isDeleting,
    handleUpdateConfiguration,
    handleStatusUpdate,
    handleDelete
  } = useMarketConfiguration(market, onUpdate);

  const [saveSuccess, setSaveSuccess] = useState(false);

  // Log current values when component mounts or market changes
  useEffect(() => {
    logger.info('ConfigurationForm initialized with market values:', {
      marketId: market.id,
      dailyContributionTarget: market.daily_contribution_target,
      adjustmentFactor: market.adjustment_factor,
      maxParticipationsPerUser: market.max_participations_per_user,
      requiresApproval: market.requires_approval,
      marketType: market.market_type,
      marketRiskLevel: market.market_risk_level,
      expectedROI: market.expected_roi,
      maxDuplications: market.max_duplications,
      description
    });
  }, [market, description]);

  const handleSave = useCallback(async (e: React.MouseEvent) => {
    // Completely prevent the event from propagating
    e.preventDefault();
    e.stopPropagation();
    
    if (isSaving || loading) return;
    
    logger.info('Saving market configuration with values:', {
      dailyTarget,
      adjustmentFactor,
      maxParticipationsPerUser,
      requiresApproval,
      marketType,
      marketRiskLevel,
      expectedROI,
      maxDuplications,
      description,
      sequencePosition
    });
    
    try {
      await handleUpdateConfiguration();
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (error) {
      console.error("Error saving configuration:", error);
    }
  }, [isSaving, loading, handleUpdateConfiguration, dailyTarget, adjustmentFactor, maxParticipationsPerUser, requiresApproval, marketType, marketRiskLevel, expectedROI, maxDuplications, description, sequencePosition]);

  return (
    <div className="grid gap-6" onClick={(e) => e.stopPropagation()}>
      <Card className="border-border/50 overflow-hidden">
        <CardContent className="p-6">
          <DescriptionField 
            description={description || ''}
            setDescription={setDescription}
            sequencePosition={sequencePosition}
            setSequencePosition={setSequencePosition}
            disabled={loading || isSaving}
          />
        </CardContent>
      </Card>

      <Separator />

      <div className="space-y-4">
        <h3 className="text-lg font-medium text-primary/90">Market Classification</h3>
        <div className="grid gap-4 sm:grid-cols-2">
          <MarketTypeSelector 
            value={marketType}
            onChange={setMarketType}
          />
          <MarketRiskSelector 
            value={marketRiskLevel}
            onChange={setMarketRiskLevel}
          />
        </div>
      </div>

      <Separator />

      <div className="space-y-4">
        <h3 className="text-lg font-medium text-primary/90">Financial Configuration</h3>
        <div className="grid gap-4 sm:grid-cols-2">
          <MarketTargetInput 
            value={dailyTarget}
            onChange={setDailyTarget}
          />
          <MarketAdjustmentInput 
            value={adjustmentFactor}
            onChange={setAdjustmentFactor}
          />
          <ExpectedROIInput
            value={expectedROI}
            onChange={setExpectedROI}
          />
        </div>
      </div>

      <Separator />

      <div className="space-y-4">
        <h3 className="text-lg font-medium text-primary/90">Participation Rules</h3>
        <div className="grid gap-4 sm:grid-cols-2">
          <MaxParticipationsInput
            value={maxParticipationsPerUser}
            onChange={setMaxParticipationsPerUser}
          />
          <MaxDuplicationsInput
            value={maxDuplications}
            onChange={setMaxDuplications}
          />
        </div>
        <MarketApprovalToggle 
          checked={requiresApproval}
          onCheckedChange={setRequiresApproval}
        />
      </div>

      <Separator />

      <div className="border-none">
        <MarketStatusControls 
          market={market}
          loading={loading}
          onStatusUpdate={handleStatusUpdate}
        />
      </div>

      {saveError && (
        <Alert variant="destructive">
          <AlertTriangle className="h-4 w-4" />
          <AlertDescription>{saveError}</AlertDescription>
        </Alert>
      )}

      {saveSuccess && (
        <Alert variant="default" className="bg-green-50 text-green-800 border-green-200">
          <CheckCircle className="h-4 w-4 text-green-600" />
          <AlertDescription>Configuration saved successfully!</AlertDescription>
        </Alert>
      )}

      <div className="flex gap-2 pt-4">
        <Button
          className="flex-1 bg-primary hover:bg-primary/90"
          onClick={handleSave}
          disabled={loading || isSaving || isDeleting}
          type="button"
        >
          {isSaving ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <Save className="mr-2 h-4 w-4" />
              Save Configuration
            </>
          )}
        </Button>

        <DeleteMarketDialog 
          marketName={market.name}
          loading={loading || isSaving}
          onDelete={handleDelete}
          setIsDeleting={() => {}} // This is now handled internally in the hook
        />
      </div>
    </div>
  );
};

