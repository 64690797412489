
import React from "react";
import { Market } from "@/types/market";
import { FilterableHistoryTable } from "../history/FilterableHistoryTable";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Button } from "@/components/ui/button";
import { AlertCircle } from "lucide-react";

interface HistoryTabProps {
  market: Market;
}

export const HistoryTab = ({ market }: HistoryTabProps) => {
  return (
    <ErrorBoundary
      fallback={
        <div className="p-6 bg-red-50 border border-red-200 rounded-md text-center">
          <AlertCircle className="h-10 w-10 text-red-500 mx-auto mb-2" />
          <h3 className="text-lg font-medium text-red-800 mb-2">Failed to load market history</h3>
          <p className="text-red-600 mb-4">There was an error loading the history for this market.</p>
          <Button variant="outline" onClick={() => window.location.reload()}>
            Try again
          </Button>
        </div>
      }
    >
      <FilterableHistoryTable 
        market={market}
        title={`History for ${market.name}`}
        description={`View all activity for this market (ID: ${market.id})`}
      />
    </ErrorBoundary>
  );
};
