
import { useQueryClient } from "@tanstack/react-query";
import { logger } from "@/utils/logger";

/**
 * Hook that provides functions to invalidate market queries
 */
export const useQueryInvalidation = () => {
  const queryClient = useQueryClient();

  /**
   * Invalidate all market-related queries
   */
  const invalidateAllMarketQueries = async () => {
    logger.info('Invalidating all market queries');
    await queryClient.invalidateQueries({ queryKey: ['markets'] });
  };

  /**
   * Invalidate queries for a specific market
   */
  const invalidateMarketQueries = async (marketId?: string) => {
    if (marketId) {
      logger.info(`Invalidating queries for market: ${marketId}`);
      await queryClient.invalidateQueries({ queryKey: ['market', marketId] });
    }
    
    // Always invalidate all markets list queries
    await invalidateAllMarketQueries();
  };

  return {
    invalidateAllMarketQueries,
    invalidateMarketQueries
  };
};
