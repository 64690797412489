
import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { RefreshCw, CircleDollarSign, Flag, Plus } from "lucide-react";
import ScrollSection from "@/components/ScrollSection";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { GoalProgressCard } from "@/components/goals/GoalProgressCard";
import { HistoryComparisonCard } from "@/components/goals/HistoryComparisonCard";
import { StreakTrackerCard } from "@/components/goals/StreakTrackerCard";
import { useBankingGoals } from "@/hooks/goals/use-banking-goals";
import { Input } from "@/components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogClose } from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";

const Goals = () => {
  const { session } = useAuth();
  const userId = session?.user?.id;
  const { toast } = useToast();
  const { 
    monthlyTarget, 
    currentProgress, 
    currentStreak, 
    historicalData, 
    isLoading 
  } = useBankingGoals();
  
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newTarget, setNewTarget] = useState(monthlyTarget.toString());
  const [isSaving, setIsSaving] = useState(false);

  const handleSetNewTarget = async () => {
    if (!userId) return;
    
    const targetAmount = parseFloat(newTarget);
    if (isNaN(targetAmount) || targetAmount <= 0) {
      toast({
        title: "Invalid target amount",
        description: "Please enter a valid positive number",
        variant: "destructive",
      });
      return;
    }
    
    try {
      setIsSaving(true);
      
      const { error } = await supabase
        .from('profiles')
        .update({ 
          monthly_banking_target: targetAmount 
        })
        .eq('id', userId);
      
      if (error) throw error;
      
      toast({
        title: "Goal updated",
        description: "Your monthly banking target has been updated",
      });
      
      setIsDialogOpen(false);
      // Force a refresh of the page to update the data
      window.location.reload();
      
    } catch (error) {
      console.error("Error setting new target:", error);
      toast({
        title: "Failed to update goal",
        description: "There was an error updating your monthly target",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <DashboardSidebar>
      <div className="flex-1 w-full">
        <header className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
          <div>
            <h1 className="text-3xl font-bold text-primary mb-2">Banking Goals</h1>
            <p className="text-muted-foreground dark:text-zinc-400 text-zinc-600">Track your monthly banking goals and progress</p>
          </div>
          <div className="flex gap-4">
            <Button variant="outline" className="gap-2 border-primary/20 hover:border-primary/40 dark:text-zinc-300 text-zinc-700" onClick={() => setIsDialogOpen(true)}>
              <Flag className="h-4 w-4 text-primary" />
              Set Goal
            </Button>
            <Button variant="outline" className="gap-2 border-primary/20 hover:border-primary/40 dark:text-zinc-300 text-zinc-700" onClick={() => window.location.reload()}>
              <RefreshCw className="h-4 w-4 text-primary" />
              Refresh
            </Button>
          </div>
        </header>

        <ScrollSection className="w-full">
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            <GoalProgressCard 
              target={monthlyTarget} 
              current={currentProgress} 
              isLoading={isLoading} 
            />
            
            <HistoryComparisonCard 
              monthlyData={historicalData}
              isLoading={isLoading}
            />
            
            <StreakTrackerCard 
              currentStreak={currentStreak}
              isLoading={isLoading}
            />
          </div>

          <Card className="backdrop-blur-sm mt-6 dark:bg-card/50 bg-white/90">
            <CardHeader>
              <CardTitle className="text-xl font-semibold flex items-center dark:text-zinc-100 text-zinc-800">
                <CircleDollarSign className="mr-2 h-5 w-5 text-primary" />
                Banking Tips
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="p-4 bg-primary/5 rounded-md border border-primary/10">
                  <h3 className="font-medium mb-1 dark:text-zinc-100 text-zinc-800">Consistent Daily Banking</h3>
                  <p className="text-sm dark:text-zinc-400 text-zinc-600">
                    Banking small amounts daily builds better habits than large irregular deposits.
                  </p>
                </div>
                
                <div className="p-4 bg-primary/5 rounded-md border border-primary/10">
                  <h3 className="font-medium mb-1 dark:text-zinc-100 text-zinc-800">Track Your Progress</h3>
                  <p className="text-sm dark:text-zinc-400 text-zinc-600">
                    Review your banking history regularly to identify patterns and opportunities for improvement.
                  </p>
                </div>
                
                <div className="p-4 bg-primary/5 rounded-md border border-primary/10">
                  <h3 className="font-medium mb-1 dark:text-zinc-100 text-zinc-800">Adjust Your Goals</h3>
                  <p className="text-sm dark:text-zinc-400 text-zinc-600">
                    Start with achievable targets and gradually increase them as your banking habits improve.
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </ScrollSection>
        
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent className="dark:bg-card bg-white">
            <DialogHeader>
              <DialogTitle className="dark:text-zinc-100 text-zinc-800">Set Monthly Banking Target</DialogTitle>
            </DialogHeader>
            <div className="p-4 space-y-4">
              <div className="space-y-2">
                <label htmlFor="monthly-target" className="text-sm font-medium dark:text-zinc-300 text-zinc-700">
                  Target Amount (ZAR)
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 transform -translate-y-1/2 dark:text-zinc-400 text-zinc-600">R</span>
                  <Input 
                    id="monthly-target"
                    type="number"
                    className="pl-8 dark:border-zinc-700 border-zinc-300"
                    value={newTarget}
                    onChange={(e) => setNewTarget(e.target.value)}
                    placeholder="5000"
                  />
                </div>
                <p className="text-xs dark:text-zinc-400 text-zinc-600">
                  This is the amount you aim to bank each month
                </p>
              </div>
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button variant="outline" className="dark:text-zinc-300 text-zinc-700 dark:border-zinc-700 border-zinc-300">Cancel</Button>
              </DialogClose>
              <Button 
                onClick={handleSetNewTarget}
                disabled={isSaving}
                className="bg-primary hover:bg-primary/90 text-primary-foreground"
              >
                {isSaving ? "Saving..." : "Save Target"}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </DashboardSidebar>
  );
};

export default Goals;
