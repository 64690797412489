
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface ExpectedROIInputProps {
  value: number;
  onChange: (value: number) => void;
}

export const ExpectedROIInput = ({ value, onChange }: ExpectedROIInputProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="expectedROI">Expected ROI (%)</Label>
      <Input
        id="expectedROI"
        type="number"
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
        placeholder="Enter expected ROI percentage"
        min="0"
        step="0.1"
      />
    </div>
  );
};
