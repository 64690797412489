
import { useState, useMemo } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { CheckCircle, XCircle, TrendingUp, TrendingDown, Search, BarChart3 } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { MarketPerformanceDetails } from "./MarketPerformanceDetails";
import { formatCurrency } from "@/utils/formatters";

interface Market {
  id: string;
  name: string;
  current_value: number;
  target_value: number;
  growth_rate: number;
  status: string;
  region?: string;
  participant_count?: number; // Added for compatibility
  active_participants?: number;
}

interface MarketsOverviewProps {
  markets: Market[];
}

const MarketsOverview = ({ markets }: MarketsOverviewProps) => {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  
  // Filter state
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  
  // Market details dialog state
  const [selectedMarket, setSelectedMarket] = useState<Market | null>(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  
  // Apply filters and sort
  const filteredMarkets = useMemo(() => {
    return markets
      .filter(market => {
        // Filter by search query (case insensitive)
        const matchesSearch = market.name.toLowerCase().includes(searchQuery.toLowerCase());
        
        // Filter by status
        const matchesStatus = statusFilter === 'all' ? true : market.status === statusFilter;
        
        return matchesSearch && matchesStatus;
      })
      .sort((a, b) => {
        // Handle different sort fields
        if (sortBy === 'name') {
          return sortDirection === 'asc' 
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        } else if (sortBy === 'current_value') {
          return sortDirection === 'asc' 
            ? a.current_value - b.current_value
            : b.current_value - a.current_value;
        } else if (sortBy === 'target_value') {
          return sortDirection === 'asc' 
            ? a.target_value - b.target_value
            : b.target_value - a.target_value;
        } else if (sortBy === 'growth_rate') {
          return sortDirection === 'asc' 
            ? a.growth_rate - b.growth_rate
            : b.growth_rate - a.growth_rate;
        }
        return 0;
      });
  }, [markets, searchQuery, statusFilter, sortBy, sortDirection]);

  // Pagination calculations
  const totalPages = Math.ceil(filteredMarkets.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedMarkets = filteredMarkets.slice(startIndex, startIndex + itemsPerPage);
  
  // Toggle sort direction
  const handleSortChange = (field: string) => {
    if (sortBy === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  };

  // Handle row click to open details
  const handleRowClick = (market: Market) => {
    setSelectedMarket(market);
    setIsDetailsOpen(true);
  };

  return (
    <Card className="hover:shadow-lg transition-all duration-300 border-primary/20">
      <CardHeader className="pb-3">
        <CardTitle className="text-xl font-semibold flex items-center gap-2">
          <TrendingUp className="h-5 w-5 text-primary" />
          Market Overview
        </CardTitle>
        <CardDescription>
          Monitor and adjust market targets
        </CardDescription>
      </CardHeader>
      <CardContent>
        {/* Filtering controls */}
        <div className="flex flex-col md:flex-row gap-4 mb-4">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search markets..."
                className="pl-8"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full md:w-48">
            <Select value={statusFilter} onValueChange={setStatusFilter}>
              <SelectTrigger>
                <SelectValue placeholder="Filter by status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Statuses</SelectItem>
                <SelectItem value="active">Active</SelectItem>
                <SelectItem value="completed">Completed</SelectItem>
                <SelectItem value="failed">Failed</SelectItem>
                <SelectItem value="pending">Pending</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="w-full md:w-48">
            <Select value={itemsPerPage.toString()} onValueChange={(value) => {
              setItemsPerPage(Number(value));
              setCurrentPage(1); // Reset to first page when changing items per page
            }}>
              <SelectTrigger>
                <SelectValue placeholder="Items per page" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="5">5 per page</SelectItem>
                <SelectItem value="10">10 per page</SelectItem>
                <SelectItem value="15">15 per page</SelectItem>
                <SelectItem value="20">20 per page</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Markets table */}
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="cursor-pointer" onClick={() => handleSortChange('name')}>
                  Market {sortBy === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
                </TableHead>
                <TableHead className="cursor-pointer" onClick={() => handleSortChange('current_value')}>
                  Current Value {sortBy === 'current_value' && (sortDirection === 'asc' ? '↑' : '↓')}
                </TableHead>
                <TableHead className="cursor-pointer" onClick={() => handleSortChange('target_value')}>
                  Target Value {sortBy === 'target_value' && (sortDirection === 'asc' ? '↑' : '↓')}
                </TableHead>
                <TableHead className="cursor-pointer" onClick={() => handleSortChange('growth_rate')}>
                  Growth Rate {sortBy === 'growth_rate' && (sortDirection === 'asc' ? '↑' : '↓')}
                </TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {paginatedMarkets.length > 0 ? (
                paginatedMarkets.map((market) => (
                  <TableRow 
                    key={market.id} 
                    className="hover:bg-muted/5 cursor-pointer"
                    onClick={() => handleRowClick(market)}
                  >
                    <TableCell className="font-medium">{market.name}</TableCell>
                    <TableCell>
                      {formatCurrency(market.current_value)}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(market.target_value)}
                    </TableCell>
                    <TableCell>
                      <span className={`inline-flex items-center gap-1 ${
                        market.growth_rate >= 0 ? 'text-green-500' : 'text-red-500'
                      }`}>
                        {market.growth_rate >= 0 ? (
                          <TrendingUp className="h-4 w-4" />
                        ) : (
                          <TrendingDown className="h-4 w-4" />
                        )}
                        {market.growth_rate >= 0 ? '+' : ''}{market.growth_rate}%
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={`inline-flex items-center gap-1 ${
                        market.status === 'active' ? 'text-green-500' :
                        market.status === 'completed' ? 'text-blue-500' :
                        market.status === 'pending' ? 'text-yellow-500' :
                        'text-red-500'
                      }`}>
                        {market.status === 'active' && <CheckCircle className="h-4 w-4" />}
                        {market.status === 'completed' && <CheckCircle className="h-4 w-4" />}
                        {market.status === 'pending' && <TrendingDown className="h-4 w-4" />}
                        {market.status === 'failed' && <XCircle className="h-4 w-4" />}
                        <span className="capitalize">{market.status}</span>
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="h-24 text-center">
                    No markets found matching your filters.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {/* Pagination controls */}
        {filteredMarkets.length > 0 && (
          <div className="flex items-center justify-between space-x-2 py-4">
            <div className="text-sm text-muted-foreground">
              Showing {startIndex + 1}-{Math.min(startIndex + itemsPerPage, filteredMarkets.length)} of {filteredMarkets.length} markets
            </div>
            <div className="flex space-x-2">
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setCurrentPage(1)}
                disabled={currentPage <= 1}
              >
                First
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
                disabled={currentPage <= 1}
              >
                Previous
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
                disabled={currentPage >= totalPages}
              >
                Next
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage >= totalPages}
              >
                Last
              </Button>
            </div>
          </div>
        )}

        {/* Market performance details dialog */}
        <Dialog open={isDetailsOpen} onOpenChange={setIsDetailsOpen}>
          <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle className="flex items-center gap-2">
                <BarChart3 className="h-5 w-5 text-primary" />
                Market Performance Details
              </DialogTitle>
            </DialogHeader>
            {selectedMarket && <MarketPerformanceDetails market={selectedMarket} />}
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default MarketsOverview;
