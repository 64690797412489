import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DashboardSidebar } from "@/components/DashboardSidebar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/lib/supabase";
import {
  Settings as SettingsIcon,
  Mail,
  Shield,
  Bell,
  User,
  Phone,
  Upload,
  Trash2,
  Moon,
  Sun,
  Palette,
} from "lucide-react";
import { ProfileAvatar } from "@/components/profile/ProfileAvatar";
import { Profile } from "@/types/profile";
import ThemeToggle from "@/components/navigation/ThemeToggle";

const Settings = () => {
  const { session } = useAuth();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const userId = session?.user?.id;
  const [isUploading, setIsUploading] = useState(false);

  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    email: session?.user?.email || "",
    phone: "",
  });

  const { data: profile, isLoading: isLoadingProfile } = useQuery({
    queryKey: ['profile', userId],
    queryFn: async () => {
      if (!userId) throw new Error("No user ID found");
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .maybeSingle();
      
      if (error) {
        console.error('Profile fetch error:', error);
        throw error;
      }
      
      if (!data) {
        const { data: newProfile, error: createError } = await supabase
          .from('profiles')
          .insert({ id: userId })
          .select()
          .single();
          
        if (createError) throw createError;
        return newProfile as Profile;
      }
      
      return data as Profile;
    },
    enabled: !!userId,
    retry: 1,
  });

  const { data: userSettings, isLoading: isLoadingSettings } = useQuery({
    queryKey: ['userSettings', userId],
    queryFn: async () => {
      if (!userId) throw new Error("No user ID found");
      
      const { data, error } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', userId)
        .maybeSingle();
      
      if (error) {
        console.error('Settings fetch error:', error);
        throw error;
      }
      
      if (!data) {
        const { data: newSettings, error: createError } = await supabase
          .from('user_settings')
          .insert({ user_id: userId })
          .select()
          .single();
          
        if (createError) throw createError;
        return newSettings;
      }
      
      return data;
    },
    enabled: !!userId,
    retry: 1,
  });

  useEffect(() => {
    if (profile) {
      setPersonalInfo(prev => ({
        ...prev,
        firstName: profile.first_name || "",
        lastName: profile.last_name || "",
        phone: profile.phone || "",
      }));
    }
  }, [profile]);

  const updateSettings = useMutation({
    mutationFn: async (settings: any) => {
      if (!userId) throw new Error("No user ID found");
      
      const { data, error } = await supabase
        .from('user_settings')
        .update(settings)
        .eq('user_id', userId)
        .select()
        .single();
      
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userSettings'] });
      toast({
        title: "Settings updated",
        description: "Your settings have been successfully updated.",
      });
    },
    onError: (error: Error) => {
      toast({
        title: "Error",
        description: error.message || "Failed to update settings. Please try again.",
        variant: "destructive",
      });
      console.error('Update settings error:', error);
    },
  });

  const handleToggle2FA = async (enabled: boolean) => {
    updateSettings.mutate({ two_factor_enabled: enabled });
  };

  const handleNotificationToggle = async (channel: string, enabled: boolean) => {
    const currentPreferences = userSettings?.notification_preferences || [];
    let newPreferences;
    
    if (enabled) {
      newPreferences = [...new Set([...currentPreferences, channel])];
    } else {
      newPreferences = currentPreferences.filter(p => p !== channel);
    }
    
    updateSettings.mutate({ notification_preferences: newPreferences });
  };

  const handlePersonalInfoUpdate = async () => {
    if (!userId) {
      toast({
        title: "Error",
        description: "No user ID found. Please try logging in again.",
        variant: "destructive",
      });
      return;
    }

    try {
      const { error } = await supabase
        .from('profiles')
        .upsert({
          id: userId,
          first_name: personalInfo.firstName,
          last_name: personalInfo.lastName,
          phone: personalInfo.phone,
          updated_at: new Date().toISOString(),
        })
        .eq('id', userId);

      if (error) throw error;

      await supabase.auth.updateUser({
        data: { 
          first_name: personalInfo.firstName,
          last_name: personalInfo.lastName
        }
      });

      toast({
        title: "Profile updated",
        description: "Your personal information has been updated successfully.",
      });
      
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    } catch (error) {
      const message = error instanceof Error ? error.message : "Failed to update profile";
      toast({
        title: "Error",
        description: message,
        variant: "destructive",
      });
      console.error('Update profile error:', error);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0] || !userId) return;

    const file = e.target.files[0];
    const fileExt = file.name.split('.').pop();
    const fileName = `avatars/${userId}.${fileExt}`;

    try {
      setIsUploading(true);
      
      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(fileName, file, { upsert: true });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(fileName);

      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          avatar_url: publicUrl,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (updateError) throw updateError;

      await supabase.auth.updateUser({
        data: { 
          avatar_url: publicUrl 
        }
      });

      toast({
        title: "Profile picture updated",
        description: "Your profile picture has been updated successfully.",
      });
      
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    } catch (error) {
      const message = error instanceof Error ? error.message : "Failed to upload profile picture";
      toast({
        title: "Error",
        description: message,
        variant: "destructive",
      });
      console.error('Profile picture upload error:', error);
    } finally {
      setIsUploading(false);
      if (e.target) e.target.value = '';
    }
  };

  const handleRemoveProfilePicture = async () => {
    if (!userId || !profile?.avatar_url) return;

    try {
      const urlParts = profile.avatar_url.split('/');
      const fileName = urlParts[urlParts.length - 1];
      const filePath = `avatars/${fileName}`;

      const { error: removeError } = await supabase.storage
        .from('avatars')
        .remove([filePath]);

      if (removeError) {
        console.warn('Could not remove file from storage:', removeError);
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          avatar_url: null,
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (updateError) throw updateError;

      await supabase.auth.updateUser({
        data: { 
          avatar_url: null 
        }
      });

      toast({
        title: "Profile picture removed",
        description: "Your profile picture has been removed successfully.",
      });
      
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    } catch (error) {
      const message = error instanceof Error ? error.message : "Failed to remove profile picture";
      toast({
        title: "Error",
        description: message,
        variant: "destructive",
      });
      console.error('Profile picture removal error:', error);
    }
  };

  if (isLoadingProfile || isLoadingSettings) {
    return (
      <DashboardSidebar>
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </DashboardSidebar>
    );
  }

  return (
    <DashboardSidebar>
      <div className="flex-1 w-full">
        <div className="sticky top-0 z-10 border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex h-16 items-center gap-4 px-6">
            <h1 className="text-3xl font-bold text-primary">Settings</h1>
          </div>
        </div>

        <div className="p-6 max-w-[1400px] mx-auto w-full">
          <Tabs defaultValue="personal" className="space-y-6">
            <TabsList>
              <TabsTrigger value="personal" className="flex items-center gap-2">
                <User className="h-4 w-4" />
                Personal Info
              </TabsTrigger>
              <TabsTrigger value="security" className="flex items-center gap-2">
                <Shield className="h-4 w-4" />
                Security
              </TabsTrigger>
              <TabsTrigger value="notifications" className="flex items-center gap-2">
                <Bell className="h-4 w-4" />
                Notifications
              </TabsTrigger>
              <TabsTrigger value="appearance" className="flex items-center gap-2">
                <Palette className="h-4 w-4" />
                Appearance
              </TabsTrigger>
            </TabsList>

            <TabsContent value="personal">
              <Card>
                <CardHeader>
                  <CardTitle>Personal Information</CardTitle>
                  <CardDescription>
                    Update your personal details and contact information
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  <div className="flex flex-col items-center sm:items-start sm:flex-row gap-6 pb-6 border-b">
                    <div className="flex flex-col items-center gap-2">
                      <ProfileAvatar 
                        userId={userId}
                        firstName={personalInfo.firstName}
                        lastName={personalInfo.lastName}
                        imageSrc={profile?.avatar_url}
                        size="xl"
                      />
                      <span className="text-sm text-muted-foreground">Profile Image</span>
                    </div>
                    <div className="flex flex-col gap-4 justify-center">
                      <div className="flex flex-col gap-2">
                        <Label htmlFor="profilePicture">Profile Picture</Label>
                        <Input
                          id="profilePicture"
                          type="file"
                          accept="image/*"
                          onChange={handleFileUpload}
                          disabled={isUploading}
                          className="max-w-sm"
                        />
                        <p className="text-xs text-muted-foreground">
                          Recommended: Square image, max 2MB
                        </p>
                      </div>
                      <div className="flex flex-row gap-2">
                        <Button 
                          type="button" 
                          size="sm"
                          variant="outline" 
                          onClick={handleRemoveProfilePicture}
                          disabled={!profile?.avatar_url || isUploading}
                          className="flex items-center gap-1"
                        >
                          <Trash2 className="h-4 w-4" />
                          Remove Photo
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="grid gap-4 md:grid-cols-2">
                    <div className="space-y-2">
                      <Label htmlFor="firstName">First Name</Label>
                      <Input
                        id="firstName"
                        value={personalInfo.firstName}
                        onChange={(e) =>
                          setPersonalInfo((prev) => ({
                            ...prev,
                            firstName: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="lastName">Last Name</Label>
                      <Input
                        id="lastName"
                        value={personalInfo.lastName}
                        onChange={(e) =>
                          setPersonalInfo((prev) => ({
                            ...prev,
                            lastName: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="email"
                      value={personalInfo.email}
                      disabled
                      className="bg-muted"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="phone">Phone Number</Label>
                    <Input
                      id="phone"
                      type="tel"
                      value={personalInfo.phone}
                      onChange={(e) =>
                        setPersonalInfo((prev) => ({
                          ...prev,
                          phone: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <Button onClick={handlePersonalInfoUpdate}>
                    Save Changes
                  </Button>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="security">
              <Card>
                <CardHeader>
                  <CardTitle>Security Settings</CardTitle>
                  <CardDescription>
                    Manage your account security preferences
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <Label>Two-Factor Authentication</Label>
                      <p className="text-sm text-muted-foreground">
                        Add an extra layer of security to your account
                      </p>
                    </div>
                    <Switch
                      checked={userSettings?.two_factor_enabled}
                      onCheckedChange={handleToggle2FA}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="currentPassword">Current Password</Label>
                    <Input id="currentPassword" type="password" />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="newPassword">New Password</Label>
                    <Input id="newPassword" type="password" />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="confirmPassword">Confirm New Password</Label>
                    <Input id="confirmPassword" type="password" />
                  </div>
                  <Button>Update Password</Button>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="notifications">
              <Card>
                <CardHeader>
                  <CardTitle>Notification Preferences</CardTitle>
                  <CardDescription>
                    Choose how you want to receive updates
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <Label>Email Notifications</Label>
                      <p className="text-sm text-muted-foreground">
                        Receive updates via email
                      </p>
                    </div>
                    <Switch
                      checked={userSettings?.notification_preferences?.includes('email')}
                      onCheckedChange={(checked) => handleNotificationToggle('email', checked)}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <Label>SMS Notifications</Label>
                      <p className="text-sm text-muted-foreground">
                        Receive updates via SMS
                      </p>
                    </div>
                    <Switch
                      checked={userSettings?.notification_preferences?.includes('sms')}
                      onCheckedChange={(checked) => handleNotificationToggle('sms', checked)}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <Label>In-App Notifications</Label>
                      <p className="text-sm text-muted-foreground">
                        Receive notifications within the app
                      </p>
                    </div>
                    <Switch
                      checked={userSettings?.notification_preferences?.includes('in_app')}
                      onCheckedChange={(checked) => handleNotificationToggle('in_app', checked)}
                    />
                  </div>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="appearance">
              <Card>
                <CardHeader>
                  <CardTitle>Appearance Settings</CardTitle>
                  <CardDescription>
                    Customize how the application looks
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <Label htmlFor="theme-toggle">Theme</Label>
                      <p className="text-sm text-muted-foreground">
                        Toggle between light and dark mode
                      </p>
                    </div>
                    <div className="flex items-center gap-3">
                      <Sun className="h-5 w-5 text-muted-foreground" />
                      <ThemeToggle />
                      <Moon className="h-5 w-5 text-muted-foreground" />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </DashboardSidebar>
  );
};

export default Settings;
