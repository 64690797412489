
import { Market } from "@/types/market";

export const filterByCriteria = (
  markets: Market[],
  searchQuery: string = '',
  selectedRegion: string = 'All',
  riskLevel: string = 'All'
): Market[] => {
  return markets.filter(market => {
    const matchesSearch = searchQuery 
      ? market.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        market.region.toLowerCase().includes(searchQuery.toLowerCase())
      : true;
      
    const normalizedSelectedRegion = selectedRegion.toLowerCase();
    const normalizedMarketRegion = market.region.toLowerCase();
    const normalizedRiskLevel = riskLevel.toLowerCase();
    const normalizedMarketRisk = market.market_risk_level?.toLowerCase() || '';
    
    const matchesRegion = normalizedSelectedRegion === 'all' 
      ? true 
      : normalizedMarketRegion === normalizedSelectedRegion;
      
    const matchesRisk = normalizedRiskLevel === 'all' 
      ? true 
      : normalizedMarketRisk === normalizedRiskLevel;
    
    return matchesSearch && matchesRegion && matchesRisk;
  });
};
