
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Slider } from "@/components/ui/slider";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useToast } from "@/hooks/use-toast";
import { Settings, Save, Download, History, RefreshCw, DollarSign } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { Separator } from "@/components/ui/separator";
import { format } from 'date-fns';
import { Skeleton } from "@/components/ui/skeleton";
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination";

interface Market {
  id: string;
  name: string;
  status: string;
  target_value: number;
  current_value: number;
  region: string;
  market_type: string;
  market_risk_level: string;
  active_participants: number;
  daily_contribution_target: number;
  total_contributions: number;
  entry_amount: number;
}

interface User {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  balance: number;
  status: string | null;
  member_share_percentage: number;
}

interface Transaction {
  id: string;
  user_id: string;
  amount: number;
  type: string;
  status: string;
  created_at: string;
  description: string;
  reference_number: string;
}

interface MemberFinancialManagementProps {
  user: User;
  markets: Market[];
}

export const MemberFinancialManagement: React.FC<MemberFinancialManagementProps> = ({ 
  user,
  markets
}) => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [newBalance, setNewBalance] = useState(user.balance.toString());
  const [memberSharePercentage, setMemberSharePercentage] = useState(user.member_share_percentage);
  const [autoAdjust, setAutoAdjust] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { toast } = useToast();

  useEffect(() => {
    fetchTransactions();
  }, [user.id]);
  
  useEffect(() => {
    setNewBalance(user.balance.toString());
    setMemberSharePercentage(user.member_share_percentage);
  }, [user]);

  // Calculate pagination values for markets
  const totalPages = Math.ceil(markets.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentMarkets = markets.slice(startIndex, endIndex);
  
  // Handle pagination
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  // Generate pagination numbers
  const generatePaginationItems = () => {
    const items = [];
    const maxPagesShown = 5;
    
    // Logic for determining which page numbers to show
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + maxPagesShown - 1);
    
    // Adjust if we're near the end
    if (endPage - startPage < maxPagesShown - 1) {
      startPage = Math.max(1, endPage - maxPagesShown + 1);
    }
    
    // First page
    if (startPage > 1) {
      items.push(
        <PaginationItem key="first">
          <PaginationLink isActive={currentPage === 1} onClick={() => goToPage(1)}>
            1
          </PaginationLink>
        </PaginationItem>
      );
      
      // Show ellipsis if needed
      if (startPage > 2) {
        items.push(
          <PaginationItem key="ellipsis-start">
            <PaginationEllipsis />
          </PaginationItem>
        );
      }
    }
    
    // Page numbers
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i}>
          <PaginationLink isActive={currentPage === i} onClick={() => goToPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    
    // Last page
    if (endPage < totalPages) {
      // Show ellipsis if needed
      if (endPage < totalPages - 1) {
        items.push(
          <PaginationItem key="ellipsis-end">
            <PaginationEllipsis />
          </PaginationItem>
        );
      }
      
      items.push(
        <PaginationItem key="last">
          <PaginationLink isActive={currentPage === totalPages} onClick={() => goToPage(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      );
    }
    
    return items;
  };

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase
        .from('transactions')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(10);

      if (error) throw error;
      
      setTransactions(data || []);
      
    } catch (error) {
      console.error('Error fetching transactions:', error);
      toast({
        title: "Error",
        description: "Failed to load transaction history",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateFinancialSettings = async () => {
    try {
      setLoading(true);
      
      const balanceValue = parseFloat(newBalance);
      if (isNaN(balanceValue)) {
        toast({
          title: "Invalid Balance",
          description: "Please enter a valid number for balance",
          variant: "destructive",
        });
        return;
      }
      
      // Update profile with new balance and share percentage
      const { error } = await supabase
        .from('profiles')
        .update({
          balance: balanceValue,
          member_share_percentage: memberSharePercentage
        })
        .eq('id', user.id);

      if (error) throw error;
      
      toast({
        title: "Settings Updated",
        description: "Financial settings have been updated for this member",
      });
      
    } catch (error) {
      console.error('Error updating financial settings:', error);
      toast({
        title: "Update Failed",
        description: "Failed to update financial settings",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const addTransaction = async (type: 'deposit' | 'withdrawal') => {
    try {
      setLoading(true);
      
      const amount = parseFloat(newBalance) - user.balance;
      if (isNaN(amount) || amount === 0) {
        toast({
          title: "Invalid Amount",
          description: "Please enter a different amount than the current balance",
          variant: "destructive",
        });
        return;
      }
      
      // If withdrawal, amount should be negative
      const transactionAmount = type === 'withdrawal' ? -Math.abs(amount) : Math.abs(amount);
      
      // Add transaction record
      const { error: transactionError } = await supabase
        .from('transactions')
        .insert({
          user_id: user.id,
          amount: transactionAmount,
          type: type,
          status: 'completed',
          description: `Admin ${type} adjustment`,
          reference_number: `ADMIN-${Date.now()}`
        });

      if (transactionError) throw transactionError;
      
      // Update user balance
      await updateFinancialSettings();
      
      // Refresh transactions
      await fetchTransactions();
      
      toast({
        title: "Transaction Added",
        description: `${type.charAt(0).toUpperCase() + type.slice(1)} has been processed successfully`,
      });
      
    } catch (error) {
      console.error('Error adding transaction:', error);
      toast({
        title: "Transaction Failed",
        description: `Failed to process ${type}`,
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const exportTransactionHistory = () => {
    if (transactions.length === 0) {
      toast({
        title: "No Data",
        description: "There are no transactions to export",
        variant: "destructive",
      });
      return;
    }
    
    try {
      let csvContent = "data:text/csv;charset=utf-8,";
      
      // Add headers
      csvContent += "Date,Type,Amount,Status,Description,Reference\n";
      
      // Add rows
      transactions.forEach(transaction => {
        const date = format(new Date(transaction.created_at), 'yyyy-MM-dd HH:mm:ss');
        const amount = formatCurrency(transaction.amount).replace(',', '');
        
        csvContent += `${date},${transaction.type},${amount},${transaction.status},${transaction.description || ''},${transaction.reference_number || ''}\n`;
      });
      
      // Create download link
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${user.first_name}_${user.last_name}_transactions.csv`);
      document.body.appendChild(link);
      
      // Trigger download
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      
      toast({
        title: "Export Complete",
        description: "Transaction history has been exported to CSV",
      });
      
    } catch (error) {
      console.error('Error exporting transactions:', error);
      toast({
        title: "Export Failed",
        description: "Failed to export transaction history",
        variant: "destructive",
      });
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR'
    }).format(amount);
  };

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Settings className="h-5 w-5 text-primary" />
              Financial Settings
            </CardTitle>
            <CardDescription>
              Configure financial settings for {user.first_name} {user.last_name}
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="balance">Current Balance</Label>
              <div className="flex items-center gap-2">
                <DollarSign className="h-4 w-4 text-muted-foreground" />
                <Input 
                  id="balance"
                  type="number" 
                  value={newBalance}
                  onChange={(e) => setNewBalance(e.target.value)}
                />
              </div>
              <p className="text-xs text-muted-foreground">
                Current balance: {formatCurrency(user.balance)}
              </p>
            </div>
            
            <Separator className="my-4" />
            
            <div className="space-y-4">
              <div>
                <Label htmlFor="share-percentage" className="mb-2 block">
                  Member Share Percentage: {memberSharePercentage}%
                </Label>
                <Slider
                  id="share-percentage"
                  min={0}
                  max={100}
                  step={1}
                  value={[memberSharePercentage]}
                  onValueChange={(value) => setMemberSharePercentage(value[0])}
                  className="mb-2"
                />
                <div className="flex justify-between text-xs text-muted-foreground">
                  <span>0%</span>
                  <span>50%</span>
                  <span>100%</span>
                </div>
              </div>
              
              <div className="flex items-center space-x-2">
                <Switch
                  id="auto-adjust"
                  checked={autoAdjust}
                  onCheckedChange={setAutoAdjust}
                />
                <Label htmlFor="auto-adjust">Auto-adjust based on market performance</Label>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex gap-2 flex-col sm:flex-row">
            <Button 
              variant="outline" 
              className="sm:flex-1" 
              onClick={() => addTransaction('deposit')}
              disabled={loading}
            >
              <DollarSign className="h-4 w-4 mr-2" />
              Add Deposit
            </Button>
            <Button 
              variant="outline" 
              className="sm:flex-1" 
              onClick={() => addTransaction('withdrawal')}
              disabled={loading}
            >
              <DollarSign className="h-4 w-4 mr-2" />
              Add Withdrawal
            </Button>
            <Button 
              className="sm:flex-1" 
              onClick={updateFinancialSettings}
              disabled={loading}
            >
              <Save className="h-4 w-4 mr-2" />
              Save Settings
            </Button>
          </CardFooter>
        </Card>
        
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <History className="h-5 w-5 text-primary" />
                <span>Transaction History</span>
              </div>
              <div className="flex gap-2">
                <Button 
                  variant="outline" 
                  size="sm"
                  disabled={loading} 
                  onClick={fetchTransactions}
                >
                  <RefreshCw className={`h-4 w-4 ${loading ? "animate-spin" : ""}`} />
                </Button>
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={exportTransactionHistory}
                  disabled={transactions.length === 0}
                >
                  <Download className="h-4 w-4 mr-1" />
                  Export
                </Button>
              </div>
            </CardTitle>
            <CardDescription>
              Recent transactions for this member
            </CardDescription>
          </CardHeader>
          <CardContent>
            {loading ? (
              <div className="space-y-2">
                <Skeleton className="h-10 w-full" />
                <Skeleton className="h-10 w-full" />
                <Skeleton className="h-10 w-full" />
              </div>
            ) : transactions.length === 0 ? (
              <div className="text-center py-8 text-muted-foreground border rounded-md">
                No recent transactions found
              </div>
            ) : (
              <div className="overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Date</TableHead>
                      <TableHead>Type</TableHead>
                      <TableHead>Amount</TableHead>
                      <TableHead>Status</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {transactions.map((transaction) => (
                      <TableRow key={transaction.id}>
                        <TableCell className="whitespace-nowrap">{formatDate(transaction.created_at)}</TableCell>
                        <TableCell className="capitalize">{transaction.type}</TableCell>
                        <TableCell className={transaction.amount >= 0 ? "text-green-600" : "text-red-600"}>
                          {formatCurrency(transaction.amount)}
                        </TableCell>
                        <TableCell>
                          <span className={`inline-flex items-center rounded-full px-2 py-1 text-xs ${
                            transaction.status === 'completed' ? 'bg-green-100 text-green-700' : 
                            transaction.status === 'pending' ? 'bg-yellow-100 text-yellow-700' : 
                            'bg-red-100 text-red-700'
                          }`}>
                            {transaction.status}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
      
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            Market Financial Summary
          </CardTitle>
          <CardDescription>
            Overview of this member's financial performance across markets
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {markets.length === 0 ? (
            <div className="text-center py-8 text-muted-foreground border rounded-md">
              This member is not participating in any markets
            </div>
          ) : (
            <>
              <div className="overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Market</TableHead>
                      <TableHead>Entry Amount</TableHead>
                      <TableHead>Current Earnings</TableHead>
                      <TableHead>Target Earnings</TableHead>
                      <TableHead>Progress</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <div className="space-y-2">
                            <Skeleton className="h-6 w-full" />
                            <Skeleton className="h-6 w-full" />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      currentMarkets.map(market => (
                        <TableRow key={market.id}>
                          <TableCell className="font-medium">{market.name}</TableCell>
                          <TableCell>{formatCurrency(market.entry_amount)}</TableCell>
                          <TableCell>{formatCurrency(market.current_value * (user.member_share_percentage / 100))}</TableCell>
                          <TableCell>{formatCurrency(market.target_value * (user.member_share_percentage / 100))}</TableCell>
                          <TableCell>
                            <div className="w-full max-w-[100px]">
                              <div className="h-2 w-full bg-muted rounded-full overflow-hidden">
                                <div 
                                  className="h-full bg-primary rounded-full" 
                                  style={{ 
                                    width: `${Math.min(100, Math.max(0, (market.current_value / market.target_value) * 100))}%` 
                                  }}
                                ></div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </div>
              
              {/* Pagination */}
              {totalPages > 1 && (
                <Pagination className="justify-center">
                  <PaginationContent>
                    <PaginationItem>
                      <PaginationPrevious 
                        onClick={() => goToPage(currentPage - 1)} 
                        className={currentPage === 1 ? "pointer-events-none opacity-50" : "cursor-pointer"}
                      />
                    </PaginationItem>
                    
                    {generatePaginationItems()}
                    
                    <PaginationItem>
                      <PaginationNext 
                        onClick={() => goToPage(currentPage + 1)} 
                        className={currentPage === totalPages ? "pointer-events-none opacity-50" : "cursor-pointer"}
                      />
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              )}
              
              {markets.length > 0 && (
                <div className="text-sm text-muted-foreground text-center">
                  Showing {startIndex + 1} to {Math.min(endIndex, markets.length)} of {markets.length} markets
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
