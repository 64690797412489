import React, { useState, useRef, useEffect } from "react";
import { 
  Table, 
  TableHeader, 
  TableRow, 
  TableHead, 
  TableBody, 
  TableCell 
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { FullscreenButton } from "./FullscreenButton";
import { Edit2, Check, X, Save, RefreshCw } from "lucide-react";
import { calculateAdjustedTarget } from "@/utils/marketCalculations";
import { ResizableTableHeader } from "./ResizableTableHeader";
import { syncMemberTargets, updateDashboardTargets, refreshMemberTargets, updateMarketsWithSystemTargets } from "@/utils/market/target-sync";
import { toast } from "sonner";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { withAdminPrivileges } from "@/utils/auth/admin-verification";

export interface MarketTargetData {
  baseTarget: number;
  adjustment: number;
  factor: number;
  adjustedTarget: number;
}

export interface MemberCalculatorData {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  marketAus: MarketTargetData;
  marketRsa: MarketTargetData;
  marketUk: MarketTargetData;
  marketUsa: MarketTargetData;
}

interface MarketCalculatorTableProps {
  data: MemberCalculatorData[];
  isLoading: boolean;
  onSaveChanges: (updatedData: MemberCalculatorData[]) => Promise<void>;
}

export const MarketCalculatorTable = ({
  data = [],
  isLoading,
  onSaveChanges
}: MarketCalculatorTableProps) => {
  const [editingCell, setEditingCell] = useState<{
    memberId: string;
    market: string;
    field: string;
    value: any;
  } | null>(null);
  const [editedData, setEditedData] = useState<MemberCalculatorData[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSyncingSystem, setSyncingSystem] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    setEditedData(data);
  }, [data]);
  
  useEffect(() => {
    const handleRefreshRequest = () => {
      if (editedData.length > 0) {
        toast.info("Calculator data is being synced with dashboard...");
        handleSaveAllChanges();
      }
    };
    
    const handleGlobalSettingsUpdate = (event: Event) => {
      const customEvent = event as CustomEvent;
      toast.info("Global calculator settings have been updated. Refreshing data...");
      
      if (typeof onSaveChanges === 'function') {
        onSaveChanges(editedData).then(() => {
          toast.success("Calculator data refreshed with new global settings");
        }).catch(err => {
          logger.error("Error refreshing calculator data after global settings update:", err);
          toast.error("Failed to refresh calculator data with new settings");
        });
      }
    };
    
    window.addEventListener('refresh-market-calculator', handleRefreshRequest);
    window.addEventListener('calculator-settings-updated', handleGlobalSettingsUpdate);
    window.addEventListener('regional-targets-updated', handleGlobalSettingsUpdate);
    window.addEventListener('system-targets-updated', handleGlobalSettingsUpdate);
    
    return () => {
      window.removeEventListener('refresh-market-calculator', handleRefreshRequest);
      window.removeEventListener('calculator-settings-updated', handleGlobalSettingsUpdate);
      window.removeEventListener('regional-targets-updated', handleGlobalSettingsUpdate);
      window.removeEventListener('system-targets-updated', handleGlobalSettingsUpdate);
    };
  }, [editedData, onSaveChanges]);
  
  const formatCurrency = (amount: number | null) => {
    if (amount === null) return "-";
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2
    }).format(amount);
  };
  
  const handleEditClick = (memberId: string, market: string, field: string, currentValue: number) => {
    setEditingCell({ memberId, market, field, value: currentValue });
    
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 10);
  };
  
  const handleSaveClick = () => {
    if (!editingCell) return;
    
    const { memberId, market, field, value } = editingCell;
    
    if (field === 'factor' && value <= 0) {
      toast.error("Invalid factor value", {
        description: "Factor must be greater than 0"
      });
      return;
    }
    
    if ((field === 'baseTarget' || field === 'adjustment') && value < 0) {
      toast.error("Invalid value", {
        description: "Value cannot be negative"
      });
      return;
    }
    
    const updatedData = editedData.map(member => {
      if (member.id === memberId) {
        const marketKey = `market${market}` as keyof typeof member;
        const marketData = { ...member[marketKey] as MarketTargetData };
        
        marketData[field as keyof MarketTargetData] = value;
        
        marketData.adjustedTarget = calculateAdjustedTarget(
          marketData.baseTarget,
          marketData.adjustment,
          marketData.factor
        );
        
        return {
          ...member,
          [marketKey]: marketData
        };
      }
      return member;
    });
    
    setEditedData(updatedData);
    setHasUnsavedChanges(true);
    setEditingCell(null);
    
    saveCalculatorChangesToDb(updatedData);
  };
  
  const saveCalculatorChangesToDb = async (data: MemberCalculatorData[]) => {
    try {
      const promises = data.map(member => {
        const memberData = {
          userId: member.id,
          aus: member.marketAus,
          rsa: member.marketRsa,
          uk: member.marketUk,
          usa: member.marketUsa,
          timestamp: new Date().toISOString()
        };
        
        return storeCalculatorSettings(member.id, memberData);
      });
      
      await Promise.all(promises);
      logger.info("Saved calculator changes to database");
    } catch (error) {
      logger.error("Error saving calculator changes to database:", error);
    }
  };
  
  const storeCalculatorSettings = async (userId: string, data: any) => {
    try {
      const { data: sessionData } = await supabase.auth.getSession();
      
      if (!sessionData.session) {
        logger.error("No active session found when storing calculator settings");
        return;
      }
      
      const isOwnSettings = userId === sessionData.session.user.id;
      const isAdminEmail = sessionData.session.user.email?.endsWith('@thecolosseum.co.za') || false;
      
      if (!isOwnSettings && !isAdminEmail) {
        logger.error("Permission denied: non-admin user attempting to update other user's settings");
        return;
      }
      
      if (!isOwnSettings && isAdminEmail) {
        try {
          const regions = ['AUS', 'RSA', 'UK', 'USA'];
          const regionData = {
            AUS: data.aus,
            RSA: data.rsa,
            UK: data.uk,
            USA: data.usa
          };
          
          for (const region of regions) {
            const targetData = regionData[region as keyof typeof regionData];
            
            const { error } = await supabase.rpc('update_member_region_target_secure', {
              p_user_id: userId,
              p_region: region,
              p_base_target: targetData.baseTarget,
              p_adjustment: targetData.adjustment,
              p_factor: targetData.factor,
              p_target_value: targetData.adjustedTarget
            });
            
            if (error) {
              logger.error(`RPC Error updating regional target for ${userId} in ${region}:`, error);
            }
          }
          
          const { error } = await supabase.rpc(
            'update_user_calculator_settings',
            {
              p_user_id: userId,
              p_settings: data
            }
          );
              
          if (error) {
            logger.error(`Admin client error storing calculator settings for ${userId}:`, error);
            throw error;
          }
          
          return;
        } catch (adminError) {
          logger.error(`Error in admin operations for ${userId}:`, adminError);
        }
      }
      
      const { error } = await supabase.rpc(
        'update_user_calculator_settings',
        {
          p_user_id: userId,
          p_settings: data
        }
      );
        
      if (error) {
        logger.error(`Error storing calculator settings for ${userId}:`, error);
        
        await updateRegionalTargetsDirectly(userId, data);
      }
    } catch (error) {
      logger.error(`Error in storeCalculatorSettings for ${userId}:`, error);
    }
  };
  
  const updateRegionalTargetsDirectly = async (
    userId: string,
    data: any
  ) => {
    try {
      const result = await withAdminPrivileges(async () => {
        const regions = ['AUS', 'RSA', 'UK', 'USA'];
        const regionData = {
          AUS: data.aus,
          RSA: data.rsa,
          UK: data.uk,
          USA: data.usa
        };
        
        const promises = regions.map(region => {
          const targetData = regionData[region as keyof typeof regionData];
          
          return updateRegionalTarget(userId, region, targetData);
        });
        
        await Promise.all(promises);
        return true;
      });
      
      if (!result.success) {
        logger.error("Failed to update regional targets with admin privileges:", result.error);
      }
    } catch (error) {
      logger.error(`Error in updateRegionalTargetsDirectly for ${userId}:`, error);
    }
  };
  
  const updateRegionalTarget = async (
    userId: string, 
    region: string, 
    marketData: MarketTargetData
  ) => {
    try {
      const { data: sessionData } = await supabase.auth.getSession();
      
      const isOwnSettings = userId === sessionData.session?.user.id;
      const isAdminEmail = sessionData.session?.user.email?.endsWith('@thecolosseum.co.za') || false;
      
      if (!isOwnSettings && !isAdminEmail) {
        logger.error(`Permission denied: non-admin attempting to update regional target for ${userId} in ${region}`);
        return;
      }
      
      const { error: rpcError } = await supabase.rpc('update_member_region_target_secure', {
        p_user_id: userId,
        p_region: region,
        p_base_target: marketData.baseTarget,
        p_adjustment: marketData.adjustment,
        p_factor: marketData.factor,
        p_target_value: marketData.adjustedTarget
      });
      
      if (rpcError) {
        logger.error(`Error updating regional target for ${userId} in ${region}:`, rpcError);
        
        if (isAdminEmail) {
          logger.warn(`Admin RPC failed, trying alternative approach for ${region}`);
          
          toast.error(`Failed to update ${region} target. Permission issue detected.`);
        }
      }
    } catch (error) {
      logger.error(`Error in updateRegionalTarget for ${userId} in ${region}:`, error);
    }
  };
  
  const handleCancelClick = () => {
    setEditingCell(null);
  };
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!editingCell) return;
    
    const value = e.target.value === '' ? 0 : parseFloat(e.target.value);
    setEditingCell({ ...editingCell, value });
  };
  
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSaveClick();
    } else if (e.key === 'Escape') {
      handleCancelClick();
    }
  };
  
  const handleSyncTargets = async () => {
    try {
      setSyncingSystem(true);
      
      const syncToastId = toast.loading("Synchronizing and propagating target data...");
      
      await handleSaveAllChanges();
      
      const dashboardUpdated = await updateDashboardTargets(editedData);
      
      if (!dashboardUpdated) {
        toast.error("Failed to update dashboard targets", { id: syncToastId });
        return;
      }
      
      const marketsUpdated = await updateMarketsWithSystemTargets();
      
      if (!marketsUpdated) {
        toast.warning("Some markets could not be updated", { id: syncToastId });
      }
      
      const membersUpdated = await refreshMemberTargets();
      
      if (!membersUpdated) {
        toast.warning("Some member targets could not be updated", { id: syncToastId });
      }
      
      const memberSyncPromises = editedData.map(member => 
        syncMemberTargets(member.id, editedData)
      );
      
      await Promise.all(memberSyncPromises);
      
      window.dispatchEvent(new CustomEvent('system-targets-updated', {
        detail: {
          source: 'market-calculator',
          timestamp: new Date().toISOString(),
          forceUpdate: true
        }
      }));
      
      window.dispatchEvent(new CustomEvent('calculator-settings-propagated', {
        detail: {
          timestamp: new Date().toISOString(),
          comprehensive: true
        }
      }));
      
      toast.success("Target data synchronized and propagated system-wide", { id: syncToastId });
    } catch (error) {
      logger.error("Error in comprehensive target sync:", error);
      toast.error("Failed to synchronize target data system-wide");
    } finally {
      setSyncingSystem(false);
    }
  };
  
  const handleSaveAllChanges = async () => {
    try {
      setIsSaving(true);
      
      await onSaveChanges(editedData);
      
      await saveCalculatorChangesToDb(editedData);
      
      await updateMarketsWithNewTargets(editedData);
      
      setHasUnsavedChanges(false);
      toast.success("All changes saved successfully and propagated to markets");
      return true;
    } catch (error) {
      console.error("Error saving all changes:", error);
      toast.error("Failed to save changes", {
        description: error instanceof Error ? error.message : "Unknown error occurred"
      });
      return false;
    } finally {
      setIsSaving(false);
    }
  };
  
  const updateMarketsWithNewTargets = async (memberData: MemberCalculatorData[]) => {
    try {
      if (memberData.length === 0) return;
      
      const firstMember = memberData[0];
      
      const regions = [
        { code: 'AUS', data: firstMember.marketAus },
        { code: 'RSA', data: firstMember.marketRsa },
        { code: 'UK', data: firstMember.marketUk },
        { code: 'USA', data: firstMember.marketUsa }
      ];
      
      const promises = regions.map(async ({ code, data }) => {
        const { error } = await supabase
          .from('markets')
          .update({
            target_value: data.adjustedTarget,
            base_value: data.adjustment,
            adjustment_factor: data.factor,
            updated_at: new Date().toISOString()
          })
          .eq('region', code);
        
        if (error) {
          logger.error(`Error updating markets in region ${code}:`, error);
        } else {
          logger.info(`Updated all markets in region ${code} with new target settings`);
          
          window.dispatchEvent(new CustomEvent('regional-targets-updated', {
            detail: {
              region: code,
              baseTarget: data.baseTarget,
              adjustment: data.adjustment,
              factor: data.factor,
              adjustedTarget: data.adjustedTarget,
              timestamp: new Date().toISOString()
            }
          }));
        }
      });
      
      await Promise.all(promises);
      
      await updateMarketParticipants(regions);
    } catch (error) {
      logger.error("Error updating markets with new targets:", error);
    }
  };
  
  const updateMarketParticipants = async (regions: { code: string, data: MarketTargetData }[]) => {
    try {
      for (const { code, data } of regions) {
        const { data: markets, error: marketsError } = await supabase
          .from('markets')
          .select('id')
          .eq('region', code);
        
        if (marketsError) {
          logger.error(`Error getting markets in region ${code}:`, marketsError);
          continue;
        }
        
        if (!markets || markets.length === 0) continue;
        
        const marketIds = markets.map(m => m.id);
        for (const marketId of marketIds) {
          const { data: participants, error: participantsError } = await supabase
            .from('market_participants')
            .select('id, user_id')
            .eq('market_id', marketId);
          
          if (participantsError) {
            logger.error(`Error getting participants for market ${marketId}:`, participantsError);
            continue;
          }
          
          if (!participants || participants.length === 0) continue;
          
          for (const participant of participants) {
            const { error: updateError } = await supabase
              .from('market_participants')
              .update({
                metadata: {
                  base_target: data.baseTarget,
                  adjustment: data.adjustment,
                  adjustment_factor: data.factor,
                  target_value: data.adjustedTarget,
                  last_updated: new Date().toISOString()
                },
                updated_at: new Date().toISOString()
              })
              .eq('id', participant.id);
            
            if (updateError) {
              logger.error(`Error updating participant ${participant.id}:`, updateError);
            }
          }
          
          logger.info(`Updated ${participants.length} participants for market ${marketId}`);
        }
        
        logger.info(`Completed participant updates for region ${code}`);
      }
    } catch (error) {
      logger.error("Error updating market participants:", error);
    }
  };
  
  const renderEditableCell = (memberId: string, market: string, field: string, value: number) => {
    const isEditing = 
      editingCell?.memberId === memberId && 
      editingCell?.market === market && 
      editingCell?.field === field;
    
    if (isEditing) {
      return (
        <div className="flex items-center gap-1">
          <input
            ref={inputRef}
            type="number"
            value={editingCell.value}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="w-24 p-1 border rounded bg-background text-foreground dark:border-gray-600 focus:outline-none focus:ring-1 focus:ring-primary"
            min={field === 'factor' ? 0.01 : 0}
            step={field === 'factor' ? 0.01 : 1}
            autoFocus
          />
          <Button variant="ghost" size="icon" className="h-6 w-6" onClick={handleSaveClick}>
            <Check className="h-3.5 w-3.5 text-green-500" />
          </Button>
          <Button variant="ghost" size="icon" className="h-6 w-6" onClick={handleCancelClick}>
            <X className="h-3.5 w-3.5 text-destructive" />
          </Button>
        </div>
      );
    }
    
    return (
      <div className="flex items-center group gap-1">
        <span className="tabular-nums">{field === 'factor' ? value.toFixed(2) : value}</span>
        <Button
          variant="ghost"
          size="icon"
          className="h-6 w-6 opacity-0 group-hover:opacity-100 transition-opacity"
          onClick={() => handleEditClick(memberId, market, field, value)}
        >
          <Edit2 className="h-3.5 w-3.5" />
        </Button>
      </div>
    );
  };
  
  return (
    <div className={`rounded-lg border bg-card shadow ${fullscreenMode ? 'fixed inset-0 z-50 p-6 overflow-auto' : ''}`}>
      <div className="flex justify-between items-center p-4">
        <h3 className="text-lg font-medium">Market Target Calculator</h3>
        <div className="flex items-center gap-2">
          <Button
            onClick={handleSyncTargets}
            disabled={isLoading || isSaving || isSyncingSystem}
            className="gap-2"
            variant="outline"
            size="sm"
          >
            <RefreshCw className={`h-4 w-4 ${isSyncingSystem ? 'animate-spin' : ''}`} />
            {isSyncingSystem ? "Syncing..." : "Sync & Propagate All Targets"}
          </Button>
          
          {hasUnsavedChanges && (
            <Button
              onClick={handleSaveAllChanges}
              disabled={isSaving}
              className="gap-2"
              variant="default"
            >
              <Save className="h-4 w-4" />
              {isSaving ? "Saving..." : "Save All Changes"}
            </Button>
          )}
          <FullscreenButton 
            isFullscreen={fullscreenMode} 
            onToggle={() => setFullscreenMode(prev => !prev)} 
          />
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <Table className="whitespace-nowrap">
          <TableHeader>
            <TableRow>
              <ResizableTableHeader minWidth={200}>Member</ResizableTableHeader>
              <ResizableTableHeader>Region</ResizableTableHeader>
              <ResizableTableHeader>Base Target</ResizableTableHeader>
              <ResizableTableHeader>Adjustment</ResizableTableHeader>
              <ResizableTableHeader>Factor</ResizableTableHeader>
              <ResizableTableHeader>Adjusted Target</ResizableTableHeader>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              Array.from({ length: 12 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={6}>
                    <Skeleton className="h-10 w-full" />
                  </TableCell>
                </TableRow>
              ))
            ) : editedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} className="text-center py-8 text-muted-foreground">
                  No market calculator data available
                </TableCell>
              </TableRow>
            ) : (
              editedData.flatMap((member, index) => [
                <TableRow key={`${member.id}-AUS`} className={index % 2 === 0 ? 'bg-muted/5' : ''}>
                  <TableCell rowSpan={4} className="font-medium align-top border-r border-border/30 pt-4">
                    {member.fullName}
                  </TableCell>
                  <TableCell className="text-amber-600 font-medium">AUS</TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Aus',
                      'baseTarget',
                      member.marketAus.baseTarget
                    )}
                  </TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Aus',
                      'adjustment',
                      member.marketAus.adjustment
                    )}
                  </TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Aus',
                      'factor',
                      member.marketAus.factor
                    )}
                  </TableCell>
                  <TableCell className="font-medium">
                    {formatCurrency(member.marketAus.adjustedTarget)}
                  </TableCell>
                </TableRow>,
                
                <TableRow key={`${member.id}-RSA`} className={index % 2 === 0 ? 'bg-muted/5' : ''}>
                  <TableCell className="text-green-600 font-medium">RSA</TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Rsa',
                      'baseTarget',
                      member.marketRsa.baseTarget
                    )}
                  </TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Rsa',
                      'adjustment',
                      member.marketRsa.adjustment
                    )}
                  </TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Rsa',
                      'factor',
                      member.marketRsa.factor
                    )}
                  </TableCell>
                  <TableCell className="font-medium">
                    {formatCurrency(member.marketRsa.adjustedTarget)}
                  </TableCell>
                </TableRow>,
                
                <TableRow key={`${member.id}-UK`} className={index % 2 === 0 ? 'bg-muted/5' : ''}>
                  <TableCell className="text-blue-600 font-medium">UK</TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Uk',
                      'baseTarget',
                      member.marketUk.baseTarget
                    )}
                  </TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Uk',
                      'adjustment',
                      member.marketUk.adjustment
                    )}
                  </TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Uk',
                      'factor',
                      member.marketUk.factor
                    )}
                  </TableCell>
                  <TableCell className="font-medium">
                    {formatCurrency(member.marketUk.adjustedTarget)}
                  </TableCell>
                </TableRow>,
                
                <TableRow key={`${member.id}-USA`} className={index % 2 === 0 ? 'bg-muted/5' : ''}>
                  <TableCell className="text-red-600 font-medium">USA</TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Usa',
                      'baseTarget',
                      member.marketUsa.baseTarget
                    )}
                  </TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Usa',
                      'adjustment',
                      member.marketUsa.adjustment
                    )}
                  </TableCell>
                  <TableCell>
                    {renderEditableCell(
                      member.id,
                      'Usa',
                      'factor',
                      member.marketUsa.factor
                    )}
                  </TableCell>
                  <TableCell className="font-medium">
                    {formatCurrency(member.marketUsa.adjustedTarget)}
                  </TableCell>
                </TableRow>
              ])
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
