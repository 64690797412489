
import React, { useState, useEffect } from "react";
import { OverviewDashboardTable } from "./OverviewDashboardTable";
import { MarketCalculatorTable, MemberCalculatorData } from "./MarketCalculatorTable";
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { FilterX, RotateCcw, Table } from "lucide-react";

interface AdminExcelViewProps {
  isAdmin: boolean;
}

export const AdminExcelView = ({ isAdmin }: AdminExcelViewProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [overviewData, setOverviewData] = useState([]);
  const [calculatorData, setCalculatorData] = useState<MemberCalculatorData[]>([]);
  const [lastRefreshTime, setLastRefreshTime] = useState<Date | null>(null);
  const [filters, setFilters] = useState({
    status: 'all',
    region: 'all',
    memberShareAbove: null as number | null,
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'fullName',
    direction: 'asc' as 'asc' | 'desc'
  });

  useEffect(() => {
    fetchExcelViewData();
    
    // Subscribe to real-time updates
    const channel = supabase
      .channel('excel-view-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'market_participants'
        },
        () => {
          logger.info('Detected changes in market_participants table');
          fetchExcelViewData();
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'profiles'
        },
        () => {
          logger.info('Detected changes in profiles table');
          fetchExcelViewData();
        }
      )
      .subscribe();
    
    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const fetchExcelViewData = async () => {
    if (!isAdmin) return;
    
    setIsLoading(true);
    try {
      // Fetch data for both tables
      const [membersData, financialData, marketTargetsData] = await Promise.all([
        fetchMembers(),
        fetchFinancialData(),
        fetchMarketTargetsData()
      ]);
      
      logger.info("Excel view data fetch complete", {
        membersCount: membersData.length,
        financialRecords: financialData.length,
        marketTargetsData: !!marketTargetsData
      });
      
      // Process and merge the data
      const processedOverviewData = processOverviewData(membersData, financialData);
      const processedCalculatorData = processCalculatorData(membersData, marketTargetsData);
      
      // Ensure data consistency between the two tables
      const syncedData = syncOverviewWithCalculatorData(processedOverviewData, processedCalculatorData);
      
      setOverviewData(syncedData.overviewData);
      setCalculatorData(syncedData.calculatorData);
      setLastRefreshTime(new Date());
      
      toast.success("Excel view data refreshed", {
        description: `Updated at ${new Date().toLocaleTimeString()}`
      });
    } catch (error) {
      logger.error("Error fetching Excel view data:", error);
      toast.error("Failed to load Excel view data", { 
        description: "Please try again or contact support if the issue persists." 
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMembers = async () => {
    // Get profiles data including created_at for time active calculation
    const { data, error } = await supabase
      .from('profiles')
      .select('id, first_name, last_name, status, member_share_percentage, is_admin, created_at, updated_at, email')
      .eq('is_admin', false);
      
    if (error) {
      logger.error("Error fetching member profiles:", error);
      throw error;
    }
    
    logger.info(`Retrieved ${data?.length || 0} member profiles`);
    return data || [];
  };

  const fetchFinancialData = async () => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    
    // Get financial summaries for all users for current and previous year
    const { data, error } = await supabase
      .from('monthly_financial_summaries')
      .select('*')
      .or(`year.eq.${currentYear},year.eq.${previousYear}`);
      
    if (error) {
      logger.error("Error fetching financial summaries:", error);
      throw error;
    }
    
    logger.info(`Retrieved ${data?.length || 0} financial summary records`);
    return data || [];
  };

  const fetchMarketTargetsData = async () => {
    try {
      // Get market targets from regional_settings table
      const { data: regionalSettings, error: regionalError } = await supabase
        .from('regional_settings')
        .select('*');
        
      if (regionalError) throw regionalError;
      
      // Get member-specific market adjustments
      const { data: marketAdjustments, error: adjustmentsError } = await supabase
        .from('market_participants')
        .select('user_id, market_id, metadata')
        .eq('status', 'active');
        
      if (adjustmentsError) throw adjustmentsError;
      
      // Get markets to map regions
      const { data: markets, error: marketsError } = await supabase
        .from('markets')
        .select('id, region');
        
      if (marketsError) throw marketsError;
      
      logger.info("Retrieved market targets data:", {
        regionalSettingsCount: regionalSettings?.length || 0,
        marketAdjustmentsCount: marketAdjustments?.length || 0,
        marketsCount: markets?.length || 0
      });
      
      return {
        regionalSettings: regionalSettings || [],
        marketAdjustments: marketAdjustments || [],
        markets: markets || []
      };
    } catch (error) {
      logger.error("Error fetching market targets data:", error);
      throw error;
    }
  };

  const processOverviewData = (members, financialData) => {
    return members.map(member => {
      // Get financial data for this member
      const memberFinancials = financialData.filter(f => f.user_id === member.id);
      
      // Calculate current year totals
      const currentYear = new Date().getFullYear();
      const currentYearData = memberFinancials.filter(f => f.year === currentYear);
      const totalYtd = currentYearData.reduce((sum, item) => sum + item.total_deposits, 0);
      const memberShareYtd = currentYearData.reduce((sum, item) => sum + item.member_share_amount, 0);
      const companyShareYtd = currentYearData.reduce((sum, item) => sum + item.company_share_amount, 0);
      
      // Calculate previous year totals
      const previousYearData = memberFinancials.filter(f => f.year === currentYear - 1);
      const previousYtd = previousYearData.reduce((sum, item) => sum + item.total_deposits, 0);
      const memberSharePrevYtd = previousYearData.reduce((sum, item) => sum + item.member_share_amount, 0);
      const companySharePrevYtd = previousYearData.reduce((sum, item) => sum + item.company_share_amount, 0);
      
      // Calculate current month
      const currentMonth = new Date().getMonth() + 1;
      const currentMonthData = memberFinancials.find(f => f.year === currentYear && f.month === currentMonth);
      const currentMonthTotal = currentMonthData?.total_deposits || 0;
      const memberShareCurrentMonth = currentMonthData?.member_share_amount || 0;
      const companyShareCurrentMonth = currentMonthData?.company_share_amount || 0;
      
      // Get status and active time
      const isOnline = new Date(member.updated_at).getTime() > Date.now() - 30 * 60 * 1000; // 30 minutes
      const status = member.status === 'suspended' ? 'suspended' : (isOnline ? 'online' : 'offline');
      
      // Calculate time active based on account creation
      const timeActive = getTimeActive(member.created_at);
      
      return {
        id: member.id,
        fullName: `${member.first_name || ''} ${member.last_name || ''}`.trim() || member.email?.split('@')[0] || 'Unknown User',
        status: {
          status,
          lastActive: member.updated_at,
          lastLogin: null
        },
        timeActive,
        memberSharePercentage: member.member_share_percentage,
        companySharePercentage: 100 - member.member_share_percentage,
        targetMarketAus: 0,
        targetMarketRsa: 0,
        targetMarketUk: 0,
        targetMarketUsa: 0,
        marketStatus: {
          totalYtd,
          previousYtd,
          currentMonth: currentMonthTotal
        },
        memberStatus: {
          totalYtd: memberShareYtd,
          previousYtd: memberSharePrevYtd,
          currentMonth: memberShareCurrentMonth
        },
        roiStatus: {
          totalYtd: companyShareYtd,
          previousYtd: companySharePrevYtd,
          currentMonth: companyShareCurrentMonth
        }
      };
    });
  };

  const processCalculatorData = (members, targetsData) => {
    const { regionalSettings, marketAdjustments, markets } = targetsData;
    
    // Create a map of region codes to settings
    const regionSettings = regionalSettings.reduce((acc, setting) => {
      acc[setting.region_code] = setting;
      return acc;
    }, {});
    
    // Create a map of market IDs to region
    const marketRegions = markets.reduce((acc, market) => {
      acc[market.id] = market.region;
      return acc;
    }, {});
    
    // Create a map of user_id to their market adjustments by region
    const userMarketAdjustments = {};
    
    marketAdjustments.forEach(adjustment => {
      const userId = adjustment.user_id;
      const region = marketRegions[adjustment.market_id] || '';
      const metadata = (adjustment.metadata && typeof adjustment.metadata === 'object') 
        ? adjustment.metadata 
        : {};
      
      if (!userMarketAdjustments[userId]) {
        userMarketAdjustments[userId] = {};
      }
      
      if (region && !userMarketAdjustments[userId][region]) {
        userMarketAdjustments[userId][region] = {
          baseTarget: metadata.base_target || 0,
          adjustment: metadata.adjustment || 0,
          factor: metadata.adjustment_factor || 1.07
        };
      }
    });
    
    // Map to calculator data format
    return members.map(member => {
      // Get default regional settings
      const defaultAus = regionSettings['AU'] || { base_target: 5000, adjustment: 500, adjustment_factor: 1.07 };
      const defaultRsa = regionSettings['ZA'] || { base_target: 5000, adjustment: 500, adjustment_factor: 1.07 };
      const defaultUk = regionSettings['UK'] || { base_target: 5000, adjustment: 500, adjustment_factor: 1.07 };
      const defaultUsa = regionSettings['US'] || { base_target: 5000, adjustment: 500, adjustment_factor: 1.07 };
      
      // Get user-specific adjustments or use defaults
      const userAdjustments = userMarketAdjustments[member.id] || {};
      
      const getMarketTarget = (region, defaultSetting) => {
        const baseTarget = userAdjustments[region]?.baseTarget || defaultSetting.base_target;
        const adjustment = userAdjustments[region]?.adjustment || defaultSetting.adjustment;
        const factor = userAdjustments[region]?.factor || defaultSetting.adjustment_factor;
        const adjustedTarget = baseTarget + (adjustment / factor);
        
        return {
          baseTarget,
          adjustment,
          factor,
          adjustedTarget: Math.round(adjustedTarget * 100) / 100 // Round to 2 decimal places
        };
      };
      
      return {
        id: member.id,
        fullName: `${member.first_name || ''} ${member.last_name || ''}`.trim() || member.email?.split('@')[0] || 'Unknown User',
        marketAus: getMarketTarget('AU', defaultAus),
        marketRsa: getMarketTarget('ZA', defaultRsa),
        marketUk: getMarketTarget('UK', defaultUk),
        marketUsa: getMarketTarget('US', defaultUsa)
      };
    });
  };

  const syncOverviewWithCalculatorData = (overviewData, calculatorData) => {
    // Create a map of user IDs to calculator data for quick lookup
    const calculatorDataMap = calculatorData.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    
    // Update the overview data with the target values from calculator data
    const updatedOverviewData = overviewData.map(item => {
      const calcData = calculatorDataMap[item.id];
      if (calcData) {
        return {
          ...item,
          targetMarketAus: Math.round(calcData.marketAus.adjustedTarget),
          targetMarketRsa: Math.round(calcData.marketRsa.adjustedTarget),
          targetMarketUk: Math.round(calcData.marketUk.adjustedTarget),
          targetMarketUsa: Math.round(calcData.marketUsa.adjustedTarget)
        };
      }
      return item;
    });
    
    return {
      overviewData: updatedOverviewData,
      calculatorData: calculatorData
    };
  };

  const getTimeActive = (loginTime: string) => {
    const lastActive = new Date(loginTime);
    const now = new Date();
    const diffMs = now.getTime() - lastActive.getTime();
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    
    if (diffDays > 365) {
      const years = Math.floor(diffDays / 365);
      return `${years} year${years > 1 ? 's' : ''}`;
    } else if (diffDays > 30) {
      const months = Math.floor(diffDays / 30);
      return `${months} month${months > 1 ? 's' : ''}`;
    } else if (diffDays > 0) {
      return `${diffDays} day${diffDays !== 1 ? 's' : ''}`;
    } else {
      // Less than a day
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      if (diffHours > 0) {
        return `${diffHours} hour${diffHours !== 1 ? 's' : ''}`;
      } else {
        const diffMinutes = Math.floor(diffMs / (1000 * 60));
        return `${diffMinutes || 1} minute${diffMinutes !== 1 ? 's' : ''}`;
      }
    }
  };

  const handleSaveCalculatorChanges = async (updatedData: MemberCalculatorData[]) => {
    if (!isAdmin) return;
    
    try {
      // Create a batch of updates to send to the database
      const updates = [];
      const userMarketUpdates = new Map();
      
      for (const member of updatedData) {
        // Get member-specific adjustments to save
        const memberUpdates = [
          { region: 'AU', data: member.marketAus },
          { region: 'ZA', data: member.marketRsa },
          { region: 'UK', data: member.marketUk },
          { region: 'US', data: member.marketUsa }
        ];
        
        // Find market IDs associated with member and regions
        const { data: memberMarkets, error } = await supabase
          .from('market_participants')
          .select('id, market_id, metadata')
          .eq('user_id', member.id)
          .eq('status', 'active');
          
        if (error) throw error;
        
        if (memberMarkets && memberMarkets.length > 0) {
          // Match markets to regions and update metadata
          const { data: markets, error: marketsError } = await supabase
            .from('markets')
            .select('id, region')
            .in('id', memberMarkets.map(m => m.market_id));
            
          if (marketsError) throw marketsError;
          
          // Create map of region to market ID
          const regionToMarketMap = {};
          markets.forEach(market => {
            regionToMarketMap[market.region] = market.id;
          });
          
          // For each region, update the corresponding market's metadata
          for (const update of memberUpdates) {
            const marketId = regionToMarketMap[update.region];
            if (marketId) {
              const participantEntry = memberMarkets.find(m => m.market_id === marketId);
              if (participantEntry) {
                const currentMetadata = participantEntry.metadata && typeof participantEntry.metadata === 'object' 
                  ? participantEntry.metadata 
                  : {};
                
                const updatedMetadata = {
                  ...currentMetadata,
                  base_target: update.data.baseTarget,
                  adjustment: update.data.adjustment,
                  adjustment_factor: update.data.factor,
                  target_value: update.data.adjustedTarget
                };
                
                // Add to updates array - make sure to include user_id and market_id
                updates.push({
                  id: participantEntry.id,
                  user_id: member.id,
                  market_id: marketId,
                  metadata: updatedMetadata
                });
                
                // Keep track of changes for syncing
                if (!userMarketUpdates.has(member.id)) {
                  userMarketUpdates.set(member.id, {});
                }
                userMarketUpdates.get(member.id)[update.region] = {
                  baseTarget: update.data.baseTarget,
                  adjustedTarget: update.data.adjustedTarget
                };
              }
            }
          }
        } else {
          // If the user doesn't have market participants yet, update the regional settings
          await updateRegionalSettings(member);
        }
        
        // Also update the markets table with the new target settings for each region
        await updateMarketsTable(member);
      }
      
      // Execute batch update if we have updates
      if (updates.length > 0) {
        logger.info(`Saving ${updates.length} market participant updates`, { 
          updateCount: updates.length 
        });
        
        const { error } = await supabase
          .from('market_participants')
          .upsert(updates);
          
        if (error) throw error;
        
        // Log successful updates
        logger.info(`Updated ${updates.length} market participant records with new targets`);
      }
      
      // Update calculator data to reflect saved changes
      setCalculatorData(updatedData);
      
      // Update overview data to stay in sync with calculator changes
      setOverviewData(prev => {
        return prev.map(item => {
          const userUpdates = userMarketUpdates.get(item.id);
          if (userUpdates) {
            return {
              ...item,
              targetMarketAus: userUpdates['AU']?.adjustedTarget || item.targetMarketAus,
              targetMarketRsa: userUpdates['ZA']?.adjustedTarget || item.targetMarketRsa,
              targetMarketUk: userUpdates['UK']?.adjustedTarget || item.targetMarketUk,
              targetMarketUsa: userUpdates['US']?.adjustedTarget || item.targetMarketUsa
            };
          }
          return item;
        });
      });
      
      // Trigger a market targets update event for other components to react to
      window.dispatchEvent(new CustomEvent('market-targets-updated'));
      
      toast.success("Market calculator data saved", {
        description: `Updated targets for ${userMarketUpdates.size} members`
      });
      
    } catch (error) {
      console.error("Error saving calculator changes:", error);
      logger.error("Error saving calculator changes:", error);
      toast.error("Failed to save calculator changes", {
        description: error instanceof Error ? error.message : "Unknown error occurred"
      });
      throw error;
    }
  };

  const updateMarketsTable = async (member: MemberCalculatorData) => {
    try {
      // For each region, ensure the markets table is updated with the new target values
      const regionUpdates = [
        { region: 'AU', data: member.marketAus },
        { region: 'ZA', data: member.marketRsa },
        { region: 'UK', data: member.marketUk },
        { region: 'US', data: member.marketUsa }
      ];
      
      for (const update of regionUpdates) {
        // Find markets matching this region
        const { data: marketsInRegion, error } = await supabase
          .from('markets')
          .select('id')
          .eq('region', update.region);
          
        if (error) throw error;
        
        if (marketsInRegion && marketsInRegion.length > 0) {
          // Update all markets in this region with the new target settings
          const targetSettings = {
            base_target: update.data.baseTarget,
            adjustment: update.data.adjustment,
            adjustment_factor: update.data.factor,
            target_value: update.data.adjustedTarget,
            last_updated: new Date().toISOString()
          };
          
          // Create a complete metadata object
          const updatedMetadata = { target_settings: targetSettings };
          
          const { error: updateError } = await supabase
            .from('markets')
            .update({
              target_value: update.data.adjustedTarget,
              base_value: update.data.adjustment,
              adjustment_factor: update.data.factor,
              updated_at: new Date().toISOString(),
              metadata: updatedMetadata
            })
            .eq('region', update.region);
            
          if (updateError) {
            logger.warn(`Error updating markets in region ${update.region}:`, updateError);
          } else {
            logger.info(`Updated markets in region ${update.region} with new target settings`);
          }
        }
      }
      
      return true;
    } catch (error) {
      logger.error("Error updating markets table:", error);
      // Don't throw to allow the process to continue
      return false;
    }
  };

  const updateRegionalSettings = async (member: MemberCalculatorData) => {
    try {
      // Update regional settings directly since the user doesn't have market participants
      const regions = [
        { code: 'AU', data: member.marketAus },
        { code: 'ZA', data: member.marketRsa },
        { code: 'UK', data: member.marketUk },
        { code: 'US', data: member.marketUsa }
      ];
      
      for (const { code, data } of regions) {
        await supabase
          .from('regional_settings')
          .upsert({
            region_code: code,
            base_target: data.baseTarget,
            adjustment: data.adjustment,
            adjustment_factor: data.factor,
            updated_at: new Date().toISOString()
          }, { onConflict: 'region_code' });
      }
      
      logger.info(`Updated regional settings for member ${member.id}`);
      return true;
    } catch (error) {
      logger.error("Error updating regional settings:", error);
      throw error;
    }
  };

  const handleUpdateMemberShare = async (userId: string, newSharePercentage: number) => {
    if (!isAdmin) return;
    
    try {
      // Update the profile in the database
      const { error } = await supabase
        .from('profiles')
        .update({ member_share_percentage: newSharePercentage })
        .eq('id', userId);
        
      if (error) throw error;
      
      // Update local state
      setOverviewData(prev => 
        prev.map(member => {
          if (member.id === userId) {
            return {
              ...member,
              memberSharePercentage: newSharePercentage,
              companySharePercentage: 100 - newSharePercentage
            };
          }
          return member;
        })
      );
      
      logger.info(`Updated member share percentage for user ${userId} to ${newSharePercentage}%`);
      
    } catch (error) {
      logger.error("Error updating member share percentage:", error);
      toast.error("Failed to update member share percentage", {
        description: "Database update error"
      });
      throw error;
    }
  };

  const handleUpdateMemberStatus = async (userId: string, newStatus: 'online' | 'offline' | 'suspended') => {
    if (!isAdmin) return;
    
    try {
      // Only "suspended" is stored in the database as a status
      // "online"/"offline" are determined by activity timestamps
      const statusToSave = newStatus === 'suspended' ? 'suspended' : 'active';
      
      // Update the profile in the database
      const { error } = await supabase
        .from('profiles')
        .update({ 
          status: statusToSave,
          admin_status_updated_at: new Date().toISOString()
        })
        .eq('id', userId);
        
      if (error) throw error;
      
      // Update local state
      setOverviewData(prev => 
        prev.map(member => {
          if (member.id === userId) {
            return {
              ...member,
              status: {
                ...member.status,
                status: newStatus
              }
            };
          }
          return member;
        })
      );
      
      logger.info(`Updated member status for user ${userId} to ${newStatus}`);
      toast.success(`Member status updated to ${newStatus}`);
      
    } catch (error) {
      logger.error("Error updating member status:", error);
      toast.error("Failed to update member status", {
        description: "Please try again"
      });
      throw error;
    }
  };

  const refreshData = () => {
    fetchExcelViewData();
  };

  const clearFilters = () => {
    setFilters({
      status: 'all',
      region: 'all',
      memberShareAbove: null
    });
  };

  const handleSort = (key: string) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const filteredOverviewData = overviewData.filter(item => {
    if (filters.status !== 'all' && item.status.status !== filters.status) {
      return false;
    }
    
    // Filter by region if any of the region targets match
    if (filters.region !== 'all') {
      const regionKey = `targetMarket${filters.region}` as keyof typeof item;
      const hasTargetInRegion = (item[regionKey] as number) > 0;
      if (!hasTargetInRegion) return false;
    }
    
    // Filter by member share percentage
    if (filters.memberShareAbove !== null && item.memberSharePercentage < filters.memberShareAbove) {
      return false;
    }
    
    return true;
  });

  const sortedOverviewData = [...filteredOverviewData].sort((a, b) => {
    const key = sortConfig.key as keyof typeof a;
    
    // Handle nested properties
    if (typeof key === 'string' && key.includes('.')) {
      const [parentKey, childKey] = key.split('.');
      const aValue = a[parentKey as keyof typeof a][childKey as any];
      const bValue = b[parentKey as keyof typeof b][childKey as any];
      
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }
      
      // For string comparison
      return sortConfig.direction === 'asc' 
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
    
    // Simple property sorting
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return sortConfig.direction === 'asc' 
        ? (a[key] as number) - (b[key] as number)
        : (b[key] as number) - (a[key] as number);
    }
    
    // String comparison
    return sortConfig.direction === 'asc'
      ? String(a[key]).localeCompare(String(b[key]))
      : String(b[key]).localeCompare(String(a[key]));
  });

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div className="col-span-1 md:col-span-2">
          <div className="flex flex-wrap gap-2 items-center">
            <div className="flex items-center">
              <span className="text-sm text-foreground dark:text-gray-300 mr-2">Status:</span>
              <select 
                className="text-sm bg-background dark:bg-zinc-800 px-2 py-1 rounded-md border border-input text-foreground dark:text-gray-200"
                value={filters.status}
                onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
              >
                <option value="all">All Statuses</option>
                <option value="online">Online</option>
                <option value="offline">Offline</option>
                <option value="suspended">Suspended</option>
              </select>
            </div>
            <div className="flex items-center">
              <span className="text-sm text-foreground dark:text-gray-300 mr-2">Region:</span>
              <select 
                className="text-sm bg-background dark:bg-zinc-800 px-2 py-1 rounded-md border border-input text-foreground dark:text-gray-200"
                value={filters.region}
                onChange={(e) => setFilters(prev => ({ ...prev, region: e.target.value }))}
              >
                <option value="all">All Regions</option>
                <option value="Aus">Australia</option>
                <option value="Rsa">South Africa</option>
                <option value="Uk">United Kingdom</option>
                <option value="Usa">United States</option>
              </select>
            </div>
            <div className="flex items-center">
              <span className="text-sm text-foreground dark:text-gray-300 mr-2">Member Share:</span>
              <select 
                className="text-sm bg-background dark:bg-zinc-800 px-2 py-1 rounded-md border border-input text-foreground dark:text-gray-200"
                value={filters.memberShareAbove === null ? 'all' : String(filters.memberShareAbove)}
                onChange={(e) => setFilters(prev => ({ ...prev, memberShareAbove: e.target.value === 'all' ? null : Number(e.target.value) }))}
              >
                <option value="all">All Percentages</option>
                <option value="10">Above 10%</option>
                <option value="15">Above 15%</option>
                <option value="20">Above 20%</option>
              </select>
            </div>
            <Button 
              variant="outline" 
              size="sm" 
              className="ml-auto gap-2 text-foreground dark:text-gray-200 bg-background dark:bg-zinc-800 hover:bg-muted/20 dark:hover:bg-zinc-700"
              onClick={clearFilters}
            >
              <FilterX className="h-4 w-4" />
              Clear Filters
            </Button>
          </div>
        </div>
        
        <div className="flex items-center gap-2 justify-end">
          {lastRefreshTime && (
            <span className="text-sm text-foreground dark:text-gray-300">
              Last updated: {lastRefreshTime.toLocaleTimeString()}
            </span>
          )}
          <Button 
            variant="outline"
            onClick={refreshData}
            className="gap-2 text-foreground dark:text-gray-200 bg-background dark:bg-zinc-800 hover:bg-muted/20 dark:hover:bg-zinc-700"
            disabled={isLoading}
          >
            <RotateCcw className="h-4 w-4" />
            {isLoading ? 'Refreshing...' : 'Refresh Data'}
          </Button>
        </div>
      </div>
      
      <OverviewDashboardTable 
        data={sortedOverviewData} 
        isLoading={isLoading}
        onUpdateMemberShare={handleUpdateMemberShare}
        onUpdateMemberStatus={handleUpdateMemberStatus}
        onSortColumn={handleSort}
        sortConfig={sortConfig}
      />
      
      <MarketCalculatorTable 
        data={calculatorData} 
        isLoading={isLoading}
        onSaveChanges={handleSaveCalculatorChanges}
      />
    </div>
  );
};
