
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MemberFinancials } from "@/components/admin/users/MemberFinancials";

const MembersAdmin = () => {
  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="pb-3">
          <CardTitle>Members Management</CardTitle>
        </CardHeader>
        <CardContent>
          <MemberFinancials />
        </CardContent>
      </Card>
    </div>
  );
};

export default MembersAdmin;
