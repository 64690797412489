
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Flag } from "lucide-react";
import { formatCurrency } from '@/utils/formatters';

interface GoalProgressCardProps {
  target: number;
  current: number;
  isLoading?: boolean;
}

export function GoalProgressCard({ target, current, isLoading = false }: GoalProgressCardProps) {
  const percentComplete = target > 0 ? Math.min(Math.round((current / target) * 100), 100) : 0;
  const remaining = Math.max(target - current, 0);
  
  let message = "";
  if (percentComplete >= 100) {
    message = "Congratulations! You've reached your goal for this month!";
  } else if (percentComplete >= 75) {
    message = `Almost there! Only ${formatCurrency(remaining)} left this month.`;
  } else if (percentComplete >= 50) {
    message = `You're doing great! ${formatCurrency(remaining)} left this month.`;
  } else if (percentComplete >= 25) {
    message = `Good progress! ${formatCurrency(remaining)} more to go this month.`;
  } else {
    message = `You've started your journey. ${formatCurrency(remaining)} left to reach your goal.`;
  }

  return (
    <Card className="shadow-sm transition-all hover:shadow-md dark:bg-card bg-white">
      <CardHeader className="pb-2">
        <CardTitle className="flex items-center text-xl font-semibold text-primary">
          <Flag className="mr-2 h-5 w-5" />
          Your Monthly Goal
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex justify-between">
          <div className="text-sm font-medium dark:text-zinc-300 text-zinc-700">Goal</div>
          <div className="font-semibold dark:text-zinc-100 text-zinc-800">{formatCurrency(target)}</div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-medium dark:text-zinc-300 text-zinc-700">Progress</div>
          <div className="font-semibold dark:text-zinc-100 text-zinc-800">{formatCurrency(current)}</div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm font-medium dark:text-zinc-300 text-zinc-700">Completion</div>
          <div className="font-semibold dark:text-zinc-100 text-zinc-800">{percentComplete}%</div>
        </div>
        
        <Progress 
          value={percentComplete} 
          className="h-2" 
          indicatorClassName={`${
            percentComplete >= 75 ? "bg-green-500" : 
            percentComplete >= 50 ? "bg-blue-500" : 
            percentComplete >= 25 ? "bg-amber-500" : "bg-red-500"
          }`}
        />
        
        <div className="mt-2 text-center text-sm dark:text-zinc-400 text-zinc-600 italic">
          {message}
        </div>
      </CardContent>
    </Card>
  );
}
