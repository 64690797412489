
import React from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { 
  RefreshCw, 
  Search, 
  Grid, 
  List, 
  Filter,
  TrendingUp,
  ChevronDown
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Separator } from "@/components/ui/separator";
import { useMarketsState } from "@/hooks/member/use-markets-state";

interface MarketHeaderProps {
  marketsCount: number;
  regionsCount: number;
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
  onRefresh: () => Promise<void>;
  isLoading: boolean;
}

export function MarketHeader({ 
  marketsCount, 
  regionsCount, 
  viewMode, 
  setViewMode, 
  onRefresh, 
  isLoading 
}: MarketHeaderProps) {
  const {
    searchQuery,
    selectedRegion,
    riskLevel,
    handleSearchChange,
    handleRegionChange,
    handleRiskLevelChange,
  } = useMarketsState();

  const regions = [
    "North America",
    "Europe",
    "Asia Pacific",
    "Latin America",
    "Middle East",
    "Africa"
  ];

  const riskLevels = [
    { value: "low", label: "Low Risk" },
    { value: "medium", label: "Medium Risk" },
    { value: "high", label: "High Risk" }
  ];

  return (
    <div className="w-full space-y-4">
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 pb-1">
        <div>
          <h1 className="text-2xl md:text-3xl font-bold text-zinc-800 dark:text-zinc-50 flex items-center gap-2 mb-1">
            <TrendingUp className="h-6 w-6 text-primary" strokeWidth={2} />
            Markets
          </h1>
          <p className="text-sm md:text-base text-zinc-600 dark:text-zinc-400">
            Explore {marketsCount} active markets across {regionsCount} regions
          </p>
        </div>
        
        <div className="flex items-center gap-2">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => onRefresh()} 
            disabled={isLoading}
            className="bg-white hover:bg-zinc-50 border-zinc-200 text-zinc-700 hover:text-primary dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:border-zinc-700 dark:text-zinc-200"
          >
            <RefreshCw className={`h-4 w-4 mr-1 ${isLoading ? 'animate-spin' : ''}`} />
            {isLoading ? 'Refreshing...' : 'Refresh'}
          </Button>
          
          <div className="flex items-center border border-zinc-200 dark:border-zinc-700 rounded-md overflow-hidden bg-white dark:bg-zinc-800 shadow-sm">
            <Button 
              variant={viewMode === 'grid' ? 'default' : 'ghost'} 
              size="sm" 
              onClick={() => setViewMode('grid')}
              className={`px-2.5 h-8 rounded-none ${
                viewMode === 'grid' 
                ? 'bg-primary text-primary-foreground hover:bg-primary/90' 
                : 'bg-transparent text-zinc-700 hover:bg-zinc-50 dark:text-zinc-300 dark:hover:bg-zinc-700'
              }`}
            >
              <Grid className="h-4 w-4" />
            </Button>
            <Separator orientation="vertical" className="h-6" />
            <Button 
              variant={viewMode === 'list' ? 'default' : 'ghost'} 
              size="sm" 
              onClick={() => setViewMode('list')}
              className={`px-2.5 h-8 rounded-none ${
                viewMode === 'list' 
                ? 'bg-primary text-primary-foreground hover:bg-primary/90' 
                : 'bg-transparent text-zinc-700 hover:bg-zinc-50 dark:text-zinc-300 dark:hover:bg-zinc-700'
              }`}
            >
              <List className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
      
      <div className="flex flex-col md:flex-row gap-3 bg-white dark:bg-card/80 p-3 rounded-xl shadow-sm border border-zinc-200 dark:border-primary/10">
        <div className="flex-1 relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-zinc-500 dark:text-zinc-400" />
          <Input
            placeholder="Search markets by name, region, or description..."
            className="pl-9 border-zinc-200 dark:border-zinc-700 placeholder:text-zinc-500 dark:placeholder:text-zinc-500 focus-visible:ring-primary/30 bg-zinc-50/50 dark:bg-zinc-800/50 text-zinc-700 dark:text-zinc-200"
            value={searchQuery}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
        
        <div className="flex gap-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button 
                variant="outline" 
                className="bg-white flex items-center gap-1 border-zinc-200 hover:bg-zinc-50 text-zinc-700 hover:text-primary dark:border-zinc-700 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-zinc-200 min-w-[120px]"
              >
                <Filter className="h-4 w-4 mr-1" />
                Region
                <ChevronDown className="h-3.5 w-3.5 ml-1 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-56 bg-white dark:bg-zinc-900 p-3 border-zinc-200 dark:border-zinc-700 shadow-lg">
              <div className="space-y-3">
                <h4 className="font-medium text-sm text-zinc-800 dark:text-zinc-100">Filter by Region</h4>
                <RadioGroup 
                  value={selectedRegion} 
                  onValueChange={handleRegionChange}
                  className="space-y-2"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="all" id="r-all" className="border-zinc-300 text-primary" />
                    <Label htmlFor="r-all" className="text-zinc-700 dark:text-zinc-300">All Regions</Label>
                  </div>
                  
                  {regions.map((region) => (
                    <div key={region} className="flex items-center space-x-2">
                      <RadioGroupItem 
                        value={region.toLowerCase().replace(/\s+/g, '-')} 
                        id={`r-${region.toLowerCase().replace(/\s+/g, '-')}`}
                        className="border-zinc-300 text-primary"
                      />
                      <Label 
                        htmlFor={`r-${region.toLowerCase().replace(/\s+/g, '-')}`}
                        className="text-zinc-700 dark:text-zinc-300"
                      >
                        {region}
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            </PopoverContent>
          </Popover>
          
          <Popover>
            <PopoverTrigger asChild>
              <Button 
                variant="outline" 
                className="bg-white flex items-center gap-1 border-zinc-200 hover:bg-zinc-50 text-zinc-700 hover:text-primary dark:border-zinc-700 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-zinc-200 min-w-[120px]"
              >
                <Filter className="h-4 w-4 mr-1" />
                Risk
                <ChevronDown className="h-3.5 w-3.5 ml-1 text-zinc-500" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-56 bg-white dark:bg-zinc-900 p-3 border-zinc-200 dark:border-zinc-700 shadow-lg">
              <div className="space-y-3">
                <h4 className="font-medium text-sm text-zinc-800 dark:text-zinc-100">Filter by Risk Level</h4>
                <RadioGroup 
                  value={riskLevel} 
                  onValueChange={handleRiskLevelChange}
                  className="space-y-2"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="all" id="risk-all" className="border-zinc-300 text-primary" />
                    <Label htmlFor="risk-all" className="text-zinc-700 dark:text-zinc-300">All Risk Levels</Label>
                  </div>
                  
                  {riskLevels.map((risk) => (
                    <div key={risk.value} className="flex items-center space-x-2">
                      <RadioGroupItem 
                        value={risk.value} 
                        id={`risk-${risk.value}`}
                        className="border-zinc-300 text-primary" 
                      />
                      <Label 
                        htmlFor={`risk-${risk.value}`}
                        className="text-zinc-700 dark:text-zinc-300"
                      >
                        {risk.label}
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  );
}
