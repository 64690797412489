
import { useEffect, useState, useCallback, useRef } from "react";
import { Market } from "@/types/market";
import { logger } from "@/utils/logger";
import { supabase } from "@/lib/supabase";
import { 
  availableTabStrategy, 
  joinedTabStrategy, 
  upcomingTabStrategy, 
  closedTabStrategy 
} from "@/utils/market/filters/tab-strategies";

// Updated to be compatible with Record<string, number>
interface TabCounts {
  available: number;
  joined: number;
  upcoming: number;
  closed: number;
  [key: string]: number; // Add index signature for string keys
}

/**
 * Hook for managing market tabs without relying on optimistic updates
 */
export const useMarketTabs = (
  markets: Market[] | null | undefined,
  activeTab: string,
  setActiveTab: (tab: string) => void
) => {
  const [participationCounts, setParticipationCounts] = useState<TabCounts>({
    available: 0,
    joined: 0,
    upcoming: 0,
    closed: 0
  });
  
  // Add timeout ref for clearing tab change timeouts
  const tabChangeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  /**
   * Calculate participation counts based on actual data and tab strategies
   */
  const getParticipationCounts = useCallback((): TabCounts => {
    if (!markets) {
      return {
        available: 0,
        joined: 0,
        upcoming: 0,
        closed: 0
      };
    }
    
    // CRITICAL FIX: Use the tab strategies to ensure consistent counts
    // This ensures the same filtering logic is used for both counts and display
    const availableMarkets = markets.filter(availableTabStrategy);
    const joinedMarkets = markets.filter(joinedTabStrategy);
    const upcomingMarkets = markets.filter(upcomingTabStrategy);
    const closedMarkets = markets.filter(closedTabStrategy);
    
    const counts = {
      available: availableMarkets.length,
      joined: joinedMarkets.length,
      upcoming: upcomingMarkets.length,
      closed: closedMarkets.length
    };
    
    logger.info('Calculated participation counts using tab strategies:', {
      counts,
      participatingTotal: markets.filter(m => m.isParticipating === true).length,
      withPendingContribution: markets.filter(m => m.participationStatus === 'pending_contribution').length,
      activeParticipations: markets.filter(m => m.participationStatus === 'active').length,
      timestamp: Date.now()
    });
    
    return counts;
  }, [markets]);
  
  /**
   * Direct database check for user participation with status information
   */
  const verifyParticipationFromDatabase = useCallback(async (userId: string) => {
    if (!userId) return [];
    
    try {
      const { data, error } = await supabase
        .from('market_participants')
        .select('market_id, status')
        .eq('user_id', userId);
      
      if (error) {
        logger.error('Error fetching participation data from database:', error);
        return [];
      }
      
      logger.info('Verified participations from database with status:', {
        count: data?.length || 0,
        statuses: data?.map(item => item.status).reduce((acc, status) => {
          acc[status] = (acc[status] || 0) + 1;
          return acc;
        }, {} as Record<string, number>),
        participations: data?.slice(0, 5)
      });
      
      return data;
    } catch (error) {
      logger.error('Exception fetching participation data:', error);
      return [];
    }
  }, []);

  /**
   * Clear any pending tab change timeouts
   */
  const clearTabChangeTimeout = useCallback(() => {
    if (tabChangeTimeoutRef.current) {
      clearTimeout(tabChangeTimeoutRef.current);
      tabChangeTimeoutRef.current = null;
      logger.info('Cleared pending tab change timeout');
    }
  }, []);
  
  // Initial calculation of participation counts
  useEffect(() => {
    const counts = getParticipationCounts();
    setParticipationCounts(counts);
    
    // Log the status of each market for debugging
    if (markets && markets.length > 0) {
      const statusCounts: Record<string, number> = {};
      const participationStatusCounts: Record<string, number> = {};
      
      markets.forEach(market => {
        statusCounts[market.status] = (statusCounts[market.status] || 0) + 1;
        participationStatusCounts[market.participationStatus || 'undefined'] = 
          (participationStatusCounts[market.participationStatus || 'undefined'] || 0) + 1;
      });
      
      logger.info('Market status breakdown:', {
        statusCounts,
        participationStatusCounts,
        isParticipatingCount: markets.filter(m => m.isParticipating === true).length,
        total: markets.length
      });
    }
  }, [markets, getParticipationCounts]);
  
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearTabChangeTimeout();
    };
  }, [clearTabChangeTimeout]);
  
  return {
    getParticipationCounts,
    verifyParticipationFromDatabase,
    participationCounts,
    clearTabChangeTimeout
  };
};
