
/**
 * Normalizes region codes to a standard format to ensure consistent lookups
 */
export const normalizeRegionCode = (regionCode: string): string => {
  if (!regionCode) return '';
  
  // Convert to uppercase
  const normalized = regionCode.toUpperCase().trim();
  
  // Map full country names to their codes
  const regionMappings: Record<string, string> = {
    'AUSTRALIA': 'AUS',
    'AU': 'AUS',
    'SOUTH AFRICA': 'RSA',
    'SOUTHAFRICA': 'RSA', 
    'AFRICA': 'RSA',
    'SA': 'RSA',
    'ZA': 'RSA',
    'UNITED KINGDOM': 'UK',
    'ENGLAND': 'UK',
    'GREAT BRITAIN': 'GB',
    'GB': 'UK',
    'UNITED STATES': 'USA',
    'AMERICA': 'USA',
    'US': 'USA'
  };
  
  // Return mapped code if it exists, otherwise return the normalized input
  return regionMappings[normalized] || normalized;
};

/**
 * Returns a list of supported region codes
 */
export const getSupportedRegions = (): string[] => {
  return ['AUS', 'RSA', 'UK', 'USA'];
};

/**
 * Checks if a region is supported
 */
export const isRegionSupported = (regionCode: string): boolean => {
  const normalizedCode = normalizeRegionCode(regionCode);
  return getSupportedRegions().includes(normalizedCode);
};

/**
 * Formats a region code into a human-readable name
 */
export const formatRegionName = (regionCode: string): string => {
  const normalized = normalizeRegionCode(regionCode);
  
  const regionNames: Record<string, string> = {
    'AUS': 'Australia',
    'RSA': 'South Africa',
    'UK': 'United Kingdom',
    'USA': 'United States'
  };
  
  return regionNames[normalized] || regionCode;
};

/**
 * Returns a color for a risk level
 */
export const getRiskLevelColor = (riskLevel: string): string => {
  const riskColors: Record<string, string> = {
    'low': 'green',
    'medium': 'yellow',
    'high': 'orange',
    'critical': 'red'
  };
  
  return riskColors[riskLevel.toLowerCase()] || 'gray';
};

/**
 * Calculates participation progress as a percentage
 */
export const calculateParticipationProgress = (current: number, target: number): number => {
  if (target <= 0) return 0;
  return Math.min(100, Math.round((current / target) * 100));
};

/**
 * Calculates target progress as a percentage
 */
export const calculateTargetProgress = (current: number, target: number): number => {
  if (target <= 0) return 0;
  return Math.min(100, Math.round((current / target) * 100));
};

/**
 * Calculates days remaining until a deadline
 */
export const calculateDaysRemaining = (deadline: string | Date): number => {
  const deadlineDate = deadline instanceof Date ? deadline : new Date(deadline);
  const now = new Date();
  
  // Set both dates to midnight for accurate day calculation
  deadlineDate.setHours(0, 0, 0, 0);
  now.setHours(0, 0, 0, 0);
  
  // Calculate the difference in milliseconds
  const diffMs = deadlineDate.getTime() - now.getTime();
  
  // Convert to days
  return Math.max(0, Math.ceil(diffMs / (1000 * 60 * 60 * 24)));
};

/**
 * Formats currency according to region
 */
export const formatCurrencyForRegion = (amount: number, regionCode: string): string => {
  const normalized = normalizeRegionCode(regionCode);
  
  const currencyByRegion: Record<string, string> = {
    'AUS': 'AUD',
    'RSA': 'ZAR',
    'UK': 'GBP',
    'USA': 'USD'
  };
  
  const currency = currencyByRegion[normalized] || 'USD';
  
  const localeByRegion: Record<string, string> = {
    'AUS': 'en-AU',
    'RSA': 'en-ZA',
    'UK': 'en-GB',
    'USA': 'en-US'
  };
  
  const locale = localeByRegion[normalized] || 'en-US';
  
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(amount);
};

/**
 * Find the corresponding standardized region code
 * This helps prevent issues with case sensitivity and different regional code formats
 */
export const findMatchingRegionCode = (targetRegion: string, availableRegions: Record<string, any>): string | null => {
  if (!targetRegion) return null;
  
  const normalizedTarget = normalizeRegionCode(targetRegion);
  
  // First try direct match with normalized code
  if (availableRegions[normalizedTarget]) {
    return normalizedTarget;
  }
  
  // Try all standardized region codes
  const standardRegions = getSupportedRegions();
  for (const region of standardRegions) {
    if (normalizeRegionCode(region) === normalizedTarget) {
      if (availableRegions[region]) {
        return region;
      }
    }
  }
  
  // Try case-insensitive matching as a fallback
  const regions = Object.keys(availableRegions);
  for (const region of regions) {
    if (normalizeRegionCode(region) === normalizedTarget) {
      return region;
    }
  }
  
  return null;
};
