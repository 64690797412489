
import { supabase } from "@/lib/supabase";
import { logger } from "@/utils/logger";
import { SUPABASE_CONFIG } from "@/lib/supabase";

interface TransactionOptions {
  functionName: string;
  payload: any;
  verificationFn?: (resultData: any) => boolean;
  maxRetries?: number;
  retryDelay?: number;
}

interface TransactionResult<T = any> {
  success: boolean;
  data: T | null;
  error: string | null;
  verified: boolean;
  attemptCount: number;
}

/**
 * Handles edge function transactions with verification and retry capability
 */
export const handleEdgeTransaction = async <T = any>({
  functionName,
  payload,
  verificationFn,
  maxRetries = 2,
  retryDelay = 1000
}: TransactionOptions): Promise<TransactionResult<T>> => {
  let attemptCount = 0;
  const txId = Math.random().toString(36).substring(7);
  
  logger.info(`TX [${txId}]: Starting edge function transaction`, {
    functionName,
    payload,
    maxRetries,
    timestamp: new Date().toISOString()
  });
  
  // Use the configured Supabase URL rather than trying to access the protected property
  const supabaseUrl = SUPABASE_CONFIG.url;
  logger.info(`TX [${txId}]: Using Supabase URL:`, { supabaseUrl });
  
  while (attemptCount <= maxRetries) {
    attemptCount++;
    
    try {
      logger.info(`TX [${txId}]: Attempt ${attemptCount}/${maxRetries + 1}`, {
        functionName
      });
      
      // Call the edge function
      const { data, error } = await supabase.functions.invoke(functionName, {
        body: payload
      });
      
      // Handle explicit errors from the edge function
      if (error) {
        logger.error(`TX [${txId}]: Edge function error`, {
          functionName,
          error,
          attempt: attemptCount,
          supabaseUrl: SUPABASE_CONFIG.url,
          functionEndpoint: `${SUPABASE_CONFIG.url}/functions/v1/${functionName}`
        });
        
        // If we've exceeded retries, return the error
        if (attemptCount > maxRetries) {
          return {
            success: false,
            data: null,
            error: error.message || `Error calling ${functionName}`,
            verified: false,
            attemptCount
          };
        }
        
        // Otherwise wait and retry
        await new Promise(resolve => setTimeout(resolve, retryDelay * attemptCount));
        continue;
      }
      
      // Check if the response indicates success
      if (!data || typeof data !== 'object' || data.success === false) {
        logger.error(`TX [${txId}]: Edge function returned failure response`, {
          functionName,
          data,
          attempt: attemptCount
        });
        
        // If we've exceeded retries, return the error
        if (attemptCount > maxRetries) {
          return {
            success: false,
            data: null,
            error: (data && data.error) ? data.error : `${functionName} returned unsuccessful response`,
            verified: false,
            attemptCount
          };
        }
        
        // Otherwise wait and retry
        await new Promise(resolve => setTimeout(resolve, retryDelay * attemptCount));
        continue;
      }
      
      // If a verification function is provided, call it
      if (verificationFn && !verificationFn(data)) {
        logger.error(`TX [${txId}]: Verification failed`, {
          functionName,
          data,
          attempt: attemptCount
        });
        
        // If we've exceeded retries, return verification failure
        if (attemptCount > maxRetries) {
          return {
            success: false,
            data: data as T,
            error: "Transaction verification failed",
            verified: false,
            attemptCount
          };
        }
        
        // Otherwise wait and retry
        await new Promise(resolve => setTimeout(resolve, retryDelay * attemptCount));
        continue;
      }
      
      // Success case
      logger.info(`TX [${txId}]: Transaction successful`, {
        functionName,
        dataKeys: Object.keys(data),
        verified: verificationFn ? true : "skipped",
        attemptCount
      });
      
      return {
        success: true,
        data: data as T,
        error: null,
        verified: verificationFn ? true : false,
        attemptCount
      };
    } catch (error) {
      logger.error(`TX [${txId}]: Exception in transaction`, {
        functionName,
        error: error instanceof Error ? error.message : String(error),
        stack: error instanceof Error ? error.stack : undefined,
        attempt: attemptCount,
        supabaseUrl: SUPABASE_CONFIG.url,
        functionEndpoint: `${SUPABASE_CONFIG.url}/functions/v1/${functionName}`
      });
      
      // If we've exceeded retries, return the caught error
      if (attemptCount > maxRetries) {
        return {
          success: false,
          data: null,
          error: error instanceof Error ? error.message : String(error),
          verified: false,
          attemptCount
        };
      }
      
      // Otherwise wait and retry
      await new Promise(resolve => setTimeout(resolve, retryDelay * attemptCount));
    }
  }
  
  // This should never execute but is here for completeness
  return {
    success: false,
    data: null,
    error: `Transaction failed after ${attemptCount} attempts`,
    verified: false,
    attemptCount
  };
};
