
import { DashboardSidebar } from "@/components/DashboardSidebar";
import { Button } from "@/components/ui/button";
import { RefreshCcw, Archive, Activity, AlertTriangle, PlusCircle, FileText, Settings2, BarChart3, History, Filter, Download, Clock, TrendingUp, Search } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import MarketsOverview from "@/components/admin/dashboard/MarketsOverview";
import AdminMarketsList from "@/components/admin/markets/AdminMarketsList";
import MarketFilters from "@/components/markets/MarketFilters";
import { CreateMarketDialog } from "@/components/admin/markets/CreateMarketDialog";
import { useAuth } from "@/contexts/AuthContext";
import { useAdminAccess } from "@/hooks/admin/use-admin-access";
import { useMarketsManagement } from "@/hooks/admin/use-markets-management";
import { MarketError } from "@/components/admin/markets/MarketError";
import { useToast } from "@/hooks/use-toast";
import { MarketPerformanceChart } from "@/components/admin/dashboard/MarketPerformanceChart";
import { useState } from "react";
import { GlobalHistoryTab } from "@/components/admin/markets/tabs/GlobalHistoryTab";
import { withErrorBoundary } from "@/components/common/withErrorBoundary";
import { MarketStatsCard } from "@/components/admin/markets/components/MarketStatsCard";
import { Input } from "@/components/ui/input";

const MarketManagement = () => {
  const { session } = useAuth();
  const { toast } = useToast();
  const [advancedMode, setAdvancedMode] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  
  useAdminAccess(session);

  const {
    markets,
    isLoading,
    error,
    searchQuery,
    selectedRegion,
    riskLevel,
    setSearchQuery,
    setSelectedRegion,
    setRiskLevel,
    handleRefresh,
    refetch,
    handleParticipate
  } = useMarketsManagement(session);

  if (isLoading) {
    return (
      <DashboardSidebar isAdmin>
        <div className="p-6 space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <Skeleton className="h-8 w-64" />
              <Skeleton className="h-4 w-40 mt-2" />
            </div>
            <div className="flex space-x-2">
              <Skeleton className="h-10 w-32" />
              <Skeleton className="h-10 w-32" />
            </div>
          </div>
          
          <div className="grid gap-4 md:grid-cols-5">
            {Array.from({ length: 5 }).map((_, i) => (
              <Card key={i}>
                <CardHeader className="pb-2">
                  <Skeleton className="h-4 w-24" />
                </CardHeader>
                <CardContent>
                  <Skeleton className="h-8 w-16" />
                </CardContent>
              </Card>
            ))}
          </div>
          
          <Skeleton className="h-12 w-full" />
          <Skeleton className="h-[400px] w-full" />
        </div>
      </DashboardSidebar>
    );
  }

  if (error) {
    return (
      <DashboardSidebar isAdmin>
        <div className="p-6">
          <MarketError onRetry={refetch} />
        </div>
      </DashboardSidebar>
    );
  }

  const activeMarkets = markets?.filter(m => m.status === 'active')?.length || 0;
  const highRiskMarkets = markets?.filter(m => m.market_risk_level === 'high')?.length || 0;
  const totalParticipants = markets?.reduce((acc, m) => acc + (m.active_participants || 0), 0) || 0;
  const totalMarketValue = markets?.reduce((acc, m) => acc + m.current_value, 0) || 0;
  const averageGrowthRate = markets?.length 
    ? markets.reduce((acc, m) => acc + m.growth_rate, 0) / markets.length 
    : 0;
  const completedMarkets = markets?.filter(m => m.status === 'completed')?.length || 0;
  const recentlyCreatedMarkets = markets?.filter(m => {
    const creationDate = new Date(m.created_at);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return creationDate > thirtyDaysAgo;
  })?.length || 0;

  const handleContributionSubmitted = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error("Error refetching markets:", error);
    }
  };

  const handleExportData = () => {
    // Implementation for exporting market data
    toast({
      title: "Export initiated",
      description: "Your market data is being prepared for download.",
    });
  };

  return (
    <DashboardSidebar isAdmin>
      <div className="flex-1 w-full">
        <div className="sticky top-0 z-10 border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex flex-col gap-4 px-6 py-4">
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
              <div className="space-y-1">
                <h1 className="text-3xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                  Market Management
                </h1>
                <p className="text-muted-foreground text-sm">
                  Manage and monitor all market activities
                </p>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <Button 
                  variant="outline" 
                  size="sm"
                  className="gap-2 hover:bg-primary/10 hover:text-primary transition-colors"
                  onClick={() => setAdvancedMode(!advancedMode)}
                >
                  <Settings2 className="h-4 w-4" />
                  {advancedMode ? "Simple Mode" : "Advanced Mode"}
                </Button>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="gap-2 hover:bg-primary/10 hover:text-primary transition-colors"
                  onClick={handleExportData}
                >
                  <Download className="h-4 w-4" />
                  Export
                </Button>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="gap-2 hover:bg-primary/10 hover:text-primary transition-colors"
                  onClick={handleRefresh}
                >
                  <RefreshCcw className="h-4 w-4" />
                  Refresh
                </Button>
                <CreateMarketDialog onMarketCreated={refetch} />
              </div>
            </div>
          </div>
        </div>

        <div className="p-6 space-y-6">
          {advancedMode && (
            <Card className="border-primary/10">
              <CardHeader className="pb-2">
                <CardTitle className="text-xl flex items-center gap-2">
                  <BarChart3 className="h-5 w-5 text-primary" />
                  Performance Overview
                </CardTitle>
              </CardHeader>
              <CardContent>
                <MarketPerformanceChart 
                  markets={markets || []} 
                  totalValue={totalMarketValue}
                  growthRate={averageGrowthRate}
                />
              </CardContent>
            </Card>
          )}

          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            <MarketStatsCard
              title="Active Markets"
              value={activeMarkets}
              icon={Activity}
              iconColor="text-green-500"
              badgeText={`Total: ${markets?.length || 0}`}
            />
            
            <MarketStatsCard
              title="Total Participants"
              value={totalParticipants}
              icon={Archive}
              iconColor="text-blue-500"
            />
            
            <MarketStatsCard
              title="High Risk Markets"
              value={highRiskMarkets}
              icon={AlertTriangle}
              iconColor="text-yellow-500"
              badgeText={highRiskMarkets > 0 ? "Needs Attention" : undefined}
              badgeVariant={highRiskMarkets > 0 ? "destructive" : undefined}
            />
            
            <MarketStatsCard
              title="Total Market Value"
              value={`$${totalMarketValue.toLocaleString()}`}
              icon={PlusCircle}
              iconColor="text-green-500"
              subValue={`Avg Growth: ${averageGrowthRate.toFixed(2)}%`}
            />
            
            <MarketStatsCard
              title="Recently Created"
              value={recentlyCreatedMarkets}
              icon={Clock}
              iconColor="text-blue-500"
              subValue="Last 30 days"
            />
          </div>

          <Tabs defaultValue="overview" className="space-y-6">
            <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-between">
              <TabsList className="bg-card">
                <TabsTrigger value="overview" className="gap-2">
                  <TrendingUp className="h-4 w-4" />
                  Overview
                </TabsTrigger>
                <TabsTrigger value="markets" className="gap-2">
                  <BarChart3 className="h-4 w-4" />
                  Markets List
                </TabsTrigger>
                {advancedMode && <TabsTrigger value="performance" className="gap-2">
                  <Activity className="h-4 w-4" />
                  Performance
                </TabsTrigger>}
                <TabsTrigger value="history" className="gap-2">
                  <History className="h-4 w-4" />
                  History
                </TabsTrigger>
              </TabsList>
              
              <div className="flex items-center gap-2">
                <div className="relative w-60">
                  <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                  <Input
                    placeholder="Search markets..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-8 h-9"
                  />
                </div>
                <Button variant="outline" size="sm" className="gap-1">
                  <Filter className="h-3.5 w-3.5" />
                  Filters
                </Button>
              </div>
            </div>

            <MarketFilters
              searchQuery={searchQuery}
              selectedRegion={selectedRegion}
              riskLevel={riskLevel}
              onSearchChange={setSearchQuery}
              onRegionChange={setSelectedRegion}
              onRiskLevelChange={setRiskLevel}
            />

            <TabsContent value="overview" className="space-y-6 mt-6">
              <MarketsOverview markets={markets || []} />
            </TabsContent>

            <TabsContent value="markets" className="mt-6">
              <AdminMarketsList
                markets={markets}
                isLoading={isLoading}
                showConfiguration={true}
                onRefresh={async () => {
                  try {
                    await refetch();
                  } catch (error) {
                    console.error("Error refreshing markets:", error);
                  }
                }}
              />
            </TabsContent>

            {advancedMode && (
              <TabsContent value="performance" className="mt-6">
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-xl flex items-center gap-2">
                      <FileText className="h-5 w-5 text-primary" />
                      Market Performance Metrics
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-4">
                      <p className="text-muted-foreground">
                        Detailed performance analysis of all markets across regions.
                      </p>
                      
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <Card className="bg-muted/40">
                          <CardContent className="p-4">
                            <p className="text-xs text-muted-foreground">Completed Markets</p>
                            <p className="text-xl font-semibold">{completedMarkets}</p>
                          </CardContent>
                        </Card>
                        
                        <Card className="bg-muted/40">
                          <CardContent className="p-4">
                            <p className="text-xs text-muted-foreground">Average Growth</p>
                            <p className="text-xl font-semibold">{averageGrowthRate.toFixed(2)}%</p>
                          </CardContent>
                        </Card>
                        
                        <Card className="bg-muted/40">
                          <CardContent className="p-4">
                            <p className="text-xs text-muted-foreground">Success Rate</p>
                            <p className="text-xl font-semibold">
                              {markets?.length ? ((completedMarkets / markets.length) * 100).toFixed(1) : 0}%
                            </p>
                          </CardContent>
                        </Card>
                        
                        <Card className="bg-muted/40">
                          <CardContent className="p-4">
                            <p className="text-xs text-muted-foreground">Avg. Participants</p>
                            <p className="text-xl font-semibold">
                              {markets?.length ? Math.round(totalParticipants / markets.length) : 0}
                            </p>
                          </CardContent>
                        </Card>
                      </div>
                      
                      <div className="rounded-lg border p-4 text-center">
                        <p>Click on a market in the Overview tab to see detailed performance metrics.</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
            )}

            <TabsContent value="history" className="mt-6">
              <GlobalHistoryTab />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </DashboardSidebar>
  );
};

export default withErrorBoundary(MarketManagement);
