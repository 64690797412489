
import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { TimePickerDemo } from "./time-picker";

interface DatePickerProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  disabled?: boolean;
  showTimePicker?: boolean;
}

export function DatePicker({
  date,
  setDate,
  disabled = false,
  showTimePicker = true,
}: DatePickerProps) {
  const handleDateSelect = (selectedDate: Date | undefined) => {
    if (!selectedDate) {
      setDate(undefined);
      return;
    }
    
    // When selecting a new date, set time to current time + 5 minutes if it's today
    const now = new Date();
    if (isSameDay(selectedDate, now)) {
      selectedDate.setHours(now.getHours());
      selectedDate.setMinutes(now.getMinutes() + 5);
      selectedDate.setSeconds(0);
      selectedDate.setMilliseconds(0);
    } else {
      // For future dates, set time to noon
      selectedDate.setHours(12);
      selectedDate.setMinutes(0);
      selectedDate.setSeconds(0);
      selectedDate.setMilliseconds(0);
    }
    
    setDate(selectedDate);
  };
  
  const isSameDay = (date1: Date, date2: Date): boolean => {
    return date1.getDate() === date2.getDate() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getFullYear() === date2.getFullYear();
  };
  
  // Check if a date is in the past
  const isPastDate = (day: Date): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return day < today;
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground",
            disabled && "opacity-50 cursor-not-allowed"
          )}
          disabled={disabled}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, showTimePicker ? "PPP HH:mm" : "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="flex flex-col">
          <Calendar
            mode="single"
            selected={date}
            onSelect={handleDateSelect}
            disabled={isPastDate}
            initialFocus
            className="p-3 pointer-events-auto border-b border-border"
          />
          {showTimePicker && date && (
            <div className="p-3">
              <TimePickerDemo date={date} setDate={setDate} />
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
