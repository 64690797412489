
import { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabase';
import { logger } from '@/utils/logger';
import { MARKET_SYNC_EVENTS, addMarketSyncListeners } from '@/utils/market/sync/market-sync-events';
import { normalizeRegionCode } from '@/utils/market/formatters';

interface Member {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  status: string | null;
  balance: number;
  regional_targets?: Record<string, number>;
  regional_settings?: Record<string, RegionalSetting>;
  created_at?: string;
}

interface RegionalSetting {
  baseTarget: number;
  adjustment: number;
  factor: number;
  adjustedTarget: number;
}

export const useMembersOverview = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [regionTargetsStatus, setRegionTargetsStatus] = useState<Record<string, string>>({});
  const [forceRefreshCounter, setForceRefreshCounter] = useState(0);
  const [pendingEdits, setPendingEdits] = useState<Record<string, Record<string, RegionalSetting>>>({});

  // Function to load cached members data if available
  const loadCachedMembers = useCallback(() => {
    try {
      const cachedData = localStorage.getItem('members_overview_data');
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        const cachedTimestamp = new Date(parsedData.timestamp);
        const now = new Date();
        
        // Use cached data if it's less than 2 minutes old
        if ((now.getTime() - cachedTimestamp.getTime()) < 2 * 60 * 1000) {
          setMembers(parsedData.members);
          setLastUpdated(cachedTimestamp);
          logger.info('Using cached members data', {
            count: parsedData.members.length,
            timestamp: cachedTimestamp
          });
          return true;
        }
      }
      return false;
    } catch (err) {
      logger.error('Error loading cached members data:', err);
      return false;
    }
  }, []);

  // Load pending edits from localStorage
  const loadPendingEdits = useCallback(() => {
    try {
      const savedEdits = localStorage.getItem('member_regional_target_edits');
      if (savedEdits) {
        setPendingEdits(JSON.parse(savedEdits));
        logger.info('Loaded pending target edits from localStorage');
      }
    } catch (err) {
      logger.error('Error loading pending edits:', err);
    }
  }, []);

  // Save pending edits to localStorage
  const savePendingEdits = useCallback((edits: Record<string, Record<string, RegionalSetting>>) => {
    try {
      localStorage.setItem('member_regional_target_edits', JSON.stringify(edits));
      setPendingEdits(edits);
    } catch (err) {
      logger.error('Error saving pending edits:', err);
    }
  }, []);

  const loadRegionalTargets = useCallback(async (memberIds: string[]) => {
    if (!memberIds.length) return {};
    
    try {
      // Check for cached regional targets
      const cachedTargets = localStorage.getItem('members_regional_targets');
      if (cachedTargets) {
        const parsedTargets = JSON.parse(cachedTargets);
        const cachedTimestamp = new Date(parsedTargets.timestamp);
        const now = new Date();
        
        // Use cached targets if less than 2 minutes old
        if ((now.getTime() - cachedTimestamp.getTime()) < 2 * 60 * 1000) {
          logger.info('Using cached regional targets', {
            membersCount: Object.keys(parsedTargets.data).length,
            timestamp: cachedTimestamp
          });
          return parsedTargets.data;
        }
      }
      
      // Get all regional targets for these members in one query
      const { data, error } = await supabase
        .from('regional_targets')
        .select('user_id, region_code, base_target, adjustment, factor, target_value')
        .in('user_id', memberIds);
        
      if (error) {
        throw error;
      }
      
      if (!data || data.length === 0) {
        return {};
      }
      
      // Group targets by user_id with full regional settings
      const targetsByUser: Record<string, Record<string, any>> = {};
      
      data.forEach(item => {
        const userId = item.user_id;
        const regionCode = normalizeRegionCode(item.region_code);
        
        if (!targetsByUser[userId]) {
          targetsByUser[userId] = {
            regional_targets: {},
            regional_settings: {}
          };
        }
        
        // Store the target value for backward compatibility
        targetsByUser[userId].regional_targets[regionCode] = item.target_value;
        
        // Store the full settings object for the new enhanced functionality
        targetsByUser[userId].regional_settings[regionCode] = {
          baseTarget: item.base_target,
          adjustment: item.adjustment,
          factor: item.factor,
          adjustedTarget: item.target_value
        };
      });
      
      // Cache the regional targets
      localStorage.setItem('members_regional_targets', JSON.stringify({
        data: targetsByUser,
        timestamp: new Date().toISOString()
      }));
      
      return targetsByUser;
    } catch (err) {
      logger.error('Error loading regional targets for members:', err);
      
      // Try to use cached data even if it's older as a fallback
      try {
        const cachedTargets = localStorage.getItem('members_regional_targets');
        if (cachedTargets) {
          const parsedTargets = JSON.parse(cachedTargets);
          logger.info('Using expired cached regional targets as fallback');
          return parsedTargets.data;
        }
      } catch (cacheErr) {
        logger.error('Error loading fallback cached targets:', cacheErr);
      }
      
      return {};
    }
  }, []);

  const loadMembers = useCallback(async (skipCache = false) => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Load pending edits first to ensure we have them available
      loadPendingEdits();
      
      // Check if we can use cached data (only if not explicitly skipping cache)
      if (!skipCache && loadCachedMembers()) {
        setIsLoading(false);
        return;
      }
      
      // First get all non-admin users
      const { data: userData, error: userError } = await supabase
        .from('profiles')
        .select('id, email, first_name, last_name, status, balance, created_at')
        .eq('is_admin', false)
        .order('created_at', { ascending: false });
        
      if (userError) throw userError;
      
      if (!userData || userData.length === 0) {
        setMembers([]);
        setIsLoading(false);
        setLastUpdated(new Date());
        return;
      }
      
      // Then load regional targets for all these users
      const memberIds = userData.map(user => user.id);
      const targetsByUser = await loadRegionalTargets(memberIds);
      
      // Combine the data
      const membersWithTargets = userData.map(user => {
        const userId = user.id;
        const userTargets = targetsByUser[userId] || { regional_targets: {}, regional_settings: {} };
        
        // Apply any pending edits for this user
        if (pendingEdits[userId]) {
          Object.entries(pendingEdits[userId]).forEach(([region, settings]) => {
            if (!userTargets.regional_settings) {
              userTargets.regional_settings = {};
            }
            
            userTargets.regional_settings[region] = settings;
            // Also update the target value for backward compatibility
            if (!userTargets.regional_targets) {
              userTargets.regional_targets = {};
            }
            userTargets.regional_targets[region] = settings.adjustedTarget;
          });
        }
        
        return {
          ...user,
          regional_targets: userTargets.regional_targets || {},
          regional_settings: userTargets.regional_settings || {}
        };
      });
      
      // Save to local storage for cache
      localStorage.setItem('members_overview_data', JSON.stringify({
        members: membersWithTargets,
        timestamp: new Date().toISOString()
      }));
      
      setMembers(membersWithTargets);
      setLastUpdated(new Date());
      
      // Set region target status
      const targetStatus: Record<string, string> = {};
      membersWithTargets.forEach(member => {
        if (member.regional_settings) {
          Object.keys(member.regional_settings).forEach(region => {
            targetStatus[region] = 'loaded';
          });
        }
      });
      setRegionTargetsStatus(targetStatus);
      
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      logger.error('Error loading members overview:', err);
      setError(new Error(errorMessage));
      
      // Try to use cached data as fallback even if it's older
      if (loadCachedMembers()) {
        logger.info('Using expired cached members data as fallback after error');
      }
    } finally {
      setIsLoading(false);
    }
  }, [loadCachedMembers, loadRegionalTargets, loadPendingEdits, pendingEdits]);
  
  // Force a complete refresh of data
  const forceRefresh = useCallback(async () => {
    localStorage.removeItem('members_overview_data');
    localStorage.removeItem('members_regional_targets');
    await loadMembers(true); // Skip cache
    setForceRefreshCounter(prev => prev + 1);
  }, [loadMembers]);

  // Save regional target settings for a specific member
  const updateMemberRegionalTarget = useCallback(async (
    memberId: string,
    region: string,
    settings: RegionalSetting
  ): Promise<boolean> => {
    try {
      const normalizedRegion = normalizeRegionCode(region);
      logger.info(`Updating regional target for member ${memberId}, region ${normalizedRegion}`, settings);
      
      // First update local state to provide immediate feedback
      const newPendingEdits = { ...pendingEdits };
      
      if (!newPendingEdits[memberId]) {
        newPendingEdits[memberId] = {};
      }
      
      newPendingEdits[memberId][normalizedRegion] = settings;
      savePendingEdits(newPendingEdits);
      
      // Update the database
      const { error } = await supabase
        .from('regional_targets')
        .upsert({
          user_id: memberId,
          region_code: normalizedRegion,
          base_target: settings.baseTarget,
          adjustment: settings.adjustment,
          factor: settings.factor,
          target_value: settings.adjustedTarget,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,region_code'
        });
      
      if (error) {
        logger.error(`Error saving regional target for member ${memberId}:`, error);
        return false;
      }
      
      // Update members array with the new settings
      setMembers(prev => prev.map(member => {
        if (member.id === memberId) {
          return {
            ...member,
            regional_targets: {
              ...member.regional_targets,
              [normalizedRegion]: settings.adjustedTarget
            },
            regional_settings: {
              ...member.regional_settings,
              [normalizedRegion]: settings
            }
          };
        }
        return member;
      }));
      
      // Dispatch event for other components to update
      window.dispatchEvent(new CustomEvent('user-regional-targets-synced', {
        detail: {
          userId: memberId,
          region: normalizedRegion,
          settings,
          timestamp: new Date().toISOString()
        }
      }));
      
      return true;
    } catch (err) {
      logger.error('Error updating member regional target:', err);
      return false;
    }
  }, [pendingEdits, savePendingEdits]);

  // Sync all pending edits to the database
  const syncPendingEdits = useCallback(async (): Promise<boolean> => {
    try {
      logger.info('Syncing all pending edits to the database');
      
      for (const [memberId, regions] of Object.entries(pendingEdits)) {
        for (const [region, settings] of Object.entries(regions)) {
          const { error } = await supabase
            .from('regional_targets')
            .upsert({
              user_id: memberId,
              region_code: region,
              base_target: settings.baseTarget,
              adjustment: settings.adjustment,
              factor: settings.factor,
              target_value: settings.adjustedTarget,
              updated_at: new Date().toISOString()
            }, {
              onConflict: 'user_id,region_code'
            });
          
          if (error) {
            logger.error(`Error syncing target for member ${memberId}, region ${region}:`, error);
          }
        }
      }
      
      // Clear pending edits after successful sync
      savePendingEdits({});
      
      // Dispatch event for bulk update completion
      window.dispatchEvent(new CustomEvent(MARKET_SYNC_EVENTS.ALL_MEMBER_TARGETS_REFRESHED, {
        detail: {
          timestamp: new Date().toISOString(),
          forceRefresh: true
        }
      }));
      
      return true;
    } catch (err) {
      logger.error('Error syncing pending edits:', err);
      return false;
    }
  }, [pendingEdits, savePendingEdits]);

  useEffect(() => {
    loadMembers();
    loadPendingEdits();
    
    // Listen for events that should trigger a refresh
    const cleanupListeners = addMarketSyncListeners(
      [
        MARKET_SYNC_EVENTS.REGIONAL_TARGETS_UPDATED,
        MARKET_SYNC_EVENTS.ALL_MEMBER_TARGETS_REFRESHED,
        MARKET_SYNC_EVENTS.USER_REGIONAL_TARGETS_SYNCED,
        MARKET_SYNC_EVENTS.SYSTEM_TARGETS_LOADED,
        MARKET_SYNC_EVENTS.DASHBOARD_TARGETS_UPDATED
      ],
      () => {
        // Refresh data when targets are updated
        loadMembers();
      }
    );
    
    return () => {
      cleanupListeners();
    };
  }, [loadMembers, forceRefreshCounter, loadPendingEdits]);

  return {
    members,
    isLoading,
    error,
    refreshMembers: loadMembers,
    forceRefresh,
    lastUpdated,
    regionTargetsStatus,
    updateMemberRegionalTarget,
    syncPendingEdits,
    pendingEdits
  };
};
