import { useCallback, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Market } from "@/types/market";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { Label } from "@/components/ui/label";
import { calculateProgressiveContribution, getPreviousFailedContributions } from "@/utils/market/calculate-progressive-contribution";
import { submitContribution } from "@/utils/market/submit-contribution";
import { useMarketContributions } from "@/hooks/use-market-contributions";
import { Loader2 } from "lucide-react";
import { formatCurrency } from "@/utils/formatters";
import { logger } from "@/utils/logger";
import { ContributionHistoryTable } from "./ContributionHistoryTable";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle, AlertTriangle, CheckCircle2, Info } from "lucide-react";
import { useMarketStatus } from "@/hooks/markets/use-market-status";

interface ContributionFormProps {
  market: Market;
  onContributionSubmitted: () => void;
}

export const MarketContributionForm = ({
  market,
  onContributionSubmitted
}: ContributionFormProps) => {
  const [oddsValue, setOddsValue] = useState("");
  const [calculatedContribution, setCalculatedContribution] = useState<number | null>(null);
  const [requiredContribution, setRequiredContribution] = useState<number | null>(null);
  const [previousFailedTotal, setPreviousFailedTotal] = useState<number>(0);
  const [contributionCount, setContributionCount] = useState(0);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reachedMaxParticipations, setReachedMaxParticipations] = useState(false);
  const [marketClosed, setMarketClosed] = useState(false);
  const { toast } = useToast();
  const { session } = useAuth();
  const {
    contributions,
    isLoading,
    refetch
  } = useMarketContributions(market.id, session?.user?.id || "");
  const { updateMarketStatus } = useMarketStatus();

  useEffect(() => {
    const loadPreviousFailedContributions = async () => {
      if (!session?.user?.id || !market.id) return;
      setIsLoadingPrevious(true);
      try {
        const failedContributions = await getPreviousFailedContributions(market.id, session.user.id);
        const failedTotal = failedContributions.reduce((sum, amount) => sum + amount, 0);
        setPreviousFailedTotal(failedTotal);
        logger.info('Loaded previous failed contributions', {
          marketId: market.id,
          failedCount: failedContributions.length,
          failedTotal
        });
      } catch (error) {
        logger.error('Error loading previous failed contributions', error);
      } finally {
        setIsLoadingPrevious(false);
      }
    };
    loadPreviousFailedContributions();
  }, [market.id, session?.user?.id, contributions]);

  useEffect(() => {
    if (contributions && contributions.length > 0) {
      setContributionCount(contributions.length);

      const maxParticipations = market.max_participations_per_user || 1;
      const hasReachedMax = contributions.length >= maxParticipations;
      setReachedMaxParticipations(hasReachedMax);

      const allFailed = contributions.every(c => c.status === 'failed');

      if (hasReachedMax && allFailed && 
          market.status !== 'closed_failed' && 
          market.status !== 'failed_final' && 
          market.status !== 'failed') {
        handleMarkMarketAsFailed();
      }

      const hasSuccessContribution = contributions.some(c => c.status === 'success' || c.status === 'closed_successful');
      const isMarketClosed = hasSuccessContribution || 
        ['closed_successful', 'closed', 'completed', 'closed_failed', 'failed_final', 'failed'].includes(market.status);
      
      setMarketClosed(isMarketClosed);
    } else {
      setMarketClosed(['closed_successful', 'closed', 'completed', 'closed_failed', 'failed_final', 'failed'].includes(market.status));
    }
  }, [contributions, market.max_participations_per_user, market.status, market.id]);

  const handleMarkMarketAsFailed = async () => {
    try {
      logger.info('Auto-marking market as failed due to maximum failed attempts', {
        marketId: market.id,
        contributionCount,
        maxParticipations: market.max_participations_per_user,
        currentStatus: market.status
      });
      
      const result = await updateMarketStatus(market.id, 'closed_failed');
      
      if (result.success) {
        logger.info('Successfully marked market as failed', {
          marketId: market.id,
          status: 'closed_failed'
        });
        
        toast({
          title: "Market closed",
          description: "Market has been marked as failed due to maximum failed contribution attempts",
          variant: "default"
        });

        setMarketClosed(true);
        onContributionSubmitted();
      } else {
        logger.error('Failed to mark market as failed', {
          marketId: market.id,
          error: result.message
        });
      }
    } catch (error) {
      logger.error('Error marking market as failed', {
        marketId: market.id,
        error
      });
    }
  };

  const handleOddsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOddsValue(e.target.value);
    setCalculatedContribution(null);
    setRequiredContribution(null);
  };

  const calculateMarketContribution = useCallback(async () => {
    if (!oddsValue || isNaN(Number(oddsValue)) || Number(oddsValue) <= 0) {
      toast({
        title: "Invalid odds",
        description: "Please enter valid odds (greater than 0)",
        variant: "destructive"
      });
      return;
    }

    if (!session?.user?.id) {
      toast({
        title: "Authentication required",
        description: "Please sign in to calculate contributions",
        variant: "destructive"
      });
      return;
    }

    setIsCalculating(true);
    try {
      const numericOdds = Number(oddsValue);
      const previousFailed = await getPreviousFailedContributions(market.id, session.user.id);

      const result = await calculateProgressiveContribution(
        market.adjustment_factor || 1.07, 
        market.target_value, 
        numericOdds, 
        0, // No input value needed, since we're calculating it
        previousFailed
      );

      if (result.success) {
        setCalculatedContribution(result.contribution || 0);
        setRequiredContribution(result.requiredContribution || 0);
        setPreviousFailedTotal(result.previousFailedTotal || 0);

        toast({
          title: "Contribution calculated",
          description: `Required contribution: ${formatCurrency(result.requiredContribution || 0)}`,
          variant: "default"
        });
      } else {
        toast({
          title: "Calculation error",
          description: result.message || "Failed to calculate contribution",
          variant: "destructive"
        });
      }
    } catch (error) {
      console.error("Error calculating contribution:", error);
      toast({
        title: "Calculation error",
        description: "An unexpected error occurred",
        variant: "destructive"
      });
    } finally {
      setIsCalculating(false);
    }
  }, [oddsValue, market.adjustment_factor, market.target_value, market.id, session?.user?.id, toast]);

  const handleMarkMarketAsSuccess = async () => {
    try {
      logger.info('Auto-marking market as successful due to successful contribution', {
        marketId: market.id,
        contributionCount,
        currentStatus: market.status
      });
      
      const result = await updateMarketStatus(market.id, 'closed_successful');
      
      if (result.success) {
        logger.info('Successfully marked market as successful', {
          marketId: market.id,
          status: 'closed_successful'
        });
        
        toast({
          title: "Market closed",
          description: "Market has been marked as successful due to meeting the target",
          variant: "default"
        });

        setMarketClosed(true);
        onContributionSubmitted();
      } else {
        logger.error('Failed to mark market as successful', {
          marketId: market.id,
          error: result.message
        });
      }
    } catch (error) {
      logger.error('Error marking market as successful', {
        marketId: market.id,
        error
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    if (!calculatedContribution || !requiredContribution) {
      toast({
        title: "Missing calculation",
        description: "Please calculate your contribution first",
        variant: "destructive"
      });
      return;
    }
    if (!session?.user?.id) {
      toast({
        title: "Authentication required",
        description: "Please sign in to submit contributions",
        variant: "destructive"
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const numericOdds = Number(oddsValue);
      
      if (isNaN(numericOdds) || numericOdds <= 0) {
        throw new Error("Invalid odds value");
      }
      
      logger.info(`Submitting progressive contribution for market ${market.id}`, {
        userId: session.user.id,
        odds: numericOdds,
        calculatedContribution,
        requiredContribution,
        previousFailedTotal,
        nextRound: contributionCount + 1
      });
      
      const nextRound = contributionCount + 1;

      const result = await submitContribution(
        market,
        session.user.id,
        numericOdds,
        calculatedContribution,
        requiredContribution,
        nextRound,
        previousFailedTotal
      );
      
      if (!result.success) {
        throw new Error(result.message || "Failed to submit contribution. Please try again.");
      }

      setOddsValue("");
      setCalculatedContribution(null);
      setRequiredContribution(null);

      setContributionCount(prevCount => prevCount + 1);
      logger.info(`Contribution submitted successfully for market ${market.id}`, {
        contributionId: result.contributionId
      });

      toast({
        title: "Contribution submitted",
        description: "Your contribution has been submitted successfully",
        variant: "default"
      });

      refetch();

      onContributionSubmitted();

      const isSuccess = calculatedContribution >= market.target_value;
      
      if (isSuccess) {
        logger.info('Contribution was successful, marking market as successful', {
          marketId: market.id,
          contributionAmount: calculatedContribution,
          requiredAmount: requiredContribution,
          targetValue: market.target_value
        });
        
        await handleMarkMarketAsSuccess();
        setMarketClosed(true);
      } else if (nextRound >= (market.max_participations_per_user || 1)) {
        logger.info('Max participation attempts reached with failed contribution', {
          marketId: market.id,
          maxAttempts: market.max_participations_per_user,
          currentAttempt: nextRound
        });
        
        await handleMarkMarketAsFailed();
        setMarketClosed(true);
      }
    } catch (error) {
      logger.error("Error submitting contribution:", error);
      toast({
        title: "Submission error",
        description: error instanceof Error ? error.message : "An unexpected error occurred",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-6">
      {isLoadingPrevious ? (
        <div className="flex justify-center items-center py-4">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <span className="ml-2">Loading contribution history...</span>
        </div>
      ) : (
        <>
          <Alert variant="default" className="mb-4">
            <Info className="h-5 w-5" />
            <AlertTitle>Progressive Loss Recovery Model</AlertTitle>
            <AlertDescription>
              Required contribution is calculated using the formula:<br/>
              ((Target + Previous Failed) ÷ Odds) × {market.adjustment_factor || 1.07} adjustment factor
            </AlertDescription>
          </Alert>

          {marketClosed ? (
            <Alert variant={market.status === 'closed_successful' ? "success" : "destructive"}>
              {market.status === 'closed_successful' ? (
                <CheckCircle2 className="h-5 w-5" />
              ) : (
                <AlertCircle className="h-5 w-5" />
              )}
              <AlertTitle>
                {market.status === 'closed_successful' 
                  ? "Market Successfully Closed" 
                  : "Market Closed - Failed"}
              </AlertTitle>
              <AlertDescription>
                {market.status === 'closed_successful' 
                  ? "Congratulations! You've successfully met the target for this market." 
                  : "This market has been closed due to reaching maximum contribution attempts without success."}
              </AlertDescription>
            </Alert>
          ) : reachedMaxParticipations ? (
            <Alert variant="warning">
              <AlertTriangle className="h-5 w-5" />
              <AlertTitle>Maximum Attempts Reached</AlertTitle>
              <AlertDescription>
                You have reached the maximum number of contribution attempts for this market.
                {contributions.every(c => c.status === 'failed') && 
                  " Since all attempts have failed, the market will be marked as closed."}
              </AlertDescription>
            </Alert>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <Label htmlFor="oddsInput">Odds Value</Label>
                  <Input 
                    id="oddsInput" 
                    type="number" 
                    placeholder="Enter odds (e.g., 1.5)" 
                    value={oddsValue} 
                    onChange={handleOddsChange} 
                    className="mt-1"
                    disabled={isCalculating || isSubmitting}
                    step="0.01"
                    min="0.01"
                  />
                  <p className="text-xs text-muted-foreground mt-1">
                    Higher odds = lower required contribution
                  </p>
                </div>
              </div>

              <div className="flex justify-end">
                <Button 
                  type="button" 
                  variant="secondary" 
                  onClick={calculateMarketContribution} 
                  disabled={isCalculating || isSubmitting || !oddsValue}
                >
                  {isCalculating ? <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Calculating...
                    </> : "Calculate Required Contribution"}
                </Button>
              </div>

              {calculatedContribution !== null && requiredContribution !== null && <div className="bg-muted p-4 rounded-md">
                  <h3 className="font-medium mb-2">Contribution Details</h3>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>Required contribution:</div>
                    <div className="font-semibold">{formatCurrency(requiredContribution)}</div>
                    
                    <div>Actual contribution:</div>
                    <div className="font-semibold">{formatCurrency(calculatedContribution)}</div>
                    
                    <div>Odds:</div>
                    <div className="font-semibold">{Number(oddsValue).toFixed(2)}</div>
                    
                    {previousFailedTotal > 0 && <>
                        <div>Previous failed total:</div>
                        <div className="font-semibold">{formatCurrency(previousFailedTotal)}</div>
                      </>}
                    
                    <div>Target value:</div>
                    <div className="font-semibold">{formatCurrency(market.target_value)}</div>
                    
                    <div>Status:</div>
                    <div className={`font-semibold ${calculatedContribution >= market.target_value ? "text-green-600" : "text-red-600"}`}>
                      {calculatedContribution >= market.target_value ? "Will Succeed" : "Will Fail"}
                    </div>
                  </div>
                </div>}

              <Button type="submit" className="w-full" disabled={calculatedContribution === null || requiredContribution === null || isCalculating || isSubmitting}>
                {isSubmitting ? <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Submitting...
                  </> : "Submit Contribution"}
              </Button>
              
              <div className="text-sm text-muted-foreground">
                <p>Contribution {contributionCount + 1} of {market.max_participations_per_user || 1}</p>
              </div>
            </form>
          )}

          {contributions && contributions.length > 0 && (
            <div className="mt-6">
              <h3 className="font-semibold mb-2">Your Contribution History</h3>
              <ContributionHistoryTable 
                contributions={contributions} 
                isLoading={isLoading} 
                showProgressiveDetails={true} 
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
