
import { supabase } from "@/lib/supabase";
import { Market } from "@/types/market";
import { logger } from "@/utils/logger";
import { logMarketHistory } from "./history/log-market-history";
import { getPreviousFailedContributions } from "./calculate-progressive-contribution";

interface ContributionResult {
  success: boolean;
  message?: string;
  contributionId?: string;
  requiredContribution?: number;
  calculatedContribution?: number;
  previousFailedTotal?: number;
  odds?: number;
}

/**
 * Submits a market contribution with progressive loss recovery model including odds
 */
export const submitContribution = async (
  market: Market,
  userId: string,
  inputOdds: number,
  calculatedContribution: number,
  requiredContribution: number,
  contributionRound: number = 1,
  previousFailedTotal: number = 0
): Promise<ContributionResult> => {
  logger.info('Submitting market contribution with progressive model and odds', {
    marketId: market.id,
    userId,
    inputOdds,
    calculatedContribution,
    requiredContribution,
    contributionRound,
    previousFailedTotal,
    marketTargetValue: market.target_value
  });

  if (!userId) {
    return {
      success: false,
      message: 'User ID is required'
    };
  }

  if (!market.id) {
    return {
      success: false,
      message: 'Market ID is required'
    };
  }

  // Validate odds
  if (!inputOdds || isNaN(inputOdds) || inputOdds <= 0) {
    return {
      success: false,
      message: 'Valid odds (greater than 0) are required'
    };
  }

  try {
    // Check if market is already closed
    if (market.status === 'closed' || 
        market.status === 'closed_successful' || 
        market.status === 'completed') {
      return {
        success: false,
        message: 'Market is already closed'
      };
    }

    // Check sequential submission
    const { count: existingCount, error: countError } = await supabase
      .from('market_contributions')
      .select('id', { count: 'exact' })
      .eq('market_id', market.id)
      .eq('user_id', userId);
      
    if (countError) {
      logger.error('Error checking contribution count', {
        error: countError.message,
        marketId: market.id,
        userId
      });
    }
    
    // Verify this is the correct next round (sequential enforcement)
    if ((existingCount || 0) + 1 !== contributionRound) {
      return {
        success: false,
        message: `Contributions must be submitted sequentially. Expected round ${(existingCount || 0) + 1}, but got ${contributionRound}`
      };
    }
    
    const isLastAttempt = (existingCount || 0) + 1 >= (market.max_participations_per_user || 1);
    
    // CORRECTED SUCCESS CRITERIA: 
    // A contribution is successful if the calculated contribution is greater than or equal to target value
    // This ensures the contribution actually meets the market's target
    const isSuccessful = calculatedContribution >= market.target_value;
    
    // Set the status based on success or failure
    const status = isSuccessful ? 'success' : 'failed';
    
    logger.info('Contribution status determination', { 
      calculatedContribution,
      requiredContribution,
      targetValue: market.target_value,
      inputOdds,
      isSuccessful,
      status,
      isLastAttempt
    });
    
    // Create metadata object for contribution
    const metadata = {
      required_contribution: requiredContribution,
      previous_failed_total: previousFailedTotal,
      progressive_calculation: true,
      is_last_attempt: isLastAttempt,
      odds: inputOdds
    };
    
    // Submit the contribution to the database - we always allow submissions, even if they will fail
    // Using a minimum input_value of 0.01 to satisfy the check constraint
    const { data, error } = await supabase
      .from('market_contributions')
      .insert([
        {
          market_id: market.id,
          user_id: userId,
          input_value: 0.01, // Minimum value to satisfy check constraint
          input_odds: inputOdds,
          calculated_contribution: calculatedContribution,
          contribution_round: contributionRound,
          contribution_date: new Date().toISOString().split('T')[0], // YYYY-MM-DD format
          status: status,
          metadata: metadata
        }
      ])
      .select('id');

    if (error) {
      logger.error('Error submitting contribution to database', {
        error: error.message,
        code: error.code,
        hint: error.hint,
        marketId: market.id,
        userId
      });
      
      return {
        success: false,
        message: error.message
      };
    }

    // Verify we got back an ID
    if (!data || data.length === 0) {
      logger.error('Contribution appears to have been submitted but no ID was returned', {
        marketId: market.id,
        userId
      });
      
      return {
        success: false,
        message: 'Contribution was submitted but no confirmation was received'
      };
    }

    const contributionId = data[0].id;
    
    // Market status update logic based on contribution result
    let newMarketStatus = null;
    let historyAction = null;
    let historyDetails = {};
    
    // Handle successful contribution - mark market as closed_successful
    if (status === 'success') {
      newMarketStatus = 'closed_successful';
      historyAction = 'status_change';
      historyDetails = {
        previous_status: market.status,
        new_status: newMarketStatus,
        reason: 'Successful contribution submitted',
        contribution_id: contributionId,
        contribution_value: calculatedContribution,
        required_value: requiredContribution,
        previous_failed_total: previousFailedTotal,
        odds: inputOdds,
        progressive_model: true
      };
      
      logger.info('Market contribution successful - updating to closed_successful', {
        marketId: market.id,
        contributionId,
        calculatedContribution,
        requiredContribution,
        odds: inputOdds
      });
    }
    // Handle failed contribution on last attempt - mark market as closed_failed
    else if (status === 'failed' && isLastAttempt) {
      newMarketStatus = 'closed_failed';
      historyAction = 'status_change';
      historyDetails = {
        previous_status: market.status,
        new_status: newMarketStatus,
        reason: 'All contribution attempts failed',
        max_attempts: market.max_participations_per_user,
        attempts_used: (existingCount || 0) + 1,
        last_contribution_id: contributionId,
        last_odds: inputOdds,
        progressive_model: true
      };
      
      logger.info('Final contribution attempt failed - updating to closed_failed', {
        marketId: market.id,
        contributionId,
        maxAttempts: market.max_participations_per_user,
        attemptsUsed: (existingCount || 0) + 1,
        odds: inputOdds
      });
    }
    
    // Apply market status update if needed
    if (newMarketStatus) {
      const { error: updateError } = await supabase
        .from('markets')
        .update({ 
          status: newMarketStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', market.id);
        
      if (updateError) {
        logger.error(`Failed to update market status to ${newMarketStatus}`, {
          error: updateError.message,
          marketId: market.id
        });
      } else {
        logger.info(`Market status updated to ${newMarketStatus}`, {
          marketId: market.id,
          userId,
          contributionId
        });
        
        // Log market history for traceability
        if (historyAction) {
          await logMarketHistory({
            marketId: market.id,
            action: historyAction as any,
            details: historyDetails,
            performedBy: userId
          });
        }
      }
    }
    
    logger.info('Progressive contribution submitted successfully', {
      contributionId,
      marketId: market.id,
      userId,
      status,
      odds: inputOdds,
      calculatedContribution,
      requiredContribution,
      isSuccessful: status === 'success',
      marketStatus: newMarketStatus || market.status
    });

    return {
      success: true,
      contributionId,
      requiredContribution,
      calculatedContribution,
      previousFailedTotal,
      odds: inputOdds
    };
  } catch (error) {
    const err = error instanceof Error ? error : new Error(String(error));
    
    logger.error('Unexpected error submitting contribution', {
      error: err.message,
      stack: err.stack,
      marketId: market.id,
      userId,
      odds: inputOdds
    });
    
    return {
      success: false,
      message: err.message
    };
  }
};
