
import {
  LayoutDashboard,
  Users,
  TrendingUp,
  Settings,
  Wallet,
  History,
  Target,
  FileText,
  DollarSign,
  Clock,
  PanelLeftOpen,
} from "lucide-react";

export interface NavItem {
  icon: any;
  label: string;
  path: string;
}

export const memberNavItems: NavItem[] = [
  { icon: LayoutDashboard, label: "Dashboard", path: "/dashboard" },
  { icon: TrendingUp, label: "Markets", path: "/dashboard/markets" },
  { icon: Wallet, label: "Portfolio", path: "/dashboard/portfolio" },
  { icon: DollarSign, label: "Finances", path: "/dashboard/finances" },
  { icon: Target, label: "Goals", path: "/dashboard/goals" },
  { icon: FileText, label: "Reports", path: "/dashboard/reports" },
  { icon: Settings, label: "Settings", path: "/dashboard/settings" },
];

export const adminNavItems: NavItem[] = [
  { icon: LayoutDashboard, label: "Dashboard", path: "/admin" },
  { icon: PanelLeftOpen, label: "Admin Panel", path: "/admin/panel" },
  { icon: Users, label: "Users", path: "/admin/users" },
  { icon: Clock, label: "Waiting List", path: "/admin/waiting-list" },
  { icon: TrendingUp, label: "Markets", path: "/admin/markets" },
  { icon: DollarSign, label: "Financial", path: "/admin/financial" },
  { icon: FileText, label: "Reports", path: "/admin/reports" },
  { icon: Settings, label: "Settings", path: "/admin/settings" },
];
