
import React from 'react';
import { UnifiedErrorBoundary } from './ui/error-boundary';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, fallback }) => {
  return (
    <UnifiedErrorBoundary fallback={fallback}>
      {children}
    </UnifiedErrorBoundary>
  );
};
