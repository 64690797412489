
import { DashboardSidebar } from "@/components/DashboardSidebar";
import PortfolioSection from "@/components/dashboard/PortfolioSection";
import QuickActions from "@/components/dashboard/QuickActions";
import { MarketOverview } from "@/components/dashboard/MarketOverview";
import { useDashboardData } from "@/hooks/dashboard/use-dashboard-data";
import { useParticipationCounts } from "@/hooks/markets/use-participation-counts";
import { useAuth } from "@/contexts/AuthContext";
import { useEffect, useState } from "react";
import { logger } from "@/utils/logger";
import { WelcomeBanner } from "@/components/dashboard/WelcomeBanner";
import { supabase } from "@/lib/supabase";
import { toast } from "sonner";

export default function MemberDashboard() {
  const { markets, isLoading, notifications, chartData, recentMarkets, refetch } = useDashboardData();
  const { session } = useAuth();
  
  // Use the enhanced participation counts hook
  const { participationCounts } = useParticipationCounts(markets, session);
  
  // Force an initial refresh when the component loads
  useEffect(() => {
    // Immediate refetch on first render
    refetch().catch(err => {
      logger.error('Error refreshing dashboard data:', err);
    });
    
    // Set up periodic refresh to catch market status changes (like failure state)
    const intervalId = setInterval(() => {
      refetch().catch(err => {
        logger.error('Error in periodic dashboard refresh:', err);
      });
    }, 60000); // Refresh every minute
    
    // Set up real-time subscription for target updates
    const channel = supabase
      .channel('db-changes')
      .on('postgres_changes', 
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'markets'
        }, 
        () => {
          // When markets are updated, refresh the dashboard data
          refetch().catch(err => {
            logger.error('Error refreshing after market update:', err);
          });
        }
      )
      .subscribe((status) => {
        if (status !== 'SUBSCRIBED') {
          logger.error('Failed to subscribe to market updates:', status);
        }
      });
      
    // Add event listeners for various market target updates
    const handleMarketTargetUpdates = () => {
      logger.info('Market targets updated, refreshing dashboard');
      refetch().catch(err => {
        logger.error('Error refreshing after market target update:', err);
      });
    };
    
    const handleDashboardUpdateRequired = (event: CustomEvent) => {
      const { forceUpdate } = event.detail || {};
      logger.info('Dashboard update required, refreshing data', forceUpdate ? '(forced)' : '');
      
      // Force immediate refresh when forceUpdate is true
      if (forceUpdate) {
        refetch().catch(err => {
          logger.error('Error refreshing dashboard data:', err);
        });
        toast.info("Dashboard is being refreshed with latest target data");
      } else {
        // For non-forced updates, debounce the refresh
        setTimeout(() => {
          refetch().catch(err => {
            logger.error('Error refreshing dashboard data:', err);
          });
        }, 500);
      }
    };
    
    const handleTargetDataUpdated = (event: CustomEvent) => {
      const { memberId, targets } = event.detail || {};
      logger.info('Target data updated for member:', memberId);
      // Immediate refresh to show the new target values
      refetch().catch(err => {
        logger.error('Error refreshing after target data update:', err);
      });
    };
    
    // Listen for all target-related events
    window.addEventListener('market-targets-updated', handleMarketTargetUpdates);
    window.addEventListener('dashboard-update-required', handleDashboardUpdateRequired as EventListener);
    window.addEventListener('target-data-updated', handleTargetDataUpdated as EventListener);
    window.addEventListener('dashboard-targets-updated', handleMarketTargetUpdates);
    window.addEventListener('member-targets-updated', handleMarketTargetUpdates);
    window.addEventListener('dashboard-targets-bulk-update', handleMarketTargetUpdates);
    
    // Also subscribe to changes in regional_settings
    const regionalSettingsChannel = supabase
      .channel('regional-settings-changes')
      .on('postgres_changes', 
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'regional_settings'
        }, 
        () => {
          logger.info('Regional settings updated, refreshing dashboard data');
          refetch().catch(err => {
            logger.error('Error refreshing after regional settings update:', err);
          });
        }
      )
      .subscribe((status) => {
        if (status !== 'SUBSCRIBED') {
          logger.error('Failed to subscribe to regional settings updates:', status);
        }
      });
    
    return () => {
      clearInterval(intervalId);
      supabase.removeChannel(channel);
      supabase.removeChannel(regionalSettingsChannel);
      window.removeEventListener('market-targets-updated', handleMarketTargetUpdates);
      window.removeEventListener('dashboard-update-required', handleDashboardUpdateRequired as EventListener);
      window.removeEventListener('target-data-updated', handleTargetDataUpdated as EventListener);
      window.removeEventListener('dashboard-targets-updated', handleMarketTargetUpdates);
      window.removeEventListener('member-targets-updated', handleMarketTargetUpdates);
      window.removeEventListener('dashboard-targets-bulk-update', handleMarketTargetUpdates);
    };
  }, [refetch]);

  return (
    <DashboardSidebar>
      <div className="flex-1 p-4 md:p-8 space-y-6">
        {/* Remove DashboardHeader component since we now have a global header */}
        
        <WelcomeBanner session={session} />
        
        <div className="grid gap-6 md:grid-cols-2">
          <PortfolioSection 
            chartData={chartData}
            markets={markets}
            isLoading={isLoading}
            recentMarkets={recentMarkets}
          />
          <QuickActions />
        </div>

        <MarketOverview markets={markets} />
      </div>
    </DashboardSidebar>
  );
}
