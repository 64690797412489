
import React from 'react';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import MemberDashboard from '@/pages/MemberDashboard';
import Markets from '@/pages/member/Markets';
import Finances from '@/pages/member/Finances';
import Reports from '@/pages/member/Reports';
import MarketDetails from '@/pages/member/MarketDetails';
import Portfolio from '@/pages/member/Portfolio';
import Settings from '@/pages/member/Settings';
import Goals from '@/pages/member/Goals';

interface RouteConfig {
  path: string;
  element: React.ReactNode;
}

export const memberRoutes: RouteConfig[] = [
  {
    path: '/dashboard',
    element: <ProtectedRoute><MemberDashboard /></ProtectedRoute>
  },
  {
    path: '/dashboard/markets',
    element: <ProtectedRoute><Markets /></ProtectedRoute>
  },
  {
    path: '/dashboard/finances',
    element: <ProtectedRoute><Finances /></ProtectedRoute>
  },
  {
    path: '/dashboard/reports',
    element: <ProtectedRoute><Reports /></ProtectedRoute>
  },
  {
    path: '/dashboard/transactions',
    element: <ProtectedRoute><Finances /></ProtectedRoute>
  },
  {
    path: '/dashboard/markets/details/:marketId',
    element: <ProtectedRoute><MarketDetails /></ProtectedRoute>
  },
  {
    path: '/dashboard/portfolio',
    element: <ProtectedRoute><Portfolio /></ProtectedRoute>
  },
  {
    path: '/dashboard/settings',
    element: <ProtectedRoute><Settings /></ProtectedRoute>
  },
  {
    path: '/dashboard/goals',
    element: <ProtectedRoute><Goals /></ProtectedRoute>
  }
];
