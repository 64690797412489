
/**
 * Parses market metadata ensuring it's a valid object
 */
export const parseMarketMetadata = (metadata: any): Record<string, any> => {
  if (!metadata) {
    return {};
  }
  
  // If metadata is already an object, return it
  if (typeof metadata === 'object' && !Array.isArray(metadata)) {
    return metadata;
  }
  
  // If metadata is a string, try to parse it as JSON
  if (typeof metadata === 'string') {
    try {
      return JSON.parse(metadata);
    } catch (e) {
      console.error('Error parsing market metadata string:', e);
      return {};
    }
  }
  
  // If we can't handle the metadata format, return an empty object
  console.warn('Unrecognized metadata format:', metadata);
  return {};
};

/**
 * Gets the market description from metadata
 */
export const getMarketDescription = (metadata: any): string => {
  const parsedMetadata = parseMarketMetadata(metadata);
  return parsedMetadata.description || '';
};

/**
 * Sets the market description in metadata
 */
export const setMarketDescription = (metadata: Record<string, any>, description: string): Record<string, any> => {
  const updatedMetadata = { ...metadata };
  updatedMetadata.description = description;
  return updatedMetadata;
};

/**
 * Gets the market sequence position from metadata
 */
export const getSequencePosition = (metadata: any): number | null => {
  const parsedMetadata = parseMarketMetadata(metadata);
  const position = parsedMetadata.sequence_position;
  
  if (position === undefined || position === null) {
    return null;
  }
  
  const numPosition = Number(position);
  return !isNaN(numPosition) ? numPosition : null;
};

/**
 * Sets the sequence position in metadata
 */
export const setSequencePosition = (metadata: Record<string, any>, position: number | null): Record<string, any> => {
  const updatedMetadata = { ...metadata };
  updatedMetadata.sequence_position = position;
  return updatedMetadata;
};

/**
 * Stringifies metadata object to JSON string
 */
export const stringifyMarketMetadata = (metadata: Record<string, any>): string => {
  try {
    return JSON.stringify(metadata);
  } catch (e) {
    console.error('Error stringifying metadata:', e);
    return '{}';
  }
};

/**
 * Gets a closure reason from metadata
 */
export const getClosureReason = (metadata: any): string | null => {
  const parsedMetadata = parseMarketMetadata(metadata);
  return parsedMetadata.closure_reason || null;
};

/**
 * Gets a date from metadata by key
 */
export const getMetadataDate = (metadata: any, key: string): Date | null => {
  const parsedMetadata = parseMarketMetadata(metadata);
  const dateStr = parsedMetadata[key];
  
  if (!dateStr) {
    return null;
  }
  
  try {
    const date = new Date(dateStr);
    return !isNaN(date.getTime()) ? date : null;
  } catch (e) {
    console.error(`Error parsing date from metadata[${key}]:`, e);
    return null;
  }
};

/**
 * Gets user's custom close time from metadata
 */
export const getUserCloseTime = (metadata: any): string | null => {
  const parsedMetadata = parseMarketMetadata(metadata);
  return parsedMetadata.user_close_time || null;
};

/**
 * Sets user's custom close time in metadata
 */
export const setUserCloseTime = (metadata: Record<string, any>, closeTime: string): Record<string, any> => {
  const updatedMetadata = { ...metadata };
  updatedMetadata.user_close_time = closeTime;
  return updatedMetadata;
};
