
import React from "react";
import Hero from "@/components/Hero";
import { motion } from "framer-motion";

const Index = () => {
  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Fixed Background with overlay */}
      <div className="fixed inset-0 w-full h-full">
        <div 
          className="absolute inset-0 bg-[url('/lovable-uploads/a9c5c223-2093-4c55-861f-633a359aefc0.png')] bg-cover bg-center h-full w-full" 
          style={{ backgroundAttachment: "fixed" }}
        />
        {/* Dark gradient overlay for better readability */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/60 to-black/70" />
      </div>

      {/* Centered Content Container */}
      <div className="relative flex items-center justify-center min-h-screen">
        <motion.div 
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 py-16 flex items-center justify-center"
        >
          <Hero />
        </motion.div>
      </div>
    </div>
  );
};

export default Index;
