
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useRegionalTargets } from "@/hooks/market/use-regional-targets";
import { Loader2, RefreshCcw } from "lucide-react";
import { toast } from "sonner";
import { refreshMemberTargets } from "@/utils/market/target-sync";
import { formatCurrencyForRegion } from "@/utils/market/formatters";

interface RegionalTargetSettingsProps {
  userId: string;
  marketId: string;
  onSettingsUpdated?: () => void;
}

export const RegionalTargetSettings = ({ userId, marketId, onSettingsUpdated }: RegionalTargetSettingsProps) => {
  const [selectedRegion, setSelectedRegion] = useState<string>("RSA");
  const [baseTarget, setBaseTarget] = useState<string>("5000");
  const [adjustment, setAdjustment] = useState<string>("500");
  const [factor, setFactor] = useState<string>("1.07");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  
  // Use the userId to get only this specific member's targets
  const { 
    targets, 
    isLoading, 
    error, 
    updateTarget, 
    forceRefresh,
    lastUpdated
  } = useRegionalTargets(userId);
  
  // Update form when selected region changes
  useEffect(() => {
    if (targets && selectedRegion) {
      const target = targets[selectedRegion];
      if (target) {
        setBaseTarget(target.baseTarget.toString());
        setAdjustment(target.adjustment.toString());
        setFactor(target.factor.toString());
      }
    }
  }, [selectedRegion, targets]);
  
  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      // Pass userId to only refresh this specific member's targets
      await refreshMemberTargets(userId);
      await forceRefresh();
      toast.success("Targets refreshed successfully");
    } catch (error) {
      toast.error("Failed to refresh targets");
    } finally {
      setIsRefreshing(false);
    }
  };
  
  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Calculate the target value
      const targetValue = parseFloat(baseTarget) + (parseFloat(adjustment) / parseFloat(factor));
      
      // Update ONLY this member's target for the selected region
      const success = await updateTarget(
        selectedRegion,
        targetValue,
        parseFloat(baseTarget),
        parseFloat(adjustment),
        parseFloat(factor)
      );
      
      if (success) {
        toast.success(`Regional settings for ${selectedRegion} updated`);
        if (onSettingsUpdated) {
          onSettingsUpdated();
        }
      }
    } catch (error) {
      toast.error("Failed to update regional settings");
    } finally {
      setIsSaving(false);
    }
  };
  
  const calculateTargetValue = (): number => {
    const baseValue = parseFloat(baseTarget) || 0;
    const adjustmentValue = parseFloat(adjustment) || 0;
    const factorValue = parseFloat(factor) || 1;
    
    return baseValue + (adjustmentValue / factorValue);
  };
  
  return (
    <Card className="shadow-md border-primary/20">
      <CardHeader className="bg-card/50">
        <CardTitle className="text-xl flex items-center justify-between">
          <span>Member Regional Target Settings</span>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={handleRefresh}
            disabled={isRefreshing}
          >
            {isRefreshing ? (
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            ) : (
              <RefreshCcw className="h-4 w-4 mr-2" />
            )}
            Refresh Targets
          </Button>
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        {isLoading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        ) : error ? (
          <div className="text-destructive p-4 border border-destructive/20 rounded-md bg-destructive/10">
            Error loading targets: {error.message}
          </div>
        ) : (
          <div className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="region">Select Region</Label>
              <Select value={selectedRegion} onValueChange={setSelectedRegion}>
                <SelectTrigger>
                  <SelectValue placeholder="Select a region" />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(targets).map((region) => (
                    <SelectItem key={region} value={region}>
                      {region}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            
            <div className="grid gap-4 md:grid-cols-3">
              <div className="space-y-2">
                <Label htmlFor="baseTarget">Base Target</Label>
                <Input
                  id="baseTarget"
                  type="number"
                  value={baseTarget}
                  onChange={(e) => setBaseTarget(e.target.value)}
                />
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="adjustment">Adjustment</Label>
                <Input
                  id="adjustment"
                  type="number"
                  value={adjustment}
                  onChange={(e) => setAdjustment(e.target.value)}
                />
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="factor">Factor</Label>
                <Input
                  id="factor"
                  type="number"
                  step="0.01"
                  value={factor}
                  onChange={(e) => setFactor(e.target.value)}
                />
              </div>
            </div>
            
            <div className="p-4 bg-primary/5 border border-primary/20 rounded-md">
              <p className="text-sm font-medium">Calculated Target Value:</p>
              <p className="text-2xl font-bold text-primary">
                {formatCurrencyForRegion(calculateTargetValue(), selectedRegion)}
              </p>
              <p className="text-xs text-muted-foreground mt-1">
                This is the member-specific target amount for {selectedRegion}
              </p>
            </div>
            
            <div className="flex justify-end">
              <Button
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Saving...
                  </>
                ) : (
                  "Save Member Target"
                )}
              </Button>
            </div>
            
            {lastUpdated && (
              <p className="text-xs text-muted-foreground text-right">
                Last updated: {lastUpdated.toLocaleString()}
              </p>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
