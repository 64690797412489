
import React, { useState, useEffect } from "react";
import { Market } from "@/types/market";
import { MarketStatusBadge } from "@/components/markets/MarketStatusBadge";
import { ParticipateButton } from "./ParticipateButton";
import { logger } from "@/utils/logger";
import { isPast } from "date-fns";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/lib/supabase";
import { toast } from "sonner";
import { RefreshCw, Loader2, AlertTriangle, CheckCircle } from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import { parseMarketMetadata } from "@/utils/market/metadata-parser";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { formatCurrency } from "@/utils/formatters";
import { useMarketStatus } from "@/hooks/markets/use-market-status";

interface MemberActionsProps {
  market: Market;
  onContributionSubmitted?: () => void;
  participationCount?: number;
  loadingParticipations?: boolean;
  onParticipate?: (marketId: string, customSettings?: { 
    targetEarnings?: number, 
    closeTime?: string 
  }) => Promise<void>;
  onCloseTimeUpdate?: (marketId: string, closeTime: string) => Promise<void>;
  onScrollToBottom?: () => void;
}

export const MemberActions: React.FC<MemberActionsProps> = ({
  market,
  onContributionSubmitted,
  participationCount = 0,
  loadingParticipations = false,
  onParticipate,
  onCloseTimeUpdate,
  onScrollToBottom
}) => {
  const navigate = useNavigate();
  const { session } = useAuth();
  const [isCreatingDuplicate, setIsCreatingDuplicate] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const { updateMarketStatus } = useMarketStatus();
  
  // Log participation data for this market to help with debugging
  logger.info(`Rendering MemberActions for ${market.name}`, {
    marketId: market.id,
    isParticipating: market.isParticipating,
    participationStatus: {
      _type: typeof market.participationStatus,
      value: market.participationStatus || 'undefined'
    },
    status: market.status
  });
  
  // Check participation status
  const isParticipationFailed = market.participationStatus === 'failed';
  const isParticipationSuccess = market.participationStatus === 'success';
  
  // Check if this is a scheduled market with passed start date
  const isScheduledWithPassedStartDate = 
    market.status === 'scheduled' && 
    market.start_date && 
    isPast(new Date(market.start_date));
  
  if (isScheduledWithPassedStartDate) {
    logger.info(`Scheduled market with passed start date: ${market.name}`, {
      marketId: market.id, 
      startDate: market.start_date,
      currentTime: new Date().toISOString()
    });
  }

  // Function to handle contribution button click
  const handleContributeClick = () => {
    navigate(`/dashboard/markets/details/${market.id}`);
    
    logger.info(`Navigate to market contribution page for ${market.id}`, {
      marketId: market.id,
      marketName: market.name,
      route: `/dashboard/markets/details/${market.id}`
    });
  };
  
  // Determine if market is failed - check the status
  const isFailed = ['failed', 'closed_failed', 'failed_final'].includes(market.status);
  const isSuccessful = ['success', 'closed_successful', 'completed'].includes(market.status);
  
  // Auto-show success dialog when participation is successful
  useEffect(() => {
    if (isParticipationSuccess && market.isParticipating) {
      setIsSuccessDialogOpen(true);
    }
  }, [isParticipationSuccess, market.isParticipating]);
  
  // Parse market metadata to check for duplication availability
  const parsedMetadata = parseMarketMetadata(market.metadata);
  const duplicateCount = parsedMetadata?.duplicate_count as number || 0;
  const canDuplicate = market.max_duplications && (duplicateCount < market.max_duplications);
  
  // Check if user has no attempts left
  const hasNoAttemptsLeft = market.max_participations_per_user && 
    market.participation_count && 
    market.participation_count >= market.max_participations_per_user;
  
  // Handle duplicate market functionality
  const handleDuplicateMarket = async () => {
    if (!market.id || !session?.user?.id) return;
    
    setIsCreatingDuplicate(true);
    try {
      const { data, error } = await supabase.functions.invoke("duplicate-market", {
        body: {
          market_id: market.id,
          user_id: session.user.id
        }
      });
      
      if (error) throw error;
      
      if (data && typeof data === 'object' && 'new_market_id' in data) {
        toast.success("Market duplicated successfully! You can now try again.");
        navigate(`/dashboard/markets/details/${data.new_market_id}`);
      } else {
        throw new Error("Failed to duplicate market. No new market ID returned.");
      }
    } catch (err) {
      logger.error('Error duplicating market', err);
      toast.error("Failed to duplicate market. Please try again.");
    } finally {
      setIsCreatingDuplicate(false);
    }
  };

  // Check if we need to show the retry button - prioritize participation status
  const showRetryButton = (isParticipationFailed || (isFailed && hasNoAttemptsLeft)) && canDuplicate;
  
  // Handler for moving to closed tab
  const handleMoveToClosedTab = async () => {
    try {
      // Update market status to closed_successful if it's already successful
      if ((isParticipationSuccess || isSuccessful) && !['closed_successful', 'completed'].includes(market.status)) {
        await updateMarketStatus(market.id, 'closed_successful');
        toast.success("Market has been marked as closed successful!");
      }
      
      setIsSuccessDialogOpen(false);
      
      // Navigate to the closed tab of markets
      navigate('/dashboard/markets?tab=closed');
    } catch (error) {
      logger.error('Error moving market to closed tab:', error);
      toast.error("Failed to update market status. Please try again.");
    }
  };
  
  // Check if we should show success dialog
  useEffect(() => {
    if ((isParticipationSuccess || isSuccessful) && market.isParticipating) {
      setIsSuccessDialogOpen(true);
    }
  }, [isParticipationSuccess, isSuccessful, market.isParticipating]);
  
  return (
    <div className="space-y-4 w-full">
      {/* Status display */}
      <div className="flex items-center justify-between">
        <div>
          <MarketStatusBadge 
            status={market.status} 
            participationStatus={market.participationStatus}
            startDate={market.start_date}
          />
        </div>
        
        {/* Show Participate button only if not already participating */}
        {(!market.isParticipating) && (
          <ParticipateButton
            market={market}
            onParticipate={onParticipate}
            onSuccess={onContributionSubmitted}
            size="sm"
          />
        )}
      </div>
      
      {/* Success market alert - prioritize participation status */}
      {isParticipationSuccess && (
        <Alert variant="success" className="bg-green-50/50 dark:bg-green-950/20 border border-green-300 dark:border-green-800">
          <CheckCircle className="h-4 w-4 text-green-600" />
          <AlertTitle>Participation Successful</AlertTitle>
          <AlertDescription className="text-sm">
            Your participation in this market was successful!
          </AlertDescription>
        </Alert>
      )}
      
      {/* Regular success alert if not caught by participation status */}
      {!isParticipationSuccess && isSuccessful && (
        <Alert variant="success" className="bg-green-50/50 dark:bg-green-950/20 border border-green-300 dark:border-green-800">
          <CheckCircle className="h-4 w-4 text-green-600" />
          <AlertTitle>Market Successful</AlertTitle>
          <AlertDescription className="text-sm">
            This market has successfully reached its target value!
          </AlertDescription>
        </Alert>
      )}
      
      {/* Failed market alert - prioritize participation status */}
      {isParticipationFailed && (
        <Alert variant="destructive" className="bg-red-50/50 dark:bg-red-950/20 border border-red-300 dark:border-red-800">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Participation Failed</AlertTitle>
          <AlertDescription className="text-sm">
            {canDuplicate 
              ? "Your participation attempt failed, but you can try again with a new market." 
              : "Your participation attempt failed."}
          </AlertDescription>
        </Alert>
      )}
      
      {/* Regular failed alert if not caught by participation status */}
      {!isParticipationFailed && isFailed && hasNoAttemptsLeft && (
        <Alert variant="destructive" className="bg-red-50/50 dark:bg-red-950/20 border border-red-300 dark:border-red-800">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Market Failed</AlertTitle>
          <AlertDescription className="text-sm">
            {canDuplicate 
              ? "You've used all contribution attempts, but you can try again with a new market." 
              : "You've used all contribution attempts for this market."}
          </AlertDescription>
        </Alert>
      )}
      
      {/* Retry with new market button - prioritize participation status */}
      {showRetryButton && (
        <Button 
          onClick={handleDuplicateMarket} 
          className={cn("w-full", isCreatingDuplicate ? "bg-primary/80" : "bg-primary")}
          disabled={isCreatingDuplicate}
        >
          {isCreatingDuplicate ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Creating...
            </>
          ) : (
            <>
              <RefreshCw className="mr-2 h-4 w-4" />
              Retry with New Market
            </>
          )}
        </Button>
      )}
      
      {/* Move successful market to closed tab button */}
      {(isParticipationSuccess || isSuccessful) && (
        <Button 
          onClick={() => setIsSuccessDialogOpen(true)}
          className="w-full bg-green-600 hover:bg-green-700 text-white"
        >
          <CheckCircle className="mr-2 h-4 w-4" />
          View Success Details
        </Button>
      )}
      
      {/* Show Contribute button if user is participating */}
      {market.isParticipating && (market.status === 'active' || isScheduledWithPassedStartDate) && !isFailed && !isSuccessful && !isParticipationFailed && !isParticipationSuccess && (
        <Button 
          className="w-full mt-2"
          onClick={handleContributeClick}
          size="sm"
        >
          Submit Contribution
        </Button>
      )}
      
      {/* Success Dialog */}
      <Dialog open={isSuccessDialogOpen} onOpenChange={setIsSuccessDialogOpen}>
        <DialogContent className="sm:max-w-md border-green-500 dark:border-green-700">
          <DialogHeader>
            <DialogTitle className="flex items-center text-green-700 dark:text-green-500">
              <CheckCircle className="w-6 h-6 mr-2" />
              Market Success
            </DialogTitle>
            <DialogDescription>
              This market has successfully reached its target!
            </DialogDescription>
          </DialogHeader>
          
          <div className="p-4 bg-green-50 dark:bg-green-950/20 rounded-md">
            <p className="text-sm text-green-800 dark:text-green-200">
              {isParticipationSuccess 
                ? "Congratulations! Your participation was successful!" 
                : `Congratulations! The market "${market.name}" has successfully reached its target value of ${formatCurrency(market.target_value)}.`}
            </p>
          </div>
          
          <DialogFooter className="sm:justify-end">
            <Button
              onClick={handleMoveToClosedTab}
              className="w-full sm:w-auto bg-green-600 hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-800 text-white"
            >
              Okay
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
