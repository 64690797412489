
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import {
  Eye,
  Search,
  Pencil,
  Save,
  X
} from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { updateMemberSharePercentage } from "@/utils/financials/member-financials";
import { useToast } from "@/hooks/use-toast";
import { User } from "@/components/admin/users/types";
import { Badge } from "@/components/ui/badge";
import { formatCurrency } from "@/utils/formatters";

interface MemberListTableProps {
  users: User[];
  loading: boolean;
  onViewDetails: (userId: string) => void;
  onUpdateStatus?: (userId: string, status: string) => Promise<void>;
  onUpdateBalance?: (userId: string, balance: number) => Promise<void>;
}

export const MemberListTable: React.FC<MemberListTableProps> = ({
  users,
  loading,
  onViewDetails,
  onUpdateStatus,
  onUpdateBalance
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [editingUserId, setEditingUserId] = useState<string | null>(null);
  const [editingField, setEditingField] = useState<"sharePercentage" | "balance" | "status" | null>(null);
  const [editSharePercentage, setEditSharePercentage] = useState<number>(0);
  const [editBalance, setEditBalance] = useState<number>(0);
  const [editStatus, setEditStatus] = useState<string>("");
  const { toast } = useToast();

  const nonAdminUsers = users.filter(user => !user.is_admin);

  const filteredUsers = nonAdminUsers.filter(user => {
    const searchString = `${user.first_name || ''} ${user.last_name || ''} ${user.email}`.toLowerCase();
    return searchString.includes(searchTerm.toLowerCase());
  });

  const handleStartEdit = (user: User, field: "sharePercentage" | "balance" | "status") => {
    setEditingUserId(user.id);
    setEditingField(field);
    
    if (field === "sharePercentage") {
      setEditSharePercentage(user.member_share_percentage || 85);
    } else if (field === "balance") {
      setEditBalance(user.balance || 0);
    } else if (field === "status") {
      setEditStatus(user.status || "pending");
    }
  };

  const handleCancelEdit = () => {
    setEditingUserId(null);
    setEditingField(null);
  };

  const handleSaveSharePercentage = async (userId: string) => {
    try {
      await updateMemberSharePercentage(userId, editSharePercentage);
      toast({
        title: "Share percentage updated",
        description: `User share percentage has been updated to ${editSharePercentage}%`,
      });
      setEditingUserId(null);
      setEditingField(null);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update share percentage",
        variant: "destructive",
      });
    }
  };

  const handleSaveBalance = async (userId: string) => {
    if (!onUpdateBalance) return;
    
    try {
      await onUpdateBalance(userId, editBalance);
      toast({
        title: "Balance updated",
        description: `User balance has been updated to ${formatCurrency(editBalance)}`,
      });
      setEditingUserId(null);
      setEditingField(null);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update balance",
        variant: "destructive",
      });
    }
  };

  const handleSaveStatus = async (userId: string) => {
    if (!onUpdateStatus) return;
    
    try {
      await onUpdateStatus(userId, editStatus);
      toast({
        title: "Status updated",
        description: `User status has been updated to ${editStatus}`,
      });
      setEditingUserId(null);
      setEditingField(null);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update status",
        variant: "destructive",
      });
    }
  };

  const getStatusBadgeStyles = (status: string) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'suspended':
        return 'bg-red-100 text-red-800';
      case 'waiting':
        return 'bg-yellow-100 text-yellow-800';
      case 'deactivated':
        return 'bg-gray-100 text-gray-800';
      case 'pending':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
    { value: 'suspended', label: 'Suspended' },
    { value: 'waiting', label: 'Waiting' },
    { value: 'deactivated', label: 'Deactivated' },
    { value: 'rejected', label: 'Rejected' },
  ];

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row gap-4 justify-between">
        <div className="relative">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search users..."
            className="pl-8 w-full sm:w-[300px]"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex gap-2">
          <Select defaultValue="all">
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filter status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All statuses</SelectItem>
              <SelectItem value="active">Active</SelectItem>
              <SelectItem value="suspended">Suspended</SelectItem>
              <SelectItem value="waiting">Waiting</SelectItem>
              <SelectItem value="inactive">Inactive</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {loading ? (
        <div className="space-y-2">
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-16 w-full" />
          <Skeleton className="h-16 w-full" />
          <Skeleton className="h-16 w-full" />
        </div>
      ) : (
        <div className="rounded-md border overflow-x-auto">
          <Table>
            <TableHeader className="bg-muted/50">
              <TableRow>
                <TableHead>User</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Current Balance</TableHead>
                <TableHead>User Share %</TableHead>
                <TableHead>Company ROI %</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredUsers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="h-24 text-center">
                    No users found.
                  </TableCell>
                </TableRow>
              ) : (
                filteredUsers.map((user) => (
                  <TableRow key={user.id} className="hover:bg-muted/40">
                    <TableCell className="font-medium">
                      <div>
                        {user.first_name} {user.last_name}
                      </div>
                      <div className="text-sm text-muted-foreground mt-1">{user.email}</div>
                    </TableCell>
                    <TableCell>
                      {editingUserId === user.id && editingField === "status" ? (
                        <div className="flex items-center space-x-2">
                          <Select 
                            value={editStatus} 
                            onValueChange={setEditStatus}
                          >
                            <SelectTrigger className="w-[140px] h-8">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {statusOptions.map(option => (
                                <SelectItem key={option.value} value={option.value}>
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <Button 
                            size="sm" 
                            className="h-8 px-2" 
                            onClick={() => handleSaveStatus(user.id)}
                          >
                            <Save className="h-3.5 w-3.5" />
                          </Button>
                          <Button 
                            size="sm" 
                            variant="outline" 
                            className="h-8 px-2" 
                            onClick={handleCancelEdit}
                          >
                            <X className="h-3.5 w-3.5" />
                          </Button>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <Badge variant="outline" className={`${getStatusBadgeStyles(user.status || 'pending')}`}>
                            {user.status || 'pending'}
                          </Badge>
                          {onUpdateStatus && (
                            <Button 
                              variant="ghost" 
                              size="sm" 
                              className="ml-2 h-8 w-8 p-0" 
                              onClick={() => handleStartEdit(user, "status")}
                            >
                              <Pencil className="h-3.5 w-3.5" />
                            </Button>
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {editingUserId === user.id && editingField === "balance" ? (
                        <div className="flex items-center space-x-2">
                          <Input 
                            type="number"
                            value={editBalance}
                            onChange={(e) => setEditBalance(Number(e.target.value))}
                            className="w-24 h-8"
                            min="0"
                          />
                          <Button 
                            size="sm" 
                            className="h-8 px-2" 
                            onClick={() => handleSaveBalance(user.id)}
                          >
                            <Save className="h-3.5 w-3.5" />
                          </Button>
                          <Button 
                            size="sm" 
                            variant="outline" 
                            className="h-8 px-2" 
                            onClick={handleCancelEdit}
                          >
                            <X className="h-3.5 w-3.5" />
                          </Button>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <span>{formatCurrency(user.balance || 0)}</span>
                          {onUpdateBalance && (
                            <Button 
                              variant="ghost" 
                              size="sm" 
                              className="ml-2 h-8 w-8 p-0" 
                              onClick={() => handleStartEdit(user, "balance")}
                            >
                              <Pencil className="h-3.5 w-3.5" />
                            </Button>
                          )}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {editingUserId === user.id && editingField === "sharePercentage" ? (
                        <div className="flex items-center space-x-2">
                          <Input 
                            type="number"
                            value={editSharePercentage}
                            onChange={(e) => setEditSharePercentage(Number(e.target.value))}
                            className="w-20 h-8"
                            min="0"
                            max="100"
                          />
                          <Button 
                            size="sm" 
                            className="h-8 px-2" 
                            onClick={() => handleSaveSharePercentage(user.id)}
                          >
                            <Save className="h-3.5 w-3.5" />
                          </Button>
                          <Button 
                            size="sm" 
                            variant="outline" 
                            className="h-8 px-2" 
                            onClick={handleCancelEdit}
                          >
                            <X className="h-3.5 w-3.5" />
                          </Button>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <span className="font-medium">{user.member_share_percentage || 85}%</span>
                          <Button 
                            variant="ghost" 
                            size="sm" 
                            className="ml-2 h-8 w-8 p-0" 
                            onClick={() => handleStartEdit(user, "sharePercentage")}
                          >
                            <Pencil className="h-3.5 w-3.5" />
                          </Button>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{100 - (user.member_share_percentage || 85)}%</TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => onViewDetails(user.id)}
                        className="gap-2"
                      >
                        <Eye className="h-3.5 w-3.5" />
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
