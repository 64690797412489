
import { Market } from "@/types/market";
import { logger } from "@/utils/logger";
import { MemberActions } from "./actions/MemberActions";
import { AdminActions } from "./actions/AdminActions";

interface MarketActionsProps {
  market: Market;
  onContributionSubmitted?: () => void;
  variant?: 'grid' | 'list';
  participationCount?: number;
  loadingParticipations?: boolean;
  onParticipate?: (marketId: string, customSettings?: { 
    targetEarnings?: number, 
    closeTime?: string 
  }) => Promise<void>;
  onCloseTimeUpdate?: (marketId: string, closeTime: string) => Promise<void>;
  isAdminView?: boolean;
  showConfiguration?: boolean;
  onScrollToBottom?: () => void;
}

export const MarketActions = ({ 
  market, 
  onContributionSubmitted, 
  participationCount = 0,
  loadingParticipations = false,
  onParticipate,
  onCloseTimeUpdate,
  isAdminView = false,
  showConfiguration = false,
  onScrollToBottom
}: MarketActionsProps) => {
  logger.info(`Rendering MarketActions for ${market.name}`, {
    marketId: market.id,
    isParticipating: market.isParticipating,
    isAdminView
  });
  
  // Choose the appropriate component based on whether this is an admin view
  if (isAdminView) {
    return (
      <AdminActions 
        market={market} 
        onConfigurationUpdated={onContributionSubmitted}
        onScrollToBottom={onScrollToBottom}
      />
    );
  }
  
  // Default to member actions
  return (
    <MemberActions 
      market={market}
      onContributionSubmitted={onContributionSubmitted}
      participationCount={participationCount}
      loadingParticipations={loadingParticipations}
      onParticipate={onParticipate}
      onCloseTimeUpdate={onCloseTimeUpdate}
      onScrollToBottom={onScrollToBottom}
    />
  );
};
