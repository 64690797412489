
import { useEffect, useRef } from 'react';
import { Session } from '@supabase/supabase-js';
import { logger } from '@/utils/logger';

export const useSessionCheck = (
  session: Session | null, 
  authLoading: boolean,
  setAuthChecked: (checked: boolean) => void
) => {
  const sessionCheckedRef = useRef(false);
  
  useEffect(() => {
    if (!authLoading && !sessionCheckedRef.current) {
      sessionCheckedRef.current = true;
      
      if (!session) {
        logger.info('No authenticated session found, redirecting will be handled by useAuthRedirect');
      } else {
        setAuthChecked(true);
        
        // Store current user ID in localStorage for recovery purposes
        localStorage.setItem('current_user_id', session.user.id);
      }
    }
  }, [session, authLoading, setAuthChecked]);
};
