
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LogOut, Settings, User, Loader2, LayoutDashboard } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { ProfileAvatar } from "@/components/profile/ProfileAvatar";

const UserMenu = () => {
  const navigate = useNavigate();
  const { session, signOut, isSigningOut } = useAuth();
  const { toast } = useToast();

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      toast({
        title: "Error signing out",
        description: "There was a problem signing you out. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (!session) {
    return (
      <Button
        className="bg-primary hover:bg-primary/90 text-primary-foreground transition-all duration-300 hover:shadow-lg rounded-full px-6"
        onClick={() => navigate("/signin")}
      >
        <User className="h-4 w-4 mr-2" />
        Sign In
      </Button>
    );
  }

  const avatarUrl = session.user?.user_metadata?.avatar_url;
  const firstName = session.user?.user_metadata?.first_name;
  const lastName = session.user?.user_metadata?.last_name;
  const fullName = firstName && lastName ? `${firstName} ${lastName}` : firstName || "User";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          className="hover:bg-primary/10 transition-colors duration-200 rounded-full p-0 flex items-center gap-2"
        >
          <span className="hidden md:inline-block font-medium text-sm mr-1">{fullName}</span>
          <ProfileAvatar 
            userId={session.user.id}
            firstName={firstName}
            lastName={lastName}
            imageSrc={avatarUrl}
            size="sm"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-card/95 backdrop-blur-sm border border-primary/20 rounded-md">
        <DropdownMenuItem 
          className="hover:bg-primary/10 cursor-pointer"
          onClick={() => navigate("/dashboard")}
        >
          <LayoutDashboard className="h-4 w-4 mr-2 text-primary dark:text-primary" />
          Dashboard
        </DropdownMenuItem>
        <DropdownMenuItem 
          className="hover:bg-primary/10 cursor-pointer"
          onClick={() => navigate("/profile")}
        >
          <User className="h-4 w-4 mr-2 text-primary dark:text-primary" />
          Profile
        </DropdownMenuItem>
        <DropdownMenuItem 
          className="hover:bg-primary/10 cursor-pointer"
          onClick={() => navigate("/dashboard/settings")}
        >
          <Settings className="h-4 w-4 mr-2 text-primary dark:text-primary" />
          Settings
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem 
          className="hover:bg-primary/10 cursor-pointer text-red-500 dark:text-red-400"
          onClick={handleSignOut}
          disabled={isSigningOut}
        >
          {isSigningOut ? (
            <Loader2 className="h-4 w-4 mr-2 animate-spin text-red-500 dark:text-red-400" />
          ) : (
            <LogOut className="h-4 w-4 mr-2 text-red-500 dark:text-red-400" />
          )}
          Sign Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserMenu;
