
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { ArrowLeft, Download, Calendar, FileSpreadsheet } from "lucide-react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { supabase } from "@/lib/supabase";
import { DailyFinancialRecord, getAllDaysInMonthWithRecords, calculateDailyTotals, generateMonthlySummary, MonthlyFinancialSummary } from "@/utils/financials/member-financials";
import { DailyFinancialRecord as DailyFinancialRecordComponent } from "@/components/financials/DailyFinancialRecord";
import { format } from "date-fns";
import { Skeleton } from "@/components/ui/skeleton";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";

interface MonthlyReportData {
  summary: MonthlyFinancialSummary | null;
  dailyRecords: DailyFinancialRecord[];
  totals: {
    totalDeposits: number;
    startBalance: number;
    endBalance: number;
  };
}

interface User {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  balance: number;
  status: string | null;
  member_share_percentage: number;
}

interface MemberFinancialDetailsProps {
  userId: string;
  onBack: () => void;
}

export const MemberFinancialDetails: React.FC<MemberFinancialDetailsProps> = ({
  userId,
  onBack
}) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [report, setReport] = useState<MonthlyReportData | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    fetchUserDetails();
    fetchFinancialData();
  }, [userId, selectedMonth, selectedYear]);

  const fetchUserDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error);
      toast({
        title: "Error",
        description: "Failed to load user details",
        variant: "destructive",
      });
    }
  };

  const fetchFinancialData = async () => {
    try {
      setLoading(true);
      
      // Get all daily records for the month
      const dailyRecords = await getAllDaysInMonthWithRecords(
        userId,
        selectedMonth,
        selectedYear
      );
      
      // Calculate totals for the daily records
      const totals = calculateDailyTotals(dailyRecords);
      
      // Generate or fetch the monthly summary
      let monthlySummary = await generateMonthlySummary(
        userId,
        selectedMonth,
        selectedYear
      );
      
      setReport({
        summary: monthlySummary,
        dailyRecords,
        totals
      });
    } catch (error) {
      console.error('Error fetching financial data:', error);
      toast({
        title: "Error",
        description: "Failed to load financial report data",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (value: string) => {
    setSelectedMonth(parseInt(value));
  };

  const handleYearChange = (value: string) => {
    setSelectedYear(parseInt(value));
  };

  const exportMonthlyReport = () => {
    if (!report || !user) return;
    
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Date,Opening Balance,Bank Deposit,Closing Balance\n";
    
    report.dailyRecords.forEach(record => {
      const date = format(new Date(record.record_date), 'yyyy-MM-dd');
      csvContent += `${date},${record.opening_balance},${record.bank_deposit || 0},${record.closing_balance}\n`;
    });
    
    csvContent += `\nTotals,${report.totals.startBalance},${report.totals.totalDeposits},${report.totals.endBalance}\n`;
    
    if (report.summary) {
      csvContent += `\nMonthly Summary for ${format(new Date(selectedYear, selectedMonth - 1), 'MMMM yyyy')}\n`;
      csvContent += `Member Share Percentage,${report.summary.member_share_amount / report.summary.total_deposits * 100}%\n`;
      csvContent += `Total Deposits,${report.summary.total_deposits}\n`;
      csvContent += `Member Share Amount,${report.summary.member_share_amount}\n`;
      csvContent += `Company Share Amount,${report.summary.company_share_amount}\n`;
      csvContent += `YTD Total,${report.summary.ytd_total}\n`;
    }
    
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${user.first_name}_${user.last_name}_${selectedYear}_${selectedMonth}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    toast({
      title: "Export Complete",
      description: "The financial report has been downloaded",
    });
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2
    }).format(amount || 0);
  };

  if (!user) {
    return (
      <div className="space-y-4">
        <Button variant="ghost" onClick={onBack} className="mb-4">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Member List
        </Button>
        <div className="space-y-2">
          <Skeleton className="h-12 w-1/2" />
          <Skeleton className="h-24 w-full" />
          <Skeleton className="h-48 w-full" />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <Button variant="ghost" onClick={onBack} className="w-auto sm:mb-0 self-start">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Member List
        </Button>
        
        <div className="flex flex-wrap gap-2">
          <Select value={selectedMonth.toString()} onValueChange={handleMonthChange}>
            <SelectTrigger className="w-[140px]">
              <SelectValue placeholder="Month" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">January</SelectItem>
              <SelectItem value="2">February</SelectItem>
              <SelectItem value="3">March</SelectItem>
              <SelectItem value="4">April</SelectItem>
              <SelectItem value="5">May</SelectItem>
              <SelectItem value="6">June</SelectItem>
              <SelectItem value="7">July</SelectItem>
              <SelectItem value="8">August</SelectItem>
              <SelectItem value="9">September</SelectItem>
              <SelectItem value="10">October</SelectItem>
              <SelectItem value="11">November</SelectItem>
              <SelectItem value="12">December</SelectItem>
            </SelectContent>
          </Select>
          
          <Select value={selectedYear.toString()} onValueChange={handleYearChange}>
            <SelectTrigger className="w-[110px]">
              <SelectValue placeholder="Year" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="2023">2023</SelectItem>
              <SelectItem value="2024">2024</SelectItem>
              <SelectItem value="2025">2025</SelectItem>
            </SelectContent>
          </Select>
          
          <Button variant="outline" onClick={exportMonthlyReport} className="gap-2">
            <FileSpreadsheet className="h-4 w-4" />
            Export Report
          </Button>
        </div>
      </div>
      
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <div className="lg:col-span-12">
          <Card>
            <CardHeader className="pb-3">
              <CardTitle className="text-2xl">
                {user.first_name} {user.last_name}
              </CardTitle>
              <div className="text-sm text-muted-foreground mt-1">{user.email}</div>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="p-4 border rounded-lg">
                  <div className="text-sm font-medium text-muted-foreground mb-1">Current Balance</div>
                  <div className="text-2xl font-bold">{formatCurrency(user.balance)}</div>
                </div>
                <div className="p-4 border rounded-lg">
                  <div className="text-sm font-medium text-muted-foreground mb-1">Member Share %</div>
                  <div className="text-2xl font-bold">{user.member_share_percentage}%</div>
                </div>
                <div className="p-4 border rounded-lg">
                  <div className="text-sm font-medium text-muted-foreground mb-1">Company ROI %</div>
                  <div className="text-2xl font-bold">{100 - user.member_share_percentage}%</div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        
        <div className="lg:col-span-4">
          <Card>
            <CardHeader>
              <CardTitle>Monthly Summary</CardTitle>
            </CardHeader>
            <CardContent>
              {loading ? (
                <div className="space-y-3">
                  <Skeleton className="h-10 w-full" />
                  <Skeleton className="h-10 w-full" />
                  <Skeleton className="h-10 w-full" />
                  <Skeleton className="h-10 w-full" />
                </div>
              ) : (
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Month</div>
                      <div className="font-medium">{format(new Date(selectedYear, selectedMonth - 1), 'MMMM yyyy')}</div>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Total Deposits</div>
                      <div className="font-medium">{formatCurrency(report?.summary?.total_deposits || 0)}</div>
                    </div>
                  </div>
                  
                  <div className="border-t pt-3"></div>
                  
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Member Share</div>
                      <div className="font-medium">{formatCurrency(report?.summary?.member_share_amount || 0)}</div>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Company Share</div>
                      <div className="font-medium text-primary">{formatCurrency(report?.summary?.company_share_amount || 0)}</div>
                    </div>
                  </div>
                  
                  <div className="border-t pt-3"></div>
                  
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">YTD Total</div>
                      <div className="font-medium">{formatCurrency(report?.summary?.ytd_total || 0)}</div>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Member Share %</div>
                      <div className="font-medium">{user.member_share_percentage}%</div>
                    </div>
                  </div>
                  
                  <div className="border-t pt-3"></div>
                  
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">Start Balance</div>
                      <div className="font-medium">{formatCurrency(report?.totals.startBalance || 0)}</div>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-muted-foreground">End Balance</div>
                      <div className="font-medium">{formatCurrency(report?.totals.endBalance || 0)}</div>
                    </div>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
        
        <div className="lg:col-span-8">
          <Card>
            <CardHeader>
              <CardTitle>Daily Financial Records</CardTitle>
            </CardHeader>
            <CardContent>
              {loading ? (
                <div className="space-y-2">
                  <Skeleton className="h-24 w-full" />
                  <Skeleton className="h-24 w-full" />
                  <Skeleton className="h-24 w-full" />
                </div>
              ) : report?.dailyRecords.length === 0 ? (
                <div className="text-center py-8 text-muted-foreground">
                  No records found for this time period.
                </div>
              ) : (
                <div className="space-y-2 max-h-[500px] overflow-y-auto pr-2">
                  {report?.dailyRecords.map((record) => (
                    <DailyFinancialRecordComponent
                      key={record.record_date}
                      record={record}
                      userId={userId}
                      onRecordUpdated={fetchFinancialData}
                      isEditable={false}
                      memberSharePercentage={user.member_share_percentage}
                      isCurrentMonth={
                        selectedMonth === new Date().getMonth() + 1 &&
                        selectedYear === new Date().getFullYear()
                      }
                    />
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
