
import { useQuery } from "@tanstack/react-query";
import { Session } from "@supabase/supabase-js";
import { logger } from "@/utils/logger";
import { useToast } from "@/hooks/use-toast";
import { QueryState, QueryStateUpdater } from "./types/query-state";
import { fetchMarketsData } from "./services/markets-service";

export const useMarketsQuery = (
  session: Session | null,
  state: QueryState,
  stateUpdater: QueryStateUpdater
) => {
  const { toast } = useToast();
  const { attempts, lastQueryTime } = state;
  const { setAttempts, setLastQueryTime } = stateUpdater;

  return useQuery({
    queryKey: ['admin-markets', session?.user?.id],
    queryFn: async () => {
      try {
        setAttempts(attempts + 1);
        setLastQueryTime(Date.now());

        if (!session) {
          throw new Error('Authentication required');
        }

        return await fetchMarketsData(session, attempts, lastQueryTime);

      } catch (error) {
        const err = error as Error;
        logger.error('Error in markets query:', {
          message: err.message,
          attempts,
          lastQueryTime,
          stack: err.stack
        });
        throw error;
      }
    },
    meta: {
      onError: (error: Error) => {
        toast({
          title: "Error loading markets",
          description: `${error.message} (Attempt ${attempts})`,
          variant: "destructive",
        });
      }
    },
    enabled: !!session?.user?.id,
    staleTime: 30000,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000)
  });
};
