
import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { generateUniqueGradient, getInitials } from "@/utils/profile-gradients";
import { cn } from "@/lib/utils";

interface ProfileAvatarProps {
  userId?: string;
  firstName?: string | null;
  lastName?: string | null;
  imageSrc?: string | null;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  onImageClick?: () => void;
}

export const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  userId,
  firstName,
  lastName,
  imageSrc,
  size = 'md',
  className,
  onImageClick
}) => {
  const initials = getInitials(firstName, lastName);
  const gradient = generateUniqueGradient(userId);
  
  // Size mapping
  const sizeClasses = {
    sm: "h-8 w-8 text-xs",
    md: "h-10 w-10 text-sm",
    lg: "h-12 w-12 text-base",
    xl: "h-16 w-16 text-lg"
  };

  return (
    <Avatar 
      className={cn(
        sizeClasses[size], 
        className,
        onImageClick && "cursor-pointer hover:opacity-90 transition-opacity"
      )}
      onClick={onImageClick}
    >
      {imageSrc ? (
        <AvatarImage src={imageSrc} alt={`${firstName || ''} ${lastName || ''}`} />
      ) : null}
      <AvatarFallback 
        style={{ background: gradient }} 
        className="text-white font-medium"
      >
        {initials}
      </AvatarFallback>
    </Avatar>
  );
};

export default ProfileAvatar;
