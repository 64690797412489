
import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { 
  Settings, Users, TrendingUp, DollarSign, 
  Activity, Percent, History
} from "lucide-react";
import { OverviewTab } from '@/components/admin/dashboard/OverviewTab';
import MarketsAdmin from '@/components/admin/markets/MarketsAdmin';
import MembersAdmin from '@/components/admin/members/MembersAdmin';
import { FinancialsTab } from '@/components/admin/financials/FinancialsTab';
import { ConfigurationTab } from '@/components/admin/configuration/ConfigurationTab';
import { CommissionManagement } from '@/components/admin/financials/CommissionManagement';
import { AdminActivityLog } from '@/components/admin/system/AdminActivityLog';
import { FinancialSettings, RegionalSettings } from '@/types/financial';

interface AdminTabsProps {
  currentYear: number;
  currentMonth: number;
  settings: FinancialSettings | null;
  regionalSettings: RegionalSettings[];
  loading: boolean;
  isLoading: boolean;
  financialData: any;
  updateSettings: (baseTarget: number, adjustmentFactor: number) => Promise<any>;
  handleUpdateRegionalSettings: (regionData: any) => Promise<any>;
}

export const AdminTabs: React.FC<AdminTabsProps> = ({
  currentYear,
  currentMonth,
  settings,
  regionalSettings,
  loading,
  isLoading,
  financialData,
  updateSettings,
  handleUpdateRegionalSettings
}) => {
  return (
    <Tabs defaultValue="overview" className="space-y-6">
      <div className="bg-muted/10 dark:bg-muted/30 p-2 rounded-lg mb-6">
        <TabsList className="inline-flex h-12 items-center justify-center w-full gap-1 bg-transparent">
          <TabsTrigger 
            value="overview" 
            className="flex items-center gap-2 h-full data-[state=active]:bg-card data-[state=active]:text-foreground dark:data-[state=active]:text-white data-[state=active]:shadow-md rounded-md transition-all text-foreground dark:text-gray-300"
          >
            <Activity className="h-5 w-5" />
            Overview
          </TabsTrigger>
          <TabsTrigger value="financial" className="flex items-center gap-2 h-full data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all">
            <DollarSign className="h-5 w-5" />
            Financial Management
          </TabsTrigger>
          <TabsTrigger value="markets" className="flex items-center gap-2 h-full data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all">
            <TrendingUp className="h-5 w-5" />
            Markets
          </TabsTrigger>
          <TabsTrigger value="members" className="flex items-center gap-2 h-full data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all">
            <Users className="h-5 w-5" />
            Users
          </TabsTrigger>
          <TabsTrigger value="commissions" className="flex items-center gap-2 h-full data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all">
            <Percent className="h-5 w-5" />
            Commissions
          </TabsTrigger>
          <TabsTrigger value="configuration" className="flex items-center gap-2 h-full data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all">
            <Settings className="h-5 w-5" />
            System Configuration
          </TabsTrigger>
          <TabsTrigger value="logs" className="flex items-center gap-2 h-full data-[state=active]:bg-card data-[state=active]:shadow-md rounded-md transition-all">
            <History className="h-5 w-5" />
            Activity Logs
          </TabsTrigger>
        </TabsList>
      </div>

      <TabsContent value="overview">
        <OverviewTab 
          currentYear={currentYear}
          currentMonth={currentMonth}
          loading={loading}
          financialData={financialData}
        />
      </TabsContent>
      
      <TabsContent value="financial">
        <FinancialsTab 
          settings={settings}
          regionalSettings={regionalSettings}
          loading={loading}
          isLoading={isLoading}
          updateSettings={updateSettings}
          handleUpdateRegionalSettings={handleUpdateRegionalSettings}
        />
      </TabsContent>
      
      <TabsContent value="markets">
        <MarketsAdmin />
      </TabsContent>
      
      <TabsContent value="members">
        <MembersAdmin />
      </TabsContent>
      
      <TabsContent value="commissions">
        <CommissionManagement />
      </TabsContent>
      
      <TabsContent value="configuration">
        <ConfigurationTab 
          settings={settings}
          regionalSettings={regionalSettings}
          isLoading={isLoading}
          updateSettings={updateSettings}
          handleUpdateRegionalSettings={handleUpdateRegionalSettings}
        />
      </TabsContent>
      
      <TabsContent value="logs">
        <AdminActivityLog />
      </TabsContent>
    </Tabs>
  );
};
