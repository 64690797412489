
import React, { useState } from "react";
import { MessageCircle, X, Send, RefreshCw, MessageSquare, PhoneCall, FileText, HelpCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useMediaQuery } from "@/hooks/use-media-query";
import { useAdminMessaging } from "@/hooks/admin/use-admin-messaging";
import { AnnouncementHistory } from "./AnnouncementHistory";
import { useAuth } from "@/contexts/AuthContext";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";

export function ChatBubble() {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const {
    isAdmin
  } = useAuth();
  const {
    announcements,
    isLoading,
    isSending,
    message,
    setMessage,
    title,
    setTitle,
    targetGroup,
    setTargetGroup,
    searchQuery,
    setSearchQuery,
    sendAnnouncement,
    deleteAnnouncement,
    refreshData,
    users
  } = useAdminMessaging();
  
  const handleSend = () => {
    if (!title.trim()) {
      return; // Don't proceed if title is empty
    }
    
    if (!message.trim()) {
      return; // Don't proceed if message is empty
    }
    
    // Call the sendAnnouncement function with the current state values
    sendAnnouncement();
    
    // Only close the dialog if sending is not in progress
    if (!isSending) {
      setOpen(false);
    }
  };

  const handleBackToMenu = () => {
    setActiveTab(null);
  };
  
  const renderMessagingTab = () => {
    return <div className="space-y-4">
        <div className="flex items-center mb-4">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleBackToMenu} 
            className="mr-2 text-muted-foreground hover:text-foreground"
          >
            <X className="h-4 w-4 mr-1" />
            Back
          </Button>
          <h3 className="font-medium text-primary">Messaging</h3>
        </div>

        <Input type="text" placeholder="Announcement Title" value={title} onChange={e => setTitle(e.target.value)} className="w-full bg-background/80 border-primary/20 focus-visible:ring-primary/30" />
        
        {isAdmin ? <Select value={targetGroup} onValueChange={setTargetGroup}>
            <SelectTrigger className="w-full bg-background/80 border-primary/20">
              <SelectValue placeholder="Select target audience" />
            </SelectTrigger>
            <SelectContent position="popper" className="max-h-[200px] overflow-y-auto bg-background/95 backdrop-blur-sm border-primary/20 z-50">
              <SelectItem value="all">All Users</SelectItem>
              <SelectItem value="members">Members Only</SelectItem>
              <SelectItem value="admins">Admins Only</SelectItem>
              <div className="py-1 px-2 text-xs font-medium text-muted-foreground">
                Individual Users
              </div>
              {users.map(user => <SelectItem key={user.id} value={user.id}>{user.first_name} {user.last_name}</SelectItem>)}
            </SelectContent>
          </Select> : <div className="text-sm text-muted-foreground py-3 px-4 bg-muted/30 rounded-md border border-border/50">
            Your message will be sent to all users
          </div>}
        
        <Textarea 
          placeholder="Type your announcement here..." 
          className="min-h-[120px] max-h-[200px] resize-none bg-background/80 border-primary/20 focus-visible:ring-primary/30" 
          value={message} 
          onChange={e => setMessage(e.target.value)} 
        />
        
        <div className="flex justify-between">
          <Button variant="outline" size="sm" onClick={() => {
            refreshData();
            setTitle("");
            setMessage("");
          }} className="flex items-center gap-1 border-primary/20 hover:bg-primary/10">
            <RefreshCw className="h-4 w-4" />
            Clear
          </Button>
          
          <Button className="flex items-center gap-2 bg-primary hover:bg-primary/90 text-primary-foreground" onClick={handleSend} disabled={isSending || !title.trim() || !message.trim()}>
            <Send className="h-4 w-4" />
            {isSending ? "Sending..." : "Send"}
          </Button>
        </div>
        
        <div className="mt-6 border-t border-border/30 pt-4">
          <h3 className="font-medium mb-2 text-primary">Recent Announcements</h3>
          <div className="rounded-md border border-border/30">
            <ScrollArea className="h-[180px] w-full rounded-md">
              <div className="p-2">
                <AnnouncementHistory 
                  announcements={announcements} 
                  isLoading={isLoading} 
                  onDelete={deleteAnnouncement} 
                  searchQuery={searchQuery} 
                  setSearchQuery={setSearchQuery} 
                  showDelete={isAdmin} 
                  compact={true}
                />
              </div>
            </ScrollArea>
          </div>
        </div>
      </div>;
  };

  const renderSupportTab = () => {
    return (
      <div className="space-y-4">
        <div className="flex items-center mb-4">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleBackToMenu} 
            className="mr-2 text-muted-foreground hover:text-foreground"
          >
            <X className="h-4 w-4 mr-1" />
            Back
          </Button>
          <h3 className="font-medium text-primary">Contact Support</h3>
        </div>
        <p className="text-muted-foreground">Need help? Contact our support team.</p>
        <Input type="text" placeholder="Subject" className="w-full bg-background/80 border-primary/20 focus-visible:ring-primary/30" />
        <Textarea placeholder="Describe your issue here..." className="min-h-[150px] resize-none bg-background/80 border-primary/20 focus-visible:ring-primary/30" />
        <Button className="w-full gap-2 bg-primary hover:bg-primary/90 text-primary-foreground">
          <Send className="h-4 w-4" />
          Send Request
        </Button>
      </div>
    );
  };

  const renderWhatsNewTab = () => {
    return (
      <div className="space-y-4">
        <div className="flex items-center mb-4">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleBackToMenu} 
            className="mr-2 text-muted-foreground hover:text-foreground"
          >
            <X className="h-4 w-4 mr-1" />
            Back
          </Button>
          <h3 className="font-medium text-primary">What's New</h3>
        </div>
        <ScrollArea className="h-[300px] pr-1">
          <div className="space-y-4">
            <div className="border border-primary/20 rounded-lg p-4 bg-background/50 hover:bg-background/70 transition-colors">
              <div className="flex justify-between items-start">
                <h4 className="font-medium text-primary">New Feature: Chat Bubble</h4>
                <span className="text-xs bg-primary/20 text-primary px-2 py-1 rounded-full">New</span>
              </div>
              <p className="text-sm text-muted-foreground mt-2">
                Access messaging, support, updates, and documentation from a convenient chat bubble interface.
              </p>
              <p className="text-xs text-muted-foreground mt-4">Released: June 15, 2023</p>
            </div>
            <div className="border border-primary/20 rounded-lg p-4 bg-background/50 hover:bg-background/70 transition-colors">
              <div className="flex justify-between items-start">
                <h4 className="font-medium text-primary">Enhanced User Management</h4>
                <span className="text-xs bg-primary/20 text-primary px-2 py-1 rounded-full">Improved</span>
              </div>
              <p className="text-sm text-muted-foreground mt-2">
                Better user management with waiting list approval functionality for admins.
              </p>
              <p className="text-xs text-muted-foreground mt-4">Released: June 10, 2023</p>
            </div>
          </div>
        </ScrollArea>
      </div>
    );
  };

  const renderDocsTab = () => {
    return (
      <div className="space-y-4">
        <div className="flex items-center mb-4">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={handleBackToMenu} 
            className="mr-2 text-muted-foreground hover:text-foreground"
          >
            <X className="h-4 w-4 mr-1" />
            Back
          </Button>
          <h3 className="font-medium text-primary">Documentation</h3>
        </div>
        <ScrollArea className="h-[300px] pr-1">
          <div className="space-y-2 text-muted-foreground">
            <Button variant="outline" className="w-full justify-start text-left hover:bg-primary/10 border-primary/20" asChild>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FileText className="h-4 w-4 mr-2 text-primary" />
                Getting Started Guide
              </a>
            </Button>
            <Button variant="outline" className="w-full justify-start text-left hover:bg-primary/10 border-primary/20" asChild>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FileText className="h-4 w-4 mr-2 text-primary" />
                Admin Documentation
              </a>
            </Button>
            <Button variant="outline" className="w-full justify-start text-left hover:bg-primary/10 border-primary/20" asChild>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FileText className="h-4 w-4 mr-2 text-primary" />
                API Reference
              </a>
            </Button>
            <Button variant="outline" className="w-full justify-start text-left hover:bg-primary/10 border-primary/20" asChild>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FileText className="h-4 w-4 mr-2 text-primary" />
                Frequently Asked Questions
              </a>
            </Button>
          </div>
        </ScrollArea>
      </div>
    );
  };
  
  const renderMenuOptions = () => {
    return (
      <div className="grid grid-cols-2 gap-4 p-2">
        <Button
          onClick={() => setActiveTab("messaging")}
          variant="outline"
          className="flex flex-col items-center justify-center p-6 h-auto border border-primary/20 hover:bg-primary/10"
        >
          <MessageSquare className="h-8 w-8 mb-2 text-primary" />
          <span className="text-sm font-medium">Messages</span>
        </Button>
        <Button
          onClick={() => setActiveTab("support")}
          variant="outline"
          className="flex flex-col items-center justify-center p-6 h-auto border border-primary/20 hover:bg-primary/10"
        >
          <PhoneCall className="h-8 w-8 mb-2 text-primary" />
          <span className="text-sm font-medium">Support</span>
        </Button>
        <Button
          onClick={() => setActiveTab("whatsNew")}
          variant="outline"
          className="flex flex-col items-center justify-center p-6 h-auto border border-primary/20 hover:bg-primary/10"
        >
          <HelpCircle className="h-8 w-8 mb-2 text-primary" />
          <span className="text-sm font-medium">What's New</span>
        </Button>
        <Button
          onClick={() => setActiveTab("docs")}
          variant="outline"
          className="flex flex-col items-center justify-center p-6 h-auto border border-primary/20 hover:bg-primary/10"
        >
          <FileText className="h-8 w-8 mb-2 text-primary" />
          <span className="text-sm font-medium">Documents</span>
        </Button>
      </div>
    );
  };

  const renderContent = () => {
    if (!activeTab) {
      return renderMenuOptions();
    }

    switch (activeTab) {
      case "messaging":
        return renderMessagingTab();
      case "support":
        return renderSupportTab();
      case "whatsNew":
        return renderWhatsNewTab();
      case "docs":
        return renderDocsTab();
      default:
        return renderMenuOptions();
    }
  };
  
  const chatBubbleButton = <Button className="h-14 w-14 rounded-full shadow-lg bg-primary hover:bg-primary/90 transition-all hover:scale-105 backdrop-blur-sm border border-primary/20" variant="default" onClick={() => setOpen(true)}>
      <MessageCircle className="h-6 w-6" />
    </Button>;
  
  if (isDesktop) {
    return <div className="fixed bottom-4 right-4 z-50">
        <Sheet open={open} onOpenChange={setOpen}>
          <SheetTrigger asChild>
            {chatBubbleButton}
          </SheetTrigger>
          <SheetContent className="w-[400px] sm:max-w-md border-l border-primary/20 bg-background/95 backdrop-blur-sm shadow-xl p-0" side="right">
            <SheetHeader className="p-6 pb-4 border-b border-border/30">
              <SheetTitle className="text-xl text-primary font-semibold">
                {!activeTab ? (isAdmin ? "Admin Dashboard" : "Community Hub") : ""}
                {activeTab === "messaging" && "Messaging"}
                {activeTab === "support" && "Support"}
                {activeTab === "whatsNew" && "What's New"}
                {activeTab === "docs" && "Documentation"}
              </SheetTitle>
            </SheetHeader>
            <div className="p-6 overflow-y-auto h-[calc(100vh-80px)]">
              {renderContent()}
            </div>
          </SheetContent>
        </Sheet>
      </div>;
  }
  
  return <div className="fixed bottom-4 right-4 z-50">
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger asChild>
          {chatBubbleButton}
        </DrawerTrigger>
        <DrawerContent className="max-h-[90vh] bg-background/95 backdrop-blur-sm border-t border-primary/20">
          <DrawerHeader className="border-b border-border/30">
            <DrawerTitle className="text-xl text-primary font-semibold">
              {!activeTab ? (isAdmin ? "Admin Dashboard" : "Community Hub") : ""}
              {activeTab === "messaging" && "Messaging"}
              {activeTab === "support" && "Support"}
              {activeTab === "whatsNew" && "What's New"}
              {activeTab === "docs" && "Documentation"}
            </DrawerTitle>
          </DrawerHeader>
          <div className="p-4 overflow-y-auto max-h-[calc(90vh-80px)]">
            {renderContent()}
          </div>
        </DrawerContent>
      </Drawer>
    </div>;
}
