
import React, { useState, useMemo, useCallback } from "react";
import { useMarketHistory } from "@/hooks/markets/history/use-market-history";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Market, MarketHistoryEntry } from "@/types/market";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { Download, Filter, RefreshCw, Search, ChevronUp, ChevronDown } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { DateRange } from "react-day-picker";
import { Skeleton } from "@/components/ui/skeleton";
import { Input } from "@/components/ui/input";
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination";
import { MarketHistoryDetailsModal } from "./MarketHistoryDetailsModal";

interface FilterableHistoryTableProps {
  market?: Market;
  title: string;
  description: string;
}

const PAGE_SIZE = 10;

type SortDirection = "asc" | "desc";
type SortField = "action" | "market" | "performer" | "date";

const MARKET_ACTIONS = [
  { value: "created", label: "Created" },
  { value: "updated", label: "Updated" },
  { value: "deleted", label: "Deleted" },
  { value: "status_change", label: "Status Change" },
  { value: "config_updated", label: "Config Updated" },
  { value: "market_duplicated", label: "Market Duplicated" },
  { value: "market_cloned", label: "Market Cloned" }
];

export const FilterableHistoryTable = ({ market, title, description }: FilterableHistoryTableProps) => {
  const [page, setPage] = useState(1);
  const [filterAction, setFilterAction] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [searchText, setSearchText] = useState<string>("");
  const [sortField, setSortField] = useState<SortField>("date");
  const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
  const [selectedMarketId, setSelectedMarketId] = useState<string | null>(null);
  const [selectedMarketName, setSelectedMarketName] = useState<string | undefined>(undefined);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const formattedDateRange = useMemo(() => {
    if (!dateRange) return undefined;
    
    return {
      start: dateRange.from ? new Date(dateRange.from) : undefined,
      end: dateRange.to ? new Date(dateRange.to) : undefined
    };
  }, [dateRange]);

  const { history, totalCount, isLoading, error, refetch } = useMarketHistory({
    market,
    page,
    limit: PAGE_SIZE,
    filterByAction: filterAction,
    dateRange: formattedDateRange
  });

  const handleError = (error: Error) => {
    console.error("Market history error:", error);
  };

  const totalPages = Math.max(1, Math.ceil((totalCount || 0) / PAGE_SIZE));

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNum: number) => {
    if (pageNum >= 1 && pageNum <= totalPages) {
      setPage(pageNum);
    }
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("desc");
    }
  };

  const sortedEntries = useMemo(() => {
    if (!history.length) return history;

    return [...history].sort((a, b) => {
      const factor = sortDirection === "asc" ? 1 : -1;
      
      switch (sortField) {
        case "action":
          return factor * a.action_type.localeCompare(b.action_type);
        case "market":
          return factor * a.market_name.localeCompare(b.market_name);
        case "performer":
          return factor * a.admin_email.localeCompare(b.admin_email);
        case "date":
          return factor * (new Date(a.performed_at).getTime() - new Date(b.performed_at).getTime());
        default:
          return 0;
      }
    });
  }, [history, sortField, sortDirection]);

  const filteredEntries = useMemo(() => {
    if (!searchText.trim()) return sortedEntries;
    
    const searchLower = searchText.toLowerCase();
    return sortedEntries.filter(entry => 
      entry.action_type.toLowerCase().includes(searchLower) ||
      entry.market_name.toLowerCase().includes(searchLower) ||
      entry.admin_email.toLowerCase().includes(searchLower) ||
      JSON.stringify(entry.details).toLowerCase().includes(searchLower)
    );
  }, [sortedEntries, searchText]);

  const handleExport = useCallback(() => {
    const headers = ["ID", "Market", "Action", "Performed By", "Date", "Details"];
    const csvContent = [
      headers.join(","),
      ...history.map(entry => [
        entry.id,
        entry.market_name,
        entry.action_type,
        entry.admin_email,
        format(new Date(entry.performed_at), "yyyy-MM-dd HH:mm:ss"),
        JSON.stringify(entry.details)
      ].join(","))
    ].join("\n");
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `market-history-${new Date().toISOString().slice(0, 10)}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [history]);

  const handleMarketClick = (entry: MarketHistoryEntry) => {
    if (entry.market_id) {
      setSelectedMarketId(entry.market_id);
      setSelectedMarketName(entry.market_name);
      setIsDetailsModalOpen(true);
    }
  };

  const getBadgeVariant = (action: string): "default" | "destructive" | "secondary" | "outline" => {
    if (action.includes("delete") || action.includes("suspend") || action.includes("fail")) {
      return "destructive";
    } else if (action.includes("update") || action.includes("config") || action.includes("change")) {
      return "secondary"; 
    } else if (action.includes("create") || action.includes("approve")) {
      return "outline";
    } else {
      return "default";
    }
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers: (number | 'ellipsis')[] = [];
    
    pageNumbers.push(1);
    
    const rangeStart = Math.max(2, page - 1);
    const rangeEnd = Math.min(totalPages - 1, page + 1);
    
    if (rangeStart > 2) {
      pageNumbers.push('ellipsis');
    }
    
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageNumbers.push(i);
    }
    
    if (rangeEnd < totalPages - 1) {
      pageNumbers.push('ellipsis');
    }
    
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return (
      <Pagination className="mt-4">
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious 
              onClick={prevPage} 
              className={page === 1 ? "pointer-events-none opacity-50" : "cursor-pointer"}
            />
          </PaginationItem>
          
          {pageNumbers.map((pageNum, idx) => 
            pageNum === 'ellipsis' ? (
              <PaginationItem key={`ellipsis-${idx}`}>
                <span className="px-4 py-2">...</span>
              </PaginationItem>
            ) : (
              <PaginationItem key={pageNum}>
                <PaginationLink 
                  isActive={page === pageNum}
                  onClick={() => goToPage(pageNum as number)}
                >
                  {pageNum}
                </PaginationLink>
              </PaginationItem>
            )
          )}
          
          <PaginationItem>
            <PaginationNext 
              onClick={nextPage}
              className={page === totalPages ? "pointer-events-none opacity-50" : "cursor-pointer"}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    );
  };

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <div className="flex space-x-2">
          <Button variant="outline" size="sm" onClick={() => refetch()}>
            <RefreshCw className="mr-2 h-4 w-4" />
            Refresh
          </Button>
          <Button variant="outline" size="sm" onClick={handleExport}>
            <Download className="mr-2 h-4 w-4" />
            Export CSV
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col gap-4 md:flex-row md:items-center">
              <div className="flex items-center space-x-2">
                <Filter className="h-4 w-4 text-muted-foreground" />
                <Select 
                  value={filterAction || "all"} 
                  onValueChange={(value) => setFilterAction(value === "all" ? undefined : value)}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Filter by action" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All actions</SelectItem>
                    {MARKET_ACTIONS.map(action => (
                      <SelectItem key={action.value} value={action.value}>
                        {action.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <DateRangePicker 
                value={dateRange}
                onChange={setDateRange}
                className="w-full sm:w-auto"
              />
            </div>

            <div className="relative w-full md:w-auto">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search history..."
                className="pl-8 w-full md:w-[260px]"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>

          {isLoading ? (
            <div className="space-y-3">
              {Array.from({ length: 5 }).map((_, i) => (
                <div key={i} className="flex items-center space-x-4">
                  <Skeleton className="h-8 w-24 rounded-full" />
                  {!market && <Skeleton className="h-10 w-[120px]" />}
                  <Skeleton className="h-10 w-[200px]" />
                  <Skeleton className="h-10 w-[100px]" />
                  <Skeleton className="h-10 w-[300px]" />
                </div>
              ))}
            </div>
          ) : filteredEntries.length === 0 ? (
            <div className="py-20 text-center">
              <p className="text-muted-foreground">No history entries found</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b">
                    <th className="text-left p-2 cursor-pointer" onClick={() => handleSort("action")}>
                      Action {sortField === "action" && (sortDirection === "asc" ? <ChevronUp className="h-4 w-4 inline ml-1" /> : <ChevronDown className="h-4 w-4 inline ml-1" />)}
                    </th>
                    {!market && (
                      <th className="text-left p-2 cursor-pointer" onClick={() => handleSort("market")}>
                        Market {sortField === "market" && (sortDirection === "asc" ? <ChevronUp className="h-4 w-4 inline ml-1" /> : <ChevronDown className="h-4 w-4 inline ml-1" />)}
                      </th>
                    )}
                    <th className="text-left p-2 cursor-pointer" onClick={() => handleSort("performer")}>
                      Performed By {sortField === "performer" && (sortDirection === "asc" ? <ChevronUp className="h-4 w-4 inline ml-1" /> : <ChevronDown className="h-4 w-4 inline ml-1" />)}
                    </th>
                    <th className="text-left p-2 cursor-pointer" onClick={() => handleSort("date")}>
                      Date {sortField === "date" && (sortDirection === "asc" ? <ChevronUp className="h-4 w-4 inline ml-1" /> : <ChevronDown className="h-4 w-4 inline ml-1" />)}
                    </th>
                    <th className="text-left p-2">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEntries.map((entry) => (
                    <tr key={entry.id} className="border-b hover:bg-muted/50">
                      <td className="p-2">
                        <Badge variant={getBadgeVariant(entry.action_type)}>
                          {entry.action_type}
                        </Badge>
                      </td>
                      {!market && (
                        <td className="p-2">
                          <button 
                            onClick={() => handleMarketClick(entry)}
                            className="cursor-pointer text-left hover:text-primary transition-colors"
                          >
                            <div className="font-medium underline-offset-2 hover:underline">{entry.market_name}</div>
                            <div className="text-xs text-muted-foreground">{entry.market_status}</div>
                          </button>
                        </td>
                      )}
                      <td className="p-2">{entry.admin_email}</td>
                      <td className="p-2 whitespace-nowrap">
                        {format(new Date(entry.performed_at), "MMM d, yyyy HH:mm")}
                      </td>
                      <td className="p-2">
                        <div className="max-w-md truncate">
                          {typeof entry.details === 'string' 
                            ? entry.details 
                            : JSON.stringify(entry.details, null, 2)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {renderPagination()}

          {totalCount > 0 && (
            <div className="text-sm text-muted-foreground">
              Showing {((page - 1) * PAGE_SIZE) + 1} to {Math.min(page * PAGE_SIZE, totalCount)} of {totalCount} entries
            </div>
          )}
        </div>
      </CardContent>

      <MarketHistoryDetailsModal
        marketId={selectedMarketId}
        marketName={selectedMarketName}
        isOpen={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
      />
    </Card>
  );
};
