
import React, { useEffect } from "react";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { AuthContext } from "./AuthContextProvider";
import { useAuthState } from "./useAuthState";
import { useDevelopmentSession } from "./useDevelopmentSession";
import { useSessionInitialization } from "./useSessionInitialization";
import { useAuthStateChange } from "./useAuthStateChange";
import { logger } from "@/utils/logger";
import { useAuthActions } from "./useAuthActions";

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { state, dispatch, mounted } = useAuthState();
  const { signOut, hardSignOut, checkAndUpdateAdminStatus } = useAuthActions(dispatch);
  const { setDevelopmentSession } = useDevelopmentSession({ dispatch });
  const { initializeAuth } = useSessionInitialization({ dispatch, mounted: mounted.current });
  const { setupAuthStateChange } = useAuthStateChange({ dispatch, mounted: mounted.current });

  useEffect(() => {
    initializeAuth();

    const { data: { subscription } } = setupAuthStateChange();

    return () => {
      mounted.current = false;
      subscription?.unsubscribe();
      logger.debug('Auth provider cleanup completed');
    };
  }, [initializeAuth, setupAuthStateChange]);

  const value = {
    ...state,
    signOut,
    hardSignOut,
    checkAndUpdateAdminStatus,
    ...(import.meta.env.DEV && { setDevelopmentSession }),
  };

  return (
    <ErrorBoundary>
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    </ErrorBoundary>
  );
};
