
import { Clock } from "lucide-react";
import * as React from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface TimePickerProps {
  date: Date;
  setDate: (date: Date) => void;
}

export function TimePickerDemo({
  date,
  setDate,
}: TimePickerProps) {
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);

  // Get hours and minutes from the date
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Handle hour change
  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    const newHour = parseInt(inputValue, 10);
    
    if (isNaN(newHour)) {
      e.target.value = hours.toString().padStart(2, "0");
      return;
    }
    
    // Allow temporary invalid values during typing
    if (inputValue.length <= 2) {
      // Update the input field immediately for better UX
      e.target.value = inputValue;
      
      // Only update the date if we have a valid hour
      if (newHour >= 0 && newHour <= 23) {
        const newDate = new Date(date);
        newDate.setHours(newHour);
        
        // Only update if the resulting date is in the future
        if (isValidFutureDate(newDate)) {
          setDate(newDate);
        }
      }
      
      // Auto-focus to minute input if hour is entered
      if (inputValue.length === 2 && newHour >= 0 && newHour <= 23) {
        minuteRef.current?.focus();
      }
    } else {
      // If input is too long, truncate it
      e.target.value = inputValue.substring(0, 2);
    }
  };

  // Handle minute change
  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    const newMinute = parseInt(inputValue, 10);
    
    if (isNaN(newMinute)) {
      e.target.value = minutes.toString().padStart(2, "0");
      return;
    }
    
    // Allow temporary invalid values during typing
    if (inputValue.length <= 2) {
      // Update the input field immediately for better UX
      e.target.value = inputValue;
      
      // Only update the date if we have a valid minute
      if (newMinute >= 0 && newMinute <= 59) {
        const newDate = new Date(date);
        newDate.setMinutes(newMinute);
        
        // Only update if the resulting date is in the future
        if (isValidFutureDate(newDate)) {
          setDate(newDate);
        }
      }
    } else {
      // If input is too long, truncate it
      e.target.value = inputValue.substring(0, 2);
    }
  };
  
  // Check if a date is in the future
  const isValidFutureDate = (dateToCheck: Date): boolean => {
    const now = new Date();
    return dateToCheck > now;
  };
  
  // Handle direct click/focus on inputs
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select(); // Select all text when focused
  };
  
  // Handle keyboard events for easier input
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, type: 'hour' | 'minute') => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const current = type === 'hour' ? hours : minutes;
      const max = type === 'hour' ? 23 : 59;
      const newValue = current < max ? current + 1 : 0;
      
      const newDate = new Date(date);
      if (type === 'hour') {
        newDate.setHours(newValue);
      } else {
        newDate.setMinutes(newValue);
      }
      
      if (isValidFutureDate(newDate)) {
        setDate(newDate);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      const current = type === 'hour' ? hours : minutes;
      const max = type === 'hour' ? 23 : 59;
      const newValue = current > 0 ? current - 1 : max;
      
      const newDate = new Date(date);
      if (type === 'hour') {
        newDate.setHours(newValue);
      } else {
        newDate.setMinutes(newValue);
      }
      
      if (isValidFutureDate(newDate)) {
        setDate(newDate);
      }
    }
  };
  
  // Handle blur to format inputs correctly
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>, type: 'hour' | 'minute') => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      if (type === 'hour' && (value < 0 || value > 23)) {
        e.target.value = hours.toString().padStart(2, "0");
      } else if (type === 'minute' && (value < 0 || value > 59)) {
        e.target.value = minutes.toString().padStart(2, "0");
      } else {
        e.target.value = value.toString().padStart(2, "0");
      }
    } else {
      e.target.value = type === 'hour' 
        ? hours.toString().padStart(2, "0") 
        : minutes.toString().padStart(2, "0");
    }
  };

  return (
    <div className="flex items-end gap-2">
      <div className="grid gap-1 text-center">
        <Label htmlFor="hours" className="text-xs">
          Hours
        </Label>
        <Input
          id="hours"
          ref={hourRef}
          className="w-16 text-center cursor-text"
          value={hours.toString().padStart(2, "0")}
          onChange={handleHourChange}
          onFocus={handleFocus}
          onBlur={(e) => handleBlur(e, 'hour')}
          onKeyDown={(e) => handleKeyDown(e, 'hour')}
          min={0}
          max={23}
          inputMode="numeric"
          pattern="[0-9]*"
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="minutes" className="text-xs">
          Minutes
        </Label>
        <Input
          id="minutes"
          ref={minuteRef}
          className="w-16 text-center cursor-text"
          value={minutes.toString().padStart(2, "0")}
          onChange={handleMinuteChange}
          onFocus={handleFocus}
          onBlur={(e) => handleBlur(e, 'minute')}
          onKeyDown={(e) => handleKeyDown(e, 'minute')}
          min={0}
          max={59}
          inputMode="numeric"
          pattern="[0-9]*"
        />
      </div>
      <div className="flex h-10 items-center">
        <Clock className="ml-2 h-4 w-4" />
      </div>
    </div>
  );
}
