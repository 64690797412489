
import React from 'react';
import { Badge } from '@/components/ui/badge';

interface UserMarketStatusBadgeProps {
  status: string | null | undefined;
  className?: string;
}

export const UserMarketStatusBadge = ({ status, className = '' }: UserMarketStatusBadgeProps) => {
  if (!status) return null;

  const getStatusDetails = (status: string): { label: string; variant: 'default' | 'success' | 'destructive' | 'outline' | 'secondary' } => {
    switch (status.toLowerCase()) {
      case 'success':
        return { label: 'Success', variant: 'success' };
      case 'failed':
        return { label: 'Failed', variant: 'destructive' };
      case 'pending':
        return { label: 'Pending', variant: 'outline' };
      case 'active':
        return { label: 'Active', variant: 'default' };
      case 'pending_contribution':
        return { label: 'Contribution Required', variant: 'secondary' };
      case 'withdrawal_requested':
        return { label: 'Withdrawal Requested', variant: 'secondary' };
      case 'withdrawn':
        return { label: 'Withdrawn', variant: 'outline' };
      default:
        return { label: status, variant: 'outline' };
    }
  };

  const { label, variant } = getStatusDetails(status);

  return (
    <Badge variant={variant} className={className}>
      {label}
    </Badge>
  );
};
